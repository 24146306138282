import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {FiFolder} from 'react-icons/fi'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoGrid,
  VmoGridColumn,
  VmoProgress,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

const Projects = () => {
  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/projects" />
            <h5>Projects</h5>
          </div>
        </div>
        <div className="mailActivity">
          <VmoDropdown text="Project">
            <VmoDropdownMenu>
              <VmoDropdownItem>Onboarding Project</VmoDropdownItem>
              <VmoDropdownItem>Testing</VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>
        </div>
      </div>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="projchartBox">
            <VmoCardContent className="projheaderBox">
              <div className="projd-flex">
                <span className="projSvg">
                  <FiFolder className="m-0" />
                </span>
                <div className="proj-Content">
                  <span>2</span>
                  <span>Total Projects</span>
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="projchartBox">
            <VmoCardContent className="projheaderBox">
              <div className="projd-flex">
                <span className="projSvg">
                  <FiFolder className="m-0" />
                </span>
                <div className="proj-Content">
                  <span>2</span>
                  <span>Archived Projects</span>
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="projchartBox">
            <VmoCardContent className="projheaderBox">
              <div className="projd-flex">
                <VmoGrid centered>
                  <VmoGridColumn
                    style={{
                      width: '80px',
                      height: '80px',
                      marginRight: '18px',
                    }}
                  >
                    <CircularProgressbar
                      styles={buildStyles({
                        textColor: 'rgba(0, 0, 0, 0.6)',
                        trailColor: '#f2f4f7',
                        textSize: '7px',
                      })}
                      strokeWidth={10}
                      value={0}
                      text="No Data Available"
                    />
                  </VmoGridColumn>
                </VmoGrid>
                <div className="proj-Content">
                  <span>Project</span>
                  <span>Distribution</span>
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Project Status</VmoCardHeader>
              <VmoTooltip
                content="Project Status"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent>
              <VmoGrid>
                <VmoGridColumn width="8">
                  <VmoTable sortable basic>
                    <VmoTableHeader>
                      <VmoTableRow>
                        <VmoTableHeaderCell>Status</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Count</VmoTableHeaderCell>
                      </VmoTableRow>
                    </VmoTableHeader>
                    <VmoTableBody>
                      <VmoTableRow>
                        <VmoTableCell>Active</VmoTableCell>
                        <VmoTableCell>2</VmoTableCell>
                      </VmoTableRow>
                    </VmoTableBody>
                  </VmoTable>
                </VmoGridColumn>
                <VmoGridColumn width="8">
                  <span>Graph chart</span>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Project Catgory</VmoCardHeader>
              <VmoTooltip
                content="Project Category"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent>
              <VmoGrid>
                <VmoGridColumn width="8">
                  <span>Graph chart</span>
                </VmoGridColumn>
                <VmoGridColumn width="8">
                  <VmoTable sortable basic>
                    <VmoTableHeader>
                      <VmoTableRow>
                        <VmoTableHeaderCell>Category</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Count</VmoTableHeaderCell>
                      </VmoTableRow>
                    </VmoTableHeader>
                    <VmoTableBody>
                      <VmoTableRow>
                        <VmoTableCell>Uncategorized</VmoTableCell>
                        <VmoTableCell>2</VmoTableCell>
                      </VmoTableRow>
                    </VmoTableBody>
                  </VmoTable>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>ProjectS</VmoCardHeader>
              <VmoTooltip
                content="Projects"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent>
              <VmoGrid>
                <VmoGridColumn>
                  <VmoTable sortable basic>
                    <VmoTableHeader>
                      <VmoTableRow>
                        <VmoTableHeaderCell>Project Name</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Manager</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Status</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Start Date</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Due Date</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Assignees</VmoTableHeaderCell>
                        <VmoTableHeaderCell>Task Completion %</VmoTableHeaderCell>
                      </VmoTableRow>
                    </VmoTableHeader>
                    <VmoTableBody>
                      <VmoTableRow>
                        <VmoTableCell>Onboarding Project</VmoTableCell>
                        <VmoTableCell>Ranto Berk</VmoTableCell>
                        <VmoTableCell>Active</VmoTableCell>
                        <VmoTableCell>Nov 22</VmoTableCell>
                        <VmoTableCell>Dec 05</VmoTableCell>
                        <VmoTableCell>4</VmoTableCell>
                        <VmoTableCell>
                          <div className="d-flex">
                            <VmoProgress className="m-0" style={{width: '75%'}} size="tiny" percent={15.79} /> 15.79%
                          </div>
                        </VmoTableCell>
                      </VmoTableRow>
                      <VmoTableRow>
                        <VmoTableCell>Testing</VmoTableCell>
                        <VmoTableCell>-</VmoTableCell>
                        <VmoTableCell>Active</VmoTableCell>
                        <VmoTableCell>-</VmoTableCell>
                        <VmoTableCell>-</VmoTableCell>
                        <VmoTableCell>1</VmoTableCell>
                        <VmoTableCell>
                          <div className="d-flex">
                            <VmoProgress className="m-0" style={{width: '75%'}} size="tiny" percent={0} /> 0%
                          </div>
                        </VmoTableCell>
                      </VmoTableRow>
                    </VmoTableBody>
                  </VmoTable>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Projects
