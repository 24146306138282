import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiHome} from 'react-icons/fi'
import {ResponsiveBar} from '@nivo/bar'
import moment from 'moment'
import {startCase} from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {
  getProjectsPlanningOverviewStatsByUser,
  getProjectsPlanningOverviewTimewiseStats,
} from 'actions/resource_planning'
import {
  GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER,
  GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS,
} from 'actions/types'
import CustomLoader from 'components/common/CustomLoader'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import SvgIcon from 'components/common/SvgIcon'

const formattedData = [
  {
    time: 'Sunday',
    Estimated: 60,
    EstimatedColor: '#ff61c7',
    Logged: 80,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Monday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 95,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Tuesday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 17,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Wednesday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 0,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Thursday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 0,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Friday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 0,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
  {
    time: 'Saturday',
    Estimated: 0,
    EstimatedColor: '#ff61c7',
    Logged: 0,
    LoggedColor: '#d8e0ff',
    Available: 0,
    AvailableColor: '#4ecd97',
  },
]

const getFormattedTime = minutes => {
  const duration = moment.duration(minutes, 'minutes')
  const hour = Math.floor(duration.asHours())
  const minute = duration.minutes()

  const formattedDuration = `${hour}h ${minute}m`
  return formattedDuration
}

const Overview = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)

  const {projectsPlanningOverviewTimewiseStats = [], projectsPlanningOverviewStatsByUser = {}} = useSelector(
    state => state.resourcePlanning
  )
  const {users = [], total = {}} = projectsPlanningOverviewStatsByUser

  const actionsToDispatch = useCallback(
    data => {
      const {from, to} = data
      const payload = {from, to}
      dispatch(getProjectsPlanningOverviewTimewiseStats(data))
      dispatch(getProjectsPlanningOverviewStatsByUser(payload))
    },
    [dispatch]
  )

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER)) {
      return <VmoTablePlaceholder columns={9} />
    }
    if (!type.includes(GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER) && !users.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="9" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return (
      <>
        <VmoTableBody>
          {users.map(user => (
            <VmoTableRow key={user.id} className="tableLink">
              <VmoTableCell>{startCase(`${user.firstName} ${user.lastName}`)}</VmoTableCell>
              <VmoTableCell>{user.openTasks || 0}</VmoTableCell>
              <VmoTableCell>{user.completedTasks || 0}</VmoTableCell>
              <VmoTableCell>{user.availableTime || 0}h</VmoTableCell>
              <VmoTableCell>{user.estimatedTime}</VmoTableCell>
              <VmoTableCell>{user.loggedTime}</VmoTableCell>
              <VmoTableCell>{user.remainingTime}</VmoTableCell>
              <VmoTableCell>
                <div style={{width: '130px'}}>
                  <p className="d-flex mb-1">
                    <span>{user.estimatedTimePercentage || 0}%</span>
                    <span>{user.availableTimePercentage || 0}%</span>
                  </p>
                  <div className="budget-progress">
                    <div
                      className="budget-progress-completed"
                      style={{width: `${user.estimatedTimePercentage || 0}%`}}
                    />
                  </div>
                </div>
              </VmoTableCell>
              <VmoTableCell>{user.projects || 0}</VmoTableCell>
            </VmoTableRow>
          ))}
        </VmoTableBody>
        <VmoTableFooter>
          <VmoTableRow>
            <VmoTableCell>
              <strong>Totals</strong>
            </VmoTableCell>
            <VmoTableCell>{total.openTasks || 0}</VmoTableCell>
            <VmoTableCell>{total.completedTasks || 0}</VmoTableCell>
            <VmoTableCell>{total.availableTime || 0}h</VmoTableCell>
            <VmoTableCell>{total.estimatedTime}</VmoTableCell>
            <VmoTableCell>{total.loggedTime}</VmoTableCell>
            <VmoTableCell>{total.remainingTime}</VmoTableCell>
            <VmoTableCell>
              <div style={{width: '130px'}}>
                <p className="d-flex mb-1">
                  <span>{total.estimatedTimePercentage || 0}%</span>
                  <span>{total.availableTimePercentage || 0}%</span>
                </p>
                <div className="budget-progress">
                  <div
                    className="budget-progress-completed"
                    style={{width: `${total.estimatedTimePercentage || 0}%`}}
                  />
                </div>
              </div>
            </VmoTableCell>
            <VmoTableCell>{total.projects || 0}</VmoTableCell>
          </VmoTableRow>
        </VmoTableFooter>
      </>
    )
  }

  return (
    <div style={{padding: '18px'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <FiHome />
            <h5>Overview</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Employees Time</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of working time over a period of timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 367}}>
              {type.includes(GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS) &&
                projectsPlanningOverviewTimewiseStats.every(({added, deleted}) => added === 0 && deleted === 0) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS) && (
                <ResponsiveBar
                  data={projectsPlanningOverviewTimewiseStats}
                  keys={['Estimated', 'Logged', 'Available']}
                  indexBy="time"
                  margin={{top: 37, right: 27, bottom: 70, left: 70}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(projectsPlanningOverviewTimewiseStats, [
                    'Estimated',
                    'Logged',
                    'Available',
                  ])}
                  gridYValues={getGridYValuesMultipleBar(projectsPlanningOverviewTimewiseStats, [
                    'Estimated',
                    'Logged',
                    'Available',
                  ])}
                  groupMode="grouped"
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`${d.id}Color`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    format: value => getFormattedTime(value),
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(projectsPlanningOverviewTimewiseStats, [
                      'Estimated',
                      'Logged',
                      'Available',
                    ]),
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: -65,
                  }}
                  enableLabel={false}
                  labelSkipHeight={1}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -40,
                      itemsSpacing: 2,
                      itemWidth: 100,
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  tooltip={e => {
                    const {label, value} = e
                    return (
                      <div style={{backgroundColor: 'white', padding: '3px 6px 3px 6px', borderRadius: '2px'}}>
                        {label} : <strong>{getFormattedTime(value)}</strong>
                      </div>
                    )
                  }}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Employees List</VmoCardHeader>
              <VmoTooltip
                content="List of employees organized based on workload"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>User Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Open Tasks</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Completed Tasks</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Available Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Estimated Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Logged Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Remaining Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Total Capacity</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Projects</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                {renderTableBody()}
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Overview
