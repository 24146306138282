/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import _ from 'lodash'
import Moment from 'moment'
import {
  VmoGrid,
  VmoGridColumn,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoCardMeta,
  VmoForm,
  VmoFormField,
  VmoRadio,
  VmoGridRow,
  VmoFormGroup,
  VmoTooltip,
  VmoTable,
  VmoTableHeader,
  VmoTableRow,
  VmoTableHeaderCell,
  VmoTableBody,
  VmoTableCell,
} from 'vmo-library'

import {STATUS_WISE_DEALS} from 'actions/types'
import {homeStats, topTasks, statusWiseDeals} from 'actions/dashboards_sales'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {maxOfGraphBar} from 'utils/graph'
import CustomLoader from 'components/common/CustomLoader'
import SvgIcon from 'components/common/SvgIcon'
import {UserDropdown} from '../AnalyticsUtils'
import DateRangeGroup from '../DateRangeGroup'

export const Sales = () => {
  const dispatch = useDispatch()

  const [allData, setAllData] = useState({})
  const [dealsRadio, setDealsRadio] = useState('value')

  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {homeStatsData, topTasksData = [], statusWiseDealsData = []} = useSelector(state => state.dashboardsSales)

  const actionsToDispatch = useCallback(
    data => {
      dispatch(homeStats(_.omit(data, 'graphType')))
      dispatch(topTasks(_.omit(data, 'graphType')))
      setAllData(_.omit(data, 'graphType'))
      // dispatch(statusWiseDeals(data));
    },
    [dispatch]
  )

  useEffect(() => {
    if (dealsRadio) {
      allData.graphType = dealsRadio
      dispatch(statusWiseDeals(allData))
    }
  }, [dispatch, allData, dealsRadio])

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="navigation/home" />
            <h5>Sales</h5>
          </div>
        </div>
        <div className="page-action">
          <UserDropdown />
          <DateRangeGroup
            actionsToDispatch={actionsToDispatch}
            userId={
              analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                ? Number(analyticsSelectedAssignee.id)
                : null
            }
          />
        </div>
      </div>
      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoCardHeader>Contacts</VmoCardHeader>
            </VmoCardContent>
            <VmoCardContent style={{border: 'none'}}>
              <VmoGrid columns={2}>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>New</VmoCardHeader>
                      <h3>
                        {get(['contacts', 'new', 'newContacts'], homeStatsData, '')}
                        <VmoTooltip
                          content="number of new contacts added compared to previous duration"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['contacts', 'new', 'growth'], homeStatsData, []) === 'positive'
                                  ? ''
                                  : 'text-danger'
                              }
                            >
                              {get(['contacts', 'new', 'growth'], homeStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['contacts', 'new', 'percentage'], homeStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Totals</VmoCardHeader>
                      <h3>
                        {get(['contacts', 'total'], homeStatsData, '')}
                        <VmoTooltip
                          content="total number of contacts added compared to previous duration"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['leads', 'new', 'growth'], homeStatsData, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['leads', 'new', 'growth'], homeStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['leads', 'new', 'percentage'], homeStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoCardHeader>Companies</VmoCardHeader>
            </VmoCardContent>
            <VmoCardContent style={{border: 'none'}}>
              <VmoGrid columns={2}>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>New</VmoCardHeader>
                      <h3>
                        {get(['companies', 'new', 'newCompanies'], homeStatsData, '')}
                        <VmoTooltip
                          content="number of new companies added compared to previous duration"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['companies', 'new', 'growth'], homeStatsData, []) === 'positive'
                                  ? ''
                                  : 'text-danger'
                              }
                            >
                              {get(['companies', 'new', 'growth'], homeStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['companies', 'new', 'percentage'], homeStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total</VmoCardHeader>
                      <h3>
                        {get(['companies', 'total'], homeStatsData, '')}
                        <VmoTooltip
                          content="total number of companies added compared to previous duration"
                          size="mini"
                          position="bottom center"
                          trigger={
                            <span
                              className={
                                get(['leads', 'new', 'growth'], homeStatsData, []) === 'positive' ? '' : 'text-danger'
                              }
                            >
                              {get(['leads', 'new', 'growth'], homeStatsData, []) === 'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['leads', 'new', 'percentage'], homeStatsData, [])}%
                            </span>
                          }
                        />
                      </h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Deals</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of Deals based on their status over a specific period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent
              className="p-0"
              style={{
                position: 'absolute',
                right: '10px',
                top: '15px',
                zIndex: '1',
              }}
            >
              <VmoCardMeta className="d-flex" style={{alignItems: 'flex-start'}}>
                <VmoForm>
                  <VmoFormGroup inline>
                    <VmoFormField>
                      <VmoRadio
                        label="Value"
                        checked={dealsRadio === 'value'}
                        onChange={() => {
                          setDealsRadio('value')
                        }}
                      />
                    </VmoFormField>
                    <VmoFormField>
                      <VmoRadio
                        label="Count"
                        checked={dealsRadio === 'count'}
                        onChange={() => setDealsRadio('count')}
                      />
                    </VmoFormField>
                  </VmoFormGroup>
                </VmoForm>
              </VmoCardMeta>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(STATUS_WISE_DEALS) && <CustomLoader />}
              {!type.includes(STATUS_WISE_DEALS) && (
                <ResponsiveBar
                  data={statusWiseDealsData}
                  // keys={["urgent", "high", "medium", "low", "default"]}
                  indexBy="status"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  minValue={0}
                  maxValue={maxOfGraphBar(statusWiseDealsData, 'value')}
                  // gridXValues={getGridYValuesBar(statusWiseDealsData, 'value')}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Status',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Value',
                    // tickValues: getGridYValuesBar(statusWiseDealsData, 'value'),
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Open Tasks(Past Dues,Today,Upcoming)</VmoCardHeader>
              <VmoTooltip
                content="List of tasks based on types over a time frame"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Subject</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Type</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Time</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {topTasksData && topTasksData.length === 0 ? (
                    <tr>
                      <td colSpan="4" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {topTasksData?.map((data, index) => (
                        <VmoTableRow key={index}>
                          <VmoTableCell>{startCase(data.subject)}</VmoTableCell>
                          <VmoTableCell>{startCase(data.type)}</VmoTableCell>
                          <VmoTableCell>
                            {startCase(`${data.createdby?.firstName} ${data.createdby?.lastName}`)}
                          </VmoTableCell>
                          <VmoTableCell>{getFormattedDate(data.startAt)}</VmoTableCell>
                        </VmoTableRow>
                      ))}
                    </>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Sales
