import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {ResponsivePie} from '@nivo/pie'
import Moment from 'moment'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {TASKS_BY_STATUS, TASKS_BY_TYPES, TASKS_BY_TYPES_OVER_TIME, TASKS_BY_STATUS_OVER_TIME} from 'actions/types'
import {taskByStatus, taskByStatusOverTime, taskByType, taskByTypeOverTime, taskByUser} from 'actions/reports_task'
import {getGridYValues, getGridYValuesBar, maxOfGraph, maxOfGraphBar} from 'utils/graph'
import {startCase, get} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import {AssigneeDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const TaskAnalytics = () => {
  const dispatch = useDispatch()
  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {
    tasksByStatusData = [],
    tasksByTypesData = [],
    tasksByTypesOverTimeData = [],
    tasksByStatusOverTimeData = [],
    tasksByUsersData = [],
  } = useSelector(state => state.reportsTasks)
  const {reportsPermissions} = useUserPermissions()
  const performanceReportsPermissions = reportsPermissions?.performance_reports

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  const actionsToDispatch = useCallback(
    data => {
      dispatch(taskByStatus(data))
      dispatch(taskByStatusOverTime(data))
      dispatch(taskByType(data))
      dispatch(taskByTypeOverTime(data))
      dispatch(taskByUser(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/task" />
            <h5>Tasks</h5>
          </div>
        </div>
        {performanceReportsPermissions && (
          <div className="page-action">
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <VmoGrid columns="equal">
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tasks By Status</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of tasks with respective of status"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TASKS_BY_STATUS) && <CustomLoader />}
              {!type.includes(TASKS_BY_STATUS) && !tasksByStatusData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={tasksByStatusData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.7}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableRadialLabels
                  enableSlicesLabels
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, label, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {label} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(TASKS_BY_STATUS) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tasks By Type</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of tasks with respective of type"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TASKS_BY_TYPES) && <CustomLoader />}
              {!type.includes(TASKS_BY_TYPES) && !tasksByTypesData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={tasksByTypesData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.7}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableRadialLabels
                  enableSlicesLabels
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, label, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {label} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(TASKS_BY_TYPES) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Task By Type</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of tasks with respective of type"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TASKS_BY_TYPES_OVER_TIME) && <CustomLoader />}
              {!type.includes(TASKS_BY_TYPES_OVER_TIME) && (
                <ResponsiveBar
                  data={tasksByTypesOverTimeData}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(tasksByTypesOverTimeData, 'value')}
                  gridYValues={getGridYValuesBar(tasksByTypesOverTimeData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(tasksByTypesOverTimeData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tasks By Status</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of task based on status over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(TASKS_BY_STATUS_OVER_TIME) && <CustomLoader />}
              {!type.includes(TASKS_BY_STATUS_OVER_TIME) && (
                <ResponsiveLine
                  data={tasksByStatusOverTimeData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(tasksByStatusOverTimeData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,

                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(tasksByStatusOverTimeData),
                    legend: 'Lead Count',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(tasksByStatusOverTimeData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tasks By Users</VmoCardHeader>
              <VmoTooltip
                content="List of users organized based on task assigned to each"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Calls</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Meetings</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Activity</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Total</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {tasksByUsersData && tasksByUsersData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {tasksByUsersData.map(user => (
                        <VmoTableRow key={user.id}>
                          <VmoTableCell>{startCase(`${user.firstName} ${user.lastName}`)}</VmoTableCell>
                          <VmoTableCell>{user.call}</VmoTableCell>
                          <VmoTableCell>{user.meeting}</VmoTableCell>
                          <VmoTableCell>{user.activity}</VmoTableCell>
                          <VmoTableCell>{user.total}</VmoTableCell>
                        </VmoTableRow>
                      ))}
                    </>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}
export default TaskAnalytics
