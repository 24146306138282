import React from 'react'
import {useHistory, useLocation, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoImage,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'

import {getSingleInvoice} from 'actions/billing'
import logo from 'assets/logo.svg'
import {get, getFormattedDate, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const InvoiceDetail = () => {
  const {invoiceId} = useParams()
  const location = useLocation()
  const history = useHistory()

  const {data: getSingleInvoiceDetailsData = []} = useApiResponse({
    action: getSingleInvoice,
    payload: invoiceId,
    dependency: [invoiceId],
    enabled: invoiceId,
    storePath: 'billing.getSingleInvoiceDetailsData',
  })

  const renderListOfItems = () => {
    return getSingleInvoiceDetailsData?.data?.map(({plan = '', addon = '', quantity = 0, amount = 0}, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(plan || addon)}</VmoTableCell>
        <VmoTableCell>{get(['metadata', 'name'], getSingleInvoiceDetailsData, '-')}</VmoTableCell>
        <VmoTableCell>{getFormattedDate(getSingleInvoiceDetailsData.endAt)}</VmoTableCell>
        <VmoTableCell>{quantity || 0}</VmoTableCell>
        <VmoTableCell className="price">$ {amount / 100}</VmoTableCell>
        <VmoTableCell>$ {((quantity * amount) / 100).toFixed(2)}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  const renderDiscountItems = () => {
    return get(['discounts'], getSingleInvoiceDetailsData, []).map(val => (
      <VmoTableRow>
        <VmoTableCell colSpan="5" className="text-right">
          {get(['description'], val, '')} {get(['couponCode'], val) ? `(${get(['couponCode'], val)})` : ''}
        </VmoTableCell>
        <VmoTableCell className="price">- ${get(['amount'], val, '')}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="set-layout set-layout-full">
      <VmoContainer fluid>
        <VmoButton
          content="Back"
          transparent
          compact
          icon="chevron left"
          style={{background: 'none'}}
          onClick={() => {
            if (location.state) {
              history.push('/settings/invoices')
            } else {
              history.push('/settings/my-plan')
            }
          }}
        />
        <VmoCard fluid className="invoiceCard">
          <VmoCardContent className="invoiceBody">
            <div className="invHeader">
              <div className="invLeft">
                <VmoImage src={logo} alt="img" size="small" />
                <h3 className="mb-0 mt-5">
                  Invoices : &nbsp;
                  {startCase(
                    get(
                      ['data', [getSingleInvoiceDetailsData?.data?.length - 1], 'plan'],
                      getSingleInvoiceDetailsData,
                      '-'
                    )
                  )}
                </h3>
                <h5 className="mt-1">
                  Date :{' '}
                  {get(['paidAt'], getSingleInvoiceDetailsData)
                    ? getFormattedDate(get(['paidAt'], getSingleInvoiceDetailsData, ''))
                    : getFormattedDate(get(['createdAt'], getSingleInvoiceDetailsData, ''))}
                </h5>
              </div>
              <div className="InvoiceTo">
                <h6>Invoice To</h6>
                <h4 className="clientName">
                  {`${get(['billingProfile', 'firstName'], getSingleInvoiceDetailsData, '')} ${get(
                    ['billingProfile', 'lastName'],
                    getSingleInvoiceDetailsData,
                    ''
                  )}`}
                  <br />
                  <small className="ml-1">
                    {get(['billingProfile', 'companyName'], getSingleInvoiceDetailsData, '')}
                  </small>
                </h4>
                <p className="contactInfo">
                  {/* <SvgIcon path="settings/email" />  */}
                  {get(['billingProfile', 'email'], getSingleInvoiceDetailsData, '')}
                </p>
                <p className="contactInfo">
                  <SvgIcon path="settings/phone" />
                  {`${get(['billingProfile', 'phoneCode'], getSingleInvoiceDetailsData, '-')} ${get(
                    ['billingProfile', 'phone'],
                    getSingleInvoiceDetailsData,
                    '-'
                  )}`}
                </p>
              </div>
            </div>
            <VmoTable basic selectable className="my-5">
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Description</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Validity</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Users</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Unit Price</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Total</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {renderListOfItems()}
                <VmoTableRow>
                  <VmoTableCell colSpan="6" style={{textAlign: 'right'}}>
                    <h5 className="mb-0">Sub-Total</h5>
                  </VmoTableCell>
                  <VmoTableCell className="price">
                    <h5>
                      $
                      {get(['data'], getSingleInvoiceDetailsData, [])
                        .reduce((acc, el) => (el.amount / 100) * el.quantity + acc, 0)
                        .toFixed(2)}
                    </h5>
                  </VmoTableCell>
                </VmoTableRow>
                {renderDiscountItems()}
                <VmoTableRow>
                  <VmoTableCell colSpan="6" style={{textAlign: 'right'}}>
                    <h5 className="mb-0">Total</h5>
                  </VmoTableCell>
                  <VmoTableCell>
                    <h4 style={{color: '#1d89ff'}}>
                      ${(get(['total'], getSingleInvoiceDetailsData, 0) / 100).toFixed(2)}
                    </h4>
                  </VmoTableCell>
                </VmoTableRow>
              </VmoTableBody>
            </VmoTable>
            <div className="invoiceFooter">
              <h5>Centum Technologies</h5>
              <p className="kooper-lead">807, Gala Empire, Opp. Doordarshan Tower, DriveIn Road, Ahmedabad - 380054</p>
              <p className="contactInfo">
                {/* <SvgIcon path="settings/email" />  */}
                info@centum.com
              </p>
              <p className="contactInfo">
                <SvgIcon path="settings/phone" /> +91 81245 54785
              </p>
            </div>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </div>
  )
}

export default InvoiceDetail
