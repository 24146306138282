import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {
  TOTAL_REVENUE_BY_PRODUCTS,
  TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
  WON_DEALS_BY_PRODUCTS,
  CURRENT_DEAL_BY_PRODUCTS,
  CONTACT_BY_PRODUCTS,
} from './types'

export function wonDealsByProducts(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/products/totalwondealsbyproducts`,
    method: 'POST',
    label: WON_DEALS_BY_PRODUCTS,
    data,
  })
}

export function totalRevenueByProducts(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/products/totalrevenuebyproducts`,
    method: 'POST',
    label: TOTAL_REVENUE_BY_PRODUCTS,
    data,
  })
}

export function totalQuantitySoldByProduct(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/products/totalquantitysoldbyproduct`,
    method: 'POST',
    label: TOTAL_QUANTITY_SOLD_BY_PRODUCTS,
    data,
  })
}

export function currentDealByProducts(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/products/currentdealbyproducts`,
    method: 'POST',
    label: CURRENT_DEAL_BY_PRODUCTS,
    data,
  })
}

export function newLeadsOrContactByProduct(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/products/newcontactbyproduct`,
    method: 'POST',
    label: CONTACT_BY_PRODUCTS,
    data,
  })
}
