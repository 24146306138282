/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import moment from 'moment'
import {VmoButton, VmoTab, vmoToast} from 'vmo-library'

import {
  CREATE_SEQUENCE,
  GET_ALL_CONTACTS_IDS,
  GET_ALL_MAIL_INBOX,
  GET_ALL_TAG_LABEL,
  GET_SEGMENTS_LIST,
  GET_USERS,
} from 'actions/types'
import {getAllContactsIds} from 'actions/contacts'
import {getAllMailInbox} from 'actions/inbox'
import {getTagLabel} from 'actions/multiple_fields'
import {getSegmentListWithNumbers} from 'actions/segment'
import {createSequence, getAllSequences} from 'actions/sequence'
import {get} from 'utils/helper'
import {CreateSequence1, CreateSequence2, CreateSequence3} from 'validation/Sales/sequence.schema'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {INBOX_TYPES} from 'constants/enums'
import Flow from './Steps/Flow'
import Settings from './Steps/Settings'
import Setup from './Steps/Setup'

const workingDayList = [
  {value: 0, key: 0, text: 'Sunday'},
  {value: 1, key: 1, text: 'Monday'},
  {value: 2, key: 2, text: 'Tuesday'},
  {value: 3, key: 3, text: 'Wednesday'},
  {value: 4, key: 4, text: 'Thursday'},
  {value: 5, key: 5, text: 'Friday'},
  {value: 6, key: 6, text: 'Saturday'},
]

const steps = [
  {name: 'Setup', icon: 'clipboard outline'},
  {name: 'Flow', icon: 'unordered list'},
  {name: 'Settings', icon: 'setting'},
]

const SequencesCreate = props => {
  const {setWarningModal} = props
  const dispatch = useDispatch()
  const history = useHistory()

  const [createSeqData, setCreateSeqData] = useState({})
  const [disabledIndex, setDisabledIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [contactList, setcontactList] = useState([])
  const [contactSegmentList, setContactSegmentList] = useState([])
  const [stepList, setStepList] = useState([])
  const [allAssigness, setAllAssigness] = useState([])
  const [listOfInbox, setListOfInbox] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [contactsSelectedShowError, setContactsSelectedShowError] = useState(false)

  const {sequencesList = []} = useSelector(state => state.sequences)
  const {successLabels = [], isLoadingData, types = []} = useSelector(state => state.apiReducer)

  const dayList = Array.from({length: 30}, (value, index) => ({
    value: index + 1,
    text: index + 1,
    key: index + 1,
  }))

  const initialData1 = {
    name: '',
    inboxId: '',
    contactIds: [],
    contactSegemntIds: [],
    contactSegmentCheckbox: false,
    contactCheckbox: false,
    startAt: new Date().toISOString(),
    timezone: moment.tz.guess(true),
    startTime: '10:00:00',
    endTime: '18:00:00',
    workingDays: workingDayList.filter(value => value.value > 0 && value.value < 6).map(value => value.value),
  }
  const initialData2 = {
    name: '',
  }

  const initialData3 = {
    holidays: '',
    isSingleThread: true,
    addUnsubLink: false,
    updateContactAfterDays: '',
    pauseOnOutOfEmail: false,
    resumeAfterDays: '',
    addContactLabels: [],
    contactUnsubLabels: [],
    firstStepLabels: [],
    contactReplyLabels: [],
    contactNoReplyLabels: [],
    stopsOnBookMeeting: true,
    stopsOnEmail: true,
    stopsOnText: true,
    stopsOnCloseDeal: true,
    stopsOnTaskStatus: true,
    delayBetweenEmails: 15,
    dailyLimit: '',
    vmoBranding: false,
    taskStatus: 'closed',
  }

  const {control, errors, handleSubmit, formState, watch} = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(CreateSequence1),
    defaultValues: initialData1,
  })

  const {
    control: control1,
    errors: errors1,
    handleSubmit: handleSubmit1,
    formState: formState1,
    setError: setError1,
    clearErrors: clearErrors1,
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(CreateSequence2),
    defaultValues: initialData2,
  })

  const {
    control: control2,
    errors: errors2,
    handleSubmit: handleSubmit2,
    watch: watch2,
    formState: formState2,
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(CreateSequence3),
    defaultValues: initialData3,
  })

  const {isDirty} = formState
  const {isDirty1} = formState1
  const {isDirty2} = formState2
  useEffect(() => {
    dispatch(getAllSequences())
  }, [])
  const watchMailBoxId = watch('inboxId')
  const handleCreate = (data, index) => {
    setWarningModal(false)
    if (index === 0) {
      if (data.contactCheckbox || data.contactSegmentCheckbox) {
        setContactsSelectedShowError(false)
        setCreateSeqData({...createSeqData, ...data})
        setIndex(index + 1)
        setDisabledIndex(disabledIndex + 1)
      } else {
        setContactsSelectedShowError(true)
      }
    }

    if (index === 1) {
      setDisabledIndex(disabledIndex + 1)
      if (stepList.length > 0) {
        setCreateSeqData({...createSeqData, ...data, steps: stepList})
        setIndex(index + 1)
      } else {
        setError1('steps', {
          type: 'manual',
          message: 'Please add minimum one step to proceed',
        })
      }
    }

    if (index === 2) {
      // setCreateSeqData({ ...createSeqData, ...data });
      if (sequencesList.map(({name}) => name).includes(createSeqData.name)) {
        vmoToast.error('Sequences with same name is not allowed')
      } else {
        dispatch(
          createSequence(
            _.omit({...createSeqData, ...data}, [
              'contactSegmentCheckbox',
              'contactCheckbox',
              'leadSegmentCheckbox',
              'leadCheckbox',
            ])
          )
        )
      }
    }
  }

  const handleUnSaved = () => {
    if (isDirty || isDirty1 || isDirty2) {
      setWarningModal(true)
    } else {
      setWarningModal(false)
    }
  }

  useEffect(() => {
    handleUnSaved()
  }, [isDirty, isDirty1, isDirty2])

  useEffect(() => {
    if (successLabels.includes(CREATE_SEQUENCE)) {
      history.push(`/sequences`)
    }
  }, [successLabels])

  useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
    onSuccess: contactsIdsList => {
      const list = contactsIdsList.map(contact => ({
        value: contact.id,
        key: contact.id,
        text: contact.name,
      }))
      setcontactList(list)
    },
  })

  useApiResponse({
    action: getSegmentListWithNumbers,
    enabled: 'contacts',
    label: GET_SEGMENTS_LIST,
    storePath: 'segment.segmentList',
    onSuccess: segmentList => {
      if (segmentList.contacts) {
        const list = segmentList.contacts.map(contact => ({
          value: contact.id,
          key: contact.id,
          text: `${contact.name} (${contact.contacts} contacts)`,
        }))
        setContactSegmentList(list)
      }
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        content: (
          <>
            {`${get(['firstName'], user, '')} ${get(['lastName'], user, '')}`}
            <small className="ml-2 kooper-lead">({user.email})</small>
          </>
        ),
      }))
      setAllAssigness(list)
    },
  })

  useApiResponse({
    action: getAllMailInbox,
    enabled: true,
    label: GET_ALL_MAIL_INBOX,
    storePath: 'mailInbox.inbox',
    onSuccess: mailboxes => {
      const mailBoxList = mailboxes
        .filter(ls => ls.type === INBOX_TYPES.EMAIL && ls.emailVerified)
        .map(mail => {
          return {
            value: mail.id,
            key: mail.id,
            content: (
              <div>
                {get(['name'], mail, '')}
                <small className="ml-2 kooper-lead">({get(['email'], mail, '')})</small>
              </div>
            ),
            text: `${get(['name'], mail, '')}`,
          }
        })

      setListOfInbox(mailBoxList)
    },
  })

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: labelOptions => {
      const newData = labelOptions.map(label => ({
        value: label.id,
        key: label.id,
        text: label.label,
      }))
      setLabelOptionsState(newData)
    },
  })

  return (
    <div className="kooper-full-page">
      <div className="page-header">
        <div className="top">
          <div className="title">
            <VmoButton basic inverted onClick={() => history.push('/sequences')}>
              Exit
            </VmoButton>
            {index > 0 && (
              <VmoButton basic inverted onClick={() => setIndex(index - 1)}>
                Back
              </VmoButton>
            )}
          </div>

          <div className="center blue-tab">
            <VmoTab
              key={index}
              panes={steps.map((item, i) => ({
                menuItem: {
                  key: item.name,
                  icon: item.icon,
                  content: item.name,
                  disabled: !(disabledIndex >= i),
                },
                render: null,
              }))}
              menu={{
                secondary: true,
                pointing: true,
                color: 'blue',
              }}
              activeIndex={index}
              onTabChange={(e, {activeIndex}) => {
                setIndex(activeIndex)
              }}
              renderActiveOnly={false}
            />
          </div>

          <div className="action">
            <VmoButton
              disabled={isLoadingData && types.includes(CREATE_SEQUENCE)}
              onClick={
                index === 0
                  ? handleSubmit(data => handleCreate(data, index))
                  : index === 1
                  ? handleSubmit1(data => handleCreate(data, index))
                  : handleSubmit2(data => handleCreate(data, index))
              }
              primary
            >
              {index !== 2 ? 'Next' : 'Create'}
            </VmoButton>
          </div>
        </div>
      </div>

      <Setup
        style={{display: index === 0 ? '' : 'none'}}
        control={control}
        errors={errors}
        listOfInbox={listOfInbox}
        contactList={contactList}
        contactSegmentList={contactSegmentList}
        watch={watch}
        contactsSelectedShowError={contactsSelectedShowError}
      />
      <Flow
        style={{
          display: index === 1 ? '' : 'none',
          paddingTop: 20,
        }}
        errors={errors1}
        control={control1}
        stepList={stepList}
        assignee={allAssigness}
        setStepList={setStepList}
        dayList={dayList}
        watchMailBoxId={watchMailBoxId}
        clearErrors={clearErrors1}
      />
      <Settings
        style={{display: index === 2 ? '' : 'none'}}
        control={control2}
        errors={errors2}
        labelOptionsState={labelOptionsState}
        watch={watch2}
        dayList={dayList}
      />
    </div>
  )
}
export default SequencesCreate
