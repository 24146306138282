/* eslint-disable jsx-a11y/media-has-caption */
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {VmoButton, VmoTabPane, VmoTab, VmoCardContent, VmoCard, VmoTooltip, VmoAttachment, VmoIcon} from 'vmo-library'
import parse from 'html-react-parser'
import {FiImage} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {EditorState, getDefaultKeyBinding, Modifier, ContentState} from 'draft-js'
import 'draft-js/dist/Draft.css'
import Editor from '@draft-js-plugins/editor'
import createEmojiPlugin from '@draft-js-plugins/emoji'
import '@draft-js-plugins/emoji/lib/plugin.css'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import createMentionPlugin, {defaultSuggestionsFilter} from '@draft-js-plugins/mention'
import '@draft-js-plugins/mention/lib/plugin.css'
import {stateToHTML} from 'draft-js-export-html'
import {Mention, MentionsInput} from 'react-mentions'
import {ADD_MESSAGE_TO_CONVERSATION, CONVERSATION_AUDIO_UPLOAD, SEND_NOTE} from 'actions/types'
import {extractTextContentFromHTML, getFormattedTime} from 'utils/helper'
import {getCannedResposeList} from 'actions/settings_productivity'
import {
  addMessageToConversation,
  audioUpload,
  editMessage,
  sendNote,
  updateConversationStatus,
  uploadChatAttachment,
} from 'actions/inbox'
import classNames from 'styles/example.module.css'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from '@draft-js-plugins/buttons'
import {GetAdvancedWidget, GetInstallationWidget} from 'actions/settings_messenger'
import {MdKeyboardVoice, MdOutlinePause} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'

const emojiPlugin = createEmojiPlugin()
const {EmojiSuggestions, EmojiSelect} = emojiPlugin
const mentionPlugin = createMentionPlugin({
  mentionTrigger: ['/'],
})
const {MentionSuggestions} = mentionPlugin

export const ChatMessageFooter = props => {
  const {
    editorState,
    setEditorState,
    setEditMsgId,
    editMsgId,
    setActiveMsgTab,
    activeMsgTab,
    conversationId,
    CurrentStatusOfConversation,
    getTeammatesForSuggesions,
    getTeammatesExcludingMe,
    messageId,
    isReplyingQuote,
    setIsReplyingQuote,
    replyingQuoteMsg,
    setReplyingQuoteMsg,
  } = props

  const dispatch = useDispatch()
  const editor = useRef(null)

  const [notes, setNotes] = useState('')
  const [mentionedTeammates, setmentionedTeamamtes] = useState([])
  const [fileUpload, setFileUpload] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [open, setOpen] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [recordedUrl, setRecordedUrl] = useState('')
  const [recordingState, setRecordingState] = useState('idle') // "idle", "recording"
  const [recordingTime, setRecordingTime] = useState(0)
  const [recordedBlob, setRecordedBlob] = useState(null)
  const mediaStream = useRef(null)
  const mediaRecorder = useRef(null)
  const chunks = useRef([])
  const timerRef = useRef(null)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {statusList = []} = useSelector(state => state.emailInbox)
  const {cannedResponseList = []} = useSelector(store => store.settingsProductivity)
  const {advanced = {}, installation} = useSelector(state => state.settingsMessenger)

  useEffect(() => {
    dispatch(GetInstallationWidget())
  }, [dispatch])

  useEffect(() => {
    if (installation && installation[0]) dispatch(GetAdvancedWidget(installation[0].id))
  }, [dispatch, installation])

  useEffect(() => {
    dispatch(getCannedResposeList())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(CONVERSATION_AUDIO_UPLOAD)) {
      setIsRecording(false)
      setRecordedUrl('')
      setRecordingState('idle')
      setRecordingTime(0)
      mediaStream.current = null
      mediaRecorder.current = null
      chunks.current = []
      timerRef.current = null
    }
  }, [successLabels])

  const getHtml = () => {
    return stateToHTML(editorState.getCurrentContent())
  }
  const getContent = () => {
    return extractTextContentFromHTML(stateToHTML(editorState.getCurrentContent()))
  }
  const checkEditorStateEmpty = () => {
    return editorState.getCurrentContent().hasText()
  }
  const setEditorClear = () => {
    const editorStateaa = EditorState.push(editorState, ContentState.createFromText(''))
    return setEditorState(EditorState.moveFocusToEnd(editorStateaa))
  }

  const [plugins, InlineToolbar] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin()
    return [[mentionPlugin, emojiPlugin, inlineToolbarPlugin], inlineToolbarPlugin.InlineToolbar]
  }, [])

  const getStatusId = status => {
    return statusList.find(stat => stat.status === status).id
  }

  function Entry(props) {
    const {mention, theme, searchValue, isFocused, ...parentProps} = props

    return (
      <div {...parentProps}>
        <div className="MentionSuggestionsEntryContainer">
          <div className="MentionSuggestionsEntryContainerRight">
            <div className="MentionSuggestionsEntryTitle">{mention.title}</div>
            <div className="MentionSuggestionsEntryText">{mention.name}</div>
          </div>
        </div>
      </div>
    )
  }

  function myKeyBindingFn(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      /* `shift + enter` key */
      return 'shift_enter_command'
    }

    return getDefaultKeyBinding(e)
  }

  const sendMessageData = () => {
    if (conversationId) {
      if (Number(editMsgId) && getContent().trim()) {
        dispatch(
          editMessage(editMsgId, {
            messageHtml: getHtml(),
            messageText: getContent(),
          })
        )
        setEditMsgId(null)
        setEditorClear()
      } else if (fileUpload && getContent().trim()) {
        const data = {
          conversationId,
          fileUpload,
          messageHtml: getHtml(),
          messageText: getContent(),
        }
        dispatch(uploadChatAttachment(data))
        setFileUpload(null)
        setEditorClear()
      } else if (fileUpload) {
        const data = {
          fileUpload,
          conversationId,
        }
        dispatch(uploadChatAttachment(data))
        setFileUpload(null)
      } else if (getContent().trim()) {
        const data = {
          messageHtml: getHtml(),
          messageText: getContent(),
        }
        if (isReplyingQuote && replyingQuoteMsg?.id) {
          data.parentMessageId = replyingQuoteMsg.id
        }
        dispatch(addMessageToConversation(data, conversationId))
        setEditorClear()
      }
    }
  }

  const onConversationStatusChange = statusTo => {
    const data = {
      statusId: statusTo,
      conversationIds: conversationId,
    }

    dispatch(updateConversationStatus(data, 'Status'))
  }

  const convertNote = noteToMessage => {
    let note = noteToMessage
    if (noteToMessage !== '') {
      note = note.split('@@@').join('<a>')
      note = note.split('@@^^^').join('</a> ')
      return note
    }
  }

  const cannedRespoList = () => {
    if (cannedResponseList) {
      const list = cannedResponseList.map(response => {
        return {
          id: response.id,
          title: response.name,
          name: response.message,
        }
      })
      return list
    }
    return []
  }

  useEffect(() => {
    if (cannedResponseList && cannedResponseList.length > 0) {
      setSuggestions(cannedRespoList())
    }
  }, [cannedResponseList])

  const onSearchChange = ({value}) => {
    setSuggestions(defaultSuggestionsFilter(value, cannedRespoList()))
  }

  const sendNoteData = e => {
    e.preventDefault()
    const data = {
      messageId,
      mentions: mentionedTeammates,
    }

    if (notes.replace(/^\s+|\s+$/g, '')) {
      data.note = convertNote(notes)

      dispatch(sendNote(data))
      setNotes('')
    }
  }

  const onMentionAdd = id => {
    if (id === -1) setmentionedTeamamtes(getTeammatesExcludingMe().map(mate => mate.id))
    else setmentionedTeamamtes([...mentionedTeammates, id])
  }

  const parseHtmlMsg = html => {
    if (!html) return ''
    return parse(html)
  }

  const EditorTab = [
    {
      menuItem: 'Reply',
      render: () => (
        <VmoTabPane attached={false} className="m-0">
          <div className="edtrBody">
            <div style={{marginTop: '-15px'}}>
              <EmojiSuggestions />
              <Editor
                ref={editor}
                editorState={editorState}
                plugins={plugins}
                onChange={editorState => setEditorState(editorState)}
                placeholder="Type here..."
                handleKeyCommand={command => {
                  if (command === 'shift_enter_command') {
                    sendMessageData()
                    // write here
                    if (CurrentStatusOfConversation.status !== 'open') onConversationStatusChange(getStatusId('Open'))
                  }
                }}
                keyBindingFn={myKeyBindingFn}
              />
              <MentionSuggestions
                open={open}
                onOpenChange={() => setOpen(!open)}
                onSearchChange={onSearchChange}
                suggestions={suggestions}
                onAddMention={() => {}}
                entryComponent={Entry}
              />
              {/* <InlineToolbar /> */}
              <InlineToolbar>
                {externalProps => (
                  <>
                    <BoldButton {...externalProps} />
                    <ItalicButton {...externalProps} />
                    <UnderlineButton {...externalProps} />
                    <CodeButton {...externalProps} />
                    <UnorderedListButton {...externalProps} />
                    <OrderedListButton {...externalProps} />
                    <BlockquoteButton {...externalProps} />
                  </>
                )}
              </InlineToolbar>
            </div>
            <div className="sendMsgBtn">
              {editMsgId && checkEditorStateEmpty() && (
                <VmoButton
                  basic
                  className="m-0"
                  onClick={() => {
                    setEditMsgId(null)
                    setEditorClear()
                  }}
                >
                  Cancel
                </VmoButton>
              )}
              <VmoButton
                basic
                className="mr-0 ml-3"
                disabled={
                  (isLoadingData && type.includes(ADD_MESSAGE_TO_CONVERSATION)) ||
                  (!checkEditorStateEmpty() && !fileUpload)
                }
                onClick={e => {
                  if (CurrentStatusOfConversation?.status !== 'closed') {
                    onConversationStatusChange(getStatusId('Closed'))
                  }
                  if (CurrentStatusOfConversation?.status !== 'open') {
                    onConversationStatusChange(getStatusId('Open'))
                  }
                  sendMessageData(e)
                }}
              >
                {CurrentStatusOfConversation?.status !== 'closed' ? 'Send & Close' : 'Send & Open'}
              </VmoButton>
              {CurrentStatusOfConversation?.status !== 'closed' && (
                <VmoButton
                  className="mr-0 ml-3"
                  disabled={
                    (isLoadingData && type.includes(ADD_MESSAGE_TO_CONVERSATION)) ||
                    (!checkEditorStateEmpty() && !fileUpload)
                  }
                  primary
                  onClick={sendMessageData}
                >
                  Send
                </VmoButton>
              )}
            </div>
          </div>
        </VmoTabPane>
      ),
    },
    {
      menuItem: 'Note',
      render: () => (
        <VmoTabPane attached={false}>
          <div className="edtrNoteBody">
            <MentionsInput
              placeholder="Type note here or @ to mention your teammate"
              className="addNoteTextarea"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              allowSuggestionsAboveCursor
              onKeyPress={e => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  sendNoteData(e)
                } else if (e.key === 'Enter') {
                  setNotes(`${notes}\n`)
                } else {
                  setNotes(notes)
                }
              }}
            >
              <Mention
                trigger="@"
                markup="@@@__display__@@^^^"
                data={getTeammatesForSuggesions()}
                renderSuggestion={suggetion => (
                  <div key={suggetion.id}>
                    {suggetion?.avatar}
                    {suggetion?.display}
                  </div>
                )}
                className={classNames.mentions__mention}
                appendSpaceOnAdd
                onAdd={onMentionAdd}
                mode="immutable"
              />
            </MentionsInput>
            <div className="sendMsgBtn">
              <VmoButton
                primary
                className="mr-0"
                disabled={(isLoadingData && type.includes(SEND_NOTE)) || notes === ''}
                onClick={sendNoteData}
              >
                Send
              </VmoButton>
            </div>
          </div>
        </VmoTabPane>
      ),
    },
  ]

  useEffect(() => {
    if (recordingState === 'recording') {
      timerRef.current = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1)
      }, 1000)
    }
    return () => clearInterval(timerRef.current)
  }, [recordingState])

  const startRecording = async () => {
    try {
      setRecordingTime(0)
      const stream = await navigator.mediaDevices.getUserMedia({audio: true})
      mediaStream.current = stream
      mediaRecorder.current = new MediaRecorder(stream)
      mediaRecorder.current.ondataavailable = e => {
        if (e.data.size > 0) {
          chunks.current.push(e.data)
        }
      }
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, {type: 'audio/webm'})
        const url = URL.createObjectURL(recordedBlob)
        setRecordedBlob(recordedBlob)
        setRecordedUrl(url)
        chunks.current = []
      }
      mediaRecorder.current.start()
      setRecordingState('recording')
    } catch (error) {
      console.error('Error accessing microphone:', error)
    }
  }

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop()
      setRecordingState('idle')
      // setRecordingTime(0)
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach(track => {
        track.stop()
      })
    }
  }

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  const sendAudioMessage = () => {
    const formData = new FormData()
    formData.append('audio', recordedBlob)
    formData.append('conversationId', conversationId)
    dispatch(audioUpload(formData))
  }

  if (isRecording) {
    return (
      <div className="voice-recording-container">
        <div className="d-flex">
          <div className="voice-recording-controls">
            <div className="d-flex" style={{width: '100%'}}>
              <div className="d-flex">
                <VmoButton
                  icon="close"
                  onClick={() => {
                    setIsRecording(false)
                    setRecordedUrl('')
                    setRecordingState('idle')
                    setRecordingTime(0)
                    mediaStream.current = null
                    mediaRecorder.current = null
                    chunks.current = []
                    timerRef.current = null
                  }}
                />
                {recordingState === 'idle' && <audio controls src={recordedUrl} className="recording-audio" />}
                {recordingState === 'recording' && (
                  <>
                    <div className="recording-dot" />
                    <p className="recording-text">Recording...</p>
                  </>
                )}
              </div>
              <p className="recording-time">{formatTime(recordingTime)}</p>
            </div>
            {recordingState === 'recording' && (
              <VmoButton icon className="voice-pause" onClick={stopRecording}>
                <MdOutlinePause />
              </VmoButton>
            )}
            {recordingState === 'idle' && (
              <VmoButton icon className="voice-play" onClick={startRecording}>
                <MdKeyboardVoice />
              </VmoButton>
            )}
          </div>
          <VmoButton primary disabled={recordingState === 'recording'} onClick={sendAudioMessage}>
            Send
          </VmoButton>
        </div>
      </div>
    )
  }

  return (
    <div className="write-msg">
      {isReplyingQuote && (
        <div className="quote-message-container">
          <div className="quote-message-content">
            <div className="quote-message-left-bar" />
            <div className="quote-message-right-bar">
              <p>
                {replyingQuoteMsg?.messageHtml
                  ? parseHtmlMsg(replyingQuoteMsg.messageHtml)
                  : extractTextContentFromHTML(replyingQuoteMsg.messageText)}
              </p>

              <div className="quote-message-right-bar-bottom">
                <p>
                  {replyingQuoteMsg?.from?.name ||
                    replyingQuoteMsg?.from?.agentName ||
                    replyingQuoteMsg.from?.username ||
                    '-'}
                  <span className="ml-2">{getFormattedTime(replyingQuoteMsg.createdAt)}</span>
                </p>
              </div>
            </div>
            <SvgIcon
              path="common/close"
              className="quote-message-close-icon"
              onClick={() => {
                setIsReplyingQuote(false)
                setReplyingQuoteMsg(null)
              }}
            />
          </div>
        </div>
      )}
      <VmoCard fluid>
        <VmoCardContent>
          <div className="buttonsGroup">
            {advanced?.enableVoiceMessage && (
              <VmoTooltip
                content="Voice Recording"
                size="mini"
                position="top center"
                trigger={
                  <VmoButton
                    icon
                    onClick={() => {
                      startRecording()
                      setIsRecording(true)
                    }}
                  >
                    <SvgIcon path="inbox/voice-recorder" />
                  </VmoButton>
                }
              />
            )}

            <VmoTooltip
              content="Insert Image"
              trigger={
                <VmoAttachment
                  title=""
                  content={<FiImage />}
                  onChange={value => {
                    if (value.length > 0) {
                      setFileUpload(value[0])
                    }
                    value = null
                  }}
                  onClick={e => (e.target.value = null)}
                  accept="image/*"
                  maxSize={3072}
                  maxHeight={3072}
                />
              }
              size="mini"
              position="top center"
            />
            <VmoTooltip
              content="Insert Attachment"
              trigger={
                <VmoAttachment
                  title=""
                  content={<SvgIcon path="common/attachment" />}
                  onChange={value => {
                    if (value.length > 0) {
                      setFileUpload(value[0])
                    }
                    value = null
                  }}
                  onClick={e => (e.target.value = null)}
                  maxSize={20480}
                  maxHeight={20480}
                />
              }
              size="mini"
              position="top center"
            />
            <EmojiSelect />
          </div>
          {fileUpload && (
            <div className="attachPreview attachPreview-chat d-flex">
              <h5>
                {fileUpload.name}
                <span>({`${Math.round(fileUpload.size / 1024)}kb`})</span>
              </h5>

              <VmoButton icon className="m-0">
                <SvgIcon path="common/close" onClick={() => setFileUpload(null)} />
              </VmoButton>
            </div>
          )}
          <VmoTab
            menu={{color: 'blue', secondary: true, pointing: true}}
            panes={EditorTab}
            className="chatinbox"
            activeIndex={activeMsgTab}
            onTabChange={(e, data) => setActiveMsgTab(data.activeIndex)}
          />
        </VmoCardContent>
      </VmoCard>
    </div>
  )
}
