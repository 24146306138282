import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {getUserInfo} from 'utils/local-storage'
import {createProjectsTasksList, getProjectsSingleTasksList, updateProjectsTasksList} from 'actions/projects'
import {get, removeDoubleQuotes} from 'utils/helper'
import {CREATE_PROJECT_TASKS_LIST, GET_PROJECTS_SINGLE_TASKS_LIST, UPDATE_PROJECT_TASKS_LIST} from 'actions/types'
import {taskListSchema} from 'validation/Projects/projects/tasks.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const taskListDefaultValue = {
  title: '',
  // workflowId: null,
  subscribers: [Number(getUserInfo().id)],
}

const TaskListModal = ({open, toggle, projectId, taskListId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(taskListSchema),
    defaultValues: taskListDefaultValue,
  })

  useApiResponse({
    action: getProjectsSingleTasksList,
    payload: {projectId, taskListId},
    dependency: [projectId, taskListId],
    enabled: projectId && taskListId,
    label: GET_PROJECTS_SINGLE_TASKS_LIST,
    storePath: 'projects.tasksListData',
    onSuccess: (tasksListData = {}) => {
      const data = {
        title: get(['title'], tasksListData, ''),
        // workflowId: get(['workflowId'], tasksListData, null),
        subscribers: get(['subscribers'], tasksListData, []),
      }
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_TASKS_LIST) || successLabels.includes(UPDATE_PROJECT_TASKS_LIST)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (taskListId) {
      dispatch(updateProjectsTasksList({projectId, taskListId, data}))
    } else {
      dispatch(createProjectsTasksList({projectId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{taskListId ? 'Edit List' : 'Add List'}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Title</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify title of the task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Assignee</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Assignee agents for this particular task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="subscribers"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  multiple
                  options={projectAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {taskListId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TaskListModal
