/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, useFieldArray, Controller} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {Ref} from 'semantic-ui-react'
import _ from 'lodash'
import Joi from 'joi'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {CREATE_PIPELINE, DELETE_PIPELINE, GET_ALL_PIPELINES, UPDATE_PIPELINE} from 'actions/types'
import {deletePipelines, getPipelines, updatePipeline} from 'actions/deals'
import SettingLayout from 'layouts/settingLayout'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import {getPermissionTooltipContent} from 'utils/helper'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import CloneDealModal from './CloneDealModal'
import CreateDealModal from './CreateDealModal'
import DeletePipelineModal from './DeletePipelineModal'

const Deals = props => {
  const [selectedPipeline, setSelectedPipeline] = useState(null)
  const {errors, control, handleSubmit, reset} = useForm({
    resolver: joiResolver(
      Joi.object({
        pipeline: Joi.string().trim().min(4).max(30).required().messages({
          'string.empty': `Pipeline cannot be an empty field`,
          'string.min': `Pipeline name should have a minimum length of 4 characters`,
          'string.max': `Pipeline name must be less or equal to 30 characters`,
        }),
        stages: Joi.array()
          .items(
            Joi.object({
              id: Joi.any(),
              stage: Joi.string().trim().required().messages({
                'string.empty': `Name must be a required field`,
              }),
              win: Joi.number(),
              sequenceNo: Joi.number().required(),
            })
          )
          .required()
          .min(1),
      })
    ),
    defaultValues: {pipeline: '', stages: []},
  })

  const {fields, append, remove, swap} = useFieldArray({
    control,
    name: 'stages',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const dealsLearnMore = settingsLearnMoreUrl?.dataManagement?.customization?.deals?.learnMore

  const dispatch = useDispatch()
  const [createDeal, setCreateDeal] = useState(false)
  const [cloneDeal, setCloneDeal] = useState(false)
  const [deleteDeal, setDeleteDeal] = useState(false)

  const {generalAccess, dealsPermissions} = useUserPermissions()
  const dataManageCustomizationAccess = generalAccess?.dataManageCustomization
  const managePipelinePermissions = dealsPermissions?.manage_pipeline

  const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'rgb(30 144 255 / 24%)' : '',
  })

  const {data: getAllPipelineData = []} = useApiResponse({
    action: getPipelines,
    dependency: [dataManageCustomizationAccess],
    enabled: dataManageCustomizationAccess,
    label: GET_ALL_PIPELINES,
    storePath: 'deals.getAllPipelineData',
  })

  const resetPipelines = (selectedId, list) => {
    const selected = list.find(pipe => pipe.id === selectedId)
    reset({
      pipeline: selected.pipeline,
      stages: selected.stages || [],
    })
  }

  const onDragEnd = result => {
    const {destination, source} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    swap(destination.index, source.index)
  }

  useEffect(() => {
    if (selectedPipeline) {
      resetPipelines(selectedPipeline, getAllPipelineData)
    }
  }, [selectedPipeline])

  useEffect(() => {
    if (successLabels.includes(CREATE_PIPELINE)) {
      dispatch(getPipelines())
      setCreateDeal(false)
    }
    if (successLabels.includes(DELETE_PIPELINE)) {
      dispatch(getPipelines())
      setSelectedPipeline(null)
      setDeleteDeal(false)
    }
    if (successLabels.includes(UPDATE_PIPELINE)) {
      dispatch(getPipelines())
    }
  }, [successLabels])

  if (getAllPipelineData.length === 0 || !dataManageCustomizationAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Deals"
          subHeader="Create and manage deals pipeline by setting win probability for each stages of deals"
          buttonText="Create"
          addFirst={() => dataManageCustomizationAccess && managePipelinePermissions && setCreateDeal(true)}
          tooltip={!dataManageCustomizationAccess || !managePipelinePermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: dataManageCustomizationAccess,
            roleAccess: managePipelinePermissions,
          })}
          img={create}
          list1="list1"
          list2="list2"
          list3="list3"
        />
        {createDeal && (
          <CreateDealModal createDeal={createDeal} setCreateDeal={() => setCreateDeal(!createDeal)} {...props} />
        )}
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/deals" />}
      header="Deals"
      subHeader="Create and manage deal stages for better sales visualization"
      learnMoreUrl={dealsLearnMore}
      table={false}
      headerButton={{
        onClick: () => managePipelinePermissions && setCreateDeal(!createDeal),
        content: 'Create',
      }}
      headerDivider
      lockRole={!managePipelinePermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormGroup
          style={{
            width: '100%',
            display: 'inline-flex',
            justifyContent: 'space-between',
          }}
        >
          <VmoFormField width={5}>
            <div className="info-header">
              <label>Select a Pipeline</label>
              <VmoTooltip
                content="Select a pipeline for which you want to add deal stages"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <VmoDropdown
              selection
              placeholder="Select pipeline"
              options={getAllPipelineData.map(pipeline => ({
                key: pipeline.id,
                text: pipeline.pipeline,
                value: pipeline.id,
              }))}
              value={selectedPipeline}
              onChange={(e, {value}) => setSelectedPipeline(value)}
            />
          </VmoFormField>
          {selectedPipeline && (
            <VmoFormField width={3}>
              <VmoDropdown
                button
                icon={<SvgIcon path="common/more-verticle" />}
                floating
                className="icon bg-btn"
                style={{marginTop: '27px'}}
              >
                <VmoDropdownMenu direction="left">
                  {/* <VmoDropdownItem onClick={() => setCloneDeal(!cloneDeal)}>Clone pipeline</VmoDropdownItem> */}
                  <LockPermissionTooltip isRoleAccessDenied={!managePipelinePermissions}>
                    <VmoDropdownItem onClick={() => managePipelinePermissions && setDeleteDeal(!deleteDeal)}>
                      Delete pipeline
                    </VmoDropdownItem>
                  </LockPermissionTooltip>
                </VmoDropdownMenu>
              </VmoDropdown>
            </VmoFormField>
          )}
        </VmoFormGroup>
        {selectedPipeline && (
          <VmoFormField width={8} required>
            <label>Name</label>
            <Controller
              name="pipeline"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  placeholder="Stage Name"
                  onChange={(e, {value}) => onChange(value)}
                  onBlur={onBlur}
                  error={
                    errors.pipeline && {
                      content: errors.pipeline.message,
                    }
                  }
                  disabled={!managePipelinePermissions}
                />
              )}
              control={control}
            />
          </VmoFormField>
        )}
      </VmoForm>

      {selectedPipeline && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <VmoTable basic className="my-4">
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell style={{paddingLeft: '28px'}}>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Win Probability</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Action</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <Droppable droppableId="tableBody">
                {provided => (
                  <Ref innerRef={provided.innerRef}>
                    <VmoTableBody {...provided.droppableProps}>
                      {fields.map((items, index) => (
                        <Draggable
                          draggableId={`${items.id}`}
                          index={index}
                          key={items.id}
                          isDragDisabled={!managePipelinePermissions}
                        >
                          {(provided, snapshot) => (
                            <Ref innerRef={provided.innerRef}>
                              <VmoTableRow
                                key={items.id}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                              >
                                <VmoTableCell>
                                  <div className="DragBtnShow">
                                    <SvgIcon path="common/move" />
                                    <Controller
                                      render={({value}) => <input hidden value={value} />}
                                      name={`stages[${index}].sequenceNo`}
                                      control={control}
                                      defaultValue={items.sequenceNo} // make sure to set up defaultValue
                                    />
                                    <Controller
                                      render={({value}) => <input hidden value={value} />}
                                      name={`stages[${index}].id`}
                                      control={control}
                                      defaultValue={items.id} // make sure to set up defaultValue
                                    />
                                  </div>
                                </VmoTableCell>

                                <VmoTableCell>
                                  <Controller
                                    render={({value, onChange}) => (
                                      <VmoFormInput
                                        value={value}
                                        onChange={e => onChange(e.target.value)}
                                        disabled={!managePipelinePermissions}
                                        error={
                                          errors.stages &&
                                          errors.stages[index] && {
                                            content: errors.stages[index].stage.message,
                                          }
                                        }
                                      />
                                    )}
                                    name={`stages[${index}].stage`}
                                    control={control}
                                    defaultValue={items.stage} // make sure to set up defaultValue
                                  />
                                </VmoTableCell>
                                <VmoTableCell>
                                  <Controller
                                    render={({value, onChange}) => (
                                      <VmoFormDropdown
                                        selection
                                        options={[
                                          {
                                            id: 1,
                                            text: '10%',
                                            value: 10,
                                          },
                                          {
                                            id: 2,
                                            text: '20%',
                                            value: 20,
                                          },
                                          {
                                            id: 3,
                                            text: '30%',
                                            value: 30,
                                          },
                                          {
                                            id: 4,
                                            text: '40%',
                                            value: 40,
                                          },
                                          {
                                            id: 5,
                                            text: '50%',
                                            value: 50,
                                          },
                                          {
                                            id: 6,
                                            text: '60%',
                                            value: 60,
                                          },
                                          {
                                            id: 7,
                                            text: '70%',
                                            value: 70,
                                          },
                                          {
                                            id: 8,
                                            text: '80%',
                                            value: 80,
                                          },
                                          {
                                            id: 9,
                                            text: '90%',
                                            value: 90,
                                          },
                                        ]}
                                        onChange={(e, {value}) => {
                                          onChange(value)
                                        }}
                                        value={value}
                                        disabled={!managePipelinePermissions}
                                      />
                                    )}
                                    name={`stages[${index}].win`}
                                    control={control}
                                    defaultValue={items.win}
                                  />
                                </VmoTableCell>
                                <VmoTableCell>
                                  {/* <VmoButton icon>
                                    <FiCode />
                                  </VmoButton> */}
                                  <VmoTooltip
                                    content={
                                      managePipelinePermissions
                                        ? 'Delete'
                                        : getPermissionTooltipContent({roleAccess: managePipelinePermissions})
                                    }
                                    size="mini"
                                    position="top center"
                                    trigger={
                                      <VmoButton
                                        className="bg-btn"
                                        disabled={!(_.size(fields) > 3)}
                                        icon
                                        onClick={() => managePipelinePermissions && remove(index)}
                                      >
                                        <SvgIcon path="common/delete" />
                                      </VmoButton>
                                    }
                                  />
                                </VmoTableCell>
                              </VmoTableRow>
                            </Ref>
                          )}
                        </Draggable>
                      ))}

                      {selectedPipeline && _.size(fields) <= 15 && (
                        <VmoTableRow>
                          <VmoTableCell>
                            <LockPermissionTooltip isRoleAccessDenied={!managePipelinePermissions}>
                              <VmoButton
                                size="tiny"
                                className={`bg-btn ${!managePipelinePermissions ? 'disabled-button' : ''}`}
                                primary
                                onClick={() =>
                                  managePipelinePermissions &&
                                  append({
                                    id: null,
                                    stage: '',
                                    sequenceNo: fields.length,
                                    win: 10,
                                  })
                                }
                              >
                                Add a Deal Stage
                              </VmoButton>
                            </LockPermissionTooltip>
                          </VmoTableCell>
                        </VmoTableRow>
                      )}
                    </VmoTableBody>
                  </Ref>
                )}
              </Droppable>
            </VmoTable>
          </DragDropContext>

          <VmoButton basic className="mr-3" onClick={() => resetPipelines(selectedPipeline, getAllPipelineData)}>
            Cancel
          </VmoButton>
          {/* <VmoButton negative className="mx-4">
          Delete Board
        </VmoButton> */}
          <LockPermissionTooltip isRoleAccessDenied={!managePipelinePermissions}>
            <VmoButton
              primary
              className={!managePipelinePermissions ? 'disabled-button' : ''}
              {...(managePipelinePermissions && {
                onClick: handleSubmit(async data => {
                  await data.stages.map((el, index) => {
                    el.sequenceNo = index + 100
                  })
                  dispatch(updatePipeline(selectedPipeline, data))
                }),
              })}
            >
              Save
            </VmoButton>
          </LockPermissionTooltip>
        </>
      )}

      {createDeal && (
        <CreateDealModal createDeal={createDeal} setCreateDeal={() => setCreateDeal(!createDeal)} {...props} />
      )}
      {cloneDeal && <CloneDealModal cloneDeal={cloneDeal} setCloneDeal={() => setCloneDeal(!cloneDeal)} {...props} />}
      {deleteDeal && (
        <DeletePipelineModal
          deleteDeal={deleteDeal}
          setDeleteDeal={() => setDeleteDeal(!deleteDeal)}
          onDelete={() => dispatch(deletePipelines(selectedPipeline))}
          {...props}
        />
      )}
    </SettingLayout>
  )
}
export default Deals
