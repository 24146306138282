import {ANALYTICS_ENDPOINT} from './endpoint'
import {apiAction} from './api'
import {
  INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS,
  TOTAL_INVOICES,
  INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS,
  NUMBER_OF_INVOICES_BY_USERS,
  AMOUNT_OF_INVOICES_BY_USERS,
  INVOICES_COUNT_OVERTIME,
  INVOICES_AMOUNT_OVERTIME,
} from './types'

export function totalInvoices(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/total-invoices`,
    method: 'POST',
    label: TOTAL_INVOICES,
    data,
  })
}

export function invoiceNumber(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/invoice-number-breakdown-by-products`,
    method: 'POST',
    label: INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function invoiceAmount(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/invoice-amount-breakdown-by-products`,
    method: 'POST',
    label: INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS,
    data,
  })
}

export function invoiceNumberByUser(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/number-of-invoices-by-users`,
    method: 'POST',
    label: NUMBER_OF_INVOICES_BY_USERS,
    data,
  })
}

export function invoiceAmountByUser(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/amount-of-invoices-by-users`,
    method: 'POST',
    label: AMOUNT_OF_INVOICES_BY_USERS,
    data,
  })
}

export function invoiceCountOvertime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/invoices-count-overtime`,
    method: 'POST',
    label: INVOICES_COUNT_OVERTIME,
    data,
  })
}

export function invoiceAmountOvertime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/invoices/invoices-amount-overtime`,
    method: 'POST',
    label: INVOICES_AMOUNT_OVERTIME,
    data,
  })
}
