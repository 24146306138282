export const INBOX_TYPES = {
  EMAIL: 'email',
  WEB: 'web',
}

export const CAMPAIGN_TYPES = {
  MESSAGE: '1',
  EMAIL: '3',
  SMS: 'sms_campaign',
}
