/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {FaBriefcase} from 'react-icons/fa'
import create from 'assets/create.svg'
import _ from 'lodash'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {deleteAnnouncement, getAllAnnouncements} from 'actions/Announcement'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import {DELETE_ANNOUNCEMENT, GET_ALL_ANNOUNCEMENT} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {getFormattedDate, getFormattedTime, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import AnnouncementSidemenu from './AnnouncementSidemenu'

const Announcements = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_ANNOUNCEMENT})

  const {data: getAllAnnouncementList = []} = useApiResponse({
    action: getAllAnnouncements,
    enabled: true,
    label: GET_ALL_ANNOUNCEMENT,
    storePath: 'announcement.getAllAnnouncementList',
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllAnnouncements(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_ANNOUNCEMENT)) {
      dispatch(getAllAnnouncements())
    }
  }, [successLabels, dispatch])

  const renderTableBody = () => {
    return getAllAnnouncementList.map(({id, title, createdBy, scheduledDateTime, unpublishDate}, index) => {
      return (
        <VmoTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/announcements/update/${id}`)
          }}
        >
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoHeader as="h5">{startCase(title)}</VmoHeader>
          </VmoTableCell>
          {/* <VmoTableCell /> */}
          <VmoTableCell>{startCase(createdBy.name)}</VmoTableCell>
          <VmoTableCell>
            {scheduledDateTime ? `${getFormattedDate(scheduledDateTime)} ${getFormattedTime(scheduledDateTime)}` : '-'}
          </VmoTableCell>
          <VmoTableCell>{unpublishDate ? getFormattedDate(unpublishDate) : '-'}</VmoTableCell>
          {/* <VmoTableCell />
          <VmoTableCell /> */}
          <VmoTableCell>
            <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <VmoDropdownMenu>
                <VmoDropdownItem
                  onClick={() => {
                    setEditId(id)
                    setDeleteModal(true)
                  }}
                >
                  <SvgIcon path="common/delete" /> Delete
                </VmoDropdownItem>
              </VmoDropdownMenu>
            </VmoDropdown>
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllAnnouncementList?.length > 0 || currentListParams.text ? (
        <>
          <AnnouncementSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Announcement ({getAllAnnouncementList ? getAllAnnouncementList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <VmoButton
                  content="Create Announcement"
                  primary
                  onClick={() => history.push('/announcements/create')}
                />
              </div>
            </div>

            <VmoTable basic>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Title</VmoTableHeaderCell>
                  {/* <VmoTableHeaderCell>Type</VmoTableHeaderCell> */}
                  <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Published Date</VmoTableHeaderCell>
                  <VmoTableHeaderCell>UnPublished Date</VmoTableHeaderCell>
                  {/* <VmoTableHeaderCell>Acknowledged</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Pending</VmoTableHeaderCell> */}
                  <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_ALL_ANNOUNCEMENT) ? (
                  <VmoTablePlaceholder columns={9} />
                ) : (getAllAnnouncementList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={9} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </VmoTableBody>
            </VmoTable>

            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteAnnouncement}
                idTobeDeleted={editId}
                type={DELETE_ANNOUNCEMENT}
              />
            )}
          </div>
        </>
      ) : (
        <CreateFirst
          src={<FaBriefcase />}
          header="Announcement"
          subHeader="Announcements helps to communicate important updates or messages to employees within the organization"
          addFirst={() => history.push('/announcements/create')}
          buttonText="Create New Announcement"
          Feature1="Announcement Creation"
          Feature2="Target Audience"
          Feature3="Announcement Scheduling"
          list1="Create announcements with customizable content"
          list2="Set target audience for each announcement"
          list3="Schedule your announcement for the perfect time"
          imageSrc={create}
        />
      )}
    </>
  )
}

export default Announcements
