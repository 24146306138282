import React from 'react'
import {
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormSelect,
  VmoRadio,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {Controller} from 'react-hook-form'
import {removeDoubleQuotes} from 'utils/helper'
import {statusList} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'

const Settings = props => {
  const {control, errors, labelOptionsState, watch, dayList, style} = props
  const watchPauseOnOutOfEmail = watch('pauseOnOutOfEmail')
  const watchStopsOnTask = watch('stopsOnTaskStatus')

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h5 className="mb-0">General Settings</h5>
            <p className="mt-0 mb-4 card-description">Manage all general settings related to the sequence </p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField>
                <div className="d-flex mt-3">
                  <div>
                    <label>Enable Threading</label>
                    <p className="kooper-lead">
                      Contact will receive sequence in a thread rather that separate conversation each time
                    </p>
                  </div>
                  <Controller
                    name="isSingleThread"
                    render={({onChange, value}) => (
                      <VmoRadio style={{float: 'right'}} checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Delay</label>
                  <VmoTooltip
                    content="Delay in sending between two sequence"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="delayBetweenEmails"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      className="mb-2"
                      width={8}
                      placeholder="Delay Between Emails"
                      value={value}
                      options={[
                        {value: 15, text: '15 seconds'},
                        {value: 30, text: '30 seconds'},
                        {value: 45, text: '45 seconds'},
                        {value: 60, text: '60 seconds'},
                      ]}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      search
                      onBlur={onBlur}
                      error={
                        errors.delayBetweenEmails && {
                          content: removeDoubleQuotes(errors.delayBetweenEmails.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField>
                <div className="info-header">
                  <label>Daily Limit</label>
                  <VmoTooltip
                    content="Daily limit for messages for this specific sequence"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="dailyLimit"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      className="mb-2"
                      width={8}
                      placeholder="Daily Limit"
                      value={value}
                      options={[
                        {value: 50, text: 50, key: 50},
                        {value: 100, text: 100, key: 100},
                        {value: 500, text: 500, key: 500},
                        {value: 1000, text: 1000, key: 1000},
                        // {
                        //   value: "contact us",
                        //   text: "Contact Us(if you want to increase the limit)",
                        //   key: "contact us",
                        // },
                      ]}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      search
                      onBlur={onBlur}
                      error={
                        errors.dailyLimit && {
                          content: removeDoubleQuotes(errors.dailyLimit.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
                <a style={{fontSize: '12px'}}>Contact us if you want to increase the limit</a>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Branding</label>
                    <p className="kooper-lead">Enable branding in this sequence messages</p>
                  </div>
                  <Controller
                    name="vmoBranding"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Add UnsubLink</label>
                    <p className="kooper-lead m-0">Add unsubscribe link at the end of sequence emails</p>
                  </div>
                  <Controller
                    name="addUnsubLink"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="info-header">
                  <label>Contact completes sequence, with no reply after in x days</label>
                  <VmoTooltip
                    content="When contact does not reply for below mentioned date, the sequence will be automatically completed"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="updateContactAfterDays"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      className="mb-2"
                      width={8}
                      placeholder="Update Contact After Days"
                      value={value}
                      options={dayList}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      search
                      onBlur={onBlur}
                      error={
                        errors.updateContactAfterDays && {
                          content: removeDoubleQuotes(errors.updateContactAfterDays.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid>
          <VmoCardContent>
            <h5 className="m-0">Out-Of-Office Settings</h5>
            <p className="card-description">Manage your out of office settings for this sequence</p>
            <VmoForm className="mt-3 errorLabel">
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Pause on out of office email?</label>
                  </div>
                  <Controller
                    name="pauseOnOutOfEmail"
                    render={({onChange, value}) => (
                      <VmoRadio style={{float: 'right'}} checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>

              {watchPauseOnOutOfEmail && (
                <VmoFormField>
                  <label> Auto-resume out of office email after x Days</label>
                  <Controller
                    name="resumeAfterDays"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        width={8}
                        placeholder="Resume After Days"
                        value={value}
                        options={dayList}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        search
                        onBlur={onBlur}
                        error={
                          errors.resumeAfterDays && {
                            content: removeDoubleQuotes(errors.resumeAfterDays.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              )}
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid>
          <VmoCardContent>
            <h5 className="m-0">Exit Criteria</h5>
            <p className="card-description mb-3">Manage your exit criterias for this sequence</p>
            <VmoForm className="mt-3 errorLabel">
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>When meeting is booked</label>
                    <p className="kooper-lead">When meeting is booked sequence will stop</p>
                  </div>
                  <Controller
                    name="stopsOnBookMeeting"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Stops on any email communication</label>
                    <p className="kooper-lead">When any contact replies back, stop the sequence</p>
                  </div>
                  <Controller
                    name="stopsOnEmail"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Stops on Text</label>
                    <p className="kooper-lead">When receive any text from contact sequence will stop</p>
                  </div>
                  <Controller
                    name="stopsOnText"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Stops on deal close</label>
                    <p className="kooper-lead">When deal is closed sequence will stop</p>
                  </div>
                  <Controller
                    name="stopsOnCloseDeal"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Stops On Task Status</label>
                    <p className="kooper-lead">Sequence will stop based on task status</p>
                  </div>
                  <Controller
                    name="stopsOnTaskStatus"
                    render={({onChange, value}) => (
                      <VmoRadio checked={value} onChange={() => onChange(!value)} toggle />
                    )}
                    control={control}
                  />
                </div>
                {watchStopsOnTask && (
                  <Controller
                    name="taskStatus"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        className="mt-2 mb-2"
                        width={8}
                        options={statusList.filter(value => value.value === 'closed' || value.value === 'on_hold')}
                        placeholder="Status"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.taskStatus && {
                            content: removeDoubleQuotes(errors.taskStatus.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                )}
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid>
          <VmoCardContent>
            <h5 className="m-0">Assign Tags</h5>
            <p className="card-description mb-3">Manage assign task settings for this sequence</p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField>
                <div className="info-header">
                  <label> Add Contacts labels</label>
                  <VmoTooltip
                    content="Lorem ipsum"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="addContactLabels"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      width={8}
                      options={labelOptionsState}
                      search
                      placeholder="Tags"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      multiple
                      onBlur={onBlur}
                      error={
                        errors.addContactLabels && {
                          content: removeDoubleQuotes(errors.addContactLabels.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label> Contact Unsubcribe Labels</label>
                  <VmoTooltip
                    content="Lorem ipsum"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="contactUnsubLabels"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      width={8}
                      options={labelOptionsState}
                      search
                      placeholder="Tags"
                      value={value}
                      multiple
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.contactUnsubLabels && {
                          content: removeDoubleQuotes(errors.contactUnsubLabels.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>First Step Labels</label>
                  <VmoTooltip
                    content="Lorem ipsum"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="firstStepLabels"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      width={8}
                      options={labelOptionsState}
                      search
                      placeholder="Tags"
                      value={value}
                      multiple
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.firstStepLabels && {
                          content: removeDoubleQuotes(errors.firstStepLabels.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </VmoTabPane>
  )
}

export default Settings
