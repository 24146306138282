/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoButton,
  VmoCheckbox,
  VmoDivider,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoIcon,
  VmoImage,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {GOOGLE_CALENDAR_DISCONNECT, MEETING_CALENDAR_LIST} from 'actions/types'
import {calendarList, calendarTwoWaySync, googleCalendarDisconnect} from 'actions/meeting'
import {MEETINGS_ENDPOINT} from 'actions/endpoint'
import img from 'assets/calender.svg'
import {get, getFormattedDate, getPermissionTooltipContent} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const GoogleDisconnectModal = props => {
  const {isModalOpen, setIsModalOpen, data} = props
  const [SecondModalOpen, setSecondModalOpen] = useState(false)
  const [checkboxAgreeDisconnect, setCheckboxAgreeDisconnect] = useState(false)
  const dispatch = useDispatch()
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const logout = () => {
    dispatch(googleCalendarDisconnect(data.calendarId))
  }

  useEffect(() => {
    if (successLabels.includes(GOOGLE_CALENDAR_DISCONNECT)) {
      setIsModalOpen()
    }
  }, [successLabels, setIsModalOpen])

  // const email = localStorage.getItem("GEMAIL");

  return (
    <>
      <VmoModal
        size="large"
        open={isModalOpen}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={setIsModalOpen}
      >
        <VmoHeader className="vmoModalHeader">Google Disconnect</VmoHeader>
        <VmoModalContent>
          <div className="createPage">
            <h2>Meetings</h2>
            <VmoGrid columns="equal">
              <VmoGridColumn>
                <div className="createPageInfo">
                  <p>
                    Calendar sync Calendar sync logs new meetings from your calendar to existing contacts in Kooper. You
                    can also create and send meeting invites from Kooper. This calendar setting is separate from the
                    meetings tool.
                  </p>
                  <p>{`${_.get(data, 'family_name', '') || ''}`}</p>
                  <p>{`Now connected to Google Calendar account ${_.get(data, 'email', '') || ''}`}</p>
                  <VmoButton negative onClick={() => setSecondModalOpen(!SecondModalOpen)}>
                    Disconnect
                  </VmoButton>
                </div>
              </VmoGridColumn>
              <VmoGridColumn>
                <VmoImage src={img} />
              </VmoGridColumn>
            </VmoGrid>
          </div>
        </VmoModalContent>
      </VmoModal>
      {SecondModalOpen && (
        <VmoModal
          size="tiny"
          open={SecondModalOpen}
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          onClose={setSecondModalOpen}
        >
          <VmoHeader className="vmoModalHeader">Warning</VmoHeader>
          <VmoModalContent>
            <VmoForm>
              <VmoFormField>
                <label>All Meeting will be disable are sure you want to disconnect google.</label>
                <VmoCheckbox
                  label="Are you agree to disconnect with google"
                  checked={checkboxAgreeDisconnect}
                  onChange={() => setCheckboxAgreeDisconnect(!checkboxAgreeDisconnect)}
                />
              </VmoFormField>
            </VmoForm>
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton
              basic
              disabled={isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              onClick={() => {
                setSecondModalOpen(!SecondModalOpen)
                setCheckboxAgreeDisconnect(false)
              }}
            >
              Cancel
            </VmoButton>
            <VmoButton
              primary
              loading={isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              disabled={!checkboxAgreeDisconnect || isLoadingData || type.includes(GOOGLE_CALENDAR_DISCONNECT)}
              onClick={() => logout()}
            >
              Yes
            </VmoButton>
          </VmoModalActions>
        </VmoModal>
      )}
    </>
  )
}

const Meetings = props => {
  const {style} = props
  const dispatch = useDispatch()
  const [googleDisconnect, setGoogleDisconnect] = useState(false)
  const [calId, setCalId] = useState(null)

  const {meetingCalendarList = [], addCalendar} = useSelector(state => state.meetings)
  const {isLoadingData, type} = useSelector(state => state.apiReducer)

  const {salesAndCrmAccess, meetingsPermissions} = useUserPermissions()
  const meetingsAccess = salesAndCrmAccess?.meetings
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings

  const uId = get(['id'], JSON.parse(window.localStorage.getItem('userInfo')))

  useEffect(() => {
    if (meetingsAccess) {
      dispatch(calendarList())
    }
  }, [meetingsAccess, dispatch])

  const renderCalendarList = () => {
    if (meetingCalendarList) {
      return meetingCalendarList.map(list => (
        <VmoTableRow>
          <VmoTableCell className="pl-4">
            <VmoHeader as="h5"> {list.family_name} </VmoHeader>
          </VmoTableCell>
          <VmoTableCell>{list.email}</VmoTableCell>
          <VmoTableCell>{getFormattedDate(list.createdAt)}</VmoTableCell>
          <VmoTableCell>
            <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
              <VmoCheckbox
                toggle
                checked={list.twoWaySync}
                onChange={e => {
                  e.stopPropagation()
                  if (manageMeetingsPermissions) {
                    dispatch(calendarTwoWaySync(list.calendarId))
                  }
                }}
              />
            </LockPermissionTooltip>
          </VmoTableCell>
          <VmoTableCell>
            <VmoDropdown
              icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
              button
              className="icon"
            >
              <VmoDropdownMenu direction="left">
                <LockPermissionTooltip isRoleAccessDenied={!manageMeetingsPermissions}>
                  <VmoDropdownItem
                    onClick={() => {
                      if (manageMeetingsPermissions) {
                        setCalId(list)
                        setGoogleDisconnect(!googleDisconnect)
                      }
                    }}
                  >
                    <VmoIcon name="cancel" /> Disconnect
                  </VmoDropdownItem>
                </LockPermissionTooltip>
                {/* <VmoDropdownItem
                  onClick={() => dispatch(meetingSetDefault(list.id))}
                >
                  {!list.isDefault ? "Set as Default" : "Active"}
                </VmoDropdownItem> */}
              </VmoDropdownMenu>
            </VmoDropdown>
          </VmoTableCell>
        </VmoTableRow>
      ))
    }
  }

  return (
    <>
      {!meetingsAccess ||
      !Array.isArray(meetingCalendarList) ||
      _.size(meetingCalendarList) === 0 ||
      !meetingCalendarList.some(
        l => l.assigneeId === get(['id'], JSON.parse(window.localStorage.getItem('userInfo')))
      ) ? (
        <CreateFirst
          src={<SvgIcon path="settings/users" />}
          Feature1="Sync your calendar"
          Feature2="Set availability"
          Feature3="Automated reminder"
          list1="Connect with Google Calendar to manage your schedule."
          list2="Customize availability based on preferences & timezone."
          list3="Get meeting reminder before start for smooth workflow"
          addFirst={() =>
            meetingsAccess &&
            manageMeetingsPermissions &&
            window.open(`${MEETINGS_ENDPOINT}/google_auth/googleAuth/${uId}`)
          }
          tooltip={!meetingsAccess || !manageMeetingsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: meetingsAccess,
            roleAccess: manageMeetingsPermissions,
          })}
          buttonText="Connect your Google calendar"
          img={img}
          header="Meetings"
          subHeader="Schedule and keep track of all meetings at an unified place"
        />
      ) : (
        <div className="kooper-side-wrap" style={style}>
          <div className="page-header">
            <div className="page-title">
              <h4 className="mb-1">
                All Calendar
                {_.size(meetingCalendarList) !== 0 && `(${_.size(meetingCalendarList)})`}
              </h4>
              <p>Manage all of your integrated google calendars</p>
            </div>
            <div className="page-action">
              {!addCalendar && (
                <VmoTooltip
                  size="mini"
                  position="bottom center"
                  content={
                    manageMeetingsPermissions
                      ? !addCalendar
                        ? 'Not Access'
                        : 'Add Calendar'
                      : getPermissionTooltipContent({roleAccess: manageMeetingsPermissions})
                  }
                  trigger={
                    <VmoButton
                      primary
                      className={!addCalendar || !manageMeetingsPermissions ? 'disabled-button' : ''}
                      onClick={() =>
                        addCalendar &&
                        manageMeetingsPermissions &&
                        window.open(`${MEETINGS_ENDPOINT}/google_auth/googleAuth/${uId}`)
                      }
                    >
                      Add Calendar {!addCalendar && <VmoIcon className="ml-1" name="lock" />}
                    </VmoButton>
                  }
                />
              )}
            </div>
          </div>
          <VmoDivider className="mt-0" />

          <VmoTable sortable basic selectable style={{marginTop: '24px'}}>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4">Name</VmoTableHeaderCell>
                <VmoTableHeaderCell>Email</VmoTableHeaderCell>
                <VmoTableHeaderCell>Created At</VmoTableHeaderCell>
                <VmoTableHeaderCell>Sync</VmoTableHeaderCell>
                <VmoTableHeaderCell>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(MEETING_CALENDAR_LIST) ? (
                <VmoTablePlaceholder columns={4} rows={4} />
              ) : !meetingCalendarList || meetingCalendarList.length > 0 ? (
                renderCalendarList()
              ) : (
                <tr>
                  <td colSpan="8" className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </VmoTableBody>
          </VmoTable>
        </div>
      )}
      {googleDisconnect && (
        <GoogleDisconnectModal
          data={calId}
          isModalOpen={googleDisconnect}
          setIsModalOpen={() => setGoogleDisconnect(!googleDisconnect)}
        />
      )}
    </>
  )
}

export default Meetings
