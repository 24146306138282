import {SETTINGS_ENDPOINT} from './endpoint'
import {
  ADD_SLA,
  GET_SLA,
  DELETE_SLA,
  EDIT_SLA,
  CREATE_TIMING_SCHEME,
  GET_TIMING_SCHEME,
  DELETE_TIMING_SCHEME,
  EDIT_TIMING_SCHEME,
  CREATE_ROUTING,
  GET_ROUTING,
  GET_ROUTING_DATA,
  DELETE_ROUTING,
  UPDATE_ROUTING,
} from './types'
import {apiAction} from './api'

// SLA Policy --------------------------->

export function addSLAPolicy(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/sla`,
    method: 'POST',
    label: ADD_SLA,
    data,
    showToast: true,
    successMessage: 'SLA Policy Created',
  })
}

export function editSLAPolicy(id, data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/sla/${id}`,
    method: 'PUT',
    label: EDIT_SLA,
    data,
    showToast: true,
    successMessage: 'SLA Policy Updated',
  })
}

export function deleteSLAPolicy(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/sla/${id}`,
    method: 'DELETE',
    label: DELETE_SLA,
    showToast: true,
    successMessage: 'SLA Policy deleted',
  })
}

export function getSLAPolicy() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/sla`,
    method: 'GET',
    label: GET_SLA,
  })
}

// Business-hours --------------------------->

export function createTimingScheme(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/business-hours`,
    method: 'POST',
    label: CREATE_TIMING_SCHEME,
    data,
    showToast: true,
    successMessage: 'Business Hours Created',
  })
}

export function getTimingScheme() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/business-hours`,
    method: 'GET',
    label: GET_TIMING_SCHEME,
  })
}

export function deleteTimingScheme(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/business-hours/${id}`,
    method: 'DELETE',
    label: DELETE_TIMING_SCHEME,
    showToast: true,
    successMessage: 'Business Hours Deleted',
  })
}

export function editingTimingScheme(id, data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/business-hours/${id}`,
    method: 'PUT',
    label: EDIT_TIMING_SCHEME,
    data,
    showToast: true,
    successMessage: 'Business Hours Updated',
  })
}

export function createRouting(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/routingrules`,
    method: 'POST',
    label: CREATE_ROUTING,
    data,
    showToast: true,
    successMessage: 'Routing Created',
  })
}

export function getRouting() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/routingrules`,
    method: 'GET',
    label: GET_ROUTING,
    showToast: true,
  })
}
export function getRoutingData(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/routingrules/${id}`,
    method: 'GET',
    label: GET_ROUTING_DATA,
    showToast: true,
  })
}
export function deleteRouting(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/routingrules/${id}`,
    method: 'DELETE',
    label: DELETE_ROUTING,
    showToast: true,
    successMessage: 'Routing Deleted',
  })
}

export function updateRouting(id, data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/routingrules/${id}`,
    method: 'PUT',
    label: UPDATE_ROUTING,
    data,
    showToast: true,
    successMessage: 'Routing Updated',
  })
}
