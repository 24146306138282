/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import create from 'assets/create.svg'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoButton,
  VmoDivider,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoHeader,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_EMPLOYEES, GET_ALL_EMPLOYEES, GET_DEPARTMENT, GET_USERS} from 'actions/types'
import {getAllEmployees, deleteEmployee, getDepartment, resendEmployeeInvitation} from 'actions/employees'
import {getUsers} from 'actions/settings'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import {startCase} from 'utils/helper'
import EmployeeSidemenu from 'navigation/sub-routes/Hr/Employees/EmployeeMenu'
import {employeeSummary} from 'constants/hr/employees'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import SettingLayout from 'layouts/settingLayout'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const Employees = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [departmentList, setDepartmentList] = useState([])
  const [managerList, setManagerList] = useState([])
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    department: '',
    manager: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_EMPLOYEES})

  const {data: allEmployeesList = []} = useApiResponse({
    action: getAllEmployees,
    enabled: true,
    label: GET_ALL_EMPLOYEES,
    storePath: 'employees.allEmployeesList',
  })

  useApiResponse({
    action: getDepartment,
    enabled: true,
    label: GET_DEPARTMENT,
    storePath: 'employees.getDepartmentData',
    onSuccess: getDepartmentData => {
      const list = getDepartmentData.map(department => ({
        value: department.id,
        text: department.department,
        id: department.id,
      }))
      setDepartmentList(list)
    },
  })

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setManagerList(list)
    },
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllEmployees(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_EMPLOYEES)) {
      dispatch(getAllEmployees())
    }
  }, [successLabels, dispatch])

  const renderTableBody = () => {
    return allEmployeesList.map(
      (
        {
          id,
          firstName,
          lastName,
          employeeID,
          workEmail,
          title,
          department,
          manager,
          createdBy,
          updatedBy,
          allowPortalAccess,
          isOnboard,
        },
        index
      ) => {
        return (
          <VmoTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              history.push(`/employees/update/${id}`)
            }}
          >
            <VmoTableCell>{index + 1}</VmoTableCell>
            <VmoTableCell>
              <VmoHeader as="h5">{startCase(`${firstName} ${lastName || ''}`)}</VmoHeader>
            </VmoTableCell>
            <VmoTableCell>{startCase(employeeID)}</VmoTableCell>
            <VmoTableCell>{workEmail}</VmoTableCell>
            <VmoTableCell>{startCase(title)}</VmoTableCell>
            <VmoTableCell>{startCase(manager?.name)}</VmoTableCell>
            <VmoTableCell>{startCase(department?.department)}</VmoTableCell>
            <VmoTableCell>{startCase(createdBy?.name)}</VmoTableCell>
            <VmoTableCell>{startCase(updatedBy?.name)}</VmoTableCell>
            <VmoTableCell>
              <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                <VmoDropdownMenu>
                  {allowPortalAccess && !isOnboard && (
                    <VmoDropdownItem onClick={() => dispatch(resendEmployeeInvitation(id))}>
                      <VmoIcon name="refresh" /> Resend Invitation
                    </VmoDropdownItem>
                  )}
                  <VmoDropdownItem
                    onClick={() => {
                      setEditId(id)
                      setDeleteModal(true)
                    }}
                  >
                    <VmoIcon name="trash alternate" /> Delete
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>
            </VmoTableCell>
          </VmoTableRow>
        )
      }
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {allEmployeesList?.length > 0 ||
      currentListParams.text ||
      currentListParams.department ||
      currentListParams.manager ? (
        <>
          <EmployeeSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header mb-0 pb-0">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Employees ({allEmployeesList ? allEmployeesList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <VmoTooltip
                  position="top center"
                  size="mini"
                  content="Select Manager"
                  trigger={
                    <div className="mailActivity ml-3">
                      <VmoDropdown
                        icon="chevron down"
                        button
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Manager'},
                          {
                            key: 'No Manager',
                            value: 'No Manager',
                            text: 'No Manager',
                          },
                          <VmoDivider />,
                          ...managerList,
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            manager: value,
                          })
                        }}
                      />
                    </div>
                  }
                />
                <VmoTooltip
                  position="top center"
                  size="mini"
                  content="Select Department"
                  trigger={
                    <div className="mailActivity ml-3">
                      <VmoDropdown
                        icon="chevron down"
                        button
                        defaultValue="all"
                        options={[
                          {key: 'all', value: 'all', text: 'All Department'},
                          {
                            key: 'No Department',
                            value: 'No Department',
                            text: 'No Department',
                          },
                          <VmoDivider />,
                          ...departmentList,
                        ]}
                        onChange={(e, {value}) => {
                          setCurrentListParams({
                            ...currentListParams,
                            department: value,
                          })
                        }}
                      />
                    </div>
                  }
                />
                <VmoButton content="Add" primary onClick={() => history.push('/employees/create')} />
              </div>
            </div>

            <VmoGrid style={{margin: '24px 0'}}>
              <VmoGridRow columns={5} className="p-0 m-0">
                {employeeSummary.map(e => (
                  <VmoGridColumn className="summary-grid">
                    <div className="m-0" style={{'--summary-color': e.color}}>
                      <span>{e.name}</span>
                      <span>{e.time}</span>
                    </div>
                  </VmoGridColumn>
                ))}
              </VmoGridRow>
            </VmoGrid>

            <VmoTable sortable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Employee ID</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Email</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Title</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Manager</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Department</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Modified By</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_ALL_EMPLOYEES) ? (
                  <VmoTablePlaceholder columns={10} />
                ) : (allEmployeesList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={10} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </VmoTableBody>
            </VmoTable>
            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteEmployee}
                idTobeDeleted={editId}
                type={DELETE_EMPLOYEES}
              />
            )}
          </div>
        </>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Employees"
            subHeader="Effectively create and maintain the list of employees working in your organization"
            addFirst={() => history.push(`/employees/create`)}
            buttonText="Create Employees"
            Feature1="Work Hours"
            Feature2="Employee Self Service"
            Feature3="Employment status"
            list1="Set work hours of employees to work for business."
            list2="Allow employee to update their own data"
            list3="Change employment status who have left or terminated"
            imageSrc={create}
          />
        </SettingLayout>
      )}
    </>
  )
}

export default Employees
