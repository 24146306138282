import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom/cjs/react-router-dom.min'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoCheckbox,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'

import {ADD_ROLE, GET_ROLE, GET_ROLES_STRUCTURE, UPDATE_ROLE} from 'actions/types'
import {addRole, getRole, getRolesStructure, updateRole} from 'actions/roles'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes, startCase} from 'utils/helper'
import {roleSchema} from 'validation/Settings/myOrganisation/roles.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'

const RolesDetails = ({toggleCreateRole, setWarningModal}) => {
  const {roleId} = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [servObj, setServObj] = useState({})

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(roleSchema),
    defaultValues: {
      role: '',
      description: '',
    },
  })

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {getRoleData = {}, rolesStructure = {}} = useSelector(state => state.roles)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const rolesAddLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.addLearnMore
  const rolesUpdateLearnMore = settingsLearnMoreUrl?.myOrganization?.teams?.updateLearnMore

  const {myOrganizationDefaultPermissions} = useUserPermissions()

  useEffect(() => {
    dispatch(getRolesStructure())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ROLES_STRUCTURE)) {
      const newObj = Object.keys(rolesStructure).reduce((acc, curArr) => ({...acc, [curArr]: []}), {})
      setServObj(oldObj => ({...newObj, ...oldObj}))
    }
  }, [successLabels, rolesStructure])

  useEffect(() => {
    if (isDirty && roleId) {
      setWarningModal(true)
    }
    return () => {
      setWarningModal(false)
    }
  }, [isDirty, roleId, setWarningModal])

  useEffect(() => {
    if (roleId) {
      dispatch(getRole(roleId))
    }
  }, [roleId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ROLE)) {
      reset(_.omit(getRoleData, ['id', 'accountId', 'isDefault', 'permissions']))
      const permissions = getRoleData.permissions || {}
      const newObj = Object.keys(rolesStructure).reduce((acc, curArr) => ({...acc, [curArr]: []}), {})
      setServObj({...newObj, ...permissions})
    }
  }, [successLabels, reset, getRoleData, rolesStructure])

  useEffect(() => {
    if (successLabels.includes(ADD_ROLE)) {
      if (toggleCreateRole) {
        toggleCreateRole(false)
      }
    }
    if (successLabels.includes(UPDATE_ROLE)) {
      history.push('/settings/roles')
    }
  }, [successLabels, toggleCreateRole, history])

  const handleOnSubmit = data => {
    if (isDirty && roleId) setWarningModal(false)
    const payload = {...data, permissions: servObj}

    if (roleId) {
      dispatch(updateRole(roleId, payload))
    } else {
      dispatch(addRole(payload))
    }
  }

  const isAllPermissionCheck = item => {
    if (rolesStructure[item]?.length === servObj[item]?.length) {
      return true
    }
    return false
  }

  const handleCheckAll = item => {
    if (rolesStructure[item]?.length === servObj[item]?.length) {
      setServObj(servObj => ({...servObj, [item]: []}))
    } else {
      setServObj(servObj => ({
        ...servObj,
        [item]: [...rolesStructure[item]],
      }))
    }
  }

  const checkSinglePermission = (type, item) => {
    if (servObj[type]?.includes(item)) {
      return true
    }
    return false
  }

  const handleSingleCheck = (type, item) => {
    if (servObj[type]?.includes(item)) {
      setServObj(servObj => ({...servObj, [type]: servObj[type].filter(i => i !== item)}))
    } else {
      setServObj(servObj => ({...servObj, [type]: [...servObj[type], item]}))
    }
  }

  return (
    <SettingLayout
      header="Create Role"
      subHeader="Create your role with specific permissions and access based on requirement"
      learnMoreUrl={roleId ? rolesUpdateLearnMore : rolesAddLearnMore}
      table={false}
      headerDivider
      headerbackbtn={{
        onClick: () => {
          if (roleId) {
            setWarningModal(false)
            history.push('/settings/roles')
          } else {
            toggleCreateRole(false)
          }
        },
      }}
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && (type.includes(ADD_ROLE) || type.includes(UPDATE_ROLE)),
          onClick: async () => {
            if (roleId) {
              await setWarningModal(false)
              reset()
              history.push('/settings/roles')
            } else {
              toggleCreateRole(false)
            }
          },
        },
        success: {
          content: roleId ? 'Update' : 'Create',
          disabled: Object.values(servObj).every(item => item.length === 0),
          ...(myOrganizationDefaultPermissions && {
            onClick: handleSubmit(handleOnSubmit),
          }),
        },
      }}
      lockRole={!myOrganizationDefaultPermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormField required width={8}>
          <label>Name Your Role</label>
          <Controller
            name="role"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormInput
                maxLength={20}
                placeholder="Type Name..."
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={errors.role && {content: removeDoubleQuotes(errors.role.message)}}
              />
            )}
          />
        </VmoFormField>

        <VmoFormField width={8}>
          <label>Description</label>
          <Controller
            name="description"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormTextArea
                maxLength={150}
                style={{resize: 'none'}}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
              />
            )}
          />
        </VmoFormField>
      </VmoForm>
      <VmoDivider hidden />

      <VmoGrid columns={3} className="mb-3">
        {Object.keys(rolesStructure).map(item => (
          <VmoGridColumn key={item}>
            <h3 style={{marginBottom: 6}}>{startCase(item)}</h3>
            <VmoTable basic selectable style={{maxWidth: '400px'}}>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell className="f-700">Permissions</VmoTableHeaderCell>
                  <VmoTableHeaderCell>
                    <VmoCheckbox checked={isAllPermissionCheck(item)} onClick={() => handleCheckAll(item)} />
                  </VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {rolesStructure[item].map(permissions => (
                  <VmoTableRow className="tableLink" key={item + permissions}>
                    <VmoTableCell>{startCase(permissions)}</VmoTableCell>
                    <VmoTableCell>
                      <VmoCheckbox
                        id={item + permissions}
                        checked={checkSinglePermission(item, permissions)}
                        onClick={() => handleSingleCheck(item, permissions)}
                      />
                    </VmoTableCell>
                  </VmoTableRow>
                ))}
              </VmoTableBody>
            </VmoTable>
          </VmoGridColumn>
        ))}
      </VmoGrid>
    </SettingLayout>
  )
}

export default RolesDetails
