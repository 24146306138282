import DeleteModal from 'components/common/DeleteModal'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoCheckbox,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {deleteProjectFinanceExpense} from 'actions/projects'
import {useDispatch, useSelector} from 'react-redux'
import {
  DELETE_PROJECT_FINANCE_EXPENSE,
  MOVE_PROJECT_FINANCE_BILLING_ITEMS,
  UPDATE_PROJECT_FINANCE_EXPENSE,
} from 'actions/types'
import {getFormattedDate, startCase} from 'utils/helper'
import {MdOutlineMoreVert} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'
import ProjectBillingExpenseModal from '../ProjectBillingExpenseModal'
import ProjectBillingMoveExpenseModal from './ProjectBillingMoveExpenseModal'

const ProjectBillingExpenseTableList = ({expenseList = [], currency = '', projectId, budgetId, invoiceId}) => {
  const dispatch = useDispatch()

  const [isBillingExpenseModalOpen, setIsBillingExpenseModalOpen] = useState(false)
  const [idToUpdateExpense, setIdToUpdateExpense] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [idToDeleteExpense, setIdToDeleteExpense] = useState(null)
  const [checked, setChecked] = useState([])
  const [isMoveExpenseModalOpen, setIsMoveExpenseModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_EXPENSE)) {
      setIsBillingExpenseModalOpen(false)
      setIdToUpdateExpense(null)
    }
    if (successLabels.includes(DELETE_PROJECT_FINANCE_EXPENSE)) {
      setIsDeleteModalOpen(false)
      setIdToDeleteExpense(null)
    }

    if (successLabels.includes(MOVE_PROJECT_FINANCE_BILLING_ITEMS)) {
      setChecked([])
      setIsMoveExpenseModalOpen(false)
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const isCheckedAll = () => {
    const allIds = expenseList.map(lead => lead.id)

    const checkAll = _.xor(allIds, checked).length === 0 && allIds.length !== 0
    if (checkAll) {
      return true
    }
    return false
  }

  const handleCheckAll = () => {
    const allIds = expenseList.map(lead => lead.id)

    if (_.xor(allIds, checked).length === 0) {
      setChecked([])
    } else setChecked(allIds)
  }

  const handleCheck = id => {
    const isChecked = checked.some(lead => lead === id)

    if (isChecked) {
      const removeChecked = checked.filter(lead => lead !== id)
      setChecked(removeChecked)
    } else {
      setChecked([...checked, id])
    }
  }

  const isChecked = id => {
    const check = checked.find(lead => lead === id)
    if (check) {
      return true
    }
    return false
  }

  const renderTableBody = () => {
    if (expenseList.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="7" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return expenseList.map(expense => (
      <VmoTableRow key={expense.id} className="tableLink">
        <VmoTableCell>
          <VmoCheckbox checked={isChecked(expense.id)} onChange={() => handleCheck(expense.id)} />
        </VmoTableCell>
        <VmoTableCell>{getFormattedDate(expense.expenseDate)}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(expense.title)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{expense.description || '-'}</VmoTableCell>
        <VmoTableCell>{expense.createdBy?.name}</VmoTableCell>
        <VmoTableCell>
          {currency} {expense.cost}
        </VmoTableCell>
        <VmoTableCell>
          <VmoDropdown className="icon" direction="left" style={{marginLeft: 'auto'}} icon={<MdOutlineMoreVert />}>
            <VmoDropdownMenu>
              <VmoDropdownItem
                onClick={() => {
                  setIdToUpdateExpense(expense.id)
                  setIsBillingExpenseModalOpen(true)
                }}
              >
                <SvgIcon path="common/edit2" /> Edit
              </VmoDropdownItem>
              <VmoDropdownItem
                onClick={() => {
                  setIdToDeleteExpense(expense.id)
                  setIsDeleteModalOpen(true)
                }}
              >
                <SvgIcon path="common/delete" /> Delete
              </VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  const getTotalCost = () => {
    return expenseList.reduce((acc, item) => acc + item.cost, 0)
  }

  return (
    <>
      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>
              <VmoCheckbox checked={isCheckedAll()} onChange={handleCheckAll} />
            </VmoTableHeaderCell>
            <VmoTableHeaderCell>Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Expense</VmoTableHeaderCell>
            <VmoTableHeaderCell>Description</VmoTableHeaderCell>
            <VmoTableHeaderCell>Who</VmoTableHeaderCell>
            <VmoTableHeaderCell>Cost</VmoTableHeaderCell>
            <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
        {expenseList.length !== 0 && (
          <VmoTableFooter>
            <VmoTableRow>
              <VmoTableCell colSpan="4" />
              <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
              <VmoTableCell>
                {currency} {getTotalCost()}
              </VmoTableCell>
              <VmoTableCell colSpan="1" />
            </VmoTableRow>
          </VmoTableFooter>
        )}
      </VmoTable>

      {expenseList.length !== 0 && (
        <VmoButton basic primary disabled={!checked.length} onClick={() => setIsMoveExpenseModalOpen(true)}>
          Move Items
        </VmoButton>
      )}

      {isBillingExpenseModalOpen && (
        <ProjectBillingExpenseModal
          open={isBillingExpenseModalOpen}
          toggle={setIsBillingExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          expenseId={idToUpdateExpense}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete this expense?"
          onDelete={() => dispatch(deleteProjectFinanceExpense({projectId, budgetId, expenseId: idToDeleteExpense}))}
        />
      )}

      {isMoveExpenseModalOpen && (
        <ProjectBillingMoveExpenseModal
          open={isMoveExpenseModalOpen}
          toggle={setIsMoveExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          expenseIds={checked}
          invoiceId={invoiceId}
        />
      )}
    </>
  )
}

export default ProjectBillingExpenseTableList
