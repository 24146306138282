import {apiAction} from './api'
import {FORM, KOOPER_CHANNEL_V1} from './endpoint'
import {
  ADD_SMS_CHANNEL,
  CREATE_FEEDBACK_FORM,
  DELETE_CHANNEL,
  DELETE_FEEDBACK_FORM,
  DELETE_SMS,
  EDIT_CHANNEL,
  EDIT_FEEDBACK_FORM,
  GET_CHANNEL,
  GET_CHANNELS_ALL,
  GET_FEEDBACK_FORM,
  GET_PHONE_NUMBER,
  GET_SHARED_SECRET_KEY,
  GET_SMS_CHANNEL_LIST,
  GET_SMS_SINGLE,
  GET_TWITTER_CODE,
  SINGLE_FEEDBACK_FORM,
  UPDATE_SMS,
} from './types'

export function getChannels(par) {
  const params = `?type=${par.type}`
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}${params}`,
    method: 'GET',
    label: GET_CHANNELS_ALL,
  })
}

export function getSocialCode() {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/code`,
    method: 'GET',
    label: GET_TWITTER_CODE,
  })
}

export function deleteChannel(id, data) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/${id}`,
    method: 'DELETE',
    label: DELETE_CHANNEL,
    data,
    showToast: true,
    successMessage: 'Channel Deleted',
  })
}

export function getChannel(id) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/${id}`,
    method: 'GET',
    label: GET_CHANNEL,
  })
}

export function editChannel(id, data) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/${id}`,
    method: 'PUT',
    label: EDIT_CHANNEL,
    data,
    showToast: true,
    successMessage: 'Channel Updated',
  })
}

// SMS Channel Start
export function getSMSList() {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms`,
    method: 'GET',
    label: GET_SMS_CHANNEL_LIST,
  })
}

export function getSharedSecretKey() {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms/sharedsecret`,
    method: 'GET',
    label: GET_SHARED_SECRET_KEY,
    showToast: true,
  })
}

export function getPhoneNumberSMS(data) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms/phonenumbers`,
    method: 'GET',
    label: GET_PHONE_NUMBER,
    params: data,
    showToast: true,
  })
}

export function addSmsChannel(data) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms`,
    method: 'POST',
    label: ADD_SMS_CHANNEL,
    data,
    showToast: true,
    successMessage: 'SMS Channel Added',
  })
}

export function getSMSSingle(id) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms/${id}`,
    method: 'GET',
    label: GET_SMS_SINGLE,
  })
}

export function updateSMS(id, data) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/sms/${id}`,
    method: 'PUT',
    label: UPDATE_SMS,
    data,
    successMessage: 'SMS Channel Updated',
  })
}

export function deleteSMS(id) {
  return apiAction({
    url: `${KOOPER_CHANNEL_V1}/${id}`,
    method: 'DELETE',
    label: DELETE_SMS,
    successMessage: 'SMS Channel Deleted',
  })
}
// SMS Channel End

// Contact Form Start
export function getFeedbackForm() {
  return apiAction({
    url: `${FORM}/forms?type=feedback`,
    method: 'GET',
    label: GET_FEEDBACK_FORM,
  })
}

export function deleteFeedbackForm(id) {
  return apiAction({
    url: `${FORM}/forms/${id}`,
    method: 'DELETE',
    label: DELETE_FEEDBACK_FORM,
    showToast: true,
    successMessage: 'Contact Form Deleted',
  })
}

export function createFeedbackForm(data) {
  return apiAction({
    url: `${FORM}/forms`,
    method: 'POST',
    label: CREATE_FEEDBACK_FORM,
    data,
    showToast: true,
    successMessage: 'Contact Form Created',
  })
}

export function editFeedbackForm(id, data) {
  return apiAction({
    url: `${FORM}/forms/${id}`,
    method: 'PUT',
    label: EDIT_FEEDBACK_FORM,
    data,
    showToast: true,
    successMessage: 'Contact Form Updated',
  })
}

export function getSingleFeedbackForm(id) {
  return apiAction({
    url: `${FORM}/forms/${id}`,
    method: 'GET',
    label: SINGLE_FEEDBACK_FORM,
  })
}

// Contact Form End
