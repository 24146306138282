import React, {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsivePie} from '@nivo/pie'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {VISITORS_OVERTIME, VISITORS_TOP_LIST} from 'actions/types'
import {visitorsOvertimeAnalytics, visitorStatsAnalytics, visitorsTopListAnalytics} from 'actions/analytics_visitors'
import CustomLoader from 'components/common/CustomLoader'
import NoDataChart from 'components/common/NoDataChart'
import {get, wrapText} from 'utils/helper'
import {getGridYValuesBar, maxOfGraphBar} from 'utils/graph'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const Visitors = () => {
  const dispatch = useDispatch()

  const [topCountriesList, setTopCountriesList] = useState([])

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {
    visitorStats: {totalVisitors = {}, totalRatio = {}} = {},
    visitorsOvertime = [],
    visitorsTopList: {topCountries = [], topPageUrl = [], topReferralUrl = []} = {},
  } = useSelector(state => state.analyticsVisitors)
  const {reportsPermissions} = useUserPermissions()
  const customersReportsPermissions = reportsPermissions?.customers_reports

  useEffect(() => {
    if (successLabels.includes(VISITORS_TOP_LIST)) {
      if (topCountries.length <= 10) {
        setTopCountriesList(topCountries)
      } else {
        const descendingList = topCountries.sort((a, b) => b.value - a.value)
        const top10 = descendingList.slice(0, 10)
        const others = descendingList.slice(10).reduce(
          (accumulator, currentValue) => {
            return {
              value: accumulator.value + +currentValue.value,
              percentage: accumulator.percentage + +currentValue.percentage,
            }
          },
          {value: 0, percentage: 0}
        )
        setTopCountriesList([...top10, {id: 'Others', label: 'Others', ...others}])
      }
    }
  }, [topCountries, successLabels])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(visitorStatsAnalytics(data))
      dispatch(visitorsOvertimeAnalytics(data))
      dispatch(visitorsTopListAnalytics(data))
    },
    [dispatch]
  )

  const renderTopPagesBody = () => {
    if (type.includes(VISITORS_TOP_LIST)) {
      return <VmoTablePlaceholder columns={3} lines={1} />
    }
    if (!type.includes(VISITORS_TOP_LIST) && topPageUrl.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="3" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return topPageUrl.map((obj, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>
          <VmoTooltip
            content={get(['pageUrl'], obj, '-')}
            position="top center"
            size="mini"
            trigger={
              <a target="_blank" rel="noopener noreferrer" href={get(['pageUrl'], obj, '')}>
                {wrapText(get(['pageUrl'], obj, '-'), 50)}
              </a>
            }
          />
        </VmoTableCell>
        <VmoTableCell>{obj.value}</VmoTableCell>
        <VmoTableCell>{Number.parseFloat(obj.percentage).toFixed(1)}%</VmoTableCell>
      </VmoTableRow>
    ))
  }

  const renderTopRefferalPagesBody = () => {
    if (type.includes(VISITORS_TOP_LIST)) {
      return <VmoTablePlaceholder columns={2} lines={1} />
    }
    if (!type.includes(VISITORS_TOP_LIST) && topReferralUrl.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="2" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return topReferralUrl.map((obj, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>
          <VmoTooltip
            content={get(['referralUrl'], obj, '-')}
            position="top center"
            size="mini"
            trigger={
              <a href={get(['referralUrl'], obj, '')} target="_blank" rel="noopener noreferrer">
                {wrapText(get(['referralUrl'], obj, '-'), 80)}
              </a>
            }
          />
        </VmoTableCell>
        <VmoTableCell>{Number.parseFloat(obj.percentage).toFixed(1)}%</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="settings/users" />
            <h5>Visitors</h5>
          </div>
        </div>
        {customersReportsPermissions && (
          <div className="page-action">
            <DateRangeGroup actionsToDispatch={actionsToDispatch} />
          </div>
        )}
      </div>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                Total Visitors
                <VmoTooltip
                  content="Total number of visitors visited compared to the previous duration"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </VmoCardHeader>
              <h3>
                {get(['value'], totalVisitors, 0)}
                <span className={get(['growth'], totalVisitors, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], totalVisitors, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], totalVisitors, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="valueBox">
            <VmoCardContent>
              <VmoCardHeader
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                Engagement Rate
                <VmoTooltip
                  content="Engagement rate of the visitors during the selected time range"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </VmoCardHeader>
              <h3>
                {get(['value'], totalRatio, 0)}
                <span className={get(['growth'], totalRatio, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], totalRatio, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], totalRatio, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Total Visitors</VmoCardHeader>
              <VmoTooltip
                content="Graphical represents the count of total visitors with respect to a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 300}}>
              {type.includes(VISITORS_OVERTIME) && <CustomLoader />}
              {!type.includes(VISITORS_OVERTIME) && visitorsOvertime.every(({value}) => value === 0) && (
                <div className="empty-chart">No Data Available</div>
              )}
              {!type.includes(VISITORS_OVERTIME) && (
                <ResponsiveBar
                  data={visitorsOvertime}
                  indexBy="time"
                  margin={{top: 20, right: 20, bottom: 70, left: 40}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphBar(visitorsOvertime, 'value')}
                  gridYValues={getGridYValuesBar(visitorsOvertime, 'value')}
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data.color}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(visitorsOvertime, 'value'),
                    legend: 'Count',
                    legendPosition: 'middle',
                    legendOffset: -30,
                  }}
                  labelSkipHeight={1}
                  label={d => <tspan y={-8}>{d.value}</tspan>}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Top Countries</VmoCardHeader>
              <VmoTooltip
                content="Overview of visitors across the world for selected range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 300}}>
              {type.includes(VISITORS_TOP_LIST) && <CustomLoader />}
              {!type.includes(VISITORS_TOP_LIST) && !topCountriesList.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={topCountriesList}
                  margin={{top: 20, right: 20, bottom: 40, left: 20}}
                  innerRadius={0.7}
                  padAngle={1}
                  cornerRadius={1}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.4]],
                  }}
                  arcLinkLabel={e => `${e.id} (${e.data.percentage}%)`}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsDiagonalLength={5}
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{from: 'color'}}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 2]],
                  }}
                  animate
                  tooltip={function (e) {
                    const {id, value} = e.datum
                    return (
                      <strong
                        style={{
                          backgroundColor: 'white',
                          padding: '3px 6px 3px 6px',
                          borderRadius: '2px',
                        }}
                      >
                        {id} : {value} Persons
                      </strong>
                    )
                  }}
                />
              ) : (
                !type.includes(VISITORS_TOP_LIST) && <NoDataChart top="15px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Top Pages</VmoCardHeader>
              <VmoTooltip
                content="Shows list of all pages along with their performance like visitors count and percentage"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Page</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Visitors</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Percentage</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTopPagesBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Top Refferal Pages</VmoCardHeader>
              <VmoTooltip
                content="List of top URLs based on visitors percentage"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>URLs</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Percentage</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTopRefferalPagesBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Visitors
