/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {VmoToastContainer} from 'vmo-library'
import {get} from 'utils/helper'
import {getMetaData, helpLearnMore, logoutCall} from 'actions'
import {EMPLOYEE_LOGOUT, GET_META_DATA, LOGOUT} from 'actions/types'
import withToast from 'components/hoc/withToast'
import Routes from 'navigation/AppRoutes'
import LogoutModal from 'components/pages/LogoutModal'
import {
  clearStorage,
  getAppToken,
  getLastActivity,
  getProfilePicColor,
  getRememberMe,
  setProfilePicColor,
} from 'utils/local-storage'
import io from 'socket'
import UserPermissionsProvider from 'context/UserPermissions'
import {currentSubscription} from 'actions/billing'
import {getUserRolePermissions} from 'actions/roles'
import LearnMoreUrlProvider from 'context/learnMoreUrl'

function App() {
  const [tokenModal, setTokenModal] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const {tokenExpired = false} = useSelector(state => state.appReducer)
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {metadata} = useSelector(state => state.createUserAcct)

  const onLoad = () => {
    const date1 = getLastActivity()

    const date2 = Date.now()

    if (date1 !== undefined && date1 !== null) {
      const difference = date2 - date1
      const diff = (difference / 1000).toFixed(0)

      if (!getRememberMe()) {
        if (Number(diff) > 432000000) {
          dispatch(logoutCall())
        }
      }
    }
  }

  useEffect(() => {
    if (successLabels.includes(LOGOUT)) {
      clearStorage()
      history.push(`/`)
    }
    if (successLabels.includes(EMPLOYEE_LOGOUT)) {
      clearStorage()
      history.push('/signin/employee')
    }
    if (successLabels.includes(GET_META_DATA) && !getProfilePicColor()) {
      if (metadata) {
        setProfilePicColor(`#${get(['profilePicColor'], metadata, '9eb9d6')}`)
      }
    }
  }, [successLabels, metadata])

  useEffect(() => {
    if (getAppToken()) {
      dispatch(getMetaData())
      dispatch(helpLearnMore())
      dispatch(currentSubscription())
      dispatch(getUserRolePermissions())
      // if (get(['roleId'], getUserInfo())) {
      //   dispatch(getRole(get(['roleId'], getUserInfo())))
      // }

      io.connect()
      window.onload = () => {
        onLoad()
      }

      return () => {
        io.disconnect()
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (tokenExpired) {
      setTokenModal(tokenExpired)
    }
  }, [tokenExpired])

  return (
    <div>
      <VmoToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <UserPermissionsProvider>
        <LearnMoreUrlProvider>
          <Routes />
          {tokenModal && <LogoutModal tokenModal={tokenModal} setTokenModal={setTokenModal} />}
        </LearnMoreUrlProvider>
      </UserPermissionsProvider>
    </div>
  )
}
export default withToast(App)
