/* eslint-disable no-unused-expressions */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {getSingleCompany} from 'actions/company'
import PreviewQuote from 'components/pages/Sales/Quotes/Templates/PreviewQuote'
import {buyerInfoFormSchema, buyerInfoSchema} from 'validation/Sales/quotes.schema'

const companyFields = [
  {name: 'name', label: 'Name'},
  {name: 'streetAddress', label: 'Street Address'},
  {name: 'locality', label: 'Locality'},
  {name: 'state', label: 'State'},
  {name: 'country', label: 'Country'},
  {name: 'postalCode', label: 'Postal Code'},
]

export function ControlledYourInfoFormField({classes = '', control, errors, label, name}) {
  return (
    <VmoFormField required className={classes}>
      <label>{label}</label>
      <VmoFormField>
        <Controller
          control={control}
          name={name}
          render={({value, onChange}) => (
            <VmoFormInput
              maxLength={name !== 'email' ? 20 : undefined}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={
                _.get(errors, `${name}`) && {
                  content: errors[name]?.message,
                }
              }
            />
          )}
        />
      </VmoFormField>
    </VmoFormField>
  )
}
export function ControlledCompanyInfoFormField({classes = '', control, errors, label, name}) {
  return (
    <VmoFormField required={name === 'company.name'} className={classes}>
      <label>{label}</label>
      <VmoFormField>
        <Controller
          control={control}
          name={name}
          render={({value, onChange}) => (
            <VmoFormInput
              maxLength={20}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={
                _.get(errors, `${name}`) && {
                  content: errors?.company[`${name.split('.')[1]}`]?.message,
                }
              }
            />
          )}
        />
      </VmoFormField>
    </VmoFormField>
  )
}
export function ControlledBuyerInfoFormField({classes = '', control, errors, label, name}) {
  return (
    <VmoFormField required className={classes}>
      <label>{label}</label>
      <Controller
        control={control}
        name={name}
        render={({value, onChange}) => (
          <VmoFormInput
            maxLength={20}
            value={value}
            onChange={(e, {value}) => onChange(value)}
            error={
              _.get(errors, `${name}`) && {
                content: errors[name]?.message,
              }
            }
          />
        )}
      />
    </VmoFormField>
  )
}

function BuyerInfoModal({currentData, open, toggle, submitBuyerInfo}) {
  const {control, handleSubmit, errors} = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(buyerInfoSchema),
    defaultValues: currentData,
  })
  return (
    <VmoModal open={open} onClose={toggle}>
      <VmoModalHeader>Buyer Info</VmoModalHeader>

      <VmoModalContent>
        <VmoForm className="errorLabel" onSubmit={handleSubmit(submitBuyerInfo)}>
          <VmoFormField required>
            <label>First Name</label>
            <Controller
              control={control}
              name="firstName"
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.firstName && {
                      content: errors.firstName.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Last Name</label>
            <Controller
              control={control}
              name="lastName"
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.lastName && {
                      content: errors.lastName.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Job Title</label>
            <Controller
              control={control}
              name="jobTitle"
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.jobTitle && {
                      content: errors.jobTitle.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Phone</label>
            <Controller
              control={control}
              name="phone"
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  maxLength={20}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.phone && {
                      content: errors.phone.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          <VmoFormField required>
            <label>Email</label>
            <Controller
              control={control}
              name="email"
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.email && {
                      content: errors.email.message,
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>

      <VmoModalActions>
        <VmoButton basic onClick={toggle}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitBuyerInfo)}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

function CompanyModal({open, toggle, companyData, submitCompanyInfo}) {
  const {control, handleSubmit, errors} = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    submitFocusError: true,
    shouldUnregister: false,
    defaultValues: companyData,
    resolver: joiResolver(buyerInfoFormSchema),
  })

  return (
    <VmoModal open={open} onClose={toggle}>
      <VmoModalHeader>Company Info</VmoModalHeader>

      <VmoModalContent>
        <VmoForm className="errorLabel" onSubmit={handleSubmit(submitCompanyInfo)}>
          {companyFields.map(({label, name}) => (
            <ControlledBuyerInfoFormField key={name} control={control} errors={errors} label={label} name={name} />
          ))}
        </VmoForm>
      </VmoModalContent>

      <VmoModalActions>
        <VmoButton basic onClick={toggle}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitCompanyInfo)}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

function BuyerInfo({
  formData,
  quoteId,
  setWarningModal,
  companyDataError,
  setCompanyDataError,
  buyerDataError,
  setBuyerDataError,
}) {
  const {control, setValue, watch} = formData

  const dispatch = useDispatch()
  const watchAll = watch()

  const initialCompanyData = {
    name: '',
    locality: '',
    postalCode: '',
    state: '',
    streetAddress: '',
    country: '',
  }

  const [selectedBuyerId, setSelectedBuyerId] = useState(null)
  const [contactList, setContactList] = useState([])

  const [isCompanyOpen, setIsCompanyOpen] = useState(false)
  const [companyData, setCompanyData] = useState(initialCompanyData)
  const [hasCompany, setHasCompany] = useState(true)

  const {singleDeals: {contacts = [], primaryContact} = {}} = useSelector(state => state.deals)

  const companyId = primaryContact?.company?.id

  const companyAddress = useSelector(state => state?.companies?.singleCompany?.address?.companyAddress)

  const primaryCompanyAddressId = useSelector(
    state => state?.companies?.singleCompany?.address?.id?.primaryCompanyAddressId
  )

  const primaryCompanyName = useSelector(state => state?.companies?.singleCompany?.primaryCompanyName)

  const {
    quoteForm: {buyerInfo},
  } = useSelector(state => state.quotes)

  useEffect(() => {
    // * get company data if not present alreadycompanyId
    if (companyId) {
      !primaryCompanyName && dispatch(getSingleCompany(companyId))
    }
  }, [companyId])

  useEffect(() => {
    // * set companyData address and name, same as companyData
    if (primaryCompanyAddressId) {
      setCompanyData(data => ({...data, ...companyAddress}))
    }

    if (primaryCompanyName) {
      setCompanyData(data => ({...data, name: primaryCompanyName}))
    }
  }, [primaryCompanyAddressId, primaryCompanyName])

  useEffect(() => {
    if (contacts?.length) {
      // * local contacts list which we will use to generate data for main form
      setContactList(
        contacts.slice(0, 2).map(({id, firstName, lastName, title}) => ({
          firstName,
          lastName,
          jobTitle: title || '',
          id,
          phone: '',
          email: '',
          isSelected: true,
        }))
      )
    }
  }, [contacts?.length])

  useEffect(() => {
    // * set company data in form data
    setValue('company', hasCompany ? companyData : {}, {
      shouldDirty: true,
    })
  }, [hasCompany, companyData])

  useEffect(() => {
    // * set contact data in form data
    if (contactList.length) {
      setValue(
        'contacts',
        contactList.filter(({isSelected}) => isSelected).map(({isSelected, ...contactData}) => ({...contactData})),
        {shouldDirty: true}
      )
    }
  }, [contactList])

  useEffect(() => {
    if (buyerInfo) {
      const ids = buyerInfo.contacts.map(con => con.id)

      if (buyerInfo.company?.name) {
        setCompanyData(buyerInfo.company)
      } else {
        setHasCompany(false)
      }

      setContactList(con => {
        return con.map(item => (ids.includes(item.id) ? {...item, isSelected: true} : {...item, isSelected: false}))
      })
    }
  }, [buyerInfo])

  //* used methods
  const onCheck = (index, checked) =>
    setContactList(contactList => {
      const tempList = [...contactList]
      tempList[index] = {
        ...tempList[index],
        isSelected: checked,
      }
      setContactList(tempList)
    })

  const submitBuyerInfo = data => {
    const newData = {...contactList[selectedBuyerId], ...data}
    const tempContactList = [...contactList]
    tempContactList.splice(selectedBuyerId, 1, newData)
    setContactList(tempContactList)
    setSelectedBuyerId(null)
    setBuyerDataError(false)
  }

  const submitCompanyInfo = data => {
    setCompanyDataError(false)
    setCompanyData(data)
    setIsCompanyOpen(false)
  }

  return (
    <div className="stepWrap">
      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <h3 className="mb-0">Buyer Information</h3>
              <p className="mt-0 mb-4 card-description">Add buyer information which will be included in quote</p>

              <Controller
                control={control}
                name="contacts"
                render={({value}) => <input type="hidden" value={value} />}
              />
              <Controller
                control={control}
                name="company"
                render={({value}) => <input type="hidden" value={value} />}
              />

              <VmoGrid className="mt-3">
                {contactList?.map(({id, firstName, lastName, jobTitle, phone, email, isSelected}, index) => {
                  return (
                    <>
                      <VmoHeader as="h5" onClick={() => setSelectedBuyerId(index)}>
                        <VmoButton basic size="small" color="blue">
                          Add Buyer Information
                        </VmoButton>
                      </VmoHeader>
                      <div
                        className="d-flex"
                        style={{
                          width: '100%',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <VmoCheckbox checked={isSelected} onChange={(e, {checked}) => onCheck(index, checked)} />

                        <div className="ml-4">
                          <div>
                            <div>{firstName || lastName ? `${firstName || ''} ${lastName || ''}` : 'No name'}</div>
                          </div>
                          <div>{jobTitle || ''}</div>
                          <div>{phone || ''}</div>
                          <div>{email || ''}</div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </VmoGrid>
              {buyerDataError && <p style={{padding: '15px 38px', color: 'red'}}>Please fill all Buyer Information</p>}

              <VmoGrid className="my-4">
                <VmoHeader as="h5" className="mt-2" onClick={() => setIsCompanyOpen(true)}>
                  <VmoButton basic color="blue">
                    Add Company Information
                  </VmoButton>
                </VmoHeader>
                <div className="d-flex" style={{width: '100%', justifyContent: 'flex-start'}}>
                  <VmoCheckbox
                    checked={hasCompany}
                    onChange={(e, {checked}) => {
                      setHasCompany(checked)
                    }}
                  />
                  <div className="ml-4">
                    <div>{companyData.name}</div>
                    <div>{companyData.streetAddress}</div>
                    <div>
                      {`${companyData.locality ? `${companyData.locality},` : ''} ${
                        companyData.state ? `${companyData.state},` : ''
                      }
                   ${companyData.postalCode}`}
                    </div>
                    <div>{companyData.country}</div>
                  </div>
                </div>
              </VmoGrid>
              {companyDataError && (
                <p style={{padding: '15px 38px', color: 'red'}}>Please fill all Company Information</p>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={8} className="quotePreBox">
          <PreviewQuote
            // watch={{
            //   buyerInfo: { contacts: contactList, company: companyData },
            // }}
            watch={{buyerInfo: watchAll}}
          />
        </VmoGridColumn>

        {selectedBuyerId !== null ? (
          <BuyerInfoModal
            open={selectedBuyerId !== null}
            toggle={() => setSelectedBuyerId(null)}
            currentData={contactList[selectedBuyerId]}
            submitBuyerInfo={submitBuyerInfo}
          />
        ) : null}

        {isCompanyOpen && (
          <CompanyModal
            open={isCompanyOpen}
            toggle={() => setIsCompanyOpen(false)}
            companyData={companyData}
            submitCompanyInfo={submitCompanyInfo}
          />
        )}
      </VmoGrid>
    </div>
  )
}

export default BuyerInfo
