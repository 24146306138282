/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import Linkify from 'react-linkify'
import {MdFiberManualRecord} from 'react-icons/md'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoHeaderContent,
  VmoHeaderSubheader,
  VmoIcon,
  VmoImage,
  VmoRating,
  VmoTooltip,
} from 'vmo-library'
import parse from 'html-react-parser'
import {ContentState, convertFromHTML, EditorState} from 'draft-js'
import {deleteMessageOfConversation, deleteNote} from 'actions/inbox'
import {extractTextContentFromHTML, getFormattedDate, getFormattedTime, wrapText} from 'utils/helper'
import read from 'assets/read.svg'
import unread from 'assets/unread.svg'
import firstEmj from 'assets/01-emj.svg'
import secondEmj from 'assets/02-emj.svg'
import thirdEmj from 'assets/03-emj.svg'
import fourthEmj from 'assets/04-emj.svg'
import fifthEmj from 'assets/05-emj.svg'
import SvgIcon from 'components/common/SvgIcon'

export const ChatMessageList = props => {
  const {
    conversationId,
    chatMessagesReverse,
    setEditMsgId,
    setEditorState,
    setActiveMsgTab,
    typingConversationId,
    setIsReplyingQuote,
    setReplyingQuoteMsg,
  } = props

  const messagesEndRef = useRef(null)
  const dispatch = useDispatch()

  const [chatMessages, setChatMessages] = useState([])

  const {isLoadMoreMsgs = true} = useSelector(state => state.emailInbox)

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView()
    }
  }, [chatMessages])

  useEffect(() => {
    // if (chatMessagesReverse && chatMessagesReverse.length !== 0) {
    //   setUnreadMsgLine(_.find(chatMessagesReverse, val => Boolean(val.isReadAgent) === false))
    // }

    // when chat msgs is there it convert to time stamp of 1 min
    const newArray = []
    _.forEachRight(chatMessagesReverse, function (value, index, array) {
      let temp = {}
      if (array[index + 1]) {
        if (value.isInbound === array[index + 1].isInbound) {
          if (
            getFormattedDate(value.createdAt) === getFormattedDate(array[index + 1].createdAt) &&
            !((value.call && value.call[0]) || (array[index + 1].call && array[index + 1].call[0])) &&
            getFormattedDate(value.createdAt) === getFormattedDate(array[index + 1].createdAt)
          ) {
            temp = {
              showCreatedAt: false,
              ...value,
            }
          } else {
            temp = {
              showCreatedAt: true,
              ...value,
            }
          }
        } else {
          temp = {
            showCreatedAt: true,
            ...value,
          }
        }
      } else {
        temp = {
          showCreatedAt: true,
          ...value,
        }
      }
      if (index === 0) {
        temp.showDateStamp = true
      } else if (
        array[index - 1] &&
        getFormattedDate(value.createdAt) !== getFormattedDate(array[index - 1].createdAt) &&
        ((value.feedback && value.feedback[0].feedbackVal) ||
          value.messageHtml ||
          value.messageText ||
          value.mediaUrl ||
          (value.note && value.note.note) ||
          value.call)
      ) {
        temp.showDateStamp = true
      } else {
        temp.showDateStamp = false
      }
      newArray.push(temp)
    })
    setChatMessages(newArray.reverse() || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(chatMessagesReverse)])

  const onScroll = e => {
    if (
      chatMessagesReverse &&
      chatMessagesReverse[0] &&
      chatMessagesReverse[0].id &&
      conversationId &&
      e.target.scrollTop === 0 &&
      isLoadMoreMsgs
    ) {
      // dispatch(loadMoreMessages(conversationId, chatMessagesReverse[0].id))
    }
  }

  const renderMediaFile = ({IsBounded, fileType, dir}) => {
    if (IsBounded.mediaName === 'audio-recording.wav') {
      return (
        <audio controls>
          <source src={IsBounded.mediaUrl} type="audio/wav" />
        </audio>
      )
    }

    return ['png', 'jpeg', 'jpg'].includes(fileType) ? (
      <a
        download={IsBounded.mediaName}
        className="attachDownload"
        href={IsBounded.mediaUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="img" src={IsBounded.mediaUrl} />
      </a>
    ) : (
      <VmoCard className={`attachcard-${dir}`}>
        <VmoCardContent className="attchcontent">
          <VmoHeader as="h5">
            <SvgIcon path="common/attachment" />
            <VmoHeaderContent className="attchhead">
              {wrapText(IsBounded.mediaName, 10)}
              <VmoHeaderSubheader>
                {`${fileType.toUpperCase()}`} <MdFiberManualRecord className="attech-dividerDot" />
                {Number(IsBounded.mediaSize / (1024 * 1024)).toFixed(2) > 1
                  ? `${Number(IsBounded.mediaSize / (1024 * 1024)).toFixed(2)} Mb`
                  : `${Number(IsBounded.mediaSize / 1024).toFixed(2)} Kb`}
              </VmoHeaderSubheader>
            </VmoHeaderContent>
            <VmoTooltip
              size="mini"
              position="top center"
              content="Download"
              trigger={
                <VmoButton icon basic className="dw-btn" href={IsBounded.mediaUrl} target="_blank">
                  <SvgIcon path="common/download" className="m-0" />
                </VmoButton>
              }
            />
          </VmoHeader>
        </VmoCardContent>
      </VmoCard>
    )
  }

  const parseHtmlMsg = html => {
    if (!html) return ''
    return parse(html)
  }

  const renderFeedback = feedback => {
    if (!feedback) return

    const {ratings, comment, createdAt, ratingsType} = feedback

    let smily
    let smilyTxt

    const renderEmoji = () => {
      if (!ratings) return
      if (ratings === 1) {
        smily = firstEmj
        smilyTxt = 'Angry'
      } else if (ratings === 2) {
        smily = secondEmj
        smilyTxt = 'Sad'
      } else if (ratings === 3) {
        smily = thirdEmj
        smilyTxt = 'Good'
      } else if (ratings === 4) {
        smily = fourthEmj
        smilyTxt = 'Satisfied'
      } else if (ratings === 5) {
        smily = fifthEmj
        smilyTxt = 'Love it!'
      }

      return (
        <>
          <div className="rating-type">
            <VmoImage src={smily} /> {smilyTxt}
          </div>
          <div className="comment">{comment}</div>
          <div className="time">{getFormattedTime(createdAt)}</div>
        </>
      )
    }

    const renderStar = () => {
      if (!ratings) return
      return (
        <>
          <div className="rating-type">
            <VmoRating icon="star" disabled defaultRating={ratings} maxRating={5} />
          </div>
          <div className="comment">{comment}</div>
          <div className="time">{getFormattedTime(createdAt)}</div>
        </>
      )
    }

    return (
      <div className="left-user">
        <div className="user-feedback" style={{marginBottom: '35px'}}>
          <h5>Feedback From User :</h5>
          {!ratings && <h5 className="m-0">Not Submited</h5>}
          {ratingsType === 'emoji' ? renderEmoji() : renderStar()}
        </div>
      </div>
    )
  }

  const renderMedia = (IsBounded, dateformatFalse, dir) => {
    const fileType = IsBounded.mediaUrl.replace(/^.*\./, '')
    let topClass
    if (dir === 'left') {
      topClass = 'left-user'
    } else if (dir === 'right') {
      topClass = 'right-user'
    }
    return (
      <div className={topClass}>
        <div className="attachMsg">
          {dir === 'left' && renderMediaFile({IsBounded, fileType, dir})}
          {dir === 'right' && (
            <>
              <VmoDropdown direction="left" icon={<SvgIcon path="common/more-verticle" />} className="icon showPrev">
                <VmoDropdownMenu>
                  <VmoDropdownItem
                    onClick={() => {
                      if (IsBounded.id) dispatch(deleteMessageOfConversation(conversationId, IsBounded.id))
                    }}
                  >
                    <VmoIcon name="trash alternate" /> delete
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>

              {renderMediaFile({IsBounded, fileType, dir})}
            </>
          )}
        </div>
        {(IsBounded.messageHtml || IsBounded.messageText) && (
          <div className="content">
            {IsBounded?.messageHtml
              ? parseHtmlMsg(IsBounded.messageHtml)
              : extractTextContentFromHTML(IsBounded.messageText)}
          </div>
        )}
        <span className="msgTime">
          {IsBounded.isEdited && dir === 'right' && (
            <>
              edited <MdFiberManualRecord className="dividerDot" />
            </>
          )}
          {IsBounded.showCreatedAt && dateformatFalse}
          {dir === 'right' ? (
            <>
              {/* {IsBounded.showCreatedAt && <MdFiberManualRecord className="dividerDot" />} */}
              {IsBounded.isReadCustomer ? (
                <VmoImage
                  width={16}
                  height={16}
                  style={{position: 'relative', bottom: '1px'}}
                  className="ml-1"
                  src={read}
                />
              ) : (
                <VmoImage
                  width={16}
                  height={16}
                  style={{position: 'relative', bottom: '1px'}}
                  className="ml-1"
                  src={unread}
                />
              )}
            </>
          ) : null}
        </span>
      </div>
    )
  }

  const RenderLeftInBounded = IsBounded => {
    if (IsBounded.survey) return

    const dateformatFalse = getFormattedTime(IsBounded.createdAt)
    if (IsBounded.mediaUrl) return renderMedia(IsBounded, dateformatFalse, 'left')
    return (
      <div className="left-user">
        <div className="content">
          <Linkify priority={{target: '_blank'}}>
            <p>
              {IsBounded?.parentMessage && (
                <div className="parent-message-text">
                  <p>
                    {IsBounded.parentMessage?.messageHtml
                      ? parseHtmlMsg(IsBounded.parentMessage.messageHtml)
                      : extractTextContentFromHTML(IsBounded.parentMessage?.messageText)}
                  </p>
                  <div className="parent-message-text-bottom">
                    <p>
                      {IsBounded.parentMessage?.fromWithName?.name ||
                        IsBounded.parentMessage?.fromWithName?.agentName ||
                        IsBounded.parentMessage.fromWithName?.username ||
                        '-'}{' '}
                      ,<span className="ml-2">{getFormattedTime(IsBounded.parentMessage.createdAt)}</span>
                    </p>
                  </div>
                </div>
              )}
              {IsBounded?.messageHtml
                ? parseHtmlMsg(IsBounded.messageHtml)
                : extractTextContentFromHTML(IsBounded.messageText)}
            </p>
          </Linkify>
          <VmoTooltip
            position="top center"
            size="tiny"
            content="Reply"
            trigger={
              <VmoIcon
                name="share"
                className="reply-quote"
                onClick={() => {
                  setIsReplyingQuote(true)
                  setReplyingQuoteMsg(IsBounded)
                }}
              />
            }
          />
        </div>
        {IsBounded.showCreatedAt && <span className="msgTime">{dateformatFalse}</span>}
      </div>
    )
  }

  const renderNote = (dateformatTrue, isNote) => {
    const {
      notes: [{id: noteId, note, author}],
      mediaUrl,
      mediaName,
      mediaSize,
    } = isNote

    return (
      <div className="right-user note">
        <div className="content">
          <VmoDropdown
            direction="left"
            icon={<SvgIcon path="common/more-verticle" />}
            className="icon showPrev"
            closeOnBlur={false}
          >
            <VmoDropdownMenu>
              <VmoDropdownItem onClick={() => dispatch(deleteNote(noteId))}>
                <VmoIcon name="trash alternate" /> delete
              </VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>

          <Linkify priority={{target: '_blank'}}>
            {mediaUrl ? (
              <p>
                Attachment: {mediaName} {`${Math.round(mediaSize / 1024)}kb`}
                {note && <div className="attachCmtText"> {note}</div>}
              </p>
            ) : (
              <p className="rightUserText">{parseHtmlMsg(note)}</p>
            )}
          </Linkify>
          <span>
            {author.firstName}
            {` `}
            {author.lastName}
            <MdFiberManualRecord className="dividerDot" />
            {dateformatTrue}
          </span>
        </div>
      </div>
    )
  }

  const RenderRightInBounded = (IsBounded, index) => {
    if (IsBounded.survey) return

    const dateformatTrue = getFormattedTime(IsBounded.createdAt)
    if (IsBounded.mediaUrl) return renderMedia(IsBounded, dateformatTrue, 'right', index)

    if (IsBounded?.notes?.[0] && IsBounded.notes[0].note) return renderNote(dateformatTrue, IsBounded)

    return (
      <div className="right-user">
        <div className="content">
          <VmoTooltip
            position="top center"
            size="tiny"
            content="Reply"
            trigger={
              <VmoIcon
                name="share"
                className="reply-quote"
                onClick={() => {
                  setIsReplyingQuote(true)
                  setReplyingQuoteMsg(IsBounded)
                }}
              />
            }
          />

          <VmoDropdown
            upward
            direction="left"
            icon={<SvgIcon path="common/more-verticle" />}
            closeOnBlur={false}
            className="showPrev icon"
          >
            <VmoDropdownMenu>
              <VmoDropdownItem
                onClick={() => {
                  if (IsBounded.messageHtml) {
                    const temp = EditorState.createWithContent(
                      ContentState.createFromBlockArray(convertFromHTML(IsBounded.messageHtml))
                    )
                    setEditMsgId(IsBounded.id)
                    setEditorState(temp)
                    setActiveMsgTab(0)
                  }
                }}
              >
                <VmoIcon name="edit" /> Edit
              </VmoDropdownItem>
              <VmoDropdownItem
                onClick={() => {
                  if (IsBounded.id) dispatch(deleteMessageOfConversation(conversationId, IsBounded.id))
                }}
              >
                <VmoIcon name="trash alternate" /> Delete
              </VmoDropdownItem>
            </VmoDropdownMenu>
          </VmoDropdown>
          <Linkify priority={{target: '_blank'}}>
            <p className="rightUserText">
              {IsBounded?.parentMessage && (
                <div className="parent-message-text">
                  <p>
                    {IsBounded.parentMessage?.messageHtml
                      ? parseHtmlMsg(IsBounded.parentMessage.messageHtml)
                      : extractTextContentFromHTML(IsBounded.parentMessage?.messageText)}
                  </p>
                  <div className="parent-message-text-bottom">
                    <p>
                      {IsBounded.parentMessage?.fromWithName?.name ||
                        IsBounded.parentMessage?.fromWithName?.agentName ||
                        IsBounded.parentMessage.fromWithName?.username ||
                        '-'}{' '}
                      ,<span className="ml-2">{getFormattedTime(IsBounded.parentMessage.createdAt)}</span>
                    </p>
                  </div>
                </div>
              )}
              {IsBounded?.messageHtml
                ? parseHtmlMsg(IsBounded.messageHtml)
                : extractTextContentFromHTML(IsBounded.messageText)}
            </p>
          </Linkify>
          <span className="msgTime">
            {IsBounded.isEdited && (
              <>
                edited <MdFiberManualRecord className="dividerDot" />
              </>
            )}

            {IsBounded.showCreatedAt && <>{dateformatTrue}</>}
            {IsBounded.isReadCustomer || IsBounded.isEdited ? (
              <VmoImage
                width={16}
                height={16}
                style={{position: 'relative', bottom: '1px'}}
                className="ml-1"
                src={read}
              />
            ) : (
              <VmoImage
                width={16}
                height={16}
                style={{position: 'relative', bottom: '1px'}}
                className="ml-1"
                src={unread}
              />
            )}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="user-chat" onScroll={onScroll}>
      {chatMessages.map((IsBounded, index) => (
        <div key={(IsBounded?.id + index).toString()} ref={messagesEndRef}>
          {IsBounded.showDateStamp && (
            <div className="chat-activity-line">
              <strong>{getFormattedDate(IsBounded.createdAt)}</strong>
            </div>
          )}
          {IsBounded.isInbound ? RenderLeftInBounded(IsBounded) : RenderRightInBounded(IsBounded, index)}
          {IsBounded.survey && renderFeedback(IsBounded.survey)}
        </div>
      ))}
      {typingConversationId.includes(conversationId) && (
        <div className="left-user user-typing">
          <div className="kooper-loader">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
          <span>typing...</span>
        </div>
      )}
    </div>
  )
}
