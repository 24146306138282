import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {VmoForm, VmoFormField, VmoFormSelect, VmoTextArea, VmoTooltip} from 'vmo-library'
import {getAllAddressess} from 'actions/settings_organisation'
import {GET_ALL_ADDRESSESS} from 'actions/types'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const EmployeeWorkInformation = props => {
  const {errors, control, watch} = props

  const [addressOptions, setAddressOptions] = useState([])

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
    onSuccess: allAddressess => {
      const list = allAddressess.map(address => ({
        value: address.id,
        text: address.companyName,
        id: address.id,
      }))
      setAddressOptions(list)
    },
  })

  const getWorkAddress = id => {
    const getAddress = allAddressess?.find(address => address.id === id)
    const address = `Address : ${getAddress?.streetAddress && `${getAddress?.streetAddress},`} ${
      getAddress?.city && `${getAddress?.city},`
    } ${getAddress?.state && `${getAddress?.state},`} ${getAddress?.country && `${getAddress?.country}`} ${
      getAddress?.postalCode && `- ${getAddress?.postalCode}`
    }`
    const phone = `Phone : ${getAddress?.phone && getAddress?.phone}`
    const email = `Email : ${getAddress?.email && getAddress?.email}`

    return `${address}\n${phone}\n${email}`
  }

  return (
    <VmoForm className="errorLabel">
      <VmoFormField width={8}>
        <div className="info-header mb-1">
          <label>Work Address</label>
          <VmoTooltip
            content="Choose office address from the list"
            trigger={<SvgIcon path="common/question" />}
            size="mini"
            position="top center"
          />
        </div>
        <Controller
          name="workAddressId"
          render={({onChange, value}) => (
            <VmoFormSelect
              fluid
              selection
              options={addressOptions}
              placeholder="Address"
              value={value}
              onChange={(e, {value}) => {
                onChange(value)
              }}
              error={
                errors?.workAddressId && {
                  content: removeDoubleQuotes(errors?.workAddressId.message),
                }
              }
            />
          )}
          control={control}
        />
      </VmoFormField>

      {watch('workAddressId') && (
        <VmoFormField width={8}>
          <VmoTextArea value={getWorkAddress(watch('workAddressId'))} />
        </VmoFormField>
      )}
    </VmoForm>
  )
}

export default EmployeeWorkInformation
