import React, {useState} from 'react'
import {withRouter} from 'react-router'
import {
  VmoContainer,
  VmoGrid,
  VmoModal,
  VmoModalContent,
  VmoModalActions,
  VmoButton,
  VmoGridRow,
  VmoImage,
  VmoCard,
  VmoCardContent,
  VmoGridColumn,
  VmoCardHeader,
  VmoIcon,
  VmoList,
  VmoListItem,
  VmoListIcon,
  VmoListContent,
} from 'vmo-library'
import {Link} from 'react-router-dom'
import topImage from 'assets/getting-started.svg'
import {FiAtSign, FiBookmark, FiChevronRight, FiInbox, FiUploadCloud} from 'react-icons/fi'
import {TiTicket} from 'react-icons/ti'
import inbox01 from 'assets/inbox01.png'
import SvgIcon from 'components/common/SvgIcon'

const slideData = [
  {
    key: 1,
    image: inbox01,
    head: 'Client Communication',
    subHead: 'Manage entire client & team communication in one place',
    features: [
      'Team inbox to manage client conversations across lifecycle',
      'Customized client portal to share updates',
      "Keep track of your team's activities and milestones",
    ],
  },
  {
    key: 2,
    image: inbox01,
    head: 'Deals & Sales Forecasting',
    subHead: 'Keep track of deals progress and sales forecasting to meet your sales targets',
    features: [
      'Estimate future revenue to enhance strategic sales planning',
      'Track deals efficiently with sales pipeline visualization',
      'Automated sequences streamline sales & boost deal forecasting',
    ],
  },
  {
    key: 3,
    image: inbox01,
    head: 'Quotes & Invoicing',
    subHead: 'Create professional quotes and invoices instantly to track your sales progress',
    features: [
      'Customizable quote template to match your branding & business needs',
      'Keep track of client details & transaction history in one central location',
      'Handle transactions in different currencies to adapt international clients',
    ],
  },
  {
    key: 3,
    image: inbox01,
    head: 'Project Management',
    subHead: 'Manage project lifecycle with powerful features for your entire team',
    features: [
      'Internal discussion for real-time communication and enhances teamwork',
      'Timesheet for tracking project progress and team productivity',
      'Budgeting for efficient project resource optimization',
    ],
  },
  {
    key: 4,
    image: inbox01,
    head: 'Budgeting & Finance',
    subHead: 'Track project budgeting & finances to measure and achieve the business KPIs',
    features: [
      'Billing management to stay on top of recurring payments',
      'Budget planner for efficient project financial control',
      'Make large financial decisions with budgeting',
    ],
  },
  {
    key: 4,
    image: inbox01,
    head: 'Resource Planning',
    subHead: "Manage your team's time with a birds eye view to deliver amazing productivity at scale",
    features: [
      "Optimize the use of team's time to maximize project outcomes",
      'Visualize project timelines and dependencies to manage tasks effectively',
      'Track employees workloads to identify bottlenecks & redistribute tasks',
    ],
  },
  {
    key: 5,
    image: inbox01,
    head: 'Workflow Automation',
    subHead:
      'Create and manage operational workflows to automate your team’s routine activities and focus on high value tasks',
    features: [
      'Enhance team productivity by reducing the occurrence of errors',
      'Automate repetitive tasks to reduce manual effort',
      'Create workflows to manage leads, deals and sales activities at scale',
    ],
  },
  {
    key: 6,
    image: inbox01,
    head: 'Reporting',
    subHead: 'Measure your client and business operations metrics to keep track of your organizational goals',
    features: [
      'Track reports tailored to specific metrics & KPIs for data-driven decision',
      'Evaluate team performance with analytics to identify areas for development',
      'Historical data to predict future sales trends & better allocate resources',
    ],
  },
]

function GettingStarted() {
  const [productTour, setProductTour] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const slide1 = () => (
    <>
      <div className="FeatureModHeader">
        <h5>Welcome to Kooper</h5>
        <p className="kooper-lead">Kooper Channel Customer Service & Help Desk Software</p>
      </div>

      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiInbox />
            </VmoIcon>
            <h4>Client Communication </h4>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <TiTicket />
            </VmoIcon>
            <h4>Deals & Sales Forecasting</h4>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiUploadCloud />
            </VmoIcon>
            <h4>Quotes & Invoicing</h4>
          </div>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiBookmark />
            </VmoIcon>
            <h4>Project Management</h4>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <SvgIcon path="settings/users" />
            </VmoIcon>
            <h4>Budgeting & Finance</h4>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <SvgIcon path="common/mail" />
            </VmoIcon>
            <h4>Resource Planning</h4>
          </div>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid relaxed columns="equal">
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiAtSign />
            </VmoIcon>
            <h4>Workflow Automation</h4>
          </div>
        </VmoGridColumn>
        <VmoGridColumn>
          <div className="productFeatures">
            <VmoIcon>
              <FiAtSign />
            </VmoIcon>
            <h4>Reporting</h4>
          </div>
        </VmoGridColumn>
      </VmoGrid>
    </>
  )

  const slide = ({image, head, subHead, features = []}) => (
    <>
      <div className="featureTour">
        <div className="info">
          <div className="featureInfoName mb-2">
            <FiInbox />
            <div>
              <h5>{head}</h5>
              <p className="kooper-lead">{subHead}</p>
            </div>
          </div>
        </div>
        <VmoImage src={image} alt="img" className="fea-img" />

        <div className="info mb-3">
          <VmoList>
            {features.map(feature => (
              <VmoListItem key={feature}>
                <VmoListIcon name="check circle outline" />
                <VmoListContent>{feature}</VmoListContent>
              </VmoListItem>
            ))}
          </VmoList>
        </div>
      </div>
    </>
  )

  const actionCancelModal = action => {
    if (action === 'prev') {
      setCurrentSlide(currentSlide => currentSlide - 1)
    }
    if (action === 'next') {
      setCurrentSlide(currentSlide => currentSlide + 1)
    }
    if (action === 'completed') {
      setProductTour(false)
    }
  }

  const carousel = [slide1(), ...slideData.map(data => slide(data))]

  const renderProductTourModal = () => {
    return (
      <VmoModal className="productTourModal" open={productTour}>
        <VmoModalContent>{carousel[currentSlide]}</VmoModalContent>
        <VmoModalActions className="d-flex pt-0">
          {currentSlide > 0 && (
            <VmoButton basic className="ml-0 mr-3" onClick={() => actionCancelModal('prev')}>
              Prev
            </VmoButton>
          )}
          {carousel.length - 1 > currentSlide &&
            (currentSlide <= 0 ? (
              <VmoButton className="start-tour-btn" primary onClick={() => actionCancelModal('next')}>
                Start Kooper Product Tour
              </VmoButton>
            ) : (
              <VmoButton primary className="mr-0" onClick={() => actionCancelModal('next')}>
                Next
              </VmoButton>
            ))}
          {carousel.length - 1 === currentSlide && (
            <VmoButton className="mr-0" primary onClick={() => actionCancelModal('completed')}>
              Done
            </VmoButton>
          )}
        </VmoModalActions>
      </VmoModal>
    )
  }

  return (
    <div style={{width: '100%', background: '#F9FBFD'}}>
      <VmoGrid style={{width: '100%', background: '#FFF'}} className="m-0">
        <VmoGridRow className="p-0">
          <VmoContainer style={{width: '70%', padding: '24px 0'}}>
            <div className="d-flex">
              <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                <VmoImage className="mr-4" src={topImage} alt="getting-started.svg" />
                <div>
                  <p className="kooper-lead mb-1">Tues, Jan 10,2023</p>
                  <h2 className="m-0">Good Afternoon, Username</h2>
                  <span>Just grab quick lunch break to refuel, then back to work at full speed in kooper!</span>
                </div>
              </div>
              <div className="d-flex">
                <VmoButton
                  className="take-a-tour-btn mr-5"
                  onClick={() => {
                    setProductTour(true)
                    setCurrentSlide(0)
                  }}
                >
                  Take a tour!
                </VmoButton>
                <div style={{textAlign: 'end'}}>
                  <p className="kooper-lead mb-0">Trail ends in 7 Days</p>
                  <p>
                    Check out our <a href="https://www.xyz.com">pricing</a>
                  </p>
                </div>
              </div>
            </div>
          </VmoContainer>
        </VmoGridRow>
      </VmoGrid>

      <VmoContainer style={{width: '70%', marginTop: 24}}>
        <VmoGrid className="m-0">
          <VmoGridRow stretched className="mb-3 pt-0">
            <VmoGridColumn width={6} className="pl-0">
              <VmoCard fluid>
                <VmoCardContent style={{padding: 24}}>
                  <VmoCardHeader>
                    <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                      <span>
                        <SvgIcon path="common/file" />
                      </span>
                      <h4 className="m-0">Resources</h4>
                    </div>
                  </VmoCardHeader>
                  <div className="gs-community">
                    <h5>Community</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-quick-links">
                    <h5>Quick Links</h5>
                    <span>
                      <SvgIcon path="common/file" /> Add Channels
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Auto Replies
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-help">
                    <h5>Help</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                  <div className="gs-follow">
                    <h5>Follow Kooper</h5>
                    <span>
                      <SvgIcon path="common/file" /> See the Roadmap
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Submit your product idea
                    </span>
                    <span>
                      <SvgIcon path="common/file" /> Join our product research community
                    </span>
                  </div>
                </VmoCardContent>
              </VmoCard>
            </VmoGridColumn>

            <VmoGridColumn width={10} className="pr-0">
              <VmoGrid columns="equal">
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Client Communication</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/settings/emails">
                            <div className="setup-links">
                              <span>Connect Team Email</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/portal/announcements">
                            <div className="setup-links">
                              <span>Customize Client Portal</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/calendar">
                            <div className="setup-links">
                              <span>Connect Calendar</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Sales CRM</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/deals">
                            <div className="setup-links">
                              <span>Manage Deals</span>
                              <FiChevronRight />
                            </div>
                          </Link>

                          <Link to="/forecasts">
                            <div className="setup-links">
                              <span>Forecast Sales </span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/quotes">
                            <div className="setup-links">
                              <span>Create Quotes</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Project Management</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/projects">
                            <div className="setup-links">
                              <span>Create Projects</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/budgeting">
                            <div className="setup-links">
                              <span>Plan Budgeting</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/timesheets">
                            <div className="setup-links">
                              <span>Add Timesheet</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Finance</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/invoices/all">
                            <div className="setup-links">
                              <span>Create Invoices</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/vendors/all">
                            <div className="setup-links">
                              <span>Manage Vendors</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/purchases/all">
                            <div className="setup-links">
                              <span>Track Purchases</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
                <VmoGridRow>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Automation & Productivity</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/automations">
                            <div className="setup-links">
                              <span>Build Workflows</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/settings/time-tracking">
                            <div className="setup-links">
                              <span>Time Tracking</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/settings/routing">
                            <div className="setup-links">
                              <span>Setup Routing</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <VmoCard fluid>
                      <VmoCardContent style={{padding: 24}}>
                        <VmoCardHeader style={{marginBottom: 12}}>
                          <div className="d-flex setup-header" style={{justifyContent: 'flex-start'}}>
                            <span>
                              <SvgIcon path="common/file" />
                            </span>
                            <h4 className="m-0">Reporting</h4>
                          </div>
                        </VmoCardHeader>

                        <div className="setup-content">
                          <Link to="/reports/inbox">
                            <div className="setup-links">
                              <span>Client Conversations</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/reports/deals">
                            <div className="setup-links">
                              <span>Sales Management</span>
                              <FiChevronRight />
                            </div>
                          </Link>
                          <Link to="/reports/project-health">
                            <div className="setup-links">
                              <span>Projects Tracking </span>
                              <FiChevronRight />
                            </div>
                          </Link>
                        </div>
                      </VmoCardContent>
                    </VmoCard>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoGridColumn>
          </VmoGridRow>
        </VmoGrid>
        {productTour && renderProductTourModal()}
      </VmoContainer>
    </div>
  )
}

export default withRouter(GettingStarted)
