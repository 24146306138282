import React, {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar'
import {ResponsiveLine} from '@nivo/line'
import {ResponsivePie} from '@nivo/pie'
import _ from 'lodash'
import {Progress} from 'semantic-ui-react'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoLoader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {
  CONVERSATION_STATS,
  CONVERSATION_STATUS_ANALYTICS,
  HOME_USERS_ANALYTICS,
  NPS_SCORE_ANALYTICS,
} from 'actions/types'
import {
  analyticsHomeUsers,
  conversationStats,
  conversationStatusAnalytics,
  npsRatingsAnalytics,
} from 'actions/dashboards_support'
import CustomLoader from 'components/common/CustomLoader'
import {get, getSortType, startCase} from 'utils/helper'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import {useLocation} from 'react-router'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {DATE_RANGE_KEY} from 'constants/variables'
import DateRangeGroup from '../DateRangeGroup'

const CenteredMetric =
  myProps =>
  ({centerX, centerY}) => {
    return (
      <text
        x={centerX}
        y={centerY - 15}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '35px',
          fontWeight: 600,
          fill: '#49a8ff',
        }}
      >
        {myProps}
      </text>
    )
  }

const Support = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [data, setData] = useState(JSON.parse(localStorage.getItem(DATE_RANGE_KEY))?.persistDateRangeDataToDispatch)
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    npsScoreAnalyticsData = {},
    conversationStatsData = [],
    conversationStatusData = [],
  } = useSelector(state => state.dashboardsSupport)

  const {
    isLoading: isLoadingHomeUsersAnalytics,
    data: homeUserAnalyticsData = {},
    totalPages,
    currentPage,
  } = useApiResponse({label: HOME_USERS_ANALYTICS, storePath: 'dashboardsSupport.homeUserAnalyticsData'})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    dispatch(analyticsHomeUsers({...data, ...newList}))
  }, [currentListParams, dispatch, data])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(npsRatingsAnalytics(data))
      dispatch(conversationStats(data))
      dispatch(conversationStatusAnalytics(data))
      setData(data)
    },
    [dispatch]
  )

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
    } else {
      setCurrentListParams({
        ...currentListParams,
        sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
      })
    }
  }

  const renderUsersBody = () => {
    if (isLoadingHomeUsersAnalytics) {
      return <VmoTablePlaceholder columns={6} rows={5} />
    }
    if (!isLoadingHomeUsersAnalytics && get(['data'], homeUserAnalyticsData, []).length === 0) {
      return (
        <VmoTableRow className="mb-3">
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return get(['data'], homeUserAnalyticsData, []).map((item, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{startCase(item.user)}</VmoTableCell>
        <VmoTableCell>{item.new}</VmoTableCell>
        <VmoTableCell>{item.open}</VmoTableCell>
        <VmoTableCell>{item.closed}</VmoTableCell>
        <VmoTableCell>{item.on_hold}</VmoTableCell>
        <VmoTableCell>{item.overdue}</VmoTableCell>
        <VmoTableCell>{item.total}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="navigation/home" />
            <h5>Support</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn width={5}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>NPS Score</VmoCardHeader>
              <VmoTooltip
                content="Analyze NPS score calculated from customers responses over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              <VmoGrid>
                <VmoGridColumn width={9} style={{height: 350}}>
                  {type.includes(NPS_SCORE_ANALYTICS) && <CustomLoader />}
                  {!type.includes(NPS_SCORE_ANALYTICS) &&
                    (!get(['npsScore', 'data'], npsScoreAnalyticsData, []).every(({value}) => value === 0) ? (
                      <ResponsivePie
                        data={get(['npsScore', 'data'], npsScoreAnalyticsData, [])}
                        margin={{
                          top: -10,
                          right: 60,
                          bottom: 0,
                          left: 50,
                        }}
                        startAngle={-90}
                        endAngle={90}
                        innerRadius={0.6}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeInnerRadiusOffset={2}
                        activeOuterRadiusOffset={2}
                        borderWidth={1}
                        colors={d => d.data.color}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]],
                        }}
                        arcLinkLabel={d => `${d.id} (${d.data.percentage}%)`}
                        arcLinkLabelsTextOffset={2}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsDiagonalLength={8}
                        arcLinkLabelsStraightLength={12}
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{from: 'color'}}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                          from: 'color',
                          modifiers: [['darker', 2]],
                        }}
                        layers={[
                          'arcs',
                          'arcLabels',
                          'arcLinkLabels',
                          'legends',
                          CenteredMetric(get(['npsScore', 'scorePercentage'], npsScoreAnalyticsData, 0)),
                        ]}
                      />
                    ) : (
                      !type.includes(NPS_SCORE_ANALYTICS) && (
                        <VmoGrid centered>
                          {!type.includes(NPS_SCORE_ANALYTICS) && (
                            <VmoGridColumn
                              style={{
                                width: '200px',
                                height: '200px',
                                marginTop: '50px',
                              }}
                            >
                              <CircularProgressbar
                                styles={buildStyles({
                                  textColor: 'rgba(0, 0, 0, 0.6)',
                                  trailColor: '#f2f4f7',
                                  textSize: '7px',
                                })}
                                strokeWidth={10}
                                value={0}
                                text="No Data Available"
                              />
                            </VmoGridColumn>
                          )}
                        </VmoGrid>
                      )
                    ))}
                </VmoGridColumn>
                <VmoGridColumn width={6} style={{height: 300, marginTop: '60px'}}>
                  {type.includes(NPS_SCORE_ANALYTICS) && (
                    <VmoLoader
                      active
                      inline="centered"
                      style={{
                        position: 'absolute',
                        top: '37%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#49a8ff',
                      }}
                    />
                  )}
                  {!type.includes(NPS_SCORE_ANALYTICS) &&
                    get(['npsScore', 'data'], npsScoreAnalyticsData, []).length > 0 && (
                      <div>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <h4 className="mb-0">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontSize: '16px',
                                }}
                              >
                                🤩
                              </span>
                              {startCase(get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].label)}
                            </h4>
                            <p
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '16px',
                                color: '#888',
                                margin: 0,
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '17px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '16px',
                                }}
                              >
                                {get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].percentage}
                                <span style={{marginLeft: '2px'}}>%</span>
                              </span>
                              <span style={{fontSize: '15px'}}>
                                ({get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].value})
                              </span>
                            </p>
                          </div>
                          <Progress
                            percent={get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].percentage}
                            color="green"
                            size="small"
                          />
                        </div>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <h4 className="mb-0">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontSize: '16px',
                                }}
                              >
                                😐
                              </span>
                              {startCase(get(['npsScore', 'data'], npsScoreAnalyticsData, [])[1].label)}
                            </h4>
                            <p
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '16px',
                                color: '#888',
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '17px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '16px',
                                }}
                              >
                                {get(['npsScore', 'data'], npsScoreAnalyticsData, [])[1].percentage}
                                <span style={{marginLeft: '2px'}}>%</span>
                              </span>
                              <span style={{fontSize: '15px'}}>
                                ({get(['npsScore', 'data'], npsScoreAnalyticsData, [])[1].value})
                              </span>
                            </p>
                          </div>
                          <Progress
                            percent={get(['npsScore', 'data'], npsScoreAnalyticsData, [])[1].percentage}
                            color="blue"
                            size="small"
                          />
                        </div>
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <h4 className="mb-0">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontSize: '16px',
                                }}
                              >
                                😞
                              </span>
                              {startCase(get(['npsScore', 'data'], npsScoreAnalyticsData, [])[2].label)}
                            </h4>
                            <p
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                fontSize: '16px',
                                color: '#888',
                                marginLeft: 2,
                              }}
                            >
                              <span
                                style={{
                                  marginLeft: '17px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '16px',
                                }}
                              >
                                {get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].percentage}
                                <span style={{marginLeft: '2px'}}>%</span>
                              </span>
                              <span style={{fontSize: '15px'}}>
                                ({get(['npsScore', 'data'], npsScoreAnalyticsData, [])[0].value})
                              </span>
                            </p>
                          </div>
                          <Progress
                            percent={get(['npsScore', 'data'], npsScoreAnalyticsData, [])[2].percentage}
                            color="red"
                            size="small"
                          />
                        </div>
                      </div>
                    )}
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>All Conversations</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of conversations based on status over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            {!type.includes(CONVERSATION_STATS) && conversationStatsData.length > 0 && (
              <VmoCardContent className="pt-0" style={{marginLeft: '30px', width: '75%'}}>
                <VmoGrid columns="equal">
                  {conversationStatsData.map((data, id) => {
                    if (data.status !== 'overdue') {
                      return (
                        <VmoGridColumn key={id}>
                          <VmoCard fluid style={{boxShadow: 'none'}}>
                            <VmoCardContent className="headerBox pb-0">
                              <VmoCardHeader>{startCase(data.status)}</VmoCardHeader>
                            </VmoCardContent>
                            <VmoCardContent className="valueBox pt-2">
                              <h3>
                                {get(['value'], data, 0)}
                                <span className={get(['growth'], data, 'positive') === 'positive' ? '' : 'text-danger'}>
                                  {get(['growth'], data, 'positive') === 'positive' ? (
                                    <SvgIcon path="common/arrow-up-right" />
                                  ) : (
                                    <SvgIcon path="common/arrow-down-left" className="down-left" />
                                  )}
                                  {get(['percentage'], data, 0)}%
                                </span>
                              </h3>
                            </VmoCardContent>
                          </VmoCard>
                        </VmoGridColumn>
                      )
                    }
                  })}
                </VmoGrid>
              </VmoCardContent>
            )}
            <VmoCardContent className="pt-0" style={{height: 380}}>
              {type.includes(CONVERSATION_STATUS_ANALYTICS) && <CustomLoader />}
              {!type.includes(CONVERSATION_STATUS_ANALYTICS) &&
                !conversationStatusData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(CONVERSATION_STATUS_ANALYTICS) && (
                <ResponsiveLine
                  data={conversationStatusData}
                  margin={{top: 37, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(conversationStatusData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(conversationStatusData)}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 55,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Conversations',
                    tickValues: getGridYValues(conversationStatusData),
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  colors={d => d.color}
                  lineWidth={2}
                  pointSize={3}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  enableArea
                  useMesh
                  legends={[
                    {
                      anchor: 'top-right',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -40,
                      itemsSpacing: 1,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Users</VmoCardHeader>
              <VmoTooltip
                content="List of agents organized based on status of assigned conversations"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    {!isLoadingHomeUsersAnalytics &&
                      get(['headers'], homeUserAnalyticsData, []).map((item, index) => (
                        <VmoTableHeaderCell
                          key={index}
                          sorted={currentListParams.sortBy === item ? getSortType(currentListParams.sortType) : null}
                          onClick={handleSort(item)}
                        >
                          {startCase(item)}
                        </VmoTableHeaderCell>
                      ))}
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderUsersBody()}</VmoTableBody>
              </VmoTable>
              <KooperCustomPagination
                activePage={currentPage}
                totalPages={totalPages}
                onPageChange={page =>
                  setCurrentListParams(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Support
