/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {VmoButton, VmoTab} from 'vmo-library'

import {createQuote, getSingleQuote, updateQuote} from 'actions/quotes'
import {CREATE_QUOTE, UPDATE_QUOTE} from 'actions/types'
import PreviewQuote from 'components/pages/Sales/Quotes/Templates/PreviewQuote'
import {buyerForm, productSchema, setupSchema, yourInfoSchema} from 'validation/Sales/quotes.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import Setup from './Setup'
import Customization from './Customization'
import Product from './Product'
import Signature from './Signature'
import QuoteModal from './QuotesModal'
import YourInfo from './YourInfo'
import BuyerInfo from './BuyerInfo'

const steps = [
  {name: 'Setup', icon: 'file alternate'},
  {name: 'Buyer Info', icon: 'unordered list'},
  {name: 'Your Info', icon: 'list'},
  {name: 'Customization', icon: 'paint brush'},
  {name: 'Product', icon: 'box'},
  {name: 'Signature', icon: 'pencil alternate'},
  {name: 'Preview', icon: 'eye'},
]

function QuotesForm({setWarningModal}) {
  const {push, goBack} = useHistory()
  const {id: quoteId} = useParams()

  const nextButtonRef = useRef()

  const dispatch = useDispatch()
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const quotePublicLink = useSelector(state => state.quotes.quotePublicLink)

  const [index, setIndex] = useState(0)
  const [showQuoteModal, setShowQuoteModal] = useState(false)
  const [buyerDataError, setBuyerDataError] = useState(false)
  const [companyDataError, setCompanyDataError] = useState(false)

  const setupFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      dealId: '',
      quoteNo: '',
      prefixId: '',
      validTill: '',
    },
    resolver: joiResolver(setupSchema),
  })

  const buyerInfoFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(buyerForm),
    defaultValues: {
      contacts: [],
      company: {
        name: '',
        locality: '',
        postalCode: '',
        state: '',
        streetAddress: '',
        country: '',
      },
    },
  })

  const yourInfoFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(yourInfoSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      jobTitle: '',
      company: {
        name: '',
        locality: '',
        postalCode: '',
        state: '',
        streetAddress: '',
        country: '',
      },
    },
  })

  const customizationFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      templateNo: 2,
      color: '#1e90ff',
      header: '',
      footer: '',
      paymentProcess: '',
      terms: '',
    },
  })
  const productFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    resolver: joiResolver(productSchema),
    defaultValues: {
      products: [],
      otherCharges: [],
      total: 123,
      subTotal: 0,
    },
  })

  const signatureFormData = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {signature: 'No Signature', publish: true},
  })

  const {
    setupData,
    products,
    yourInfo,
    signature,
    buyerInfo,
    template,
    products: formProducts,
  } = useSelector(state => state.quotes.quoteForm)

  const {logo, ...logoLessTemplateForm} = template || {}

  const {template: {logo: previewLogo} = {}} = useSelector(state => state.quotes.quoteDetails)

  const {selectedFile} = useSelector(state => state.quotes.uploadFile)

  // * data for single quote /edit quote
  const quoteDetails = useSelector(state => state.quotes.quoteDetails)
  const {
    buyerInfo: buyerInfoData,
    yourInfo: yourInfoData,
    template: {id, accountId, quoteId: tempQuoteId, logo: currentLogo, ...templateData} = {},
    signature: signatureDetails,
    otherCharges: otherChargesDetails,
    products: productsDetails,
    total,
    subTotal,
  } = quoteDetails

  const {quotesPermissions} = useUserPermissions()
  const manageQuotesPermissions = quotesPermissions?.manage_quotes

  useEffect(() => {
    if (quoteId) dispatch(getSingleQuote(quoteId))

    return () => {
      dispatch({type: 'CLEAR_QUOTE_FORM'})
      dispatch({type: 'CLEAR_QUOTE_DETAILS'})
      dispatch({type: 'CLEAR_QUOTE_UPLOADFILE'})
    }
  }, [])

  //* quote create/update success and
  useEffect(() => {
    if (quotePublicLink) {
      if (quotePublicLink === 'quote created') {
        // setShowQuoteModal(true);
        goBack()
      } else {
        setShowQuoteModal(true)
      }
    }

    if (successLabels.includes(CREATE_QUOTE)) {
      setWarningModal(false)
      setShowQuoteModal(true)
      // goBack();
      // dispatch({ type: "CLEAR_QUOTE_FORM" });
    }

    if (successLabels.includes(UPDATE_QUOTE)) {
      // setShowQuoteModal(true);
      setWarningModal(false)
      dispatch({type: 'CLEAR_QUOTE_FORM'})
      dispatch({type: 'CLEAR_QUOTE_DETAILS'})
      dispatch({type: 'CLEAR_QUOTE_UPLOADFILE'})
    }
  }, [quotePublicLink, successLabels])

  //* reset data for quote preview
  useEffect(() => {
    if (quoteId) {
      if (!setupData && quoteDetails?.name) {
        dispatch({
          type: 'SETUP_SUBMIT',
          payload: {
            name: quoteDetails.name,
            dealId: quoteDetails.dealId,
            quoteNo: quoteDetails.quoteNo,
            prefixId: quoteDetails.prefixId,
            validTill: quoteDetails.validTill,
          },
        })
      }

      if (!buyerInfo && buyerInfoData) {
        dispatch({
          type: 'BUYER_INFO_SUBMIT',
          payload: buyerInfoData,
        })
      }

      if (!yourInfo && yourInfoData) {
        dispatch({type: 'YOUR_INFO_SUBMIT', payload: yourInfoData})
      }

      // if (!logoLessTemplateForm && templateData) {
      dispatch({type: 'CUSTOMIZATION_SUBMIT', payload: templateData})
      dispatch({type: 'SET_PREVIEW_FILE', payload: currentLogo})
      dispatch({type: 'SET_SELECTED_FILE', payload: 'currentLogo'})

      // }
      if (!formProducts && productsDetails) {
        dispatch({
          type: 'PRODUCT_HANDLE_SUBMIT',
          payload: {
            // products: productsDetails,
            // otherCharges: otherChargesDetails ? otherChargesDetails : [],
            products: productsDetails.map(val => _.omit(val, ['id', 'accountId', 'productName', 'quoteId'])),
            otherCharges: otherChargesDetails
              ? otherChargesDetails.map(val => _.omit(val, ['id', 'accountId', 'productName', 'quoteId']))
              : [],
            total,
            subTotal,
          },
        })
      }

      if (!signature && signatureDetails) {
        dispatch({
          type: 'SIGNATURE_SUBMIT',
          payload: {
            signature: signatureDetails,
            publish: quoteDetails.status === 'published',
          },
        })
      }
    }
  }, [quoteId, quoteDetails])

  useEffect(() => {
    if (previewLogo) {
      // const [, setPreview] = preview;
      // setPreview(previewLogo);
      dispatch({type: 'SET_PREVIEW_FILE', payload: previewLogo})
    }
  }, [previewLogo])

  useEffect(() => {
    if (index !== 0 && quoteId) {
      submitQuote()
    }
  }, [index])

  const submitQuote = () => {
    const data = {
      ...setupData,
      ...products,
      buyerInfo,
      yourInfo,
      template: logoLessTemplateForm,
      ...signature,
    }

    const formData = new FormData()

    for (const key in data) {
      if (typeof data[key] === 'string') {
        formData.append(key, data[key])
      } else {
        formData.append(key, data[key] === null ? '' : JSON.stringify(data[key]))
      }
    }

    // if (selectedFile?.[0]) formData.append("logo", selectedFile[0]);
    if (selectedFile && typeof selectedFile !== 'string') {
      formData.append('logo', selectedFile)
    }

    if (quoteId) {
      if (index === undefined && quoteId) {
        dispatch(updateQuote(formData, quoteId))
      }
    } else {
      dispatch(createQuote(formData))
    }
  }

  const handleNext = tabIndex => {
    if (index === 0) {
      setupFormData.handleSubmit(data => {
        dispatch({type: 'SETUP_SUBMIT', payload: data})
        quoteId ? setIndex(tabIndex) : setIndex(index + 1)
      })()
    }

    if (index === 1) {
      buyerInfoFormData.handleSubmit(data => {
        const {firstName, lastName, jobTitle, phone, email} = data.contacts[0]
        if (firstName === '' || lastName === '' || jobTitle === '' || phone === '' || email === '') {
          setBuyerDataError(true)
        } else {
          setBuyerDataError(false)
        }
        const {name, locality, postalCode, state, streetAddress, country} = data.company
        if (
          name === '' ||
          locality === '' ||
          postalCode === '' ||
          state === '' ||
          streetAddress === '' ||
          country === ''
        ) {
          setCompanyDataError(true)
        } else {
          setCompanyDataError(false)

          dispatch({type: 'BUYER_INFO_SUBMIT', payload: data})
          quoteId ? setIndex(tabIndex) : setIndex(index + 1)
        }
      })()
    }
    if (index === 2) {
      yourInfoFormData.handleSubmit(data => {
        dispatch({type: 'YOUR_INFO_SUBMIT', payload: data})
        quoteId ? setIndex(tabIndex) : setIndex(index + 1)
      })()
    }

    if (index === 3) {
      customizationFormData.handleSubmit(data => {
        dispatch({type: 'CUSTOMIZATION_SUBMIT', payload: data})
        quoteId ? setIndex(tabIndex) : setIndex(index + 1)
      })()
    }

    if (index === 4) {
      productFormData.handleSubmit(data => {
        dispatch({type: 'PRODUCT_HANDLE_SUBMIT', payload: data})
        quoteId ? setIndex(tabIndex) : setIndex(index + 1)
      })()
    }

    if (index === 5) {
      signatureFormData.handleSubmit(data => {
        dispatch({type: 'SIGNATURE_SUBMIT', payload: data})
        quoteId ? setIndex(tabIndex) : setIndex(index + 1)
      })()
    }

    // * create quote step and submit
    if (index === 6) {
      tabIndex ? setIndex(tabIndex) : submitQuote()
    }
    if (!index && quoteId) {
      return submitQuote()
    }
  }

  return (
    <div className="kooper-full-page">
      <div className="page-header">
        <div className="top">
          <div className="title">
            {quoteId ? (
              <VmoButton inverted basic onClick={() => push('/quotes')}>
                Exit
              </VmoButton>
            ) : (
              <>
                <VmoButton inverted basic onClick={() => push('/quotes')}>
                  Exit
                </VmoButton>
                {index > 0 && (
                  <VmoButton inverted basic onClick={() => setIndex(index - 1)}>
                    Back
                  </VmoButton>
                )}
              </>
            )}
          </div>

          <div className="center blue-tab">
            <VmoTab
              key={index}
              panes={steps.map(item => ({
                menuItem: {
                  key: item.name,
                  icon: item.icon,
                  content: item.name,
                },
                render: null,
              }))}
              menu={{
                secondary: true,
                pointing: true,
                color: 'blue',
              }}
              activeIndex={index}
              onTabChange={(e, {activeIndex}) => {
                handleNext(activeIndex)
              }}
            />
          </div>

          <div className="action">
            <LockPermissionTooltip isRoleAccessDenied={!manageQuotesPermissions}>
              <button
                ref={nextButtonRef}
                className="ui primary button"
                // onClick={() => {
                //   quoteId ? submitQuote() : handleNext();
                // }}
                onClick={() => manageQuotesPermissions && handleNext()}
              >
                {quoteId ? 'Update' : index === steps.length - 1 ? 'Create' : 'Next'}
              </button>
            </LockPermissionTooltip>
          </div>
        </div>
      </div>

      {index === 0 && <Setup quoteId={quoteId} formData={setupFormData} setWarningModal={setWarningModal} />}

      {index === 1 && (
        <BuyerInfo
          quoteId={quoteId}
          companyDataError={companyDataError}
          setCompanyDataError={setCompanyDataError}
          buyerDataError={buyerDataError}
          setBuyerDataError={setBuyerDataError}
          formData={buyerInfoFormData}
          setWarningModal={setWarningModal}
        />
      )}

      {index === 2 && <YourInfo quoteId={quoteId} formData={yourInfoFormData} setWarningModal={setWarningModal} />}

      {index === 3 && (
        <Customization
          quoteId={quoteId}
          formData={customizationFormData}
          setWarningModal={setWarningModal}

          // previewState={preview}
          // selectedFileState={selectedFile}
        />
      )}

      {index === 4 && (
        <Product
          buttonRef={nextButtonRef}
          quoteId={quoteId}
          formData={productFormData}
          setWarningModal={setWarningModal}
        />
      )}

      {index === 5 && <Signature quoteId={quoteId} formData={signatureFormData} setWarningModal={setWarningModal} />}

      {index === 6 && <PreviewQuote />}

      {showQuoteModal && (
        <QuoteModal
          link={quotePublicLink}
          open={showQuoteModal}
          toggle={() => {
            setShowQuoteModal(false)
            goBack()
            dispatch({type: 'CLEAR_QUOTE_PUBLIC_LINK'})
          }}
        />
      )}
    </div>
  )
}

export default QuotesForm
