import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {updateApprovalStatus} from 'actions/proofs'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {approvalStatusSchema} from 'validation/Projects/projects/proofs.schema'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

const initialCreateInfo = {
  approvalStatus: '',
  remarks: '',
}
function ApproveStatusModal(props) {
  const {isApproveStatusModal, setIsApproveStatusModal, approveStatus, editId} = props
  const dispatch = useDispatch()

  const [successMessage, setSuccessMessage] = useState('')

  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(approvalStatusSchema),
    defaultValues: initialCreateInfo,
  })

  useEffect(() => {
    if (approveStatus === 'approved') {
      setSuccessMessage('Proof has been approved successfully')
    } else {
      setSuccessMessage('Submitted request for change')
    }
  }, [approveStatus])

  const submitStatus = data => {
    data.approvalStatus = approveStatus

    dispatch(updateApprovalStatus(editId, data, successMessage))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isApproveStatusModal}
      onClose={() => setIsApproveStatusModal(false)}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">
        {approveStatus === 'approved' ? "You're about to approve" : "You're about to request change"}
      </VmoModalHeader>

      <VmoModalContent scrolling>
        <VmoForm className="errorLabel">
          <VmoFormField required width={16}>
            <Controller
              name="remarks"
              render={({onChange, value}) => (
                <VmoFormTextArea
                  type="text"
                  value={value}
                  maxLength={250}
                  placeholder={approveStatus === 'approved' ? 'Any other feedback?' : 'What needs to be changed?'}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.remarks && {
                      content: removeDoubleQuotes(errors.remarks.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>

      <VmoModalActions style={{position: 'relative'}}>
        <VmoButton basic onClick={() => setIsApproveStatusModal(false)}>
          Cancel
        </VmoButton>

        <VmoButton primary onClick={handleSubmit(submitStatus)}>
          {approveStatus === 'approved' ? 'Submit Approval' : 'Submit request'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default ApproveStatusModal
