import React, {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {removeDoubleQuotes} from 'utils/helper'
import {automationTitleSchema} from 'validation/automation.schema'
import SvgIcon from 'components/common/SvgIcon'

const AutomationTitle = ({open, toggle, automationData, setAutomationData}) => {
  const location = useLocation()
  const history = useHistory()

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(automationTitleSchema),
    defaultValues: {
      name: '',
      description: '',
    },
  })

  useEffect(() => {
    if (automationData) {
      reset({name: automationData.name, description: automationData.description})
    }
  }, [automationData, reset])

  const submitForm = data => {
    if (automationData) {
      setAutomationData(automationData => ({...automationData, ...data}))
    }
    if (location.pathname === '/automations') {
      history.push({
        pathname: 'automations/builder',
        state: data,
      })
    } else {
      history.replace({state: data})
      toggle(false)
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Create Automation</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Title</label>
              <VmoTooltip
                content="Specify title of the workflow"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
            </div>

            <Controller
              name="name"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.name && {content: removeDoubleQuotes(errors.name.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                content="Specify a brief description about the workflow"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <VmoFormTextArea
                  maxLength={250}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Create
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default AutomationTitle
