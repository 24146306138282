import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray} from 'react-hook-form'
import _ from 'lodash'
import {
  VmoButton,
  VmoCheckbox,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoHeader,
  VmoHeaderSubheader,
  VmoIcon,
  VmoInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoSearch,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  vmoToast,
} from 'vmo-library'

import {GET_ALL_MAIL_INBOX, GET_PRODUCTS_LIST} from 'actions/types'
import {get, removeDoubleQuotes, startCase} from 'utils/helper'
import {priorityList, taskTypesList} from 'constants/variables'
import {getProductlist} from 'actions/productLibrary'
import {billingFreqOptions} from 'components/pages/Sales/Products/ProductsModal'
import {getAllMailInbox} from 'actions/inbox'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'
import {
  ACTION_CONVERSATION_EMAIL_OPTIONS,
  ACTION_CONVERSATION_WEB_OPTIONS,
  ACTION_ENTITY_OPTIONS,
  ACTION_EVENT_OPTONS,
  fieldsForActions,
} from '../../../constants/automation'

const typeOptions = [
  {key: '%', value: '%', text: '%'},
  {key: 'USD', value: 'USD', text: 'USD'},
]

const AddAction = props => {
  const {
    open,
    toggle,
    handleSubmit,
    control,
    errors,
    clearErrors,
    watchActionEntity,
    watchActionEvent,
    automationData,
    watch,
    setValue,
    handleAdd,
    setAutomationData,
    dropdownData,
    setEditData,
    searchValue,
    setSearchValue,
  } = props

  const dispatch = useDispatch()

  const [mailBoxList, setMailBoxList] = useState([])
  const [searchText, setSearchText] = useState('')
  // const [searchValue, setSearchValue] = useState([])
  const [searchBoxOptions, setSearchBoxOptions] = useState([])
  const [optionsToShow, setOptionsToShow] = useState([])
  const testIsNumber = value => /^\d{0,8}(\.\d{1,2})?$/g.test(value)
  const testIsPercentage = value => /^[0-9][0-9]?(\.\d+)?$|^100$/.test(value)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {productList = []} = useSelector(state => state.productLibrary)
  const {inbox: mailboxes} = useSelector(state => state.mailInbox)

  const {
    fields: actionsArray,
    append: actionsAdd,
    remove: actionsRemove,
  } = useFieldArray({
    name: 'actionJson.actionsArray',
    control,
  })

  useEffect(() => {
    dispatch(getProductlist())
    dispatch(getAllMailInbox())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_MAIL_INBOX)) {
      const list = mailboxes
        .filter(mail => {
          if (mail.type === INBOX_TYPES.WEB || (mail.type === INBOX_TYPES.EMAIL && mail.emailVerified)) {
            return true
          }
          return false
        })
        .map(mail => {
          return {
            key: mail.id,
            value: mail.id,
            text: `${get(['name'], mail, '')}`,
            type: mail.type,
          }
        })
      setMailBoxList(list)
    }
    if (successLabels.includes(GET_PRODUCTS_LIST)) {
      const options = productList.map(item => {
        return {
          productId: item.id,
          title: item.name,
          price: item.sku,
          quantity: 1,
          billingFreq: item.billingFreq,
          unitPrice: Number(item.unitPrice),
          discountType: '%',
          discountValue: 0,
        }
      })
      setSearchBoxOptions(options)
    }
  }, [successLabels, mailboxes, productList])

  const getEntityOptions = () => {
    const options = [
      ...ACTION_ENTITY_OPTIONS,
      ...(automationData.entity === 'conversations'
        ? [
            {
              key: 'conversations',
              value: 'conversations',
              text: 'Conversations',
              description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit',
              icon: <SvgIcon path="common/mail" />,
            },
          ]
        : []),
    ]
    return options.map(({key, value, text, description, icon}) => ({
      key,
      value,
      text,
      content: (
        <div className="d-flex" style={{justifyContent: 'flex-start'}}>
          <VmoIcon className="pr-4">{icon}</VmoIcon>
          <div>
            <VmoHeader as="h5" className="mb-0">
              {text}
            </VmoHeader>
            <VmoHeaderSubheader>{description}</VmoHeaderSubheader>
          </div>
        </div>
      ),
    }))
  }

  const getEventOptions = () => {
    return get([watchActionEntity], ACTION_EVENT_OPTONS, [])
      .filter(option => (automationData.entity === option.entity ? option.value.includes('update') : option))
      .map(({key, value, text, description, icon}) => ({
        key,
        value,
        text,
        content: (
          <div className="d-flex" style={{justifyContent: 'flex-start'}}>
            <VmoIcon className="pr-4">{icon}</VmoIcon>
            <div>
              <VmoHeader as="h5" className="mb-0">
                {text}
              </VmoHeader>
              <VmoHeaderSubheader>{description}</VmoHeaderSubheader>
            </div>
          </div>
        ),
        disabled:
          (automationData.entity === 'deal' && automationData.trigger === 'deleted' && value === 'update_deal') ||
          (automationData.entity === 'contact' && automationData.trigger === 'deleted' && value === 'update_contact') ||
          (automationData.entity === 'company' && automationData.trigger === 'deleted' && value === 'update_company'),
      }))
  }

  const getActionsArrayFieldOptions = () => {
    if (watchActionEntity === 'conversations') {
      if (
        automationData.entity === 'conversations' &&
        automationData.trigger === 'incoming' &&
        automationData.triggerId
      ) {
        const type = mailBoxList.find(list => list.value === automationData.triggerId)?.type
        if (type === 'web') {
          return ACTION_CONVERSATION_WEB_OPTIONS
        }
        if (type === 'email') {
          return ACTION_CONVERSATION_EMAIL_OPTIONS
        }
      }
    }
    return get([watchActionEntity], fieldsForActions, [])
  }

  const renderAppropriateField = (condition, index, defaultVal) => {
    if (
      [
        'company',
        'sourceId',
        'assigneeId',
        'contactTypeId',
        'labelIds',
        'stageId',
        'type',
        'priority',
        'callType',
        'statusId',
        'addLabelIds',
        'removeLabelIds',
        'mailboxId',
        'teamId',
        'tag',
        'status',
      ].includes(condition)
    ) {
      let optionsList
      switch (condition) {
        case 'company':
          optionsList = dropdownData.companyList
          break
        case 'sourceId':
          optionsList = dropdownData.sourceList
          break
        case 'assigneeId':
          optionsList = dropdownData.usersList
          break
        case 'contactTypeId':
          optionsList = dropdownData.contactTypeOptionList
          break
        case 'labelIds':
        case 'addLabelIds':
        case 'removeLabelIds':
        case 'tag':
          optionsList = dropdownData.tagLabelData
          break
        case 'stageId':
          optionsList = dropdownData.dealStageList
          break
        case 'type':
          optionsList = taskTypesList
          break
        case 'priority':
          optionsList = priorityList
          break
        case 'callType':
          optionsList = [
            {value: 'outbound', key: 'outbound', text: 'Outbound'},
            {value: 'inbound', key: 'inbound', text: 'Inbound'},
          ]
          break
        case 'statusId':
        case 'status':
          optionsList = dropdownData.statusList
          break
        case 'mailboxId':
          optionsList = mailBoxList
          break
        case 'teamId':
          optionsList = dropdownData.teamList
          break
        default:
          break
      }
      return (
        <Controller
          name={`actionJson.actionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <VmoFormSelect
              placeholder="Value"
              options={optionsList}
              value={value}
              onChange={(e, {value}) => onChange(value)}
              onClick={() => clearErrors(`actionJson.actionsArray[${index}].value`)}
              error={
                errors?.actionJson?.actionsArray?.[index]?.value && {
                  content: removeDoubleQuotes(errors.actionJson.actionsArray[index].value.message),
                }
              }
            />
          )}
        />
      )
    }
    if (
      [
        'name',
        'domain',
        'monetaryValue',
        'description',
        'firstName',
        'lastName',
        'email',
        'phone',
        'title',
        'subject',
        'duration',
        'note',
      ].includes(condition)
    ) {
      return (
        <Controller
          name={`actionJson.actionsArray[${index}].value`}
          control={control}
          defaultValue={defaultVal}
          render={({value, onChange}) => (
            <VmoFormInput
              placeholder="Value"
              value={value}
              onChange={(e, {value}) => onChange(value)}
              error={
                errors?.actionJson?.actionsArray?.[index]?.value && {
                  content: removeDoubleQuotes(errors.actionJson.actionsArray[index].value.message),
                }
              }
            />
          )}
        />
      )
    }

    return (
      <Controller
        name={`actionJson.actionsArray[${index}].value`}
        control={control}
        defaultValue={defaultVal}
        render={({value, onChange}) => (
          <VmoFormSelect
            placeholder="Value"
            options={[]}
            value={value}
            onChange={(e, {value}) => onChange(value)}
            onClick={() => clearErrors(`actionJson.actionsArray[${index}].value`)}
            error={
              errors?.actionJson?.actionsArray?.[index]?.value && {
                content: removeDoubleQuotes(errors.actionJson.actionsArray[index].value.message),
              }
            }
          />
        )}
      />
    )
  }

  const getField = field => {
    if (field === 'firstName') return 'First Name'
    if (field === 'lastName') return 'Last Name'
    if (field === 'stageId') return 'Stage'
    if (field === 'name') return 'Name'
    if (field === 'assigneeId') return 'Assignee'
    return 'Value'
  }

  const handleSearchChange = (e, {value}) => {
    setSearchText(value)
    setTimeout(() => {
      if (value.length < 1) {
        setOptionsToShow(searchBoxOptions)
        setSearchText('')
        setSearchValue([])
        return
      }
      const re = new RegExp(_.escapeRegExp(searchText), 'i')
      const isMatch = result => re.test(result.title)
      setOptionsToShow(_.filter(searchBoxOptions, isMatch))
    }, 300)
  }

  const handleResultSelect = (e, {result}) => {
    setSearchValue(searchValue => {
      if (searchValue.some(sv => sv.productId === result.productId)) {
        return searchValue
      }
      return [...searchValue, result]
    })
    setSearchText('')
    setOptionsToShow([])
  }

  const changeSearchValue = (index, value, property) => {
    setSearchValue(searchValue => {
      const tempSearchValue = [...searchValue]
      const newValue = {
        ...tempSearchValue[index],
        [property]: value,
      }
      tempSearchValue.splice(index, 1, newValue)
      return tempSearchValue
    })
  }

  const getUnitTotal = (totalPrice, discountType, discountValue) => {
    if (!testIsNumber(totalPrice)) return 0

    if (discountType === '%') {
      if (!testIsPercentage(discountValue)) return 0
      return Number((totalPrice * ((100 - discountValue) / 100)).toFixed(2))
    }

    if (discountType === 'USD') {
      if (!testIsNumber(discountValue)) return 0

      return Number((totalPrice - discountValue).toFixed(2))
    }
  }

  const getTaskField = field => {
    if (field === 'subject') return 'Task Name'
    if (field === 'type') return 'Activity'
    return field
  }

  const submitForm = data => {
    const payload = {...data}

    const {
      actionJson: {actionsArray = []},
    } = payload

    const string1 = <p>{`${startCase(payload.actionEvent)}`}</p>
    let string2 = ``

    if (watchActionEvent.match('delete')) {
      string2 = `performOnlinked is ${payload.actionJson.performOnlinked}`
    } else if (['create_quote', 'update_quote'].includes(watchActionEvent)) {
      if (searchValue.length > 0) {
        const products = searchValue.map(val => ({
          ...val,
          unitTotal: getUnitTotal(val.quantity * val.unitPrice, val.discountType, val.discountValue),
        }))
        // .map(val => _.omit(val, ['title', 'price']))
        payload.actionJson = {...payload.actionJson, products: JSON.stringify(products)}
        string2 = `Quote name is ${payload.actionJson.name}`
      } else {
        vmoToast.error('minimum 1 product must be required')
        toggle(true)
        return
      }
    } else if (['send_internal_email', 'in_app_notify'].includes(watchActionEvent)) {
      const users = dropdownData.usersList
        .filter(user => payload.actionJson.userIds.some(id => user.value === id))
        .map(user => user.text)
      string2 = `User is ${users.join(', ')}`
    } else if (watchActionEvent === 'send_external_email') {
      string2 = `Subject is ${payload.actionJson.subject}`
    } else {
      string2 = actionsArray.map(action => {
        if (
          [
            'company',
            'sourceId',
            'assigneeId',
            'contactTypeId',
            'labelIds',
            'stageId',
            'type',
            'priority',
            'callType',
            'statusId',
            'addLabelIds',
            'removeLabelIds',
            'mailboxId',
            'teamId',
            'tag',
            'status',
          ].includes(action.field)
        ) {
          return <p>{`${startCase(action.field)} is ${action.value}`}</p>
        }
        if (
          [
            'name',
            'domain',
            'monetaryValue',
            'description',
            'firstName',
            'lastName',
            'email',
            'phone',
            'title',
            'subject',
            'duration',
            'note',
          ].includes(action.field)
        ) {
          return <p>{`${startCase(action.field)} is ${action.value}`}</p>
        }
        if (action.field === 'deleteConversation') {
          return <p>{`${startCase(action.field)}`}</p>
        }
      })
    }
    const addString = (
      <div>
        {string1}
        {string2}
      </div>
    )
    handleAdd(addString)
    setAutomationData(automationData => ({...automationData, ...payload}))
    toggle(false)
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="large"
      open={open}
      onClose={() => {
        setEditData(null)
        toggle(false)
      }}
    >
      <VmoModalHeader>Action</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Select Entity</label>
            <Controller
              name="actionEntity"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={getEntityOptions()}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => clearErrors('actionEntity')}
                  error={
                    errors.actionEntity && {
                      content: removeDoubleQuotes(errors.actionEntity.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Select Event</label>
            <Controller
              name="actionEvent"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  options={getEventOptions()}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  onClick={() => clearErrors('actionEvent')}
                  error={
                    errors.actionEvent && {
                      content: removeDoubleQuotes(errors.actionEvent.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>

          {!Array.isArray(watchActionEvent.match('delete')) &&
            Array.isArray(watchActionEntity?.match(/deal|contact|company|conversations/)) &&
            actionsArray.map(({id, field, value}, index) => {
              const conditionField = watch(`actionJson.actionsArray[${index}].field`)
              return (
                <VmoFormGroup key={id}>
                  {/* {!Array.isArray(field.match(/firstName|lastName|stageId|name|assigneeId/)) && (
                    <VmoFormField required>
                      <label>Field</label>
                      <Controller
                        name={`actionJson.actionsArray[${index}].field`}
                        control={control}
                        defaultValue={field}
                        render={({value, onChange}) => (
                          <VmoFormSelect
                            placeholder="Field"
                            options={getActionsArrayFieldOptions()}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                              setValue(`actionJson.actionsArray[${index}].value`, '')
                            }}
                            onClick={() => clearErrors(`actionJson.actionsArray[${index}].field`)}
                            error={
                              errors?.actionJson?.actionsArray?.[index]?.field && {
                                content: removeDoubleQuotes(errors.actionJson.actionsArray[index].field.message),
                              }
                            }
                          />
                        )}
                      />
                    </VmoFormField>
                  )} */}
                  {(watchActionEvent === 'create_deal' &&
                    ['name', 'stageId', 'assigneeId'].includes(field) &&
                    index <= 2) ||
                  (watchActionEvent === 'create_contact' && ['firstName', 'lastName'].includes(field) && index <= 1) ||
                  (watchActionEvent === 'create_company' && field === 'name' && index === 0) ? (
                    <Controller name={`actionJson.actionsArray[${index}].field`} control={control} render={null} />
                  ) : (
                    <VmoFormField required>
                      <label>Field</label>
                      <Controller
                        name={`actionJson.actionsArray[${index}].field`}
                        control={control}
                        defaultValue={field}
                        render={({value, onChange}) => (
                          <VmoFormSelect
                            placeholder="Field"
                            options={getActionsArrayFieldOptions()}
                            value={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                              setValue(`actionJson.actionsArray[${index}].value`, '')
                            }}
                            onClick={() => clearErrors(`actionJson.actionsArray[${index}].field`)}
                            error={
                              errors?.actionJson?.actionsArray?.[index]?.field && {
                                content: removeDoubleQuotes(errors.actionJson.actionsArray[index].field.message),
                              }
                            }
                          />
                        )}
                      />
                    </VmoFormField>
                  )}

                  <VmoFormField required>
                    {conditionField !== 'deleteConversation' && (
                      <>
                        {/* <label>{getField(field)}</label> */}
                        {(watchActionEvent === 'create_deal' &&
                          ['name', 'stageId', 'assigneeId'].includes(field) &&
                          index <= 2) ||
                        (watchActionEvent === 'create_contact' &&
                          ['firstName', 'lastName'].includes(field) &&
                          index <= 1) ||
                        (watchActionEvent === 'create_company' && field === 'name' && index === 0) ? (
                          <label>{getField(field)}</label>
                        ) : (
                          <label>Value</label>
                        )}
                        {renderAppropriateField(conditionField, index, value)}
                      </>
                    )}
                  </VmoFormField>

                  {/* {actionsArray.length > 1 &&
                    !Array.isArray(field.match(/firstName|lastName|stageId|name|assigneeId/)) && (
                      <VmoFormField>
                        <VmoButton style={{marginTop: '24px'}} primary onClick={() => actionsRemove(index)}>
                          Remove
                        </VmoButton>
                      </VmoFormField>
                    )} */}

                  {actionsArray.length > 1 &&
                    ((watchActionEvent === 'create_deal' && index > 2) ||
                      (watchActionEvent === 'create_contact' && index > 1) ||
                      (watchActionEvent === 'create_company' && index > 0)) && (
                      <VmoFormField>
                        <VmoButton style={{marginTop: '24px'}} primary onClick={() => actionsRemove(index)}>
                          Remove
                        </VmoButton>
                      </VmoFormField>
                    )}
                  {index === actionsArray.length - 1 && (
                    <VmoFormField>
                      <VmoButton style={{marginTop: '24px'}} primary onClick={() => actionsAdd({field: '', value: ''})}>
                        Add
                      </VmoButton>
                    </VmoFormField>
                  )}
                </VmoFormGroup>
              )
            })}

          {['create_quote', 'update_quote'].includes(watchActionEvent) && (
            <>
              <VmoFormField required width={5}>
                <label>Quote Name</label>
                <Controller
                  name="actionJson.name"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      placeholder="Name"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors?.actionJson?.name && {content: removeDoubleQuotes(errors.actionJson.name.message)}}
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField>
                <label>Select Product & Services</label>
                <p className="kooper-lead">
                  Select your product or service for which you would like to make quote. You can add the product{' '}
                  <a href="">here</a>.
                </p>
                <VmoSearch
                  placeholder="Search to get needed results"
                  minCharacters={0}
                  results={optionsToShow}
                  value={searchText}
                  onResultSelect={handleResultSelect}
                  onSearchChange={_.debounce(handleSearchChange, 500, {
                    leading: true,
                  })}
                />
              </VmoFormField>
              <VmoTable basic className="billProduct">
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Product Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>SKU</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billing Frequency</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Quantity</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Price</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Discount (%)</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Net Price</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {searchValue.length === 0 ? (
                    <VmoTableRow>
                      <VmoTableCell colSpan="8" className="emptyValue required">
                        Add Line Items to Continue Filling Form
                      </VmoTableCell>
                    </VmoTableRow>
                  ) : (
                    searchValue.map((item, index) => (
                      <VmoTableRow key={item.productId}>
                        <VmoTableCell>{index + 1}</VmoTableCell>
                        <VmoTableCell>{item.title}</VmoTableCell>
                        <VmoTableCell>{item.price}</VmoTableCell>
                        <VmoTableCell>
                          <VmoFormSelect
                            fluid
                            options={billingFreqOptions}
                            value={item.billingFreq}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'billingFreq')}
                          />
                        </VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            min={1}
                            value={item.quantity}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'quantity')}
                            style={{width: '70px'}}
                            onBlur={() => {
                              if (!testIsNumber(item.quantity)) {
                                changeSearchValue(index, '0', 'quantity')
                              }
                            }}
                          />
                        </VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            style={{width: '70px'}}
                            value={item.unitPrice}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'unitPrice')}
                            onBlur={() => {
                              if (!testIsNumber(item.unitPrice)) {
                                changeSearchValue(index, '0', 'unitPrice')
                              }
                            }}
                          />
                        </VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            style={{width: '100px'}}
                            value={item.discountValue}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'discountValue')}
                            onBlur={() => {
                              if (item.discountType === '%') {
                                if (!testIsPercentage(item.discountValue)) {
                                  changeSearchValue(index, '0', 'discountValue')
                                }
                              } else if (item.discountType !== '%') {
                                if (!testIsNumber(item.discountValue)) {
                                  changeSearchValue(index, '0', 'discountValue')
                                }
                              }
                            }}
                            label={
                              <VmoDropdown
                                options={typeOptions}
                                value={item.discountType}
                                onChange={(e, {value}) => changeSearchValue(index, value, 'discountType')}
                              />
                            }
                          />
                        </VmoTableCell>
                        <VmoTableCell>
                          {getUnitTotal(item.quantity * item.unitPrice, item.discountType, item.discountValue)}
                        </VmoTableCell>
                      </VmoTableRow>
                    ))
                  )}
                </VmoTableBody>
              </VmoTable>
            </>
          )}

          {['send_internal_email', 'in_app_notify'].includes(watchActionEvent) && (
            <VmoFormField required width={5}>
              <label>Users</label>
              <Controller
                name="actionJson.userIds"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    placeholder="Users"
                    multiple
                    value={value}
                    options={dropdownData.usersList}
                    onChange={(e, {value}) => onChange(value)}
                    onClick={() => clearErrors('actionJson.userIds')}
                    error={
                      errors?.actionJson?.userIds && {content: removeDoubleQuotes(errors.actionJson.userIds.message)}
                    }
                  />
                )}
              />
            </VmoFormField>
          )}

          {watchActionEvent === 'send_external_email' && (
            <>
              <VmoFormField required width={5}>
                <label>Subject</label>
                <Controller
                  name="actionJson.subject"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      placeholde="subject"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors?.actionJson?.subject && {content: removeDoubleQuotes(errors.actionJson.subject.message)}
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required width={5}>
                <label>Body</label>
                <Controller
                  name="actionJson.body"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      placeholde="body"
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors?.actionJson?.body && {content: removeDoubleQuotes(errors.actionJson.body.message)}}
                    />
                  )}
                />
              </VmoFormField>
            </>
          )}

          {watchActionEntity === 'task' && watchActionEvent !== 'delete_task' && (
            <VmoGrid>
              <VmoGridRow columns={2}>
                {actionsArray.map(({id, field, value}, index) => {
                  const conditionField = watch(`actionJson.actionsArray[${index}].field`)
                  return (
                    <VmoGridColumn className="mb-3" key={id}>
                      <Controller name={`actionJson.actionsArray[${index}].field`} control={control} render={null} />
                      <VmoFormField required>
                        <label>{startCase(getTaskField(field))}</label>
                        {renderAppropriateField(conditionField, index, value)}
                      </VmoFormField>
                    </VmoGridColumn>
                  )
                })}
              </VmoGridRow>
            </VmoGrid>
          )}

          {automationData.entity !== watchActionEntity && watchActionEvent?.match(/update|delete/) !== null && (
            <Controller
              name="actionJson.performOnlinked"
              control={control}
              render={({value, onChange}) => (
                <VmoCheckbox
                  className="mb-3"
                  label="performOnlinked"
                  checked={value}
                  onChange={(e, {checked}) => onChange(checked)}
                />
              )}
            />
          )}
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            setEditData(null)
            toggle(false)
          }}
        >
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Save
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default AddAction
