import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormCheckbox,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {CREATE_PROJECTS_TIMESHEET, GET_PROJECTS_SINGLE_TIMESHEET, UPDATE_PROJECTS_TIMESHEET} from 'actions/types'
import {createProjectsTimesheet, getProjectsSingleTimesheet, updateProjectsTimesheet} from 'actions/projects'
import {get, removeDoubleQuotes} from 'utils/helper'
import _ from 'lodash'
import {timesheetSchema} from 'validation/Projects/projects/time.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const timesheetDefaultValues = {
  title: '',
  enableEstimation: false,
  estimatedHrs: null,
  estimatedMins: null,
  isPublic: true,
  subscribers: [],
}

const TimesheetModal = ({open, toggle, projectId, timesheetId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = [], timesheetData = {}} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, watch, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(timesheetSchema),
    defaultValues: timesheetDefaultValues,
  })

  useApiResponse({
    action: getProjectsSingleTimesheet,
    payload: {projectId, timesheetId},
    dependency: [projectId, timesheetId],
    enabled: projectId && timesheetId,
    label: GET_PROJECTS_SINGLE_TIMESHEET,
    storePath: 'projects.timesheetData',
    onSuccess: (timesheetData = {}) => {
      const data = {
        ..._.pick(timesheetData, ['title', 'enableEstimation', 'estimatedHrs', 'estimatedMins', 'isPublic']),
        subscribers: get(['subscribers'], timesheetData, []),
      }
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TIMESHEET) || successLabels.includes(UPDATE_PROJECTS_TIMESHEET)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (timesheetId) {
      dispatch(updateProjectsTimesheet({projectId, timesheetId, data}))
    } else {
      dispatch(createProjectsTimesheet({projectId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{`${timesheetId ? 'Edit' : 'Add'} Timesheet`}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <Controller
              name="enableEstimation"
              control={control}
              render={({value, onChange}) => (
                <VmoFormCheckbox
                  label="Estimated time"
                  checked={value}
                  onChange={(e, {checked}) => onChange(checked)}
                />
              )}
            />
          </VmoFormField>
          {watch('enableEstimation') && (
            <VmoFormGroup>
              <VmoFormField required width={8}>
                <div className="info-header">
                  <label>Hours</label>
                  <VmoTooltip
                    content="Set time in terms of hours"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <Controller
                  name="estimatedHrs"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      type="number"
                      value={value}
                      onChange={(e, {value}) => onChange(+value)}
                      min={0}
                      max={24}
                      error={
                        errors.estimatedHrs && {
                          content: removeDoubleQuotes(errors.estimatedHrs.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required width={8}>
                <div className="info-header">
                  <label>Minutes</label>
                  <VmoTooltip
                    content="Set time in terms of minutes"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="right center"
                  />
                </div>
                <Controller
                  name="estimatedMins"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      type="number"
                      value={value}
                      onChange={(e, {value}) => onChange(+value)}
                      min={0}
                      max={59}
                      error={
                        errors.estimatedMins && {
                          content: removeDoubleQuotes(errors.estimatedMins.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
          )}
          <VmoFormField>
            <div className="d-flex">
              <div>
                <label>Mark as private</label>
              </div>
              <Controller
                name="isPublic"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormRadio toggle checked={!value} onClick={(e, {checked}) => onChange(!checked)} />
                )}
              />
            </div>
          </VmoFormField>
          {!watch('isPublic') && (
            <VmoFormField required>
              <label>Subscribers</label>
              <Controller
                name="subscribers"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    search
                    multiple
                    options={projectAssignees.map(({id, firstName, lastName}) => ({
                      key: id,
                      value: id,
                      text: `${firstName} ${lastName}`,
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                  />
                )}
              />
            </VmoFormField>
          )}
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {timesheetId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TimesheetModal
