import {apiAction} from './api'
import {CLIENT_PORTAL_ENDPOINT} from './endpoint'
import {
  GET_CLIENT_PORTAL_SETTINGS,
  CREATE_CLIENT_PORTAL_ANNOUNCEMENT,
  DELETE_CLIENT_PORTAL_ANNOUNCEMENT,
  GET_CLIENT_PORTAL_ANNOUNCEMENTS,
  GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT,
  UPDATE_CLIENT_PORTAL_ANNOUNCEMENT,
  UPLOAD_CLIENT_PORTAL_LOGO,
  UPDATE_CLIENT_PORTAL_SETTINGS,
  GET_CLIENT_PORTAL_TICKET_FORM,
  UPDATE_CLIENT_PORTAL_TICKET_FORM,
} from './types'

export function createClientPortalAnnouncement(data) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/announcements`,
    method: 'POST',
    label: CREATE_CLIENT_PORTAL_ANNOUNCEMENT,
    data,
    showToast: true,
    successMessage: 'Announcement Created',
  })
}

export function getClientPortalAnnouncements(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/announcements/?${query}`,
    method: 'GET',
    label: GET_CLIENT_PORTAL_ANNOUNCEMENTS,
  })
}

export function getClientPortalSingleAnnouncement(id) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/announcements/${id}`,
    method: 'GET',
    label: GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT,
  })
}

export function updateClientPortalAnnouncement(id, data) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/announcements/${id}`,
    method: 'PUT',
    label: UPDATE_CLIENT_PORTAL_ANNOUNCEMENT,
    data,
    showToast: true,
    successMessage: 'Announcement Updated',
  })
}

export function deleteClientPortalAnnouncement(id) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/announcements/${id}`,
    method: 'DELETE',
    label: DELETE_CLIENT_PORTAL_ANNOUNCEMENT,
    showToast: true,
    successMessage: 'Announcement Deleted',
  })
}

export function getClientPortalSettings() {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/setting`,
    method: 'GET',
    label: GET_CLIENT_PORTAL_SETTINGS,
  })
}

export function updateClientPortalSettings(data) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/setting`,
    method: 'PUT',
    data,
    label: UPDATE_CLIENT_PORTAL_SETTINGS,
    showToast: true,
    successMessage: 'Settings Updated',
  })
}

export function uploadClientPortalLogo(data) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/logo`,
    method: 'POST',
    label: UPLOAD_CLIENT_PORTAL_LOGO,
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Logo Uploaded',
  })
}

export function getClientPortalTicketForm() {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/form`,
    method: 'GET',
    label: GET_CLIENT_PORTAL_TICKET_FORM,
  })
}

export function updateClientPortalTicketForm(data) {
  return apiAction({
    url: `${CLIENT_PORTAL_ENDPOINT}/form`,
    method: 'PUT',
    label: UPDATE_CLIENT_PORTAL_TICKET_FORM,
    data,
    showToast: true,
    successMessage: 'Tickets Updated',
  })
}
