import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiBarChart} from 'react-icons/fi'
import {ResponsiveLine} from '@nivo/line'
import Moment from 'moment'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS, METRICS_RESOLUTION_BREAKDOWN_ANALYTICS} from 'actions/types'
import {
  averageRepliesResolution,
  averageResolutionTimeStats,
  firstContactResolved,
  firstResponseTimeStats,
} from 'actions/analytics_inbox'
import {metricsFirstResponseBreakdownAnalytics, metrixResolutionBreakdownAnalytics} from 'actions/analytics_metrics'
import CustomLoader from 'components/common/CustomLoader'
import {getGridYValues, maxOfGraph} from 'utils/graph'
import {adjustTimeUnit, get} from 'utils/helper'
import {AssigneeDropdown, MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const Metrics = () => {
  const dispatch = useDispatch()

  const [firstResponseData, setFirstResponseData] = useState([])
  const [avgResoltutionData, setAvgResolutionData] = useState([])

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    firstResponseTimeData = {},
    averageResoltionTimeData = {},
    firstContactResolvedData = {},
    averageRepliesResolutionData = {},
    analyticsSelectedAssignee,
    analyticsSelectedInbox,
  } = useSelector(state => state.analyticsInbox)
  const {metricsFirstResponseBreakdown = {}, metricsResolutionBreakdown = {}} = useSelector(
    state => state.analyticsMetrics
  )
  const {firstResponseBreakdown = {}, formattedData: firstResponseGraph = {}} = metricsFirstResponseBreakdown
  const {resolutionBreakdown = {}, formattedData: avgResolutionGraph = {}} = metricsResolutionBreakdown
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const metricsAccess = analyticsAccess?.metrics
  const performanceReportsPermissions = reportsPermissions?.performance_reports

  useEffect(() => {
    if (Object.keys(firstResponseGraph).length > 0) {
      setFirstResponseData([firstResponseGraph])
    }
  }, [firstResponseGraph])

  useEffect(() => {
    if (Object.keys(avgResolutionGraph).length > 0) {
      setAvgResolutionData([avgResolutionGraph])
    }
  }, [avgResolutionGraph])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(firstResponseTimeStats(data))
      dispatch(averageResolutionTimeStats(data))
      dispatch(firstContactResolved(data))
      dispatch(averageRepliesResolution(data))
      dispatch(metricsFirstResponseBreakdownAnalytics(data))
      dispatch(metrixResolutionBreakdownAnalytics(data))
    },
    [dispatch]
  )

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(localStorage.getItem(DATE_RANGE_KEY)))).diff(
    get(['persistDateRangeStartDate'], JSON.parse(localStorage.getItem(DATE_RANGE_KEY))),
    'days'
  )

  const returnFixedNo = x => {
    return Number.parseFloat(x).toFixed(1)
  }

  const renderFirstResponseTimeBody = () => {
    if (type.includes(METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS)) {
      return <VmoTablePlaceholder columns={2} lines={1} />
    }
    if (
      !type.includes(METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS) &&
      firstResponseBreakdown &&
      Object.keys(firstResponseBreakdown).length === 0
    ) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="2" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return (
      <>
        <VmoTableRow>
          <VmoTableCell>&lt; 1h</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.between1Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>1h - 8h</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.between1to8Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>8h - 24h</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.between8to24Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>1d - 3d</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.between1to3Day)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>3d - 7d</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.between3to7Day)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>&gt; 7d</VmoTableCell>
          <VmoTableCell>{firstResponseBreakdown && returnFixedNo(firstResponseBreakdown.after7Day)}%</VmoTableCell>
        </VmoTableRow>
      </>
    )
  }

  const renderResolutionTimeBody = () => {
    if (type.includes(METRICS_RESOLUTION_BREAKDOWN_ANALYTICS)) {
      return <VmoTablePlaceholder columns={2} lines={1} />
    }
    if (
      !type.includes(METRICS_RESOLUTION_BREAKDOWN_ANALYTICS) &&
      resolutionBreakdown &&
      Object.keys(resolutionBreakdown).length === 0
    ) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan={2} className="emptyValue">
            Data Not Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return (
      <>
        <VmoTableRow>
          <VmoTableCell>&lt; 1h</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.between1Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>1h - 8h</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.between1to8Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>8h - 24h</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.between8to24Hr)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>1d - 3d</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.between1to3Day)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>3d - 7d</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.between3to7Day)}%</VmoTableCell>
        </VmoTableRow>
        <VmoTableRow>
          <VmoTableCell>&gt; 7d</VmoTableCell>
          <VmoTableCell>{resolutionBreakdown && returnFixedNo(resolutionBreakdown.after7Day)}%</VmoTableCell>
        </VmoTableRow>
      </>
    )
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/metrics" />
            <h5>Metrics</h5>
          </div>
        </div>
        {metricsAccess && performanceReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={4}>
          <VmoCard fluid>
            <VmoCardContent className="valueBox">
              <VmoCardHeader>First Response time</VmoCardHeader>
              <h3>
                {adjustTimeUnit(firstResponseTimeData.avg)}
                <span
                  className={get(['growth'], firstResponseTimeData, 'positive') === 'positive' ? '' : 'text-danger'}
                >
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], firstResponseTimeData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {firstResponseTimeData.percentage}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid>
            <VmoCardContent className="valueBox">
              <VmoCardHeader>Avg Resolution time</VmoCardHeader>
              <h3>
                {adjustTimeUnit(averageResoltionTimeData.avg)}
                <span
                  className={get(['growth'], averageResoltionTimeData, 'positive') === 'positive' ? '' : 'text-danger'}
                >
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], averageResoltionTimeData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {averageResoltionTimeData.percentage}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid>
            <VmoCardContent className="valueBox">
              <VmoCardHeader>First contact resolved</VmoCardHeader>
              <h3>
                {get(['resolved'], firstContactResolvedData, 0)}
                <span
                  className={get(['growth'], firstContactResolvedData, 'positive') === 'positive' ? '' : 'text-danger'}
                >
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], firstContactResolvedData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], firstContactResolvedData)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={4}>
          <VmoCard fluid>
            <VmoCardContent className="valueBox">
              <VmoCardHeader>Avg Replies per resolution</VmoCardHeader>
              <h3>
                {get(['avg'], averageRepliesResolutionData, 0)}
                <span
                  className={
                    get(['growth'], averageRepliesResolutionData, 'positive') === 'positive' ? '' : 'text-danger'
                  }
                >
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Compared to Previous Duration"
                    trigger={
                      <div>
                        {get(['growth'], averageRepliesResolutionData, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], averageRepliesResolutionData)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>First Response Time over Period</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of first response time to conversations over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              {type.includes(METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS) && <CustomLoader />}
              {!type.includes(METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS) &&
                !firstResponseData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS) && (
                <ResponsiveLine
                  data={firstResponseData}
                  margin={{top: 27, right: 27, bottom: 60, left: 80}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(firstResponseData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(firstResponseData)}
                  curve="linear"
                  sliceTooltip={({slice}) => {
                    return (
                      <div
                        style={{
                          background: 'white',
                          padding: '4px 9px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {slice.points.map(point => (
                          <div
                            key={point.id}
                            style={{
                              color: point.serieColor,
                              padding: '3px 0',
                            }}
                          >
                            {point.serieId} [
                            <strong>
                              {Moment.utc(Moment.duration(point.data.yFormatted, 'seconds').asMilliseconds()).format(
                                'H[h] m[m]'
                              )}
                            </strong>
                            ]
                          </div>
                        ))}
                      </div>
                    )
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    format: value => Moment.utc(Moment.duration(value, 'seconds').asMilliseconds()).format('H[h] m[m]'),
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Avg First Response',
                    tickValues: getGridYValues(firstResponseData),
                    legendOffset: -60,
                    legendPosition: 'middle',
                  }}
                  colors={{scheme: 'nivo'}}
                  lineWidth={2}
                  pointSize={3}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  useMesh
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Avg Resolution Time over Period</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of Average resolution time to conversations over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              {type.includes(METRICS_RESOLUTION_BREAKDOWN_ANALYTICS) && <CustomLoader />}
              {!type.includes(METRICS_RESOLUTION_BREAKDOWN_ANALYTICS) &&
                !avgResoltutionData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(METRICS_RESOLUTION_BREAKDOWN_ANALYTICS) && (
                <ResponsiveLine
                  data={avgResoltutionData}
                  margin={{top: 27, right: 27, bottom: 60, left: 80}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(avgResoltutionData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableSlices="x"
                  enableGridX={false}
                  gridYValues={getGridYValues(avgResoltutionData)}
                  curve="linear"
                  sliceTooltip={({slice}) => {
                    return (
                      <div
                        style={{
                          background: 'white',
                          padding: '4px 9px',
                          border: '1px solid #ccc',
                        }}
                      >
                        {slice.points.map(point => (
                          <div
                            key={point.id}
                            style={{
                              color: point.serieColor,
                              padding: '3px 0',
                            }}
                          >
                            {point.serieId} [
                            <strong>
                              {Moment.utc(Moment.duration(point.data.yFormatted, 'seconds').asMilliseconds()).format(
                                'H[h] m[m]'
                              )}
                            </strong>
                            ]
                          </div>
                        ))}
                      </div>
                    )
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    format: tick => {
                      if (diff > 0 && diff < 17) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    tickRotation: 0,
                    legend: 'Time',
                    legendOffset: 60,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    format: value => Moment.utc(Moment.duration(value, 'seconds').asMilliseconds()).format('H[h] m[m]'),
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Avg Resolution',
                    tickValues: getGridYValues(avgResoltutionData),
                    legendOffset: -60,
                    legendPosition: 'middle',
                  }}
                  colors={{scheme: 'category10'}}
                  lineWidth={2}
                  pointSize={3}
                  pointColor="#ffffff"
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-12}
                  useMesh
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 5,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>First Response Time Breakdown</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of first response time for selected range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>First Response</VmoTableHeaderCell>
                    <VmoTableHeaderCell>% Replies</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderFirstResponseTimeBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Resolution Time Breakdown</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of resolution time for selected range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Resolution time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>% Replies</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderResolutionTimeBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Metrics
