import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {VmoButton, VmoFormInput, VmoModal, VmoModalActions, VmoModalContent} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'

function QuoteModal({open, toggle, link}) {
  const {push} = useHistory()
  const [copyLink, setCopyLink] = useState('Copy')
  return (
    <VmoModal
      open={open}
      onClose={toggle}
      size="small"
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      style={{textAlign: 'center'}}
    >
      <VmoModalContent>
        <h1>Download as PDf</h1>
        <h2 className="mt-3">A web page for your quote has been created</h2>
        <p className="my-4">
          Your quote has been successfully created, below is the link to download and view it. You can also send this
          quote directly to the customer from below button
        </p>

        <VmoFormInput
          readOnly
          value={link.link}
          style={{width: '80%'}}
          action={{
            color: 'blue',
            labelPosition: 'right',
            icon: 'copy',
            content: copyLink,
            onClick: () => {
              navigator.clipboard.writeText(link.link)
              setCopyLink('Copied')
            },
          }}
        />
        <div className="mt-4">
          <VmoButton
            primary
            onClick={() => {
              push({
                pathname: '/inbox',
                state: {
                  quoteState: link.link,
                },
              })
            }}
          >
            Send Quote
          </VmoButton>
          {/* <VmoButton basic className="ml-3">
            Download
          </VmoButton> */}
        </div>
      </VmoModalContent>

      <VmoModalActions>
        <VmoButton basic onClick={toggle}>
          Cancel
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default QuoteModal
