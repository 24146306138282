import React, {useCallback} from 'react'
import {FaCalendar} from 'react-icons/fa'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectsPlanningReportUtilization} from 'actions/analytics_projects'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {GET_PROJECTS_PLANNING_REPORT_UTILIZATION} from 'actions/types'
import {startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import DateRangeGroup from '../../DateRangeGroup'

const ResourceUtilization = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {projectsPlanningReportUtilization = []} = useSelector(state => state.analyticsProjects)

  const actionsToDispatch = useCallback(
    data => {
      const {from, to} = data
      const payload = {from, to}
      dispatch(getProjectsPlanningReportUtilization(payload))
    },
    [dispatch]
  )

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_PLANNING_REPORT_UTILIZATION)) {
      return <VmoTablePlaceholder columns={9} />
    }
    if (!type.includes(GET_PROJECTS_PLANNING_REPORT_UTILIZATION) && !projectsPlanningReportUtilization.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="9" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return projectsPlanningReportUtilization.map(u => (
      <VmoTableRow className="tableLink" key={u.id}>
        <VmoTableCell>{startCase(`${u.firstName} ${u.lastName}`)}</VmoTableCell>
        <VmoTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.estimatedUtilization ?? 0}}%`}} />
            <div className="content">
              <span>{u.estimatedUtilization ?? 0}%</span>
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.actualUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.actualUtilization ?? 0}%</span>
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.billableUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.billableUtilization ?? 0}%</span>
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>
          <div className="project-resource-utilization-cell">
            <div className="progress" style={{width: `${u.nonbillableUtilization ?? 0}%`}} />
            <div className="content">
              <span>{u.nonbillableUtilization ?? 0}%</span>
            </div>
          </div>
        </VmoTableCell>
        <VmoTableCell>{u.availableTime}h</VmoTableCell>
        <VmoTableCell>{u.loggedTime}</VmoTableCell>
        <VmoTableCell>{u.billableTime}</VmoTableCell>
        <VmoTableCell>{u.nonBillableTime}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/resource-utilization" />
            <h5>Resource Utilization</h5>
          </div>
        </div>
        <div className="page-action">
          <DateRangeGroup actionsToDispatch={actionsToDispatch} />
        </div>
      </div>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Resource Utilization</VmoCardHeader>
              <VmoTooltip
                content="List of all agents based on resources utilized over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>User</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Estimated Utilization</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Actual Utilization</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billable Utilization</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Non-billable Utilization</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Available Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Logged Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Billable Time</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Non-Billable Time</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTableBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default ResourceUtilization
