/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm, Controller} from 'react-hook-form'
import {useHistory, useParams} from 'react-router'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormGroup,
  VmoFormTextArea,
  VmoFormSelect,
  VmoTooltip,
} from 'vmo-library'
import {CREATE_VENDORS, DELETE_VENDOR_IMAGE, GET_USERS, GET_VENDORS_DATA, UPDATE_VENDORS} from 'actions/types'
import {
  getVendorsCategory,
  getVendorFieldByCategory,
  createVendors,
  getVendorsData,
  updateVendors,
  deleteVendorImage,
} from 'actions/vendors'
import {get, KooperCountryList, removeDoubleQuotes} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {createVendorSchema} from 'validation/Finance/vendors.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import {getUsers} from 'actions/settings'
import useApiResponse from 'hooks/impure/useApiResponse'

const InputField = ({control, errors, field}) => {
  const {name, type, required} = field

  if (type === 'Textarea') {
    return (
      <Controller
        name={`fields[${name}]`}
        render={({value, onChange}) => (
          <VmoFormTextArea
            label={name}
            // required={required}
            value={value}
            onChange={e => onChange(e.target.value)}
            error={
              errors?.fields?.[name] && {
                content: errors.fields?.[name].message,
              }
            }
          />
        )}
        control={control}
      />
    )
  }
  return (
    <Controller
      name={`fields[${name}]`}
      render={({value, onChange}) => (
        <VmoFormInput
          // required={required}
          type={type}
          label={name}
          value={value}
          onChange={e => onChange(e.target.value)}
          error={
            errors?.fields?.[name] && {
              content: errors.fields?.[name].message,
            }
          }
        />
      )}
      control={control}
    />
  )
}

function checkRequiredLogic(fieldsList, watchFields) {
  const allFieldList = fieldsList
    .filter(f => f.required)
    .map(field => {
      return field.name
    })

  return allFieldList.every(value => {
    return (
      watchFields[value] !== null &&
      watchFields[value] !== undefined &&
      watchFields[value] !== '' &&
      watchFields[value] !== []
    )
  })
}

function CreateModal() {
  const history = useHistory()
  const dispatch = useDispatch()
  const inputRef = useRef()
  const {id: editId} = useParams()

  const [userOption, setUserOption] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [fieldsList, setFieldsList] = useState([])
  const [errorMessage, setErrorMessage] = useState(false)
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getVendorsCategoryData, fieldsByCategory} = useSelector(state => state.vendors)
  const {financePermissions} = useUserPermissions()
  const manageVendorsPermissions = financePermissions?.manage_vendors

  const initialCreateInfo = {
    assigneeId: null,
    name: '',
    email: '',
    phone: '',
    website: '',
    categoryId: '',
    address: {},
    description: '',
    fields: {},
  }

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createVendorSchema),
    defaultValues: initialCreateInfo,
  })
  const watchCategoryId = watch('categoryId')
  const watchFields = watch('fields')

  const onSelectFile = e => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    setSelectedFile(e.target.files[0])
  }

  useEffect(() => {
    if (!editId) {
      window.history.pushState(null, '', window.location.href)

      return () => (window.onpopstate = () => {})
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(CREATE_VENDORS) || successLabels.includes(UPDATE_VENDORS)) {
      history.push(`/vendors`)
    }
    if (successLabels.includes(DELETE_VENDOR_IMAGE)) {
      setPreview(null)
      setSelectedFile(null)
    }
  }, [successLabels, history, dispatch])

  useEffect(() => {
    if (getVendorsCategoryData)
      setCategoryList(
        getVendorsCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
  }, [getVendorsCategoryData])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list || [])
    },
  })

  const {data: vendorsData = {}} = useApiResponse({
    action: getVendorsData,
    payload: editId,
    dependency: [editId],
    enabled: editId,
    label: GET_VENDORS_DATA,
    storePath: 'vendors.vendorsData',
    onSuccess: () => {
      const {assigneeId, name, email, categoryId, address, fields, profileImage} = vendorsData
      if (address) {
        delete address?.id
      }
      reset({
        assigneeId,
        name,
        email,
        phone: get(['phone'], vendorsData, ''),
        website: get(['website'], vendorsData, ''),
        categoryId,
        description: get(['description'], vendorsData, ''),
        fields,
        address,
      })
      setPreview(profileImage)
    },
  })

  useEffect(() => {
    if (watchCategoryId) {
      const list = (fieldsByCategory || []).find(category => watchCategoryId === category.id)?.fields || []

      setFieldsList(list)
    }
  }, [watchCategoryId, fieldsByCategory])

  const checkReqruiredFields = checkRequiredLogic(fieldsList, watchFields)
  useEffect(() => {
    setErrorMessage(checkReqruiredFields)
  }, [checkReqruiredFields])

  useEffect(() => {
    if (!fieldsByCategory) dispatch(getVendorFieldByCategory())
    if (!getVendorsCategoryData) {
      dispatch(getVendorsCategory())
    }
  }, [fieldsByCategory, getVendorsCategoryData, dispatch])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  useEffect(() => {
    if (!fieldsByCategory) dispatch(getVendorFieldByCategory())
    if (!getVendorsCategoryData) {
      dispatch(getVendorsCategory())
    }
  }, [dispatch])

  function submitForm(data) {
    const formData = new FormData()

    if (selectedFile) {
      formData.append('image', selectedFile)
    }

    for (const key in data) {
      if (key === 'fields') {
        formData.append(key, JSON.stringify(data[key]))
      } else if (key === 'address') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }

    if (editId) {
      return dispatch(updateVendors(editId, formData))
    }

    dispatch(createVendors(formData))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <VmoButton primary onClick={() => history.push('/vendors')}>
          Back
        </VmoButton>
        <h3 className="m-0">Create Vendors</h3>
        <LockPermissionTooltip isRoleAccessDenied={!manageVendorsPermissions}>
          <VmoButton
            primary
            className={!manageVendorsPermissions ? 'disabled-button' : ''}
            {...(manageVendorsPermissions && {
              onClick: handleSubmit(submitForm),
            })}
            disabled={editId ? !isDirty && preview === vendorsData?.profileImage : !isDirty}
          >
            {editId ? 'Update' : 'Create'}
          </VmoButton>
        </LockPermissionTooltip>
      </div>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Vendor Information</h3>
            <p className="mt-0 mb-4 card-description">
              Create a profile of the vendor by mentioning all the required information
            </p>
            <VmoForm className="errorLabel">
              {preview ? (
                <>
                  <img
                    onClick={() => inputRef.current.click()}
                    accept="image/*"
                    src={preview}
                    alt="product"
                    height="200"
                    width="200"
                  />
                  <div>
                    <VmoTooltip
                      content="Delete"
                      size="mini"
                      position="top center"
                      trigger={
                        <VmoButton
                          icon
                          onClick={() => {
                            if (editId) {
                              setIsDeleteModalOpen(true)
                            } else {
                              setPreview(null)
                              setSelectedFile(null)
                            }
                          }}
                        >
                          <SvgIcon path="common/delete" />
                        </VmoButton>
                      }
                    />
                  </div>
                </>
              ) : (
                <VmoFormField>
                  <div className="info-header">
                    <label>Image</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="upload vendors image"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <VmoButton basic size="small" onClick={() => inputRef.current.click()}>
                    Upload Image
                  </VmoButton>
                </VmoFormField>
              )}
              <VmoFormGroup widths="equal">
                <VmoFormField required width={8}>
                  <div className="info-header">
                    <label className="label-class">Owner</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Assign a owner for the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="assigneeId"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        fluid
                        options={userOption}
                        selection
                        placeholder="Owners"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.assigneeId && {
                            content: removeDoubleQuotes(errors?.assigneeId?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>

                <VmoFormField required width={8}>
                  <div className="info-header">
                    <label className="label-class">Vendor Name</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify the name of the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="name"
                    control={control}
                    render={({onChange, value}) => (
                      <VmoFormInput
                        type="text"
                        maxLength={20}
                        placeholder="Vendor Name"
                        onChange={(e, {value}) => onChange(value)}
                        value={value}
                        error={
                          errors.name && {
                            content: removeDoubleQuotes(errors.name.message),
                          }
                        }
                      />
                    )}
                  />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <div className="info-header">
                    <label>Phone Number</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify phone number of the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="phone"
                    render={({value, onChange, onBlur}) => (
                      <VmoFormInput
                        fluid
                        type="text"
                        name="Phone"
                        placeholder="Type your number.."
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.phone && {
                            content: errors.phone.message,
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>

                <VmoFormField required>
                  <div className="info-header">
                    <label className="label-class">Email</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify email id of the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="email"
                    render={({value, onChange, onBlur}) => (
                      <VmoFormInput
                        fluid
                        type="email"
                        placeholder="Type your email.."
                        value={value}
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.email && {
                            content: removeDoubleQuotes(errors.email.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <div className="info-header">
                    <label>Website</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="enter the website"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="website"
                    render={({value, onChange, onBlur}) => (
                      <VmoFormInput
                        fluid
                        maxLength={200}
                        type="text"
                        name="companyWebsite"
                        placeholder="Type your company website.."
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.website && {
                            content: removeDoubleQuotes(errors.website.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>

                <VmoFormField required>
                  <div className="info-header">
                    <label className="label-class">Category</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Select the category under which the vendor belongs"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="categoryId"
                    render={({value, onChange}) => (
                      <VmoFormSelect
                        search
                        fluid
                        options={categoryList}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.categoryId && {
                            content: removeDoubleQuotes(errors.categoryId.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              </VmoFormGroup>

              {fieldsList.map(field => {
                return <InputField errors={errors} control={control} field={field} id={field.id} />
              })}

              {/* {!errorMessage && (
                <p style={{color: 'red'}}>
                  Fields with * signs are required please fill them before submitting the form
                </p>
              )} */}

              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Description</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify a brief description about the vendor "
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  render={({onChange, value}) => (
                    <VmoFormTextArea
                      maxLength={160}
                      type="text"
                      value={value}
                      placeholder="Description"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
        <VmoCard fluid>
          <VmoCardContent>
            <VmoForm>
              <h3 className="mb-0">Address Information</h3>
              <p className="mt-0 mb-4 card-description">
                Keep detailed record of vendor’s address for security purpose
              </p>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <div className="info-header">
                    <label>Street</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="specify street name where vendor lives"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="address.streetAddress"
                    render={({onChange, value}) => (
                      <VmoFormInput
                        type="text"
                        maxLength={20}
                        fluid
                        value={value}
                        placeholder="Street"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.streetAddress && {
                            content: removeDoubleQuotes(errors?.address?.streetAddress?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>

                <VmoFormField>
                  <div className="info-header">
                    <label>Locality</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="mention locality in which vendor live"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="address.locality"
                    render={({onChange, value}) => (
                      <VmoFormInput
                        type="text"
                        maxLength={20}
                        fluid
                        value={value}
                        placeholder="Locality"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.locality && {
                            content: removeDoubleQuotes(errors?.address?.locality?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormGroup widths="equal">
                <VmoFormField>
                  <div className="info-header">
                    <label>State</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="Specify state of the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="address.state"
                    render={({onChange, value}) => (
                      <VmoFormInput
                        type="text"
                        fluid
                        maxLength={20}
                        value={value}
                        placeholder="State"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors?.address?.state && {
                            content: removeDoubleQuotes(errors?.address?.state?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>

                <VmoFormField>
                  <div className="info-header">
                    <label>Country</label>
                    <VmoTooltip
                      trigger={<SvgIcon path="common/question" />}
                      content="mention country of the vendor"
                      size="mini"
                      psoition="top center"
                    />
                  </div>
                  <Controller
                    name="address.country"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        search
                        fluid
                        options={KooperCountryList}
                        selection
                        placeholder="Country"
                        onBlur={onBlur}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        error={
                          errors?.address?.country && {
                            content: removeDoubleQuotes(errors?.address?.country?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              </VmoFormGroup>

              <VmoFormField width={8}>
                <div className="info-header">
                  <label>Postal Code</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="specify vendor’s postal code"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="address.postalCode"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      placeholder="Postal Code"
                      onBlur={onBlur}
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      error={
                        errors?.address?.postalCode && {
                          content: removeDoubleQuotes(errors?.address?.postalCode?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <input hidden type="file" accept=".png, .jpg, .jpeg" onChange={onSelectFile} ref={inputRef} />
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>

      {isDeleteModalOpen && (
        <DeleteModal
          idTobeDeleted={editId}
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          deleteAction={deleteVendorImage}
          type={DELETE_VENDOR_IMAGE}
        />
      )}
    </div>
  )
}

export default CreateModal
