import {CONTACTS_ENDPOINT, KOOPER_ACCOUNT_V1, COMPANIES_ENDPOINT} from './endpoint'
import {
  GET_CONTACT_LIST,
  GET_CONTACT_DETAILS,
  DELETE_CONTACT,
  NEW_CONTACT,
  EDIT_CONTACT,
  ADD_LABEL_CONTACT,
  ADD_NOTE_CONTACTS,
  GET_NOTE_CONTACTS,
  LIST_COMPANY_CONTACTS,
  ATTACHMENT_CONTACTS,
  GET_CONTACT_LIST_ALL,
  GET_ALL_COMPANIES,
  NEW_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY_DETAILS,
  EDIT_COMPANY,
  ADD_NOTE_COMPANIES,
  UPDATE_CONTACT_LIST,
  GET_LIVEUSER_CONTACTS,
  LIVE_COUNT_DETAIL,
  START_NEW_CONVERSATION,
} from './types'
import {apiAction} from './api'

export function getLiveCount() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/liveCount`,
    method: 'GET',
    label: LIVE_COUNT_DETAIL,
  })
}

export function getLiveUserContacts(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${CONTACTS_ENDPOINT}?${query}`,
    method: 'GET',
    label: GET_LIVEUSER_CONTACTS,
  })
}

export function updateContactList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${CONTACTS_ENDPOINT}?${query}`,
    method: 'GET',
    label: UPDATE_CONTACT_LIST,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}

export function startNewConversation(messageData) {
  return apiAction({
    // url: `${endpoint.CHAT}/chat/conversations/new`,
    method: 'POST',
    label: START_NEW_CONVERSATION,
    tokenIdentity: 'chat',
    data: messageData,
  })
}

export function uploadContactsCSV(data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/import`,
    method: 'POST',
    label: ATTACHMENT_CONTACTS,
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Imported successfully',
  })
}

export function getContactList(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  const url = `${CONTACTS_ENDPOINT}?${query}`

  return apiAction({
    url,
    method: 'GET',
    label: GET_CONTACT_LIST,
  })
}

export function getContactListAll() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/all`,
    method: 'GET',
    label: GET_CONTACT_LIST_ALL,
  })
}

export function getContactDetails(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_CONTACT_DETAILS,
  })
}

export function deleteContact(id) {
  const stringId = id.join(',')
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${stringId}`,
    method: 'DELETE',
    label: DELETE_CONTACT,
    showToast: true,
    successMessage: 'Contact Deleted',
  })
}

export function createContact(data) {
  return apiAction({
    url: CONTACTS_ENDPOINT,
    method: 'POST',
    label: NEW_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contact Created',
  })
}

export function editContact(data, id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}`,
    method: 'PUT',
    label: EDIT_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contact Updated',
  })
}

export function addLabelToContact(id, data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/tags`,
    method: 'POST',
    label: ADD_LABEL_CONTACT,
    data,
  })
}

export function addNoteInContacts(id, data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_CONTACTS,
    data,
    showToast: true,
    successMessage: 'Note added',
  })
}

export function getNoteInContacts(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/notes`,
    method: 'GET',
    label: GET_NOTE_CONTACTS,
  })
}

export function listCompaniesInContacts() {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies`,
    method: 'GET',
    label: LIST_COMPANY_CONTACTS,
  })
}
export function getALLCompanies(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  const url = `${KOOPER_ACCOUNT_V1}/companies/?${query}`

  return apiAction({
    url,
    method: 'GET',
    label: GET_ALL_COMPANIES,
  })
}
export function createCompany(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies`,
    method: 'POST',
    label: NEW_COMPANY,
    data,
    showToast: true,
    successMessage: 'Company Created',
  })
}
export function getCompanyDetails(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies/${id}`,
    method: 'GET',
    label: GET_COMPANY_DETAILS,
  })
}

export function editCompany(data, id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies/${id}`,
    method: 'PUT',
    label: EDIT_COMPANY,
    data,
    showToast: true,
    successMessage: 'Company Updated',
  })
}

export function deleteCompany(id) {
  const stringId = id.join(',')
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies/${stringId}`,
    method: 'DELETE',
    label: DELETE_COMPANY,
    showToast: true,
    successMessage: 'Company Deleted',
  })
}

export function addNoteInCompanies(id, data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_COMPANIES,
    data,
    showToast: true,
    successMessage: 'Note added',
  })
}
