/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {useForm} from 'react-hook-form'
import _ from 'lodash'
import {joiResolver} from '@hookform/resolvers'
import {VmoButton, VmoTab} from 'vmo-library'
import {UPDATE_PURCHASE_ORDERS, GET_PURCHASE_ORDERS_DATA, GET_ALL_ADDRESSESS} from 'actions/types'
import {getPurchaseOrdersData, updatePurchaseOrders} from 'actions/PurchaseOrders'
import {get} from 'utils/helper'
import {getAllAddressess} from 'actions/settings_organisation'
import {purchaseOrdersEditSchema} from 'validation/Finance/purchaseOrders.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useApiResponse from 'hooks/impure/useApiResponse'
import Setup from './PurchaseOrdersForm/Setup'
import Product from './PurchaseOrdersForm/Product'
import Customization from './PurchaseOrdersForm/Customization'
import PurchaseOrderModal from './PurchaseOrdersForm/PurchaseOrderModal'
import PreviewPurchaseOrder from './Templates/PreviewPurchaseOrder'

const purchaseOrdersEditDefaultValues = {
  assigneeId: null,
  poNumber: '',
  prefixId: '',
  subject: '',
  vendorId: null,
  requisitionNumber: '',
  trackingNumber: '',
  contactId: null,
  poDate: null,
  dueDate: null,
  exciseDuty: '',
  salesCommission: '',
  carrier: '',
  status: '',
  description: '',
  billingAddress: {},
  shippingAddress: {},
  products: [],
  subTotal: 0,
  discount: 0,
  tax: 0,
  adjustment: 0,
  total: 0,
  terms: '',
  template: {templateNo: 2, color: '#1e90ff', header: '', footer: ''},
}

const steps = [{name: 'Setup'}, {name: 'Product'}, {name: 'Customization'}, {name: 'Preview'}]

function PurchaseOrdersEdit() {
  const dispatch = useDispatch()
  const history = useHistory()
  const {goBack} = useHistory()
  const {id: editId} = useParams()

  const [index, setIndex] = useState(0)
  const [poDate, setPoDate] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [searchValue, setSearchValue] = useState([])
  const [discountValue, setDiscountValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [adjustmentValue, setAdjustmentValue] = useState(0)
  const [addressTemplate, setAddressTemplate] = useState()
  const [prefixOptions, setPrefixOptions] = useState([])
  const [showPoModal, setShowPoModal] = useState(false)

  const {purchaseOrdersData = {}, productsData = [], poPublicLink} = useSelector(state => state.purchaseOrders)

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {selectedFile} = useSelector(state => state.purchaseOrders.uploadFile)
  const {financePermissions} = useUserPermissions()
  const managePurchaseOrdersPermissions = financePermissions?.manage_purchase_orders

  const {handleSubmit, errors, clearErrors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: purchaseOrdersEditDefaultValues,
    resolver: joiResolver(purchaseOrdersEditSchema),
  })

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
  })

  useEffect(() => {
    if (editId) dispatch(getPurchaseOrdersData(editId))

    return () => {
      dispatch({type: 'CLEAR_PO_FORM'})
      dispatch({type: 'CLEAR_PURCHASE_ORDERS_DATA'})
      dispatch({type: 'CLEAR_PO_UPLOADFILE'})
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_PURCHASE_ORDERS_DATA)) {
      const {
        assigneeId,
        poNumber,
        subject,
        vendorId,
        contactId,
        requisitionNumber,
        trackingNumber,
        poDate,
        dueDate,
        carrier,
        status,
        exciseDuty,
        salesCommission,
        description,
        billingAddress,
        shippingAddress,
        subTotal,
        total,
        terms,
        template,
      } = purchaseOrdersData
      if (template) {
        delete template?.id
        delete template?.accountId
        delete template?.poId
        delete template?.logo
        delete template?.terms
        delete template?.paymentProcess
      }
      setAddressTemplate(
        allAddressess?.filter(({id}) => {
          return id === template?.addressId
        })
      )
      reset({
        assigneeId,
        poNumber,
        prefixId: get(['prefixId'], purchaseOrdersData, ''),
        subject,
        vendorId,
        contactId,
        requisitionNumber,
        trackingNumber,
        poDate,
        dueDate,
        carrier,
        status,
        exciseDuty,
        salesCommission,
        description,
        billingAddress,
        shippingAddress,
        subTotal,
        total,
        terms,
        template,
        discount: get(['discount'], purchaseOrdersData, null),
        tax: get(['tax'], purchaseOrdersData, null),
        adjustment: get(['adjustment'], purchaseOrdersData, null),
      })
      setSearchValue(
        productsData?.map(
          ({productId, productName: title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}) => {
            return {productId, title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}
          }
        )
      )
      setDiscountValue(purchaseOrdersData.discount)
      setTaxValue(purchaseOrdersData.tax)
      setPoDate(purchaseOrdersData.poDate)
      setDueDate(purchaseOrdersData.dueDate)
      setAdjustmentValue(purchaseOrdersData.adjustment)
    }
  }, [successLabels, purchaseOrdersData, productsData, reset])

  useEffect(() => {
    if (purchaseOrdersData?.template?.logo) {
      // const [, setPreview] = preview;
      // setPreview(previewLogo);
      dispatch({type: 'SET_PO_PREVIEW_FILE', payload: purchaseOrdersData?.template?.logo})
    }
  }, [purchaseOrdersData])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PURCHASE_ORDERS)) {
      // history.push('/purchase-orders')
      setShowPoModal(true)
      dispatch({type: 'CLEAR_PO_FORM'})
      dispatch({type: 'CLEAR_PURCHASE_ORDERS_DATA'})
      dispatch({type: 'CLEAR_PO_UPLOADFILE'})
    }
  }, [successLabels, history])

  useEffect(() => {
    if (purchaseOrdersData.id) {
      if (purchaseOrdersData?.poNumber) {
        dispatch({
          type: 'SETUP_SUBMIT',
          payload: {
            poNumber: purchaseOrdersData?.poNumber,
            poDate: purchaseOrdersData?.poDate,
            status: purchaseOrdersData?.status,
            requisitionNumber: purchaseOrdersData?.requisitionNumber,
            trackingNumber: purchaseOrdersData?.trackingNumber,
            billingAddress: purchaseOrdersData?.billingAddress,
            shippingAddress: purchaseOrdersData?.shippingAddress,
            description: purchaseOrdersData?.description,
            carrier: purchaseOrdersData?.carrier,
          },
        })
      }

      if (productsData) {
        dispatch({
          type: 'PRODUCT_HANDLE_SUBMIT',
          payload: {
            products: productsData,
            total: purchaseOrdersData?.total,
            subTotal: purchaseOrdersData?.subTotal,
            discount: purchaseOrdersData?.discount,
            tax: purchaseOrdersData?.tax,
            adjustment: purchaseOrdersData?.adjustment,
            terms: purchaseOrdersData?.terms,
          },
        })
      }

      if (purchaseOrdersData?.template) {
        dispatch({
          type: 'CUSTOMIZATION_SUBMIT',
          payload: {
            template: purchaseOrdersData?.template,
          },
        })
      }
    }
  }, [purchaseOrdersData, productsData, dispatch])

  const submitForm = data => {
    const formData = new FormData()

    const payload = {...data}

    if (selectedFile && typeof selectedFile !== 'string') {
      formData.append('logo', selectedFile)
    }

    const newList = _.omitBy(payload, value => !!(value === '' || value === null || _.isEqual(value, [])))

    for (const key in newList) {
      if (key === 'products') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'shippingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'billingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'template') {
        formData.append(key, JSON.stringify(newList[key]))
      } else {
        formData.append(key, newList[key])
      }
    }
    dispatch(updatePurchaseOrders(editId, formData))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/purchase-orders')}>
            Exit
          </VmoButton>
          {index > 0 && (
            <VmoButton className="ml-2" primary onClick={() => setIndex(index - 1)}>
              Back
            </VmoButton>
          )}
        </div>
        <VmoTab
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <LockPermissionTooltip isRoleAccessDenied={!managePurchaseOrdersPermissions}>
          <VmoButton
            primary
            className={!managePurchaseOrdersPermissions ? 'disabled-button' : ''}
            {...(managePurchaseOrdersPermissions && {
              onClick: handleSubmit(submitForm),
            })}
          >
            Save
          </VmoButton>
        </LockPermissionTooltip>
      </div>
      <Setup
        style={{display: index === 0 ? '' : 'none'}}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        watch={watch}
        setValue={setValue}
        dueDate={dueDate}
        poDate={poDate}
        setPoDate={setPoDate}
        setDueDate={setDueDate}
        prefixOptions={prefixOptions}
        setPrefixOptions={setPrefixOptions}
      />

      <Product
        style={{display: index === 1 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        reset={reset}
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        taxValue={taxValue}
        setTaxValue={setTaxValue}
        adjustmentValue={adjustmentValue}
        setAdjustmentValue={setAdjustmentValue}
      />

      <Customization
        style={{display: index === 2 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        addressTemplate={addressTemplate}
        setAddressTemplate={setAddressTemplate}
        editId={editId}
      />

      <PreviewPurchaseOrder style={{display: index === 3 ? '' : 'none'}} />

      {showPoModal && (
        <PurchaseOrderModal
          link={poPublicLink}
          open={showPoModal}
          toggle={() => {
            setShowPoModal(false)
            goBack()
          }}
        />
      )}
    </div>
  )
}

export default PurchaseOrdersEdit
