import {PROJECT_FINANCE_BUDGET_TYPES} from 'constants/projects'
import Joi from 'joi'

export const createProjectDetailsSchema = Joi.object({
  useTemplate: Joi.boolean(),
  templateId: Joi.when('useTemplate', {
    is: true,
    then: Joi.number().required().messages({
      'number.base': 'Template must be specified',
    }),
    otherwise: Joi.any(),
  }),
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(500).allow('', null).messages({
    'string.max': `Length must be less than or equal to 500 characters`,
  }),
  budgetType: Joi.string()
    .valid(...Object.values(PROJECT_FINANCE_BUDGET_TYPES))
    .required(),
  rangeType: Joi.string().valid('financial', 'time').required(),
  budgetAmount: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.NO_BUDGET,
    then: Joi.number().default(0),
    otherwise: Joi.when('rangeType', {
      is: 'financial',
      then: Joi.number().min(1).required().messages({
        'number.min': 'Please enter a value greater than 0',
      }),
      otherwise: Joi.number().default(0),
    }),
  }),
  budgetTimeHour: Joi.number().integer(),
  budgetTimeMinute: Joi.number().integer(),
  startDate: Joi.date().required().messages({
    'date.base': 'Please enter valid date',
  }),
  endDate: Joi.date().min(Joi.ref('startDate')).required().messages({
    'date.base': 'Please enter valid date',
    'date.min': 'End date must be greater then start date',
  }),
  timelogType: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  repeatUnit: Joi.when('budgetType', {
    is: Joi.valid(PROJECT_FINANCE_BUDGET_TYPES.TIME_AND_MATERIALS, PROJECT_FINANCE_BUDGET_TYPES.RETAINER),
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  carryUnderspend: Joi.boolean().default(false),
  carryOverspend: Joi.boolean().default(false),
  budgetProfitMargin: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetProfit: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
  budgetTargetCosts: Joi.when('budgetType', {
    is: PROJECT_FINANCE_BUDGET_TYPES.FIXED_FEE,
    then: Joi.number().default(0),
    otherwise: Joi.any().default(0),
  }),
})

export const createProjectAdvanceOptionSchema = Joi.object({
  assignedTo: Joi.array().items(Joi.number()).min(1).required().messages({
    'array.min': 'Assignee must be specified',
  }),
  managerId: Joi.number().required().messages({
    'number.base': 'Manager must be specified',
  }),
  contactIds: Joi.array().items(Joi.number()).min(1).required().messages({
    'array.min': 'Contact must be specified',
  }),
  projectStartDate: Joi.date().iso(),
  projectEndDate: Joi.date().iso(),
  categoryId: Joi.any().invalid(null, '').required().messages({
    'number.base': 'Category must be specified',
    'any.invalid': 'Category must be specified',
  }),
  status: Joi.string().valid('active', 'inactive', 'archived').required(),
})

export const editProjectDetailsSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(500).allow('', null).messages({
    'string.max': `Length must be less than or equal to 500 characters`,
  }),
})

export const topicSchema = Joi.object({
  title: Joi.string().trim().min(3).max(50).required().messages({
    'string.min': `Length must me at least 3 charactes`,
    'string.max': `Length must be less than or equal to 50 characters`,
  }),
  description: Joi.string().trim().max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  subscribers: Joi.array().items(Joi.number()).min(1).required().messages({
    'array.min': 'Subscriber must be specified',
  }),
  isPinned: Joi.boolean(),
})
