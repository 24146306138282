import {apiAction} from './api'
import {SETTINGS_ENDPOINT} from './endpoint'
import {GET_ACCOUNT_SETTING, UPDATE_ACCOUNT_SETTING} from './types'

export function getAccountSetting() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/general-settings`,
    method: 'GET',
    label: GET_ACCOUNT_SETTING,
  })
}

export function updateAccountSetting(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/general-settings`,
    method: 'POST',
    label: UPDATE_ACCOUNT_SETTING,
    data,
    showToast: true,
    successMessage: 'Account Setting Updated',
  })
}
