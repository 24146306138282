import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoModal,
  VmoModalContent,
  VmoModalActions,
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModalHeader,
  VmoGridColumn,
  VmoGrid,
  VmoChipInput,
  VmoDatePicker,
  VmoTooltip,
} from 'vmo-library'
import {createTagSettings, getTagLabel} from 'actions/multiple_fields'
import {dealMultipleUpdate, getSourceDeals} from 'actions/deals'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {priorityList} from 'constants/variables'
import CreateLabel from 'components/common/CreateLabel'
import {dealMultipleUpdateSchema} from 'validation/Sales/deals.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {GET_ALL_TAG_LABEL, GET_SOURCE_DEALS} from 'actions/types'

const EditDealModal = props => {
  const {isModalOpen, setIsModalOpen, checked} = props
  const dispatch = useDispatch()

  const [showInputs, setShowInputs] = useState({})
  const [createInfo, setCreateInfo] = useState({})
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [listOfLable, setListOfLabel] = useState([])
  const [stageListData, setStageListData] = useState([])
  const [sourceListData, setSourceListData] = useState([])

  const initalEdit = {
    name: '',
    stageId: null,
    sourceId: null,
    priority: '',
    win: null,
    monetaryValue: null,
    currency: '',
    description: '',
    closeDate: null,
    labelIds: [],
  }

  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(dealMultipleUpdateSchema),
    defaultValues: initalEdit,
  })

  const {getStageDeals: getStageDealsList} = useSelector(state => state.deals)

  useApiResponse({
    action: getTagLabel,
    enabled: true,
    label: GET_ALL_TAG_LABEL,
    storePath: 'multipleFields.getTagList',
    onSuccess: getTagList => {
      const list = getTagList.map(user => ({
        value: user.id,
        text: user.label,
        key: user.id,
        label: {
          style: {background: user.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(list)
    },
  })

  useApiResponse({
    action: getSourceDeals,
    enabled: true,
    label: GET_SOURCE_DEALS,
    storePath: 'deals.getSourceDeals',
    onSuccess: getSourceDealsList => {
      const sourceList = getSourceDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.source,
      }))
      setSourceListData(sourceList)
    },
  })

  useEffect(() => {
    if (getStageDealsList) {
      const stageList = getStageDealsList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.stage,
      }))
      setStageListData(stageList)
    }
  }, [getStageDealsList])

  useEffect(() => {
    setCreateInfo({})
    setShowInputs({
      name: false,
      stageId: false,
      sourceId: false,
      priority: false,
      win: false,
      monetaryValue: false,
      currency: false,
      description: false,
      closeDate: false,
      labelIds: false,
    })
  }, [isModalOpen])
  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
          // leadIds={[leadId]}
        />
      )
    }
  }

  const handleMultipleUpdateDeal = data => {
    const removeNull = _.pickBy(createInfo, _.identity)
    const dataNull = _.pickBy(data, _.identity)
    const combineObj = {ids: checked, ...removeNull, ...dataNull}
    dispatch(dealMultipleUpdate(combineObj))
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Edit Deals Details</VmoModalHeader>
      <VmoModalContent scrolling>
        <h6>{checked.length} Deals Selected</h6>
        <VmoForm className="vmo-form">
          <VmoFormField>
            <label className="d-block">Name</label>
            <VmoButton className="pl-0" onClick={() => setShowInputs({...showInputs, name: !showInputs.name})}>
              Click to edit all
            </VmoButton>
            {showInputs.name && (
              <Controller
                control={control}
                name="name"
                render={({value, onChange}) => (
                  <VmoFormInput
                    type="text"
                    value={value}
                    placeholder="Enter Name..."
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.name && {
                        content: errors.name.message,
                      }
                    }
                  />
                )}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Stage</label>
            <VmoButton className="pl-0" onClick={() => setShowInputs({...showInputs, stageId: !showInputs.stageId})}>
              Click to edit all
            </VmoButton>
            {showInputs.stageId && (
              <Controller
                control={control}
                name="stageId"
                render={({value, onChange}) => (
                  <VmoFormSelect
                    selection
                    fluid
                    search
                    value={value}
                    options={stageListData}
                    onChange={(e, {value}) => onChange(value)}
                    clearable
                    placeholder="Stage"
                    error={
                      errors.stageId && {
                        content: errors.stageId.message,
                      }
                    }
                  />
                )}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Source</label>
            <VmoButton className="pl-0" onClick={() => setShowInputs({...showInputs, sourceId: !showInputs.sourceId})}>
              Click to edit all
            </VmoButton>
            {showInputs.sourceId && (
              <Controller
                control={control}
                name="sourceId"
                render={({value, onChange}) => (
                  <VmoFormSelect
                    selection
                    fluid
                    search
                    value={value}
                    options={sourceListData}
                    onChange={(e, {value}) => onChange(value)}
                    clearable
                    placeholder="Stage"
                  />
                )}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label>Currency & Value</label>
            <VmoButton className="pl-0" onClick={() => setShowInputs({...showInputs, currency: !showInputs.currency})}>
              Click to edit all
            </VmoButton>
            {showInputs.currency && (
              <VmoGrid columns="equal">
                <VmoGridColumn>
                  <Controller
                    control={control}
                    name="monetaryValue"
                    render={({value, onChange}) => (
                      <VmoFormInput
                        placeholder="value"
                        type="number"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.monetaryValue && {
                            content: errors.monetaryValue.message,
                          }
                        }
                      />
                    )}
                  />
                </VmoGridColumn>
                <VmoGridColumn>
                  <Controller
                    control={control}
                    name="currency"
                    render={({value, onChange}) => (
                      <VmoFormSelect
                        selection
                        fluid
                        search
                        placeholder="Currency"
                        clearable
                        options={[
                          {value: 'INR', text: 'INR', key: 'INR'},
                          {value: 'USD', text: 'USD', key: 'USD'},
                        ]}
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                      />
                    )}
                  />
                </VmoGridColumn>
              </VmoGrid>
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Tags</label>
            <VmoButton className="pl-0" onClick={() => setShowInputs({...showInputs, tags: !showInputs.tags})}>
              Click to edit all
            </VmoButton>
            {showInputs.tags && (
              <>
                <Controller
                  control={control}
                  name="labelIds"
                  render={({onChange}) => (
                    <VmoChipInput
                      defaultValue={listOfLable.map(tag => {
                        return {
                          id: tag.value,
                          text: (
                            <>
                              <div
                                style={{
                                  height: '10px',
                                  width: '10px',
                                  background: tag.label.style.background,
                                  borderRadius: '50%',
                                  marginRight: '10px',
                                  display: 'inline-block',
                                }}
                              />
                              {tag.text}
                            </>
                          ),
                        }
                      })}
                      onChange={value => {
                        onChange(value)
                        const valueIds = value.map(tag => tag.id)
                        const removeTag = listOfLable.filter(contact => valueIds.indexOf(contact.value) !== -1)
                        setListOfLabel(removeTag)
                        const id = _.map(removeTag, 'value')
                        setCreateInfo({...createInfo, labelIds: id})
                      }}
                      hideInputField
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="labelIds"
                  render={({onChange}) => (
                    <KooperCustomDropdown
                      options={labelOptionsState}
                      multiple
                      dropdownProps={{
                        icon: (
                          <VmoTooltip
                            content="Tags"
                            size="mini"
                            position="bottom center"
                            trigger={<SvgIcon path="common/tag" id="TooltipTagInbox" />}
                          />
                        ),
                        direction: 'right',
                        closeOnChange: false,
                      }}
                      menu={{scrolling: true}}
                      input={{
                        icon: 'search',
                        iconPosition: 'left',
                        className: 'search',
                      }}
                      activeIndicator
                      value={listOfLable.map(labels => labels.value)}
                      onClick={value => {
                        onChange(value)
                        const updatedList = labelOptionsState.filter(val => value.indexOf(val.value) !== -1)
                        setListOfLabel(updatedList)
                      }}
                      extraAction={{
                        content: '+ Add Tag',
                        onClick: () => setNewLabelModal(true),
                      }}
                    />
                  )}
                />
              </>
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Close Date</label>
            <VmoButton
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  closeDate: !showInputs.closeDate,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.closeDate && (
              <Controller
                control={control}
                name="closeDate"
                render={({onChange}) => (
                  <VmoDatePicker
                    className="vmo-picker"
                    onChange={value => {
                      onChange(value)
                    }}
                    min={new Date()}
                    time={false}
                  />
                )}
              />
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Win</label>
            <VmoButton
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  win: !showInputs.win,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.win && (
              <Controller
                control={control}
                name="win"
                render={({value, onChange}) => (
                  <VmoFormInput
                    fluid
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    clearable
                    placeholder="Win"
                    error={
                      errors.win && {
                        content: errors.win.message,
                      }
                    }
                  />
                )}
              />
            )}
          </VmoFormField>
          <VmoFormField>
            <label className="d-block">Priority</label>
            <VmoButton
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  priority: !showInputs.priority,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.priority && (
              <Controller
                control={control}
                name="priority"
                render={({value, onChange}) => (
                  <VmoFormSelect
                    selection
                    fluid
                    value={value}
                    options={priorityList}
                    onChange={(e, {value}) => onChange(value)}
                    clearable
                    placeholder="Priority"
                  />
                )}
              />
            )}
          </VmoFormField>

          <VmoFormField>
            <label className="d-block">Description</label>
            <VmoButton
              className="pl-0"
              onClick={() =>
                setShowInputs({
                  ...showInputs,
                  description: !showInputs.description,
                })
              }
            >
              Click to edit all
            </VmoButton>
            {showInputs.description && (
              <Controller
                control={control}
                name="description"
                render={({value, onChange}) => (
                  <VmoFormInput
                    value={value}
                    type="textarea"
                    placeholder="write description"
                    onChange={(e, {value}) => onChange(value)}
                  />
                )}
              />
            )}
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
        <VmoButton color="blue" onClick={handleSubmit(handleMultipleUpdateDeal)}>
          Save
        </VmoButton>
      </VmoModalActions>
      {createNewLabel()}
    </VmoModal>
  )
}

export default EditDealModal
