import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {
  ASSIGNED_VS_RESOLVED,
  RATINGS_AND_NPS_SCORE,
  TOTAL_ASSIGNED,
  TOTAL_RESOLVED,
  USER_ACTIVITIES_OVERTIME,
  USER_ACTIVITIES_TABLE_SLA,
  USER_ASSIGNMENT_OVERTIME,
} from './types'

export function totalResolved(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/users/totalresolved`,
    method: 'POST',
    label: TOTAL_RESOLVED,
    data,
  })
}

export function totalAssigned(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/users/totalassigned`,
    method: 'POST',
    label: TOTAL_ASSIGNED,
    data,
  })
}

export function assignedVSResolved(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/users/assignedvsresolved`,
    method: 'POST',
    label: ASSIGNED_VS_RESOLVED,
    data,
  })
}

export function ratingsAndNpsScore(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/surveys/ratingsandnpsscore`,
    method: 'POST',
    label: RATINGS_AND_NPS_SCORE,
    data,
  })
}

export function userAssignmentOvertime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/users/userassignmentovertimeheatmap`,
    method: 'POST',
    label: USER_ASSIGNMENT_OVERTIME,
    data,
  })
}

export function userActivitiesOverTime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/users/useractivitiesovertime`,
    method: 'POST',
    label: USER_ACTIVITIES_OVERTIME,
    data,
  })
}

export function userActivitiesTableSLA(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/sla/useractivitiestable`,
    method: 'POST',
    label: USER_ACTIVITIES_TABLE_SLA,
    data,
  })
}
