/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FaUser} from 'react-icons/fa'
import {
  VmoGridColumn,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoCardGroup,
  VmoList,
  VmoListItem,
  VmoDivider,
} from 'vmo-library'
import SettingLayout from 'layouts/settingLayout'
import {exportAllContacts} from 'actions/contacts'
import {exportAllCompany} from 'actions/company'
import {exportAllDeals} from 'actions/deals'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const Exports = () => {
  const dispatch = useDispatch()

  // Exported data of contacts companies deals
  const {exportAllContactsData} = useSelector(state => state.contacts)
  const {exportAllCompanies} = useSelector(state => state.companies)
  const {exportAllDealsData} = useSelector(state => state.deals)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const exportLearnMore = settingsLearnMoreUrl?.dataManagement?.export?.learnMore

  const {salesAndCrmAccess, contactsAndCompaniesPermissions, dealsPermissions} = useUserPermissions()
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const manageImportExportPermissions = contactsAndCompaniesPermissions?.manage_import_export
  const manageImportExportDealsPermissions = dealsPermissions?.manage_import_export_deals

  // useEffect for loading all data
  useEffect(() => {
    if (!importAndExportAccess) return

    if (manageImportExportPermissions) {
      dispatch(exportAllCompany())
      dispatch(exportAllContacts())
    }

    if (manageImportExportDealsPermissions) {
      dispatch(exportAllDeals())
    }
  }, [importAndExportAccess, manageImportExportPermissions, manageImportExportDealsPermissions, dispatch])

  // function to Download data
  const downloadResponseCSV = (data, name) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.csv`) // or any other extension
    document.body.appendChild(link)
    link.click()
  }

  return (
    <SettingLayout
      header="Export Data"
      subHeader="Export your data easily in form of CSV format"
      icon={<SvgIcon path="settings/export" />}
      headerDivider
    >
      <div className="setBodyContent">
        <VmoCardContent>
          <VmoCardGroup>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() =>
                  importAndExportAccess &&
                  manageImportExportPermissions &&
                  downloadResponseCSV(exportAllCompanies, 'exported_Companies')
                }
              >
                <VmoCardContent>
                  <SvgIcon path="navigation/companies" />
                  <VmoCardHeader>EXPORT COMPANIES</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() =>
                  importAndExportAccess &&
                  manageImportExportPermissions &&
                  downloadResponseCSV(exportAllContactsData, 'exported_Contacts')
                }
              >
                <VmoCardContent>
                  <FaUser />
                  <VmoCardHeader>EXPORT CONTACTS</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
            <LockPermissionTooltip
              isFeatureAccessDenied={!importAndExportAccess}
              isRoleAccessDenied={!manageImportExportDealsPermissions}
            >
              <VmoCard
                link
                className="hoverCard"
                onClick={() =>
                  importAndExportAccess &&
                  manageImportExportDealsPermissions &&
                  downloadResponseCSV(exportAllDealsData, 'exported_Deals')
                }
              >
                <VmoCardContent>
                  <SvgIcon path="settings/deals" />
                  <VmoCardHeader>EXPORT DEALS</VmoCardHeader>
                </VmoCardContent>
              </VmoCard>
            </LockPermissionTooltip>
          </VmoCardGroup>
        </VmoCardContent>
        <VmoGridColumn width={5}>
          <h5 className="mt-5 mb-2">Past Imports</h5>
          <p className="kooper-lead">
            You have no imports on record. Any imports initiated henceforth will show up here!
          </p>
          <h5 className="mt-5 mb-2">Tips For a Smooth Import</h5>
          <VmoList bulleted relaxed="very">
            <VmoListItem className="kooper-lead">
              Import Companies first, then People and then Deals. Importing in this order will ensure that all records
              are related correctly. Leads can be imported at any time
            </VmoListItem>
            <VmoListItem className="kooper-lead">
              We recommend using our formatted Excel and CSV templates to ensure your data is formatted properly. You'll
              be given the option to download the templates during the import process. Learn more about the import
              process <a href={exportLearnMore}>here</a>
            </VmoListItem>
            <VmoListItem className="kooper-lead">
              We support file sizes up to 3MB. If your file is larger than 3MB, we suggest you break it up into multiple
              files. It's a good idea to do a test with a smaller import file first to make sure your file works.
            </VmoListItem>
            <VmoListItem className="kooper-lead">
              The first row of your import file must be the column headers you wish to import to.
            </VmoListItem>
          </VmoList>
        </VmoGridColumn>
      </div>
    </SettingLayout>
  )
}
export default Exports
