import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

import {
  CREATE_PROJECTS_NOTEBOOK_NOTE,
  GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
  UPDATE_PROJECTS_NOTEBOOK_NOTE,
} from 'actions/types'
import {createProjectsNotebookNote, getProjectsNotebookSingleNote, updateProjectsNotebookNote} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const NoteModal = ({open, toggle, projectId, notebookId, noteId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        subscribers: Joi.array().items(Joi.number()).min(1).required().messages({
          'array.min': 'Subscriber must be specified',
        }),
      })
    ),
    defaultValues: {
      title: '',
      subscribers: [Number(getUserInfo().id)],
    },
  })

  useApiResponse({
    action: getProjectsNotebookSingleNote,
    payload: {projectId, noteId},
    dependency: [projectId, noteId],
    enabled: projectId && noteId,
    label: GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
    storePath: 'projects.noteData',
    onSuccess: (noteData = {}) => {
      const data = _.pick(noteData, ['title', 'subscribers'])
      reset(data)
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_NOTEBOOK_NOTE) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK_NOTE)
    ) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (noteId) {
      dispatch(updateProjectsNotebookNote({projectId, noteId, data}))
    } else {
      dispatch(createProjectsNotebookNote({projectId, notebookId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{noteId ? 'Edit Note' : 'Add Note'}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <label>Subscribers</label>
            <Controller
              name="subscribers"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  multiple
                  options={projectAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {noteId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default NoteModal
