import {ANALYTICS_ENDPOINT} from './endpoint'
import {apiAction} from './api'
import {EMAIL_STATS, EMAIL_OPEN_VS_TIME, EMAIL_CLICK_VS_TIME, EMAIL_TEMPLATE_STATS} from './types'

export function emailStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/email-tracking/trackingstats`,
    method: 'POST',
    label: EMAIL_STATS,
    data,
  })
}

export function emailOpenedVsTime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/email-tracking/openedvstime`,
    method: 'POST',
    label: EMAIL_OPEN_VS_TIME,
    data,
  })
}

export function emailClickedVsTime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/email-tracking/clickedvstime`,
    method: 'POST',
    label: EMAIL_CLICK_VS_TIME,
    data,
  })
}

export function emailTemplateStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/email-tracking/templatestats `,
    method: 'POST',
    label: EMAIL_TEMPLATE_STATS,
    data,
  })
}
