/* eslint-disable react/no-unknown-property */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoRadio,
  VmoTab,
  VmoTabPane,
  VmoTooltip,
  VmoFormSelect,
} from 'vmo-library'
import {
  CREATE_ADDRESS,
  GET_GENERAL_SETTING_ORGANIZATION,
  UPDATE_GENERAL_SETTING_ORGANIZATION,
  DELETE_ADDRESS,
  GET_ADDRESS_DATA,
  UPDATE_ADDRESS,
} from 'actions/types'
import {
  getGeneralSettingsOrganization,
  updateGeneralSettingsOrganization,
  createAddress,
  getAllAddressess,
  deleteAddress,
  getAddressData,
  updateAddress,
} from 'actions/settings_organisation'
import SettingLayout from 'layouts/settingLayout'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {removeDoubleQuotes, KooperCountryList, get, getPermissionTooltipContent} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import {createAccountSettingsSchema} from 'validation/Settings/myOrganisation/accountSettings.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {AiFillLock} from 'react-icons/ai'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const AccountSettings = props => {
  const {warningModal, setWarningModal} = props

  const dispatch = useDispatch()

  const [useStaffingPrediction, setUseStaffingPrediction] = useState(false)
  const [conversationsPerUser, setConversationsPerUser] = useState('')
  const [ticketDeflection, setTicketDeflection] = useState(false)
  const [isAutoResolve, setIsAutoResolve] = useState(false)
  const [noOfHours, setNoOfHours] = useState('')
  const [address, setAddress] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [addressId, setAddressId] = useState(null)

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {getAccountsSettingsData = {}, allAddressess, addressData} = useSelector(state => state.settingsOrganisation)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const accountSettingLearnMore = settingsLearnMoreUrl?.myOrganization?.accountSettings?.learnMore

  const {clientManagementAccess, myOrganizationDefaultPermissions} = useUserPermissions()
  const conversationDeflectionAccess = clientManagementAccess?.conversationDeflection
  const staffingPredictionAccess = clientManagementAccess?.staffingPrediction

  const initialCreateInfo = {
    companyName: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    email: '',
    phone: '',
  }

  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(createAccountSettingsSchema),
    defaultValues: initialCreateInfo,
  })

  useEffect(() => {
    dispatch(getGeneralSettingsOrganization())
    dispatch(getAllAddressess())
  }, [dispatch])

  const resetData = () => {
    if (addressData) {
      const {companyName, city, country, email, phone, postalCode, state, streetAddress} = addressData
      reset({
        companyName,
        city,
        country,
        email,
        phone,
        postalCode,
        state,
        streetAddress,
      })
    }
  }

  useEffect(() => {
    if (successLabels.includes(CREATE_ADDRESS) || successLabels.includes(DELETE_ADDRESS)) {
      setAddress(false)
      dispatch(getAllAddressess())
    }

    if (successLabels.includes(UPDATE_ADDRESS)) {
      setAddress(false)
      dispatch(getAllAddressess())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_ADDRESS_DATA)) {
      resetData()
    }
    setAddressId(get(['id'], addressData, null))
  }, [successLabels, addressData])

  useEffect(() => {
    if (successLabels.includes(GET_GENERAL_SETTING_ORGANIZATION)) {
      setUseStaffingPrediction(getAccountsSettingsData.useStaffingPrediction)
      setConversationsPerUser(getAccountsSettingsData.conversationsPerUser)
      setTicketDeflection(getAccountsSettingsData.ticketDeflection || false)
      setIsAutoResolve(getAccountsSettingsData.isAutoResolve)
      setIsAutoResolve(getAccountsSettingsData.isAutoResolve)
      setNoOfHours(getAccountsSettingsData.noOfHours)
    }
  }, [successLabels, getAccountsSettingsData])

  useEffect(() => {
    if (successLabels.includes(UPDATE_GENERAL_SETTING_ORGANIZATION)) {
      dispatch(getGeneralSettingsOrganization())
      setWarningModal(false)
    }
  }, [successLabels, dispatch, setWarningModal])

  useEffect(() => {
    if (
      useStaffingPrediction === getAccountsSettingsData.useStaffingPrediction &&
      conversationsPerUser === getAccountsSettingsData.conversationsPerUser &&
      ticketDeflection === getAccountsSettingsData.ticketDeflection &&
      isAutoResolve === getAccountsSettingsData.isAutoResolve &&
      noOfHours === getAccountsSettingsData.noOfHours
    ) {
      setWarningModal(false)
    } else {
      setWarningModal(true)
    }
  }, [
    setWarningModal,
    getAccountsSettingsData,
    useStaffingPrediction,
    conversationsPerUser,
    ticketDeflection,
    isAutoResolve,
    noOfHours,
  ])

  const saveAccountSettings = () => {
    const data = {
      useStaffingPrediction,
      conversationsPerUser,
      ticketDeflection,
      isAutoResolve,
      noOfHours,
    }
    if (/^[1-9][0-9]?$|^99$/.test(noOfHours) && /^[1-9]+[0-9]*$/.test(conversationsPerUser)) {
      dispatch(updateGeneralSettingsOrganization(data))
    }
  }

  function submitForm(data) {
    if (addressId) {
      return dispatch(updateAddress(data, addressId))
    }
    dispatch(createAddress(data))
  }

  const panes = [
    {
      menuItem: {
        key: 'companySettings',
        icon: <SvgIcon path="common/settings" />,
        content: 'Company Settings',
      },
      render: () => (
        <VmoTabPane>
          <SettingLayout
            paddingLeft
            header="Account Settings"
            table={false}
            headerDivider={false}
            actionButton={{
              cancel: {content: 'Cancel'},
              success: {
                content: 'Update',
                loading: isLoadingData && type.includes(UPDATE_GENERAL_SETTING_ORGANIZATION),
                ...(myOrganizationDefaultPermissions && {
                  onClick: () => saveAccountSettings(),
                }),
                disabled: (isLoadingData && type.includes(UPDATE_GENERAL_SETTING_ORGANIZATION)) || !warningModal,
              },
            }}
            lockRole={!myOrganizationDefaultPermissions}
          >
            <VmoCard fluid className="switchBox mt-3">
              <VmoCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Staffing Prediction</h5>
                    <p className="kooper-lead">Predict current, short and long term staffing needs</p>
                  </div>
                  <LockPermissionTooltip isFeatureAccessDenied={!staffingPredictionAccess}>
                    <div className="d-flex">
                      {!staffingPredictionAccess && <AiFillLock className="mr-2" />}
                      <VmoRadio
                        toggle
                        checked={useStaffingPrediction}
                        onChange={() => staffingPredictionAccess && setUseStaffingPrediction(!useStaffingPrediction)}
                      />
                    </div>
                  </LockPermissionTooltip>
                </div>
                <VmoForm className="my-2 errorLabel">
                  <VmoFormField>
                    {useStaffingPrediction && (
                      <VmoFormInput
                        type="text"
                        size="small"
                        style={{
                          width: '70px',
                          margin: '20px 0px 4px 0',
                        }}
                        value={conversationsPerUser}
                        onChange={e => setConversationsPerUser(e.target.value)}
                        error={
                          !/^[1-9]+[0-9]*$/.test(conversationsPerUser) && {
                            content: 'number allowed only positive',
                          }
                        }
                      />
                    )}
                  </VmoFormField>
                </VmoForm>
              </VmoCardContent>
              <VmoCardContent>
                <div className="d-flex">
                  <div>
                    <h5>Ticket Deflection</h5>
                    <p className="kooper-lead">Deflect tickets for frequently occurring issues</p>
                  </div>
                  <LockPermissionTooltip isFeatureAccessDenied={!conversationDeflectionAccess}>
                    <div className="d-flex">
                      {!conversationDeflectionAccess && <AiFillLock className="mr-2" />}
                      <VmoRadio
                        toggle
                        checked={ticketDeflection}
                        onChange={() => conversationDeflectionAccess && setTicketDeflection(!ticketDeflection)}
                      />
                    </div>
                  </LockPermissionTooltip>
                </div>
              </VmoCardContent>
              <VmoCardContent>
                <div className="d-flex">
                  <div>
                    <h5 style={{width: 'fit-content'}}>Conversation assigned is open for x days</h5>
                    <p className="kooper-lead">
                      All open conversations assigned will automatically close after specific amount of days
                    </p>
                  </div>
                  <VmoRadio toggle checked={isAutoResolve} onChange={() => setIsAutoResolve(!isAutoResolve)} />
                </div>
                <VmoForm className="my-2 errorLabel">
                  <VmoFormField>
                    {isAutoResolve && (
                      <VmoFormInput
                        type="text"
                        size="small"
                        style={{
                          width: '70px',
                          margin: '20px 0px 4px 0',
                        }}
                        value={noOfHours}
                        onChange={e => setNoOfHours(e.target.value)}
                        error={
                          !/^[1-9][0-9]?$|^99$/.test(noOfHours) && {
                            content: 'number allowed from 1 to 99',
                          }
                        }
                      />
                    )}
                  </VmoFormField>
                </VmoForm>
              </VmoCardContent>
            </VmoCard>
          </SettingLayout>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {
        key: 'addressSettings',
        icon: <SvgIcon path="common/settings" />,
        content: 'Address Settings',
      },
      render: () => (
        <VmoTabPane>
          <SettingLayout paddingLeft header="Address Settings" table={false} headerDivider={false}>
            <VmoCard fluid className="switchBox mt-3">
              <VmoCardContent>
                {address ? (
                  <VmoForm className="errorLabel">
                    <VmoFormGroup>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>Company Name</label>
                        <Controller
                          name="companyName"
                          render={({onChange, value, onBlur}) => (
                            <VmoFormInput
                              type="text"
                              maxLength={50}
                              fluid
                              value={value}
                              placeholder="Company Name"
                              onBlur={onBlur}
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.companyName && {
                                  content: removeDoubleQuotes(errors.companyName.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>Street Name</label>
                        <Controller
                          name="streetAddress"
                          render={({onChange, value}) => (
                            <VmoFormInput
                              type="text"
                              maxLength={100}
                              fluid
                              value={value}
                              placeholder="Street"
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.streetAddress && {
                                  content: removeDoubleQuotes(errors.streetAddress.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                    </VmoFormGroup>
                    <VmoFormGroup>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>City</label>
                        <Controller
                          name="city"
                          render={({onChange, value}) => (
                            <VmoFormInput
                              type="text"
                              maxLength={50}
                              fluid
                              value={value}
                              placeholder="City"
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.city && {
                                  content: removeDoubleQuotes(errors.city.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>State</label>
                        <Controller
                          name="state"
                          render={({onChange, value}) => (
                            <VmoFormInput
                              type="text"
                              maxLength={50}
                              fluid
                              value={value}
                              placeholder="State"
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.state && {
                                  content: removeDoubleQuotes(errors.state.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                    </VmoFormGroup>
                    <VmoFormGroup>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>Country</label>
                        <Controller
                          name="country"
                          render={({onChange, value}) => (
                            <VmoFormSelect
                              search
                              fluid
                              options={KooperCountryList}
                              selection
                              placeholder="Country"
                              value={value}
                              onChange={(e, {value}) => {
                                onChange(value)
                              }}
                              error={
                                errors.country && {
                                  content: removeDoubleQuotes(errors.country.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>Postal Code</label>
                        <Controller
                          name="postalCode"
                          render={({onChange, value}) => (
                            <VmoFormInput
                              type="text"
                              fluid
                              maxLength={10}
                              placeholder="Postal Code"
                              value={value}
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.postalCode && {
                                  content: removeDoubleQuotes(errors.postalCode.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                    </VmoFormGroup>
                    <VmoFormGroup>
                      <VmoFormField required style={{width: '100%'}}>
                        <label>Email</label>
                        <Controller
                          name="email"
                          render={({value, onChange}) => (
                            <VmoFormInput
                              fluid
                              type="email"
                              placeholder="Type your email.."
                              value={value}
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.email && {
                                  content: removeDoubleQuotes(errors.email.message),
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                      <VmoFormField style={{width: '100%'}} required>
                        <label>Phone Number</label>
                        <Controller
                          name="phone"
                          render={({value, onChange}) => (
                            <VmoFormInput
                              fluid
                              maxLength={10}
                              type="text"
                              name="Phone"
                              placeholder="Type your number.."
                              value={value}
                              onChange={e => {
                                onChange(e.target.value)
                              }}
                              error={
                                errors.phone && {
                                  content: errors.phone.message,
                                }
                              }
                            />
                          )}
                          control={control}
                        />
                      </VmoFormField>
                    </VmoFormGroup>

                    <div className="d-flex" style={{justifyContent: 'flex-end'}}>
                      <VmoButton onClick={() => setAddress(false)} basic className="mr-3">
                        Cancel
                      </VmoButton>
                      <VmoButton
                        className="m-0"
                        primary
                        onClick={handleSubmit(submitForm)}
                        loading={isLoadingData && type.includes(CREATE_ADDRESS)}
                      >
                        Save
                      </VmoButton>
                    </div>
                  </VmoForm>
                ) : (
                  <LockPermissionTooltip isRoleAccessDenied={!myOrganizationDefaultPermissions}>
                    <VmoButton
                      onClick={() => {
                        if (myOrganizationDefaultPermissions) {
                          setAddress(true)
                          setAddressId(null)
                          reset(initialCreateInfo)
                        }
                      }}
                      className="addressBtn"
                    >
                      <SvgIcon path="common/plus" /> Add New Address
                    </VmoButton>
                  </LockPermissionTooltip>
                )}
              </VmoCardContent>
            </VmoCard>

            <VmoCard fluid className="switchBox">
              {allAddressess.length > 0 ? (
                allAddressess.map(
                  ({id, companyName, streetAddress, city, state, country, postalCode, email, phone}) => {
                    return (
                      <VmoCardContent className="address-wrapper">
                        <div className="address-contents">
                          <strong>Company Name : {companyName}</strong>
                          <span>Street : {streetAddress}</span>
                          <span>City : {city}</span>
                          <span>State : {state}</span>
                          <span>Country : {country}</span>
                          <span>Zip : {postalCode}</span>
                          <span>Email: {email}</span>
                          <span>Phone No.: {phone}</span>
                        </div>

                        <div className="address-actions">
                          <VmoTooltip
                            trigger={
                              <VmoButton
                                icon={<SvgIcon path="common/edit2" />}
                                onClick={() => {
                                  if (myOrganizationDefaultPermissions) {
                                    setAddress(true)
                                    dispatch(getAddressData(id))
                                  }
                                }}
                              />
                            }
                            content={
                              myOrganizationDefaultPermissions
                                ? 'Edit'
                                : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                            }
                            size="mini"
                            position="top center"
                          />
                          <VmoTooltip
                            trigger={
                              <VmoButton
                                icon={<SvgIcon path="common/delete" />}
                                onClick={e => {
                                  e.stopPropagation()
                                  if (myOrganizationDefaultPermissions) {
                                    setEditId(id)
                                    setDeleteModal(true)
                                  }
                                }}
                              />
                            }
                            content={
                              myOrganizationDefaultPermissions
                                ? 'Delete'
                                : getPermissionTooltipContent({roleAccess: myOrganizationDefaultPermissions})
                            }
                            size="mini"
                            position="top center"
                          />
                        </div>
                      </VmoCardContent>
                    )
                  }
                )
              ) : (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'}}>
                  No Address Available
                </div>
              )}
            </VmoCard>
          </SettingLayout>
        </VmoTabPane>
      ),
    },
  ]

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/account-setting" />}
      header="Account Settings"
      subHeader="Manage all settings related to your account"
      learnMoreUrl={accountSettingLearnMore}
      table={false}
      headerDivider
    >
      <VmoTab menu={{secondary: true, pointing: true, color: 'blue'}} panes={panes} />
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteAddress}
          idTobeDeleted={editId}
          type={DELETE_ADDRESS}
        />
      )}
    </SettingLayout>
  )
}

export default AccountSettings
