/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller} from 'react-hook-form'
import _ from 'lodash'
import {
  VmoContainer,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormField,
  VmoSearch,
  VmoTable,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTableBody,
  VmoTableCell,
  VmoInput,
  VmoFormTextArea,
  VmoTooltip,
} from 'vmo-library'
import {getProductlist} from 'actions/productLibrary'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const testIsNumber = value => /^\d{0,8}(\.\d{1,2})?$/g.test(value)
const testIsPercentage = value => /^[0-9][0-9]?(\.\d+)?$|^100$/.test(value)
/**
 *
 * @param {number} totalPrice - multiplication of quantity and price
 * @param {number} discountValue 0-100 if disType is % otherwise valid number
 */

const getUnitTotal = (totalPrice, discountValue, taxValue) => {
  if (!testIsNumber(totalPrice)) return 0

  if (!testIsPercentage(discountValue)) return 0

  const discountAmount = Number((totalPrice * (100 - discountValue)) / 100)

  const totalTax = Number((discountAmount * taxValue) / 100 || 0)

  return Number(discountAmount + (discountAmount * totalTax) / discountAmount || 0)
}

const getAmount = (quantity, unitPrice) => {
  return Number(quantity * unitPrice)
}

const getCountedValue = (price, type, value) => {
  if (!testIsNumber(price)) return 0

  if (type === '%') {
    if (!testIsPercentage(value)) return 0
    return Number(((price * value) / 100).toFixed(2))
  }
  if (!testIsNumber(value)) return 0
  return Number(value)
}

function Product(props) {
  const {
    style,
    errors,
    control,
    setValue,
    watch,
    searchValue,
    setSearchValue,
    discountValue,
    setDiscountValue,
    taxValue,
    setTaxValue,
    setAdjustmentValue,
    adjustmentValue,
  } = props
  const dispatch = useDispatch()

  const [searchBoxOptions, setSearchBoxOptions] = useState([])
  const [optionsToShow, setOptionsToShow] = useState([])
  const [searchText, setSearchText] = useState('')

  const {productList = []} = useSelector(state => state.productLibrary)

  const watchProducts = watch('products')
  const watchSubtotal = watch('subTotal')
  const watchTotal = watch('total')
  const watchDiscount = watch('discount')
  const watchTax = watch('tax')

  const totalDiscount = () => {
    return Number((watchSubtotal * discountValue) / 100)
  }

  const getDiscountPrice = () => {
    return Number(watchSubtotal - (watchSubtotal * totalDiscount()) / watchSubtotal)
  }

  const totalTax = () => {
    return Number((getDiscountPrice() * taxValue) / 100 || 0)
  }

  useEffect(() => {
    if (!productList?.length) dispatch(getProductlist())
  }, [dispatch])

  const handleResultSelect = (e, {result}) => {
    setSearchValue(searchValue => {
      if (searchValue.some(sv => sv.productId === result.productId)) {
        return searchValue
      }
      return [...searchValue, result]
    })
    setSearchText('')
    setOptionsToShow([])
  }

  const handleSearchChange = (e, {value}) => {
    setSearchText(value)
    setTimeout(() => {
      if (value.length < 1) {
        setOptionsToShow(searchBoxOptions)
        setSearchText('')
        setSearchValue([])
        return
      }
      const re = new RegExp(_.escapeRegExp(searchText), 'i')
      const isMatch = result => re.test(result.title)
      setOptionsToShow(_.filter(searchBoxOptions, isMatch))
    }, 300)
  }

  /**
   *
   * @param {number} index - of an array
   * @param {string | number} value - for that field
   * @param {string} property - to change
   */
  const changeSearchValue = (index, value, property) => {
    setSearchValue(searchValue => {
      const tempSearchValue = [...searchValue]
      const newValue = {
        ...tempSearchValue[index],
        [property]: value,
      }
      tempSearchValue.splice(index, 1, newValue)
      return tempSearchValue
    })
  }
  // * set searchbox options for dropdown
  useEffect(() => {
    if (productList.length) {
      const options = productList.map(item => {
        return {
          productId: item.id,
          title: item.name,
          quantity: 1,
          unitAmount: 0,
          unitPrice: Number(item.unitPrice),
          discountValue: 0,
          taxValue: 0,
        }
      })
      setSearchBoxOptions(options)
    }
  }, [productList])

  useEffect(() => {
    if (watchProducts) {
      const subTotalValue = watchProducts.reduce((acc, ite) => Number(acc + ite.unitTotal), 0)
      setValue('subTotal', subTotalValue.toFixed(2))
    }
  }, [JSON.stringify(watchProducts)])

  useEffect(() => {
    if (watchProducts?.length) {
      watchProducts.map((charge, index) => {
        setValue(`products[${index}].unitTotal`, getCountedValue(watchSubtotal, charge.subType, charge.unitTotal))
      })
    }
  }, [JSON.stringify(watchProducts), JSON.stringify(watchSubtotal)])

  useEffect(() => {
    if (watchProducts?.length) {
      watchProducts.map((charge, index) => {
        setValue(`products[${index}].unitAmount`, getAmount(charge.quantity, charge.unitPrice))
      })
    }
  }, [JSON.stringify(watchProducts)])

  useEffect(() => {
    if (searchValue?.length) {
      const valueToSet = searchValue.map(({title, description, price, ...rest}) => {
        return {
          ...rest,
          unitTotal: getUnitTotal(rest.quantity * rest.unitPrice, rest.discountValue, rest.taxValue),
        }
      })
      setValue('products', valueToSet)
    }
  }, [searchValue, setValue])

  useEffect(() => {
    let subTot = Number(watchSubtotal || 0).toFixed(2)

    if (watchDiscount) {
      subTot = Number(getDiscountPrice() + (getDiscountPrice() * totalTax()) / getDiscountPrice() || 0)
    }
    const total = Number(subTot) + Number(adjustmentValue)

    setValue('total', total)
  }, [JSON.stringify(watchSubtotal), JSON.stringify(watchDiscount), JSON.stringify(watchTax), adjustmentValue])

  return (
    <VmoContainer style={style}>
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Invoice Item</h3>
          <p className="mt-0 mb-4 card-description">Add product in your invoice by searching it from list</p>
          <VmoForm>
            <Controller control={control} name="products" render={() => <input type="hidden" />} />
            <Controller control={control} name="subTotal" render={() => <input type="hidden" />} />
            <Controller control={control} name="total" render={() => <input type="hidden" />} />

            <VmoFormField>
              <div className="info-header">
                <label>Products</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Select products that you want to add in your invoice"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <VmoSearch
                placeholder="Search to get needed results"
                minCharacters={0}
                results={optionsToShow}
                value={searchText}
                onResultSelect={handleResultSelect}
                onSearchChange={_.debounce(handleSearchChange, 500, {
                  leading: true,
                })}
              />
            </VmoFormField>

            <VmoTable basic className="billProduct">
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Product Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Quantity</VmoTableHeaderCell>
                  <VmoTableHeaderCell>unitPrice</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Amount</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Discount</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Tax</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Total</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {searchValue?.length < 1 ? (
                  <tr>
                    <td colSpan="8" className="emptyValue required">
                      Add Line Items to Continue Filling Form
                    </td>
                  </tr>
                ) : (
                  searchValue?.map((item, index) => {
                    return (
                      <VmoTableRow key={`tablerow${index}`}>
                        <VmoTableCell>{index + 1}</VmoTableCell>
                        <VmoTableCell>{item.title}</VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            // type="number"
                            min={1}
                            value={item.quantity}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'quantity')}
                            style={{width: '70px'}}
                            onBlur={() => {
                              if (!testIsNumber(item.quantity)) {
                                changeSearchValue(index, '0', 'quantity')
                              }
                            }}
                          />
                        </VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            style={{width: '70px'}}
                            value={item.unitPrice}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'unitPrice')}
                            onBlur={() => {
                              if (!testIsNumber(item.unitPrice)) {
                                changeSearchValue(index, '0', 'unitPrice')
                              }
                            }}
                          />
                        </VmoTableCell>
                        <VmoTableCell>{getAmount(item.quantity, item.unitPrice)}</VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            style={{width: '100px'}}
                            value={item.discountValue}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'discountValue')}
                            onBlur={() => {
                              if (!testIsPercentage(item.discountValue)) {
                                changeSearchValue(index, '0', 'discountValue')
                              }
                            }}
                          />
                          %
                        </VmoTableCell>
                        <VmoTableCell>
                          <VmoInput
                            style={{width: '100px'}}
                            value={item.taxValue}
                            onChange={(e, {value}) => changeSearchValue(index, value, 'taxValue')}
                            onBlur={() => {
                              if (!testIsPercentage(item.taxValue)) {
                                changeSearchValue(index, '0', 'taxValue')
                              }
                            }}
                          />
                          %
                        </VmoTableCell>
                        <VmoTableCell>
                          {getUnitTotal(item.quantity * item.unitPrice, item.discountValue, item.taxValue)}
                        </VmoTableCell>
                      </VmoTableRow>
                    )
                  })
                )}
              </VmoTableBody>
            </VmoTable>
            <div className="totalBillCount" style={{justifyContent: 'end'}}>
              <div className="count">
                <p className="d-flex">
                  <span>Subtotal</span>
                  <span>{watchSubtotal}</span>
                </p>

                <p className="d-flex">
                  <label>
                    Discount
                    <Controller
                      name="discount"
                      render={({value, onChange}) => (
                        <VmoInput
                          style={{width: '100px', marginLeft: '29px'}}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                            setDiscountValue(value)
                          }}
                        />
                      )}
                      control={control}
                    />
                    %
                  </label>
                  <span>{totalDiscount().toFixed(2)}</span>
                </p>

                <p className="d-flex">
                  <label>
                    Tax
                    <Controller
                      name="tax"
                      render={({value, onChange}) => (
                        <VmoInput
                          style={{width: '100px', marginLeft: '65px'}}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                            setTaxValue(value)
                          }}
                        />
                      )}
                      control={control}
                    />
                    %
                  </label>
                  <span>{totalTax().toFixed(2)}</span>
                </p>

                <p className="d-flex">
                  <label>
                    Adjustment
                    <Controller
                      name="adjustment"
                      render={({value, onChange}) => (
                        <VmoInput
                          style={{width: '100px', marginLeft: '8px'}}
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                            setAdjustmentValue(value)
                          }}
                        />
                      )}
                      control={control}
                    />
                  </label>
                  <span>{adjustmentValue}</span>
                </p>

                <p className="d-flex">
                  <label>Grand Total</label>
                  <span>{watchTotal}</span>
                </p>
              </div>
            </div>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">Terms and Conditions</h3>
          <p className="mt-0 mb-4 card-description">
            Mention terms and conditions that are to be displayed on the invoice
          </p>
          <VmoForm className="errorLabel">
            <VmoFormField>
              <div className="info-header">
                <label>Terms and Conditions</label>
                <VmoTooltip
                  content="Specify terms and conditions for the invoice"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                name="terms"
                render={({onChange, value}) => (
                  <VmoFormTextArea
                    maxLength={200}
                    type="text"
                    value={value}
                    placeholder="Terms and Conditions"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    error={
                      errors?.terms && {
                        content: removeDoubleQuotes(errors?.terms?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
    </VmoContainer>
  )
}

export default Product
