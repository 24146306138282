import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS, METRICS_RESOLUTION_BREAKDOWN_ANALYTICS} from './types'

export function metricsFirstResponseBreakdownAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/metrics/firstresponsetimebreakdown`,
    method: 'POST',
    label: METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS,
    data,
  })
}

export function metrixResolutionBreakdownAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/metrics/resolutiontimebreakdown`,
    method: 'POST',
    label: METRICS_RESOLUTION_BREAKDOWN_ANALYTICS,
    data,
  })
}
