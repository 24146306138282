/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable default-case */
import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {Helmet} from 'react-helmet'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import parse from 'html-react-parser'
import _ from 'lodash'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import {
  VmoButton,
  VmoButtonGroup,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoImage,
  VmoGrid,
  VmoGridColumn,
  VmoLoader,
  VmoIcon,
  VmoCheckbox,
  VmoFormInput,
} from 'vmo-library'

import {ADD_EVENT_MEETING_BOOK, BOOKING_SLOT_AVALIBLITY, GOOGLE_CALENDAR_ACCESS} from 'actions/types'
import {addEventMeetingBook, bookingSlotAvaliablity} from 'actions/meeting'
import create from 'assets/calender.svg'
import vlogo from 'assets/desk-v-logo.svg'
import {copyToClipboardWithoutInput, get, startCase} from 'utils/helper'
import TimeZoneDropdown from 'components/common/TimeZoneDropdown'
import SvgIcon from 'components/common/SvgIcon'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const MeetingBook = () => {
  const history = useHistory()

  const [getWidgetData, setGetWidgetData] = useState(null)
  const [meetingUserData, setMeetingUserData] = useState({})
  const [loadingData, setLoadingData] = useState(true)
  const [copySuccessEmail, setCopySuccessEmail] = useState('COPY')
  const [copySuccessPhone, setCopySuccessPhone] = useState('COPY')

  window.addEventListener('message', ({data}) => {
    if (data.mId) {
      setGetWidgetData(data)
    }
  })
  const query = useQuery()
  const id = query.get('mId')
  momentDurationFormatSetup(moment)
  const dispatch = useDispatch()
  // Auto Detect TimeZone
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [timeZoneList, setTimeZoneList] = useState([])
  const [isNextpage, setnextpage] = useState(0)
  const [selectedDateIndex, setSelectedDateIndex] = useState(null)
  const {booking_slot = {}} = useSelector(state => state.meetings)
  const {dayAvailable = [], meetingDetail = {}, timezone = '', userBusyTime, statusCode} = booking_slot
  const {
    ownerSocialMedia,
    meetingDuration,
    meetingScheduleValue,
    meetingBufferTime,
    meetingTimeFrequency,
    meetingFormFields,
    formFieldWithKnownValue,
    submitFormIfAllKnownValue,
    meetingPrivacyPolicy = {},
    termsAndCondition,
  } = meetingDetail
  const {privacyTitle, privacyText, privacyCheck} = meetingPrivacyPolicy
  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const [getAllDates, setGetAllDates] = useState([])
  const [libraryDataObjFormat, setLibraryDataObjFormat] = useState(new Date('2020-12'))
  const [libraryDisabledMonth, setLibraryDisabledMonth] = useState(new Date('2020-12'))
  const [timeDurationMeeting, setTimeDurationMeeting] = useState()
  const [selectedDuration, setSelectedDuration] = useState()
  const [selectedDate, setSelectedDate] = useState(null)
  const [getAllSlotsbyDate, setGetAllSlotsbyDate] = useState(null)
  const [finalData, setFinalData] = useState({})
  const [addEventObj, setAddEventObj] = useState({dateTime: ''})
  // Get Disable and Enable Date List on Window this function RUN...
  const [dateDisabled, setDateDisabled] = useState([])
  // Time Zone
  const [timeZoneNew, setTimeZone] = useState(tz)

  const initalCreate = {
    meetingId: id,
    firstName: '',
    lastName: '',
    email: '',
  }

  const {errors, reset, getValues, register, handleSubmit} = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initalCreate,
    // resolver: joiResolver(addEventMeetingSchema),
  })

  const UserDetailShow = () => {
    return (
      <VmoCard className="userInfo">
        <VmoCardContent>
          <div className="user-content">
            <h5>{_.get(meetingDetail, 'ownerName')}</h5>

            <h6>{_.get(meetingDetail, 'ownerDesignation')}</h6>

            <div className="agentAvtar">
              {_.get(meetingDetail, 'meetingAvatar') ? (
                <img src={_.get(meetingDetail, 'meetingAvatar')} className="img-fluid" alt="img" />
              ) : (
                'Img'
              )}
            </div>
            {_.size(ownerSocialMedia) !== 0 && (
              <div className="socialLinks">
                {ownerSocialMedia.map(({category, url}) => {
                  if (category === 'facebook') {
                    return (
                      <a href={`https://${url}`} target="_blank" rel="noreferrer">
                        <VmoIcon name="facebook official" />
                      </a>
                    )
                  }
                  if (category === 'twitter') {
                    return (
                      <a href={`https://${url}`} target="_blank" rel="noreferrer">
                        <VmoIcon name="twitter square" />
                      </a>
                    )
                  }
                  if (category === 'linkedin') {
                    return (
                      <a href={`https://${url}`} target="_blank" rel="noreferrer">
                        <VmoIcon name="linkedin" />
                      </a>
                    )
                  }
                })}
              </div>
            )}
            <p className="kooper-lead">{_.get(meetingDetail, 'displayMessage')}</p>

            <div className="contactInfo">
              {_.get(meetingDetail, 'ownerEmail') && (
                <div className="conBar">
                  <VmoFormInput
                    value={_.get(meetingDetail, 'ownerEmail')}
                    action={{
                      color: 'blue',
                      labelPosition: 'right',
                      icon: 'copy',
                      content: 'Copy',
                      onClick: e =>
                        copyToClipboardWithoutInput(_.get(meetingDetail, 'ownerEmail'), setCopySuccessEmail),
                    }}
                  />
                  {/* < SvgIcon path="common/mail" />
                  <p>{_.get(meetingDetail, "ownerEmail")}</p>
                  <VmoButton
                    onClick={(e) =>
                      copyToClipboardWithoutInput(
                        _.get(meetingDetail, "ownerEmail"),
                        setCopySuccessEmail
                      )
                    }
                  >
                    {copySuccessEmail}
                  </VmoButton> */}
                </div>
              )}
              {_.get(meetingDetail, 'ownerNumber') && (
                <div className="conBar">
                  <SvgIcon path="settings/phone" />
                  <p>{_.get(meetingDetail, 'ownerNumber')}</p>
                  <VmoButton
                    onClick={e => copyToClipboardWithoutInput(_.get(meetingDetail, 'ownerNumber'), setCopySuccessPhone)}
                  >
                    {copySuccessPhone}
                  </VmoButton>
                </div>
              )}
            </div>
          </div>
        </VmoCardContent>
      </VmoCard>
    )
  }

  useEffect(() => {
    if (id) {
      dispatch(bookingSlotAvaliablity(id))
    }
    const list = moment.tz.names().map(l => ({
      key: l,
      text: l,
      value: l,
    }))
    setTimeZoneList(list)
  }, [])

  useEffect(() => {
    if (successLabels.includes(BOOKING_SLOT_AVALIBLITY)) {
      if (formFieldWithKnownValue) {
        setMeetingUserData({
          ...JSON.parse(get(['formFieldWithKnownValue'], JSON.parse(window.localStorage.getItem('sales')))),
        })
        reset({
          meetingId: id,
          ...get(['formFieldWithKnownValue'], JSON.parse(window.localStorage.getItem('sales'))),
        })
      }
      setTimeDurationMeeting(DurationConvertMinToString(meetingDuration))
      setSelectedDuration(meetingTimeFrequency || meetingDuration[0])
      if (dayAvailable) {
        const start = moment().format('YYYY-MM')
        const end = moment()
          .add(meetingScheduleValue - 1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD')
        const dateTimeData = startEndDateDurationFunction(
          new Date(start),
          new Date(end),
          meetingTimeFrequency || meetingDuration[0]
        )
        for (const property in dateTimeData) {
          dateTimeData[property].sort(function (a, b) {
            return new Date(`1970/01/01 ${a}`) - new Date(`1970/01/01 ${b}`)
          })
        }
        setFinalData(dateTimeData)
        setLoadingData(false)
      }
    }
    if (successLabels.includes(ADD_EVENT_MEETING_BOOK)) {
      const values = getValues()
      const oldSales = JSON.parse(window.localStorage.getItem('sales') || '{}')
      window.localStorage.setItem(
        'sales',
        JSON.stringify({
          ...oldSales,
          formFieldWithKnownValue: JSON.stringify(!_.isEmpty(meetingUserData) ? meetingUserData : values),
        })
      )
      history.push({
        pathname: '/meetings/confirm/new',
        state: {
          detail: moment
            .tz(
              `${moment(selectedDate).format('YYYY-MM-DD')} ${moment(addEventObj.dateTime, ['h:mm A']).format(
                'h:mm A'
              )}`,
              timeZoneNew
            )
            .format('dddd, MMMM DD,YYYY LT'),
          heading: _.get(meetingDetail, 'meetingHeading'),
          owner: _.get(meetingDetail, 'ownerName'),
        },
      })
    }
    if (successLabels.includes(GOOGLE_CALENDAR_ACCESS)) {
      window.location.reload()
    }
  }, [successLabels])

  useEffect(() => {
    setAddEventObj({dateTime: ''})
    if (dayAvailable) {
      const start = moment().format('YYYY-MM')
      const end = moment()
        .add(meetingScheduleValue - 1, 'weeks')
        .endOf('week')
        .format('YYYY-MM-DD')
      const dateTimeData = startEndDateDurationFunction(new Date(start), new Date(end), selectedDuration)
      for (const property in dateTimeData) {
        dateTimeData[property].sort(function (a, b) {
          return new Date(`1970/01/01 ${a}`) - new Date(`1970/01/01 ${b}`)
        })
      }
      setFinalData(dateTimeData)
    }
  }, [selectedDuration, timeZoneNew])

  const RemainingDates = (start, end) => {
    const disabledReaminingDates = []
    for (let arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      const date = moment(dt).format('YYYY-MM-DD')
      disabledReaminingDates.push(date)
    }
    return disabledReaminingDates
  }

  let avaiableDates = []
  useEffect(() => {
    if (_.size(finalData) !== 0) {
      if (!selectedDate) {
        setSelectedDate(new Date(Object.keys(finalData)[0]))
      }
      if (!getAllSlotsbyDate) {
        setGetAllSlotsbyDate(Object.keys(finalData)[0])
      }
      if (!selectedDateIndex) {
        setSelectedDateIndex(0)
      }
      avaiableDates = _.keys(finalData)
      const disabledDateList = getAllDates.filter(val => !avaiableDates.includes(val))
      const start = moment(disabledDateList[_.size(disabledDateList) - 1]).format('YYYY-MM-DD')
      const end = moment().add(meetingScheduleValue, 'weeks').endOf('month').format('YYYY-MM-DD')
      const remainingDate = RemainingDates(new Date(start), new Date(end))
      setDateDisabled([...disabledDateList, ...remainingDate])
      if (avaiableDates.length > 0) {
        setLibraryDataObjFormat(avaiableDates[0].substr(0, 7))
        setLibraryDisabledMonth(avaiableDates[_.size(avaiableDates) - 1].substr(0, 7))
      }
    }
  }, [finalData])
  // date click event
  const handleDayClick = (day, modifiers = {}) => {
    setAddEventObj({dateTime: ''})
    if (modifiers.disabled) {
      return
    }
    setSelectedDate(modifiers.selected ? undefined : day)
    setSelectedDateIndex(_.findIndex(finalData, moment(day).format('YYYY-MM-DD')))
    setGetAllSlotsbyDate(moment(day).format('YYYY-MM-DD'))
    // dispatch(unavailableSlots(id, { date: moment(day).format("YYYY-MM-DD") }));
  }

  const addDurations = (date, time) => {
    const avaialbleFROM = new Date(`${date} ${time}`)
    const temp = avaialbleFROM.getHours() * 60 + avaialbleFROM.getMinutes() + selectedDuration
    return moment.duration(temp, 'minutes').format('HH:mm')
  }

  const addEventBtn = data => {
    const time12to24 = moment(addEventObj.dateTime, ['h:mm A']).format('HH:mm')
    const date = moment(selectedDate).format('YYYY-MM-DD')
    const newObj = {
      start: {
        dateTime: moment.tz(`${date} ${time12to24}`, timeZoneNew).format(),
        timeZone: timeZoneNew,
      },
      end: {
        dateTime: moment.tz(`${date} ${addDurations(date, time12to24)}`, timeZoneNew).format(),
        timeZone: timeZoneNew,
      },
    }
    if (getWidgetData) {
      if (getWidgetData.hide_gdpr_banner === 'true') {
        setnextpage(2)
        setMeetingUserData(data)
      } else {
        dispatch(addEventMeetingBook({meetingId: id, ...data, ...newObj}))
      }
    } else if (termsAndCondition) {
      setnextpage(2)
      setMeetingUserData(data)
    } else {
      dispatch(addEventMeetingBook({meetingId: id, ...data, ...newObj}))
    }
  }

  const addEventBtnTermCon = () => {
    const time12to24 = moment(addEventObj.dateTime, ['h:mm A']).format('HH:mm')
    const date = moment(selectedDate).format('YYYY-MM-DD')
    const newObj = {
      start: {
        dateTime: moment.tz(`${date} ${time12to24}`, timeZoneNew).format(),
        timeZone: timeZoneNew,
      },
      end: {
        dateTime: moment.tz(`${date} ${addDurations(date, time12to24)}`, timeZoneNew).format(),
        timeZone: timeZoneNew,
      },
    }
    dispatch(addEventMeetingBook({meetingId: id, ...meetingUserData, ...newObj}))
  }
  const renderFormData = () => {
    return meetingFormFields.slice(3).map(item => {
      switch (item.vaildation) {
        case 'number':
          return (
            <VmoFormField>
              <div className="info-header">
                <label className={item.require && 'label-class'}>{startCase(item?.field)}</label>
              </div>
              <input
                name={item?.field}
                type="number"
                className={errors[item.field] ? 'active' : null}
                placeholder="Type number.."
                ref={register({
                  required: item.require && 'Field is required',
                })}
              />
              {errors[item.field] && <span>{errors[item.field].message}</span>}
            </VmoFormField>
          )
        case 'text':
          return (
            <VmoFormField>
              <div className="info-header">
                <label className={item.require && 'label-class'}>{startCase(item?.field.split(' ').join(''))}</label>
              </div>
              <input
                name={item?.field}
                type="text"
                className={errors[item.field] ? 'active' : null}
                placeholder="Type text.."
                ref={register({
                  required: item.require && 'field is required',
                  pattern: {
                    value: /[A-Za-z]{3}/,
                    message: 'length should be min 3 characters',
                  },
                })}
              />
              {errors[item.field] && <span>{errors[item.field].message}</span>}
            </VmoFormField>
          )
        case 'email':
          return (
            <VmoFormField>
              <div className="info-header">
                <label className={item.require && 'label-class'}>{startCase(item?.field.split(' ').join(''))}</label>
              </div>
              <input
                name={item?.field}
                type="email"
                placeholder="Type text.."
                className={errors[item.field] ? 'active' : null}
                ref={register({
                  required: item.require && 'Field is required',
                  pattern: {
                    value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                    message: 'enter valid email address',
                  },
                })}
              />
              {errors[item.field] && <span>{errors[item.field].message}</span>}
            </VmoFormField>
          )
        case 'textarea':
          return (
            <VmoFormField>
              <div className="info-header">
                <label className={item.require && 'label-class'}>{startCase(item?.field.split(' ').join(''))}</label>
              </div>
              <textarea
                name={item?.field}
                style={{height: '0'}}
                type="text"
                className={errors[item.field] ? 'active' : null}
                placeholder="Type message.."
                ref={register({
                  required: item.require && 'Field is required',
                  pattern: {
                    value: /[A-Za-z]{3}/,
                    message: 'length should be min 4 characters long',
                  },
                })}
              />
              {errors[item.field] && <span>{errors[item.field].message}</span>}
            </VmoFormField>
          )
        default:
          break
      }
    })
  }

  const timeConvert = (nFrom, nTo) => {
    const hmsFrom = nFrom.substr(11, 15)
    const aFrom = hmsFrom.split(':')
    const minutesFrom = +aFrom[0] * 60 + +aFrom[1]

    // before time remove
    const numBeforeMeeting = minutesFrom - meetingBufferTime
    const hoursBeforeMeeting = numBeforeMeeting / 60
    const rhoursBeforeMeeting = Math.floor(hoursBeforeMeeting)
    const minutesBeforeMeeting = (hoursBeforeMeeting - rhoursBeforeMeeting) * 60
    const rminutesBeforeMeeting = Math.round(minutesBeforeMeeting)

    // after time remove
    const hmsTo = nTo.substr(11, 15)
    const aTo = hmsTo.split(':')
    const minutesTo = +aTo[0] * 60 + +aTo[1]

    const numAfterMeeting = minutesTo + meetingBufferTime
    const hoursAfterMeeting = numAfterMeeting / 60
    const rhoursAfterMeeting = Math.floor(hoursAfterMeeting)
    const minutesAfterMeeting = (hoursAfterMeeting - rhoursAfterMeeting) * 60
    const rminutesAfterMeeting = Math.round(minutesAfterMeeting)
    return {
      beforeTime: `${nFrom.substr(0, 10)} ${`0${rhoursBeforeMeeting}`.slice(`0${rminutesBeforeMeeting}`).slice(-2)}`,
      afterTime: `${nTo.substr(0, 10)} ${`0${rhoursAfterMeeting}`.slice(-2)}:${`0${rminutesAfterMeeting}`.slice(-2)}`,
    }
  }

  const getuserBusyTimeMin = date => {
    let getUserBusyTimeMinConverted = []
    const getDate = userBusyTime.filter(list => moment(list.from).format('YYYY-MM-DD') === date)
    if (getDate) {
      getUserBusyTimeMinConverted = getDate.map(({from, to}) => {
        const hmsFrom = moment(from).format('YYYY-MM-DD HH:mm').substr(11, 15)
        const aFrom = hmsFrom.split(':')
        const minutesFrom = +aFrom[0] * 60 + +aFrom[1]
        const hmsTo = moment(to).format('YYYY-MM-DD HH:mm').substr(11, 15)
        const aTo = hmsTo.split(':')
        const minutesTo = +aTo[0] * 60 + +aTo[1]

        const fromUn = moment(from).format('YYYY-MM-DD HH:mm')
        const toUn = moment(to).format('YYYY-MM-DD HH:mm')

        const userBusyOldTimeFrom = moment.tz(fromUn, timezone).utc().format('YYYY MM DD HH:mm')

        const userBusyOldTimeTo = moment.tz(toUn, timezone).utc().format('YYYY MM DD HH:mm')

        const userBusyNewTimeFrom = moment.utc(userBusyOldTimeFrom).tz(timeZoneNew).format('YYYY-MM-DD HH:mm')

        const userBusyNewTimeTo = moment.utc(userBusyOldTimeTo).tz(timeZoneNew).format('YYYY-MM-DD HH:mm')

        const bufferTime = timeConvert(userBusyNewTimeFrom, userBusyNewTimeTo)

        return {
          from: minutesFrom,
          to: minutesTo,
          fromNewDateTimeZone: userBusyNewTimeFrom,
          toNewDateTimeZone: userBusyNewTimeTo,
          beforeTime: bufferTime.beforeTime,
          afterTime: bufferTime.afterTime,
        }
      })
    }
    return getUserBusyTimeMinConverted
  }

  // var dateWithTimesArray = [];
  const arrayObj = {}
  const slotSpiltwithUserBzyTimeFunction = data => {
    const {date, slotFromTO, selectedDuration, timeZoneNew} = data

    const getMinOfUserBusyTime = getuserBusyTimeMin(date, timeZoneNew)

    slotFromTO.map(({from, to}, i) => {
      let stop
      for (stop = from; stop <= to - selectedDuration; stop += selectedDuration) {
        const temp = moment.utc(moment.duration(stop, 'minutes').asMilliseconds()).format('HH:mm')
        const edr = getMinOfUserBusyTime.find(o =>
          o.from == stop ? o.from == stop : o.from > stop && stop + selectedDuration > o.from
        )
        if (edr && edr.from == stop) {
          stop = edr.to - selectedDuration
        } else if (edr) {
          stop = edr.from - selectedDuration
        }

        const oldTime = moment.tz(`${date} ${temp}`, timezone).utc().format('YYYY MM DD HH:mm')

        const newTime = moment.utc(oldTime).tz(timeZoneNew).format('YYYY-MM-DD HH:mm')

        // creating blank array
        if (
          !(newTime.substring(0, 10) in arrayObj) &&
          moment.utc(oldTime).tz(timeZoneNew) > moment().tz(timeZoneNew).utc()
        ) {
          arrayObj[newTime.substring(0, 10)] = []
        }

        // if slots are busy
        if (edr) {
          if (
            !moment(moment.tz(timeZoneNew).utc(oldTime).format('YYYY MM DD LT')).isSameOrAfter(newTime) &&
            moment(newTime).isSameOrAfter(
              moment(moment.tz(timeZoneNew).utc(oldTime).format('YYYY MM DD HH:mm'))
                .tz(timeZoneNew)
                .utc()
                .add(15, 'minutes')
            ) &&
            !_.includes(arrayObj[newTime.substring(0, 10)], moment(newTime).format('LT,')) &&
            moment(newTime).isBetween(edr.fromNewDateTimeZone, edr.toNewDateTimeZone, undefined, '[)') &&
            edr.fromNewDateTimeZone &&
            edr.toNewDateTimeZone
          ) {
            arrayObj[newTime.substring(0, 10)].push(moment(newTime).format('LT,'))
          }
        } else if (
          !moment(moment.tz(timeZoneNew).utc(oldTime).format('YYYY MM DD LT')).isSameOrAfter(newTime) &&
          !_.includes(arrayObj[newTime.substring(0, 10)], moment(newTime).format('LT')) &&
          moment(newTime).isSameOrAfter(
            moment(moment.tz(timeZoneNew).utc(oldTime).format('YYYY MM DD HH:mm'))
              .tz(timeZoneNew)
              .utc()
              .add(15, 'minutes')
          ) &&
          !getMinOfUserBusyTime.some(({beforeTime, afterTime}) =>
            moment(newTime).isBetween(beforeTime, afterTime, undefined, '[)')
          )
        ) {
          arrayObj[newTime.substring(0, 10)].push(moment(newTime).format('LT'))
        }
      }
    })
  }

  // meeting Duration MIN to String
  const DurationConvertMinToString = val => {
    if (typeof val === 'number') {
      const hours = Math.floor(val / 60)
      const realmin = val % 60
      return hours
        ? `${`0${hours}`.slice(-2)}:${`0${realmin}`.slice(-2)} ${hours === 0 ? 'mins' : 'hours'}`
        : `${`0${realmin}`.slice(-2)} ${hours === 0 ? 'mins' : 'hours'}`
    }
    if (Array.isArray(val)) {
      val = val.map(minutes => {
        const hours = Math.floor(minutes / 60)
        const realmin = minutes % 60
        return {
          timeString: `${`0${hours}`.slice(-2)}:${`0${realmin}`.slice(-2)} ${hours === 0 ? 'mins' : 'hours'}`,
          min: minutes,
        }
      })
      return val
    }
  }

  const startEndDateDurationFunction = (start, end, selectedDuration) => {
    const tempDate = []
    const dateWithTimesArray = []
    for (let arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      const date = moment(dt).format('YYYY-MM-DD')
      tempDate.push(date)
      const dayWeeekName = moment(dt).format('dddd')
      const slotFromTO = dayAvailable[dayWeeekName]
      if (date && slotFromTO && moment(date).isSameOrAfter(new Date(), 'day')) {
        slotSpiltwithUserBzyTimeFunction({
          date,
          slotFromTO,
          selectedDuration,
          timeZoneNew,
        })
      }
    }

    dateWithTimesArray.push(arrayObj)
    setGetAllDates(tempDate)
    return arrayObj
  }

  useEffect(() => {
    setTimeout(() => {
      setCopySuccessPhone('COPY')
      setCopySuccessEmail('COPY')
    }, 500)
  }, [copySuccessEmail, copySuccessPhone])

  return (
    <>
      {statusCode === 500 ? (
        <div className="vmoSideBar-wrap">
          <VmoGrid columns="equal">
            <VmoGridColumn>
              <VmoImage style={{width: '400px'}} src={create} />
            </VmoGridColumn>
            <VmoGridColumn>
              <h1>Well, this is disappointing.</h1>
              <p>Sorry. Sometimes things don’t go according to plan.</p>
              <p>Please check back with the person who sent you this link, because that page is nowhere to be found.</p>
            </VmoGridColumn>
          </VmoGrid>
        </div>
      ) : loadingData && meetingDetail ? (
        <VmoLoader active />
      ) : (
        <div className="meetBookPage">
          <VmoContainer>
            <h1>{_.get(meetingDetail, 'meetingHeading')}</h1>
            <div className="meet-wrap">
              {getWidgetData ? (
                getWidgetData.hide_event_type_details === 'true' ? (
                  <UserDetailShow />
                ) : null
              ) : (
                _.get(meetingDetail, 'ownerDetail') && <UserDetailShow />
              )}
              <VmoCard fluid className="meetBooking">
                <VmoCardContent>
                  {isNextpage === 1 && (
                    <div className="meet-conf">
                      <h5>Confirm Meeting</h5>
                      <h3>
                        {moment
                          .tz(
                            `${moment(selectedDate).format('YYYY-MM-DD')} ${moment(addEventObj.dateTime, [
                              'h:mm A',
                            ]).format('HH:mm')}`,
                            timeZoneNew
                          )
                          .format('dddd, MMMM DD,YYYY LT')}
                      </h3>
                      <p>{`${DurationConvertMinToString(selectedDuration)} meeting, ${timeZoneNew}`}</p>

                      <VmoForm className="meetingForm">
                        <VmoFormGroup>
                          <VmoFormField required width={8}>
                            <label>First Name</label>
                            <input
                              name="firstName"
                              type="text"
                              maxLength={30}
                              placeholder="Type name.."
                              className={errors.firstName ? 'active' : null}
                              ref={register({
                                required: 'Field is Required',
                                pattern: {
                                  value: /[A-Za-z]{3}/,
                                  message: 'Length should be min 3 characters long',
                                },
                              })}
                            />
                            {errors.firstName && <span>{errors.firstName?.message}</span>}
                          </VmoFormField>
                          <VmoFormField required width={8}>
                            <label>Last Name</label>
                            <input
                              name="lastName"
                              type="text"
                              maxLength={30}
                              placeholder="Type name.."
                              className={errors.lastName ? 'active' : null}
                              ref={register({
                                required: 'Field is Required',
                                pattern: {
                                  value: /[A-Za-z]{3}/,
                                  message: 'Length should be min 3 characters long',
                                },
                              })}
                            />
                            {errors.lastName && <span>{errors.lastName?.message}</span>}
                          </VmoFormField>
                        </VmoFormGroup>
                        <VmoFormField required width={16}>
                          <label>Enter your Email addres</label>
                          <input
                            name="email"
                            type="text"
                            className={errors.email ? 'active' : null}
                            placeholder="Type name.."
                            ref={register({
                              required: 'Field is Required',
                              pattern: {
                                value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                message: 'Enter valid email address',
                              },
                            })}
                          />
                          {errors.email && <span>{errors.email?.message}</span>}
                        </VmoFormField>
                        {renderFormData()}
                        {/* <VmoButton
                          icon={
                            <VmoTooltip
                              content="Add"
                              position="top center"
                              size="mini"
                              trigger={<SvgIcon path="common/plus" />}
                            />
                          }
                        /> */}
                      </VmoForm>
                      <VmoDivider hidden />
                      <div className="cal-action-btn mt-2">
                        <VmoButton basic onClick={() => setnextpage(0)}>
                          Back
                        </VmoButton>
                        <VmoButton
                          className="ml-3"
                          loading={isLoadingData && type.includes(ADD_EVENT_MEETING_BOOK)}
                          primary
                          style={{
                            backgroundColor: `#${getWidgetData ? getWidgetData.brandColor : ''}`,
                          }}
                          onClick={handleSubmit(addEventBtn)}
                        >
                          {getWidgetData
                            ? getWidgetData.hide_gdpr_banner === 'true'
                              ? 'Next'
                              : 'Confirm Booking'
                            : termsAndCondition
                            ? 'Next'
                            : 'Confirm Booking'}
                          {/* {termsAndCondition ? "Next" : "Confirm Booking"} */}
                        </VmoButton>
                      </div>
                    </div>
                  )}
                  {isNextpage === 2 && (
                    <VmoContainer>
                      <h1 className="mt-0">{privacyTitle}</h1>
                      <h3 className="mt-0 mb-4">Terms & Condition</h3>
                      {parse(privacyText)}
                      <VmoForm className="mt-4">
                        <VmoFormField>
                          <VmoCheckbox disabled checked label={privacyCheck} />
                        </VmoFormField>
                        <div className="cal-action-btn mt-2">
                          <VmoButton basic onClick={() => setnextpage(1)}>
                            Back
                          </VmoButton>
                          <VmoButton
                            className="ml-3"
                            loading={isLoadingData && type.includes(ADD_EVENT_MEETING_BOOK)}
                            primary
                            style={{
                              backgroundColor: `#${getWidgetData ? getWidgetData.brandColor : ''}`,
                            }}
                            onClick={() => addEventBtnTermCon()}
                          >
                            Confirm Booking
                          </VmoButton>
                        </div>
                      </VmoForm>
                    </VmoContainer>
                  )}
                  {isNextpage === 0 && (
                    <>
                      {_.size(timeDurationMeeting) > 1 && (
                        <>
                          <h5 className="mt-0 mb-2">How long do you need?</h5>
                          <div className="d-flex mb-4" style={{justifyContent: 'start'}}>
                            <VmoButtonGroup>
                              {(timeDurationMeeting || []).map(({timeString, min}) => (
                                <VmoButton
                                  active={selectedDuration === min}
                                  disabled={meetingTimeFrequency !== 0}
                                  style={{
                                    backgroundColor: `#${
                                      selectedDuration === min && getWidgetData ? getWidgetData.brandColor : ''
                                    }`,
                                  }}
                                  primary={selectedDuration === min}
                                  onClick={() => setSelectedDuration(min)}
                                >
                                  {timeString}
                                </VmoButton>
                              ))}
                            </VmoButtonGroup>
                            <p className="kooper-lead ml-4 mb-0">
                              {DurationConvertMinToString(selectedDuration)}
                              meeting
                            </p>
                          </div>
                        </>
                      )}

                      <div className="cal-wrap">
                        <div className="cal-day">
                          <Helmet>
                            <style>
                              {`.DayPicker-Day--selected {
                                     background-color: #${getWidgetData && getWidgetData.brandColor} !important;
                                     color: white;}`}
                            </style>
                          </Helmet>
                          <DayPicker
                            month={libraryDataObjFormat ? new Date(libraryDataObjFormat) : new Date('2020-12')}
                            fromMonth={libraryDataObjFormat ? new Date(libraryDataObjFormat) : new Date('2020-12')}
                            toMonth={libraryDisabledMonth ? new Date(libraryDisabledMonth) : new Date('2020-12')}
                            selectedDays={selectedDate}
                            disabledDays={(dateDisabled || []).map(list => new Date(list))}
                            modifiers={(avaiableDates || []).map(list => new Date(list))}
                            onDayClick={handleDayClick}
                          />
                        </div>
                        <div className="cal-time">
                          <h5>{moment(selectedDate).format('dddd, MMMM DD')}</h5>
                          <div className="cal-booktime">
                            {getAllSlotsbyDate && finalData ? (
                              (finalData[getAllSlotsbyDate] || []).map(time => (
                                <VmoButton
                                  basic={addEventObj.dateTime !== time}
                                  fluid
                                  disabled={_.includes(time, ',')}
                                  primary={addEventObj.dateTime === time}
                                  style={{
                                    backgroundColor: `#${
                                      addEventObj.dateTime === time && getWidgetData ? getWidgetData.brandColor : ''
                                    }`,
                                  }}
                                  onClick={() => {
                                    setAddEventObj({
                                      dateTime: time,
                                    })
                                  }}
                                >
                                  {_.includes(time, ',') ? _.replace(time, ',', '') : time}
                                </VmoButton>
                              ))
                            ) : (
                              <p>Please select avaiable dates</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex my-4" style={{justifyContent: 'start'}}>
                        <TimeZoneDropdown
                          realTime
                          format="Z (z) LT"
                          direction="right"
                          search
                          onChange={(e, {value}) => setTimeZone(value)}
                          value={timeZoneNew}
                        />
                        <p className="ml-4">{timeZoneNew}</p>
                      </div>

                      <div className="cal-action-btn mt-2">
                        <VmoButton
                          basic
                          onClick={() =>
                            setAddEventObj({
                              dateTime: '',
                            })
                          }
                        >
                          Cancel
                        </VmoButton>
                        <VmoButton
                          disabled={!(selectedDate && addEventObj.dateTime)}
                          primary
                          style={{
                            backgroundColor: `#${getWidgetData ? getWidgetData.brandColor : ''}`,
                          }}
                          className="ml-3"
                          onClick={submitFormIfAllKnownValue ? handleSubmit(addEventBtn) : () => setnextpage(1)}
                        >
                          {submitFormIfAllKnownValue ? 'Confirm Booking' : 'Next'}
                        </VmoButton>
                      </div>
                    </>
                  )}
                </VmoCardContent>
              </VmoCard>
            </div>
            <div className="cal-branding">
              <a href="">
                <VmoImage src={vlogo} /> Create your own meeting booking page
              </a>
            </div>
          </VmoContainer>
        </div>
      )}
    </>
  )
}
export default MeetingBook
