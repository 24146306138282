import {apiAction} from './api'
import {PROJECTS_ENDPOINT} from './endpoint'
import {
  CREATE_PROJECTS_CATEGORY,
  DELETE_PROJECTS_CATEGORY,
  GET_PROJECTS_CATEGORIES,
  GET_PROJECTS_SINGLE_CATEGORY,
  UPDATE_PROJECTS_CATEGORY,
} from './types'

export function getProjectsCategories() {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/categories`,
    method: 'GET',
    label: GET_PROJECTS_CATEGORIES,
  })
}

export function createProjectsCategory(data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/categories`,
    method: 'POST',
    label: CREATE_PROJECTS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Created',
  })
}

export function getProjectsSingleCategory(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/categories/${id}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_CATEGORY,
  })
}

export function updateProjectsCategory(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/categories/${id}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Updated',
  })
}

export function deleteProjectsCategory(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/categories/${id}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}
