import React from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormRadio,
  VmoTooltip,
} from 'vmo-library'
import SvgIcon from 'components/common/SvgIcon'
import RenderRules from './UserAudience/RenderRules'

const UserAudience = props => {
  const {style, control, errors, clearErrors, watch, setValue} = props

  const {
    fields: rulesFields,
    append: rulesAppend,
    remove: rulesRemove,
  } = useFieldArray({
    control,
    name: 'rules',
  })

  return (
    <VmoContainer style={style} className="stepWrap">
      <VmoCard fluid>
        <VmoCardContent>
          <h3 className="mb-0">User Audience</h3>
          <p className="mb-4">Set the condition and actions for sending campaigns to particular audience</p>
          <VmoForm>
            <Controller
              name="matchCase"
              control={control}
              render={({value, onChange}) => (
                <VmoFormField error={errors.matchCase && {content: errors.matchCase.message}}>
                  <label>Filters</label>
                  <p className="kooper-lead">
                    Users who match
                    <span style={{color: '#1e90ff', fontWeight: '600'}}> {value === 'and' ? 'ALL' : 'ANY'} </span>
                    of the below conditions
                  </p>
                  <VmoFormGroup inline>
                    <VmoFormRadio
                      value="and"
                      checked={value === 'and'}
                      onChange={(e, {value}) => onChange(value)}
                      label="All"
                      name="matchCaseRadio"
                    />
                    <VmoFormRadio
                      value="or"
                      checked={value === 'or'}
                      onChange={(e, {value}) => onChange(value)}
                      label="Any"
                      name="matchCaseRadio"
                    />
                  </VmoFormGroup>
                </VmoFormField>
              )}
            />
          </VmoForm>
          <div className="userAuu">
            <RenderRules
              rulesFields={rulesFields}
              rulesRemove={rulesRemove}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              watch={watch}
              setValue={setValue}
            />
            {rulesFields.length < 3 && (
              <VmoTooltip
                position="right center"
                content="Add New Condition"
                size="mini"
                trigger={
                  <VmoButton
                    basic
                    icon
                    className="cmpgAddBtn"
                    onClick={() => rulesAppend({operator: 'or', filters: [{key: ''}]})}
                  >
                    <SvgIcon path="common/plus" />
                  </VmoButton>
                }
              />
            )}
          </div>
        </VmoCardContent>
      </VmoCard>
    </VmoContainer>
  )
}

export default UserAudience
