import {apiAction} from './api'
import {CONTACTS_ENDPOINT, CONVERSATIONS_ENDPOINT} from './endpoint'
import {GET_LIVEUSER_CONTACTS, LIVE_COUNT_DETAIL, START_NEW_CONVERSATION, UPDATE_CONTACT_LIST} from './types'

export function getLiveUserContacts(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${CONTACTS_ENDPOINT}?${query}`,
    method: 'GET',
    label: GET_LIVEUSER_CONTACTS,
  })
}

export function getLiveCount() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/liveCount`,
    method: 'GET',
    label: LIVE_COUNT_DETAIL,
  })
}

export function startNewConversation(messageData) {
  return apiAction({
    url: `${CONVERSATIONS_ENDPOINT}/new`,
    method: 'POST',
    label: START_NEW_CONVERSATION,
    tokenIdentity: 'chat',
    data: messageData,
  })
}

export function updateContactList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${CONTACTS_ENDPOINT}?${query}`,
    method: 'GET',
    label: UPDATE_CONTACT_LIST,
    showToast: true,
    successMessage: 'Updated Successfully',
  })
}
