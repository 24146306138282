import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoChipInput,
  VmoForm,
  VmoFormCheckbox,
  VmoFormDropdown,
  VmoFormField,
  VmoInput,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {addSLAPolicy, deleteSLAPolicy, editSLAPolicy, getSLAPolicy} from 'actions/settings_automations'
import {ADD_SLA, DELETE_SLA, EDIT_SLA, GET_SLA} from 'actions/types'
import SettingLayout from 'layouts/settingLayout'
import DeleteModal from 'components/common/DeleteModal'
import {slaSchema} from 'validation/Settings/automations/sla.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const unitList = [
  {key: 'minute', value: 'minute', text: 'Mins'},
  {key: 'hour', value: 'hour', text: 'Hour'},
  {key: 'day', value: 'day', text: 'Day'},
  {key: 'month', value: 'month', text: 'Months'},
]
const defaultValue = {
  targets: [
    {
      priority: 'critical',
      response: {value: 4, unit: 'hour'},
      resolve: {value: 2, unit: 'day'},
    },
    {
      priority: 'high',
      response: {value: 4, unit: 'hour'},
      resolve: {value: 2, unit: 'day'},
    },
    {
      priority: 'medium',
      response: {value: 4, unit: 'hour'},
      resolve: {value: 2, unit: 'day'},
    },
    {
      priority: 'low',
      response: {value: 4, unit: 'hour'},
      resolve: {value: 2, unit: 'day'},
    },
    {
      priority: 'default',
      response: {value: 4, unit: 'hour'},
      resolve: {value: 2, unit: 'day'},
    },
  ],
  isEscalate: false,
  escalateTo: [],
}

const SlaForm = ({toggleModal, setWarningModal}) => {
  const {id: editId} = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(slaSchema),
    defaultValues: defaultValue,
  })

  const watchIsEscalate = watch('isEscalate')
  const watchTargets = watch('targets')

  const {slaGet = {}} = useSelector(state => state.settingAutomation)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const slaManagementAddLearnMore = settingsLearnMoreUrl?.automations?.slaManagement?.addLearnMore
  const slaManagementUpdateLearnMore = settingsLearnMoreUrl?.automations?.slaManagement?.updateLearnMore

  const [deleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState(null)

  const {automationPermissions} = useUserPermissions()
  const manageSlaPermissions = automationPermissions?.manage_sla

  const resetData = () => {
    if (editId) {
      reset(_.omit(slaGet, ['id', 'accountId', 'name', 'description']))
    } else {
      reset(defaultValue)
    }
  }

  const submitSla = data => {
    if (editId) {
      dispatch(editSLAPolicy(editId, data))
      setWarningModal(false)
    } else {
      dispatch(addSLAPolicy(data))
    }
  }

  useEffect(() => {
    if (editId) dispatch(getSLAPolicy())
  }, [dispatch, editId])

  useEffect(() => {
    if (editId) {
      if (isDirty) setWarningModal(true)
    }
    return () => {
      if (editId) setWarningModal(false)
    }
  }, [isDirty, editId, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(ADD_SLA)) {
      toggleModal(false)
      dispatch(getSLAPolicy())
    }
    if (successLabels.includes(GET_SLA)) {
      resetData()
    }
    if (successLabels.includes(EDIT_SLA)) {
      setWarningModal(false)
    }
    if (successLabels.includes(DELETE_SLA)) {
      history.push('/settings/sla-policy')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLabels])

  const unitAndValue = (type, index) => {
    return (
      <Controller
        control={control}
        name={`targets[${index}].${type}.value`}
        render={({onChange, value}) => (
          <VmoFormField
            key={`${index}${type}`}
            style={{justifyContent: 'flex-start'}}
            className="d-flex"
            error={_.get(errors, `targets[${index}][${type}].value`, false)}
          >
            <VmoInput
              style={{width: 80}}
              className="mr-4"
              fluid
              onChange={e => onChange(e.target.value)}
              value={value}
            />
            <Controller
              control={control}
              name={`targets[${index}].${type}.unit`}
              render={({value, onChange}) => (
                <VmoFormDropdown
                  // "basic" prop doesnt work
                  className="slaDropDown"
                  direction="left"
                  compact
                  selection
                  options={unitList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
            />
          </VmoFormField>
        )}
      />
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/sla-management" />}
      header={editId ? 'Edit SLA Policy' : 'Create SLA Policy'}
      headerbackbtn={
        !editId && {
          onClick: () => toggleModal(false),
        }
      }
      subHeader="Define SLA to set expectations for support ticket resolution times"
      learnMoreUrl={editId ? slaManagementUpdateLearnMore : slaManagementAddLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && (type.includes(ADD_SLA) || type.includes(EDIT_SLA)),
          onClick: () => {
            if (editId) {
              resetData()
            } else {
              toggleModal(false)
            }
          },
        },
        success: {
          content: editId ? 'Update' : 'Create',
          loading: isLoadingData && type.includes(ADD_SLA || EDIT_SLA),
          ...(manageSlaPermissions && {
            onClick: handleSubmit(data => submitSla(data)),
          }),
          disabled: isLoadingData && (type.includes(ADD_SLA) || type.includes(EDIT_SLA)),
        },
      }}
      lockRole={!manageSlaPermissions}
    >
      <VmoForm className="errorLabel">
        <div className="info-header">
          <h5 className="mb-0">SLA Targets</h5>
          <VmoTooltip
            trigger={<SvgIcon path="common/question" />}
            content="set achievable SLA targets for different aspects"
            size="mini"
            position="top center"
          />
        </div>
        <VmoCard fluid>
          <VmoTable basic>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                <VmoTableHeaderCell>Priority</VmoTableHeaderCell>
                <VmoTableHeaderCell>Respond within</VmoTableHeaderCell>
                <VmoTableHeaderCell>Resolve within</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {watchTargets.map(({priority}, i) => {
                return (
                  <VmoTableRow key={i}>
                    <VmoTableCell className="pl-4">{(i + 1).toString().padStart(2, '0')}</VmoTableCell>
                    <VmoTableCell>{priority.toUpperCase()}</VmoTableCell>
                    <VmoTableCell style={{width: 320}}>{unitAndValue('response', i)}</VmoTableCell>
                    <VmoTableCell style={{width: 320}}>{unitAndValue('resolve', i)}</VmoTableCell>
                  </VmoTableRow>
                )
              })}
            </VmoTableBody>
          </VmoTable>
        </VmoCard>
        <VmoCard fluid className="switchBox">
          <VmoCardContent>
            <div className="d-flex">
              <div>
                <h5>Escalation Email</h5>
                <p className="kooper-lead">Every update on SLA will be received on the mentioned email</p>
              </div>
              <Controller
                control={control}
                name="isEscalate"
                render={({onChange, value}) => (
                  <VmoFormCheckbox
                    style={{float: 'right'}}
                    toggle
                    checked={value}
                    onChange={(e, {checked}) => onChange(checked)}
                  />
                )}
              />
            </div>
          </VmoCardContent>

          {watchIsEscalate && (
            <VmoCardContent>
              <Controller
                control={control}
                name="escalateTo"
                render={({onChange, value}) => (
                  <VmoChipInput
                    defaultValue={value}
                    onChange={value => onChange(value)}
                    placeholder="Add emails here..."
                  />
                )}
              />
            </VmoCardContent>
          )}
        </VmoCard>
        {editId && (
          <>
            <h4 className="mt-5 mb-0">Want To Delete SLA Management?</h4>
            <p className="kooper-lead">All your settings related to SLA will be deleted </p>
            <VmoButton
              className="basic-red"
              onClick={() => {
                setIdToDelete(editId)
                setDeleteModal(true)
              }}
            >
              Delete SLA Management
            </VmoButton>
          </>
        )}
      </VmoForm>
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteSLAPolicy}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type={DELETE_SLA}
        />
      )}
    </SettingLayout>
  )
}

export default SlaForm
