/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-nested-ternary */
import React, {useState, useRef, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiMonitor, FiXCircle} from 'react-icons/fi'
import {EditorState, Modifier} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import {stateToHTML} from 'draft-js-export-html'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormField,
  VmoGrid,
  VmoGridColumn,
  VmoInput,
  VmoDropdownMenu,
  VmoDropdownItem,
  VmoLabel,
  VmoImage,
  VmoFormGroup,
  VmoDropdown,
  VmoAttachment,
  VmoTooltip,
  VmoList,
  VmoListItem,
  VmoCardHeader,
  VmoFormInput,
  VmoIcon,
} from 'vmo-library'
import {uploadAttachment} from 'actions/campaign'
import {getUsers} from 'actions/settings'
import {extractTextContentFromHTML, startCase} from 'utils/helper'
import wgt1 from 'assets/wgt1.svg'
import SvgIcon from 'components/common/SvgIcon'

const option = [
  {value: 'text', key: 'text', text: 'Text'},
  {value: 'image', key: 'image', text: 'Image'},
  {
    value: 'video',
    key: 'video',
    text: 'Video',
  },
]

const Messages = props => {
  const {style, messageStep, setMessageStep, errors, setError, getValuesForm1} = props
  const dispatch = useDispatch()
  const editorRef = useRef(null)

  const [placeHolder, setPlaceHolder] = useState([false, false, false])
  const [imageValue, setImageValue] = useState('')
  const [videoValue, setVideoValue] = useState('')

  const {getUsersList = {}} = useSelector(state => state.settings)
  const {attachment = {}} = useSelector(state => state.campaign)
  const {successData} = useSelector(state => state.apiReducer)

  const setMessageData = (index, data, type) => {
    return setMessageStep(state =>
      (state || []).map((obj, inx) => {
        if (inx === index) {
          if (type === 'text') {
            return {
              type: 'text',
              // messageText: extractTextContentFromHTML(data),
              messageHtml: data,
            }
          }
          if (type === 'image') {
            return {type: 'image', ...data}
          }
          if (type === 'video') {
            return {type: 'video', ...data}
          }
        } else {
          return obj
        }
      })
    )
  }

  useEffect(() => {
    if (attachment && successData) {
      setMessageData(
        successData.index,
        {
          ...attachment,
          mediaSize: `${attachment.mediaSize}`,
        },
        successData?.type
      )
      dispatch({type: 'CLEAR_CAMPAIGN_ATTACHMENT_UPLOAD'})
    }
  }, [attachment, successData])

  useEffect(() => {
    if (editorRef?.current) {
      editorRef.current.focusEditor()
    }
  }, [])

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const messageAppend = data => {
    return setMessageStep(state => [...state, data])
  }

  const messageReplace = (index, data) => {
    return setMessageStep(state => (state || []).map((obj, inx) => (inx === index ? data : obj)))
  }

  const messageRemove = index => {
    return setMessageStep(state => (state || []).filter((obj, inx) => inx !== index))
  }

  const focusEditor = () => {
    if (editorRef && editorRef.current) {
      editorRef.current.focusEditor()
    }
  }

  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()

    const newContent = Modifier.replaceText(currentContent, currentSelection, text)

    const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
  }

  const handlePlaceholder = (placeholder, index) => {
    setMessageStep(state =>
      state.map((obj, inx) => {
        if (inx === index && obj.type === 'text') {
          return {
            ...obj,
            messageHtml: insertText(` {{${placeholder}}} `, obj.messageHtml),
          }
        }
        return obj
      })
    )
    focusEditor()
  }

  const RenderPlaceHolder = ({index}) => {
    const allPlaceholder = ['firstName', 'lastName', 'fullName', 'companyName']

    return (
      <div className="insertPlaceHolder" key={index}>
        <VmoCard>
          <VmoCardContent style={{padding: '15px'}}>
            <VmoCardHeader>
              Insert Placeholder
              <VmoButton
                floated="right"
                className="p-0"
                onClick={() =>
                  setPlaceHolder(data => {
                    const temp = [...data]
                    temp.splice(index, 1, false)
                    return temp
                  })
                }
              >
                <SvgIcon path="common/close" />
              </VmoButton>
            </VmoCardHeader>
          </VmoCardContent>
          <VmoCardContent>
            <VmoList relaxed link>
              {allPlaceholder.map((placeholder, i) => (
                <VmoListItem key={placeholder + i} as="a" onClick={() => handlePlaceholder(placeholder, index)}>
                  {startCase(placeholder)}
                </VmoListItem>
              ))}
            </VmoList>
          </VmoCardContent>
        </VmoCard>
      </div>
    )
  }

  const checkLink = (value, type) => {
    if (type === 'image') {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(`${value}_*_${img.width}_*_${img.height}`)
        }
        img.onerror = () => {
          reject(`image not found for url ${value}`)
        }
        img.src = value
      }).catch(() =>
        setError('imgUpdate', {
          type: 'manual',
          message: 'image is not found',
        })
      )
    }
    if (type === 'video') {
      return new Promise((resolve, reject) => {
        const video = document.createElement('video')
        video.setAttribute('src', value)
        const regex = /mp4|mkv|flv/g
        if (regex.test(value)) {
          resolve(value)
          setError('vidUpdate', {
            type: 'manual',
            message: '',
          })
        }
        video.onerror = () => {
          reject(`video not found for url ${value}`)
        }
      }).catch(() =>
        setError('vidUpdate', {
          type: 'manual',
          message: 'Video is not found',
        })
      )
    }
  }

  const RenderDropdown = ({index, type}) => {
    return (
      <VmoFormField>
        <label>Block {`0${index + 1}`}</label>
        <VmoDropdown
          selection
          options={option}
          value={type}
          style={{maxWidth: '200px'}}
          onChange={(e, {value}) => {
            if (value === 'text') {
              messageReplace(index, {
                type: 'text',
                messageHtml: EditorState.createEmpty(),
              })
            } else if (value === 'image') {
              messageReplace(index, {
                type: 'image',
                mime: '',
                mediaSize: '',
                mediaUrl: '',
                mediaName: '',
              })
            } else if (value === 'video') {
              messageReplace(index, {
                type: 'video',
                mime: '',
                mediaSize: '',
                mediaUrl: '',
                mediaName: '',
              })
            }
          }}
        />
      </VmoFormField>
    )
  }

  return (
    <div style={style} className="kooper-prev-page">
      <div className="left-part">
        <VmoCard fluid style={{height: '100%'}}>
          <VmoCardContent>
            <h3 className="mb-0">Messages</h3>
            <p className="mb-4">
              You can add text, image and video content as your campaign message. you can add maximum two blocks.
            </p>
            {(messageStep || []).map((obj, index) => {
              return (
                <React.Fragment key={index.toString()}>
                  {obj && obj?.type === 'text' ? (
                    <VmoForm>
                      {RenderDropdown({type: 'text', index})}
                      <VmoFormField>
                        <VmoFormGroup>
                          <VmoFormField width="12" className="cam-msg-editor">
                            <VmoButton
                              icon
                              style={{
                                position: 'absolute',
                                top: '6px',
                                zIndex: 10,
                                right: '20px',
                                padding: '8px',
                                background: 'transparent',
                              }}
                              floated="right"
                              onClick={() =>
                                setPlaceHolder(data => {
                                  const temp = [...data]
                                  temp.splice(index, 1, !temp[index])
                                  return temp
                                })
                              }
                            >
                              {'{ }'}
                            </VmoButton>
                            {placeHolder[index] && <RenderPlaceHolder index={index} />}
                            <Editor
                              ref={editorRef}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              toolbarClassName="toolbar-class"
                              editorState={messageStep && messageStep[index]?.messageHtml}
                              onEditorStateChange={data => {
                                setMessageData(index, data, 'text')
                              }}
                              toolbar={{
                                options: ['inline', 'link', 'emoji'],
                                inline: {
                                  inDropdown: false,
                                  options: ['bold', 'italic', 'underline'],
                                },
                                link: {
                                  defaultTargetOption: '_blank',
                                },
                              }}
                            />
                          </VmoFormField>

                          <VmoFormField width="1">
                            <VmoTooltip
                              position="top center"
                              content="Delete Field"
                              size="mini"
                              trigger={
                                <VmoButton basic icon onClick={() => messageRemove(index)}>
                                  <SvgIcon path="common/delete" />
                                </VmoButton>
                              }
                            />
                          </VmoFormField>
                        </VmoFormGroup>
                      </VmoFormField>
                    </VmoForm>
                  ) : obj && obj?.type === 'image' ? (
                    <VmoForm className="mt-4">
                      {RenderDropdown({type: 'image', index})}
                      <VmoGrid className="mt-4">
                        {obj.mediaUrl && (
                          <>
                            <VmoGridColumn width={1}>
                              <VmoTooltip
                                position="top center"
                                size="mini"
                                content="Remove selected file"
                                trigger={
                                  <VmoButton
                                    icon
                                    basic
                                    onClick={() => {
                                      messageReplace(index, {
                                        type: 'image',
                                        mime: '',
                                        mediaSize: '',
                                        mediaUrl: '',
                                        mediaName: '',
                                      })
                                      setImageValue('')
                                    }}
                                  >
                                    <SvgIcon path="common/close" />
                                  </VmoButton>
                                }
                              />
                            </VmoGridColumn>
                            <VmoGridColumn width={13}>
                              <VmoFormInput disabled value={obj.mediaUrl} />
                            </VmoGridColumn>
                          </>
                        )}
                        {!obj.mediaUrl && (
                          <>
                            <VmoGridColumn width={2}>
                              <VmoAttachment
                                icon
                                basic
                                content={<FiMonitor />}
                                onChange={value => {
                                  const data = {
                                    file: value,
                                  }
                                  if (value.length > 0) {
                                    dispatch(
                                      uploadAttachment(data, {
                                        index,
                                        type: 'image',
                                      })
                                    )
                                  }
                                  value = null
                                }}
                                onClick={e => {
                                  e.target.value = null
                                }}
                                accept="image/*"
                                maxSize={1024}
                                maxHeight={1080}
                                maxWidth={1080}
                              />
                            </VmoGridColumn>
                            <VmoGridColumn width={1} className="mt-2">
                              <span>OR</span>
                            </VmoGridColumn>
                            <VmoGridColumn width={11}>
                              <VmoFormField>
                                <VmoInput
                                  name="imgUpdate"
                                  action={{
                                    color: 'primary',
                                    content: 'Insert',
                                    disabled: !imageValue,
                                    onClick: () => {
                                      checkLink(imageValue, 'image').then(val => {
                                        if (val) {
                                          const data = val.split('_*_')
                                          setMessageData(
                                            index,
                                            {
                                              mime: `image/${data[0].split('.').pop()}`,
                                              mediaUrl: data[0],
                                              mediaName: 'link',
                                              mediaSize: '0',
                                            },
                                            'image'
                                          )
                                        }
                                      })
                                    },
                                  }}
                                  value={imageValue}
                                  onChange={e => setImageValue(e.target.value)}
                                  actionPosition="right"
                                  placeholder="Paste link here and click insert"
                                />
                              </VmoFormField>
                            </VmoGridColumn>
                          </>
                        )}
                        <VmoGridColumn width={1}>
                          <VmoTooltip
                            position="top center"
                            content="Delete Field"
                            size="mini"
                            trigger={
                              <VmoButton icon basic onClick={() => messageRemove(index)}>
                                <SvgIcon path="common/delete" />
                              </VmoButton>
                            }
                          />
                        </VmoGridColumn>
                      </VmoGrid>
                      <p className="kooper-lead mb-0 mt-4">Supported image types: Jpeg, Jpg, Png</p>
                      <p className="kooper-lead mb-0">Recommended resolution: 1080x1080 pixels</p>
                      <p className="kooper-lead">Max file size: 1MB</p>
                    </VmoForm>
                  ) : obj && obj?.type === 'video' ? (
                    <VmoForm className="mt-4">
                      {RenderDropdown({type: 'video', index})}
                      <VmoGrid className="mt-4">
                        {obj.mediaUrl && (
                          <>
                            <VmoGridColumn width={1}>
                              <VmoTooltip
                                position="top center"
                                size="mini"
                                content="Remove selected file"
                                trigger={
                                  <VmoButton
                                    icon
                                    basic
                                    onClick={() => {
                                      messageReplace(index, {
                                        type: 'video',
                                        mime: '',
                                        mediaSize: '',
                                        mediaUrl: '',
                                        mediaName: '',
                                      })
                                      setVideoValue('')
                                    }}
                                  >
                                    <SvgIcon path="common/close" />
                                  </VmoButton>
                                }
                              />
                            </VmoGridColumn>
                            <VmoGridColumn width={13}>
                              <VmoFormInput disabled value={obj.mediaUrl} />
                            </VmoGridColumn>
                          </>
                        )}
                        {!obj.mediaUrl && (
                          <>
                            <VmoGridColumn width={2}>
                              <VmoAttachment
                                icon
                                basic
                                content={<FiMonitor />}
                                onChange={value => {
                                  const data = {
                                    file: value,
                                  }
                                  if (value.length > 0) {
                                    dispatch(
                                      uploadAttachment(data, {
                                        index,
                                        type: 'video',
                                      })
                                    )
                                  }
                                  value = null
                                }}
                                onClick={e => {
                                  e.target.value = null
                                }}
                                accept="video/*"
                                maxSize={20480}
                              />
                            </VmoGridColumn>
                            <VmoGridColumn width={1} className="mt-2">
                              <span>OR</span>
                            </VmoGridColumn>
                            <VmoGridColumn width={11}>
                              <VmoFormField>
                                <VmoInput
                                  action={{
                                    color: 'primary',
                                    content: 'Insert',
                                    disabled: !videoValue,
                                    onClick: () => {
                                      checkLink(videoValue, 'video').then(val => {
                                        if (val) {
                                          const data = val.split('_*_')
                                          setMessageData(
                                            index,
                                            {
                                              mime: `video/${data[0].split('.').pop()}`,
                                              mediaUrl: data[0],
                                              mediaName: 'link',
                                              mediaSize: '0',
                                            },
                                            'video'
                                          )
                                        }
                                      })
                                    },
                                  }}
                                  value={videoValue}
                                  onChange={e => setVideoValue(e.target.value)}
                                  actionPosition="right"
                                  placeholder="Paste link here and click insert"
                                />
                              </VmoFormField>
                              <p
                                style={{
                                  fontSize: '13px',
                                  color: '#df3939',
                                  marginTop: '-18px',
                                }}
                              >
                                {errors?.vidUpdate?.message && errors?.vidUpdate?.message}
                              </p>
                            </VmoGridColumn>
                          </>
                        )}
                        <VmoGridColumn width={1}>
                          <VmoTooltip
                            position="top center"
                            content="Delete Field"
                            size="mini"
                            trigger={
                              <VmoButton icon basic onClick={() => messageRemove(index)}>
                                <SvgIcon path="common/delete" />
                              </VmoButton>
                            }
                          />
                        </VmoGridColumn>
                      </VmoGrid>
                      <p className="kooper-lead mb-0 mt-4">Supported video types: mp3, mp4, wmv, mkv...</p>
                      <p className="kooper-lead">Max file size: 20MB</p>
                    </VmoForm>
                  ) : null}
                </React.Fragment>
              )
            })}
            {
              <p
                style={{
                  fontSize: '13px',
                  color: '#df3939',
                  marginTop: '-10px',
                }}
              >
                {errors?.message?.message && errors?.message?.message}
              </p>
            }
            {messageStep.length < 2 && (
              <VmoFormField className="mt-4">
                <VmoDropdown text="Add Messages" icon="add" button className="icon basic" floating labeled>
                  <VmoDropdownMenu>
                    <VmoDropdownItem
                      text="Text"
                      icon="text height"
                      onClick={() =>
                        messageAppend({
                          type: 'text',
                          // messageText: "",
                          messageHtml: EditorState.createEmpty(),
                        })
                      }
                    />
                    <VmoDropdownItem
                      onClick={() =>
                        messageAppend({
                          type: 'image',
                          mediaUrl: '',
                          mediaName: '',
                          mediaSize: '',
                          mime: '',
                        })
                      }
                    >
                      <VmoIcon name="image" className="mt-0" /> Image
                    </VmoDropdownItem>
                    <VmoDropdownItem
                      text="Video"
                      icon="video"
                      onClick={() =>
                        messageAppend({
                          type: 'video',
                          mediaUrl: '',
                          mediaName: '',
                          mediaSize: '',
                          mime: '',
                        })
                      }
                    />
                  </VmoDropdownMenu>
                </VmoDropdown>
              </VmoFormField>
            )}
          </VmoCardContent>
        </VmoCard>
      </div>
      <div className="right-part">
        <div className="live-Prev">
          <div className="wigCampgPre">
            <VmoLabel>Preview</VmoLabel>
            <div className="campgWig">
              <FiXCircle className="close" />
              <div className="msgBox">
                <div className="user">
                  <div className="ml-0 userImage">
                    {_.find(getUsersList?.users, obj => obj.id === getValuesForm1('userId'))?.firstName[0]}
                  </div>
                  {_.find(getUsersList?.users, obj => obj.id === getValuesForm1('userId'))?.firstName}
                </div>
                {messageStep.map((obj, index) => {
                  if (obj && obj?.type === 'text') {
                    return (
                      <div
                        key={index.toString()}
                        dangerouslySetInnerHTML={{
                          __html:
                            obj?.messageHtml &&
                            obj?.messageHtml?.getCurrentContent() &&
                            extractTextContentFromHTML(stateToHTML(obj?.messageHtml?.getCurrentContent()))?.length > 0
                              ? stateToHTML(obj?.messageHtml?.getCurrentContent())
                              : 'This is Sample Message',
                        }}
                      />
                    )
                  }
                  if (obj && obj?.type === 'image') {
                    return obj.mediaUrl ? <VmoImage key={index.toString()} src={obj.mediaUrl} /> : ''
                  }
                  if (obj && obj?.type === 'video') {
                    return obj.mediaUrl ? <video key={index.toString()} src={obj.mediaUrl} width="100%" /> : ''
                  }
                })}
              </div>
              <div className="msgBox">
                <VmoInput transparent placeholder="click to reply" />
              </div>
              <VmoButton className="wgtBtn active mr-0" primary>
                <VmoImage src={wgt1} />
              </VmoButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages
