import {apiAction} from './api'
import {DOCS, ANALYTICS_ENDPOINT} from './endpoint'
import {
  CONTACT_DEVICES_ANALYTICS,
  CONVERSATION_STATS,
  CONVERSATION_STATUS_ANALYTICS,
  HOMEPAGE_LIVE_COUNT,
  HOME_USERS_ANALYTICS,
  INFLOW_BY_CHANNEL,
  NPS_SCORE_ANALYTICS,
} from './types'

export function inflowByChannel(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/conversations/inflowbychannel`,
    method: 'POST',
    label: INFLOW_BY_CHANNEL,
    data,
  })
}

export function npsRatingsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/surveys/ratingsandnpsscore`,
    method: 'POST',
    label: NPS_SCORE_ANALYTICS,
    data,
  })
}

export function homepageLiveCount() {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/contacts/liveCount`,
    method: 'GET',
    label: HOMEPAGE_LIVE_COUNT,
  })
}

export function homepageDevicesAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/conversations/contactdevices`,
    method: 'POST',
    label: CONTACT_DEVICES_ANALYTICS,
    data,
  })
}

export function conversationStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/conversations/conversationstats`,
    method: 'POST',
    label: CONVERSATION_STATS,
    data,
  })
}

export function conversationStatusAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/conversations/conversationsovertime`,
    method: 'POST',
    label: CONVERSATION_STATUS_ANALYTICS,
    data,
  })
}

export function analyticsHomeUsers(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/conversations/conversationoverusers`,
    method: 'POST',
    label: HOME_USERS_ANALYTICS,
    data,
  })
}
