import React from 'react'
import {
  VmoCheckbox,
  VmoDatePicker,
  VmoFormField,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoFormTextArea,
} from 'vmo-library'

const PreviewQuestions = ({type, question, isRequired, settings, description}) => {
  switch (type) {
    case 'page':
      return (
        <VmoFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </VmoFormField>
      )
    case 'header':
      return (
        <VmoFormField>
          <div>
            <h3>{question}</h3>
          </div>
        </VmoFormField>
      )
    case 'text':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <VmoFormInput type="text" />
        </VmoFormField>
      )
    case 'dropdown':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <VmoFormSelect options={settings?.options?.map(({choice}) => ({key: choice, value: choice, text: choice}))} />
        </VmoFormField>
      )
    case 'number':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <VmoFormInput type="number" />
        </VmoFormField>
      )
    case 'date':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <VmoDatePicker time={false} />
        </VmoFormField>
      )
    case 'checkbox':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          {settings?.options?.map(({choice}) => (
            <VmoCheckbox className="mr-2" label={choice} />
          ))}
        </VmoFormField>
      )
    case 'radio':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          {settings?.options?.map(({choice}) => (
            <VmoFormRadio className="mb-2" label={choice} />
          ))}
        </VmoFormField>
      )
    case 'textarea':
      return (
        <VmoFormField required={isRequired}>
          <div className="info-header">
            <label>{question}</label>
          </div>
          <div className="form-description">
            <p>{description}</p>
          </div>
          <VmoFormTextArea type="text" />
        </VmoFormField>
      )

    default:
      return null
  }
}

export default PreviewQuestions
