import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {FiList} from 'react-icons/fi'
import _ from 'lodash'
import axios from 'axios'
import {
  VmoModal,
  VmoModalHeader,
  VmoModalActions,
  VmoInput,
  VmoModalContent,
  VmoButtonGroup,
  VmoButton,
  VmoDropdown,
  VmoSelect,
  VmoAttachment,
  VmoTooltip,
  VmoDropdownItem,
  VmoDropdownDivider,
  VmoDropdownMenu,
  VmoDropdownHeader,
  VmoTextArea,
  VmoForm,
} from 'vmo-library'

import {SEND_MAIL} from 'actions/types'
import {uploadAttachments} from 'actions/attachments'
import {getAllMailInbox, getMailBox, getUserTemplates, sendEmail, singleUserTemplates} from 'actions/inbox'
import {extractTextContentFromHTML, get} from 'utils/helper'
import AttachmentPreview from 'components/common/AttachmentPreview'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'

const MailLeadModal = props => {
  const {isModalOpen, setIsModalOpen, checked} = props
  const dispatch = useDispatch()
  const [listOfInbox, setListOfInbox] = useState([])
  const [emailTo, setEmailTo] = useState([])
  const [files, setFiles] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [emailEditorCurrentValue, setEmailEditorCurrentValue] = useState('')

  const {CancelToken} = axios
  const requestToken = CancelToken.source()
  const [data, setData] = useState({
    sendLaterTime: new Date(),
    attachments: [],
    from: '',
    to: [],
    sendTime: new Date(),
    subject: '',
  })
  const {getAllContactsList = []} = useSelector(state => state.contacts)

  const {inbox: mailboxes} = useSelector(state => state.mailInbox)
  const {getUserTemplates: userTemplates = []} = useSelector(state => state.emailInbox)
  const {successLabels = [], type, isLoadingData} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(SEND_MAIL)) {
      setIsModalOpen(!isModalOpen)
    }
  }, [successLabels])

  useEffect(() => {
    if (mailboxes) {
      const mailBoxList = mailboxes
        .filter(ls => ls.type === INBOX_TYPES.EMAIL && ls.emailVerified)
        .map(mail => {
          return {
            value: mail.id,
            key: mail.id,
            content: (
              <div>
                {get(['name'], mail, '')}
                <small className="ml-2 kooper-lead">({get(['email'], mail, '')})</small>
              </div>
            ),
            text: `${get(['name'], mail, '')}`,
          }
        })

      setListOfInbox(mailBoxList)
    }
  }, [mailboxes])

  useEffect(() => {
    const tempEmail = getAllContactsList
      .filter(list => checked.includes(list.id) && list.emails !== null)
      .map(contact => {
        return {
          value: {contactId: contact.id, email: contact.emails[0].email},
          key: contact.id,
          text: contact.emails[0].email,
        }
      })
    setEmailTo(tempEmail)
  }, [checked])

  useEffect(() => {
    dispatch(getAllMailInbox())
  }, [dispatch])

  const sendMail = (draft, conversationId, isSendLaterActive) => {
    const messageBody = emailEditorCurrentValue

    const messageText = extractTextContentFromHTML(messageBody)

    const updatedSubject = data.subject === '' ? '(no subject)' : data.subject

    const payload = {
      mailboxId: data.from,
      subject: updatedSubject,
      to: data.to,
      cc: [],
      bcc: [],
      isDraft: draft,
      messageHtml: messageBody,
      messageText,
      type: 'email',
    }

    if (files.length > 0) {
      payload.attachments = uploadedFiles.map(file => file.id)
    }
    if (isSendLaterActive) {
      payload.sendLater = true
      payload.sendTime = data.sendLaterTime
    }

    dispatch(sendEmail(payload))
  }
  const searchFromTemplateList = searchString => {
    const updatedList = templateList.filter(template => template.subject.includes(searchString))
    setTemplateList(updatedList)
    if (searchString === '') {
      setTemplateList(userTemplates)
    }
  }
  const handleSendMail = () => {
    if (data.from === '' || _.isEqual(data.to, []) || emailEditorCurrentValue === '') {
      return true
    }
    return false
  }

  return (
    <VmoModal
      className="kooper-modal"
      open={isModalOpen}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Compose Bulk Email</VmoModalHeader>
      <VmoModalContent className="composeEmail">
        <div className="emailInput">
          <ul>
            <li>
              <div className="emlEdrHdr">From :</div>
              <div className="mailActivity">
                <VmoDropdown
                  direction="right"
                  button
                  icon="chevron down"
                  placeholder="Select Inbox"
                  options={listOfInbox}
                  onChange={(e, value) => {
                    dispatch(getMailBox(value.value))

                    const newData = {...data, from: value.value}

                    setData(newData)
                  }}
                  value={data.from}
                />
              </div>
            </li>

            <li>
              <div className="emlEdrHdr">To :</div>
              <div>
                <VmoSelect
                  multiple
                  options={emailTo}
                  value={data.to}
                  onChange={(e, {value}) => setData({...data, to: value})}
                />
              </div>
            </li>

            <li>
              <div className="emlEdrHdr">Subject :</div>
              <VmoInput
                placeholder="Enter Subject"
                onChange={e => setData({...data, subject: e.target.value})}
                value={data.subject}
              />
            </li>
          </ul>
        </div>

        <VmoForm style={{padding: 12}}>
          <VmoTextArea
            maxLength={200}
            placeholder="type eWmail here..."
            onChange={e => setEmailEditorCurrentValue(e.target.value)}
          />
        </VmoForm>
        <AttachmentPreview
          files={files}
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          success="composeAttachment"
          cancelToken={requestToken}
        />
      </VmoModalContent>
      <VmoModalActions className="replyMailFooterBtn">
        <VmoButtonGroup className="replyMailFooterBtnLeft">
          <VmoTooltip
            content="Attachment"
            size="mini"
            position="top center"
            trigger={
              <VmoAttachment
                content={<SvgIcon path="common/attachment" />}
                multiple
                onChange={e => {
                  const data = {
                    file: e,
                  }
                  if (e.length > 0) {
                    dispatch(uploadAttachments(data, 'composeAttachment', requestToken))
                  }
                }}
                onClick={e => {
                  e.target.value = null
                }}
                fileList={files => {
                  setFiles(files)
                }}
              />
            }
          />
          <VmoTooltip
            content="Email templates"
            size="mini"
            position="top center"
            trigger={
              <VmoDropdown
                button
                direction="left"
                floating
                className="icon"
                onClick={() => dispatch(getUserTemplates())}
                closeOnChange={false}
                icon={<FiList />}
              >
                <VmoDropdownMenu>
                  <VmoDropdownHeader>Insert Email Templates</VmoDropdownHeader>
                  <VmoDropdownDivider />
                  <VmoInput
                    icon="search"
                    iconPosition="left"
                    type="search"
                    name="search"
                    id="search"
                    placeholder="Search email templates... "
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onChange={e => {
                      searchFromTemplateList(e.target.value)
                    }}
                  />
                  <VmoDropdownDivider />
                  {templateList.map(temp => (
                    <VmoDropdownItem
                      key={temp.id}
                      onClick={() => {
                        dispatch(singleUserTemplates(get(['id'], temp, '-'), null, 'templateListCompose'))
                      }}
                    >
                      {get(['subject'], temp, '-')}
                    </VmoDropdownItem>
                  ))}
                </VmoDropdownMenu>
              </VmoDropdown>
            }
          />

          {/* <VmoButton color="light">
            <FiFilePlus />
          </VmoButton> */}
        </VmoButtonGroup>
        <div>
          <VmoButton basic color="light" onClick={() => setIsModalOpen(false)} className="mr-3">
            Cancel
          </VmoButton>
          <VmoButton
            primary
            loading={isLoadingData && type.includes(SEND_MAIL)}
            disabled={handleSendMail() || (isLoadingData && type.includes(SEND_MAIL))}
            onClick={() => {
              sendMail(false, null, false)
            }}
          >
            Send
          </VmoButton>
        </div>
      </VmoModalActions>
    </VmoModal>
  )
}

export default MailLeadModal
