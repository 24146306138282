import React from 'react'
import {Controller} from 'react-hook-form'
import {BiConversation} from 'react-icons/bi'
import {
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoTabPane,
} from 'vmo-library'

const PrefrencesPane = ({control, errors, isActive, pauseTimerIdle}) => {
  return (
    <VmoTabPane>
      <VmoCard fluid className="switchBox">
        <VmoCardContent>
          <div className="d-flex">
            <div className="d-flex" style={{justifyContent: 'flex-start'}}>
              <div className="checkbox-card-image">
                <BiConversation />
              </div>
              <div>
                <h5>Conversation Tracking</h5>
                <p className="kooper-lead">Automatiacilly logs the time spent on tickets</p>
              </div>
            </div>
            <Controller
              control={control}
              name="isTimeTracking"
              render={({value, onChange}) => (
                <div className="d-flex">
                  <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                </div>
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <VmoForm>
            <VmoFormField disabled={!isActive}>
              <label>Tracking Type</label>
              <p className="kooper-lead">Select the type of tracking for your agents and admins</p>
              <Controller
                control={control}
                name="isAutoForConversation"
                render={({value, onChange}) => (
                  <VmoFormGroup>
                    <VmoFormRadio
                      value
                      label="Automatic"
                      checked={value === true}
                      onChange={(e, {value}) => onChange(value)}
                      className="mr-4"
                    />
                    <VmoFormRadio
                      label="Manual"
                      value={false}
                      checked={value === false}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  </VmoFormGroup>
                )}
              />
            </VmoFormField>
            <VmoFormField disabled={!isActive}>
              <label>Specify actions to be tracked</label>
              <p className="kooper-lead">Select your custom specific options which you wish to track</p>
              <VmoFormField className="mb-2" disabled={!isActive}>
                <Controller
                  control={control}
                  name="onConversation"
                  render={({value, onChange}) => (
                    <VmoCheckbox label="Reply" toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </VmoFormField>
              <VmoFormField disabled={!isActive}>
                <Controller
                  control={control}
                  name="onDraftAndReply"
                  render={({value, onChange}) => (
                    <VmoCheckbox
                      label="Compose Draft"
                      toggle
                      checked={value}
                      onChange={(e, {checked}) => onChange(checked)}
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormField>
            <VmoFormField disabled={!isActive}>
              <label>Time Pause Limit</label>
              <p className="kooper-lead">Select the type of tracking for your agents and admins</p>
              <VmoFormField disabled={!isActive}>
                <Controller
                  control={control}
                  name="pauseTimerIdle"
                  render={({value, onChange}) => (
                    <VmoFormGroup>
                      <VmoFormRadio
                        value
                        label="Default"
                        checked={value === true}
                        onChange={(e, {value}) => onChange(value)}
                        className="mr-4"
                      />
                      <VmoFormRadio
                        label="Manual"
                        value={false}
                        checked={value === false}
                        onChange={(e, {value}) => onChange(value)}
                      />
                    </VmoFormGroup>
                  )}
                />
              </VmoFormField>
              <p className="kooper-lead">Default Time Pause Limit is 30s.</p>
              {!pauseTimerIdle && (
                <Controller
                  name="timerPauseLimit"
                  control={control}
                  render={({value, onChange, onBlur}) => (
                    <VmoFormInput
                      width={2}
                      type="text"
                      placeholder="50 seconds"
                      value={value}
                      onBlur={onBlur}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.timerPauseLimit && {
                          content: errors.timerPauseLimit.message,
                        }
                      }
                    />
                  )}
                />
              )}
            </VmoFormField>
          </VmoForm>
        </VmoCardContent>
        <VmoCardContent>
          <h4 className="mb-0">Activity</h4>
          <p className="kooper-lead">Tick the checkboxes for which you wish to manually track time</p>
          <VmoDivider />
          <VmoForm>
            <VmoFormField disabled={!isActive}>
              <label>Manually Track time for</label>
              <p className="kooper-lead">Tick the checkboxes for which you wish to manually track time</p>
              <VmoFormGroup className="mb-0">
                <VmoFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="call"
                    render={({value, onChange}) => (
                      <VmoCheckbox label="Call" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </VmoFormField>
                <VmoFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="meeting"
                    render={({value, onChange}) => (
                      <VmoCheckbox label="Meeting" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </VmoFormField>
                <VmoFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="task"
                    render={({value, onChange}) => (
                      <VmoCheckbox label="Task" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </VmoFormField>
                <VmoFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="event"
                    render={({value, onChange}) => (
                      <VmoCheckbox label="Event" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </VmoFormField>
                <VmoFormField disabled={!isActive}>
                  <Controller
                    control={control}
                    name="milestone"
                    render={({value, onChange}) => (
                      <VmoCheckbox label="Milestone" checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </VmoFormField>
              </VmoFormGroup>
            </VmoFormField>
            <VmoFormField disabled={!isActive}>
              <div className="d-flex">
                <div>
                  <label>Automatic time tracking for activities</label>
                  <p className="mt-2 mb-0 kooper-lead">Run the timer for one activity at a time</p>
                </div>
                <Controller
                  control={control}
                  name="oneActivityAtTime"
                  render={({value, onChange}) => (
                    <VmoCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                  )}
                />
              </div>
            </VmoFormField>
          </VmoForm>
        </VmoCardContent>
      </VmoCard>
    </VmoTabPane>
  )
}

export default PrefrencesPane
