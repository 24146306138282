import {SETTINGS_ENDPOINT, KOOPER_ATTACHMENTS} from './endpoint'
import {ATTACHMENT_POST} from './types'
import {apiAction} from './api'

export function uploadAttachments(data, successData, cancelToken) {
  return apiAction({
    url: KOOPER_ATTACHMENTS,
    method: 'POST',
    label: ATTACHMENT_POST,
    data,

    contentType: 'multipart/form-data',
    successData,
    cancelToken,
  })
}

export function uploadTemplateAttachments(data, successData) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/automation/templates/attachments`,
    method: 'POST',
    label: ATTACHMENT_POST,
    data,

    contentType: 'multipart/form-data',
    successData,
  })
}
