import React, {useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import moment from 'moment'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {
  CREATE_PROJECTS_TIMESHEET_TIME_LOG,
  GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG,
  UPDATE_PROJECTS_TIMESHEET_TIME_LOG,
} from 'actions/types'
import {
  createProjectsTimesheetTimeLog,
  getProjectsTimesheetSingleTimeLog,
  updateProjectsTimesheetTimeLog,
} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import _ from 'lodash'
import {timeDefaultValues, createTimeSchema, updateTimeSchema} from 'validation/Projects/projects/time.schema'
import {timeStatusList} from 'constants/projects'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const TimeModal = ({open, toggle, projectId, timesheetId, timeId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {tasksList = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, watch, reset, setValue} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(timeId ? updateTimeSchema : createTimeSchema),
    defaultValues: timeDefaultValues,
  })
  const watchTasklistId = watch('tasklistId')

  useApiResponse({
    action: getProjectsTimesheetSingleTimeLog,
    payload: {projectId, timesheetId, timeId},
    dependency: [projectId, timesheetId, timeId],
    enabled: projectId && timesheetId && timeId,
    label: GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG,
    storePath: 'projects.timeLogData',
    onSuccess: (timeLogData = {}) => {
      const data = _.pick(timeLogData, [
        'date',
        'status',
        'loggedHrs',
        'loggedMins',
        'description',
        'isAddToTask',
        'tasklistId',
        'taskId',
      ])
      reset(data)
    },
  })

  useEffect(() => {
    if (watchTasklistId) {
      setValue('taskId', null)
    }
  }, [watchTasklistId, setValue])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET_TIME_LOG)
    ) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const getTaskOptions = useCallback(() => {
    if (watchTasklistId) {
      const tasks = tasksList.find(tasklist => tasklist.id === watchTasklistId)?.tasks
      if (tasks) {
        return tasks.map(({id, title}) => ({key: id, value: id, text: title}))
      }
      return []
    }
    return []
  }, [tasksList, watchTasklistId])

  const submitForm = data => {
    const payload = {...data, date: moment(data.date).format('YYYY-MM-DD')}
    if (timeId) {
      dispatch(updateProjectsTimesheetTimeLog({projectId, timesheetId, timeId, data: payload}))
    } else {
      const newPayload = {...payload, timesheetId}
      dispatch(createProjectsTimesheetTimeLog({projectId, data: newPayload}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{`${timeId ? 'Edit' : 'Add'} Time`}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormGroup>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Date</label>
                <VmoTooltip
                  content="Set start date"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <Controller
                name="date"
                control={control}
                render={({value, onChange}) => (
                  <VmoDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => onChange(value)}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Status</label>
                <VmoTooltip
                  content="Set whether the total logged time is billable or non-billable"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <Controller
                name="status"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    value={value}
                    options={timeStatusList}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.status && {content: removeDoubleQuotes(errors.status.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoFormGroup>
          <VmoFormGroup>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Hours</label>
                <VmoTooltip
                  content="Specify total logged time in terms of hours"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <Controller
                name="loggedHrs"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    type="number"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.loggedHrs && {content: removeDoubleQuotes(errors.loggedHrs.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Minutes</label>
                <VmoTooltip
                  content="Specify total logged time in terms of minutes"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
              <Controller
                name="loggedMins"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    type="number"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.loggedMins && {content: removeDoubleQuotes(errors.loggedMins.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoFormGroup>
          <VmoFormField required>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                content="Specify a brief description"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
            </div>
            <Controller
              name="description"
              control={control}
              render={({value, onChange}) => (
                <VmoFormTextArea
                  maxLength={200}
                  style={{resize: 'none'}}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                />
              )}
            />
          </VmoFormField>

          {!timeId && (
            <>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Add time details to the task</label>
                    <p className="kooper-lead">Assign task with the time details</p>
                  </div>
                  <Controller
                    name="isAddToTask"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                    )}
                  />
                </div>
              </VmoFormField>
              {watch('isAddToTask') && (
                <>
                  <VmoFormField>
                    <label>Tasklist</label>
                    <Controller
                      name="tasklistId"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormSelect
                          placeholder="Select"
                          options={tasksList.map(({id, title}) => ({key: id, value: id, text: title}))}
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.tasklistId && {content: removeDoubleQuotes(errors.tasklistId.message)}}
                        />
                      )}
                    />
                  </VmoFormField>

                  {watch('tasklistId') && (
                    <VmoFormField required>
                      <label>Task</label>
                      <Controller
                        name="taskId"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoFormSelect
                            placeholder="Select"
                            options={getTaskOptions()}
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={errors.taskId && {content: removeDoubleQuotes(errors.taskId.message)}}
                          />
                        )}
                      />
                    </VmoFormField>
                  )}
                </>
              )}
            </>
          )}
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {timeId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TimeModal
