import {
  CALCULATE_BILLING,
  CURRENT_SUBSCRIPTION,
  GET_BILLING_ADDRESS,
  GET_KOOPER_PLANS,
  GET_PAYMENT_METHOD,
  GET_SINGLE_INVOICE,
  INVOICE_LISTING,
  REFRESH_ACCESS_TOKEN,
} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscriptionData: payload,
      }

    case GET_KOOPER_PLANS:
      return {
        ...state,
        getKooperPlansData: payload,
      }

    case GET_PAYMENT_METHOD:
      return {...state, getPaymentMethodsData: payload}

    case GET_BILLING_ADDRESS:
      return {...state, getBillingAddressData: payload}

    case CALCULATE_BILLING:
      return {...state, calculateBillingData: payload}

    case INVOICE_LISTING:
      return {
        ...state,
        getListOfInvoiceData: payload,
      }

    case GET_SINGLE_INVOICE:
      return {
        ...state,
        getSingleInvoiceDetailsData: payload,
      }

    case REFRESH_ACCESS_TOKEN: {
      return {...state, refreshAccessTokenData: payload}
    }

    default:
      return {...state}
  }
}
