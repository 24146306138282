/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoButtonGroup,
  VmoCheckbox,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoInput,
  VmoTooltip,
} from 'vmo-library'
import {ADD_SMS_CHANNEL, DELETE_SMS, GET_PHONE_NUMBER, GET_SMS_SINGLE, GET_USERS, UPDATE_SMS} from 'actions/types'
import {
  addSmsChannel,
  deleteSMS,
  getPhoneNumberSMS,
  getSharedSecretKey,
  getSMSList,
  updateSMS,
} from 'actions/settings_channels'
import {getUsers} from 'actions/settings'
import {getSMSSingle} from 'actions/inbox'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes} from 'utils/helper'
import {smsSchema} from 'validation/Settings/channels/sms.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const SmsDetail = props => {
  const {setCreateSms, match, warningModal, setWarningModal} = props
  const {inboxId} = match.params

  const dispatch = useDispatch()
  const history = useHistory()

  const [userList, setUsersList] = useState([])
  const [showAuthKey, setShowAuthKey] = useState(false)
  const [showToken, setShowToken] = useState(false)
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [deleteId, setDeleteId] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {getPhoneNumberData = [], getSmsChannel = {}, getSharedKeyData} = useSelector(state => state.settingsChannels)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const smsAddLearnMore = settingsLearnMoreUrl?.channels?.sms?.addLearnMore
  const smsUpdateLearnMore = settingsLearnMoreUrl?.channels?.sms?.updateLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageSmsPermissions = channelsPermissions?.manage_sms

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
    formState: {isDirty},
    setValue,
    reset,
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(smsSchema),
  })
  const watchedValue = watch(['authToken', 'accountSID', 'provider', 'threadingInterval', 'phoneNumber'])

  useEffect(() => {
    if (inboxId) {
      dispatch(getSMSSingle(inboxId))
    }
  }, [inboxId, dispatch])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (successLabels.includes(GET_PHONE_NUMBER)) {
      const phoneList = getPhoneNumberData.map(phone => ({
        value: phone.phoneNumber,
        key: phone.sid,
        text: phone.phoneNumber,
      }))
      setPhoneNumbers(phoneList)
    }
    if (successLabels.includes(GET_SMS_SINGLE)) {
      const data = _.pick(getSmsChannel, [
        'sid',
        'name',
        'phoneNumber',
        'authToken',
        'accountSID',
        'provider',
        'webhookUrl',
        'threadingInterval',
        'autoResolvedDays',
      ])
      data.assigneeIds = getSmsChannel?.assigneeids
      reset(data)
    }
  }, [successLabels, getPhoneNumberData, getSmsChannel, reset])

  useEffect(() => {
    if (watchedValue.authToken && watchedValue.accountSID && watchedValue.provider === 'twilio') {
      const data = {
        provider: watchedValue.provider,
        accountSID: watchedValue.accountSID,
        authToken: watchedValue.authToken,
      }
      dispatch(getPhoneNumberSMS(data))
    }
  }, [watchedValue.authToken, watchedValue.accountSID, watchedValue.provider, dispatch])

  useEffect(() => {
    if (watchedValue.provider === 'custom_sms') {
      dispatch(getSharedSecretKey())
    }
  }, [watchedValue.provider, dispatch])

  useEffect(() => {
    if (getSharedKeyData && watchedValue.provider === 'custom_sms') {
      setValue('authToken', getSharedKeyData)
    }
  }, [getSharedKeyData, watchedValue.provider, setValue])

  useEffect(() => {
    if (successLabels.includes(UPDATE_SMS) || successLabels.includes(DELETE_SMS)) {
      history.push(`/settings/sms`)
      dispatch(getSMSList())
      if (setCreateSms) setCreateSms(false)
    }
    if (successLabels.includes(ADD_SMS_CHANNEL)) {
      dispatch(getSMSList())
      if (setCreateSms) setCreateSms(false)
    }
  }, [successLabels, dispatch, history, setCreateSms])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(isDirty)
    }
    return () => {
      setWarningModal(false)
    }
  }, [isDirty, setWarningModal])

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.onpopstate = () => {
      if (setCreateSms) setCreateSms(false)
      history.push(`/settings/sms`)
    }
    return () => (window.onpopstate = () => {})
  }, [history, setCreateSms])

  if (watchedValue.phoneNumber) {
    if (watchedValue.provider === 'twilio') {
      setValue('sid', phoneNumbers.find(phone => phone.value === watchedValue.phoneNumber)?.key)
    }
  }

  return (
    <SettingLayout
      header="SMS"
      subHeader="Setup your SMS channel by mentioning all the required informations"
      learnMoreUrl={inboxId ? smsUpdateLearnMore : smsAddLearnMore}
      headerbackbtn={{
        onClick: () => {
          if (setCreateSms) setCreateSms(false)
          if (!warningModal) {
            setWarningModal(false)
            history.push('/settings/sms')
            if (inboxId) {
              reset(
                _.pick(getSmsChannel, [
                  'sid',
                  'assigneeIds',
                  'name',
                  'phoneNumber',
                  'authToken',
                  'accountSID',
                  'provider',
                  'webhookUrl',
                  'threadingInterval',
                ])
              )
            } else {
              setCreateSms(false)
            }
          } else if (warningModal) {
            history.push('/settings/sms')
          }
        },
      }}
      headerDivider
      table={false}
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && (type.includes(ADD_SMS_CHANNEL) || type.includes(UPDATE_SMS)),
          onClick: () => {
            if (setCreateSms) setCreateSms(false)
            if (!warningModal) {
              setWarningModal(false)
              history.push('/settings/sms')
              if (inboxId) {
                reset(
                  _.pick(getSmsChannel, [
                    'sid',
                    'assigneeIds',
                    'name',
                    'phoneNumber',
                    'authToken',
                    'accountSID',
                    'provider',
                    'webhookUrl',
                    'threadingInterval',
                  ])
                )
              } else {
                setCreateSms(false)
              }
            } else if (warningModal) {
              history.push('/settings/sms')
            }
          },
        },
        success: {
          content: inboxId ? 'Update' : 'Create',
          loading: isLoadingData && (type.includes(ADD_SMS_CHANNEL) || type.includes(UPDATE_SMS)),
          disabled: !isDirty || (isLoadingData && (type.includes(ADD_SMS_CHANNEL) || type.includes(UPDATE_SMS))),
          ...(manageSmsPermissions && {
            onClick: handleSubmit(data => {
              setWarningModal(false)
              const payload = {...data}
              if (watchedValue.provider === 'twilio') {
                payload.sid = phoneNumbers.find(phone => phone.value === data.phoneNumber).key
              }
              if (inboxId) {
                dispatch(updateSMS(inboxId, _.omit(payload, ['phoneNumber', 'authToken', 'accountSID', 'sid'])))
              } else dispatch(addSmsChannel(payload))
            }),
          }),
        },
      }}
      lockRole={!manageSmsPermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormGroup widths="equal">
          <VmoFormField required>
            <div className="info-header">
              <label>Inbox Name</label>
              <VmoTooltip
                content="Select the Inbox in which you want to receive SMS tickets"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              control={control}
              name="name"
              render={({onChange, value}) => (
                <VmoFormInput
                  maxLength={20}
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.name && {
                      content: removeDoubleQuotes(errors.name.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField required>
            <div className="info-header">
              <label>Users</label>
              <VmoTooltip
                content="Select agents which you want to assign to handle SMS tickets"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              control={control}
              name="assigneeIds"
              render={({onChange, value}) => (
                <VmoFormSelect
                  options={userList}
                  value={value}
                  multiple
                  selection
                  fluid
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assigneeIds && {
                      content: removeDoubleQuotes(errors.assigneeIds.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormGroup>
          <VmoFormField required width={8}>
            <div className="info-header">
              <label>SMS Provider</label>
              <VmoTooltip
                content="Select a SMS provider for channel setup"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              control={control}
              name="provider"
              render={({onChange, value}) => (
                <VmoFormSelect
                  options={[
                    {
                      value: 'custom_sms',
                      text: 'Custom',
                      key: 'custom',
                    },
                    {
                      value: 'twilio',
                      text: 'Twilio',
                      key: 'twilio',
                    },
                  ]}
                  disabled={!!inboxId}
                  value={value}
                  selection
                  fluid
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.provider && {
                      content: removeDoubleQuotes(errors.provider.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          {watchedValue.provider === 'custom_sms' && (
            <VmoFormField required width={8}>
              <div className="info-header">
                <label>Webhook URL</label>
                <VmoTooltip
                  content="Enter the webhook URL of your custom SMS channel"
                  size="mini"
                  position="top center"
                  trigger={<SvgIcon path="common/question" />}
                />
              </div>
              <Controller
                control={control}
                name="webhookUrl"
                render={({onChange, value}) => (
                  <VmoFormInput
                    value={value}
                    maxLength={200}
                    onChange={e => onChange(e.target.value)}
                    error={
                      errors.webhookUrl && {
                        content: removeDoubleQuotes(errors.webhookUrl.message),
                      }
                    }
                  />
                )}
              />
            </VmoFormField>
          )}
        </VmoFormGroup>
        {watchedValue.provider === 'twilio' && (
          <>
            <VmoFormGroup>
              <VmoFormField width={8} required>
                <div className="info-header">
                  <label>Authentication Key</label>
                  <VmoTooltip
                    content="Enter the Authentication Key provided by your SMS provider"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  control={control}
                  name="accountSID"
                  render={({onChange, value}) => (
                    <VmoFormInput
                      maxLength={200}
                      type={showAuthKey ? 'text' : 'password'}
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.accountSID && {
                          content: removeDoubleQuotes(errors.accountSID.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormField>
              <VmoCheckbox
                checked={showAuthKey}
                label="Show Auth key"
                onChange={(e, {checked}) => setShowAuthKey(checked)}
              />
            </VmoFormField>
          </>
        )}
        <VmoFormGroup>
          <VmoFormField required width={8}>
            <div className="info-header">
              <label>Auth Token</label>
              <VmoTooltip
                content="Enter the Auth Token provided by your SMS provider"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              control={control}
              name="authToken"
              render={({onChange, value}) => (
                <VmoFormInput
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.authToken && {
                      content: removeDoubleQuotes(errors.authToken.message),
                    }
                  }
                  type={showToken ? 'text' : 'password'}
                  disabled={watchedValue.provider === 'custom_sms'}
                />
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormField width={8}>
          <VmoCheckbox checked={showToken} onChange={(e, {checked}) => setShowToken(checked)} label="Show token" />
        </VmoFormField>
        <VmoFormGroup>
          <VmoFormField required width={8}>
            <div className="info-header">
              <label>Phone Number</label>
              <VmoTooltip
                content="Select the phone number for your SMS channel"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </div>
            <Controller
              control={control}
              name="phoneNumber"
              render={({onChange, value}) => (
                <>
                  {watchedValue.provider === 'twilio' ? (
                    <VmoFormSelect
                      disabled={!!inboxId}
                      options={phoneNumbers}
                      value={value}
                      selection
                      fluid
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.phoneNumber && {
                          content: removeDoubleQuotes(errors.phoneNumber.message),
                        }
                      }
                    />
                  ) : (
                    <VmoFormInput
                      maxLength={20}
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.phoneNumber && {
                          content: removeDoubleQuotes(errors.phoneNumber.message),
                        }
                      }
                    />
                  )}
                </>
              )}
            />
          </VmoFormField>
        </VmoFormGroup>
      </VmoForm>

      {inboxId && (
        <>
          <h5 className="mt-5 mb-2">SMS Settings</h5>
          <VmoForm>
            <VmoFormField>
              <label>Auto-resolve stale conversations</label>
              <p className="kooper-lead">
                Specify the duration of time after which the conversation should be marked as auto resolved.
              </p>
              <VmoFormField width={3}>
                <Controller
                  control={control}
                  name="autoResolvedDays"
                  render={({onChange, value}) => (
                    <VmoInput
                      label={{basic: true, content: 'days'}}
                      labelPosition="right"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.autoResolvedDays && {
                          content: removeDoubleQuotes(errors.autoResolvedDays.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <p className="kooper-lead">Make it 0 to disable</p>
            </VmoFormField>
            <VmoFormField>
              <label>Set Ticket Threading Interval</label>
              <p className="kooper-lead">
                Select the ticket threading time from below options, For the selected days , all the different tickets
                from the same customer will be grouped in one conversation <a href="">Learn More</a>
              </p>
              <Controller name="threadingInterval" control={control} render={null} />
              <VmoButtonGroup basic onClick={() => clearErrors('threadingInterval')}>
                <VmoButton
                  onClick={() => setValue('threadingInterval', 0, {shouldDirty: true})}
                  active={watchedValue.threadingInterval === 0}
                >
                  Never
                </VmoButton>
                <VmoButton
                  onClick={() => setValue('threadingInterval', 1, {shouldDirty: true})}
                  active={watchedValue.threadingInterval === 1}
                >
                  01 Day
                </VmoButton>
                <VmoButton
                  onClick={() => setValue('threadingInterval', 7, {shouldDirty: true})}
                  active={watchedValue.threadingInterval === 7}
                >
                  01 Week
                </VmoButton>
                <VmoButton
                  onClick={() => setValue('threadingInterval', 30, {shouldDirty: true})}
                  active={watchedValue.threadingInterval === 30}
                >
                  01 Month
                </VmoButton>
              </VmoButtonGroup>
              {errors.threadingInterval && (
                <p className="errorLabelError">{removeDoubleQuotes(errors.threadingInterval.message)}</p>
              )}
            </VmoFormField>
            <VmoFormField>
              <label>Delete Channel</label>
              <p className="kooper-lead">Delete your SMS channel permanently</p>
              <VmoButton
                negative
                basic
                onClick={() => {
                  setDeleteId(getSmsChannel.id)
                  setDeleteModal(true)
                }}
              >
                Delete
              </VmoButton>
            </VmoFormField>
          </VmoForm>
        </>
      )}
      {deleteModal && (
        <DeleteModal
          idTobeDeleted={deleteId}
          deleteAction={deleteSMS}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type={DELETE_SMS}
        />
      )}
    </SettingLayout>
  )
}

export default SmsDetail
