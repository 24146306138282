import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router'
import {BsPin} from 'react-icons/bs'
import {FiBookmark, FiChevronRight} from 'react-icons/fi'
import {MdOutlineMoreVert} from 'react-icons/md'
import _ from 'lodash'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoMenu,
  VmoMenuItem,
  VmoTooltip,
} from 'vmo-library'

import {
  COPY_PROJECTS_NOTEBOOK,
  CREATE_PROJECTS_NOTEBOOK,
  DELETE_PROJECTS_NOTEBOOK,
  GET_PROJECTS_NOTEBOOKS,
  MOVE_PROJECTS_NOTEBOOK,
  PIN_PROJECT_NOTEBOOK,
  UPDATE_PROJECTS_NOTEBOOK,
} from 'actions/types'
import {
  deleteProjectsNotebook,
  getProjectsNotebooks,
  pinProjectsNotebook,
  getProjectsFilters,
  getProjectsNotebookNotes,
} from 'actions/projects'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import {getPermissionTooltipContent} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import NotebookModal from './NotebookModal'
import CopyNotebookModal from './CopyNotebookModal'
import MoveNotebookModal from './MoveNotebookModal'
import NoteModal from './NoteModal'
import NotesDetails from './NotesDetails'

const Notes = props => {
  const {rolePermissions} = props
  const {projectId} = useParams()
  const dispatch = useDispatch()

  const [active, setActive] = useState({
    active: true,
  })
  const [isNotebookModalOpen, setIsNotebookModalOpen] = useState(false)
  const [allNotebooks, setAllNotebooks] = useState([])
  const [notebookId, setNotebookId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [notebookTitle, setNotebookTitle] = useState('')
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false)
  const [currentlySelectedNotebook, setCurrentlySelectedNotebook] = useState(null)
  const [currentListParams, setCurrentListParams] = useState({
    subscribers: 'all',
    sortBy: 'title',
    orderBy: 'asc',
  })
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectAssignees = []} = useSelector(state => state.projects)

  const {isLoading} = useApiLoader({label: GET_PROJECTS_NOTEBOOKS})

  const {data: notebooksList = [], callAction: callGetProjectsNotebooksAction} = useApiResponse({
    action: getProjectsNotebooks,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECTS_NOTEBOOKS,
    storePath: 'projects.notebooksList',
    onSuccess: (notebooksList = []) => {
      if (notebooksList?.length > 0) {
        const list = notebooksList.sort(function (x, y) {
          return x.pinToTop === y.pinToTop ? 0 : x.pinToTop ? -1 : 1
        })
        setAllNotebooks(list)

        if (!currentlySelectedNotebook) {
          setCurrentlySelectedNotebook(notebooksList[0].id)
        }
      }
    },
  })

  useEffect(() => {
    dispatch(getProjectsFilters(projectId, {filters: ['assignedTo']}))
  }, [dispatch, projectId])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_NOTEBOOK) ||
      successLabels.includes(UPDATE_PROJECTS_NOTEBOOK) ||
      successLabels.includes(COPY_PROJECTS_NOTEBOOK) ||
      successLabels.includes(MOVE_PROJECTS_NOTEBOOK) ||
      successLabels.includes(PIN_PROJECT_NOTEBOOK)
    ) {
      callGetProjectsNotebooksAction()
    }
    if (successLabels.includes(DELETE_PROJECTS_NOTEBOOK)) {
      callGetProjectsNotebooksAction()
      setCurrentlySelectedNotebook(null)
    }
  }, [successLabels, callGetProjectsNotebooksAction])

  useEffect(() => {
    if (currentlySelectedNotebook) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsNotebookNotes({projectId, notebookId: currentlySelectedNotebook, params: newList}))
    }
  }, [currentListParams, currentlySelectedNotebook, dispatch, projectId])

  if (isLoading) {
    return <PageLoader />
  }

  if (!notebooksList.length) {
    return (
      <>
        {isNotebookModalOpen && (
          <NotebookModal open={isNotebookModalOpen} toggle={setIsNotebookModalOpen} projectId={projectId} />
        )}
        <CreateFirst
          header="Notes"
          subHeader="Quickly write down small pieces of information related to the project used for team reference"
          addFirst={() => rolePermissions && setIsNotebookModalOpen(true)}
          buttonText="Create Notebook"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          imageSrc={create}
          Feature1="Notes Sharing"
          list1="Share notes with your team instantly, at ease"
          Feature2="Appear on top"
          list2="Make your notes appear on top using bookmark"
          Feature3="Download notes"
          list3="Download all your notes in PDF format"
        />
      </>
    )
  }

  const renderDropdown = notebook => {
    return (
      <VmoDropdownMenu>
        <VmoDropdownItem
          onClick={() => {
            setNotebookId(notebook.id)
            setIsNotebookModalOpen(true)
          }}
        >
          <SvgIcon path="common/edit2" /> Edit
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            setNotebookId(notebook.id)
            setIsDeleteModalOpen(true)
          }}
        >
          <SvgIcon path="common/delete" /> Delete
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            setNotebookId(notebook.id)
            setNotebookTitle(notebook.title)
            setIsCopyModalOpen(true)
          }}
        >
          <SvgIcon path="common/clone" /> Create a copy
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            setNotebookId(notebook.id)
            setNotebookTitle(notebook.title)
            setIsMoveModalOpen(true)
          }}
        >
          <SvgIcon path="common/move" /> Move
        </VmoDropdownItem>
        <VmoDropdownItem>
          <FiBookmark /> Bookmark
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            const data = {pinToTop: !notebook.pinToTop}
            dispatch(pinProjectsNotebook({projectId, notebookId: notebook.id, data}))
          }}
        >
          <BsPin /> {notebook.pinToTop ? 'Unpin' : 'Pin'}
        </VmoDropdownItem>
      </VmoDropdownMenu>
    )
  }

  const onClickNotebook = id => {
    if (currentlySelectedNotebook !== id) {
      setCurrentlySelectedNotebook(id)
    }
  }

  const SideMenu = () => {
    return (
      <VmoMenu vertical secondary className="projects-side-menu kooper-menu">
        <VmoAccordion className="mt-0">
          <VmoAccordionTitle className="d-flex">
            <div
              className="d-flex"
              style={{justifyContent: 'flex-start'}}
              onClick={() => setActive({...active, active: !active.active})}
            >
              {active.active ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              Active
            </div>
            {rolePermissions && (
              <VmoDropdown className="product-add-notes" icon={<SvgIcon path="common/plus" />}>
                <VmoDropdownMenu>
                  <VmoDropdownItem
                    onClick={() => {
                      setIsNoteModalOpen(true)
                    }}
                  >
                    Add Notes
                  </VmoDropdownItem>
                  <VmoDropdownItem
                    onClick={() => {
                      setNotebookId(null)
                      setIsNotebookModalOpen(true)
                    }}
                  >
                    Add Notebook
                  </VmoDropdownItem>
                </VmoDropdownMenu>
              </VmoDropdown>
            )}
          </VmoAccordionTitle>
          <VmoAccordionContent active={active.active}>
            {allNotebooks.map(notebook => (
              <VmoMenuItem
                key={notebook.id}
                className="project-discussion-sidemenu pr-0"
                active={notebook.id === currentlySelectedNotebook}
                onClick={() => onClickNotebook(notebook.id)}
              >
                <div className="d-flex" style={{justifyContent: 'flex-start'}}>
                  {notebook.pinToTop && <BsPin className="mr-1" />} {notebook.title}
                </div>
                {rolePermissions && (
                  <VmoDropdown className="project-discussion-sidemenu-dropdown" icon={<MdOutlineMoreVert />}>
                    {renderDropdown(notebook)}
                  </VmoDropdown>
                )}
              </VmoMenuItem>
            ))}
          </VmoAccordionContent>
        </VmoAccordion>
      </VmoMenu>
    )
  }

  const getSubscribers = () => {
    const list = projectAssignees.map(({id, firstName, lastName}) => ({
      key: id,
      value: id,
      text: `${firstName} ${lastName}`,
    }))
    return [{key: 'all', value: 'all', text: 'All'}, ...list]
  }

  return (
    <>
      <SideMenu />
      <div className="project-discussion-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
        <div className="project-discussion-head">
          <div />
          <div className="mailActivity">
            <VmoTooltip
              position="top center"
              content="Subscribers"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={getSubscribers()}
                  value={currentListParams.subscribers}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, subscribers: value})}
                />
              }
            />
            <VmoTooltip
              position="top center"
              content="Sort By"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'date', value: 'date', text: 'Date'},
                    {key: 'title', value: 'title', text: 'Title'},
                  ]}
                  value={currentListParams.sortBy}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, sortBy: value})}
                />
              }
            />
            <VmoTooltip
              position="top center"
              content="Order By"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  className="dropdown-ellipsis"
                  options={[
                    {key: 'asc', value: 'asc', text: 'Ascending'},
                    {key: 'desc', value: 'desc', text: 'Descending'},
                  ]}
                  value={currentListParams.orderBy}
                  onChange={(e, {value}) => setCurrentListParams({...currentListParams, orderBy: value})}
                />
              }
            />
          </div>
        </div>

        <NotesDetails
          projectId={projectId}
          notebookId={currentlySelectedNotebook}
          currentListParams={currentListParams}
          {...props}
        />
      </div>

      {isNotebookModalOpen && (
        <NotebookModal
          open={isNotebookModalOpen}
          toggle={setIsNotebookModalOpen}
          projectId={projectId}
          notebookId={notebookId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsNotebook({projectId, notebookId}))}
          type={DELETE_PROJECTS_NOTEBOOK}
          modalText="Are you sure you want to delete the notebook?"
        />
      )}

      {isCopyModalOpen && (
        <CopyNotebookModal
          open={isCopyModalOpen}
          toggle={setIsCopyModalOpen}
          projectId={projectId}
          notebookId={notebookId}
          notebookTitle={notebookTitle}
        />
      )}

      {isMoveModalOpen && (
        <MoveNotebookModal
          open={isMoveModalOpen}
          toggle={setIsMoveModalOpen}
          projectId={projectId}
          notebookId={notebookId}
          notebookTitle={notebookTitle}
        />
      )}

      {isNoteModalOpen && (
        <NoteModal
          open={isNoteModalOpen}
          toggle={setIsNoteModalOpen}
          projectId={projectId}
          notebookId={currentlySelectedNotebook}
        />
      )}
    </>
  )
}

export default Notes
