import {apiAction} from './api'
import {PRODUCTS_ENDPOINT} from './endpoint'
import {
  CREATE_PRODUCTS_CATEGORY,
  CREATE_PRODUCTS_FIELD,
  GET_PRODUCTS_FIELD,
  DELETE_PRODUCTS_CATEGORY,
  GET_PRODUCTS_CATEGORY,
  GET_PRODUCTS_FIELDLIST,
  EDIT_PRODUCTS_FIELD,
  DELETE_PRODUCTS_FIELD,
  GET_FIELD_BY_CATEGORY,
  CREATE_PRODUCT,
  GET_PRODUCTS_LIST,
  GET_PRODUCT_DATA,
  DELETE_PRODUCT,
  DELETE_MULTIPLE_PRODUCTS,
  UPDATE_PRODUCT,
  CLONE_PRODUCT,
  EXPORT_ALL_PRODUCTS,
  EXPORT_SELECTED_PRODUCTS,
  IMPORT_PRODUCTS,
  EXPORT_SAMPLE_PRODUCTS_CSV,
  DELETE_PRODUCT_IMAGE,
} from './types'

export function getProductCategory() {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/categories`,
    method: 'GET',
    label: GET_PRODUCTS_CATEGORY,
  })
}

export function createProductCategory(data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/category`,
    method: 'POST',
    label: CREATE_PRODUCTS_CATEGORY,
    data,
    showToast: true,
    successMessage: 'Category Added',
  })
}

/* export function editProductsCategory(id, data) {
  return apiAction({
    url: PRODUCTS_ENDPOINT + "/category/" + id,
    method: "PUT",
    onSuccess: editProductsCategorySuccess,
    onFailure: editProductsCategoryFailure,
    label: EDIT_PRODUCTS_CATEGORY,
    data: data,
    showToast: true,
    successMessage: "Category Updated",
  });
}

function editProductsCategorySuccess({ message, data, statusCode }) {
  return {
    type: EDIT_PRODUCTS_CATEGORY,
    payload: data,
    message: message,
    status: statusCode,
  };
}

function editProductsCategoryFailure(err, message) {
  return {
    type: EDIT_PRODUCTS_CATEGORY,
    error: err,
    message: message,
  };
}
 */
export function deleteProductsCategory(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/category/${id}`,
    method: 'DELETE',
    label: DELETE_PRODUCTS_CATEGORY,
    showToast: true,
    successMessage: 'Category Deleted',
  })
}

export function getProductFieldlist() {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/fields`,
    method: 'GET',
    label: GET_PRODUCTS_FIELDLIST,
  })
}

export function getProductField(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/field/${id}`,
    method: 'GET',
    label: GET_PRODUCTS_FIELD,
  })
}

export function getFieldByCategory() {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/category/field`,
    method: 'GET',
    label: GET_FIELD_BY_CATEGORY,
  })
}

export function createProductField(data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/field`,
    method: 'POST',
    label: CREATE_PRODUCTS_FIELD,
    data,
    showToast: true,
    successMessage: 'Field Added',
  })
}

export function editProductsField(data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/field`,
    method: 'PUT',
    label: EDIT_PRODUCTS_FIELD,
    data,
    showToast: true,
    successMessage: 'Field Updated',
  })
}

export function deleteProductsField(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/field/${id}`,
    method: 'DELETE',
    label: DELETE_PRODUCTS_FIELD,
    showToast: true,
    successMessage: 'Field Deleted',
  })
}

export function createProduct(data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product`,
    method: 'POST',
    label: CREATE_PRODUCT,
    data,
    showToast: true,
    successMessage: 'Product Added',
  })
}

export function getProductlist() {
  return apiAction({
    url: PRODUCTS_ENDPOINT,
    method: 'GET',
    label: GET_PRODUCTS_LIST,
  })
}

export function deleteProduct(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product/${id}`,
    method: 'DELETE',
    label: DELETE_PRODUCT,
    showToast: true,
    successMessage: 'Product Deleted',
  })
}

export function deleteMultipleProducts(idList) {
  const query = idList.join(',')
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/products/${query}`,
    method: 'DELETE',
    label: DELETE_MULTIPLE_PRODUCTS,
    showToast: true,
    successMessage: 'Selected Products Deleted',
  })
}

export function getProductData(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product/${id}`,
    method: 'GET',
    label: GET_PRODUCT_DATA,
  })
}

export function updateProduct(id, data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product/${id}`,
    method: 'PUT',
    label: UPDATE_PRODUCT,
    data,
    showToast: true,
    successMessage: 'Product Updated',
  })
}

export function cloneProduct(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product/copy/${id}`,
    method: 'PUT',
    label: CLONE_PRODUCT,
    showToast: true,
    successMessage: 'Product Cloned',
  })
}

export function exportAllProducts() {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/exports`,
    method: 'GET',
    label: EXPORT_ALL_PRODUCTS,
  })
}

export function exportSelectedProducts(data) {
  const query = data.join(',')
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/export/${query}`,
    method: 'GET',
    label: EXPORT_SELECTED_PRODUCTS,
  })
}

export function downloadSampleForCategory(categoryId) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/export/sample/${categoryId}`,
    method: 'GET',
    label: EXPORT_SAMPLE_PRODUCTS_CSV,
  })
}

export function importProducts(data) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/products/import`,
    method: 'POST',
    label: IMPORT_PRODUCTS,
    data,
    // showToast: true,
    // successMessage: "Imported Successfully",
  })
}

export function deleteProductImage(id) {
  return apiAction({
    url: `${PRODUCTS_ENDPOINT}/product/image/${id}`,
    method: 'DELETE',
    label: DELETE_PRODUCT_IMAGE,
    // showToast: true,
    successMessage: 'Product Image Deleted',
  })
}
