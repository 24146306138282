import {apiAction} from './api'
import {KOOPER_ACCOUNT_V1} from './endpoint'
import {
  ADD_FORECAST,
  UPDATE_FORECAST_SETTING,
  DELETE_FORECAST,
  GET_FORECAST_DETAIL,
  GET_FORECAST_LIST,
  GET_FORECAST_SETTING,
  UPDATE_FORECAST,
} from './types'

export function forecastSetting(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast/setting`,
    method: 'POST',
    label: UPDATE_FORECAST_SETTING,
    data,
    showToast: true,
    successMessage: 'Forecast Updated',
  })
}

export function getForecastSetting() {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast/setting`,
    method: 'GET',
    label: GET_FORECAST_SETTING,
  })
}
export function getForecastList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast?${query}`,
    method: 'GET',
    label: GET_FORECAST_LIST,
  })
}
export function getForecastDetail(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast/${id}`,
    method: 'GET',
    label: GET_FORECAST_DETAIL,
  })
}

export function addForecast(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast`,
    method: 'POST',
    label: ADD_FORECAST,
    data,
    showToast: true,
    successMessage: 'Forecast Created',
  })
}

export function updateForecast(id, data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast/${id}`,
    method: 'PUT',
    label: UPDATE_FORECAST,
    data,
    showToast: true,
    successMessage: 'Forecast Updated',
  })
}

export function deleteForecast(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/forecast/${id}`,
    method: 'DELETE',
    label: DELETE_FORECAST,
    showToast: true,
    successMessage: 'Forecast Deleted',
  })
}
