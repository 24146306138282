/* eslint-disable no-useless-concat */
import {apiAction} from './api'
import {KOOPER_ACCOUNT_V1} from './endpoint'
import {
  CREATE_SEQUENCE,
  DELETE_SEQUENCE,
  EDIT_SEQUENCE,
  GET_ALL_SEQUENCES,
  GET_SEQUENCE,
  SEQUENCE_CONTACTS,
  SEQUENCE_EMAILS,
  SEQUENCE_OVERVIEW,
  UPDATE_SEQUENCE_STATUS,
} from './types'

export function getAllSequences(params = {}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences` + `?${query}`,
    method: 'GET',
    label: GET_ALL_SEQUENCES,
  })
}

export function getSequence(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}`,
    method: 'GET',
    label: GET_SEQUENCE,
  })
}

export function editSequences(id, data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}`,
    method: 'PUT',
    label: EDIT_SEQUENCE,
    data,
    successMessage: 'Sequence Updated',
    showToast: true,
  })
}

export function createSequence(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences`,
    method: 'POST',
    label: CREATE_SEQUENCE,
    data,
    successMessage: 'Sequence Created',
    showToast: true,
  })
}

export function deleteSequence(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}`,
    method: 'DELETE',
    label: DELETE_SEQUENCE,
    successMessage: 'Sequence Deleted',
    showToast: true,
  })
}

export function updateSequenceStatus(id, data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}/status`,
    method: 'PUT',
    label: UPDATE_SEQUENCE_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}

export function sequnceContactList(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}/contactdetails`,
    method: 'GET',
    label: SEQUENCE_CONTACTS,
  })
}

export function sequnceEmailList(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}/emaildetails`,
    method: 'GET',
    label: SEQUENCE_EMAILS,
  })
}

export function sequnceOverview(id) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/sales/sequences/${id}/overview`,
    method: 'GET',
    label: SEQUENCE_OVERVIEW,
  })
}
