import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {setMetaData} from 'actions'
import _ from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
} from 'vmo-library'
import {DELETE_CHANNEL, EDIT_CHANNEL, GET_CHANNEL, GET_TWITTER_CODE, GET_USERS} from 'actions/types'
import {editChannel, getChannel, getChannels} from 'actions/settings_channels'
import {getUsers} from 'actions/settings'
import {get, removeDoubleQuotes} from 'utils/helper'
import {interval} from 'constants/variables'
import SettingLayout from 'layouts/settingLayout'
import {getMetadataInfo} from 'utils/local-storage'
import {facebookSchema} from 'validation/Settings/channels/facebook.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useApiResponse from 'hooks/impure/useApiResponse'

const FacebookDetails = props => {
  const {match, warningModal, setWarningModal} = props
  const {id} = match.params

  const dispatch = useDispatch()
  const history = useHistory()

  const defaultValues = {
    assignees: [],
    inbox: {name: ''},
    permissions: {
      posts: false,
      messages: false,
    },
    threadingInterval: 0,
  }

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(facebookSchema),
    defaultValues,
  })

  const [usersList, setUsersList] = useState([])

  const {isLoadingData, type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {twitterCodeData, getAllChannelList = [], getChannelData} = useSelector(state => state.settingsChannels)

  const {channelsPermissions} = useUserPermissions()
  const manageFacebookPermissions = channelsPermissions?.manage_facebook

  useEffect(() => {
    dispatch(getChannel(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(getChannels({type: 'facebook'}))
  }, [dispatch])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        content: (
          <div>
            {`${user.firstName} ${user.lastName}`}
            <small className="ml-2 kooper-lead">({get(['email'], user, '')})</small>
          </div>
        ),
      }))
      setUsersList(list || [])
    },
  })

  useEffect(() => {
    if (successLabels.includes(GET_CHANNEL)) {
      setWarningModal(false)
      reset({
        inbox: {name: getChannelData.inbox?.name},
        assignees: getChannelData.assignees,
        permissions: {
          posts: getChannelData.permissions?.includes('posts'),
          messages: getChannelData.permissions?.includes('messages'),
        },
        threadingInterval: getChannelData.threadingInterval,
      })
    }
    if (successLabels.includes(EDIT_CHANNEL)) {
      setWarningModal(false)
    }

    if (successLabels.includes(DELETE_CHANNEL)) {
      dispatch(getChannels({type: 'facebook'}))
    }
  }, [successLabels, dispatch, getChannelData, reset, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(GET_TWITTER_CODE)) {
      window.location.replace(`https://api.veemodesk.io/channels/auth/facebook?code=${twitterCodeData.token}`)
    }
  }, [successLabels, twitterCodeData])

  useEffect(() => {
    if (getAllChannelList && getAllChannelList.length > 0 && !get(['facebookStatus'], getMetadataInfo())) {
      const data = {
        facebookStatus: true,
      }
      dispatch(setMetaData(data))
    }
  }, [getAllChannelList, dispatch])

  useEffect(() => {
    if (successLabels.includes(EDIT_CHANNEL) && !warningModal) {
      history.push('/settings/facebook')
      if (successLabels.includes(EDIT_CHANNEL)) {
        dispatch(getChannels({type: 'facebook'}))
        dispatch(getChannel(id))
      }
    }
  }, [successLabels, warningModal, dispatch, history, id])

  useEffect(() => {
    setWarningModal(isDirty)
    return () => setWarningModal(false)
  }, [isDirty, setWarningModal])

  return (
    <SettingLayout
      header="Add your Facebook pages"
      headerbackbtn={{
        onClick: () => {
          if (!warningModal) {
            setWarningModal(false)
            history.push('/settings/facebook')
            reset({
              inbox: {name: getChannelData.inbox.name},
              assignees: getChannelData.assignees,
              permissions: {
                posts: getChannelData.permissions?.includes('posts'),
                messages: getChannelData.permissions?.includes('messages'),
              },
              threadingInterval: getChannelData.threadingInterval,
            })
          } else if (warningModal) {
            history.push('/settings/facebook')
          }
        },
      }}
      subHeader=" Associate your Facebook company pages with your helpdesk to manage all
        queries coming in from there along with your support tickets."
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Cancel',
          disabled: isLoadingData && type.includes(EDIT_CHANNEL),
          onClick: () => {
            if (!warningModal) {
              setWarningModal(false)
              history.push('/settings/facebook')
              reset({
                inbox: {name: getChannelData.inbox.name},
                assignees: getChannelData.assignees,
                permissions: {
                  posts: getChannelData.permissions.includes('posts'),
                  messages: getChannelData.permissions.includes('messages'),
                },
                threadingInterval: getChannelData.threadingInterval,
              })
            } else if (warningModal) {
              history.push('/settings/facebook')
            }
          },
        },
        success: {
          content: 'Update',
          loading: isLoadingData && type.includes(EDIT_CHANNEL),
          ...(manageFacebookPermissions && {
            onClick: handleSubmit(data => {
              const dataToDispatch = {...data}
              dataToDispatch.permissions = _.keys(_.pickBy(data.permissions))
              dispatch(editChannel(id, dataToDispatch))
            }),
          }),
          disabled: !isDirty || (isLoadingData && type.includes(EDIT_CHANNEL)),
        },
      }}
      lockRole={!manageFacebookPermissions}
    >
      <div className="socialWigInfo">
        <h5>
          <img src="https://www.facebook.com/images/fb_icon_325x325.png" className="socialLogo" alt="img" />@
          {get(['displayName'], getChannelData, '')}
        </h5>
        <p className="kooper-lead">This inbox will contain all your @ mentions</p>
        <VmoForm className="errorLabel">
          <VmoFormField required width={6}>
            <label>Inbox Name</label>
            <Controller
              control={control}
              name="inbox.name"
              render={({value, onChange}) => (
                <VmoFormInput
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.inbox?.name && {
                      content: removeDoubleQuotes(errors.inbox.name.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
          <VmoFormField width={6}>
            <label>Assign Inbox</label>
            <Controller
              control={control}
              name="assignees"
              render={({value, onChange}) => (
                <VmoFormSelect
                  multiple
                  selection
                  fluid
                  options={usersList}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.assignees && {
                      content: removeDoubleQuotes(errors.assignees.message),
                    }
                  }
                />
              )}
            />
          </VmoFormField>
        </VmoForm>

        <VmoCard fluid className="switchBox">
          <VmoCardContent>
            <div className="d-flex">
              <div>
                <h5>Convert all Messages to tickets</h5>
                <p>
                  All the incoming messages from facebook portal will be automatically converted into tickets and will
                  be reflected in the Inbox section
                </p>
              </div>
              <Controller
                control={control}
                name="permissions.messages"
                render={({value, onChange}) => (
                  <VmoFormRadio disabled toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid className="switchBox">
          <VmoCardContent>
            <VmoForm>
              <VmoFormField>
                <label className="pb-1">Set ticket threading interval</label>
                <p className="kooper-lead pb-2">
                  Multiple messages with same person within this time interval will be merged into one ticket
                </p>
                <Controller
                  control={control}
                  name="threadingInterval"
                  render={({value, onChange}) => (
                    <VmoFormDropdown
                      className="m-0"
                      width={6}
                      selection
                      options={interval}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                    />
                  )}
                />
                <p style={{marginTop: '10px'}}>
                  For example, if you set the interval to 1 day, the messages sent to you by the same user within 1 day
                  will generate a single ticket.
                </p>
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid className="switchBox">
          <VmoCardContent>
            <div className="d-flex">
              <div>
                <h5>Convert posts and comments into tickets</h5>
                <p>
                  Enabling this option will convert the posts and comments on the page into tickets and issues and will
                  be reflected in the selected inbox.
                </p>
              </div>
              <Controller
                control={control}
                name="permissions.posts"
                render={({onChange}) => (
                  <VmoFormRadio
                    toggle
                    className="float-right"
                    checked={false}
                    disabled
                    onChange={(e, {checked}) => onChange(checked)}
                  />
                )}
              />
            </div>
          </VmoCardContent>
        </VmoCard>
      </div>
    </SettingLayout>
  )
}

export default FacebookDetails
