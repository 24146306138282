import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_FEEDBACK_FORM, GET_FEEDBACK_FORM} from 'actions/types'
import {deleteFeedbackForm, getFeedbackForm} from 'actions/settings_channels'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import create from 'assets/create.svg'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import Feedback from './Feedback'

const FeedbackList = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [createFeedback, setCreateFeedback] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type = []} = useSelector(state => state.apiReducer)
  const {feedbackFormList = []} = useSelector(state => state.settingsChannels)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const contactFormLearnMore = settingsLearnMoreUrl?.channels?.contactForm?.learnMore

  const {isLoading} = useApiLoader({label: GET_FEEDBACK_FORM})
  const {clientManagementAccess, channelsPermissions} = useUserPermissions()
  const contactFormsAccess = clientManagementAccess?.contactForms
  const manageContactFormPermissions = channelsPermissions?.manage_contact_forms

  useEffect(() => {
    if (contactFormsAccess) {
      dispatch(getFeedbackForm())
    }
  }, [contactFormsAccess, dispatch])

  useApiResponse({
    label: 'DELETE_FEEDBACK_FORM',
    onSuccess: () => {
      dispatch(getFeedbackForm())
    },
  })

  const renderFeedbackList = () => {
    return feedbackFormList.map((list, index) => (
      <VmoTableRow
        key={index}
        className="tableLink"
        onClick={() =>
          history.push({
            pathname: `/settings/contact-form/${list.id}`,
          })
        }
      >
        <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(list.headerText)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{list.inbox}</VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            content={
              manageContactFormPermissions
                ? 'Delete'
                : getPermissionTooltipContent({roleAccess: manageContactFormPermissions})
            }
            position="top center"
            size="mini"
            trigger={
              <VmoButton
                icon
                className="tableLink-delete-icon"
                onClick={e => {
                  e.stopPropagation()
                  if (manageContactFormPermissions) {
                    setIdToDelete(list.id)
                    setDeleteModal(true)
                  }
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  if (isLoading && contactFormsAccess) {
    return <PageLoader />
  }

  if (createFeedback) {
    return <Feedback toggleModal={setCreateFeedback} {...props} />
  }

  if (feedbackFormList.length === 0 || !contactFormsAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Contact Form"
          subHeader="Contact form help you to collect customer information and leads directly from website"
          addFirst={() => contactFormsAccess && manageContactFormPermissions && setCreateFeedback(true)}
          Feature1="Form Fields"
          Feature2="Embedding"
          Feature3="Prevent spam"
          list1="Based on requirement add fields in contact form"
          list2="Embed form on your website or landing page"
          list3="Enable capatch to prevent response spam"
          buttonText="Create Form"
          tooltip={!contactFormsAccess || !manageContactFormPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: contactFormsAccess,
            roleAccess: manageContactFormPermissions,
          })}
          imageSrc={create}
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/contact-form" />}
      header="Contact Form"
      subHeader="Create and embed contact forms in your website for customers to contact"
      learnMoreUrl={contactFormLearnMore}
      headerButton={{
        onClick: () => manageContactFormPermissions && setCreateFeedback(true),
        content: 'Add',
      }}
      table
      headerDivider
      lockRole={!manageContactFormPermissions}
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Form Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Mailbox</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && type.includes(GET_FEEDBACK_FORM) ? <VmoTablePlaceholder rows={4} /> : renderFeedbackList()}
        </VmoTableBody>
      </VmoTable>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteFeedbackForm}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete contact form ?"
          type={DELETE_FEEDBACK_FORM}
        />
      )}
    </SettingLayout>
  )
}

export default FeedbackList
