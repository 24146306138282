import {CONVERSATIONS_ENDPOINT, TASKS_ENDPOINT} from './endpoint'
import {
  CREATE_TASK,
  GET_ALL_TASK,
  GET_TASK,
  DELETE_TASK,
  EDIT_TASK,
  GET_CONVERSATION_ACTIVITIES,
  CREATE_SUBTASK,
  EDIT_SUBTASK,
  DELETE_SUBTASK,
  CREATE_TASK_NOTE,
  GET_ALL_TASK_SCROLL,
} from './types'
import {apiAction} from './api'

export function createTaskActivities(data) {
  return apiAction({
    url: TASKS_ENDPOINT,
    method: 'POST',
    label: CREATE_TASK,
    data,
    showToast: true,
    successMessage: 'Task Created',
  })
}

export function createSubtask(data) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/subtasks`,
    method: 'POST',
    label: CREATE_SUBTASK,
    data,
    showToast: true,
    successMessage: 'Subtask Created',
  })
}

export function getAllTaskActivities(params) {
  const keys = Object.keys(params || {})

  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  const url = `${TASKS_ENDPOINT}?${query}`

  return apiAction({
    url,
    method: 'GET',
    label: GET_ALL_TASK,
  })
}

export function getAllTaskScroll({status, userId, page}) {
  let url = `${TASKS_ENDPOINT}?status=${status}&page=${page}`
  if (userId) {
    url = `${TASKS_ENDPOINT}?status=${status}&userId=${userId}&page=${page}`
  }
  return apiAction({
    url,
    method: 'GET',
    label: GET_ALL_TASK_SCROLL,
  })
}

export function getTaskActivities(id) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_TASK,
  })
}

export function deleteTaskActivities(id) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/${id}`,
    method: 'DELETE',
    label: DELETE_TASK,
    showToast: true,
    successMessage: 'Task deleted',
  })
}

export function deleteSubtask(id) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/subtasks/${id}`,
    method: 'DELETE',
    label: DELETE_SUBTASK,
    showToast: true,
    successMessage: 'Subtask deleted',
  })
}

export function editTaskActivities(id, data) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/${id}`,
    method: 'PUT',
    label: EDIT_TASK,
    data,
    showToast: true,
    successMessage: 'Task Updated',
  })
}

export function editSubtask(id, data) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/subtasks/${id}`,
    method: 'PUT',
    label: EDIT_SUBTASK,
    data,
    showToast: true,
    successMessage: 'Subtask Updated',
  })
}

export function getConversationActivities(id) {
  return apiAction({
    url: `${CONVERSATIONS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_CONVERSATION_ACTIVITIES,
  })
}

export function createTaskNote(id, data) {
  return apiAction({
    url: `${TASKS_ENDPOINT}/${id}/note`,
    method: 'POST',
    label: CREATE_TASK_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Created',
  })
}
