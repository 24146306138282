/* eslint-disable camelcase */
import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import moment from 'moment'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {
  EXPENSES_BREAKDOWN_BY_CATEGORY,
  EXPENSES_BREAKDOWN_BY_STATUS,
  EXPENSES_BY_DEPARTMENT,
  EXPENSES_STATUS_TIMETREND,
  EXPENSES_CATEGORY_TIMETREND,
  EXPENSES_TIMETREND_BY_DEPARTMENT,
} from 'actions/types'
import {
  expensesBreakdownByCategory,
  expensesBreakdownByStatus,
  expensesbyDepartment,
  expensesCountStats,
  expensesStatusTimeTrend,
  expensesValueStats,
  expensesCategoryTimeTrend,
  expensesTimeTrendByDepartment,
  expensesEmployeeStats,
} from 'actions/reports_expenses'
import {getDepartment} from 'actions/employees'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import CustomLoader from 'components/common/CustomLoader'
import {
  getGridYValues,
  getGridYValuesBar,
  getGridYValuesMultipleBar,
  maxOfGraph,
  maxOfGraphBar,
  maxOfGraphMultipleBar,
} from 'utils/graph'
import {startCase, get} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {DATE_RANGE_KEY} from 'constants/variables'

const ValueOutside = ({bars}) => {
  return bars.map(bar => {
    const {
      key,
      width,
      height,
      x,
      y,
      data: {value},
    } = bar
    return (
      <g key={key} transform={`translate(${x}, ${y})`}>
        <text transform={`translate(${width + 5}, ${height / 2 + 3})`} textAnchor="middle" fontSize="11px">
          {value !== 0 ? value : null}
        </text>
      </g>
    )
  })
}

function Expenses() {
  const dispatch = useDispatch()

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {type = []} = useSelector(state => state.apiReducer)
  const {
    expensesCountStatsData = {},
    expensesValueStatsData = {},
    BreakDownByCateogoryCountData = [],
    BreakDownByCateogoryValueData = [],
    BreakDownByStatusCountData = [],
    BreakDownByStatusValueData = [],
    expensesByDepartmentData = [],
    expensesStatusTimetrendData = [],
    expensesCategoryTimetrendData = [],
    TimetrendByDepartmentData = [],
    ExpensesEmployeeStatsData = [],
  } = useSelector(state => state.reportsExpenses)
  const {Approved, Paid, Rejected, Submitted, Total, Under_Review, draft} = expensesCountStatsData
  const {
    Approved: ApprovedValue,
    Paid: PaidValue,
    Rejected: RejectedValue,
    Submitted: SubmittedValue,
    Total: TotalValue,
    Under_Review: Under_ReviewValue,
    draft: draftValue,
  } = expensesValueStatsData

  const diff = moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).diff(
    moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))),
    'days'
  )

  const {data: getDepartmentData = []} = useApiResponse({
    action: getDepartment,
    enabled: true,
    storePath: 'employees.getDepartmentData',
  })

  const actionsToDispatch = useCallback(
    data => {
      dispatch(expensesCountStats(data))
      dispatch(expensesValueStats(data))
      dispatch(expensesBreakdownByCategory(data))
      dispatch(expensesBreakdownByStatus(data))
      dispatch(expensesbyDepartment(data))
      dispatch(expensesStatusTimeTrend(data))
      dispatch(expensesCategoryTimeTrend(data))
      dispatch(expensesTimeTrendByDepartment(data))
      dispatch(expensesEmployeeStats(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="settings/users" />
            <h5>Expenses</h5>
          </div>
        </div>
        <div className="page-action">
          <UserDropdown />
          <DateRangeGroup
            actionsToDispatch={actionsToDispatch}
            userId={
              analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                ? Number(analyticsSelectedAssignee.id)
                : null
            }
          />
        </div>
      </div>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Approved by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of expenses approved compared to the previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Approved, 0)}
                <span className={get(['growth'], Approved, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Approved"
                    trigger={
                      <div>
                        {get(['growth'], Approved, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Approved, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Paid by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of expenses paid compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Paid, 0)}
                <span className={get(['growth'], Paid, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Paid"
                    trigger={
                      <div>
                        {get(['growth'], Paid, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Paid, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Rejected by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of approval requested compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Rejected, 0)}
                <span className={get(['growth'], Rejected, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Rejected"
                    trigger={
                      <div>
                        {get(['growth'], Rejected, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Rejected, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Submitted by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of expense request submitted compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Submitted, 0)}
                <span className={get(['growth'], Submitted, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Submitted"
                    trigger={
                      <div>
                        {get(['growth'], Submitted, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Submitted, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Total by Number</VmoCardHeader>
              <VmoTooltip
                content="Total number of expenses compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Total, 0)}
                <span className={get(['growth'], Total, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Total"
                    trigger={
                      <div>
                        {get(['growth'], Total, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Total, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Under_Review by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of expense which are under review"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Under_Review, 0)}
                <span className={get(['growth'], Under_Review, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Under_Review"
                    trigger={
                      <div>
                        {get(['growth'], Under_Review, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Under_Review, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>draft by Number</VmoCardHeader>
              <VmoTooltip
                content="Number of draft expenses"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], draft, 0)}
                <span className={get(['growth'], draft, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="draft"
                    trigger={
                      <div>
                        {get(['growth'], draft, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], draft, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid columns="equal">
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Approved by Value</VmoCardHeader>
              <VmoTooltip
                content="Value of expenses approved"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], ApprovedValue, 0)}
                <span className={get(['growth'], ApprovedValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Approved"
                    trigger={
                      <div>
                        {get(['growth'], ApprovedValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], ApprovedValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Paid by Value</VmoCardHeader>
              <VmoTooltip
                content="Expense value paid compared to the previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], PaidValue, 0)}
                <span className={get(['growth'], PaidValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Paid"
                    trigger={
                      <div>
                        {get(['growth'], PaidValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], PaidValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Rejected by Value</VmoCardHeader>
              <VmoTooltip
                content="Expense value rejected compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], RejectedValue, 0)}
                <span className={get(['growth'], RejectedValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Rejected"
                    trigger={
                      <div>
                        {get(['growth'], RejectedValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], RejectedValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Submitted by Value</VmoCardHeader>
              <VmoTooltip
                content="Expense value requested compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], SubmittedValue, 0)}
                <span className={get(['growth'], SubmittedValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Submitted"
                    trigger={
                      <div>
                        {get(['growth'], SubmittedValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], SubmittedValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Total by Value</VmoCardHeader>
              <VmoTooltip
                content="Total expense amount compared to previou duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], TotalValue, 0)}
                <span className={get(['growth'], TotalValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Total"
                    trigger={
                      <div>
                        {get(['growth'], TotalValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], TotalValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Under_Review by Value</VmoCardHeader>
              <VmoTooltip
                content="Expense value which are under review"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], Under_ReviewValue, 0)}
                <span className={get(['growth'], Under_ReviewValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="Under_Review"
                    trigger={
                      <div>
                        {get(['growth'], Under_ReviewValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], Under_ReviewValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>draft by Value</VmoCardHeader>
              <VmoTooltip
                content="Expense value which are draft compared to previous duration"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="valueBox pt-2">
              <h3>
                {get(['value'], draftValue, 0)}
                <span className={get(['growth'], draftValue, 'positive') === 'positive' ? '' : 'text-danger'}>
                  <VmoTooltip
                    position="top center"
                    size="mini"
                    content="draft"
                    trigger={
                      <div>
                        {get(['growth'], draftValue, 'positive') === 'positive' ? (
                          <SvgIcon path="common/arrow-up-right" />
                        ) : (
                          <SvgIcon path="common/arrow-down-left" className="down-left" />
                        )}
                        {get(['percentage'], draftValue, 0)}%
                      </div>
                    }
                  />
                </span>
              </h3>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid columns="equal">
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense by Category (Number)</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of expenses based category within a time range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) && <CustomLoader />}
              {!type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) &&
              !BreakDownByCateogoryCountData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={BreakDownByCateogoryCountData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 100,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense by Category (Value)</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of expense based on category value over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) && <CustomLoader />}
              {!type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) &&
              !BreakDownByCateogoryValueData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={BreakDownByCateogoryValueData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 100,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(EXPENSES_BREAKDOWN_BY_CATEGORY) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid columns="equal">
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense by Status (Number)</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of expenses based on status within a time range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EXPENSES_BREAKDOWN_BY_STATUS) && <CustomLoader />}
              {!type.includes(EXPENSES_BREAKDOWN_BY_STATUS) &&
              !BreakDownByStatusCountData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={BreakDownByStatusCountData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(EXPENSES_BREAKDOWN_BY_STATUS) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense by Status (Value)</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of expense based on status value within a time range"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EXPENSES_BREAKDOWN_BY_STATUS) && <CustomLoader />}
              {!type.includes(EXPENSES_BREAKDOWN_BY_STATUS) &&
              !BreakDownByStatusValueData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={BreakDownByStatusValueData}
                  margin={{top: 30, right: 30, bottom: 30, left: 30}}
                  startAngle={-45}
                  innerRadius={0.5}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  sliceLabel={({value}) => `${value}`}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: {value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: 30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(EXPENSES_BREAKDOWN_BY_STATUS) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense by Department</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of expenses based on their respective departments over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 270}}>
              {type.includes(EXPENSES_BY_DEPARTMENT) && <CustomLoader />}
              {!type.includes(EXPENSES_BY_DEPARTMENT) && expensesByDepartmentData.every(({total}) => total === 0) && (
                <div className="empty-chart" style={{marginLeft: '10px'}}>
                  No Data Available
                </div>
              )}
              {!type.includes(EXPENSES_BY_DEPARTMENT) && (
                <ResponsiveBar
                  data={expensesByDepartmentData}
                  keys={expensesByDepartmentData?.map(({department}) => {
                    return department
                  })}
                  layout="horizontal"
                  indexBy="department"
                  margin={{
                    top: 10,
                    right: 10,
                    bottom: 60,
                    left: 60,
                  }}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  minValue={0}
                  maxValue={maxOfGraphBar(expensesByDepartmentData, 'total')}
                  enableGridX
                  enableGridY={false}
                  gridXValues={getGridYValuesBar(expensesByDepartmentData, 'total')}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Department',
                    legendPosition: 'middle',
                    legendOffset: -50,
                  }}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(expensesByDepartmentData, 'total'),
                    legend: 'Total',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  enableLabel={false}
                  labelSkipWidth={1}
                  layers={[
                    'grid',
                    'axes',
                    'bars',
                    'markers',
                    'legends',
                    'annotations',
                    props => <ValueOutside {...props} />,
                  ]}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense By Status</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of expenses based on their status over a period of time"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: '350px'}}>
              {type.includes(EXPENSES_STATUS_TIMETREND) && <CustomLoader />}
              {!type.includes(EXPENSES_STATUS_TIMETREND) &&
                !expensesStatusTimetrendData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(EXPENSES_STATUS_TIMETREND) && (
                <ResponsiveLine
                  data={expensesStatusTimetrendData}
                  colors={{scheme: 'nivo'}}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(expensesStatusTimetrendData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(expensesStatusTimetrendData),
                    legend: 'Status',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(expensesStatusTimetrendData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 30,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Expense By Category</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of expenses with respect to their categories"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: '350px'}}>
              {type.includes(EXPENSES_CATEGORY_TIMETREND) && <CustomLoader />}
              {!type.includes(EXPENSES_CATEGORY_TIMETREND) &&
                !expensesCategoryTimetrendData.map(({data}) => data.every(({y}) => y === 0)).includes(false) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(EXPENSES_CATEGORY_TIMETREND) && (
                <ResponsiveLine
                  data={expensesCategoryTimetrendData}
                  colors={{scheme: 'nivo'}}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(expensesCategoryTimetrendData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(expensesCategoryTimetrendData),
                    legend: 'Category',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  lineWidth={3}
                  pointSize={5}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(expensesCategoryTimetrendData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 110,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Expenses by Department</h5>
                <VmoTooltip
                  content="Graphical representation of expenses base of various departments"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(EXPENSES_TIMETREND_BY_DEPARTMENT) && <CustomLoader />}
              {!type.includes(EXPENSES_TIMETREND_BY_DEPARTMENT) && (
                <ResponsiveBar
                  data={TimetrendByDepartmentData}
                  keys={getDepartmentData?.map(({department}) => {
                    return department
                  })}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  // colors={(d) => d.data[`${d.id}Color`]}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(
                    TimetrendByDepartmentData,
                    getDepartmentData?.map(({department}) => {
                      return department
                    })
                  )}
                  gridYValues={getGridYValuesMultipleBar(
                    TimetrendByDepartmentData,
                    getDepartmentData?.map(({department}) => {
                      return department
                    })
                  )}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 40,
                    format: tick => {
                      if (diff > 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(
                      TimetrendByDepartmentData,
                      getDepartmentData?.map(({department}) => {
                        return department
                      })
                    ),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent>
              <div className="info-header">
                <h5 className="m-0">Expense by Employee </h5>
                <VmoTooltip
                  content="list of all the expenses based on employees along with their different activities"
                  trigger={<SvgIcon path="common/question" />}
                  size="mini"
                  position="right center"
                />
              </div>
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable responsive basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Total Expenses</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Approved</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Rejected</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Submitted</VmoTableHeaderCell>
                    <VmoTableHeaderCell>In-review</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Paid</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {ExpensesEmployeeStatsData && ExpensesEmployeeStatsData.length === 0 ? (
                    <tr>
                      <td colSpan="8" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    <>
                      {ExpensesEmployeeStatsData?.map(
                        ({name, total, approved, rejected, submitted, under_review, paid}, index) => (
                          <VmoTableRow key={index}>
                            <VmoTableCell>{startCase(name)}</VmoTableCell>
                            <VmoTableCell>{total}</VmoTableCell>
                            <VmoTableCell>{approved}</VmoTableCell>
                            <VmoTableCell>{rejected}</VmoTableCell>
                            <VmoTableCell>{submitted}</VmoTableCell>
                            <VmoTableCell>{under_review}</VmoTableCell>
                            <VmoTableCell>{paid}</VmoTableCell>
                          </VmoTableRow>
                        )
                      )}
                    </>
                  )}
                </VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Expenses
