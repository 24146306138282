import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {
  AVERAGE_RESOLUTION_TIME_SLA,
  FIRST_RESPONSE_TIME_SLA,
  RESOLVE_TARGET_OVER_TIME_SLA,
  RESPONSE_TARGET_OVER_TIME_SLA,
} from './types'

export function firstResponseTimeSLA(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/sla/firstresponsetimesla`,
    method: 'POST',
    label: FIRST_RESPONSE_TIME_SLA,
    data,
  })
}

export function averageResolutionTimeSLA(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/sla/averageresolutiontimesla`,
    method: 'POST',
    label: AVERAGE_RESOLUTION_TIME_SLA,
    data,
  })
}

export function resolveTargetOverTimeSLA(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/sla/slaresolvetargetsovertime`,
    method: 'POST',
    label: RESOLVE_TARGET_OVER_TIME_SLA,
    data,
  })
}

export function responseTargetOverTimeSLA(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/sla/slaresponsetargetsovertime`,
    method: 'POST',
    label: RESPONSE_TARGET_OVER_TIME_SLA,
    data,
  })
}
