import {apiAction} from './api'
import {USER_PLATFORM_V1} from './endpoint'
import {
  CALCULATE_BILLING,
  CANCEL_BILLING,
  CREATE_PAYMENT_METHOD,
  CURRENT_SUBSCRIPTION,
  DELETE_PAYMENT_METHOD,
  GET_BILLING_ADDRESS,
  GET_KOOPER_PLANS,
  GET_PAYMENT_METHOD,
  GET_SINGLE_INVOICE,
  INVOICE_LISTING,
  REFRESH_ACCESS_TOKEN,
  RESUME_BILLING,
  START_BILLING,
  UPDATE_BILLING,
  UPDATE_BILLING_ADDRESS,
  UPDATE_PAYMENT_METHOD,
} from './types'

export function currentSubscription() {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/currentsubscription`,
    method: 'GET',
    label: CURRENT_SUBSCRIPTION,
  })
}

export function getKooperPlans() {
  return apiAction({
    url: `${USER_PLATFORM_V1}/plans`,
    method: 'GET',
    label: GET_KOOPER_PLANS,
  })
}

export function resumeBilling(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/resumecancelled`,
    method: 'POST',
    label: RESUME_BILLING,
    data,
    showToast: true,
    successMessage: 'Subscription Resumed',
  })
}

export function cancelBilling(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/cancel`,
    method: 'POST',
    label: CANCEL_BILLING,
    data,
    successMessage: 'Subscription Cancelled',
    showToast: true,
  })
}

export function getPaymentMethods() {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/paymentmethods`,
    method: 'GET',
    label: GET_PAYMENT_METHOD,
  })
}

export function createPaymentMethods(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/paymentmethods`,
    method: 'POST',
    label: CREATE_PAYMENT_METHOD,
    data,
    showToast: true,
    successMessage: 'Card Added',
  })
}

export function createPaymentMethodsToken(token) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/card/token`,
    method: 'POST',
    label: CREATE_PAYMENT_METHOD,
    data: token,
    showToast: true,
    successMessage: 'Card Added',
  })
}

export function updatePaymentMethods(id, data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/paymentmethods/${id}/default`,
    method: 'PUT',
    label: UPDATE_PAYMENT_METHOD,
    data,
    successMessage: 'Default card updated',
    showToast: true,
  })
}

export function deletePaymentMethods(id, data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/paymentmethods/${id}`,
    method: 'DELETE',
    label: DELETE_PAYMENT_METHOD,
    data,
    successMessage: 'Card Deleted',
    showToast: true,
  })
}

export function getBillingAddress(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/address`,
    method: 'GET',
    label: GET_BILLING_ADDRESS,
    data,
  })
}

export function updateBillingAddress(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/address`,
    method: 'POST',
    label: UPDATE_BILLING_ADDRESS,
    data,
    successMessage: 'Billing Address Updated',
    showToast: true,
  })
}

export function calculateBilling(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/calculateamount`,
    method: 'POST',
    label: CALCULATE_BILLING,
    data,
  })
}

export function startBilling(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/start`,
    method: 'POST',
    label: START_BILLING,
    data,
    successMessage: 'Subscription Started',
    showToast: true,
  })
}

export function updateBilling(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/change`,
    method: 'POST',
    label: UPDATE_BILLING,
    data,
    successMessage: 'Subscription Updated',
    showToast: true,
  })
}

export function invoiceListing(data) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/invoices`,
    method: 'GET',
    label: INVOICE_LISTING,
    data,
  })
}

export function getSingleInvoice(invoiceId) {
  return apiAction({
    url: `${USER_PLATFORM_V1}/billing/invoices/${invoiceId}`,
    method: 'GET',
    label: GET_SINGLE_INVOICE,
  })
}

export function refreshAccessToken() {
  return apiAction({
    url: `${USER_PLATFORM_V1}/refreshaccesstoken`,
    method: 'GET',
    label: REFRESH_ACCESS_TOKEN,
  })
}
