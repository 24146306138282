import {useEffect, useState} from 'react'
import React, {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoDropdown,
  VmoDropdownDivider,
  VmoDropdownHeader,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoTooltip,
} from 'vmo-library'

import {GET_CAMPAIGN_LIST, GET_PROJECTS_LIST, GET_TEAMS_LIST, GET_USERS} from 'actions/types'
import {updateSelectedAssignee, updateSelectedInbox} from 'actions/analytics_inbox'
import {getUsers} from 'actions/settings'
import {getAllTeams} from 'actions/settings_organisation'
import {getCampaignList} from 'actions/campaign'
import {updateSelectedCampaignId} from 'actions/analytics_campaign'
import {getProjectsList} from 'actions/projects'
import {updateSelectedProject} from 'actions/analytics_projects'
import useApiResponse from 'hooks/impure/useApiResponse'
import {INBOX_TYPES} from 'constants/enums'

export const MailboxDropdown = () => {
  const dispatch = useDispatch()
  const {inbox} = useSelector(state => state.mailInbox)
  const {analyticsSelectedInbox} = useSelector(state => state.analyticsInbox)
  const [mailboxList, setMailboxList] = useState([])

  useEffect(() => {
    if (inbox) {
      const inboxList = inbox
        .filter(mail => (mail.type === INBOX_TYPES.EMAIL ? mail.emailVerified : true))
        .map(({id, name}) => {
          return {
            key: id,
            value: id,
            text: name,
          }
        })
      setMailboxList([{text: 'All', key: 'all', value: 'all'}, ...inboxList])
      if (!analyticsSelectedInbox) dispatch(updateSelectedInbox('all'))
    }
  }, [inbox, analyticsSelectedInbox, dispatch])

  return (
    <VmoTooltip
      position="top center"
      content="Mailbox Filter"
      size="mini"
      trigger={
        <VmoDropdown
          icon="chevron down"
          button
          className="dropdown-ellipsis"
          options={mailboxList}
          value={analyticsSelectedInbox}
          onChange={(e, {value}) => dispatch(updateSelectedInbox(value))}
        />
      }
    />
  )
}

export const AssigneeDropdown = () => {
  const dispatch = useDispatch()

  const [teamList, setTeamList] = useState([])
  const [assigneeList, setAssigneeList] = useState([])
  const [selectedOption, setSelectedOption] = useState('All')

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        image: <div className="userImage">{_.upperCase(user.firstName[0])}</div>,
      }))
      setAssigneeList(list || [])
    },
  })

  useApiResponse({
    action: getAllTeams,
    enabled: true,
    label: GET_TEAMS_LIST,
    storePath: 'settingsOrganisation.teamsList',
    onSuccess: teamsList => {
      const list = teamsList.map(team => ({
        key: team.id,
        value: team.id,
        text: team.name,
      }))
      setTeamList(list || [])
    },
  })

  useEffect(() => {
    dispatch(updateSelectedAssignee({type: 'all', id: 'all'}))
  }, [dispatch])

  return (
    <VmoTooltip
      position="top center"
      size="mini"
      content="Assignee Filter"
      trigger={
        <VmoDropdown className="dropdown-ellipsis" icon="chevron down" button text={selectedOption}>
          <VmoDropdownMenu>
            <VmoDropdownItem
              key="all"
              onClick={() => {
                dispatch(updateSelectedAssignee({type: 'all', id: 'all'}))
                setSelectedOption('All')
              }}
              active={analyticsSelectedAssignee?.id === 'all'}
            >
              All
            </VmoDropdownItem>
            <VmoDropdownDivider />
            <VmoDropdownHeader>Assignees</VmoDropdownHeader>
            {assigneeList.map(assignee => (
              <VmoDropdownItem
                key={assignee.value}
                {...assignee}
                onClick={() => {
                  dispatch(
                    updateSelectedAssignee({
                      type: 'assignee',
                      id: assignee.value,
                    })
                  )
                  setSelectedOption(assignee.text)
                }}
                active={analyticsSelectedAssignee.id === assignee.value}
              />
            ))}
            <VmoDropdownDivider />
            <VmoDropdownHeader>Teams</VmoDropdownHeader>
            {teamList.map((team, index) => (
              <VmoDropdownItem
                key={`${team.value}${index}`}
                {...team}
                onClick={() => {
                  dispatch(updateSelectedAssignee({type: 'team', id: team.value}))
                  setSelectedOption(team.text)
                }}
                active={analyticsSelectedAssignee.id === team.value}
              />
            ))}
          </VmoDropdownMenu>
        </VmoDropdown>
      }
    />
  )
}
export const UserDropdown = () => {
  const dispatch = useDispatch()

  const [assigneeList, setAssigneeList] = useState([])
  const [selectedOption, setSelectedOption] = useState('All')

  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        image: <div className="userImage">{_.upperCase(user.firstName[0])}</div>,
      }))
      setAssigneeList(list || [])
    },
  })

  useEffect(() => {
    dispatch(updateSelectedAssignee({type: 'all', id: 'all'}))
  }, [dispatch])

  return (
    <VmoTooltip
      position="top center"
      size="mini"
      content="Assignee Filter"
      trigger={
        <VmoDropdown className="dropdown-ellipsis" icon="chevron down" button text={selectedOption}>
          <VmoDropdownMenu>
            <VmoDropdownItem
              key="all"
              onClick={() => {
                dispatch(updateSelectedAssignee({type: 'all', id: 'all'}))
                setSelectedOption('All')
              }}
              active={analyticsSelectedAssignee?.id === 'all'}
            >
              All
            </VmoDropdownItem>
            {assigneeList.map(assignee => (
              <VmoDropdownItem
                key={assignee.value}
                {...assignee}
                onClick={() => {
                  dispatch(
                    updateSelectedAssignee({
                      type: 'assignee',
                      id: assignee.value,
                    })
                  )
                  setSelectedOption(assignee.text)
                }}
                active={analyticsSelectedAssignee.id === assignee.value}
              />
            ))}
          </VmoDropdownMenu>
        </VmoDropdown>
      }
    />
  )
}

export const CampaignListDropdown = ({campaignType}) => {
  const dispatch = useDispatch()

  const [list, setList] = useState([])
  const {selectedCampaignId} = useSelector(state => state.analyticsCampaign)

  useApiResponse({
    action: getCampaignList,
    enabled: true,
    label: GET_CAMPAIGN_LIST,
    storePath: 'campaign.campaignList',
    onSuccess: (campaignList = []) => {
      const list =
        campaignList
          ?.filter(c => c.type === campaignType)
          ?.map(obj => {
            return {key: obj.id, value: obj.id, text: obj.name}
          }) || []
      setList([{key: 'all', value: 'all', text: 'All'}, ...list])
    },
  })

  useEffect(() => {
    if (!selectedCampaignId) dispatch(updateSelectedCampaignId('all'))
  }, [selectedCampaignId, dispatch])

  return (
    <VmoTooltip
      position="top center"
      size="mini"
      content="Campaign Filter"
      trigger={
        <VmoDropdown
          icon="chevron down"
          button
          options={list}
          className="dropdown-ellipsis"
          value={selectedCampaignId}
          onChange={(e, {value}) => dispatch(updateSelectedCampaignId(value))}
        />
      }
    />
  )
}

export const ProjectDropdown = () => {
  const dispatch = useDispatch()

  const [projectsList, setProjectsList] = useState([])

  const {analyticsSelectedProject} = useSelector(state => state.analyticsProjects)

  useApiResponse({
    action: getProjectsList,
    enabled: true,
    label: GET_PROJECTS_LIST,
    storePath: 'projects.projectsList',
    onSuccess: (projectsList = []) => {
      const list = projectsList?.map(({id, title}) => ({key: id, value: id, text: title})) || []
      setProjectsList([{text: 'All', key: 'all', value: 'all'}, ...list])

      if (!analyticsSelectedProject) {
        dispatch(updateSelectedProject('all'))
      }
    },
  })

  return (
    <VmoTooltip
      position="top center"
      size="mini"
      content="Project Filter"
      trigger={
        <VmoDropdown
          icon="chevron down"
          button
          options={projectsList}
          className="dropdown-ellipsis"
          value={analyticsSelectedProject}
          onChange={(e, {value}) => dispatch(updateSelectedProject(value))}
        />
      }
    />
  )
}
