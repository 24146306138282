import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {ResponsiveLine} from '@nivo/line'
import {ResponsivePie} from '@nivo/pie'
import Moment from 'moment'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoGridRow, VmoTooltip} from 'vmo-library'

import {
  QUOTES_BY_PRODUCTS,
  QUOTES_OVER_TIME,
  QUOTES_BY_OPEN_DEALS,
  QUOTES_BY_CLOSE_DEALS,
  QUOTES_BY_OWNER,
} from 'actions/types'
import {
  totalQuotesByCloseDeals,
  totalQuotesByOpenDeals,
  totalQuotesByOwner,
  totalQuotesByProducts,
  totalQuotesOvertime,
} from 'actions/reports_quotes'
import {getGridYValues, getGridYValuesBar, maxOfGraph, maxOfGraphBar} from 'utils/graph'
import {get} from 'utils/helper'
import NoDataChart from 'components/common/NoDataChart'
import CustomLoader from 'components/common/CustomLoader'
import {UserDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {DATE_RANGE_KEY} from 'constants/variables'

const QuotesAnalytics = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {
    quotesByProductsData = [],
    quotesByOpenDealsData = [],
    quotesByCloseDealsData = [],
    quotesByOwnerData = [],
    quotesOverTimeData = [],
  } = useSelector(state => state.reportsQuotes)
  const {analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const quotesAccess = analyticsAccess?.quotes
  const revenueReportsPermissions = reportsPermissions?.revenue_reports

  const diff = Moment(get(['persistDateRangeEndDate'], JSON.parse(localStorage.getItem(DATE_RANGE_KEY)))).diff(
    get(['persistDateRangeStartDate'], JSON.parse(localStorage.getItem(DATE_RANGE_KEY))),
    'days'
  )

  const actionsToDispatch = useCallback(
    data => {
      dispatch(totalQuotesOvertime(data))
      dispatch(totalQuotesByProducts(data))
      dispatch(totalQuotesByOwner(data))
      dispatch(totalQuotesByOpenDeals(data))
      dispatch(totalQuotesByCloseDeals(data))
    },
    [dispatch]
  )

  const quotesByOpenDealsGraphData = get(['formatedData'], quotesByOpenDealsData, [])

  const quotesByCloseDealsGraphData = get(['formatedData'], quotesByCloseDealsData, [])

  const quotesOverTimeGraphData = get(['formatedData'], quotesOverTimeData, [])

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/quotes" />
            <h5>Quotes</h5>
          </div>
        </div>
        {quotesAccess && revenueReportsPermissions && (
          <div className="page-action">
            <UserDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
            />
          </div>
        )}
      </div>
      <VmoGrid columns="equal">
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid columns={2}>
                <VmoGridColumn fluid>Quotes Over Time</VmoGridColumn>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Quotes</VmoCardHeader>
                      <h3>{get(['totalQuotes'], quotesOverTimeData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Value</VmoCardHeader>
                      <h3>{get(['totalValue'], quotesOverTimeData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid columns={2}>
                <VmoGridColumn fluid>Quotes Over Time By Open Deals</VmoGridColumn>
                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Quotes</VmoCardHeader>
                      <h3>{get(['totalQuotes'], quotesByOpenDealsData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Value</VmoCardHeader>
                      <h3>{get(['totalValue'], quotesByOpenDealsData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent>
              <VmoGrid columns={2}>
                <VmoGridColumn>Quotes Over Time By Closed Deals</VmoGridColumn>

                <VmoGridRow>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Quotes</VmoCardHeader>
                      <h3>{get(['totalQuotes'], quotesByCloseDealsData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <div className="valueBox">
                      <VmoCardHeader>Total Value</VmoCardHeader>
                      <h3>{get(['totalValue'], quotesByCloseDealsData, '')}</h3>
                    </div>
                  </VmoGridColumn>
                </VmoGridRow>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox mb-0">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Quotes By Products</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of quotes with respective of products"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 337}}>
              {type.includes(QUOTES_BY_PRODUCTS) && <CustomLoader />}
              {!type.includes(QUOTES_BY_PRODUCTS) && !quotesByProductsData.every(({value}) => value === 0) ? (
                <ResponsivePie
                  data={quotesByProductsData}
                  margin={{top: 20, right: 150, bottom: 30, left: 0}}
                  startAngle={-45}
                  innerRadius={0.7}
                  padAngle={2}
                  cornerRadius={0}
                  borderWidth={1}
                  enableArcLinkLabels={false}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 0.2]],
                  }}
                  radialLabelsSkipAngle={10}
                  radialLabelsTextXOffset={6}
                  radialLabelsTextColor="#333333"
                  radialLabelsLinkOffset={0}
                  radialLabelsLinkDiagonalLength={7}
                  radialLabelsLinkHorizontalLength={13}
                  radialLabelsLinkStrokeWidth={1}
                  radialLabelsLinkColor={{from: 'color'}}
                  slicesLabelsSkipAngle={10}
                  slicesLabelsTextColor="#333333"
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                  tooltip={({
                    datum: {
                      data: {id, value, percentage},
                    },
                  }) => (
                    <>
                      <strong>
                        {id}: ${value} ({percentage}%)
                      </strong>
                    </>
                  )}
                  legends={[
                    {
                      anchor: 'right',
                      direction: 'column',
                      justify: false,
                      translateX: 110,
                      translateY: 0,
                      itemsSpacing: 20,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',

                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              ) : (
                !type.includes(QUOTES_BY_PRODUCTS) && <NoDataChart top="35px" />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox mb9px">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Quotes Over Time</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of quotes based on time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(QUOTES_OVER_TIME) && <CustomLoader />}
              {!type.includes(QUOTES_OVER_TIME) && (
                <ResponsiveLine
                  data={quotesOverTimeGraphData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(quotesOverTimeGraphData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableLabel
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: tick => {
                      if (diff >= 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(quotesOverTimeGraphData),
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={7}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(quotesOverTimeGraphData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox mb9px">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Quotes Over Time By Open Deals</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of quotes based on open deals"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(QUOTES_BY_OPEN_DEALS) && <CustomLoader />}
              {!type.includes(QUOTES_BY_OPEN_DEALS) && (
                <ResponsiveLine
                  data={quotesByOpenDealsGraphData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(quotesByOpenDealsGraphData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: tick => {
                      if (diff >= 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(quotesByOpenDealsGraphData),
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={7}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(quotesByOpenDealsGraphData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox mb9px">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Quotes Over Time By Closed Deals</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of quotes based on time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(QUOTES_BY_CLOSE_DEALS) && <CustomLoader />}
              {!type.includes(QUOTES_BY_CLOSE_DEALS) && (
                <ResponsiveLine
                  data={quotesByCloseDealsGraphData}
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  xScale={{type: 'point'}}
                  yScale={{
                    type: 'linear',
                    min: 0,
                    max: maxOfGraph(quotesByCloseDealsGraphData),
                    stacked: false,
                    reverse: false,
                  }}
                  enableGridX={false}
                  curve="monotoneX"
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    format: tick => {
                      if (diff >= 0 && diff < 22) {
                        return tick
                      }
                      return Number(tick.match(/\d+/)) % 2 ? '' : tick
                    },
                    legend: 'Time',
                    legendOffset: 40,
                    legendPosition: 'middle',
                  }}
                  axisLeft={{
                    orient: 'left',
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValues(quotesByCloseDealsGraphData),
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle',
                  }}
                  // colors={(d) => d.color}
                  lineWidth={3}
                  pointSize={7}
                  pointColor={{theme: 'grid.line.stroke'}}
                  pointBorderWidth={2}
                  pointBorderColor={{from: 'serieColor'}}
                  pointLabel="y"
                  pointLabelYOffset={-10}
                  useMesh
                  enableSlices="x"
                  gridYValues={getGridYValues(quotesByCloseDealsGraphData)}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox mb9px">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Quotes By Owner</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of all quotes with respect to owners over a time period"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 330}}>
              {type.includes(QUOTES_BY_OWNER) && <CustomLoader />}
              {!type.includes(QUOTES_BY_OWNER) && (
                <ResponsiveBar
                  data={quotesByOwnerData}
                  indexBy="name"
                  margin={{top: 27, right: 27, bottom: 60, left: 60}}
                  padding={0.3}
                  colors={{scheme: 'nivo'}}
                  enableLabel
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  minValue={0}
                  maxValue={maxOfGraphBar(quotesByOwnerData, 'value')}
                  gridYValues={getGridYValuesBar(quotesByOwnerData, 'value')}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'name',
                    legendPosition: 'middle',
                    legendOffset: 40,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesBar(quotesByOwnerData, 'value'),
                    legend: 'Value',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  tooltip={({value, data: {count}}) => (
                    <div className="customTooltip">
                      <span />
                      <strong className="pl-2">
                        value : ${value} , count : {count}
                      </strong>
                    </div>
                  )}
                  legends={[
                    {
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 73,
                      itemHeight: 23,
                      itemOpacity: 0.75,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000',
                          },
                        },
                      ],
                    },
                  ]}
                  animate
                  motionStiffness={90}
                  motionDamping={15}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}
export default QuotesAnalytics
