import {GET_PROJECTS_CATEGORIES, GET_PROJECTS_SINGLE_CATEGORY} from 'actions/types'

export default function (state = {}, action) {
  const {type, payload} = action

  switch (type) {
    case GET_PROJECTS_CATEGORIES:
      return {...state, projectsCategories: payload}

    case GET_PROJECTS_SINGLE_CATEGORY:
      return {...state, projectsCategoryData: payload}

    default:
      return state
  }
}
