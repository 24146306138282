/* eslint-disable no-restricted-syntax */
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import _ from 'lodash'
import {joiResolver} from '@hookform/resolvers'
import {VmoButton, VmoTab} from 'vmo-library'
import {GET_ALL_ADDRESSESS, GET_SALES_ORDERS_DATA, UPDATE_SALES_ORDERS} from 'actions/types'
import {getSalesOrdersData, updateSalesOrders} from 'actions/salesOrders'
import {get} from 'utils/helper'
import {getAllAddressess} from 'actions/settings_organisation'
import {salesOrdersEditSchema} from 'validation/Finance/salesOrders.schema'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useApiResponse from 'hooks/impure/useApiResponse'
import Setup from './SalesOrdersForm/Setup'
import Product from './SalesOrdersForm/Product'
import Customization from './SalesOrdersForm/Customization'
import SalesOrderModal from './SalesOrdersForm/SalesOrderModal'
import PreviewSalesOrder from './Templates/PreviewSalesOrder'

const salesOrdersEditDefaultValues = {
  assigneeId: null,
  soNumber: '',
  prefixId: '',
  subject: '',
  customerNumber: '',
  purchaseOrderNumber: '',
  quoteId: '',
  contactId: null,
  pending: null,
  dueDate: null,
  exciseDuty: '',
  salesCommission: '',
  carrier: '',
  status: '',
  description: '',
  billingAddress: {},
  shippingAddress: {},
  products: [],
  subTotal: 0,
  discount: 0,
  tax: 0,
  adjustment: 0,
  total: 0,
  terms: '',
  template: {templateNo: 2, color: '#1e90ff', header: '', footer: ''},
}

const steps = [{name: 'Setup'}, {name: 'Product'}, {name: 'Customization'}, {name: 'Preview'}]

function SalesOrdersEdit() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {goBack} = useHistory()
  const {id: editId} = useParams()

  const [index, setIndex] = useState(0)
  const [dueDate, setDueDate] = useState(null)
  const [discountValue, setDiscountValue] = useState(0)
  const [taxValue, setTaxValue] = useState(0)
  const [adjustmentValue, setAdjustmentValue] = useState(0)
  const [searchValue, setSearchValue] = useState([])
  const [showSoModal, setShowSoModal] = useState(false)
  const [addressTemplate, setAddressTemplate] = useState()

  const {salesOrdersData = {}, productsData = [], soPublicLink} = useSelector(state => state.salesOrders)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {selectedFile} = useSelector(state => state.salesOrders.uploadFile)
  const {financePermissions} = useUserPermissions()
  const manageSalesOrdersPermissions = financePermissions?.manage_sales_orders

  const {handleSubmit, errors, clearErrors, control, watch, setValue, reset} = useForm({
    mode: 'onTouched',
    shouldUnregister: false,
    shouldFocusError: true,
    reValidateMode: 'onChange',
    defaultValues: salesOrdersEditDefaultValues,
    resolver: joiResolver(salesOrdersEditSchema),
  })

  const {data: allAddressess = []} = useApiResponse({
    action: getAllAddressess,
    enabled: true,
    label: GET_ALL_ADDRESSESS,
    storePath: 'settingsOrganisation.allAddressess',
  })

  useEffect(() => {
    if (editId) dispatch(getSalesOrdersData(editId))

    return () => {
      dispatch({type: 'CLEAR_SO_FORM'})
      dispatch({type: 'CLEAR_SO_DETAILS'})
      dispatch({type: 'CLEAR_SO_UPLOADFILE'})
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SALES_ORDERS_DATA)) {
      const {
        soNumber,
        subject,
        assigneeId,
        customerNumber,
        quoteId,
        contactId,
        pending,
        dueDate,
        carrier,
        exciseDuty,
        salesCommission,
        status,
        billingAddress,
        shippingAddress,
        description,
        products,
        subTotal,
        total,
        terms,
        template,
      } = salesOrdersData
      if (template) {
        delete template?.id
        delete template?.accountId
        delete template?.soId
        delete template?.logo
        delete template?.paymentProcess
      }
      setAddressTemplate(
        allAddressess?.filter(({id}) => {
          return id === template?.addressId
        })
      )
      reset({
        soNumber,
        prefixId: get(['prefixId'], salesOrdersData, ''),
        subject,
        assigneeId,
        customerNumber,
        purchaseOrderNumber: get(['purchaseOrderNumber'], salesOrdersData, ''),
        quoteId,
        contactId,
        pending,
        dueDate,
        carrier,
        exciseDuty,
        salesCommission,
        status,
        billingAddress,
        shippingAddress,
        description,
        products,
        subTotal,
        total,
        terms,
        template,
        discount: get(['discount'], salesOrdersData, null),
        tax: get(['tax'], salesOrdersData, null),
        adjustment: get(['adjustment'], salesOrdersData, null),
      })
      setSearchValue(
        productsData?.map(
          ({productId, productName: title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}) => {
            return {productId, title, unitAmount, quantity, unitPrice, taxValue, discountValue, unitTotal}
          }
        )
      )
      setDiscountValue(salesOrdersData.discount)
      setTaxValue(salesOrdersData.tax)
      setDueDate(salesOrdersData.dueDate)
      setAdjustmentValue(salesOrdersData.adjustment)
    }
  }, [successLabels, salesOrdersData, productsData, reset])

  useEffect(() => {
    if (salesOrdersData?.template?.logo) {
      // const [, setPreview] = preview;
      // setPreview(previewLogo);
      dispatch({type: 'SET_SO_PREVIEW_FILE', payload: salesOrdersData?.template?.logo})
      // dispatch({type: 'SET_SO_SELECTED_FILE', payload: 'logo'})
    }
  }, [salesOrdersData])

  useEffect(() => {
    if (salesOrdersData.id) {
      if (salesOrdersData?.soNumber) {
        dispatch({
          type: 'SETUP_SUBMIT',
          payload: {
            soNumber: salesOrdersData?.soNumber,
            poDate: salesOrdersData?.soDate,
            status: salesOrdersData?.status,
            billingAddress: salesOrdersData?.billingAddress,
            shippingAddress: salesOrdersData?.shippingAddress,
            description: salesOrdersData?.description,
            carrier: salesOrdersData?.carrier,
          },
        })
      }

      if (productsData) {
        dispatch({
          type: 'PRODUCT_HANDLE_SUBMIT',
          payload: {
            products: productsData,
            total: salesOrdersData?.total,
            subTotal: salesOrdersData?.subTotal,
            discount: salesOrdersData?.discount,
            tax: salesOrdersData?.tax,
            adjustment: salesOrdersData?.adjustment,
            terms: salesOrdersData?.terms,
          },
        })
      }

      if (salesOrdersData?.template) {
        dispatch({
          type: 'CUSTOMIZATION_SUBMIT',
          payload: {
            template: salesOrdersData?.template,
          },
        })
      }
    }
  }, [salesOrdersData, productsData, dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_SALES_ORDERS)) {
      // history.push('/sales-orders')
      setShowSoModal(true)
      dispatch({type: 'CLEAR_SO_FORM'})
      dispatch({type: 'CLEAR_SO_DETAILS'})
      dispatch({type: 'CLEAR_SO_UPLOADFILE'})
    }
  }, [successLabels, history])

  const submitForm = data => {
    const formData = new FormData()

    const payload = {...data}

    if (selectedFile && typeof selectedFile !== 'string') {
      formData.append('logo', selectedFile)
    }

    const newList = _.omitBy(payload, value => !!(value === '' || value === null || _.isEqual(value, [])))

    for (const key in newList) {
      if (key === 'products') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'shippingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'billingAddress') {
        formData.append(key, JSON.stringify(newList[key]))
      } else if (key === 'template') {
        formData.append(key, JSON.stringify(newList[key]))
      } else {
        formData.append(key, newList[key])
      }
    }
    dispatch(updateSalesOrders(editId, formData))
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <div>
          <VmoButton primary onClick={() => history.push('/sales-orders')}>
            Exit
          </VmoButton>
          {index > 0 && (
            <VmoButton className="ml-2" primary onClick={() => setIndex(index - 1)}>
              Back
            </VmoButton>
          )}
        </div>
        <VmoTab
          panes={steps.map(item => ({
            menuItem: {
              key: item.name,
              icon: item.icon,
              content: item.name,
            },
            render: null,
          }))}
          menu={{
            secondary: true,
            pointing: true,
            color: 'blue',
          }}
          activeIndex={index}
          onTabChange={(e, {activeIndex}) => {
            setIndex(activeIndex)
          }}
          renderActiveOnly={false}
        />
        <LockPermissionTooltip isRoleAccessDenied={!manageSalesOrdersPermissions}>
          <VmoButton
            primary
            className={!manageSalesOrdersPermissions ? 'disabled-button' : ''}
            {...(manageSalesOrdersPermissions && {
              onClick: handleSubmit(submitForm),
            })}
          >
            Save
          </VmoButton>
        </LockPermissionTooltip>
      </div>
      <Setup
        style={{display: index === 0 ? '' : 'none'}}
        control={control}
        errors={errors}
        clearErrors={clearErrors}
        watch={watch}
        setValue={setValue}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />
      <Product
        style={{display: index === 1 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        reset={reset}
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        taxValue={taxValue}
        setTaxValue={setTaxValue}
        adjustmentValue={adjustmentValue}
        setAdjustmentValue={setAdjustmentValue}
      />

      <Customization
        style={{display: index === 2 ? '' : 'none'}}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        editId={editId}
        addressTemplate={addressTemplate}
        setAddressTemplate={setAddressTemplate}
      />

      <PreviewSalesOrder style={{display: index === 3 ? '' : 'none'}} />

      {showSoModal && (
        <SalesOrderModal
          link={soPublicLink}
          open={showSoModal}
          toggle={() => {
            setShowSoModal(false)
            goBack()
          }}
        />
      )}
    </div>
  )
}

export default SalesOrdersEdit
