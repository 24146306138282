import React, {useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {FaGlobe} from 'react-icons/fa'
import {FiChevronRight} from 'react-icons/fi'
import {VmoMenu, VmoMenuItem, VmoAccordion, VmoAccordionContent, VmoAccordionTitle} from 'vmo-library'
import PermissionIcon from 'components/common/PermissionIcon'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'

export function SettingMenu() {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])
  // const [activeIndex, setActiveIndex] = useState({
  //   setting: true,
  //   organization: false,
  //   channels: false,
  //   messenger: false,
  //   automations: false,
  //   productivity: false,
  //   sales: false,
  //   dataManagement: false,
  //   customization: false,
  //   projects: false,
  //   customerSatisfaction: false,
  //   billing: false,
  //   apiAndIntegrations: false,
  // })

  const {
    clientManagementAccess,
    salesAndCrmAccess,
    projectsAndCollaborationAccess,
    workflowAndAutomationAccess,
    generalAccess,
    teamAndUsersAccess,
    customizationPermissions,
    productivityPermissions,
    channelsPermissions,
    dealsPermissions,
    meetingsPermissions,
    quotesPermissions,
    salesPermissions,
    tasksPermissions,
    automationPermissions,
    projectsPermissions,
    myOrganizationDefaultPermissions,
    manageBillingDefaultPermissions,
  } = useUserPermissions()
  const contactFormsAccess = clientManagementAccess?.contactForms
  const importAndExportAccess = salesAndCrmAccess?.importAndExport
  const quotesAccess = salesAndCrmAccess?.quotes
  const meetingsAccess = salesAndCrmAccess?.meetings
  const scoringRulesAccess = salesAndCrmAccess?.scoringRules
  const timeTrackingAccess = clientManagementAccess?.timeTracking
  const projectsAccess = projectsAndCollaborationAccess?.projects
  const slaManagementAccess = workflowAndAutomationAccess?.slaManagement
  const taskTemplatesAccess = workflowAndAutomationAccess?.taskTemplates
  const auditLogsAccess = workflowAndAutomationAccess?.auditLogs
  const chatSurveysAccess = generalAccess?.chatSurveys
  const dataManageCustomizationAccess = generalAccess?.dataManageCustomization
  const customRolesAndPermisionAccess = teamAndUsersAccess?.customRolesAndPermision
  const manageContactStatusPermissions = customizationPermissions?.manage_contact_status
  const manageDealSourcesPermissions = customizationPermissions?.manage_deal_sources
  const manageContactTypesPermissions = customizationPermissions?.manage_contact_types
  const manageLossReasonsPermissions = customizationPermissions?.manage_loss_reasons
  const manageCannedReponsesPermissions = productivityPermissions?.manage_canned_reponses
  const auditLogsPermissions = productivityPermissions?.audit_logs
  const customerSatficationsPermissions = productivityPermissions?.customer_satfication
  const manageEmailsPermissions = channelsPermissions?.manage_emails
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages
  const manageContactFormPermissions = channelsPermissions?.manage_contact_forms
  const manageFacebookPermissions = channelsPermissions?.manage_facebook
  const manageSmsPermissions = channelsPermissions?.manage_sms
  const managePipelinePermissions = dealsPermissions?.manage_pipeline
  const manageMeetingsPermissions = meetingsPermissions?.manage_meetings
  const manageQuoteSettingsPermissions = quotesPermissions?.manage_quote_settings
  const manageForecastingSettingPermissions = salesPermissions?.manage_forecasting_settings
  const manageScoringRulesPermissions = salesPermissions?.manage_scoring_rules
  const manageCurrenciesPermissions = salesPermissions?.manage_currencies
  const manageTaskTemplatesPermissions = tasksPermissions?.manage_task_templates
  const manageBussinesHoursPermissions = automationPermissions?.manage_bussines_hours
  const manageRoutingRulesPermissions = automationPermissions?.manage_routing_rules
  const manageSlaPermissions = automationPermissions?.manage_sla
  const manageProjectSettingsPermissions = projectsPermissions?.manage_project_settings
  const manageRetainerSettingsPermissions = projectsPermissions?.manage_retainer_settings

  const menus = [
    {
      key: 'setting',
      title: 'My Settings',
      items: [
        {
          path: '/my-profile',
          title: 'My Profile',
          icon: <SvgIcon path="settings/my-profile" />,
        },
        {
          path: '/notification',
          title: 'Notifications',
          icon: <SvgIcon path="settings/notification" />,
        },
      ],
    },
    {
      key: 'organization',
      title: 'My Organization',
      items: [
        {
          path: '/account-settings',
          title: 'Account Settings',
          icon: <SvgIcon path="settings/account-setting" />,
          isRoleAccessDenied: !myOrganizationDefaultPermissions,
        },
        {
          path: '/users',
          title: 'Users',
          icon: <SvgIcon path="settings/users" />,
          isRoleAccessDenied: !myOrganizationDefaultPermissions,
        },
        {
          path: '/teams',
          title: 'Teams',
          icon: <SvgIcon path="settings/teams" />,
          isRoleAccessDenied: !myOrganizationDefaultPermissions,
        },
        {
          path: '/roles',
          title: 'Roles',
          icon: <SvgIcon path="settings/roles" />,
          isFeatureAccessDenied: !customRolesAndPermisionAccess,
          isRoleAccessDenied: !myOrganizationDefaultPermissions,
        },
        {
          path: '/holidays',
          title: 'Holidays',
          // icon: <SvgIcon path="settings/holidays" />,
          icon: null,
        },
      ],
    },
    {
      key: 'channels',
      title: 'Channels',
      items: [
        {
          path: '/chat-messenger',
          title: 'Chat Messenger',
          icon: <SvgIcon path="settings/chat-messenger" />,
          isFeatureAccessDenied: !manageMessangerPagesPermissions,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
        {
          path: '/chat-pages',
          title: 'Chat Pages',
          icon: <SvgIcon path="settings/chat-pages" />,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
        {
          path: '/emails',
          title: 'Email',
          icon: <SvgIcon path="common/mail" />,
          isRoleAccessDenied: !manageEmailsPermissions,
        },
        {
          path: '/sms',
          title: 'SMS',
          icon: <SvgIcon path="settings/sms" />,
          isRoleAccessDenied: !manageSmsPermissions,
        },
        /*        {
          path: '/twitter',
          title: 'Twitter',
          icon: <SvgIcon path="settings/twitter" />,
          isRoleAccessDenied: !manageTwitterPermissions,
        }, */
        {
          path: '/facebook',
          title: 'Facebook',
          icon: <SvgIcon path="settings/facebook" />,
          isRoleAccessDenied: !manageFacebookPermissions,
        },
        {
          path: '/contact-form',
          title: 'Contact Form',
          icon: <SvgIcon path="settings/contact-form" />,
          isFeatureAccessDenied: !contactFormsAccess,
          isRoleAccessDenied: !manageContactFormPermissions,
        },
      ],
    },
    {
      key: 'messenger',
      title: 'Messenger',
      items: [
        {
          path: '/appearance',
          title: 'Appearance',
          icon: <SvgIcon path="settings/appearance" />,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
        {
          path: '/configure',
          title: 'Configure',
          icon: <SvgIcon path="settings/configure" />,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
        {
          path: '/advanced',
          title: 'Advanced',
          icon: <SvgIcon path="settings/advanced" />,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
        {
          path: '/offline-experience',
          title: 'Offline Experience',
          icon: <SvgIcon path="settings/offline-experience" />,
          isRoleAccessDenied: !manageMessangerPagesPermissions,
        },
      ],
    },
    {
      key: 'automations',
      title: 'Automations',
      items: [
        {
          path: '/routing',
          title: 'Routing',
          icon: <SvgIcon path="settings/routing" />,
          isRoleAccessDenied: !manageRoutingRulesPermissions,
        },
        {
          path: '/business-hours',
          title: 'Business Hours',
          icon: <SvgIcon path="settings/business-hours" />,
          isRoleAccessDenied: !manageBussinesHoursPermissions,
        },
        {
          path: '/sla-policy',
          title: 'SLA Management',
          icon: <SvgIcon path="settings/sla-management" />,
          isFeatureAccessDenied: !slaManagementAccess,
          isRoleAccessDenied: !manageSlaPermissions,
        },
        {
          path: '/time-tracking',
          title: 'Time Tracking',
          icon: <SvgIcon path="settings/time-tracking" />,
          isFeatureAccessDenied: !timeTrackingAccess,
        },
      ],
    },
    {
      key: 'productivity',
      title: 'Productivity',
      items: [
        {
          path: '/tags',
          title: 'Tags',
          icon: <SvgIcon path="settings/tags" />,
        },
        {
          path: '/campaign-template',
          title: 'Campaign Templates',
          icon: <SvgIcon path="settings/campaign-templates" />,
        },
        {
          path: '/email-templates',
          title: 'Email Templates',
          icon: <SvgIcon path="settings/canned-response" />,
          isRoleAccessDenied: !manageCannedReponsesPermissions,
        },
        {
          path: '/task-template',
          title: 'Task Templates',
          icon: <SvgIcon path="settings/task-templates" />,
          isFeatureAccessDenied: !taskTemplatesAccess,
          isRoleAccessDenied: !manageTaskTemplatesPermissions,
        },
        {
          path: '/trusted-domain',
          title: 'Trusted Domain',
          icon: <FaGlobe />,
        },
        {
          path: '/audit-logs',
          title: 'Audit Logs',
          icon: <SvgIcon path="settings/audit-logs" />,
          isFeatureAccessDenied: !auditLogsAccess,
          isRoleAccessDenied: !auditLogsPermissions,
        },
      ],
    },
    {
      key: 'sales',
      title: 'Sales',
      items: [
        {
          path: '/currencies',
          title: 'Currencies',
          icon: <SvgIcon path="settings/currencies" />,
          isRoleAccessDenied: !manageCurrenciesPermissions,
        },
        {
          path: '/goals',
          title: 'Forecasting',
          icon: <SvgIcon path="settings/forecasting" />,
          isRoleAccessDenied: !manageForecastingSettingPermissions,
        },
        {
          path: '/meetings',
          title: 'Meetings',
          icon: <SvgIcon path="settings/meetings" />,
          isFeatureAccessDenied: !meetingsAccess,
          isRoleAccessDenied: !manageMeetingsPermissions,
        },
        {
          path: '/scoring',
          title: 'Scoring Rules',
          icon: <SvgIcon path="settings/scoring-rules" />,
          isFeatureAccessDenied: !scoringRulesAccess,
          isRoleAccessDenied: !manageScoringRulesPermissions,
        },
      ],
    },
    {
      key: 'dataManagement',
      title: 'Data Management',
      items: [
        {
          key: 'customization',
          title: 'Customization',
          items: [
            {
              path: '/contact-status',
              title: 'Contact Status',
              icon: <SvgIcon path="settings/contact-status" />,
              isFeatureAccessDenied: !dataManageCustomizationAccess,
              isRoleAccessDenied: !manageContactStatusPermissions,
            },
            {
              path: '/contact-types',
              title: 'Contact Types',
              icon: <SvgIcon path="settings/contact-types" />,
              isFeatureAccessDenied: !dataManageCustomizationAccess,
              isRoleAccessDenied: !manageContactTypesPermissions,
            },
            {
              path: '/sources',
              title: 'Deals Sources',
              // icon: <SvgIcon path="settings/deals-sources" />,
              icon: null,
              isFeatureAccessDenied: !dataManageCustomizationAccess,
              isRoleAccessDenied: !manageDealSourcesPermissions,
            },
            {
              path: '/loss-reasons',
              title: 'Loss Reasons',
              icon: <SvgIcon path="settings/loss-reasons" />,
              isFeatureAccessDenied: !dataManageCustomizationAccess,
              isRoleAccessDenied: !manageLossReasonsPermissions,
            },
            {
              path: '/deals',
              title: 'Deals',
              icon: <SvgIcon path="settings/deals" />,
              isFeatureAccessDenied: !dataManageCustomizationAccess,
              isRoleAccessDenied: !managePipelinePermissions,
            },
          ],
        },
        {
          path: '/activities',
          title: 'Activities',
          icon: <SvgIcon path="settings/activities" />,
        },
        {
          path: '/quotes-prefix',
          title: 'Quotes',
          icon: <SvgIcon path="settings/quotes" />,
          isFeatureAccessDenied: !quotesAccess,
          isRoleAccessDenied: !manageQuoteSettingsPermissions,
        },
        {
          path: '/import',
          title: 'Import',
          icon: <SvgIcon path="settings/import" />,
          isFeatureAccessDenied: !importAndExportAccess,
        },
        {
          path: '/export',
          title: 'Export',
          icon: <SvgIcon path="settings/export" />,
          isFeatureAccessDenied: !importAndExportAccess,
        },
      ],
    },
    {
      key: 'projects',
      title: 'Projects',
      items: [
        {
          path: '/project-categories',
          title: 'Project Categories',
          icon: <SvgIcon path="settings/project-categories" />,
          isFeatureAccessDenied: !projectsAccess,
          isRoleAccessDenied: !manageProjectSettingsPermissions,
        },
      ],
    },
    {
      key: 'billing',
      title: 'Billing',
      items: [
        {
          path: '/my-plan',
          title: 'My Plan',
          icon: <SvgIcon path="settings/my-plan" />,
          isRoleAccessDenied: !manageBillingDefaultPermissions,
        },
        {
          path: '/invoices',
          title: 'Invoices',
          icon: <SvgIcon path="settings/invoice" />,
        },
      ],
    },
    {
      key: 'apiAndIntegrations',
      title: 'API & Integrations',
      items: [
        {
          path: '/integrations',
          title: 'Integrations',
          icon: <SvgIcon path="settings/integrations" />,
        },
        {
          path: '/developer-api',
          title: 'Developer API',
          icon: <SvgIcon path="settings/developer-api" />,
        },
        {
          path: '/webhooks',
          title: 'Webhooks',
          icon: <SvgIcon path="settings/webhooks" />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <>
      <VmoMenu vertical secondary className="kooper-menu scrollbar-setting">
        {menus.map(({key, title, items = []}) => (
          <VmoAccordion key={key}>
            <VmoAccordionTitle onClick={() => handleMenuExpand(key)}>
              <div className="d-flex-ac">
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
              </div>
            </VmoAccordionTitle>
            <VmoAccordionContent active={isActive(key)}>
              {items.map(
                ({
                  path,
                  title,
                  icon,
                  isFeatureAccessDenied = false,
                  isRoleAccessDenied = false,
                  key: itemKey,
                  items: subItems = [],
                }) =>
                  subItems.length ? (
                    <VmoAccordion key={itemKey}>
                      <VmoAccordionTitle onClick={() => handleMenuExpand(itemKey)}>
                        <VmoMenuItem action tag="a" name="teammatesCollapse">
                          {isActive(itemKey) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
                        </VmoMenuItem>
                      </VmoAccordionTitle>
                      <VmoAccordionContent active={isActive(itemKey)}>
                        {subItems.map(
                          ({path, title, icon, isFeatureAccessDenied = false, isRoleAccessDenied = false}) => (
                            <VmoMenuItem
                              key={path}
                              active={isActiveNavlink(`${url}${path}`)}
                              onClick={() => history.push(`${url}${path}`)}
                            >
                              {icon}
                              {title}
                              {(isFeatureAccessDenied || isRoleAccessDenied) && (
                                <span>
                                  <PermissionIcon
                                    isFeatureAccessDenied={isFeatureAccessDenied}
                                    isRoleAccessDenied={isRoleAccessDenied}
                                  />
                                </span>
                              )}
                            </VmoMenuItem>
                          )
                        )}
                      </VmoAccordionContent>
                    </VmoAccordion>
                  ) : (
                    <VmoMenuItem
                      key={path}
                      active={isActiveNavlink(`${url}${path}`)}
                      onClick={() => history.push(`${url}${path}`)}
                    >
                      {icon}
                      {title}
                      {(isFeatureAccessDenied || isRoleAccessDenied) && (
                        <span>
                          <PermissionIcon
                            isFeatureAccessDenied={isFeatureAccessDenied}
                            isRoleAccessDenied={isRoleAccessDenied}
                          />
                        </span>
                      )}
                    </VmoMenuItem>
                  )
              )}
            </VmoAccordionContent>
          </VmoAccordion>
        ))}
      </VmoMenu>
    </>
  )
}
