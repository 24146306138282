import React from 'react'
import SvgIcon from 'components/common/SvgIcon'
import {VmoButton, VmoModal, VmoModalActions, VmoModalContent, VmoModalHeader} from 'vmo-library'

const UnsavedChanges = props => {
  const {cleanUp} = props

  return (
    <VmoModal
      size="tiny"
      dimmer="blurring"
      open
      centered={false}
      closeIcon={
        <SvgIcon
          path="common/close"
          className="closeIcon"
          onClick={() => {
            cleanUp(false)
          }}
        />
      }
    >
      <VmoModalHeader className="vmoModalHeader">Warning</VmoModalHeader>
      <VmoModalContent>
        <h5>There are some unsaved changes !</h5>
        <p className="kooper-lead">Do you still wish to leave?</p>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          basic
          onClick={() => {
            cleanUp(false)
          }}
        >
          No
        </VmoButton>
        <VmoButton
          primary
          content="Yes"
          onClick={() => {
            cleanUp(true)
          }}
        />
      </VmoModalActions>
    </VmoModal>
  )
}

export default UnsavedChanges
