/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoAttachment,
  VmoCard,
  VmoCardContent,
  VmoColorPicker,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoTooltip,
} from 'vmo-library'

import {GET_CLIENT_PORTAL_SETTINGS, UPDATE_CLIENT_PORTAL_SETTINGS, UPLOAD_CLIENT_PORTAL_LOGO} from 'actions/types'
import {getClientPortalSettings, updateClientPortalSettings, uploadClientPortalLogo} from 'actions/client-portal'
import {get, removeDoubleQuotes} from 'utils/helper'
import SettingLayout from 'layouts/settingLayout'
import {customizationSchema} from 'validation/Customers/clientPortal.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const customizationDefaultValues = {
  heading: '',
  subHeading: '',
  logo: '',
  backgroundColor: '#2287FB',
  textColor: '#2287FB',
  textOnPortal: '',
  textOnLoginPage: '',
}

const Customization = () => {
  const dispatch = useDispatch()

  const [isTextColorPickerOpen, setIsTextColorPickerOpen] = useState(false)
  const [isBackGroundColorPickerOpen, setIsBackGroundColorPickerOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {generalSetting = {}, logo} = useSelector(state => state.clientPortal)

  const {clientPortalPermissions} = useUserPermissions()
  const settingsPermissions = clientPortalPermissions?.settings

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(customizationSchema),
    defaultValues: customizationDefaultValues,
  })

  useEffect(() => {
    dispatch(getClientPortalSettings())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(UPDATE_CLIENT_PORTAL_SETTINGS)) {
      dispatch(getClientPortalSettings())
    }
  }, [successLabels, dispatch])

  const resetData = useCallback(
    setting => {
      const data = {
        heading: get(['heading'], setting, ''),
        subHeading: get(['subHeading'], setting, ''),
        logo: get(['logo'], setting, ''),
        backgroundColor: get(['backgroundColor'], setting, '#2287FB'),
        textColor: get(['textColor'], setting, '#2287FB'),
        textOnPortal: get(['textOnPortal'], setting, ''),
        textOnLoginPage: get(['textOnLoginPage'], setting, ''),
      }
      reset(data)
    },
    [reset]
  )

  useEffect(() => {
    if (successLabels.includes(GET_CLIENT_PORTAL_SETTINGS)) {
      resetData(generalSetting)
    }
  }, [successLabels, generalSetting, resetData])

  useEffect(() => {
    if (successLabels.includes(UPLOAD_CLIENT_PORTAL_LOGO)) {
      if (logo) {
        setValue('logo', logo)
      }
    }
  }, [successLabels, logo, setValue])

  return (
    <SettingLayout
      header="Customization"
      subHeader="Manage all customization settings related to clients portal"
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          disabled: !isDirty,
          onClick: () => resetData(generalSetting),
        },
        success: {
          content: 'Update',
          disabled: !isDirty,
          ...(settingsPermissions && {
            onClick: handleSubmit(data => dispatch(updateClientPortalSettings(data))),
          }),
        },
      }}
      lockRole={!settingsPermissions}
    >
      <div>
        <VmoCard fluid>
          <VmoCardContent>
            <div>
              <h5 className="mb-1">Portal Branding</h5>
              <p className="kooper-lead">Customize your client portal to match your brand</p>
            </div>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField width={8}>
                <div className="info-header">
                  <label htmlFor="">Heading</label>
                  <VmoTooltip
                    content="Specify a heading for your portal"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="heading"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      maxLength={25}
                      placeholder="Heading"
                      fluid
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.heading && {content: removeDoubleQuotes(errors.heading.message)}}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField width={8}>
                <div className="info-header">
                  <label htmlFor="">Subheading</label>
                  <VmoTooltip
                    content="Specify a subheading for your portal"
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="subHeading"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      maxLength={50}
                      placeholder="Subheading"
                      fluid
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.subHeading && {content: removeDoubleQuotes(errors.subHeading.message)}}
                    />
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <Controller name="logo" control={control} render={null} />
                <label>Logo</label>
                <div className="custom-upload-btn">
                  <LockPermissionTooltip isRoleAccessDenied={!settingsPermissions}>
                    {settingsPermissions ? (
                      <VmoAttachment
                        basic
                        type="file"
                        content={
                          <>
                            <SvgIcon path="common/attachment" /> Upload Logo
                          </>
                        }
                        accept=".jpeg,.png,.jpg"
                        onChange={value => {
                          const data = {
                            file: value,
                          }

                          if (value.length > 0) {
                            dispatch(uploadClientPortalLogo(data))
                          }
                        }}
                        onClick={e => {
                          e.target.value = null
                        }}
                        maxSize={5120}
                      />
                    ) : (
                      <button type="button" className="ui icon button attach">
                        <SvgIcon path="common/attachment" /> Upload Logo
                      </button>
                    )}
                  </LockPermissionTooltip>
                </div>
                <p className="kooper-lead pt-3">Supports JPEG and PNG of max 5MB Scales upto 128px*32px.</p>
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Background color</label>
                  <VmoTooltip
                    content="Select background color for your portal"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="backgroundColor"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <div
                        style={{
                          padding: 5,
                          background: '#fff',
                          borderRadius: '1px',
                          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsBackGroundColorPickerOpen(!isBackGroundColorPickerOpen)}
                      >
                        <div
                          style={{
                            width: '80px',
                            height: '15px',
                            borderRadius: '2px',
                            background: value,
                          }}
                        />
                      </div>
                      {isBackGroundColorPickerOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: '2',
                          }}
                        >
                          <div
                            style={{
                              position: 'fixed',
                              top: '0px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px',
                            }}
                            onClick={() => setIsBackGroundColorPickerOpen(!isBackGroundColorPickerOpen)}
                          />
                          <VmoColorPicker color={value} onChange={value => onChange(value.hex)} />
                        </div>
                      )}
                    </>
                  )}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Text color</label>
                  <VmoTooltip
                    content="Specify the text color of the client portal"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <Controller
                  name="textColor"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <div
                        style={{
                          padding: 5,
                          background: '#fff',
                          borderRadius: '1px',
                          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                        onClick={() => setIsTextColorPickerOpen(!isTextColorPickerOpen)}
                      >
                        <div
                          style={{
                            width: '80px',
                            height: '15px',
                            borderRadius: '2px',
                            background: value,
                          }}
                        />
                      </div>
                      {isTextColorPickerOpen && (
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: '2',
                          }}
                        >
                          <div
                            style={{
                              position: 'fixed',
                              top: '0px',
                              right: '0px',
                              bottom: '0px',
                              left: '0px',
                            }}
                            onClick={() => setIsTextColorPickerOpen(!isTextColorPickerOpen)}
                          />
                          <VmoColorPicker color={value} onChange={value => onChange(value.hex)} />
                        </div>
                      )}
                    </>
                  )}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <div className="client-portal-login">
          <VmoCard fluid>
            <VmoCardContent>
              <div>
                <h5 className="mb-1">Login Page</h5>
                <p className="kooper-lead">Customize your login page</p>
              </div>
              <VmoForm className="mt-4">
                <VmoFormField width={8}>
                  <div className="info-header">
                    <label htmlFor="">What would you like to call your portal?</label>
                    <VmoTooltip
                      content="Give a name to your portal"
                      trigger={<SvgIcon path="common/question" />}
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="textOnPortal"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormInput
                        maxLength={50}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.textOnPortal && {content: removeDoubleQuotes(errors.textOnPortal.message)}}
                      />
                    )}
                  />
                </VmoFormField>

                <VmoFormField width={8}>
                  <div className="info-header">
                    <label htmlFor="">Welcome Text on Login Page</label>
                    <VmoTooltip
                      content="Add a welcome text for the login page"
                      trigger={<SvgIcon path="common/question" />}
                      size="mini"
                      position="top center"
                    />
                  </div>
                  <Controller
                    name="textOnLoginPage"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormInput
                        maxLength={50}
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={errors.textOnLoginPage && {content: removeDoubleQuotes(errors.textOnLoginPage.message)}}
                      />
                    )}
                  />
                </VmoFormField>
              </VmoForm>
            </VmoCardContent>
          </VmoCard>
        </div>
      </div>
    </SettingLayout>
  )
}

export default Customization
