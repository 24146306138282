import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {TAGS_STATS, CONTACT_TAGS, COMPANY_TAGS, DEAL_TAGS} from './types'

export function tagsStatsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/tags/conversationtagscountovertime`,
    method: 'POST',
    label: TAGS_STATS,
    data,
  })
}
export function contactTagsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/tags/contacttagscountovertime`,
    method: 'POST',
    label: CONTACT_TAGS,
    data,
  })
}
export function companyTagsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/tags/companytagscountovertime`,
    method: 'POST',
    label: COMPANY_TAGS,
    data,
  })
}
export function dealTagsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/tags/dealtagscountovertime`,
    method: 'POST',
    label: DEAL_TAGS,
    data,
  })
}
