/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Board from 'react-trello'
import _ from 'lodash'
import {FiList, FiSliders} from 'react-icons/fi'
import {
  VmoButton,
  VmoTooltip,
  VmoTable,
  VmoTableHeader,
  VmoTableRow,
  VmoTableHeaderCell,
  VmoTableCell,
  VmoLabel,
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoTableBody,
  VmoIcon,
  VmoHeader,
  vmoToast,
} from 'vmo-library'

import {CREATE_TASK, DELETE_TASK, EDIT_TASK, GET_ALL_TASK, SET_METADATA} from 'actions/types'
import {getAllTaskActivities, deleteTaskActivities, getTaskActivities, editTaskActivities} from 'actions/activities'
import {setMetaData} from 'actions'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import {
  get,
  GetFormattedDate,
  wrapText,
  getBadgeColorPriority,
  startCase,
  getPermissionTooltipContent,
  getSortType,
} from 'utils/helper'
import {statusList, taskTypes} from 'constants/variables'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import UserImage from 'components/common/UserImage'
import JoyRide, {ACTIONS, EVENTS, STATUS} from 'react-joyride'
import SearchComponent from 'components/common/SearchComponent'
import DeleteModal from 'components/common/DeleteModal'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import {getTimeTrackingSetting} from 'actions/time_tracking'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {MdCall} from 'react-icons/md'
import SvgIcon from 'components/common/SvgIcon'

const Activities = props => {
  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [currentSelectedActivity, setCurrentlySelectedActivity] = useState('')

  // table data
  const [tableData, setTableData] = useState([])

  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState(false)
  const [joyRide, setJoyRide] = useState({
    run: false,
    steps: [
      {
        placement: 'center',
        target: 'body',
        content: 'Get a quick overview of Tasks',
        disableBeacon: true,
      },
      {
        target: '#first-step-joyride',
        title: 'Add New',
        content: 'Add your customer service tasks such as calls, meetings or follow ups',
      },
      {
        target: '#second-step-joyride',
        title: 'Toggle Views',
        content: 'Change between the board view and list view',
      },
    ],
    stepIndex: 0,
  })

  const [kanBoardData, setKanBoardData] = useState({
    lanes: [
      {
        id: 'new',
        title: 'New',
        label: '0/0',
        cards: [],
        cardStyle: {},
      },
      {
        id: 'open',
        title: 'Open',
        label: '0/0',
        cards: [],
      },
      {
        id: 'on_hold',
        title: 'On Hold',
        label: '0/0',
        cards: [],
      },
      {
        id: 'closed',
        title: 'Closed',
        label: '0/0',
        cards: [],
      },
      {
        id: 'overdue',
        title: 'Overdue',
        label: '0/0',
        cards: [],
      },
    ],
  })
  const [sortBy, setSortBy] = useState({
    subject: null,
    type: null,
    assignee: null,
    priority: null,
    createdAt: null,
    endAt: null,
    status: null,
  })
  const [numberOfPage, setNumberOfPage] = useState(0)

  const [isDefaultView, setDefaultView] = useState(false)

  const [currentListParams, setCurrentListParams] = useState({
    type: '',
    status: '',
    userId: '',
    text: '',
    sortBy: '',
    sortType: '',
  })

  const getUsersList = useSelector(state => {
    return get(['settings', 'getUsersList'], state)
  })
  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {getAllTaskData = {}} = useSelector(state => state.activities)

  const {tasksPermissions} = useUserPermissions()
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  const {totalPages = 1, all: AllTask} = getAllTaskData || {}

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParams({
      ...currentListParams,
      sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    if (AllTask) {
      setTableData(AllTask)
    }
  }, [AllTask])

  const onCardDrag = (cardId, sourceLandId, targetLaneId) => {
    if (sourceLandId === 'overdue') {
      vmoToast.error('Task cannot be moved from overdue', {
        appearance: 'error',
        autoDismiss: true,
      })
      return null
    }
    const data = {
      status: targetLaneId,
    }

    if (targetLaneId === 'unassigned') {
      data.assigneeId = null
    }

    dispatch(editTaskActivities(cardId, data))
  }

  const getTypeIcon = type => {
    if (type === 'activity' || type === 'activity') {
      return (
        <>
          <VmoIcon name="calendar check" /> To-Do
        </>
      )
    }
    if (type === 'call') {
      return (
        <>
          <MdCall /> Call
        </>
      )
    }
    if (type === 'meeting') {
      return (
        <>
          <VmoIcon name="calendar alternate" /> Meeting
        </>
      )
    }
  }

  const onDeleteCard = cardId => {
    // dispatch(deleteTaskActivities(cardId)); // direct delete remvoed and added modal delete
    setCurrentlySelectedActivity(cardId)
    setIsDeleteModalOpen(!isDeleteModalOpen)
  }

  const getTextColor = priority => {
    if (priority === 'default') {
      return ''
    }
    if (priority === 'low') {
      return 'green'
    }
    if (priority === 'medium') {
      return 'olive'
    }
    if (priority === 'high') {
      return 'orange'
    }
    if (priority === 'critical') {
      return 'red'
    }
  }

  const MyCard = e => {
    return (
      <VmoCard style={e.metadata.cardStyle} className="kanBanCard">
        <VmoCardContent>
          <p className="type">
            {getTypeIcon(e.metadata.type)}
            <span className="time">{GetFormattedDate(e.metadata.endAt)}</span>
            {e.showDeleteButton && (
              <VmoTooltip
                content={
                  manageTasksPermissions ? 'Delete' : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                }
                size="mini"
                position="top center"
                trigger={
                  <VmoButton onClick={() => manageTasksPermissions && onDeleteCard(e.id)}>
                    <SvgIcon path="common/delete" />
                  </VmoButton>
                }
              />
            )}
            <LockPermissionTooltip isRoleAccessDenied={!manageTasksPermissions}>
              <VmoButton
                onClick={() => {
                  if (manageTasksPermissions) {
                    dispatch(getTaskActivities(e.id))
                    setIsViewTaskOpen(true)
                  }
                }}
              >
                <SvgIcon path="common/edit2" />
              </VmoButton>
            </LockPermissionTooltip>
          </p>
          <h5
            onClick={() => {
              if (manageTasksPermissions) {
                dispatch(getTaskActivities(e.id))
                setIsViewTaskOpen(true)
              }
            }}
          >
            {wrapText(e.title, 20)}
          </h5>

          {/* <VmoCardMeta>{e.description}</VmoCardMeta> */}
          {<h6>{e.subTasks > 0 ? e.subTasks : 0} sub-task</h6>}
          <div className="d-flex m-0">
            <VmoLabel className={`${getTextColor(e.metadata.priority)} kooper-label `}>{e.metadata.priority}</VmoLabel>

            <span className="assName">
              <UserImage profile={get(['metadata', 'assignee'], e)} />
              <span>{`${get(['metadata', 'assignee', 'name'], e, 'Unassigned')}`}</span>
            </span>
          </div>
        </VmoCardContent>
      </VmoCard>
    )
  }

  const CustomLaneHeader = ({cards, title}) => {
    return (
      <div>
        <header
          style={{
            marginTop: 0,
            display: 'block',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              fontSize: 15,
              fontWeight: '600',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            {startCase(title)} ({cards.length})
          </div>
        </header>
      </div>
    )
  }

  const components = {
    Card: MyCard,
    LaneHeader: CustomLaneHeader,
  }

  useEffect(() => {
    if (getUsersList) {
      const usersList = getUsersList.users.map(list => ({
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
        key: list.id,
      }))

      setUsersList(usersList)
    }
  }, [getUsersList])

  useEffect(() => {
    dispatch(getTimeTrackingSetting())
  }, [dispatch])

  useEffect(() => {
    dispatch({type: 'CLEAR_ALL_TASK_DATA'})
    dispatch(getAllTaskActivities(currentListParams, sortBy))
  }, [currentListParams, sortBy])

  useEffect(() => {
    if (successLabels.includes(CREATE_TASK)) {
      dispatch(getAllTaskActivities(currentListParams, sortBy))
    }

    if (successLabels.includes(DELETE_TASK)) {
      dispatch({type: 'CLEAR_ALL_TASK_DATA'})
      dispatch(getAllTaskActivities(currentListParams, sortBy))
    }

    if (successLabels.includes(EDIT_TASK)) {
      dispatch({type: 'CLEAR_ALL_TASK_DATA'})

      setCurrentListParams(
        isDefaultView ? {...currentListParams, page: 1, ...sortBy} : {...currentListParams, page: null, ...sortBy}
      )
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_TASK)) {
      const boardKeys = Object.keys(getAllTaskData || {}).filter(
        lane => lane !== 'totalPages' && lane !== 'all' && lane !== 'page'
      )

      const boardData = {
        lanes: boardKeys.map((lane, index) => ({
          id: lane,
          title: lane,
          label: `${index + 1}/${boardKeys.length}`,
          droppable: lane !== 'overdue',
          cards: getAllTaskData[lane].map(cards => {
            return {
              id: cards.id,
              title: cards.subject,
              description: cards.description,
              label: '5 mins',
              subTasks: cards.subTasks,

              metadata: {
                priority: cards.priority,
                status: cards.status,
                assignee: cards.assignee,
                endAt: cards.endAt,
                type: cards.type,
                ticketId: cards.ticketId,
              },
            }
          }),
        })),
      }

      setKanBoardData(boardData)

      const data = {}
      boardKeys.map(lane => {
        getAllTaskData[lane].map(cards => {
          data[cards.id] = false
        })
      })
      setNumberOfPage(totalPages)
    }
  }, [successLabels])

  useEffect(() => {
    if (get(['viewTaskIsOpen'], props.location.state)) {
      setIsViewTaskOpen(get(['viewTaskIsOpen'], props.location.state))
      dispatch(getTaskActivities(get(['taskId'], props.location.state)))
    }
  }, [props.location.state])

  useEffect(() => {
    const logic =
      get(['accountMetadata'], JSON.parse(window.localStorage.getItem('sales'))) !== 'null'
        ? get(['accountMetadata'], JSON.parse(window.localStorage.getItem('sales')))
        : '{}'.taskTour || false

    if (!logic) {
      setJoyRide({
        ...joyRide,
        run: true,
      })
    }
  }, [get(['accountMetadata'], JSON.parse(window.localStorage.getItem('sales')))])

  useEffect(() => {
    if (successLabels.includes(SET_METADATA)) {
      let metadata = JSON.parse(
        get(['accountMetadata'], JSON.parse(window.localStorage.getItem('sales'))) !== 'null'
          ? get(['accountMetadata'], JSON.parse(window.localStorage.getItem('sales')))
          : '{}'
      )
      metadata = {...metadata, taskTour: true}
      const oldSales = JSON.parse(window.localStorage.getItem('sales') || '{}')
      window.localStorage.setItem(
        'sales',
        JSON.stringify({
          ...oldSales,
          accountMetadata: metadata,
        })
      )
    }
  }, [successLabels])

  const renderCreateTaskModal = () => {
    if (isCreateTaskOpen) {
      return <CreateTask setIsCreateTaskOpen={setIsCreateTaskOpen} isCreateTaskOpen={isCreateTaskOpen} />
    }
  }

  const renderDefaultView = () => {
    if (isDefaultView)
      return (
        <div className="pageBodyWrap" style={{height: 'max-content'}}>
          <VmoTable sortable basic selectable>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'subject' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('subject')}
                >
                  Subject
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'type' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('type')}
                >
                  Type
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'assigneeName' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('assigneeName')}
                >
                  Assignee
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'priority' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('priority')}
                >
                  Priority
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'createdAt' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('createdAt')}
                >
                  Created date
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'endAt' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('endAt')}
                >
                  End date
                </VmoTableHeaderCell>
                <VmoTableHeaderCell
                  sorted={currentListParams.sortBy === 'status' ? getSortType(currentListParams.sortType) : null}
                  onClick={handleSort('status')}
                >
                  Status
                </VmoTableHeaderCell>
                <VmoTableHeaderCell>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {(tableData || []).length === 0 && (
                <tr>
                  <td colSpan="9" className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
              {_.map(tableData, ({id, subject, priority, type, assignee, createdAt, endAt, status}, index) => (
                <VmoTableRow
                  onClick={() => {
                    if (manageTasksPermissions) {
                      dispatch(getTaskActivities(id))
                      setIsViewTaskOpen(true)
                    }
                  }}
                  className="tableLink"
                  key={index}
                >
                  <VmoTableCell className="pl-4">{index + 1 + (currentListParams.page - 1) * 15}</VmoTableCell>
                  <VmoTableCell>
                    <VmoHeader as="h5">{startCase(wrapText(subject, 30))}</VmoHeader>
                    {/* {previewLabel.index === index &&
                        previewLabel.showPreview && (
                          <div className="PrevActPart">
                            <VmoButton
                              icon
                              onClick={(e) => {
                                setCurrentlySelectedActivity(id);
                                setIsDeleteModalOpen(!isDeleteModalOpen);
                                e.stopPropagation();
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          </div>
                        )} */}
                  </VmoTableCell>
                  <VmoTableCell>{startCase(type)}</VmoTableCell>
                  <VmoTableCell>{startCase(get(['name'], assignee, ''))}</VmoTableCell>
                  <VmoTableCell>
                    <VmoLabel className="kooper-label" color={getBadgeColorPriority(priority)}>
                      {startCase(priority)}
                    </VmoLabel>
                  </VmoTableCell>
                  <VmoTableCell>{GetFormattedDate(createdAt)}</VmoTableCell>
                  <VmoTableCell>{GetFormattedDate(endAt)}</VmoTableCell>
                  <VmoTableCell>
                    <VmoLabel color="green">{startCase(status)}</VmoLabel>
                  </VmoTableCell>
                  <VmoTableCell>
                    <VmoTooltip
                      content={
                        manageTasksPermissions
                          ? 'Delete'
                          : getPermissionTooltipContent({roleAccess: manageTasksPermissions})
                      }
                      size="mini"
                      position="top center"
                      trigger={
                        <VmoButton
                          icon
                          onClick={e => {
                            e.stopPropagation()
                            if (manageTasksPermissions) {
                              setCurrentlySelectedActivity(id)
                              setIsDeleteModalOpen(!isDeleteModalOpen)
                            }
                          }}
                        >
                          <SvgIcon path="common/delete" />
                        </VmoButton>
                      }
                    />
                  </VmoTableCell>
                </VmoTableRow>
              ))}
            </VmoTableBody>
          </VmoTable>

          {numberOfPage > 1 && (
            <KooperCustomPagination
              totalPages={numberOfPage}
              activePage={currentListParams.page}
              onPageChange={(e, value) => {
                const data = currentListParams
                data.page = value.activePage
                dispatch(getAllTaskActivities(data, sortBy))
              }}
            />
          )}

          {/* </InfiniteScroll> */}
        </div>
      )
    return (
      <Board
        components={components}
        canAddLanes={false}
        laneStyle={{
          width: '20%',
          margin: '0',
          borderRight: '1px solid #ebedf1',
          borderRadius: '0',
          backgroundColor: '#F9FBFD',
          height: 'calc(100vh - 120px)',
        }}
        data={kanBoardData}
        // onCardDelete={onDeleteCard}
        cardDragClass="draggingCard"
        handleDragEnd={onCardDrag}
        className="canBanView"
        cardDraggable={manageTasksPermissions}
      />
    )
  }

  const handleJoyRideCallback = data => {
    const {action, index, status, type} = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setJoyRide({
        ...joyRide,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      })
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyRide({...joyRide, run: false})
      const data = {
        taskTour: true,
      }
      dispatch(setMetaData(data))
    }
  }

  return (
    <>
      <div className="pageHeader" style={{background: '#fff'}}>
        <div className="pageTitle">
          <JoyRide
            showProgress
            steps={joyRide.steps}
            continuous
            showSkipButton
            callback={handleJoyRideCallback}
            locale={{
              last: 'Done',
              skip: 'Skip',
              next: 'Next',
            }}
            stepIndex={joyRide.stepIndex}
            run={joyRide.run}
            styles={{
              options: {
                arrowColor: '#a8b1bf',
                backgroundColor: '#fff',
                primaryColor: '#1e90ff',
                textColor: '#1b325a',
                zIndex: 1000,
              },
            }}
          />

          <div className="pageTitleIcon">
            <SvgIcon path="navigation/tasks" />
            <h5 className="initial-joyride">Task ({tableData ? tableData.length : 0})</h5>
          </div>
        </div>
        <div className="pageAction">
          <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />

          <div className="mailActivity">
            <VmoTooltip
              position="top center"
              content="Select assignee"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  placeholder="Select assignee"
                  options={[
                    {
                      value: '',
                      text: 'All Assignee',
                      key: '',
                    },
                    {
                      value: 'null',
                      text: 'Unassigned',
                      key: 'unassingned',
                    },
                    ...usersList,
                  ]}
                  onChange={(e, {value}) => {
                    setCurrentListParams({
                      ...currentListParams,
                      userId: value,
                    })
                  }}
                />
              }
            />
            <VmoTooltip
              position="top center"
              content="Select Status"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  placeholder="Select Status"
                  options={[
                    {
                      value: '',
                      text: 'All Status',
                      key: 'allStatus',
                    },
                    {
                      value: 'overdue',
                      text: 'Overdue',
                      key: 'overdue',
                    },
                    ...statusList,
                  ]}
                  onChange={(e, value) => {
                    setCurrentListParams({
                      ...currentListParams,
                      status: value.value,
                    })
                  }}
                />
              }
            />
            <VmoTooltip
              position="top center"
              content="Select Type"
              size="mini"
              trigger={
                <VmoDropdown
                  icon="chevron down"
                  button
                  placeholder="Select Type"
                  options={[{key: '', value: '', text: 'All types'}, ...taskTypes]}
                  onChange={(e, value) => {
                    setCurrentListParams({
                      ...currentListParams,
                      type: value.value,
                    })
                  }}
                />
              }
            />
          </div>

          {!isDefaultView ? (
            <VmoTooltip
              content="List View"
              position="top center"
              size="mini"
              trigger={
                <VmoButton
                  id="second-step-joyride"
                  className="mr-0"
                  icon
                  active={isDefaultView}
                  onClick={() => {
                    setCurrentListParams({
                      ...currentListParams,
                      page: 1,
                    })
                    setDefaultView(true)
                    dispatch({
                      type: 'CLEAR_ALL_TASK_DATA',
                    })
                  }}
                >
                  <FiSliders />
                </VmoButton>
              }
            />
          ) : (
            <VmoTooltip
              content="Board View"
              position="top center"
              size="mini"
              trigger={
                <VmoButton
                  icon
                  id="boardViewTooltip"
                  active={!isDefaultView}
                  onClick={() => {
                    setCurrentListParams({
                      ...currentListParams,
                      page: null,
                    })
                    setDefaultView(false)
                    dispatch({
                      type: 'CLEAR_ALL_TASK_DATA',
                    })
                  }}
                >
                  <FiList />
                </VmoButton>
              }
            />
          )}
          <LockPermissionTooltip isRoleAccessDenied={!manageTasksPermissions}>
            <VmoButton
              id="first-step-joyride"
              primary
              className={!manageTasksPermissions ? 'disabled-button' : ''}
              onClick={() => manageTasksPermissions && setIsCreateTaskOpen(!isCreateTaskOpen)}
            >
              Add Task
            </VmoButton>
          </LockPermissionTooltip>
        </div>

        {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
        {isDeleteModalOpen && (
          <DeleteModal
            idTobeDeleted={currentSelectedActivity}
            isModalOpen={isDeleteModalOpen}
            setIsModalOpen={setIsDeleteModalOpen}
            deleteAction={deleteTaskActivities}
            modalText="Are you sure you want to delete this task?"
            deleteModelHeader="Delete Task"
            type={DELETE_TASK}
          />
        )}
        {renderCreateTaskModal()}
      </div>
      {renderDefaultView()}
    </>
  )
}
export default Activities
