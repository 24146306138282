import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PurchasesMenu from 'navigation/sub-routes/Finance/Purchases/PurchasesMenu'
import PurchasesRoutes from 'navigation/sub-routes/Finance/Purchases/PurchasesRoutes'
import {getPermissionTooltipContent} from 'utils/helper'

function Purchases() {
  const {financeAccess} = useUserPermissions()
  const purchasesAccess = financeAccess?.purchases

  if (!purchasesAccess) {
    return (
      <CreateFirst
        header="Purchases"
        buttonText="Create Purchase"
        tooltip={!purchasesAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: purchasesAccess})}
        imageSrc={create}
        subHeader="Maintain all your purchases in form of bills and manage inventory for those purchases"
        Feature1="Bills of purchases"
        list1="Maintain purchase details in bills for reference"
        Feature2="Purchase Categorization"
        list2="Categorize purchases based on factors"
        Feature3="Online payment"
        list3="Allow customers to pay online through purchase bills"
      />
    )
  }
  return (
    <>
      <PurchasesMenu />
      <PurchasesRoutes />
    </>
  )
}

export default Purchases
