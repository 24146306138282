import React from 'react'
import parse from 'html-react-parser'
import {FiDownload} from 'react-icons/fi'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoHeader,
  VmoHeaderContent,
  VmoHeaderSubheader,
  VmoTooltip,
} from 'vmo-library'
import {get, wrapText, GetFormattedDate} from 'utils/helper'
import {getUserInfo} from 'utils/local-storage'
import {KOOPER_ATTACHMENTS} from 'actions/endpoint'

export const Note = props => {
  const {msg} = props

  function parseHtmlMsg(html) {
    if (!html) return ''
    return parse(html)
  }

  const renderAttachment = attachments => {
    if (attachments !== null && attachments.length > 0) {
      return (
        <div>
          {attachments.map(attachment => (
            <VmoCard className="attachcard-right">
              <VmoCardContent className="attchcontent">
                <VmoHeader as="h5">
                  {attachment.type.split('/')?.[1]}
                  <VmoHeaderContent className="attchhead">
                    {wrapText(attachment.name, 10)}
                    <VmoHeaderSubheader>
                      {Number(attachment.size / (1024 * 1024)).toFixed(2) > 1
                        ? `${Number(attachment.size / (1024 * 1024)).toFixed(2)} Mb`
                        : `${Number(attachment.size / 1024).toFixed(2)} Kb`}
                    </VmoHeaderSubheader>
                  </VmoHeaderContent>
                  <VmoTooltip
                    size="mini"
                    position="top center"
                    content="Download"
                    trigger={
                      <VmoButton
                        icon
                        basic
                        className="dw-btn"
                        href={`${KOOPER_ATTACHMENTS}/${attachment.id}/download`}
                        target="_blank"
                      >
                        <FiDownload className="m-0" />
                      </VmoButton>
                    }
                  />
                </VmoHeader>
              </VmoCardContent>
            </VmoCard>
          ))}
        </div>
      )
    }
  }

  const noteDecider = () => {
    return (
      <div className="noteBodyBar">
        {msg.notes.map((note, index) => {
          return (
            <VmoHeader
              as="h5"
              className={
                Number(get(['id'], getUserInfo())) === get(['author', 'id'], msg.notes[index], 1) ? 'noteOwner' : ''
              }
            >
              <VmoHeaderContent>
                {msg.notes[index].author &&
                  get(['author', 'id'], msg.notes[index], 1) !== get(['author', 'id'], msg.notes[index - 1], 0) &&
                  Number(get(['id'], getUserInfo())) !== get(['author', 'id'], msg.notes[index], 1) && (
                    <span> {`${note.author.firstName} ${note.author.lastName}`} </span>
                  )}
                {note.note && (
                  <VmoHeaderSubheader>
                    <div className="note">{parseHtmlMsg(note.note)}</div>
                    <div className="noteTime">{GetFormattedDate(note.createdAt)}</div>
                  </VmoHeaderSubheader>
                )}
              </VmoHeaderContent>
              {renderAttachment(note.attachments)}
            </VmoHeader>
          )
        })}
      </div>
    )
  }

  if (msg.notes !== null) {
    return <>{noteDecider()}</>
  }
  return null
}
