import {joiResolver} from '@hookform/resolvers'
import {
  createProjectFinanceBillingInvoice,
  getProjectFinanceBillingSingleInvoice,
  updateProjectFinanceBillingInvoice,
} from 'actions/projects'
import {getAccountCurrency} from 'actions/setting_currency'
import {GET_ACCOUNT_CURRENCY, GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {removeDoubleQuotes} from 'utils/helper'
import {projectFinanceBillingInvoiceSchema} from 'validation/Projects/projects/finance.schema'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTab,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'

const projectFinanceBillingInvoiceDefaultValues = {
  invoiceNumber: '',
  invoiceDate: new Date(),
  currencyId: null,
  pricingBasedOn: 'variable',
  finalPrice: 0,
  poNumber: '',
  description: '',
}

const ProjectBillingInvoiceModal = ({open, toggle, projectId, budgetId, invoiceId}) => {
  const dispatch = useDispatch()

  const [currencyList, setCurrencyList] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {accountCurrencyList = []} = useSelector(state => state.settingCurrency)
  const {billingInvoiceData = {}} = useSelector(state => state.projects)

  const {
    handleSubmit,
    control,
    errors,
    watch,
    formState: {isDirty},
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    defaultValues: projectFinanceBillingInvoiceDefaultValues,
    resolver: joiResolver(projectFinanceBillingInvoiceSchema),
  })

  const watchPricingBasedOn = watch('pricingBasedOn')

  useEffect(() => {
    dispatch(getAccountCurrency())
  }, [dispatch])

  useEffect(() => {
    if (projectId && budgetId && invoiceId) {
      dispatch(getProjectFinanceBillingSingleInvoice({projectId, budgetId, invoiceId}))
    }
  }, [dispatch, projectId, budgetId, invoiceId])

  useEffect(() => {
    if (successLabels.includes(GET_ACCOUNT_CURRENCY)) {
      const data = accountCurrencyList?.map(list => ({
        key: list.base.id,
        value: list.currencyId,
        text: `${list.symbol} - ${list.base?.name}`,
      }))
      setCurrencyList(data)
    }
  }, [successLabels, accountCurrencyList])

  useEffect(() => {
    if (successLabels.includes(GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE)) {
      const data = _.pick(billingInvoiceData, [
        'invoiceNumber',
        'invoiceDate',
        'currencyId',
        'pricingBasedOn',
        'finalPrice',
        'poNumber',
        'description',
      ])
      reset(data)
    }
  }, [successLabels, billingInvoiceData, reset])

  const panes = [
    {
      menuItem: 'Essentials',
      render: () => {
        return (
          <VmoTabPane attached={false}>
            <VmoForm className="errorLabel">
              <VmoFormField required>
                <div className="info-header">
                  <label>Invoice no./ID</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify invoice number or ID"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="invoiceNumber"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.invoiceNumber && {
                          content: removeDoubleQuotes(errors.invoiceNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required>
                <div className="info-header">
                  <label>Issue Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the issue date of invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="invoiceDate"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoDatePicker
                      time={false}
                      value={value ? new Date(value) : value}
                      onChange={value => onChange(value)}
                      inputProps={{readOnly: true}}
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required>
                <div className="info-header">
                  <label>Currency</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select currency for the invoice"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="currencyId"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      options={currencyList}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.currencyId && {
                          content: removeDoubleQuotes(errors.currencyId.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required>
                <div className="info-header">
                  <label>Pricing</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select in which pricing type the invoice will be generated"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="pricingBasedOn"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      options={[
                        {
                          key: 'variable',
                          value: 'variable',
                          text: 'Pricing will be based on time and expenses',
                        },
                        {key: 'fixed', value: 'fixed', text: 'Invoice has a fixed price...'},
                      ]}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.pricingBasedOn && {
                          content: removeDoubleQuotes(errors.pricingBasedOn.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              {watchPricingBasedOn === 'fixed' && (
                <VmoFormField required>
                  <label>Final Price</label>
                  <Controller
                    name="finalPrice"
                    control={control}
                    render={({value, onChange}) => (
                      <VmoFormInput
                        type="number"
                        value={value}
                        onChange={(e, {value}) => onChange(value)}
                        error={
                          errors.finalPrice && {
                            content: removeDoubleQuotes(errors.finalPrice.message),
                          }
                        }
                      />
                    )}
                  />
                </VmoFormField>
              )}
            </VmoForm>
          </VmoTabPane>
        )
      },
    },
    {
      menuItem: 'Optional',
      render: () => {
        return (
          <VmoTabPane attached={false}>
            <VmoForm className="errorLabel">
              <VmoFormField>
                <div className="info-header">
                  <label>PO Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify purchase order number"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="poNumber"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.poNumber && {
                          content: removeDoubleQuotes(errors.poNumber.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField>
                <div className="info-header">
                  <label>Description</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Specify a brief description of purchase order"
                    size="mini"
                    psoition="top center"
                  />
                </div>
                <Controller
                  name="description"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormTextArea
                      maxLength={200}
                      style={{resize: 'none'}}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
            </VmoForm>
          </VmoTabPane>
        )
      },
    },
  ]

  const submitForm = data => {
    if (invoiceId) {
      dispatch(updateProjectFinanceBillingInvoice({projectId, budgetId, invoiceId, data}))
    } else {
      dispatch(createProjectFinanceBillingInvoice({projectId, budgetId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{invoiceId ? 'Edit Invoice' : 'Add Invoice'}</VmoModalHeader>
      <VmoModalContent>
        <VmoTab panes={panes} menu={{color: 'blue', secondary: true, pointing: true}} />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)} disabled={!isDirty}>
          {invoiceId ? 'Update Invoice' : 'Add Invoice'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default ProjectBillingInvoiceModal
