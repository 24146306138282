import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {CREATE_VENDORS_CATEGORY, EDIT_VENDORS_CATEGORY, GET_SINGLE_VENDORS_CATEGORY} from 'actions/types'
import {createVendorsCategory, getSingleVendorsCategory, editVendorsCategory} from 'actions/vendors'
import {createVendorsCategorySchema} from 'validation/Finance/vendors.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const initialFormData = {
  category: '',
}

const VendorCategoryModal = props => {
  const dispatch = useDispatch()
  const {open, toggleModal, editId, categoryToggle} = props
  const {handleSubmit, errors, control, reset} = useForm({
    mode: 'onSubmit',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createVendorsCategorySchema),
    defaultValues: initialFormData,
  })

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {getSingleVendorsCategoryData} = useSelector(state => state.vendors)
  const {financePermissions} = useUserPermissions()
  const manageVendorSettingsPermissions = financePermissions?.manage_vendor_settings

  useEffect(() => {
    return () => {
      categoryToggle('')
    }
  }, [dispatch, categoryToggle])

  useEffect(() => {
    if (editId) {
      dispatch(getSingleVendorsCategory(editId))
    }
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_VENDORS_CATEGORY)) {
      reset({category: getSingleVendorsCategoryData.category})
    }
    if (successLabels.includes(EDIT_VENDORS_CATEGORY)) {
      toggleModal(false)
    }
    if (successLabels.includes(CREATE_VENDORS_CATEGORY)) {
      toggleModal(false)
    }
  }, [successLabels])

  const handleSave = data => {
    if (editId) {
      dispatch(editVendorsCategory(editId, data))
    } else {
      dispatch(createVendorsCategory(data))
    }
  }

  return (
    <VmoModal
      open={open}
      onClose={() => toggleModal(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Category Field</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel" onSubmit={handleSubmit(handleSave)}>
          <VmoFormField required>
            <label>Name</label>
            <Controller
              name="category"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  maxLength={20}
                  autoFocus
                  value={value}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  onKeyDown={e => e.key === 'Enter' && handleSubmit(data => handleSave(data))}
                  error={
                    errors.category && {
                      content: errors.category.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          disabled={isLoadingData && (type.includes(EDIT_VENDORS_CATEGORY) || type.includes(CREATE_VENDORS_CATEGORY))}
          basic
          onClick={() => toggleModal(false)}
        >
          Cancel
        </VmoButton>
        <LockPermissionTooltip isRoleAccessDenied={!manageVendorSettingsPermissions}>
          <VmoButton
            loading={isLoadingData && (type.includes(EDIT_VENDORS_CATEGORY) || type.includes(CREATE_VENDORS_CATEGORY))}
            disabled={isLoadingData && (type.includes(EDIT_VENDORS_CATEGORY) || type.includes(CREATE_VENDORS_CATEGORY))}
            primary
            className={!manageVendorSettingsPermissions ? 'disabled-button' : ''}
            {...(manageVendorSettingsPermissions && {
              onClick: handleSubmit(data => handleSave(data)),
            })}
          >
            {editId ? 'Save' : 'Create'}
          </VmoButton>
        </LockPermissionTooltip>
      </VmoModalActions>
    </VmoModal>
  )
}

export default VendorCategoryModal
