import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {CAMPAIGN_STATES_ANALYTICS, CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS} from './types'

export function campaignStatesAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/campaign/stats`,
    method: 'POST',
    label: CAMPAIGN_STATES_ANALYTICS,
    data,
  })
}

export function campaignTotalSendUniqueVisitorsAnalytics(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/campaign/totalSentUniqueVisitors`,
    method: 'POST',
    label: CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS,
    data,
  })
}

export function updateSelectedCampaignId(id) {
  return {
    type: 'UPDATE_SELECTED_CAMPAIGN_ID',
    payload: id,
  }
}
