/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FaBriefcase} from 'react-icons/fa'
import _ from 'lodash'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoGrid,
  VmoGridColumn,
  VmoGridRow,
  VmoHeader,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {DELETE_TIMEOFF, GET_ALL_TIMEOFF} from 'actions/types'
import {deleteTimeOff, getAllTimeOff} from 'actions/timeOff'
import DeleteModal from 'components/common/DeleteModal'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import SearchComponent from 'components/common/SearchComponent'
import {getFormattedDate, startCase} from 'utils/helper'
import TimeOffSidemenu from 'navigation/sub-routes/Hr/TimeOff/TimeOffMenu'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SettingLayout from 'layouts/settingLayout'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const summary = [
  {color: 'green', time: '120h', name: 'Estimated time'},
  {color: 'blue', time: '6h 25m', name: 'Total logged time'},
  {color: 'orange', time: '20m', name: 'Billed time'},
  {color: 'red', time: '6h 5m', name: 'Billable time'},
  {color: 'purple', time: '0h', name: 'Non-billable'},
]

const TimeOff = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_TIMEOFF})

  const {data: getAllTimeOffData = []} = useApiResponse({
    action: getAllTimeOff,
    enabled: true,
    label: GET_ALL_TIMEOFF,
    storePath: 'timeOff.getAllTimeOffData',
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getAllTimeOff(newCurrentListParams))
  }, [currentListParams, dispatch])

  useEffect(() => {
    if (successLabels.includes(DELETE_TIMEOFF)) {
      dispatch(getAllTimeOff())
    }
  }, [successLabels, dispatch])

  const renderTableBody = () => {
    return getAllTimeOffData.map(({id, employee, type, totalDays, createdAt}, index) => {
      return (
        <VmoTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            history.push(`/time-off/update/${id}`)
          }}
        >
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoHeader as="h5">{startCase(employee?.name)}</VmoHeader>
          </VmoTableCell>
          <VmoTableCell>
            <div className="d-flex" style={{justifyContent: 'flex-start'}}>
              <div className="timroff-color" style={{'--timeoff-type-color': type.color}} />
              <span>{startCase(type.name)}</span>
            </div>
          </VmoTableCell>
          <VmoTableCell>{getFormattedDate(createdAt)}</VmoTableCell>
          <VmoTableCell>{totalDays}</VmoTableCell>
          <VmoTableCell>
            <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <VmoDropdownMenu>
                <VmoDropdownItem
                  onClick={() => {
                    setEditId(id)
                    setDeleteModal(true)
                  }}
                >
                  <VmoIcon name="trash alternate" /> Delete
                </VmoDropdownItem>
              </VmoDropdownMenu>
            </VmoDropdown>
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {getAllTimeOffData?.length > 0 || currentListParams.text ? (
        <>
          <TimeOffSidemenu />

          <div className="kooper-side-wrap">
            <div className="page-header pb-0">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="common/file" />
                  <h5>Timeoff List ({getAllTimeOffData ? getAllTimeOffData.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <VmoButton content="Add" primary onClick={() => history.push('/time-off/create')} />
              </div>
            </div>

            <VmoGrid style={{margin: '24px 0'}}>
              <VmoGridRow columns={5} className="p-0 m-0">
                {summary.map(e => (
                  <VmoGridColumn className="summary-grid">
                    <div className="m-0" style={{'--summary-color': e.color}}>
                      <span>{e.name}</span>
                      <span>{e.time}</span>
                    </div>
                  </VmoGridColumn>
                ))}
              </VmoGridRow>
            </VmoGrid>

            <VmoTable sortable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Employee</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Type</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Date Applied</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Total Days</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_ALL_TIMEOFF) ? (
                  <VmoTablePlaceholder columns={8} />
                ) : (getAllTimeOffData || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={8} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </VmoTableBody>
            </VmoTable>
            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteTimeOff}
                idTobeDeleted={editId}
                type={DELETE_TIMEOFF}
              />
            )}
          </div>
        </>
      ) : (
        <SettingLayout>
          <CreateFirst
            src={<FaBriefcase />}
            header="TimeOff"
            subHeader="Simplify and streamline the process of managing employee leave and attendance"
            addFirst={() => history.push('/time-off/create')}
            buttonText="Create TimeOff"
            Feature1="Time-off request"
            Feature2="Approval Workflow"
            Feature3="Customizable Leave Types"
            list1="User-friendly interface to facilitate easy leave requests and approvals"
            list2="Simplified request and approval processes designed to reduce delays"
            list3="Flexible settings to accommodate various leave types"
            imageSrc={create}
          />
        </SettingLayout>
      )}
    </>
  )
}

export default TimeOff
