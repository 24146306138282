/* eslint-disable no-nested-ternary */
import React, {useEffect, useState, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'

import {TAGS_STATS, CONTACT_TAGS, COMPANY_TAGS, DEAL_TAGS} from 'actions/types'
import {tagsStatsAnalytics, contactTagsAnalytics, companyTagsAnalytics, dealTagsAnalytics} from 'actions/reports_tags'
import {get, getSortType, startCase} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import {MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {DATE_RANGE_KEY} from 'constants/variables'

const Tags = () => {
  const dispatch = useDispatch()

  const [currentListParamsLead, setCurrentListParamsLead] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })
  const [currentListParamsContact, setCurrentListParamsContact] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })
  const [currentListParamsCompany, setCurrentListParamsCompany] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })
  const [currentListParamsDeal, setCurrentListParamsDeal] = useState({
    page: 1,
    sortBy: '',
    sortType: '',
  })

  const {analyticsSelectedInbox} = useSelector(state => state.analyticsInbox)
  const {reportsPermissions} = useUserPermissions()
  const conversationsReportsPermissions = reportsPermissions?.conversations_reports

  const {
    isLoading: isLoadingTagsStats,
    data: tagsStatsData = {},
    totalPages: totalPagesTagsStats,
    currentPage: currentPageTagsStats,
    pageSize: pageSizeTagsStats,
  } = useApiResponse({
    label: TAGS_STATS,
    storePath: 'reports_Tags.tagsStatsData',
  })

  const {
    isLoading: isLoadingContactTags,
    data: contactTagsData = {},
    totalPages: totalPagesContactTags,
    currentPage: currentPageContactTags,
    pageSize: pageSizeContactTags,
  } = useApiResponse({
    label: CONTACT_TAGS,
    storePath: 'reports_Tags.contactTagsData',
  })

  const {
    isLoading: isLoadingCompanyTags,
    data: companyTagsData = {},
    totalPages: totalPagesCompanyTags,
    currentPage: currentPageCompanyTags,
    pageSize: pageSizeCompanyTags,
  } = useApiResponse({
    label: COMPANY_TAGS,
    storePath: 'reports_Tags.companyTagsData',
  })

  const {
    isLoading: isLoadingDealTags,
    data: dealTagsData = {},
    totalPages: totalPagesDealTags,
    currentPage: currentPageDealTags,
    pageSize: pageSizeDealTags,
  } = useApiResponse({
    label: DEAL_TAGS,
    storePath: 'reports_Tags.dealTagsData',
  })

  useEffect(() => {
    if (!conversationsReportsPermissions) return
    const data = JSON.parse(localStorage.getItem(DATE_RANGE_KEY)).persistDateRangeDataToDispatch

    const newList = _.omitBy(currentListParams, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    const newListContact = _.omitBy(currentListParamsContact, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    const newListCompany = _.omitBy(currentListParamsCompany, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    const newListDeal = _.omitBy(currentListParamsDeal, value => {
      if (value === 'none' || value === '') {
        return true
      }
      return false
    })
    dispatch(tagsStatsAnalytics({...data, ...newList}))
    dispatch(contactTagsAnalytics({...data, ...newListContact}))
    dispatch(companyTagsAnalytics({...data, ...newListCompany}))
    dispatch(dealTagsAnalytics({...data, ...newListDeal}))
  }, [
    conversationsReportsPermissions,
    currentListParams,
    currentListParamsLead,
    currentListParamsContact,
    currentListParamsCompany,
    currentListParamsDeal,
    dispatch,
  ])

  const actionsToDispatch = useCallback(
    data => {
      dispatch(tagsStatsAnalytics(data))
      dispatch(contactTagsAnalytics(data))
      dispatch(companyTagsAnalytics(data))
      dispatch(dealTagsAnalytics(data))
    },
    [dispatch]
  )

  const handleSort = clickedColumn => () => {
    if (currentListParams.sortBy !== clickedColumn) {
      setCurrentListParams({
        ...currentListParams,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParams({
      ...currentListParams,
      sortType: currentListParams.sortType === 'asc' ? 'desc' : 'asc',
    })
  }
  const handleSortContact = clickedColumn => () => {
    if (currentListParamsContact.sortBy !== clickedColumn) {
      setCurrentListParamsContact({
        ...currentListParamsContact,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParamsContact({
      ...currentListParamsContact,
      sortType: currentListParamsContact.sortType === 'asc' ? 'desc' : 'asc',
    })
  }
  const handleSortCompany = clickedColumn => () => {
    if (currentListParamsCompany.sortBy !== clickedColumn) {
      setCurrentListParamsCompany({
        ...currentListParamsCompany,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParamsLead({
      ...currentListParamsCompany,
      sortType: currentListParamsCompany.sortType === 'asc' ? 'desc' : 'asc',
    })
  }
  const handleSortDeal = clickedColumn => () => {
    if (currentListParamsDeal.sortBy !== clickedColumn) {
      setCurrentListParamsDeal({
        ...currentListParamsDeal,
        sortType: 'asc',
        sortBy: clickedColumn,
      })
      return
    }
    setCurrentListParamsDeal({
      ...currentListParamsDeal,
      sortType: currentListParamsDeal.sortType === 'asc' ? 'desc' : 'asc',
    })
  }

  const renderTableBody = () => {
    return get(['data'], tagsStatsData, []).map(({label, count, change}, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{pageSizeTagsStats * (currentPageTagsStats - 1) + index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(label)}</VmoTableCell>
        <VmoTableCell>{count}</VmoTableCell>
        <VmoTableCell>{change}</VmoTableCell>
      </VmoTableRow>
    ))
  }
  const renderTableBodyContact = () => {
    return get(['data'], contactTagsData, []).map(({label, count, change}, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{pageSizeContactTags * (currentPageContactTags - 1) + index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(label)}</VmoTableCell>
        <VmoTableCell>{count}</VmoTableCell>
        <VmoTableCell>{change}</VmoTableCell>
      </VmoTableRow>
    ))
  }
  const renderTableBodyCompany = () => {
    return get(['data'], companyTagsData, []).map(({label, count, change}, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{pageSizeCompanyTags * (currentPageCompanyTags - 1) + index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(label)}</VmoTableCell>
        <VmoTableCell>{count}</VmoTableCell>
        <VmoTableCell>{change}</VmoTableCell>
      </VmoTableRow>
    ))
  }
  const renderTableBodyDeal = () => {
    return get(['data'], dealTagsData, []).map(({label, count, change}, index) => (
      <VmoTableRow key={index}>
        <VmoTableCell>{pageSizeDealTags * (currentPageDealTags - 1) + index + 1}</VmoTableCell>
        <VmoTableCell>{startCase(label)}</VmoTableCell>
        <VmoTableCell>{count}</VmoTableCell>
        <VmoTableCell>{change}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/tags" />
            <h5>Tags</h5>
          </div>
        </div>
        {conversationsReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={16} className="pr-0 pb-0">
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tags By Conversations</VmoCardHeader>
              <VmoTooltip
                content="List of tags organized based on their usage in conversation"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'label' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('label')}
                    >
                      tags
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'count' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('count')}
                    >
                      count
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={currentListParams.sortBy === 'change' ? getSortType(currentListParams.sortType) : null}
                      onClick={handleSort('change')}
                    >
                      change
                    </VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingTagsStats ? (
                    <VmoTablePlaceholder columns={4} rows={3} />
                  ) : !get(['data'], tagsStatsData, []).length ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    renderTableBody()
                  )}
                </VmoTableBody>
              </VmoTable>
              <KooperCustomPagination
                activePage={currentPageTagsStats}
                totalPages={totalPagesTagsStats}
                onPageChange={page =>
                  setCurrentListParams(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={16} className="pr-0 pb-0">
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tags By Contacts</VmoCardHeader>
              <VmoTooltip
                content="List of tags organized based on their usage in contacts"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsContact.sortBy === 'label'
                          ? getSortType(currentListParamsContact.sortType)
                          : null
                      }
                      onClick={handleSortContact('label')}
                    >
                      tags
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsContact.sortBy === 'count'
                          ? getSortType(currentListParamsContact.sortType)
                          : null
                      }
                      onClick={handleSortContact('count')}
                    >
                      count
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsContact.sortBy === 'change'
                          ? getSortType(currentListParamsContact.sortType)
                          : null
                      }
                      onClick={handleSortContact('change')}
                    >
                      change
                    </VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingContactTags ? (
                    <VmoTablePlaceholder columns={4} rows={3} />
                  ) : !get(['data'], contactTagsData, []).length ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    renderTableBodyContact()
                  )}
                </VmoTableBody>
              </VmoTable>
              <KooperCustomPagination
                activePage={currentPageContactTags}
                totalPages={totalPagesContactTags}
                onPageChange={page =>
                  setCurrentListParamsContact(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={16} className="pr-0 pb-0">
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tags By Company</VmoCardHeader>
              <VmoTooltip
                content="List of tags organized based on their usage in company"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsCompany.sortBy === 'label'
                          ? getSortType(currentListParamsCompany.sortType)
                          : null
                      }
                      onClick={handleSortCompany('label')}
                    >
                      tags
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsCompany.sortBy === 'count'
                          ? getSortType(currentListParamsCompany.sortType)
                          : null
                      }
                      onClick={handleSortCompany('count')}
                    >
                      count
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsCompany.sortBy === 'change'
                          ? getSortType(currentListParamsCompany.sortType)
                          : null
                      }
                      onClick={handleSortCompany('change')}
                    >
                      change
                    </VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingCompanyTags ? (
                    <VmoTablePlaceholder columns={4} rows={3} />
                  ) : !get(['data'], companyTagsData, []).length ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    renderTableBodyCompany()
                  )}
                </VmoTableBody>
              </VmoTable>
              <KooperCustomPagination
                activePage={currentPageCompanyTags}
                totalPages={totalPagesCompanyTags}
                onPageChange={page =>
                  setCurrentListParamsCompany(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={16} className="pr-0 pb-0">
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>Tags By Deals</VmoCardHeader>
              <VmoTooltip
                content="List of tags organized based on their usage in deals"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0">
              <VmoTable sortable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>#</VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsDeal.sortBy === 'label' ? getSortType(currentListParamsDeal.sortType) : null
                      }
                      onClick={handleSortDeal('label')}
                    >
                      tags
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsDeal.sortBy === 'count' ? getSortType(currentListParamsDeal.sortType) : null
                      }
                      onClick={handleSortDeal('count')}
                    >
                      count
                    </VmoTableHeaderCell>
                    <VmoTableHeaderCell
                      sorted={
                        currentListParamsDeal.sortBy === 'change' ? getSortType(currentListParamsDeal.sortType) : null
                      }
                      onClick={handleSortDeal('change')}
                    >
                      change
                    </VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {isLoadingDealTags ? (
                    <VmoTablePlaceholder columns={4} rows={3} />
                  ) : !get(['data'], dealTagsData, []).length ? (
                    <tr>
                      <td colSpan="6" className="emptyValue">
                        No Data Available
                      </td>
                    </tr>
                  ) : (
                    renderTableBodyDeal()
                  )}
                </VmoTableBody>
              </VmoTable>
              <KooperCustomPagination
                activePage={currentPageDealTags}
                totalPages={totalPagesDealTags}
                onPageChange={page =>
                  setCurrentListParamsDeal(currentListParams => ({
                    ...currentListParams,
                    page,
                  }))
                }
              />
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Tags
