export const HeaderFieldDefaultValue = {
  fieldType: 'header',
  label: 'Header',
}
export const SubHeaderFieldDefaultValue = {
  fieldType: 'sub_header',
  label: 'Sub Header',
}
export const DescriptionFieldDefaultValue = {
  fieldType: 'description',
  label: 'Description',
}
export const NumberBoxFieldDefaultValue = {
  fieldType: 'number_box',
  label: 'Number Box',
  withPoints: false,
  maxPoints: 0,
  fieldQuestions: [
    {
      question: 'what your Number box about this form?',
      inputFor: 'both',
      employeeAnswer: null,
      reviewerAnswer: null,
      points: 0,
    },
  ],
}
export const TextBoxFieldDefaultValue = {
  fieldType: 'text_box',
  label: 'Text Box',
  withPoints: false,
  maxPoints: 0,
  fieldQuestions: [
    {
      question: 'what your Text box about this form?',
      inputFor: 'both',
      employeeAnswer: null,
      reviewerAnswer: null,
      points: 0,
    },
  ],
}
export const TextAreaFieldDefaultValue = {
  fieldType: 'text_area',
  label: 'Text Area',
  withPoints: false,
  maxPoints: 0,
  fieldQuestions: [
    {
      question: 'what your text area about this form?',
      inputFor: 'both',
      employeeAnswer: null,
      reviewerAnswer: null,
      points: 0,
    },
  ],
}
export const ratingFieldDefaultValue = {
  fieldType: 'rating',
  label: 'rating',
  numberOfButtons: 10,
  minPointLabel: 'Unsatisfactory',
  maxPointLabel: 'Outstanding',
  fieldQuestions: [
    {
      question: 'What is your rating about this form?',
      inputFor: 'both',
      employeeAnswer: null,
      reviewerAnswer: null,
      points: 0,
    },
  ],
}

export const DividerField = {
  fieldType: 'divider',
}
