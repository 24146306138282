import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDatePicker,
  VmoDivider,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoRadio,
  VmoTooltip,
} from 'vmo-library'

import {
  CREATE_SMS_CAMPAIGN,
  GET_ALL_SEGMENT,
  GET_LABEL_TAGS,
  GET_SINGLE_SMS_CAMPAIGN,
  GET_SMS_CHANNEL_LIST,
  UPDATE_SMS_CAMPAIGN,
} from 'actions/types'
import {getAllSegment} from 'actions/segment'
import {createSmsCampaign, gatLabelTags, getsingleSmsCampaign, updateSmsCampaign} from 'actions/campaign'
import {removeDoubleQuotes} from 'utils/helper'
import {getSMSList} from 'actions/settings_channels'
import TimeZoneDropdown from 'components/common/TimeZoneDropdown'
import {oneTimeSmsSchema} from 'validation/Marketing/campaign.schema'
import {KOOPER_ACCOUNT_V1} from 'actions/endpoint'
import SvgIcon from 'components/common/SvgIcon'

export const oneTimeSmsDefaultValues = {
  name: '',
  description: '',
  campaignBy: '',
  segmentIds: [],
  labelBy: '',
  labelIds: [],
  csv: null,
  inboxId: null,
  placeholder: '',
  message: '',
  schedule: 'now',
  timezone: '',
  date: new Date(),
  time: new Date(),
}

const OneTimeSms = ({setWarningModal}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {id: editId} = useParams()

  const [campaignBy, setCampaignBy] = useState('bySegment')
  const [allSegments, setAllSegments] = useState([])
  const [tagLabelBy, setTagLabelBy] = useState('')
  const [allTags, setAllTags] = useState([])
  const [url, setUrl] = useState('')
  const [totalCsvContacts, setTotalCsvContacts] = useState(0)
  const [numbersList, setNumbersList] = useState([])
  const [messageCursorPosition, setmessageCursorPosition] = useState(0)

  const {successLabels = []} = useSelector(store => store.apiReducer)
  const {segmentListAll = []} = useSelector(state => state.segment)
  const {tagLabelList = [], smsCampaignData = {}} = useSelector(state => state.campaign)
  const {getSmsChannelList = []} = useSelector(state => state.settingsChannels)

  const {
    handleSubmit,
    errors,
    clearErrors,
    control,
    watch,
    setValue,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    resolver: joiResolver(oneTimeSmsSchema),
    defaultValues: oneTimeSmsDefaultValues,
  })
  const segmentIds = watch('segmentIds')
  const labelBy = watch('labelBy')
  const labelIds = watch('labelIds')
  const schedule = watch('schedule')

  useEffect(() => {
    dispatch(getAllSegment())
    dispatch(getSMSList())
    dispatch(gatLabelTags())
  }, [dispatch])

  useEffect(() => {
    if (editId) dispatch(getsingleSmsCampaign(editId))
  }, [editId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_SMS_CAMPAIGN)) {
      if (smsCampaignData.url && smsCampaignData.fileKey && smsCampaignData.fileName) {
        setUrl(smsCampaignData.url)
      }
      if (smsCampaignData.contactIds) setTotalCsvContacts(smsCampaignData.contactIds.length)
      const data = _.pick(smsCampaignData, [
        'name',
        'description',
        'segmentIds',
        'labelBy',
        'labelIds',
        'inboxId',
        'message',
        'date',
        'time',
        'timezone',
      ])
      if (smsCampaignData.segmentIds) setCampaignBy('bySegment')
      if (smsCampaignData.labelBy && smsCampaignData.labelIds) setCampaignBy('byTag')
      if (smsCampaignData.url && smsCampaignData.fileKey && smsCampaignData.fileName) {
        setCampaignBy('byCsv')
        data.csv = smsCampaignData.url
      }
      if (smsCampaignData.date && smsCampaignData.time && smsCampaignData.timezone) data.schedule = 'custom'
      if (smsCampaignData.sendAt) data.schedule = 'now'
      reset(data)
    }
  }, [successLabels, smsCampaignData, reset])

  useEffect(() => {
    if (successLabels.includes(GET_ALL_SEGMENT)) {
      const list = segmentListAll.map(({id, name}) => {
        return {
          key: id,
          value: id,
          text: name,
        }
      })
      setAllSegments(list)
    }
  }, [successLabels, segmentListAll])

  useEffect(() => {
    if (successLabels.includes(GET_LABEL_TAGS)) {
      const list = tagLabelList.map(({id, label}) => {
        return {
          key: id,
          value: id,
          text: label,
        }
      })
      setAllTags(list)
    }
  }, [successLabels, tagLabelList])

  useEffect(() => {
    if (successLabels.includes(GET_SMS_CHANNEL_LIST)) {
      const list = getSmsChannelList.map(({inboxId, name, phoneNumber}) => {
        return {key: inboxId, value: inboxId, text: `${name} (${phoneNumber})`}
      })
      setNumbersList(list)
    }
  }, [getSmsChannelList, successLabels])

  useEffect(() => {
    if (campaignBy) setValue('campaignBy', campaignBy)
  }, [campaignBy, setValue])

  useEffect(() => {
    if (labelBy === 'conversations') {
      setTagLabelBy('contactsByConversationTags')
    }
    if (labelBy === 'contacts') {
      setTagLabelBy('contactsByTags')
    }
  }, [labelBy, setValue])

  useEffect(() => {
    if (schedule === 'custom') {
      setValue('date', new Date())
      setValue('time', new Date())
    }
  }, [schedule, setValue])

  useEffect(() => {
    if (successLabels.includes(CREATE_SMS_CAMPAIGN) || successLabels.includes(UPDATE_SMS_CAMPAIGN)) {
      history.push('/campaigns')
    }
  }, [successLabels, history])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(true)
    } else {
      setWarningModal(false)
    }
    return () => {
      setWarningModal(false)
    }
  }, [isDirty, setWarningModal])

  const totalSegmentContacts = () => {
    const segments = []
    segmentIds?.forEach(segmentId => {
      const segment = segmentListAll.filter(s => s.id === segmentId)
      if (segment.length > 0) segments.push(segment[0])
    })
    return segments?.map(s => s.totalContacts).reduce((a, b) => a + b, 0)
  }

  const totalTagContacts = () => {
    const labelTags = []
    labelIds?.forEach(labelId => {
      const labelTag = tagLabelList.filter(t => t.id === labelId)
      labelTags.push(labelTag[0])
    })
    // return _.map(labelTags, tagLabelBy).reduce((a, b) => +a + +b, 0)
    return _.reduce(_.map(labelTags, tagLabelBy), (a, b) => +a + +b, 0)
  }

  const onChangeCsv = file => {
    const fileReader = new FileReader()
    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result
        setTotalCsvContacts(csvOutput.split('\n').length - 2)
      }
      fileReader.readAsText(file)
    }
  }

  const onSubmit = data => {
    if (data.schedule === 'now') {
      data.sendAt = new Date().toISOString()
    }
    data.date = moment(data.date).format('DD-MM-YYYY')
    data.time = moment(data.time).format('HH:mm')
    if (data.sendAt) {
      delete data.date
      delete data.time
      delete data.timezone
    }
    const payload = _.omit(data, ['campaignBy', 'placeholder', 'schedule'])
    if (editId && payload.csv instanceof File) {
      dispatch(updateSmsCampaign(payload, editId, 'multipart/form-data'))
    } else if (editId) {
      dispatch(updateSmsCampaign(payload, editId))
    }
    if (!editId && payload.csv) {
      dispatch(createSmsCampaign({...payload, status: true}, 'multipart/form-data'))
    }
    if (!editId && !payload.csv) {
      dispatch(createSmsCampaign({...payload, status: true}))
    }
    setWarningModal(false)
  }

  return (
    <div className="kooper-full-page">
      <div className="campaignNavbar">
        <VmoButton primary onClick={() => history.push('/campaigns')}>
          Back
        </VmoButton>
        <h3 className="m-0">Create SMS Campaign</h3>
        <VmoButton primary onClick={handleSubmit(onSubmit)} disabled={!isDirty}>
          {editId ? 'Update' : 'Create'}
        </VmoButton>
      </div>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Configuration</h3>
            <p>Configure your SMS Type campaign as per requirement</p>
            <VmoDivider />
            <VmoForm className="errorLabel">
              <VmoFormField required width={8}>
                <label>Name</label>
                <Controller
                  name="name"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormInput
                      placeholder="Name"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.name && {
                          content: removeDoubleQuotes(errors.name.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField width={8}>
                <label>Description</label>
                <Controller
                  control={control}
                  name="description"
                  render={({value, onChange}) => (
                    <VmoFormTextArea
                      placeholder="Description"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                      error={
                        errors.description && {
                          content: removeDoubleQuotes(errors.description.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField>
                <Controller name="campaignBy" control={control} render={null} />
                <div className="info-header">
                  <label>Select Customers</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                    content="Select customers for the campaign based on criteria"
                  />
                </div>
                <VmoRadio
                  value="bySegment"
                  label="By Segment"
                  checked={campaignBy === 'bySegment'}
                  onChange={(e, {value}) => setCampaignBy(value)}
                />
                <div className="radio-subheading-box">
                  <p className="kooper-lead">Select contacts for the campaign by segment</p>
                  {campaignBy === 'bySegment' && (
                    <VmoFormGroup>
                      <VmoFormField width={8} className="mt-0 mb-0">
                        <Controller
                          name="segmentIds"
                          control={control}
                          render={({value, onChange}) => (
                            <VmoFormSelect
                              placeholder="Select Segments"
                              className="mb-3"
                              search
                              multiple
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              onClick={() => clearErrors('segmentIds')}
                              options={allSegments}
                              error={
                                errors.segmentIds && {
                                  content: removeDoubleQuotes(errors.segmentIds.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      <p className="kooper-lead pt-4">All Contacts ({totalSegmentContacts()})</p>
                    </VmoFormGroup>
                  )}
                </div>
                <VmoRadio
                  value="byTag"
                  label="By Tag"
                  checked={campaignBy === 'byTag'}
                  onChange={(e, {value}) => setCampaignBy(value)}
                />
                <div className="radio-subheading-box">
                  <p className="kooper-lead">Select Customers by tag</p>
                  {campaignBy === 'byTag' && (
                    <VmoFormGroup>
                      <VmoFormField width={6}>
                        <Controller
                          name="labelBy"
                          control={control}
                          render={({value, onChange}) => (
                            <VmoFormSelect
                              placeholder="Select"
                              className="mb-3"
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                              onClick={() => clearErrors('labelBy')}
                              options={[
                                {
                                  key: 1,
                                  text: 'Select By Conversations',
                                  value: 'conversations',
                                },
                                {
                                  key: 2,
                                  text: 'Select By Contacts',
                                  value: 'contacts',
                                },
                              ]}
                              error={
                                errors.labelBy && {
                                  content: removeDoubleQuotes(errors.labelBy.message),
                                }
                              }
                            />
                          )}
                        />
                      </VmoFormField>
                      {(watch('labelBy') === 'conversations' || watch('labelBy') === 'contacts') && (
                        <>
                          <VmoFormField width={6}>
                            <Controller
                              name="labelIds"
                              control={control}
                              render={({value, onChange}) => (
                                <VmoFormSelect
                                  search
                                  multiple
                                  placeholder="Select Tag"
                                  className="mb-3"
                                  value={value}
                                  onChange={(e, {value}) => onChange(value)}
                                  onClick={() => clearErrors('labelIds')}
                                  options={allTags}
                                  error={
                                    errors.labelIds && {
                                      content: removeDoubleQuotes(errors.labelIds.message),
                                    }
                                  }
                                />
                              )}
                            />
                          </VmoFormField>
                          <p className="kooper-lead pt-4">All Contacts ({totalTagContacts()})</p>
                        </>
                      )}
                    </VmoFormGroup>
                  )}
                </div>
                <VmoRadio
                  label="Upload CSV"
                  value="byCsv"
                  checked={campaignBy === 'byCsv'}
                  onChange={(e, {value}) => setCampaignBy(value)}
                />
                <div className="radio-subheading-box">
                  <p className="kooper-lead">Select customers by uploading CSV</p>
                  {campaignBy === 'byCsv' && (
                    <>
                      {url && editId && (
                        <>
                          <a href={url} download>
                            Download Your CSV File
                          </a>
                          <button
                            type="button"
                            className="ml-3"
                            onClick={() => {
                              setUrl('')
                              setValue('csv', null)
                              setTotalCsvContacts(0)
                            }}
                          >
                            Remove
                          </button>
                          <span className="kooper-lead pl-3">All Contacts ({totalCsvContacts})</span>
                        </>
                      )}
                      {url === '' && (
                        <VmoFormGroup>
                          <VmoFormField width={6}>
                            <Controller
                              name="csv"
                              control={control}
                              render={({onChange}) => (
                                <VmoFormInput
                                  type="file"
                                  onChange={e => {
                                    if (e.target.files[0].size < 5000000) {
                                      onChangeCsv(e.target.files[0])
                                      onChange(e.target.files[0])
                                    } else {
                                      alert('File size must be less than 5Mb')
                                    }
                                  }}
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                  error={
                                    errors.csv && {
                                      content: removeDoubleQuotes(errors.csv.message),
                                    }
                                  }
                                />
                              )}
                            />
                          </VmoFormField>
                          <p className="kooper-lead pt-4">All Contacts ({totalCsvContacts})</p>
                        </VmoFormGroup>
                      )}
                      {!editId && (
                        <>
                          <a href={`${KOOPER_ACCOUNT_V1}/settings/sms-campaign/sample/csv`} download>
                            Download Sample CSV File
                          </a>
                        </>
                      )}
                      <small style={{color: '#000', display: 'block'}}>
                        Max upload file size is 5 MB. File must be in CSV format.
                      </small>
                    </>
                  )}
                </div>
              </VmoFormField>
              <h3 className="mb-0">Messages</h3>
              <VmoDivider className="mt-1" />
              <VmoFormField required width={8}>
                <div className="info-header">
                  <label className="label-class">SMS Number</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                    content="Select the phone number to send SMS campaign"
                  />
                </div>
                <Controller
                  name="inboxId"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      placeholder="Select Phone Number"
                      search
                      selection
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      onClick={() => clearErrors('inboxId')}
                      options={numbersList}
                      error={
                        errors.inboxId && {
                          content: removeDoubleQuotes(errors.inboxId.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField required width={8}>
                <label>
                  Message
                  <Controller
                    control={control}
                    name="placeholder"
                    render={({value, onChange}) => (
                      <div style={{float: 'right'}} className="mailActivity">
                        <VmoDropdown
                          icon="chevron down"
                          button
                          placeholder="Insert Placeholder"
                          header="Select placeholder"
                          value={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                            const elem = document.getElementById('messageArea')
                            const old = elem.value
                            elem.value = `${old.substring(0, messageCursorPosition)}{{${value}}}${old.substring(
                              messageCursorPosition
                            )}`
                            setmessageCursorPosition(old.substring(0, messageCursorPosition).length + value.length + 4)
                            setValue('message', elem.value)
                          }}
                          options={[
                            {
                              key: 1,
                              text: 'First Name',
                              value: 'firstName',
                            },
                            {
                              key: 2,
                              text: 'Last Name',
                              value: 'lastName',
                            },
                            {
                              key: 3,
                              text: 'Company',
                              value: 'company',
                            },
                          ]}
                        />
                      </div>
                    )}
                  />
                </label>
                <Controller
                  control={control}
                  name="message"
                  render={({value, onChange}) => (
                    <VmoFormTextArea
                      id="messageArea"
                      placeholder="Type message here"
                      value={value}
                      onChange={(e, {value}) => {
                        setmessageCursorPosition(e.target.selectionStart)
                        onChange(value)
                      }}
                      onBlur={e => setmessageCursorPosition(e.target.selectionStart)}
                      error={
                        errors.message && {
                          content: removeDoubleQuotes(errors.message.message),
                        }
                      }
                    />
                  )}
                />
              </VmoFormField>
              <VmoFormField width={8}>
                <div className="info-header">
                  <label>When to send</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    size="mini"
                    position="top center"
                    content="Select when you want to send the campaign"
                  />
                </div>
                <Controller
                  name="schedule"
                  control={control}
                  render={({value, onChange}) => (
                    <>
                      <VmoFormField>
                        <VmoRadio
                          label="Send Now"
                          value="now"
                          checked={value === 'now'}
                          onChange={(e, {value}) => onChange(value)}
                        />
                      </VmoFormField>
                      <VmoFormField>
                        <VmoRadio
                          className="m-0"
                          label="Send Later"
                          value="custom"
                          checked={value === 'custom'}
                          onChange={(e, {value}) => onChange(value)}
                        />
                      </VmoFormField>
                    </>
                  )}
                />
              </VmoFormField>
              {watch('schedule') === 'custom' && (
                <>
                  <VmoFormField width={6}>
                    <label>Timezone</label>
                    <Controller
                      control={control}
                      name="timezone"
                      render={({value, onChange}) => (
                        <TimeZoneDropdown
                          selection
                          realTime
                          format="Z (z) LT"
                          direction="right"
                          search
                          placeholder="Select Timezone"
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          onClick={() => clearErrors('timezone')}
                          value={value}
                          error={errors.timezone}
                        />
                      )}
                    />
                    {errors.timezone && (
                      <p className="errorLabelError">{removeDoubleQuotes(errors.timezone.message)}</p>
                    )}
                  </VmoFormField>
                  <VmoFormGroup>
                    <VmoFormField width={3}>
                      <label>Date</label>
                      <Controller
                        name="date"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoDatePicker
                            type="date"
                            value={value}
                            onChange={value => onChange(value)}
                            inputProps={{readOnly: true}}
                            min={new Date()}
                            time={false}
                          />
                        )}
                      />
                    </VmoFormField>
                    <VmoFormField width={3}>
                      <label>Time</label>
                      <Controller
                        name="time"
                        control={control}
                        render={({value, onChange}) => (
                          <VmoDatePicker
                            type="time"
                            value={value}
                            onChange={value => onChange(value)}
                            inputProps={{readOnly: true}}
                            // min={new Date()}
                            date={false}
                          />
                        )}
                      />
                    </VmoFormField>
                  </VmoFormGroup>
                </>
              )}
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </div>
  )
}

export default OneTimeSms
