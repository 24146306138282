import {ANALYTICS_ENDPOINT} from './endpoint'
import {ESTIMATED_REVENUE, WIN_RATIO} from './types'
import {apiAction} from './api'

export function estimatedRevenue(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/deal-conversion/dealsumanalysisbyusers`,
    method: 'POST',
    label: ESTIMATED_REVENUE,
    data,
  })
}
export function winRatio(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/deal-conversion/winratio`,
    method: 'POST',
    label: WIN_RATIO,
    data,
  })
}
