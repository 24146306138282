/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiUserPlus} from 'react-icons/fi'
import moment from 'moment'
import {
  VmoButton,
  VmoButtonGroup,
  VmoDatePicker,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoFormSelect,
  VmoFormTextArea,
  VmoIcon,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
  VmoDropdownHeader,
  VmoDropdownDivider,
  VmoInput,
} from 'vmo-library'

import {CREATE_TASK, GET_ALL_COMPANIES_IDS, GET_ALL_CONTACTS_IDS, GET_TASK_TEMPLATE, GET_USERS} from 'actions/types'
import {createTaskActivities} from 'actions/activities'
import {getAllCompaniesIds} from 'actions/company'
import {getAllContactsIds} from 'actions/contacts'
import {getAllDeals} from 'actions/deals'
import {getUsers} from 'actions/settings'
import {getAllTaskTemplate, getTaskTemplate} from 'actions/settings_task'
import {priorityList, statusList, taskTypes} from 'constants/variables'
import {get, getHourMinuteFromMinutes, onIdealUser, removeDoubleQuotes} from 'utils/helper'
import UserImage from 'components/common/UserImage'
import KooperCustomDropdown from 'components/common/KooperCustomDropdown'
import {createTaskSchema} from 'validation/Projects/task.schema'
import {submitTimeTrackingManualTask} from 'actions/time_tracking'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

export const AddSubTaskForm = ({
  addMethod,
  usersList,
  defaultValue = {
    title: '',
    dueDate: null,
    assigneeId: null,
  },
}) => {
  const [subTaskForm, setSubTaskForm] = useState(defaultValue)

  return (
    <VmoForm>
      <VmoFormGroup>
        <VmoFormField className="task-input" width={7}>
          <VmoFormInput
            value={subTaskForm.title}
            maxLength={30}
            placeholder="Write your title here"
            onChange={(e, {value}) =>
              setSubTaskForm(subTaskForm => ({
                ...subTaskForm,
                title: value,
              }))
            }
          />
        </VmoFormField>
        <VmoFormField width={4}>
          <VmoDatePicker
            style={{width: '100%'}}
            className="vmo-picker"
            inputProps={{readOnly: true}}
            dropUp
            value={subTaskForm.dueDate}
            onChange={value =>
              setSubTaskForm(subTaskForm => ({
                ...subTaskForm,
                dueDate: value,
              }))
            }
            time={false}
            min={new Date()}
          />
        </VmoFormField>
        <VmoFormField width={1}>
          <KooperCustomDropdown
            options={[
              {
                key: 'unass',
                value: null,
                text: 'Unassign',
              },
              ...usersList,
            ]}
            value={subTaskForm.assigneeId || null}
            tooltip
            tooltipContent={
              subTaskForm.assigneeId
                ? usersList.find(user => {
                    return user.value === subTaskForm.assigneeId ? user : null
                  })
                : 'Assignee'
            }
            dropdownProps={{
              className: 'bg-btn-bg',
              item: true,
              upward: true,
              icon: (
                <VmoButton
                  icon
                  className="m-0"
                  style={{
                    position: 'relative',
                  }}
                >
                  {subTaskForm.assigneeId ? (
                    <UserImage
                      profile={usersList.find(user => {
                        return user.value === subTaskForm.assigneeId ? user : null
                      })}
                    />
                  ) : (
                    <FiUserPlus />
                  )}
                </VmoButton>
              ),
            }}
            menu={{scrolling: true}}
            activeIndicator
            input={{
              icon: 'search',
              iconPosition: 'left',
              className: 'search createTask-search',
            }}
            header="Assignee"
            onClick={value => {
              setSubTaskForm(currentValue => ({
                ...currentValue,
                assigneeId: value,
              }))
            }}
          />
        </VmoFormField>
        <VmoFormField width={3}>
          <VmoButton
            style={{marginTop: 2, marginLeft: 10, color: 'white', background: 'var(--primary-color)'}}
            disabled={subTaskForm.title.trim().length < 1}
            primary
            fluid
            inverted
            onClick={() => {
              addMethod(subTaskForm)
              setSubTaskForm(defaultValue)
            }}
          >
            Add
          </VmoButton>
        </VmoFormField>
      </VmoFormGroup>
    </VmoForm>
  )
}

const initialData = {
  type: 'call',
  subject: '',
  status: 'new',
  endAt: new Date(moment().add(1, 'days').startOf('day')),
  priority: 'default',
  assigneeId: 'unass',
  description: '',
  callType: 'inbound',
  startAt: new Date(moment().startOf('day')),
  subTasks: [],
  taskFor: 'contact',
  taskForId: null,
}

const CreateTask = props => {
  const dispatch = useDispatch()
  const {conversation: conversationData, isDataAvailabel, contactId, dealId, companyId} = props
  const [templateList, setTemplateList] = useState([])
  const [usersList, setUsersList] = useState([])
  const [listForTaskId, setListForTaskId] = useState([])
  const [timeUsage, setTimeUsage] = useState(0)
  const [timeTrackingTimer, setTimeTrackingTimer] = useState(0)
  const [timeTrackingCounterStatus, setTimeTrackingCounterStatus] = useState('completed')
  const [timeTrackingStartTime, setTimeTrackingStartTime] = useState(new Date())

  const {handleSubmit, errors, control, watch, setValue} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createTaskSchema),
    defaultValues: initialData,
  })
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'subTasks',
  })

  const watchedTaskFor = watch('taskFor')
  const currentType = watch('type')
  const watchStartAt = watch('startAt')
  const watchEndAt = watch('endAt')

  const {taskTemplateList, taskTemplate} = useSelector(state => state.settingsTasks)
  const {getUsersList} = useSelector(state => state.settings)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const allDealsData = useSelector(state => {
    return get(['deals', 'getAllDealsList', 'deals'], state)
  })

  // const { getAllDealsList } = useSelector((state) => state.deals),
  const {timeTrackingData = {}} = useSelector(state => state.timeTracking)
  const {createTaskData} = useSelector(state => state.activities)

  const {data: contactsIdsList = []} = useApiResponse({
    action: getAllContactsIds,
    enabled: true,
    label: GET_ALL_CONTACTS_IDS,
    storePath: 'contacts.contactsIdsList',
  })

  const {data: companiesIdsList = []} = useApiResponse({
    action: getAllCompaniesIds,
    enabled: true,
    label: GET_ALL_COMPANIES_IDS,
    storePath: 'companies.companiesIdsList',
  })

  const createTask = data => {
    if (data.assigneeId === 'unass') {
      data.assigneeId = null
    }
    dispatch(createTaskActivities(data))
  }

  useEffect(() => {
    if (timeTrackingCounterStatus === 'running') {
      if (timeTrackingData.pauseTimerIdle) {
        onIdealUser({
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
          },
        })
      }
      if (!timeTrackingData.pauseTimerIdle && timeTrackingData.timerPauseLimit > 0) {
        onIdealUser({
          timeOut: timeTrackingData.timerPauseLimit * 1000,
          onIdeal: () => {
            setTimeTrackingCounterStatus('paused')
          },
        })
      }
    }
  }, [timeTrackingCounterStatus])

  useEffect(() => {
    let id
    if (timeTrackingCounterStatus === 'running') {
      id = setInterval(() => {
        setTimeTrackingTimer(timeTrackingTimer + 1000)
      }, 1000)
    }

    return () => clearInterval(id)
  })

  useEffect(() => {
    if (taskTemplateList) {
      setTemplateList(taskTemplateList)
    }
  }, [taskTemplateList])

  useEffect(() => {
    if (successLabels.includes(GET_TASK_TEMPLATE)) {
      const {name, description, status, priority, assigneeId, duration, subTasks} = taskTemplate

      setValue('subject', name || '')
      setValue('description', description || '')
      setValue('status', status || 'new')
      setValue('priority', priority || 'default')
      setValue('assigneeId', assigneeId || 'unass')
      setValue('endAt', duration ? new Date(new Date().setDate(new Date().getDate() + duration)) : null)
      setValue(
        'subTasks',
        (subTasks || []).map(({name, duration, assigneeId}) => {
          return {
            assigneeId,
            title: name,
            dueDate: new Date(new Date().setDate(new Date().getDate() + duration)),
          }
        })
      )
    }

    if (successLabels.includes(CREATE_TASK)) {
      if (timeTrackingData.activity || timeTrackingData.call || timeTrackingData.meeting)
        dispatch(
          submitTimeTrackingManualTask({
            taskId: createTaskData.id,
            startTime: timeTrackingStartTime,
            status: 'completed',
            duration: timeTrackingTimer,
          })
        )
      props.setIsCreateTaskOpen(false)
    }
  }, [successLabels])

  useEffect(() => {
    if (successLabels.includes(GET_USERS)) {
      const usersList = getUsersList.users.map(list => ({
        value: list.id,
        key: list.id,
        text: `${get(['firstName'], list, '')} ${get(['lastName'], list, ' ')}`,
        content: <div>{`${get(['firstName'], list, '')} ${get(['lastName'], list, ' ')}`}</div>,
      }))

      if (getUsersList.users.avatar) {
        usersList.image = {
          avatar: true,
          src: getUsersList.users.avatar,
        }
      } else {
        usersList.content = (
          <>
            <UserImage profile={usersList} className="ui avatar image" />
            {usersList.firstName}
          </>
        )
      }

      setUsersList(usersList)
    }
  }, [successLabels, getUsersList])

  useEffect(() => {
    if (watchedTaskFor) {
      let listForTask = []
      if (watchedTaskFor === 'contact' && contactsIdsList) {
        const list = contactsIdsList.map(contact => ({
          value: contact.id,
          key: contact.id,
          text: contact.name,
        }))
        listForTask = list
      }

      if (watchedTaskFor === 'company' && companiesIdsList) {
        const list = companiesIdsList.map(company => ({
          value: company.id,
          key: company.id,

          text: `${get(['name'], company, '')} ${get(['lastName'], company, '')}`,
        }))
        listForTask = list
      }

      if (watchedTaskFor === 'deal' && allDealsData) {
        const list = allDealsData?.stages?.map(deal => ({
          value: deal.id,
          key: deal.id,
          text: `${get(['name'], deal, '')} ${get(['lastName'], deal, '')}`,
        }))
        listForTask = list
      }

      setListForTaskId(listForTask)
    }
  }, [watchedTaskFor, allDealsData, companiesIdsList, contactsIdsList])

  useEffect(() => {
    if (watchStartAt && watchStartAt) {
      const diff = watchEndAt.getTime() - watchStartAt.getTime()
      if (diff > 0) {
        const minutes = diff / (1000 * 60)
        setTimeUsage(minutes)
      } else {
        setTimeUsage(0)
      }
    }
  }, [watchStartAt, watchEndAt])

  useEffect(() => {
    if (isDataAvailabel && conversationData) {
      setValue('subject', get(['subject'], conversationData, ''))
      setValue('assigneeId', get(['assignee', 'id'], conversationData, 'unass'))
      setValue('priority', get(['priority'], conversationData, 'default'))
      setValue('contactId', get(['contact', 'id'], conversationData))
      setValue('subject', get(['subject'], conversationData, ''))
      setValue('status', get(['status', 'status'], conversationData, 'new'))
    }
  }, [isDataAvailabel, conversationData])

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getAllDeals())
    dispatch(getAllTaskTemplate())
  }, [dispatch])

  useEffect(() => {
    if (contactId) {
      setValue('taskForId', Number(contactId))
      setValue('taskFor', 'contact')
    }
    if (dealId) {
      setValue('taskFor', 'deal')
      setValue('taskForId', Number(dealId))
    }
    if (companyId) {
      setValue('taskFor', 'company')
      setValue('taskForId', Number(companyId))
    }
  }, [])

  const searchFromTemplateList = searchString => {
    const updatedList = templateList.filter(template => template.name.includes(searchString))
    setTemplateList(updatedList)
    if (searchString === '') {
      setTemplateList(taskTemplateList)
    }
  }

  const createEditTaskForm = () => {
    return (
      <VmoForm className="errorLabel">
        <VmoFormField required>
          <div className="info-header">
            <label className="label-class">Select Type</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="Set type of the task"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="type"
            render={({value, onChange, onBlur}) => (
              <VmoFormSelect
                fluid
                options={taskTypes}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={
                  errors.type && {
                    content: removeDoubleQuotes(errors.type.message),
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>

        <VmoFormField required>
          <div className="info-header">
            <label className="label-class">Subject</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="write about subject of the task"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="subject"
            control={control}
            render={({value, onChange, onBlur}) => (
              <VmoFormInput
                placeholder="Type your Subject.."
                maxLength={30}
                value={value}
                onChange={e => onChange(e.target.value)}
                onBlur={onBlur}
                error={
                  errors.subject && {
                    content: errors.subject.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>

        <VmoFormField required>
          <div className="info-header">
            <label className="label-class">Assign To</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="select whom you want to assign"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="assigneeId"
            render={({value, onChange, onBlur}) => (
              <VmoFormSelect
                fluid
                search
                options={[
                  {
                    key: 'unass',
                    value: 'unass',
                    text: 'Unassigned',
                  },
                  ...usersList,
                ]}
                value={value}
                onChange={(e, {value}) => onChange(value)}
                onBlur={onBlur}
                error={
                  errors.assigneeId && {
                    content: errors.assigneeId.message,
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>

        {currentType === 'call' && (
          <VmoFormGroup className="fields" inline>
            <div className="info-header mr-3">
              <label>Direction</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Choose what is this direction for"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="callType"
              render={({value, onChange}) => (
                <>
                  <VmoFormRadio
                    label="Inbound"
                    value="inbound"
                    checked={value === 'inbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                  <VmoFormRadio
                    label="Outbound"
                    value="outbound"
                    checked={value === 'outbound'}
                    onClick={(e, {value}) => onChange(value)}
                    error={
                      errors.callType && {
                        content: errors.callType.message,
                      }
                    }
                  />
                </>
              )}
              control={control}
            />
          </VmoFormGroup>
        )}
        <VmoFormGroup widths="equal">
          <VmoFormField>
            <div className="info-header">
              <label>Task For</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Choose what is this task for"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="taskFor"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  selection
                  fluid
                  className="vmo-dropdown settingAssignee"
                  options={[
                    {
                      text: 'Contact',
                      value: 'contact',
                      key: 'contact',
                    },
                    {
                      text: 'Deal',
                      value: 'deal',
                      key: 'deal',
                    },
                    {
                      text: 'Company',
                      value: 'company',
                      key: 'company',
                    },
                  ]}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.taskFor && {
                      content: errors.taskFor.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Name</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="select a name from the list."
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="taskForId"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  selection
                  fluid
                  // open={taskForIdDropdown}
                  // icon={null}
                  search
                  className="vmo-dropdown settingAssignee"
                  // onSearchChange={(e, data) => {
                  //   if (data.searchQuery.length > 0) {
                  //     setTaskForIdDropdown(true);
                  //   } else setTaskForIdDropdown(false);
                  // }}
                  // clearable
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                    // setTaskForIdDropdown(false);
                  }}
                  options={listForTaskId}
                  onBlur={onBlur}
                  error={
                    errors.taskForId && {
                      content: errors.taskForId.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormGroup widths="equal">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Status</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="set initial status of the task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="status"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  selection
                  fluid
                  options={statusList}
                  value={value}
                  onBlur={onBlur}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={
                    errors.status && {
                      content: errors.status.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>

          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Priority</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="set priority of task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="priority"
              render={({value, onChange, onBlur}) => (
                <VmoFormSelect
                  fluid
                  selection
                  options={priorityList}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.priority && {
                      content: errors.priority.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormGroup>
          <VmoFormField required width={6}>
            <div className="info-header">
              <label className="label-class">Start Time</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="setup task start time for easy tracking"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              control={control}
              name="startAt"
              render={({value, onChange, onBlur}) => (
                <VmoDatePicker
                  className="vmo-picker full-wdith-picker"
                  value={value}
                  inputProps={{readOnly: true}}
                  onChange={value => onChange(value)}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.startAt && <div>{errors.startAt.message}</div>}
          </VmoFormField>

          <VmoFormField required width={6}>
            <div className="info-header">
              <label className="label-class">End Time</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="set estimated end time"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="endAt"
              control={control}
              render={({value, onChange, onBlur}) => (
                <VmoDatePicker
                  className="vmo-picker full-wdith-picker"
                  value={value}
                  inputProps={{readOnly: true}}
                  onChange={value => onChange(value)}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.endAt && <div>{errors.endAt.message}</div>}
          </VmoFormField>

          <VmoFormField width={4}>
            <div className="info-header">
              <label>Time Usage</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Total time allotted for this task"
                size="mini"
                psoition="top center"
              />
            </div>
            <VmoInput disabled placeholder="00:00" value={getHourMinuteFromMinutes(timeUsage)} />
          </VmoFormField>
        </VmoFormGroup>
        <VmoFormField>
          <div className="info-header">
            <label>Description</label>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="write brief description about the task"
              size="mini"
              psoition="top center"
            />
          </div>
          <Controller
            name="description"
            render={({value, onChange, onBlur}) => (
              <VmoFormTextArea
                value={value}
                maxLength={200}
                onChange={e => {
                  onChange(e.target.value)
                }}
                style={{resize: 'none'}}
                onBlur={onBlur}
                error={
                  errors.description && {
                    content: errors.description.message,
                  }
                }
              />
            )}
            control={control}
          />
        </VmoFormField>
        <div className="info-header">
          <h5 className="mb-0">Subtask</h5>
          <VmoTooltip
            content="Add subtask associated with main task"
            size="mini"
            position="top center"
            trigger={<SvgIcon path="common/question" />}
          />
        </div>
        <div className="addTaskView">
          <AddSubTaskForm usersList={usersList} addMethod={append} />
        </div>
        <div className="subTaskView">
          {fields.map(({id, title, dueDate: dueDateSubtask, assigneeId}, index) => {
            return (
              <div className="subTaskViewBody" key={id}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Controller
                    control={control}
                    name={`subTasks[${index}].title`}
                    defaultValue={title}
                    render={({value, onChange}) => (
                      <VmoFormInput placeholder="Title" onChange={e => onChange(e.target.value)} value={value} />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name={`subTasks[${index}].dueDate`}
                  defaultValue={dueDateSubtask}
                  render={({value, onChange}) => (
                    <VmoDatePicker
                      placeholder="Select a date"
                      time={false}
                      dropUp
                      value={value}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                      min={new Date()}
                      onChange={value => onChange(value)}
                    />
                  )}
                />
                <VmoButtonGroup>
                  <VmoButton icon style={{fontSize: '0rem'}}>
                    <Controller
                      control={control}
                      name={`subTasks[${index}].assigneeId`}
                      defaultValue={assigneeId}
                      render={({value, onChange}) => (
                        <KooperCustomDropdown
                          style={{overflowX: 'hidden'}}
                          options={[
                            {
                              key: 'unass',
                              value: 'unass',
                              text: 'Unassigned',
                            },
                            ...usersList,
                          ]}
                          dropdownProps={{
                            pointing: 'right',
                            upward: true,
                            item: true,
                            icon: value ? (
                              <UserImage
                                profile={getUsersList.users.find(user => {
                                  return user.id === value ? user : null
                                })}
                              />
                            ) : (
                              <FiUserPlus />
                            ),
                          }}
                          menu={{scrolling: true}}
                          input={{
                            icon: 'search',
                            iconPosition: 'left',
                            className: 'search',
                          }}
                          activeIndicator
                          header="Assignee"
                          value={value}
                          onClick={value => onChange(value)}
                        />
                      )}
                    />
                  </VmoButton>
                  <VmoTooltip
                    content="Delete"
                    size="mini"
                    position="top center"
                    trigger={
                      <VmoButton icon onClick={() => remove(index)}>
                        <SvgIcon path="common/delete" />
                      </VmoButton>
                    }
                  />
                </VmoButtonGroup>
              </div>
            )
          })}
        </div>
      </VmoForm>
    )
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={props.isCreateTaskOpen}
      onClose={() => props.setIsCreateTaskOpen(false)}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">Create Task</VmoModalHeader>
      <VmoModalContent scrolling>{createEditTaskForm()}</VmoModalContent>
      <VmoModalActions style={{position: 'relative'}}>
        <VmoDropdown
          upward
          style={{
            zIndex: '99',
            opacity: '1',
            position: 'absolute',
            top: 10,
          }}
          closeOnChange={false}
          className="icon task-template"
          icon={
            <VmoTooltip
              content="Task templates"
              size="mini"
              position="top center"
              trigger={<VmoIcon className="list" />}
            />
          }
        >
          <VmoDropdownMenu>
            <VmoDropdownHeader>Insert Task Templates</VmoDropdownHeader>
            <VmoDropdownDivider />
            <VmoFormInput
              icon="search"
              iconPosition="left"
              type="search"
              name="search"
              id="search"
              placeholder="Search task templates... "
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
              }}
              onChange={e => {
                searchFromTemplateList(e.target.value)
              }}
            />
            <VmoDropdownMenu scrolling>
              {templateList.length > 0 ? (
                templateList.map(temp => (
                  <VmoDropdownItem
                    key={temp.id}
                    onClick={() => {
                      dispatch(getTaskTemplate(temp.id))
                    }}
                  >
                    {temp.name}
                  </VmoDropdownItem>
                ))
              ) : (
                <VmoDropdownItem>No Task Template Available </VmoDropdownItem>
              )}
            </VmoDropdownMenu>
          </VmoDropdownMenu>
        </VmoDropdown>
        {timeTrackingData.isTimeTracking &&
          ((timeTrackingData.task && currentType === 'task') ||
            (timeTrackingData.call && currentType === 'call') ||
            (timeTrackingData.meeting && currentType === 'meeting') ||
            (timeTrackingData.event && currentType === 'event') ||
            (timeTrackingData.milestone && currentType === 'milestone')) && (
            <div className="timeTrack ml-5" style={{position: 'absolute', top: '10px'}}>
              <VmoButton
                icon
                onClick={() => {
                  setTimeTrackingCounterStatus(timeTrackingCounterStatus === 'running' ? 'paused' : 'running')
                }}
              >
                {timeTrackingCounterStatus === 'running' ? <VmoIcon name="pause" /> : <VmoIcon name="play" />}
              </VmoButton>
              {`${moment('2000-01-01 00:00:00').add(moment.duration(timeTrackingTimer)).format('HH:mm:ss')}`}
              <VmoButton icon>
                <VmoIcon name="redo" />
              </VmoButton>
            </div>
          )}

        <VmoButton
          basic
          disabled={isLoadingData && type.includes(CREATE_TASK)}
          onClick={() => props.setIsCreateTaskOpen(false)}
        >
          Cancel
        </VmoButton>
        <VmoButton
          primary
          loading={isLoadingData && type.includes(CREATE_TASK || GET_TASK_TEMPLATE)}
          disabled={isLoadingData && (type.includes(CREATE_TASK) || type.includes(GET_TASK_TEMPLATE))}
          onClick={handleSubmit(createTask)}
        >
          Create
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default CreateTask
