import React from 'react'
import {useSelector} from 'react-redux'
import {ResponsivePie} from '@nivo/pie'
import {
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {GET_PROJECTS_HEALTH_DATA} from 'actions/types'
import CustomLoader from 'components/common/CustomLoader'
import NoDataChart from 'components/common/NoDataChart'
import {get, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const ProjectHealthData = ({projectsHealthData}) => {
  const {
    isBudgetCreated,
    projectBudget = {},
    myTaskData = [],
    taskData = [],
    myTime = [],
    allTime = [],
    boardBreakdown = [],
  } = projectsHealthData

  const {type = []} = useSelector(state => state.apiReducer)

  const renderTableBody = () => {
    if (type.includes(GET_PROJECTS_HEALTH_DATA)) {
      return <VmoTablePlaceholder columns={3} />
    }
    if (!type.includes(GET_PROJECTS_HEALTH_DATA) && !boardBreakdown.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="3" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return boardBreakdown.map(board => (
      <VmoTableRow className="tableLink">
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(board.stage)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{board.active ?? 0}</VmoTableCell>
        <VmoTableCell>{board.completed ?? 0}</VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoCardHeader className="mb-2">Project Budget</VmoCardHeader>
              {isBudgetCreated ? (
                <div style={{maxWidth: '400px'}}>
                  <h5 className="mb-1">Total ({get(['totalAMount'], projectBudget, 0)})</h5>
                  <div className="budget-progress">
                    <div
                      className="budget-progress-completed"
                      style={{width: `${get(['usedPercentage'], projectBudget, 0).toFixed(2)}%`}}
                    />
                  </div>
                  <div className="d-flex mt-2">
                    <span>Used</span>
                    <span>(${get(['usedAmount'], projectBudget, 0)})</span>
                  </div>
                  <div className="d-flex">
                    <span>Remaining</span>
                    <span>(${get(['totalAMount'], projectBudget, 0) - get(['usedAmount'], projectBudget, 0)})</span>
                  </div>
                </div>
              ) : (
                <div className="emptyValue" style={{border: 'none'}}>
                  No Budget Created
                </div>
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>My Tasks</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of task associated with the project template"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent style={{height: 250}}>
              {type.includes(GET_PROJECTS_HEALTH_DATA) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_HEALTH_DATA) &&
                (!myTaskData.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={myTaskData.map(item => ({
                      ...item,
                      label: `${item.label} (${item.value})`,
                    }))}
                    margin={{top: 20, right: 20, bottom: 60, left: 120}}
                    startAngle={-50}
                    innerRadius={0.7}
                    padAngle={1}
                    cornerRadius={1}
                    colors={d => d.data.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={12}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    enableArcLabels={false}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    animate
                    legends={[
                      {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -50,
                        translateY: 0,
                        itemsSpacing: 20,
                        itemWidth: 120,
                        itemHeight: 10,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <NoDataChart top="-15px" />
                ))}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>All Tasks</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of all task within the project template"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent style={{height: 250}}>
              {type.includes(GET_PROJECTS_HEALTH_DATA) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_HEALTH_DATA) &&
                (!taskData.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={taskData.map(item => ({
                      ...item,
                      label: `${item.label} (${item.value})`,
                    }))}
                    margin={{top: 20, right: 20, bottom: 60, left: 120}}
                    startAngle={-50}
                    innerRadius={0.7}
                    padAngle={1}
                    cornerRadius={1}
                    colors={d => d.data.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={12}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    enableArcLabels={false}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    animate
                    legends={[
                      {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -50,
                        translateY: 0,
                        itemsSpacing: 20,
                        itemWidth: 120,
                        itemHeight: 10,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <NoDataChart top="-15px" />
                ))}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>My Time</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of usage time of the project template"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent style={{height: 250}}>
              {type.includes(GET_PROJECTS_HEALTH_DATA) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_HEALTH_DATA) &&
                (!myTime.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={myTime.map(item => ({
                      ...item,
                      label: `${item.label} (${item.value})`,
                    }))}
                    margin={{top: 20, right: 20, bottom: 60, left: 120}}
                    startAngle={-50}
                    innerRadius={0.7}
                    padAngle={1}
                    cornerRadius={1}
                    colors={d => d.data.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={12}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    enableArcLabels={false}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    animate
                    legends={[
                      {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -50,
                        translateY: 0,
                        itemsSpacing: 20,
                        itemWidth: 120,
                        itemHeight: 10,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <NoDataChart top="-15px" />
                ))}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>All Time</VmoCardHeader>
              <VmoTooltip
                content="Breakdown of task time based on project template"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent style={{height: 250}}>
              {type.includes(GET_PROJECTS_HEALTH_DATA) && <CustomLoader />}
              {!type.includes(GET_PROJECTS_HEALTH_DATA) &&
                (!allTime.every(({value}) => value === 0) ? (
                  <ResponsivePie
                    data={allTime.map(item => ({
                      ...item,
                      label: `${item.label} (${item.value})`,
                    }))}
                    margin={{top: 20, right: 20, bottom: 60, left: 120}}
                    startAngle={-50}
                    innerRadius={0.7}
                    padAngle={1}
                    cornerRadius={1}
                    colors={d => d.data.color}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]],
                    }}
                    arcLinkLabel={e => `${e.id} (${e.value})`}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={8}
                    arcLinkLabelsStraightLength={12}
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{from: 'color'}}
                    enableArcLabels={false}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]],
                    }}
                    animate
                    legends={[
                      {
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -50,
                        translateY: 0,
                        itemsSpacing: 20,
                        itemWidth: 120,
                        itemHeight: 10,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                ) : (
                  <NoDataChart top="-15px" />
                ))}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox pb-0">
              <VmoCardHeader>Board</VmoCardHeader>
              <VmoTooltip
                content="List of all task status organized based on task count under each"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent>
              <VmoTable basic>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Column Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Active</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Completed</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>{renderTableBody()}</VmoTableBody>
              </VmoTable>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </>
  )
}

export default ProjectHealthData
