import NoDataTableSvg from 'assets/noDataTableSvg'
import React from 'react'

const KooperNoDataTable = ({noDataText}) => {
  return (
    <div className="no-data-design">
      <NoDataTableSvg />
      <p>{noDataText || 'No Data Available'}</p>
    </div>
  )
}

export default KooperNoDataTable
