import SearchComponent from 'components/common/SearchComponent'
import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import DeleteModal from 'components/common/DeleteModal'
import {useDispatch, useSelector} from 'react-redux'
import {
  CREATE_PROJECT_FINANCE_EXPENSE,
  DELETE_PROJECT_FINANCE_EXPENSE,
  GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST,
  UPDATE_PROJECT_FINANCE_EXPENSE,
} from 'actions/types'
import {deleteProjectFinanceExpense, getProjectFinanceBudgetExpenseList} from 'actions/projects'
import CreateFirst from 'components/common/CreateFirst'
import {getFormattedDate, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import ProjectBudgetExpenseModal from './ProjectBudgetExpenseModal'

const ProjectBudgetExpenses = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [text, setText] = useState('')
  const [isBudgetExpenseModalOpen, setIsBudgetExpenseModalOpen] = useState(false)
  const [idToUpdateExpense, setIdToUpdateExpense] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [idToDeleteExpense, setIdToDeleteExpense] = useState(null)

  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)
  const {budgetExpenseList = []} = useSelector(state => state.projects)

  useEffect(() => {
    if (projectId && budgetId) {
      const params = {}
      if (text.length) {
        params.text = text
      }
      dispatch(getProjectFinanceBudgetExpenseList({projectId, budgetId, params}))
    }
  }, [projectId, budgetId, dispatch, text])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECT_FINANCE_EXPENSE) ||
      successLabels.includes(UPDATE_PROJECT_FINANCE_EXPENSE)
    ) {
      setIsBudgetExpenseModalOpen(false)
      dispatch(getProjectFinanceBudgetExpenseList({projectId, budgetId}))
    }
    if (successLabels.includes(UPDATE_PROJECT_FINANCE_EXPENSE)) {
      setIdToUpdateExpense(null)
    }
    if (successLabels.includes(DELETE_PROJECT_FINANCE_EXPENSE)) {
      setIsDeleteModalOpen(false)
      setIdToDeleteExpense(null)
      dispatch(getProjectFinanceBudgetExpenseList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const renderBudgetExpenseModal = () => {
    if (isBudgetExpenseModalOpen) {
      return (
        <ProjectBudgetExpenseModal
          open={isBudgetExpenseModalOpen}
          toggle={setIsBudgetExpenseModalOpen}
          projectId={projectId}
          budgetId={budgetId}
          expenseId={idToUpdateExpense}
        />
      )
    }
    return null
  }

  if (budgetExpenseList.length === 0 && !text) {
    return (
      <>
        <CreateFirst
          header="Expense"
          subHeader="Expense is used to help individuals and businesses keep track of their project expenses."
          addFirst={() => setIsBudgetExpenseModalOpen(true)}
          buttonText="Add Expense"
          Feature1="Track project expenses"
          Feature2="Budget management"
          Feature3="Real-time tracking"
          list1="Easily record and categorize expenses related to projects."
          list2="Set project budgets to stay within budget."
          list3="Keep track of expenses to avoid overspending."
        />
        {renderBudgetExpenseModal()}
      </>
    )
  }

  const renderTableBody = () => {
    if (type.includes(GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST)) {
      return <VmoTablePlaceholder columns={5} />
    }
    if (!type.includes(GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST) && !budgetExpenseList.length) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="5" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return budgetExpenseList.map(expense => (
      <VmoTableRow
        key={expense.id}
        className="tableLink"
        onClick={() => {
          setIdToUpdateExpense(expense.id)
          setIsBudgetExpenseModalOpen(true)
        }}
      >
        <VmoTableCell>
          <VmoHeader as="h5">{startCase(expense.description)}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>${expense.cost}</VmoTableCell>
        <VmoTableCell>{expense.createdBy?.name}</VmoTableCell>
        <VmoTableCell>{getFormattedDate(expense.updatedAt || expense.createdAt)}</VmoTableCell>
        <VmoTableCell>
          <VmoTooltip
            size="mini"
            position="top center"
            content="Delete"
            trigger={
              <VmoButton
                basic
                icon
                className="tableLink-delete-icon"
                onClick={e => {
                  e.stopPropagation()
                  setIdToDeleteExpense(expense.id)
                  setIsDeleteModalOpen(true)
                }}
              >
                <SvgIcon path="common/delete" />
              </VmoButton>
            }
          />
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <div className="project-finance-budget-expense-action">
        <SearchComponent onSuccess={text => setText(text)} />
        <VmoButton primary onClick={() => setIsBudgetExpenseModalOpen(true)}>
          Create Expense
        </VmoButton>
      </div>

      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>Description</VmoTableHeaderCell>
            <VmoTableHeaderCell>Cost</VmoTableHeaderCell>
            <VmoTableHeaderCell>User</VmoTableHeaderCell>
            <VmoTableHeaderCell>Last Updated</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
        {budgetExpenseList.length > 0 && (
          <VmoTableFooter>
            <VmoTableRow>
              <VmoTableCell>
                <VmoHeader as="h3">Total</VmoHeader>
              </VmoTableCell>
              <VmoTableCell>
                ${budgetExpenseList.reduce((acc, expense) => acc + parseFloat(expense.cost), 0)}
              </VmoTableCell>
              <VmoTableCell colSpan="3" />
            </VmoTableRow>
          </VmoTableFooter>
        )}
      </VmoTable>

      {renderBudgetExpenseModal()}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          modalText="Are you sure you want to delete this expense?"
          onDelete={() => dispatch(deleteProjectFinanceExpense({projectId, budgetId, expenseId: idToDeleteExpense}))}
        />
      )}
    </>
  )
}

export default ProjectBudgetExpenses
