import {apiAction} from './api'
import {FINANCE_BUDGET_ENDPOINT} from './endpoint'
import {
  GET_BREAKDOWN_LIST,
  CREATE_BUDGET,
  GET_BUDGET_LIST,
  DELETE_BUDGET,
  UPDATE_BUDGET_STATUS,
  GET_BUDGET_DATA,
  UPDATE_BUDGET,
} from './types'

export function getBreakdownList() {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}/breakdownbylist`,
    method: 'GET',
    label: GET_BREAKDOWN_LIST,
  })
}

export function createBudget(data) {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}`,
    method: 'POST',
    label: CREATE_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget Planner Added',
  })
}

export function getBudgetlist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: FINANCE_BUDGET_ENDPOINT + query,
    method: 'GET',
    label: GET_BUDGET_LIST,
  })
}

export function deleteBudget(id) {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}/${id}`,
    method: 'DELETE',
    label: DELETE_BUDGET,
    showToast: true,
    successMessage: 'Budget Planner Deleted',
  })
}

export function updateBudgetStatus(id, data) {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}/status/${id}`,
    method: 'PUT',
    label: UPDATE_BUDGET_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}

export function getBudgetData(id) {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_BUDGET_DATA,
  })
}

export function updateBudget(id, data) {
  return apiAction({
    url: `${FINANCE_BUDGET_ENDPOINT}/${id}`,
    method: 'PUT',
    label: UPDATE_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget planner Updated',
  })
}
