import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import TimezoneSelect from 'react-timezone-select'
import moment from 'moment'
import {
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormSelect,
  VmoRadio,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {removeDoubleQuotes, startCase} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

const workingDayList = [
  {value: 0, key: 0, text: 'Sunday'},
  {value: 1, key: 1, text: 'Monday'},
  {value: 2, key: 2, text: 'Tuesday'},
  {value: 3, key: 3, text: 'Wednesday'},
  {value: 4, key: 4, text: 'Thursday'},
  {value: 5, key: 5, text: 'Friday'},
  {value: 6, key: 6, text: 'Saturday'},
]

const Setup = props => {
  const {control, errors, listOfInbox, contactList, contactSegmentList, watch, contactsSelectedShowError, style} = props
  const [startTime, setStartTime] = useState(new Date())
  const [isContactsSelected, setIsContactsSelected] = useState(false)
  const timezone = moment.tz.names().map(zone => ({
    text: startCase(zone),
    value: zone,
    key: zone,
  }))

  const watchedValues = watch()

  useEffect(() => {
    if (
      watchedValues.contactCheckbox ||
      watchedValues.contactSegmentCheckbox ||
      watchedValues.leadCheckbox ||
      watchedValues.leadSegmentCheckbox
    ) {
      setIsContactsSelected(true)
    } else {
      setIsContactsSelected(false)
    }
  }, [watchedValues])

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Sequence</h3>
            <p className="mt-0 mb-4 card-description">
              Create and send personalized sequences to streamline repetitive tasks and hit your goals
            </p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField required>
                <div className="info-header">
                  <label className="label-class">Send From</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select the inbox from which you execute this sequence"
                    size="mini"
                  />
                </div>
                <Controller
                  name="inboxId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      className="mt-2 mb-2"
                      width={6}
                      options={listOfInbox}
                      search
                      placeholder="Select Inbox"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.inboxId && {
                          content: removeDoubleQuotes(errors.inboxId.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              {/* <VmoFormField>
                <VmoListItem>
                  <VmoListContent>
                    <VmoListHeader>Reply to</VmoListHeader>
                  </VmoListContent>
                  <VmoListDescription className="kooper-lead mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Deserunt eaque non facilis neque.
                  </VmoListDescription>
                  <VmoFormSelect
                    className="mt-2 mb-2"
                    width={6}
                    options={timezone}
                    placeholder="Replay"
                  />
                </VmoListItem>
              </VmoFormField> */}
              {/* <VmoFormField>
                <VmoListItem>
                  <VmoListContent>
                    <VmoListHeader>Access</VmoListHeader>
                  </VmoListContent>
                  <VmoListDescription className="kooper-lead mb-2">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit
                    Deserunt eaque non facilis neque.
                  </VmoListDescription>
                  <VmoFormSelect
                    className="mt-2 mb-2"
                    width={6}
                    options={Accessdrop}
                    placeholder="Access"
                  />
                </VmoListItem>
              </VmoFormField> */}
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
        <VmoCard fluid>
          <VmoCardContent>
            <div className="info-header">
              <h3 className="mb-0 label-class">Select Contacts</h3>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content=" Select individual contacts or contact lists for sending automated sequences"
                size="mini"
              />
            </div>
            {contactsSelectedShowError && !isContactsSelected && (
              <p style={{color: 'red'}}>Please select the destination</p>
            )}
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField>
                <div className="d-flex mt-3">
                  <div>
                    <label>Contacts</label>
                    <p className="kooper-lead">Select individual contacts for automated sequences</p>
                  </div>
                  <Controller
                    name="contactCheckbox"
                    render={({onChange, value}) => (
                      <VmoRadio
                        toggle
                        checked={value}
                        onChange={() => {
                          onChange(!value)
                        }}
                      />
                    )}
                    control={control}
                  />
                </div>
                {watchedValues.contactCheckbox && (
                  <Controller
                    name="contactIds"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        className="mt-2 mb-2"
                        width={8}
                        multiple
                        options={contactList}
                        search
                        placeholder="Select Contact"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.contactIds && {
                            content: removeDoubleQuotes(errors.contactIds.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                )}
              </VmoFormField>
              <VmoFormField>
                <div className="d-flex">
                  <div>
                    <label>Contact List</label>
                    <p className="kooper-lead">Select entire list for automated sequences</p>
                  </div>
                  <Controller
                    name="contactSegmentCheckbox"
                    render={({onChange, value}) => (
                      <VmoRadio
                        toggle
                        checked={value}
                        onChange={() => {
                          onChange(!value)
                        }}
                      />
                    )}
                    control={control}
                  />
                </div>

                {/* {(watchedValues.contactSegmentIds.length > 0 ||
                    watchedValues.contactIds.length > 0) &&
                    `${
                      watchedValues.contactSegmentIds.length +
                      watchedValues.contactIds.length
                    } contacts are selected`} */}

                {watchedValues.contactSegmentCheckbox && (
                  <Controller
                    name="contactSegmentIds"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        className="mt-2 mb-2"
                        width={8}
                        multiple
                        options={contactSegmentList}
                        search
                        placeholder="Select Contact List"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                        }}
                        onBlur={onBlur}
                        error={
                          errors.contactIds && {
                            content: removeDoubleQuotes(errors.contactIds.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                )}
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
        <VmoCard fluid>
          <VmoCardContent>
            <h5 className="mb-0">Execution Time</h5>
            <p className="mt-0 mb-4 card-description">Manage execution time of the automated sequence</p>
            <VmoForm className="mt-4 errorLabel popperUp">
              <VmoFormField width={4}>
                <div className="info-header">
                  <label>Start Date</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the start date of the sequence"
                    size="mini"
                  />
                </div>
                <Controller
                  name="startAt"
                  render={({onChange, value, onBlur}) => (
                    <VmoDatePicker
                      placeholder="Date"
                      time={false}
                      value={new Date(value)}
                      min={new Date()}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                      messages={{
                        date: errors.startAt && {
                          content: removeDoubleQuotes(errors.startAt.message),
                        },
                      }}
                      onChange={value => {
                        onChange(value.toISOString())
                      }}
                      onBlur={onBlur}
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField width={8}>
                <div className="info-header">
                  <label>TimeZone</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select timezone in which you want to execute the sequence"
                    size="mini"
                  />
                </div>
                <Controller
                  name="timezone"
                  //  render={({ onChange, value, onBlur }) => (
                  //    <VmoFormSelect
                  //      className="mt-2 mb-2"
                  //      width={8}
                  //      options={timezone}
                  //      placeholder="Timezone"
                  //      value={value}
                  //      onChange={(e, { value }) => {
                  //        onChange(value);
                  //      }}
                  //      search
                  //      onBlur={onBlur}
                  //      error={
                  //        errors.timezone && {
                  //          content: removeDoubleQuotes(errors.timezone.message),
                  //        }
                  //      }
                  //    />
                  //  )}
                  render={({onChange, value}) => (
                    <TimezoneSelect
                      labelStyle="altName"
                      value={value}
                      onChange={value => onChange(value.value)}
                      required
                    />
                  )}
                  control={control}
                />
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Sending Hours</label>
                  <VmoTooltip
                    content="Set a period between which you want to send sequence "
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                <div style={{display: 'flex', gap: '35px'}}>
                  <Controller
                    name="startTime"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        onChange={value => {
                          onChange(moment(value).format('HH:MM:SS'))
                          setStartTime(value)
                        }}
                        value={moment(new Date())
                          .set({
                            h: value.substring(0, 2),
                            m: 0,
                          })
                          .toDate()}
                        className="vmo-picker"
                        step={60}
                        date={false}
                        inputProps={{
                          component: props => <input {...props} readOnly />,
                        }}
                      />
                    )}
                    control={control}
                  />
                  <Controller
                    name="endTime"
                    render={({onChange, value}) => (
                      <VmoDatePicker
                        onChange={value => {
                          onChange(moment(value).format('HH:MM:SS'))
                        }}
                        value={moment()
                          .set({
                            h: value.substring(0, 2),
                            m: 0,
                          })
                          .toDate()}
                        className="vmo-picker"
                        style={{marginLeft: '1rem'}}
                        step={60}
                        date={false}
                        min={moment(new Date())
                          .set({
                            h: (startTime.getHours() + 1).toString(),
                            m: 0,
                          })
                          .toDate()}
                        inputProps={{
                          component: props => <input {...props} readOnly />,
                        }}
                      />
                    )}
                    control={control}
                  />
                </div>
              </VmoFormField>

              <VmoFormField>
                <div className="info-header">
                  <label>Sending Days</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Select days on which you want to send sequence"
                    size="mini"
                  />
                </div>
                <Controller
                  name="workingDays"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      className="mt-2 mb-2"
                      width={16}
                      multiple
                      options={workingDayList}
                      placeholder="Working Days"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      onBlur={onBlur}
                      error={
                        errors.workingDays && {
                          content: removeDoubleQuotes(errors.workingDays.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </VmoTabPane>
  )
}

export default Setup
