import {ANALYTICS_ENDPOINT} from './endpoint'
import {apiAction} from './api'
import {
  BREAKDOWN_BY_TYPES,
  BREAKDOWN_BY_DEPARTMENT,
  LEAVES_BY_DEPARTMENT,
  LEAVES_BY_TYPE,
  EMPLOYEE_STATS,
} from './types'

export function breakDownByTypes(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/timeoffs/breakdown-by-types`,
    method: 'POST',
    label: BREAKDOWN_BY_TYPES,
    data,
  })
}
export function breakDownByDepartment(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/timeoffs/breakdown-by-department`,
    method: 'POST',
    label: BREAKDOWN_BY_DEPARTMENT,
    data,
  })
}
export function leavesByDepartment(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/timeoffs/leaves-by-department`,
    method: 'POST',
    label: LEAVES_BY_DEPARTMENT,
    data,
  })
}
export function leavesByType(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/timeoffs/leaves-by-type`,
    method: 'POST',
    label: LEAVES_BY_TYPE,
    data,
  })
}
export function employeeStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/timeoffs/employee-stats`,
    method: 'POST',
    label: EMPLOYEE_STATS,
    data,
  })
}
