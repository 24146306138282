import React from 'react'
import TimezoneSelect from 'react-timezone-select'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoContainer,
  VmoDatePicker,
  VmoDropdown,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormGroup,
  VmoRadio,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableRow,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {Avdate, Bufftime, inctime, Mintime, Setweeks} from 'constants/sales/meeting'
import SvgIcon from 'components/common/SvgIcon'

const Availabilty = props => {
  const {
    errors,
    Controller,
    control,
    getValues,
    removeMeetingAvailableTimes,
    appendMeetingAvailableTimes,
    meetingAvalTime,
    style,
  } = props

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Availability</h3>
            <p className="kooper-lead">
              Manage your available times during which the user will be able to book meeting
            </p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField width={12}>
                <label>Time Zone</label>
                <Controller
                  name="meetingTimeZone"
                  render={({onChange, value}) => (
                    <TimezoneSelect labelStyle="altName" value={value} onChange={value => onChange(value)} />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField width={12} className="meettabel">
                <label>Available Times</label>
                <VmoTable className="tabletime">
                  <VmoTableBody>
                    {meetingAvalTime?.map((item, index) => {
                      return (
                        <VmoTableRow key={item.id}>
                          <VmoTableCell textAlign="left" width={4}>
                            <Controller
                              control={control}
                              name={`meetingAvailableTimes[${index}].day`}
                              defaultValue={item.day}
                              render={({onChange}) => (
                                <VmoDropdown
                                  placeholder="select day"
                                  selection
                                  fluid
                                  options={Avdate}
                                  onChange={(e, {value}) => {
                                    onChange(value)
                                  }}
                                  defaultValue={item.day}
                                  error={
                                    _.get(errors, `meetingAvailableTimes[${index}].day`) && {
                                      content: _.get(errors, `meetingAvailableTimes[${index}].day.message`),
                                    }
                                  }
                                />
                              )}
                            />
                          </VmoTableCell>
                          <VmoTableCell width={1}>from</VmoTableCell>
                          <VmoTableCell width={4}>
                            <Controller
                              control={control}
                              name={`meetingAvailableTimes[${index}].from`}
                              defaultValue={item.from}
                              render={({onChange, value}) => (
                                <VmoDatePicker
                                  onChange={value => {
                                    onChange(value)
                                  }}
                                  defaultValue={typeof value === 'string' ? new Date(value) : value}
                                  max={getValues(`meetingAvailableTimes[${index}].to`)}
                                  className="vmo-picker"
                                  step={60}
                                  date={false}
                                  inputProps={{
                                    component: props => <input {...props} readOnly />,
                                  }}
                                />
                              )}
                            />
                          </VmoTableCell>
                          <VmoTableCell textAlign="center" width={1}>
                            to
                          </VmoTableCell>
                          <VmoTableCell width={4}>
                            <Controller
                              control={control}
                              name={`meetingAvailableTimes[${index}].to`}
                              defaultValue={item.to}
                              render={({onChange, value}) => (
                                <VmoDatePicker
                                  onChange={value => {
                                    onChange(value)
                                  }}
                                  defaultValue={typeof value === 'string' ? new Date(value) : value}
                                  min={moment()
                                    .set({
                                      h: (getValues(`meetingAvailableTimes[${index}].from`)?.getHours() + 1).toString(),
                                      m: getValues(`meetingAvailableTimes[${index}].from`)?.getMinutes(),
                                    })
                                    .toDate()}
                                  className="vmo-picker"
                                  step={60}
                                  date={false}
                                  inputProps={{
                                    component: props => <input {...props} readOnly />,
                                  }}
                                />
                              )}
                            />
                          </VmoTableCell>
                          {index > 0 && (
                            <VmoTableCell>
                              <VmoTooltip
                                size="mini"
                                position="top center"
                                content="Delete"
                                trigger={
                                  <VmoButton icon onClick={() => removeMeetingAvailableTimes(index)}>
                                    <SvgIcon path="common/delete" />
                                  </VmoButton>
                                }
                              />
                            </VmoTableCell>
                          )}
                        </VmoTableRow>
                      )
                    })}
                    <VmoTooltip
                      size="mini"
                      position="top center"
                      content="Add"
                      trigger={
                        <VmoButton
                          icon
                          className="ml-4"
                          onClick={() => {
                            appendMeetingAvailableTimes({
                              day: 'mon-fri',
                              from: new Date('2020 09:00'),
                              to: new Date('2020 17:00'),
                            })
                          }}
                        >
                          <SvgIcon path="common/plus" />
                        </VmoButton>
                      }
                    />
                  </VmoTableBody>
                </VmoTable>
              </VmoFormField>
              <VmoFormField width={12}>
                <label>When meeting can be scheduled</label>
                {/* <VmoFormField>
                  <VmoFormGroup inline>
                    <Controller
                      name="meetingScheduled"
                      render={({value}) => (
                        <VmoRadio
                          label="Over a period of rolling weeks"
                          name="radioGroup"
                          value={value}
                          checked={value}
                        />
                      )}
                      control={control}
                    />
                    <VmoRadio
                      disabled
                      className="ml-3"
                      label="Custom date range"
                      name="radioGroup"
                      value="tt"
                      // style={{ marginTop: '5px' }}
                    />
                  </VmoFormGroup>
                </VmoFormField> */}
                <p className="kooper-lead">
                  Set a period of rolling weeks, or a customer date range, people can book in your calender
                </p>
                <Controller
                  name="meetingScheduleValue"
                  render={({onChange, value}) => (
                    <VmoFormDropdown
                      placeholder="Select weeks"
                      selection
                      value={value}
                      options={Setweeks}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.meetingScheduled && {
                          content: errors.meetingScheduled.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField width={12}>
                <div className="info-header">
                  <label>Minimum notice time</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Minimum amount of time befor a meeting you can be booked"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="meetingNoticeTime"
                  render={({onChange, value}) => (
                    <VmoFormDropdown
                      placeholder="Select Notice Time"
                      selection
                      value={value}
                      options={Mintime}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.meetingNoticeTime && {
                          content: errors.meetingNoticeTime.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField width={12}>
                <div className="info-header">
                  <label>Buffer time</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Padding around your meeting you can't be booked for"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="meetingBufferTime"
                  render={({onChange, value}) => (
                    <VmoFormDropdown
                      placeholder="Select Buffer Time"
                      selection
                      value={value}
                      options={Bufftime}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.meetingBufferTime && {
                          content: errors.meetingBufferTime.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField width={12}>
                <div className="info-header">
                  <label>Start time increment</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Set the frequency of your meeting start times"
                    size="mini"
                    position="top center"
                  />
                </div>
                <Controller
                  name="meetingTimeFrequency"
                  render={({onChange, value}) => (
                    <VmoFormDropdown
                      placeholder="Select Notice Time"
                      selection
                      options={inctime}
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                      }}
                      error={
                        errors.meetingTimeFrequency && {
                          content: errors.meetingTimeFrequency.message,
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </VmoTabPane>
  )
}

export default Availabilty
