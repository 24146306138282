/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {FiArrowUp, FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import moment from 'moment'
import {
  VmoModal,
  VmoModalHeader,
  VmoModalContent,
  VmoButton,
  VmoHeader,
  VmoIcon,
  VmoLabel,
  VmoHeaderSubheader,
  VmoHeaderContent,
  VmoTab,
  VmoTabPane,
  VmoFormGroup,
  VmoForm,
  VmoFormField,
  VmoInput,
  VmoGrid,
  VmoGridColumn,
  VmoDropdown,
  VmoDropdownMenu,
  VmoDropdownItem,
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoTooltip,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoCard,
  VmoCardContent,
  VmoRating,
  VmoList,
  VmoListItem,
  VmoCheckbox,
  VmoListContent,
  VmoListHeader,
  VmoListDescription,
} from 'vmo-library'

import {
  ADD_CONTACT_WEBSITE,
  ADD_EMAIL_CONTACT,
  ADD_NOTE_CONTACT,
  ADD_PHONE_CONTACT,
  ATTACHMENT_POST_CONTACT,
  CREATE_TAG,
  CREATE_TASK,
  DELETE_CONTACT_EMAIL,
  DELETE_CONTACT_PHONE,
  DELETE_CONTACT_WEBSITE,
  EDIT_CONTACT,
  EDIT_TASK,
  GET_SINGLE_CONTACT,
  UPDATE_CONTACT_WEBSITE,
  UPDATE_EMAIL_CONTACT,
  UPDATE_PHONE_CONTACT,
} from 'actions/types'
import {editContact, getSingleContact, uploadAttachmentsContact} from 'actions/contacts'
import {
  createTagSettings,
  addContactEmail,
  updateContactEmail,
  deleteContactEmail,
  addContactWebsite,
  updateContactWebsite,
  deleteContactWebsite,
  addContactPhone,
  updateContactPhone,
  deleteContactPhone,
} from 'actions/multiple_fields'
import {
  get,
  GetFormattedDate,
  getPermissionTooltipContent,
  KooperCountryList,
  removeDoubleQuotes,
  wrapText,
} from 'utils/helper'
import CreateTask from 'components/pages/Projects/Tasks/CreateTask'
import CreateLabel from 'components/common/CreateLabel'
import AccountUserImage from 'components/common/UserImage'
import {commonEmail, commonPhone, commonWebsite} from 'components/common/CommonDispatch'
import Email from 'components/common/Email'
import Phone from 'components/common/Phone'
import Website from 'components/common/Website'
import PreviewTimeline from 'components/common/PreviewTimeline'
import Tags from 'components/common/Tags'
import SocialIcons from 'components/pages/Socials/SocialIcon'
import AddNote from 'components/common/AddNote'
import {editTaskActivities, getTaskActivities} from 'actions/activities'
import ViewTask from 'components/pages/Projects/Tasks/ViewTask'
import {editContactSchema} from 'validation/Customers/contacts.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const PreviewModal = props => {
  const {isModalOpen, setIsModalOpen, getContactId} = props
  const refForm = useRef()
  const dispatch = useDispatch()

  const [userOption, setUserOption] = useState([])
  const [isNoteOpen, setIsNoteOpen] = useState(false)
  const [isEmailOpen, setIsEmailOpen] = useState(false)
  const [isTaskOpen, setIsTaskOpen] = useState(false)
  const [typeList, setTypeList] = useState([])
  const [activeIndexId, setActiveIndexId] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [listOfLable, setListOfLabel] = useState([])
  const [labelOptionsState, setLabelOptionsState] = useState([])
  const [newLabelModal, setNewLabelModal] = useState(false)
  const [isViewTaskOpen, setIsViewTaskOpen] = useState(false)
  const [company, setCompany] = useState('')

  const [statusOptionList, setStatusOptionList] = useState([])
  const [contactCompanyList, setContactCompanyList] = useState([])
  const {
    singleContact = {},

    contactStatusListData,
    getAllContactCompanyList,

    getTypeListData,
  } = useSelector(state => state.contacts)
  const {
    interactions: getAllInterActionsData = [],
    interactionTime = '',
    labels: getSingleContactTags,
    allActivities = [],
  } = singleContact
  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getTagList} = useSelector(state => get(['multipleFields'], state))

  const {contactsAndCompaniesPermissions, tasksPermissions} = useUserPermissions()
  const manageContactsCompanyPermissions = contactsAndCompaniesPermissions?.manage_contacts_company
  const manageTasksPermissions = tasksPermissions?.manage_tasks

  // useForm
  const initialPreviewData = {
    firstName: get(['firstName'], singleContact, ''),
    lastName: get(['lastName'], singleContact, ''),
    emails: get(['emails'], singleContact, [{category: 'work', email: ''}]),
    phones: get(['phones'], singleContact, [{category: 'work', phone: ''}]),
    websites: get(['websites'], singleContact, [{category: 'work', url: ''}]),
    // companyId: get(["companyId"], singleContact, null),
    // company: get(["company"], singleContact, ""),
    title: get(['title'], singleContact, ''),
    description: get(['description'], singleContact, ''),
    address: {
      streetAddress: get(['address', 'streetAddress'], singleContact, ''),
      locality: get(['address', 'locality'], singleContact, ''),
      state: get(['address', 'state'], singleContact, ''),
      country: get(['address', 'country'], singleContact, ''),
      postalCode: get(['address', 'postalCode'], singleContact, ''),
    },
    contactTypeId: get(['contactTypeId'], singleContact, null),
    assigneeId: get(['assigneeId'], singleContact, null),
    statusId: get(['statusId'], singleContact, ''),
  }
  const {
    control,
    errors,
    reset,
    getValues,

    handleSubmit,
    formState: {dirtyFields},
  } = useForm({
    shouldFocusError: true,
    mode: 'onTouched',
    reValidateMode: 'onChange',
    submitFocusError: true,
    defaultValues: {
      firstName: '',
      lastName: '',
      emails: [],
      phones: [],
      websites: [],
      // companyId: null,
      // company: "",
      title: '',
      description: '',
      address: {},
      assigneeId: null,
      statusId: null,
      sourceId: null,
      labelIds: [],
    },
    resolver: joiResolver(editContactSchema),
  })

  const {
    fields: emailFields,
    append: appendEmail,
    remove: removeEmail,
  } = useFieldArray({
    control,
    name: 'emails',
  })
  const {
    fields: phoneFields,
    append: appendPhone,
    remove: removePhone,
  } = useFieldArray({
    control,
    name: 'phones',
  })
  const {
    fields: websiteFields,
    append: appendwebsite,
    remove: removeWebsite,
  } = useFieldArray({
    control,
    name: 'websites',
  })
  const {getUsersList = {}} = useSelector(state => state.settings)

  useEffect(() => {
    if (getContactId) {
      dispatch(getSingleContact(getContactId))
    }
  }, [getContactId])

  // tags full code
  useEffect(() => {
    if (getSingleContactTags) {
      const formated = getSingleContactTags.map(label => ({
        value: label.id,
        text: label.label,
        color: label.color,
      }))
      setListOfLabel(formated || [])
    } else {
      setListOfLabel([])
    }
  }, [getSingleContactTags])

  useEffect(() => {
    if (getAllContactCompanyList) {
      const companyList = getAllContactCompanyList.map(list => ({
        key: list.id,
        value: list.id,
        text: list.name,
      }))
      setContactCompanyList(companyList)
    }
  }, [getAllContactCompanyList])

  useEffect(() => {
    if (getTagList) {
      const newData = getTagList.map(label => ({
        value: label.id,
        key: label.id,
        text: label.label,
        label: {
          style: {background: label.color},
          empty: true,
          circular: true,
        },
      }))
      setLabelOptionsState(newData)
    }
  }, [getTagList])

  const createNewLabel = () => {
    if (newLabelModal) {
      return (
        <CreateLabel
          isModalOpen={newLabelModal}
          setIsModalOpen={setNewLabelModal}
          createMethod={data => dispatch(createTagSettings(data))}
          // leadIds={[getContactId]}
        />
      )
    }
  }

  // assignee code
  useEffect(() => {
    if (getUsersList) {
      const assigneeList = getUsersList.users.map(list => ({
        key: list.id,
        value: list.id,
        text: `${list.firstName} ${list.lastName}`,
      }))
      setUserOption(assigneeList)
    }
  }, [getUsersList])

  // status code
  useEffect(() => {
    if (contactStatusListData) {
      const list = contactStatusListData.map(item => ({
        value: item.id,
        text: item.status,
        key: item.id,
      }))
      setStatusOptionList(list)
    }
  }, [contactStatusListData])

  useEffect(() => {
    if (getTypeListData) {
      const companyList = getTypeListData.map(list => ({
        key: list.id,
        value: list.id,
        text: list.type,
      }))
      setTypeList(companyList)
    }
  }, [getTypeListData])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_CONTACT)) {
      reset(initialPreviewData)
    }
  }, [successLabels, reset])

  useEffect(() => {
    if (successLabels.includes(ADD_NOTE_CONTACT)) {
      setIsNoteOpen(false)
    }
    if (successLabels.includes(ADD_EMAIL_CONTACT)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_EMAIL_CONTACT)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_EMAIL)) {
      removeEmail()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(ADD_PHONE_CONTACT)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_PHONE_CONTACT)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_PHONE)) {
      removePhone()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(ADD_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(UPDATE_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(DELETE_CONTACT_WEBSITE)) {
      removeWebsite()
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (successLabels.includes(CREATE_TAG)) {
      setNewLabelModal(false)
    }
    if (successLabels.includes(EDIT_CONTACT)) {
      reset(initialPreviewData, {dirtyFields: false})
    }
    if (
      successLabels.includes(ATTACHMENT_POST_CONTACT) ||
      successLabels.includes(CREATE_TASK) ||
      successLabels.includes(EDIT_TASK)
    ) {
      dispatch(getSingleContact(getContactId))
    }
  }, [successLabels, reset])

  const formStateVal = dirtyFields

  const updateContact = data => {
    const dirtyFields = _.pick(data, Object.keys(formStateVal))

    if (dirtyFields.emails) {
      commonEmail({
        dispatch,
        id: getContactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactEmail,
        updateApi: updateContactEmail,
        removeEmail,
      })
    } else if (dirtyFields.phones) {
      commonPhone({
        dispatch,
        id: getContactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactPhone,
        updateApi: updateContactPhone,
        removePhone,
      })
    } else if (dirtyFields.websites) {
      commonWebsite({
        dispatch,
        id: getContactId,
        data,
        singleData: singleContact,
        formStateVal,
        addApi: addContactWebsite,
        updateApi: updateContactWebsite,
        removeWebsite,
      })
    } else if (!_.isEmpty(dirtyFields)) {
      dispatch(editContact(dirtyFields, getContactId))
    }
  }

  const panes = [
    {
      menuItem: {key: 'details', icon: 'info', content: 'DETAILS'},
      render: () => (
        <VmoTabPane attached={false}>
          <VmoForm
            ref={refForm}
            className="errorLabel"
            {...(manageContactsCompanyPermissions && {
              onSubmit: handleSubmit(updateContact),
            })}
          >
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <label>First Name</label>

                <Controller
                  name="firstName"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Type name..."
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                      error={
                        errors.firstName && {
                          content: removeDoubleQuotes(errors.firstName.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField>
                <label>Last Name</label>

                <Controller
                  name="lastName"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Last Name"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.lastName && {
                          content: removeDoubleQuotes(errors.lastName.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <Email
                  id={getContactId}
                  deleteEmail={deleteContactEmail}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  emailFields={emailFields}
                  appendEmail={appendEmail}
                  removeEmail={removeEmail}
                  disabled={!manageContactsCompanyPermissions}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <label>Company</label>
                <VmoDropdown
                  placeholder="Company"
                  selection
                  fluid
                  search
                  additionPosition="bottom"
                  allowAdditions
                  additionLabel={<i>Add Company: </i>}
                  onChange={(e, {value}) => {
                    if (!manageContactsCompanyPermissions) return
                    setCompany(value)
                    if (typeof value === 'number') {
                      dispatch(editContact({companyId: value}, getContactId))
                    } else {
                      dispatch(editContact({company: value}, getContactId))
                    }
                  }}
                  onAddItem={(e, {value}) => {
                    setCompany(value)
                    setContactCompanyList([{value, key: value, text: value}, ...contactCompanyList])
                  }}
                  value={_.get(singleContact, 'companyId')}
                  options={contactCompanyList}
                  disabled={!manageContactsCompanyPermissions}
                />
              </VmoFormField>
              <VmoFormField>
                <label>Title</label>

                <Controller
                  name="title"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormInput
                      maxLength={20}
                      type="text"
                      fluid
                      value={value}
                      placeholder="Title"
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onBlur={e => {
                        onBlur(e)
                        refForm.current.handleSubmit()
                      }}
                      error={
                        errors.title && {
                          content: removeDoubleQuotes(errors.title.message),
                        }
                      }
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          e.target.blur()
                        }
                      }}
                      disabled={!manageContactsCompanyPermissions}
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormGroup widths="equal">
              <VmoFormField>
                <Phone
                  id={getContactId}
                  deletePhone={deleteContactPhone}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  phoneFields={phoneFields}
                  appendPhone={appendPhone}
                  removePhone={removePhone}
                  disabled={!manageContactsCompanyPermissions}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <Website
                  id={getContactId}
                  deleteWebsite={deleteContactWebsite}
                  refForm={refForm}
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  websiteFields={websiteFields}
                  appendwebsite={appendwebsite}
                  removeWebsite={removeWebsite}
                  disabled={!manageContactsCompanyPermissions}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormField>
              <label>Address</label>

              <Controller
                name="address.streetAddress"
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    type="text"
                    fluid
                    value={value}
                    placeholder="Street Address"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={e => {
                      onBlur(e)
                      refForm.current.handleSubmit()
                    }}
                    error={
                      errors?.address?.streetAddress && {
                        content: removeDoubleQuotes(errors?.address?.streetAddress?.message),
                      }
                    }
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                    disabled={!manageContactsCompanyPermissions}
                  />
                )}
                control={control}
              />
              <VmoGrid className="mt-2">
                <VmoGridColumn width={4}>
                  <Controller
                    name="address.locality"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormInput
                        type="text"
                        fluid
                        value={value}
                        placeholder="Locality"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        onBlur={e => {
                          onBlur(e)
                          refForm.current.handleSubmit()
                        }}
                        error={
                          errors?.address?.locality && {
                            content: removeDoubleQuotes(errors?.address?.locality?.message),
                          }
                        }
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        disabled={!manageContactsCompanyPermissions}
                      />
                    )}
                    control={control}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={4}>
                  <Controller
                    name="address.state"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormInput
                        type="text"
                        fluid
                        value={value}
                        placeholder="State"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        onBlur={e => {
                          onBlur(e)
                          refForm.current.handleSubmit()
                        }}
                        error={
                          errors?.address?.state && {
                            content: removeDoubleQuotes(errors?.address?.state?.message),
                          }
                        }
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        disabled={!manageContactsCompanyPermissions}
                      />
                    )}
                    control={control}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={4}>
                  <Controller
                    name="address.country"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormSelect
                        search
                        fluid
                        options={KooperCountryList}
                        selection
                        placeholder="Country"
                        value={value}
                        onChange={(e, {value}) => {
                          onChange(value)
                          refForm.current.handleSubmit()
                        }}
                        onBlur={onBlur}
                        disabled={!manageContactsCompanyPermissions}
                        error={
                          errors?.address?.country && {
                            content: removeDoubleQuotes(errors?.address?.country?.message),
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoGridColumn>
                <VmoGridColumn width={4}>
                  <Controller
                    name="address.postalCode"
                    render={({onChange, value, onBlur}) => (
                      <VmoFormInput
                        type="text"
                        fluid
                        value={value}
                        placeholder="Postal code"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        onBlur={e => {
                          onBlur(e)
                          refForm.current.handleSubmit()
                        }}
                        error={
                          errors?.address?.postalCode && {
                            content: removeDoubleQuotes(errors?.address?.postalCode?.message),
                          }
                        }
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            e.target.blur()
                          }
                        }}
                        disabled={!manageContactsCompanyPermissions}
                      />
                    )}
                    control={control}
                  />
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
            <VmoFormField>
              <label>Description</label>
              <Controller
                name="description"
                render={({onChange, value, onBlur}) => (
                  <VmoFormTextArea
                    maxLength={200}
                    type="text"
                    value={value}
                    placeholder="Description"
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        e.target.blur()
                      }
                    }}
                    onBlur={e => {
                      onBlur(e)
                      refForm.current.handleSubmit()
                    }}
                    error={
                      errors?.description && {
                        content: removeDoubleQuotes(errors?.description?.message),
                      }
                    }
                    disabled={!manageContactsCompanyPermissions}
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoForm>
        </VmoTabPane>
      ),
    },
    {
      menuItem: {key: 'timeline', icon: 'calendar', content: 'TIMELINE'},
      render: () => (
        <VmoTabPane attached={false}>
          <PreviewTimeline allActivities={allActivities} />
        </VmoTabPane>
      ),
    },
    {
      menuItem: {key: 'related', icon: 'list', content: 'RELATED'},
      render: () => (
        <VmoTabPane attached={false}>
          <VmoForm
            ref={refForm}
            className="editForm errorLabel"
            {...(manageContactsCompanyPermissions && {
              onSubmit: handleSubmit(updateContact),
            })}
          >
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <label className="kooper-lead">Last Contacted</label>
                <h5 className="m-0">{interactionTime ? GetFormattedDate(interactionTime) : null}</h5>
              </VmoFormField>
              <VmoFormField>
                <label className="kooper-lead">Interactions</label>
                <h5 className="m-0">{getAllInterActionsData.length > 0 ? getAllInterActionsData.length : null}</h5>
              </VmoFormField>
              <VmoFormField>
                <label className="kooper-lead">Inactive For</label>
                <h5 className="m-0">{interactionTime ? moment(interactionTime).fromNow(true) : '-'}</h5>
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <label>Contact Type</label>

                <Controller
                  name="contactTypeId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      fluid
                      options={typeList}
                      selection
                      placeholder="Contact type"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)

                        refForm.current.handleSubmit()
                      }}
                      onBlur={onBlur}
                      disabled={!manageContactsCompanyPermissions}
                      error={
                        errors?.contactTypeId && {
                          content: removeDoubleQuotes(errors?.contactTypeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
              <VmoFormField>
                <label>Status</label>

                <Controller
                  name="statusId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={statusOptionList}
                      selection
                      placeholder="Status"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      onBlur={onBlur}
                      disabled={!manageContactsCompanyPermissions}
                      error={
                        errors?.statusId && {
                          content: removeDoubleQuotes(errors?.statusId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
            <VmoFormGroup widths="equal">
              <VmoFormField>
                <label>Assignee</label>

                <Controller
                  name="assigneeId"
                  render={({onChange, value, onBlur}) => (
                    <VmoFormSelect
                      search
                      fluid
                      options={userOption}
                      selection
                      placeholder="Owner"
                      value={value}
                      onChange={(e, {value}) => {
                        onChange(value)
                        refForm.current.handleSubmit()
                      }}
                      disabled={!manageContactsCompanyPermissions}
                      onBlur={onBlur}
                      error={
                        errors?.assigneeId && {
                          content: removeDoubleQuotes(errors?.assigneeId?.message),
                        }
                      }
                    />
                  )}
                  control={control}
                />
              </VmoFormField>
            </VmoFormGroup>
          </VmoForm>

          <VmoCard fluid>
            <VmoCardContent className="p-0">
              <VmoAccordion styled className="kooper-accordion">
                <VmoAccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tags ({listOfLable.length !== 0 ? listOfLable.length : '0'})
                  <span>{activeIndex === 0 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </VmoAccordionTitle>

                <VmoAccordionContent active={activeIndex === 0} className="pt-0">
                  {listOfLable.length === 0 ? <p className="kooper-lead mb-0">No Tags</p> : null}

                  <Tags
                    id={getContactId}
                    data={singleContact}
                    tagsUpdate={editContact}
                    Controller={Controller}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    rolePermissions={manageContactsCompanyPermissions}
                  />
                </VmoAccordionContent>
              </VmoAccordion>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent className="p-0">
              <VmoAccordion styled className="kooper-accordion">
                <VmoAccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Tasks ({get(['tasks'], singleContact, []).length})
                  <span>{activeIndex === 1 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </VmoAccordionTitle>

                <VmoAccordionContent active={activeIndex === 1}>
                  <VmoList className="recentConv">
                    {get(['tasks'], singleContact, []).length > 0
                      ? get(['tasks'], singleContact, [])
                          .slice(0, 3)
                          .map(task => (
                            <VmoListItem>
                              <VmoIcon
                                style={{
                                  paddingTop: '4px',
                                }}
                              >
                                <VmoCheckbox
                                  onClick={() => {
                                    if (!manageContactsCompanyPermissions) return
                                    let data = {}

                                    if (task.status === 'closed') {
                                      data = {
                                        status: 'open',
                                      }
                                    } else {
                                      data = {
                                        status: 'closed',
                                      }
                                    }

                                    dispatch(editTaskActivities(task.id, data))
                                  }}
                                  checked={task.status === 'closed'}
                                />
                              </VmoIcon>
                              <VmoListContent>
                                <VmoListHeader
                                  as="a"
                                  style={
                                    task.status === 'closed'
                                      ? {
                                          textDecoration: 'line-through',
                                        }
                                      : null
                                  }
                                  onClick={() => {
                                    if (manageContactsCompanyPermissions) {
                                      dispatch(getTaskActivities(task.id))
                                      setIsViewTaskOpen(true)
                                    }
                                  }}
                                >
                                  {task.subject ? wrapText(task.subject, 20) : ''}
                                  <span
                                    style={{
                                      float: 'right',
                                    }}
                                  >
                                    {get(['firstName'], task.assignee, '-')} &nbsp;
                                    {get(['lastName'], task.assignee, '-')}
                                  </span>
                                </VmoListHeader>
                                {task.status === 'closed' ? null : (
                                  <VmoListDescription>
                                    Due:
                                    {GetFormattedDate(get(['endAt'], task, ''))}
                                  </VmoListDescription>
                                )}
                              </VmoListContent>
                            </VmoListItem>
                          ))
                      : ''}
                  </VmoList>
                  {get(['tasks'], singleContact, []).length === 0 ? <p className="kooper-lead mb-0">No Task</p> : null}
                  <VmoTooltip
                    content={
                      manageContactsCompanyPermissions
                        ? 'Add Task'
                        : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
                    }
                    size="mini"
                    position="top center"
                    trigger={
                      <VmoButton
                        className="mt-3 ml-0 delete-forecast"
                        icon
                        onClick={() => manageContactsCompanyPermissions && setIsTaskOpen(!isTaskOpen)}
                      >
                        <SvgIcon path="common/plus" />
                      </VmoButton>
                    }
                  />
                </VmoAccordionContent>
              </VmoAccordion>
            </VmoCardContent>
          </VmoCard>

          <VmoCard fluid>
            <VmoCardContent className="p-0">
              <VmoAccordion styled className="kooper-accordion">
                <VmoAccordionTitle
                  active={activeIndex === 2}
                  index={2}
                  onClick={(e, titleProps) => {
                    const {index} = titleProps
                    const newIndex = activeIndex === index ? -1 : index
                    setActiveIndex(newIndex)
                  }}
                >
                  Files ({_.size(_.get(singleContact, 'attachments'))})
                  <span>{activeIndex === 2 ? <SvgIcon path="common/down" /> : <FiChevronRight />}</span>
                </VmoAccordionTitle>

                <VmoAccordionContent active={activeIndex === 2} className="pt-0">
                  <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                    <VmoInput
                      id="fileUpload"
                      type="file"
                      multiple
                      disabled={!manageContactsCompanyPermissions}
                      onChange={e => {
                        const data = {
                          file: e.target.files,
                        }
                        dispatch(uploadAttachmentsContact(getContactId, data))
                      }}
                    />
                  </LockPermissionTooltip>
                  {get(['attachments'], singleContact, []).map(item => (
                    <div>{item.name}</div>
                  ))}
                  {get(['attachments'], singleContact, []).length === 0 && <p className="kooper-lead">No Files</p>}
                </VmoAccordionContent>
              </VmoAccordion>
            </VmoCardContent>
          </VmoCard>
        </VmoTabPane>
      ),
    },
  ]

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      className="kooper-modal"
      open={isModalOpen}
      onClose={() => setIsModalOpen(!isModalOpen)}
    >
      <VmoModalHeader>Preview Contact</VmoModalHeader>
      <VmoModalContent scrolling style={{maxHeight: 'calc(100vh - 47.5px)'}}>
        <VmoHeader className="basicNameHeader mb-0">
          <VmoIcon>
            <AccountUserImage />
          </VmoIcon>
          <VmoHeaderContent className="p-0">
            <h4 className="mb-0">{`${_.get(singleContact, 'firstName') || ''} ${
              _.get(singleContact, 'lastName') || ''
            }`}</h4>
            <div className="d-flex">
              <VmoTooltip
                size="mini"
                position="bottom center"
                content={
                  manageContactsCompanyPermissions
                    ? singleContact.isFollowed
                      ? 'Remove Favorite'
                      : 'Add Favorite'
                    : getPermissionTooltipContent({roleAccess: manageContactsCompanyPermissions})
                }
                trigger={
                  <VmoRating
                    icon="star"
                    className="icon button"
                    onClick={() =>
                      manageContactsCompanyPermissions &&
                      dispatch(editContact({isFollowed: !singleContact.isFollowed}, getContactId))
                    }
                    rating={singleContact.isFollowed ? 1 : 0}
                  />
                }
              />
              <VmoTooltip
                size="mini"
                position="bottom center"
                content="Leads score to identify potential customers"
                trigger={
                  <VmoLabel size="small" circular color="green" className="leadScore">
                    65 <FiArrowUp />
                  </VmoLabel>
                }
              />
            </div>
            <VmoHeaderSubheader>
              {`${_.get(singleContact, 'title') || ''} ${_.get(singleContact, 'company.name') || ''}`}
            </VmoHeaderSubheader>
          </VmoHeaderContent>
        </VmoHeader>
        <div className="d-flexb mr-3" style={{height: 46}}>
          <SocialIcons
            singleContact={singleContact}
            id={getContactId}
            identifier="contacts"
            rolePermissions={manageContactsCompanyPermissions}
          />
          <div className="mailActivity">
            <VmoDropdown button icon="chevron down" text="More Action">
              <VmoDropdownMenu>
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <VmoDropdownItem onClick={() => manageContactsCompanyPermissions && setIsNoteOpen(!isNoteOpen)}>
                    Add Note
                  </VmoDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageContactsCompanyPermissions}>
                  <VmoDropdownItem onClick={() => manageContactsCompanyPermissions && setIsEmailOpen(!isEmailOpen)}>
                    Add Email
                  </VmoDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageTasksPermissions}>
                  <VmoDropdownItem onClick={() => manageTasksPermissions && setIsTaskOpen(!isTaskOpen)}>
                    Add Task
                  </VmoDropdownItem>
                </LockPermissionTooltip>
              </VmoDropdownMenu>
            </VmoDropdown>
          </div>
        </div>
        <VmoTab
          className="preview-tabs"
          menu={{color: 'blue', secondary: true, pointing: true}}
          panes={panes}
          onTabChange={(e, data) => setActiveIndexId(data.activeIndex)}
        />
      </VmoModalContent>
      {/* <VmoModalActions>
        <VmoButton basic color="light" onClick={() => setIsModalOpen(false)}>
          Cancel
        </VmoButton>
      </VmoModalActions> */}
      {isNoteOpen && <AddNote isNoteOpen={isNoteOpen} contactId={getContactId} setIsNoteOpen={setIsNoteOpen} />}
      {isTaskOpen && (
        <CreateTask
          setIsCreateTaskOpen={setIsTaskOpen}
          isCreateTaskOpen={isTaskOpen}
          conversation={get(['conversation'], props)}
          contactId={getContactId}
          isDataAvailabel
        />
      )}
      {createNewLabel()}
      {isViewTaskOpen && <ViewTask setIsViewTaskOpen={setIsViewTaskOpen} isViewTaskOpen={isViewTaskOpen} />}
    </VmoModal>
  )
}

export default PreviewModal
