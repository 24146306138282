import React, {useEffect} from 'react'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoContainer,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormTextArea,
  VmoInput,
  VmoList,
  VmoListContent,
  VmoListDescription,
  VmoListHeader,
  VmoListItem,
  VmoRadio,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import {startCase} from 'utils/helper'
import {formFieldValidation} from 'constants/sales/meeting'
import SvgIcon from 'components/common/SvgIcon'

const FormQuestion = props => {
  const {errors, control, Controller, watch, setValue, formFields, removeMeetingForm, appendMeetingForm, style} = props

  const watchPrePopulate = watch('formFieldWithKnownValue')
  const watchtermsAndCondition = watch('termsAndCondition')

  useEffect(() => {
    if (!watchPrePopulate) {
      setValue('submitFormIfAllKnownValue', false)
    }
  }, [watchPrePopulate, setValue])

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Form Question</h3>
            <p className="mt-0 mb-4 kooper-lead">
              Manage your form questions which will be asked to the user booking the meeting
            </p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField width={10}>
                <div className="info-header">
                  <label>Form Questions</label>
                  <VmoTooltip
                    trigger={<SvgIcon path="common/question" />}
                    content="Ask prospects and customer a few qualifying questions as they are booking meeting with you."
                    size="mini"
                    position="top center"
                  />
                </div>
                {formFields?.map((item, index) => {
                  return (
                    <VmoFormGroup inline key={item.id}>
                      <VmoFormField width={6}>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].field`}
                          defaultValue={item.field}
                          render={({onChange, value}) => (
                            <VmoInput
                              readOnly={!(index > 2)}
                              defaultValue={startCase(value)}
                              placeholder={startCase(value)}
                              onChange={(e, {value}) => onChange(value)}
                            />
                          )}
                        />
                      </VmoFormField>

                      <VmoFormField width={5}>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].vaildation`}
                          defaultValue={item.vaildation}
                          render={({onChange, value}) => (
                            <VmoDropdown
                              selection
                              options={formFieldValidation}
                              value={value}
                              onChange={(e, {value}) => onChange(value)}
                            />
                          )}
                        />
                      </VmoFormField>
                      <VmoFormField width={3} Required>
                        <Controller
                          control={control}
                          name={`meetingFormFields[${index}].require`}
                          defaultValue={item.require}
                          render={({onChange, value}) => (
                            <VmoCheckbox
                              checked={value}
                              onChange={(e, {checked}) => onChange(checked)}
                              disabled={!(index > 2)}
                              label="Required"
                            />
                          )}
                        />
                      </VmoFormField>
                      {index > 2 && (
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Delete"
                          trigger={
                            <VmoButton icon onClick={() => removeMeetingForm(index)}>
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      )}
                    </VmoFormGroup>
                  )
                })}
                <VmoFormField>
                  <VmoButton
                    basic
                    onClick={() => {
                      appendMeetingForm({
                        field: 'Note',
                        require: false,
                        vaildation: 'text',
                      })
                    }}
                  >
                    Add Field
                  </VmoButton>
                </VmoFormField>
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">Pre-populate Fields</h3>
            <p className="mt-0 mb-4 kooper-lead">Manage your pre populate fields settings for user</p>
            <VmoForm className="mt-4 errorLabel">
              <VmoFormField width={12}>
                <VmoCard fluid>
                  <VmoCardContent>
                    <VmoList relaxed="very" divided>
                      <VmoListItem>
                        <VmoListContent>
                          <Controller
                            name="formFieldWithKnownValue"
                            render={({onChange, value}) => (
                              <VmoRadio
                                toggle
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <VmoListHeader style={{marginBottom: '4px'}}>
                            Pre-populate fields with known values
                          </VmoListHeader>
                          <VmoListDescription>
                            Control whether or not forms pre-populate with know information when a contact returns to
                            your site.
                          </VmoListDescription>
                        </VmoListContent>
                      </VmoListItem>
                    </VmoList>
                  </VmoCardContent>
                  <VmoCardContent>
                    <VmoList relaxed="very" divided>
                      <VmoListItem>
                        <VmoListContent>
                          <Controller
                            name="submitFormIfAllKnownValue"
                            render={({onChange, value}) => (
                              <VmoRadio
                                toggle
                                disabled={!watchPrePopulate}
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <VmoListHeader style={{marginBottom: '4px'}}>
                            Auto-submit form when all fields are pre-populate
                          </VmoListHeader>
                          <VmoListDescription>
                            Control whether or not your contact will see this form when all fields are pre-populate
                          </VmoListDescription>
                        </VmoListContent>
                      </VmoListItem>
                    </VmoList>
                  </VmoCardContent>
                  <VmoCardContent>
                    <VmoList relaxed="very" divided>
                      <VmoListItem>
                        <VmoListContent>
                          <Controller
                            name="termsAndCondition"
                            render={({onChange, value}) => (
                              <VmoRadio
                                toggle
                                style={{float: 'right'}}
                                onChange={(e, {checked}) => onChange(checked)}
                                checked={value}
                              />
                            )}
                            control={control}
                          />
                          <VmoListHeader style={{marginBottom: '4px'}}>Privacy and consent (GDRP)</VmoListHeader>
                          <VmoListDescription>
                            Add consent checkbox and text to your from to follow privacy laws and regulations
                            {watchtermsAndCondition && (
                              <VmoForm className="mt-4 errorLabel">
                                <VmoFormField>
                                  <div className="info-header">
                                    <label>Privacy Title</label>
                                    <VmoTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <Controller
                                    name="privacyTitle"
                                    render={({onChange, value}) => (
                                      <VmoFormInput
                                        fluid
                                        value={value}
                                        placeholder="Privacy Title"
                                        onChange={e => {
                                          onChange(e.target.value)
                                        }}
                                        error={
                                          errors.privacyTitle && {
                                            content: errors.privacyTitle.message,
                                          }
                                        }
                                      />
                                    )}
                                    control={control}
                                  />
                                </VmoFormField>
                                <VmoFormField>
                                  <div className="info-header">
                                    <label>Privacy Text</label>
                                    <VmoTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <Controller
                                    name="privacyText"
                                    render={({onChange, value}) => (
                                      <VmoFormTextArea
                                        maxlength="300"
                                        placeholder="Privacy Text"
                                        value={value}
                                        onChange={(e, {value}) => onChange(value)}
                                      />
                                      // <VmoEditorSmall
                                      //   setContents={value}
                                      //   onChange={(content) => {
                                      //     onChange(content);
                                      //   }}
                                      //   width="100%"
                                      //   setOptions={{
                                      //     // defaultStyle: `font-size: ${inboxFontSize}px; font-family: ${inboxFont};`,
                                      //     buttonList: [
                                      //       [
                                      //         'bold',
                                      //         'underline',
                                      //         'italic',
                                      //         'fontColor',
                                      //         'hiliteColor',
                                      //         'indent',
                                      //       ],
                                      //     ],
                                      //   }}
                                      // />
                                    )}
                                    control={control}
                                  />
                                </VmoFormField>
                                <VmoFormField required>
                                  <div className="info-header">
                                    <label>Terms & Condition checkbox text</label>
                                    <VmoTooltip
                                      content="Lorem ipsum"
                                      size="mini"
                                      position="top center"
                                      trigger={<SvgIcon path="common/question" />}
                                    />
                                  </div>
                                  <VmoFormGroup inline>
                                    <VmoFormField width={16}>
                                      <Controller
                                        name="privacyCheck"
                                        render={({onChange, value}) => (
                                          <VmoFormInput
                                            fluid
                                            value={value}
                                            width="16"
                                            placeholder="Privacy Check"
                                            onChange={e => {
                                              onChange(e.target.value)
                                            }}
                                            error={
                                              errors.privacyCheck && {
                                                content: errors.privacyCheck.message,
                                              }
                                            }
                                          />
                                        )}
                                        control={control}
                                      />
                                    </VmoFormField>
                                  </VmoFormGroup>
                                </VmoFormField>
                              </VmoForm>
                            )}
                          </VmoListDescription>
                        </VmoListContent>
                      </VmoListItem>
                    </VmoList>
                  </VmoCardContent>
                </VmoCard>
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>
      </VmoContainer>
    </VmoTabPane>
  )
}

export default FormQuestion
