/* eslint-disable no-nested-ternary */
import CreateFirst from 'components/common/CreateFirst'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import create from 'assets/create.svg'
import _ from 'lodash'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoIcon,
  VmoLabel,
  VmoMenu,
  VmoMenuItem,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
} from 'vmo-library'
import {DELETE_BUDGET, GET_BUDGET_LIST, UPDATE_BUDGET_STATUS} from 'actions/types'
import {getBudgetlist, deleteBudget, updateBudgetStatus} from 'actions/budget'
import SearchComponent from 'components/common/SearchComponent'
import DeleteModal from 'components/common/DeleteModal'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const BudgetsPlanner = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    category: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {isLoading} = useApiLoader({label: GET_BUDGET_LIST})
  const {financeAccess} = useUserPermissions()
  const budgetPlannerAccess = financeAccess?.budgetPlanner
  const {financePermissions} = useUserPermissions()
  const manageBudgetPlannerPermissions = financePermissions?.manage_budget_planner

  const {data: budgetPlannerList = []} = useApiResponse({
    action: getBudgetlist,
    enabled: budgetPlannerAccess,
    dependency: [budgetPlannerAccess],
    label: GET_BUDGET_LIST,
    storePath: 'budget.budgetPlannerList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_BUDGET) || successLabels.includes(UPDATE_BUDGET_STATUS)) {
      dispatch(getBudgetlist())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    if (!budgetPlannerAccess) return
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getBudgetlist(newCurrentListParams))
  }, [budgetPlannerAccess, currentListParams, dispatch])

  const renderTableBody = () => {
    return budgetPlannerList.map(
      ({id, name, rangePeriod, compareWith, startMonth, startYear, endMonth, endYear, rangeTotal, isActive}, index) => {
        return (
          <VmoTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              history.push(`/budgets-planner/new/${id}`)
            }}
          >
            <VmoTableCell>{index + 1}</VmoTableCell>
            <VmoTableCell>{startCase(name)}</VmoTableCell>
            <VmoTableCell>{startCase(compareWith)}</VmoTableCell>
            <VmoTableCell>{startCase(rangePeriod)}</VmoTableCell>
            <VmoTableCell>{`${startMonth || ''}-${startYear || ''}`}</VmoTableCell>
            <VmoTableCell>{`${endMonth || ''}-${endYear || ''}`}</VmoTableCell>
            <VmoTableCell>{rangeTotal}</VmoTableCell>
            <VmoTableCell>
              <VmoLabel className="kooper-label" color={isActive ? 'green' : 'red'}>
                {isActive ? 'Active' : 'inActive'}
              </VmoLabel>
            </VmoTableCell>
            <VmoTableCell>
              <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
                <VmoDropdownMenu>
                  <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                    <VmoDropdownItem
                      onClick={() =>
                        manageBudgetPlannerPermissions &&
                        dispatch(
                          updateBudgetStatus(id, {
                            isActive: !isActive,
                          })
                        )
                      }
                    >
                      <VmoIcon name={isActive ? 'eye slash' : 'share square'} />
                      {isActive ? 'InActive' : 'Active'}
                    </VmoDropdownItem>
                  </LockPermissionTooltip>

                  <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                    <VmoDropdownItem
                      onClick={() => {
                        if (manageBudgetPlannerPermissions) {
                          setEditId(id)
                          setDeleteModal(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" /> Delete
                    </VmoDropdownItem>
                  </LockPermissionTooltip>
                </VmoDropdownMenu>
              </VmoDropdown>
            </VmoTableCell>
          </VmoTableRow>
        )
      }
    )
  }

  if (isLoading && budgetPlannerAccess) {
    return <PageLoader />
  }

  return (
    <>
      {budgetPlannerList.length > 0 || currentListParams.text ? (
        <>
          <VmoMenu vertical secondary className="kooper-menu">
            <VmoMenuItem
              active={window.location.pathname.includes('/budgets-planner')}
              onClick={() => history.push('/budgets-planner')}
            >
              All Budget plan
            </VmoMenuItem>
          </VmoMenu>

          <div className="kooper-side-wrap">
            <div className="page-header">
              <div className="page-title">
                <div className="page-titleIcon">
                  <SvgIcon path="navigation/budget-planner" />
                  <h5>Budget Planner List ({budgetPlannerList ? budgetPlannerList.length : 0})</h5>
                </div>
              </div>
              <div className="page-action">
                <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
                <LockPermissionTooltip isRoleAccessDenied={!manageBudgetPlannerPermissions}>
                  <VmoButton
                    className={!manageBudgetPlannerPermissions ? 'disabled-button' : ''}
                    content="Add"
                    primary
                    onClick={() => manageBudgetPlannerPermissions && history.push('/budgets-planner/new')}
                  />
                </LockPermissionTooltip>
              </div>
            </div>
            <VmoTable sortable basic selectable>
              <VmoTableHeader>
                <VmoTableRow>
                  <VmoTableHeaderCell>#</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Compare With</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Range Period</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Start Month Year</VmoTableHeaderCell>
                  <VmoTableHeaderCell>End Month Year</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Range Total</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Status</VmoTableHeaderCell>
                  <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
                </VmoTableRow>
              </VmoTableHeader>
              <VmoTableBody>
                {isLoadingData && type.includes(GET_BUDGET_LIST) ? (
                  <VmoTablePlaceholder columns={9} />
                ) : (budgetPlannerList || []).length > 0 ? (
                  renderTableBody()
                ) : (
                  <tr>
                    <td colSpan={9} className="emptyValue">
                      No Data Available
                    </td>
                  </tr>
                )}
              </VmoTableBody>
            </VmoTable>

            {deleteModal && (
              <DeleteModal
                isModalOpen={deleteModal}
                setIsModalOpen={setDeleteModal}
                modalText="Are you sure you want to delete ?"
                deleteAction={deleteBudget}
                idTobeDeleted={editId}
                type={DELETE_BUDGET}
              />
            )}
          </div>
        </>
      ) : (
        <CreateFirst
          header="Budgets Planner"
          addFirst={() => budgetPlannerAccess && manageBudgetPlannerPermissions && history.push(`/budgets-planner/new`)}
          buttonText="Create"
          tooltip={!budgetPlannerAccess || !manageBudgetPlannerPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: budgetPlannerAccess,
            roleAccess: manageBudgetPlannerPermissions,
          })}
          imageSrc={create}
          subHeader="Budget planner helps to create budgets in a simpler and easier way"
          Feature1="Employee reimbursement"
          list1="Eliminate costly process of reimbursing employees easily"
          Feature2="Budget planning period"
          list2="Set custom time period for budget planning"
          Feature3="Comparison"
          list3="Pick an entity and compared to budget in real time"
        />
      )}
    </>
  )
}

export default BudgetsPlanner
