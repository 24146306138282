import {startCase} from 'utils/helper'
import {apiAction} from './api'
import {PROJECTS_ENDPOINT} from './endpoint'
import {
  ARCHIVED_PROJECT,
  ARCHIVE_PROJECT_DESCUSSION_TOPIC,
  CLONE_PROJECT,
  COPY_PROJECTS_NOTEBOOK,
  COPY_PROJECTS_NOTEBOOK_NOTE,
  COPY_PROJECTS_TASKS_LIST_TASK,
  COPY_PROJECTS_TASK_LIST,
  CREATE_PROJECT,
  CREATE_PROJECTS_NOTEBOOK,
  CREATE_PROJECTS_NOTEBOOK_NOTE,
  CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  CREATE_PROJECTS_TASKS_LIST_TASK,
  CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT,
  CREATE_PROJECTS_TIMESHEET,
  CREATE_PROJECTS_TIMESHEET_TIME_LOG,
  CREATE_PROJECT_DISCUSSION_TOPIC,
  CREATE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  CREATE_PROJECT_FINANCE_EXPENSE,
  CREATE_PROJECT_FINANCE_BUDGET,
  CREATE_PROJECT_TASKS_LIST,
  DELETE_PROJECT,
  DELETE_PROJECTS_NOTEBOOK,
  DELETE_PROJECTS_NOTEBOOK_NOTE,
  DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  DELETE_PROJECTS_TASKS_LIST,
  DELETE_PROJECTS_TASKS_LIST_TASK,
  DELETE_PROJECTS_TIMESHEET,
  DELETE_PROJECTS_TIMESHEET_TIME_LOGS,
  DELETE_PROJECT_DISCUSSION_TOPIC,
  DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  DELETE_PROJECT_FINANCE_BUDGET,
  EXPORT_PROJECTS_TIMESHEET,
  FAVOURITE_PROJECT,
  GET_PROJECTS_FILTERS,
  GET_PROJECTS_LIST,
  GET_PROJECTS_LOOKUP,
  GET_PROJECTS_NOTEBOOKS,
  GET_PROJECTS_NOTEBOOK_NOTES,
  GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
  GET_PROJECTS_SINGLE_NOTEBOOK,
  GET_PROJECTS_SINGLE_TASKS_LIST,
  GET_PROJECTS_SINGLE_TIMESHEET,
  GET_PROJECTS_TASKS_LIST,
  GET_PROJECTS_TASKS_LIST_FILTER,
  GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
  GET_PROJECTS_TASKS_LIST_TASKS,
  GET_PROJECTS_TIMESHEETS,
  GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG,
  GET_PROJECTS_TIMESHEET_SUMMARY,
  GET_PROJECTS_TIMESHEET_TIME_LOGS,
  GET_PROJECTS_TIME_TRACKING,
  GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
  GET_PROJECT_DISCUSSION_TOPICS,
  GET_PROJECT_DISCUSSION_TOPIC_COMMENTS,
  GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST,
  GET_PROJECT_FINANCE_BUDGET,
  GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST,
  GET_PROJECT_FINANCE_RATES_LIST,
  GET_PROJECT_FINANCE_SINGLE_RATE,
  GET_SINGLE_PROJECT,
  MOVE_PROJECTS_NOTEBOOK,
  MOVE_PROJECTS_NOTEBOOK_NOTE,
  MOVE_PROJECTS_TASKS_LIST_TASK,
  MOVE_PROJECTS_TASK_LIST,
  PIN_PROJECT_DESCUSSION_TOPIC,
  PIN_PROJECT_NOTEBOOK,
  STATUS_PROJECTS_TASKS_LIST,
  STATUS_PROJECTS_TASKS_LIST_TASK,
  STATUS_PROJECTS_TIMESHEET,
  STATUS_PROJECTS_TIMESHEET_TIME_LOGS,
  UPDATE_PROJECT,
  UPDATE_PROJECTS_NOTEBOOK,
  UPDATE_PROJECTS_NOTEBOOK_NOTE,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION,
  UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS,
  UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
  UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE,
  UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE,
  UPDATE_PROJECTS_TIMESHEET,
  UPDATE_PROJECTS_TIMESHEET_TIME_LOG,
  UPDATE_PROJECT_DISCUSSION_TOPIC,
  UPDATE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS,
  UPDATE_PROJECT_FINANCE_BUDGET,
  UPDATE_PROJECT_FINANCE_SINGLE_RATE,
  UPDATE_PROJECT_PEOPLE,
  UPDATE_PROJECT_TASKS_LIST,
  GET_PROJECT_FINANCE_SINGLE_EXPENSE,
  UPDATE_PROJECT_FINANCE_EXPENSE,
  DELETE_PROJECT_FINANCE_EXPENSE,
  CREATE_PROJECTS_TASKS_LIST_STAGE,
  UPDATE_PROJECTS_TASKS_LIST_STAGE,
  GET_PROJECTS_TASKS_LIST_SINGLE_STAGE,
  DELETE_PROJECTS_TASKS_LIST_STAGE,
  MOVE_PROJECTS_TASKS_LIST_STAGE,
  UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET,
  GET_PROJECT_FINANCE_BUDGET_PROFITABILITY,
  GET_PROJECT_FINANCE_BUDGET_OVERVIEW,
  GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST,
  CREATE_PROJECT_FINANCE_BILLING_INVOICE,
  GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST,
  GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST,
  DELETE_PROJECT_FINANCE_BILLING_INVOICE,
  GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE,
  UPDATE_PROJECT_FINANCE_BILLING_INVOICE,
  GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS,
  MOVE_PROJECT_FINANCE_BILLING_ITEMS,
  COMPLETE_PROJECT_FINANCE_BILLING_INVOICE,
  REOPEN_PROJECT_FINANCE_BILLING_INVOICE,
  GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW,
  COMPLETE_PROJECTS_TASKS_LIST_TASK,
  UPDATE_PROJECT_STATUS,
  CREATE_PROJECT_RISK,
  EDIT_PROJECT_RISK,
  GET_SINGLE_PROJECT_RISK,
  GET_ALL_PROJECT_RISKS,
  DELETE_PROJECT_RISK,
  CREATE_PROJECT_MILESTONE,
  EDIT_PROJECT_MILESTONE,
  GET_SINGLE_PROJECT_MILESTONE,
  GET_ALL_PROJECT_MILESTONE,
  DELETE_PROJECT_MILESTONE,
  COPY_PROJECT_MILESTONE,
  COMPLETE_PROJECT_MILESTONE,
  ADD_PROJECT_MILESTONE_COMMENT,
  EDIT_PROJECT_MILESTONE_COMMENT,
  GET_PROJECT_MILESTONE_COMMENTS,
  DELETE_PROJECT_MILESTONE_COMMENT,
  MOVE_PROJECT_MILESTONE,
  CREATE_PROJECT_FORM,
  GET_ALL_PROJECT_FORMS,
  DELETE_PROJECT_FORM,
  GET_SINGLE_PROJECT_FORM,
  EDIT_PROJECT_FORM,
  GET_PUBLIC_PROJECT_FORMS,
  PROJECT_FORM_PUBLISHED,
  SUBMIT_PROJECT_FORM,
  CLONE_PROJECT_FORM,
  CREATE_PROJECT_TEMPLATES,
  GET_PROJECTS_TEMPLATES_LIST,
  DELETE_PROJECTS_TEMPLATE,
  GET_PROJECTS_SINGLE_TEMPLATE,
  UPDATE_PROJECTS_TEMPLATE,
  GET_PROJECTS_TEMPLATES_TASKLIST,
  CREATE_PROJECTS_TEMPLATES_TASKLIST,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST,
  GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST,
  DELETE_PROJECTS_TEMPLATES_TASKLIST,
  GET_PROJECTS_TEMPLATES_TASKLIST_TASKS,
  CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  GET_PROJECTS_TEMPLATES_FILERS,
  GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS,
  GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK,
  GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE,
  CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
  DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE,
  MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
  CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET,
  GET_PROJECTS_TEMPLATES_FINANCE_BUDGET,
  DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET,
  GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST,
  GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
  UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
} from './types'

export function createProject(data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}`,
    method: 'POST',
    label: CREATE_PROJECT,
    data,
    showToast: true,
    successMessage: 'Project Created',
  })
}

export function getProjectsList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/?${query}`,
    method: 'GET',
    label: GET_PROJECTS_LIST,
  })
}

export function getProjectsLookup(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key].join(',')}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/lookup/?${query}`,
    method: 'GET',
    label: GET_PROJECTS_LOOKUP,
  })
}

export function getProjectsFilters(projectId, params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key].join(',')}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${projectId}/filters?${query}`,
    method: 'GET',
    label: GET_PROJECTS_FILTERS,
  })
}

export function getSingleProject(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_SINGLE_PROJECT,
  })
}

export function updateProject(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${id}`,
    method: 'PUT',
    label: UPDATE_PROJECT,
    data,
    showToast: true,
    successMessage: 'Project Updated',
  })
}

export function deleteProject(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key].join(',')}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}?${query}`,
    method: 'DELETE',
    label: DELETE_PROJECT,
    showToast: true,
    successMessage: 'Project Deleted',
  })
}

export function cloneProject(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${id}/clone`,
    method: 'GET',
    label: CLONE_PROJECT,
    showToast: true,
    successMessage: 'Project Cloned',
  })
}

export function updateProjectStatus(projectId, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/status?id=${projectId}`,
    method: 'PATCH',
    label: UPDATE_PROJECT_STATUS,
    data,
    showToast: true,
    successMessage: `Project Status ${startCase(data.status)}`,
  })
}

export function archivedProject(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/archive?id=${id}`,
    method: 'PATCH',
    label: ARCHIVED_PROJECT,
    showToast: true,
    successMessage: 'Project Archived',
  })
}

export function favouriteProject(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${id}/favourite`,
    method: 'PATCH',
    data,
    label: FAVOURITE_PROJECT,
    showToast: true,
    successMessage: 'Project Updated',
  })
}

export function updateProjectPeople(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/${id}/manage-poeple`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECT_PEOPLE,
    showToast: true,
    successMessage: 'Project People Updated',
  })
}

export function getProjectDiscussionTopics(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics`,
    method: 'GET',
    label: GET_PROJECT_DISCUSSION_TOPICS,
  })
}

export function createProjectDiscussionTopic({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics`,
    method: 'POST',
    label: CREATE_PROJECT_DISCUSSION_TOPIC,
    data,
    showToast: true,
    successMessage: 'Topic Created',
  })
}

export function getProjectDiscussionSingleTopic({projectId, topicId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}`,
    method: 'GET',
    label: GET_PROJECT_DISCUSSION_SINGLE_TOPIC,
  })
}

export function updateProjectDiscussionTopic({projectId, topicId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECT_DISCUSSION_TOPIC,
    showToast: true,
    successMessage: 'Topic Updated',
  })
}

export function deleteProjectDiscussionTopic({projectId, topicId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_DISCUSSION_TOPIC,
    showToast: true,
    successMessage: 'Topic Deleted',
  })
}

export function updateProjectDiscussionTopicSubscribers({projectId, topicId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/subscribers`,
    method: 'PATCH',
    data,
    label: UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS,
    showToast: true,
    successMessage: 'Subscribers Updated',
  })
}

export function statusProjectDiscussionTopic({projectId, topicId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/status`,
    method: 'PATCH',
    data,
    label: ARCHIVE_PROJECT_DESCUSSION_TOPIC,
    showToast: true,
    successMessage: data.status === 'active' ? 'Topic Unarchived' : 'Topic Archived',
  })
}

export function pinProjectDiscussionTopic({projectId, topicId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/pin`,
    method: 'PATCH',
    data,
    label: PIN_PROJECT_DESCUSSION_TOPIC,
    showToast: true,
    successMessage: `Topic ${data.isPinned ? 'Pinned' : 'Unpinned'}`,
  })
}

export function getProjectDiscussionTopicComments({projectId, topicId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/comments`,
    method: 'GET',
    label: GET_PROJECT_DISCUSSION_TOPIC_COMMENTS,
  })
}

export function createProjectDiscussionTopicComment({projectId, topicId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/comments`,
    method: 'POST',
    data,
    label: CREATE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  })
}

export function updateProjectDiscussionTopicComment({projectId, topicId, commentId, data}) {
  return apiAction({
    // url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/comments/${commentId}`,
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/comments/${commentId}`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  })
}

export function deleteProjectDiscussionTopicComment({projectId, topicId, commentId}) {
  return apiAction({
    // url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/topics/${topicId}/comments/${commentId}`,
    url: `${PROJECTS_ENDPOINT}/discussions/${projectId}/comments/${commentId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT,
  })
}

export function createProjectsTasksList({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist`,
    method: 'POST',
    label: CREATE_PROJECT_TASKS_LIST,
    data,
    showToast: true,
    successMessage: 'Tasks List Created',
  })
}

export function getProjectsTasksList(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LIST,
  })
}

export function getProjectsSingleTasksList({projectId, taskListId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_TASKS_LIST,
  })
}

export function updateProjectsTasksList({projectId, taskListId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}`,
    method: 'PUT',
    label: UPDATE_PROJECT_TASKS_LIST,
    data,
    showToast: true,
    successMessage: 'Tasks List Updated',
  })
}

export function deleteProjectsTasksList({projectId, taskListId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TASKS_LIST,
    showToast: true,
    successMessage: 'Tasks List Deleted',
  })
}

export function statusProjectsTasksList({projectId, taskListId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}/status`,
    method: 'PATCH',
    data,
    label: STATUS_PROJECTS_TASKS_LIST,
    showToast: true,
    successMessage: data.status === 'active' ? 'Tasks List Unarchived' : 'Tasks List Archived',
  })
}

export function copyProjectsTasksList({projectId, taskListId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}/clone`,
    method: 'POST',
    label: COPY_PROJECTS_TASK_LIST,
    data,
    showToast: true,
    successMessage: 'Tasks list copy created',
  })
}

export function moveProjectsTasksList({projectId, taskListId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}/move`,
    method: 'POST',
    label: MOVE_PROJECTS_TASK_LIST,
    data,
    showToast: true,
    successMessage: 'Tasks list moved',
  })
}

export function getProjectsTasksListFilter({projectId, taskListId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/${taskListId}/filter`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LIST_FILTER,
  })
}

export function getProjectsTasksListTasks({projectId, taskListId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/list/${taskListId}?${query}`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LIST_TASKS,
  })
}

export function createProjectsTasksListTask({projectId, taskListId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskListId}`,
    method: 'POST',
    label: CREATE_PROJECTS_TASKS_LIST_TASK,
    data,
    showToast: true,
    successMessage: 'Task Created',
  })
}

export function updateProjectsTasksListTaskStage({projectId, cardId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${cardId}/stages`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE,
    data,
    showToast: true,
    successMessage: 'Task Stage Updated',
  })
}

export function getProjectTasksListSingleTask({projectId, taskId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
  })
}

export function updateProjectTaskListSingleTask({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
    data,
    showToast: true,
    successMessage: 'Task Updated',
  })
}

export function updateProjectsTasksListTaskAssignee({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/assignees`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE,
    data,
    showToast: true,
    successMessage: 'Task Assignee Updated',
  })
}

export function deleteProjectsTasksListTask({projectId, taskId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TASKS_LIST_TASK,
    showToast: true,
    successMessage: 'Task Deleted',
  })
}

export function copyProjectsTasksListTask({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/clone`,
    method: 'POST',
    label: COPY_PROJECTS_TASKS_LIST_TASK,
    data,
    showToast: true,
    successMessage: 'Task Copied',
  })
}

export function moveProjectsTasksListTask({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/move`,
    method: 'POST',
    label: MOVE_PROJECTS_TASKS_LIST_TASK,
    data,
    showToast: true,
    successMessage: 'Task Moved',
  })
}

export function statusProjectsTasksListTask({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/status`,
    method: 'PATCH',
    label: STATUS_PROJECTS_TASKS_LIST_TASK,
    data,
    showToast: true,
    successMessage: data.status === 'active' ? 'Task Unarchived' : 'Task Archived',
  })
}

export function completeProjectTasksListTask({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/complete`,
    method: 'PATCH',
    label: COMPLETE_PROJECTS_TASKS_LIST_TASK,
    data,
    showToast: true,
    successMessage: data.isCompleted ? 'Task Completed' : 'Task Uncompleted',
  })
}

export function createProjectsTasksListTaskComment({projectId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/${taskId}/comments`,
    method: 'POST',
    label: CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT,
    data,
    showToast: true,
    successMessage: 'Comment Added',
  })
}

// Notes - Notebook
export function createProjectsNotebook({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks`,
    method: 'POST',
    label: CREATE_PROJECTS_NOTEBOOK,
    data,
    showToast: true,
    successMessage: 'Notebook Created',
  })
}

export function getProjectsNotebooks(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks`,
    method: 'GET',
    label: GET_PROJECTS_NOTEBOOKS,
  })
}

export function getProjectsSingleNotebook({projectId, notebookId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_NOTEBOOK,
  })
}

export function updateProjectsNotebook({projectId, notebookId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_NOTEBOOK,
    data,
    showToast: true,
    successMessage: 'Notebook Updated',
  })
}

export function deleteProjectsNotebook({projectId, notebookId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_NOTEBOOK,
    showToast: true,
    successMessage: 'Notebook Deleted',
  })
}

export function copyProjectsNotebook({projectId, notebookId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}/clone`,
    method: 'POST',
    label: COPY_PROJECTS_NOTEBOOK,
    data,
    showToast: true,
    successMessage: 'Notebook Copied',
  })
}

export function moveProjectsNotebook({projectId, notebookId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}/move`,
    method: 'POST',
    label: MOVE_PROJECTS_NOTEBOOK,
    data,
    showToast: true,
    successMessage: 'Notebook Moved',
  })
}

export function pinProjectsNotebook({projectId, notebookId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}/pin`,
    method: 'PATCH',
    label: PIN_PROJECT_NOTEBOOK,
    data,
    showToast: true,
    successMessage: `Notebook ${data.pinToTop ? 'Pinned' : 'Unpinned'}`,
  })
}

// Notes - Note
export function createProjectsNotebookNote({projectId, notebookId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}`,
    method: 'POST',
    label: CREATE_PROJECTS_NOTEBOOK_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Created',
  })
}

export function getProjectsNotebookNotes({projectId, notebookId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/notebooks/${notebookId}/all?${query}`,
    method: 'GET',
    label: GET_PROJECTS_NOTEBOOK_NOTES,
  })
}

export function getProjectsNotebookSingleNote({projectId, noteId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}`,
    method: 'GET',
    label: GET_PROJECTS_NOTEBOOK_SINGLE_NOTE,
  })
}

export function updateProjectsNotebookNote({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_NOTEBOOK_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Updated',
  })
}

export function deleteProjectsNotebookNote({projectId, noteId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_NOTEBOOK_NOTE,
    showToast: true,
    successMessage: 'Note Deleted',
  })
}

export function updateProjectsNotebookNoteSubscribers({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}/subscribers`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS,
    data,
    showToast: true,
    successMessage: 'Note Subscribers Updated',
  })
}

export function copyProjectsNotebookNote({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}/clone`,
    method: 'POST',
    label: COPY_PROJECTS_NOTEBOOK_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Copied',
  })
}

export function moveProjectsNotebookNote({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}/move`,
    method: 'POST',
    label: MOVE_PROJECTS_NOTEBOOK_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Moved',
  })
}

export function updateProjectsNotebookNoteDescription({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}/description`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION,
    data,
    showToast: true,
    successMessage: 'Note Saved',
  })
}

export function createProjectsNotebookNoteComment({projectId, noteId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/${noteId}/comments`,
    method: 'POST',
    label: CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
    data,
    showToast: true,
    successMessage: 'Comment Created',
  })
}

export function updateProjectsNotebookNoteComment({projectId, commentId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/comments/${commentId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
    data,
    showToast: true,
    successMessage: 'Comment Updated',
  })
}

export function deleteProjectsNotebookNoteComment({projectId, commentId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/notes/${projectId}/comments/${commentId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT,
    showToast: true,
    successMessage: 'Comment Deleted',
  })
}

// Time - Timesheet
export function createProjectsTimesheet({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}`,
    method: 'POST',
    label: CREATE_PROJECTS_TIMESHEET,
    data,
    showToast: true,
    successMessage: 'Timesheet Created',
  })
}

export function getProjectsTimeSheets(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}`,
    method: 'GET',
    label: GET_PROJECTS_TIMESHEETS,
  })
}

export function getProjectsSingleTimesheet({projectId, timesheetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_TIMESHEET,
  })
}

export function updateProjectsTimesheet({projectId, timesheetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TIMESHEET,
    data,
    showToast: true,
    successMessage: 'Timesheet Updated',
  })
}

export function deleteProjectsTimesheet({projectId, timesheetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TIMESHEET,
    showToast: true,
    successMessage: 'Timesheet Deleted',
  })
}

export function statusProjectsTimesheet({projectId, timesheetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/status`,
    method: 'PATCH',
    label: STATUS_PROJECTS_TIMESHEET,
    data,
    showToast: true,
    successMessage: data.status === 'active' ? 'Timesheet Unarchived' : 'Timesheet Archived',
  })
}

export function createProjectsTimesheetTimeLog({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/times`,
    method: 'POST',
    label: CREATE_PROJECTS_TIMESHEET_TIME_LOG,
    data,
    showToast: true,
    successMessage: 'Time log Created',
  })
}

export function getProjectsTimesheetTimeLogs({projectId, timesheetId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/times/?${query}`,
    method: 'GET',
    label: GET_PROJECTS_TIMESHEET_TIME_LOGS,
  })
}

export function getProjectsTimesheetSummary({projectId, timesheetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/summary`,
    method: 'GET',
    label: GET_PROJECTS_TIMESHEET_SUMMARY,
  })
}

export function getProjectsTimesheetSingleTimeLog({projectId, timesheetId, timeId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/times/${timeId}`,
    method: 'GET',
    label: GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG,
  })
}

export function updateProjectsTimesheetTimeLog({projectId, timesheetId, timeId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/times/${timeId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TIMESHEET_TIME_LOG,
    data,
    showToast: true,
    successMessage: 'Time Log Updated',
  })
}

export function exportProjectsTimesheet({projectId, timesheetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/export`,
    method: 'GET',
    label: EXPORT_PROJECTS_TIMESHEET,
  })
}

export function deleteProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds}) {
  const ids = timeIds.join(',')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/times/?id=${ids}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TIMESHEET_TIME_LOGS,
    showToast: true,
    successMessage: 'Time Logs Deleted',
  })
}

export function statusProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds, data}) {
  const ids = timeIds.join(',')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/timesheets/${projectId}/${timesheetId}/times/status/?id=${ids}`,
    method: 'PATCH',
    label: STATUS_PROJECTS_TIMESHEET_TIME_LOGS,
    data,
    showToast: true,
    successMessage: 'Status Changes',
  })
}

// Time Tracking
export function getProjectsTimeTrackingList(params) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/allTime?${query}`,
    method: 'GET',
    label: GET_PROJECTS_TIME_TRACKING,
  })
}

// Finance

// Budget
export function updateProjectFinanceTasksListBudget({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/tasklist/budgets`,
    method: 'PUT',
    label: UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget Updated',
  })
}

export function getProjectFinanceBudget(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/get-budget`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BUDGET,
  })
}

export function createProjectFinanceBudget({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/add-budget`,
    method: 'POST',
    label: CREATE_PROJECT_FINANCE_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget Created',
  })
}

export function updateProjectFinanceBudget({projectId, budgetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/update-budget/${budgetId}`,
    method: 'PUT',
    label: UPDATE_PROJECT_FINANCE_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget Updated',
  })
}

export function deleteProjectFinanceBudget({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/delete-budget/${budgetId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_FINANCE_BUDGET,
    showToast: true,
    successMessage: 'Budget Deleted',
  })
}

export function getProjectFinanceBudgetOverview({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/overview`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BUDGET_OVERVIEW,
  })
}

export function getProjectFinanceBudgetProfitability({projectId, budgetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/profitability`,
    method: 'POST',
    data,
    label: GET_PROJECT_FINANCE_BUDGET_PROFITABILITY,
  })
}

export function getProjectFionanceBudgetRetainerOverview({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/retainer-overview`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW,
  })
}

export function getProjectFinanceBudgetExpenseList({projectId, budgetId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense/budgetlist?${query}`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST,
  })
}

export function createProjectFinanceExpense({projectId, budgetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense`,
    method: 'POST',
    label: CREATE_PROJECT_FINANCE_EXPENSE,
    data,
    showToast: true,
    successMessage: 'Expense Created',
  })
}

export function getProjectFinanceSingleExpense({projectId, budgetId, expenseId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense/${expenseId}`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_SINGLE_EXPENSE,
  })
}

export function updateProjectFinanceExpense({projectId, budgetId, expenseId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense/${expenseId}`,
    method: 'PUT',
    label: UPDATE_PROJECT_FINANCE_EXPENSE,
    data,
    showToast: true,
    successMessage: 'Expense Updated',
  })
}

export function deleteProjectFinanceExpense({projectId, budgetId, expenseId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense/${expenseId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_FINANCE_EXPENSE,
    showToast: true,
    successMessage: 'Expense Deleted',
  })
}

// Rates
export function getProjectFinanceRatesList({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/user-rates`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_RATES_LIST,
  })
}

export function getProjectFinanceSingleRate({projectId, budgetId, userId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/user-rate/${userId}`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_SINGLE_RATE,
  })
}

export function updateProjectFinanceSingleRate({projectId, budgetId, userId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/user-rate/${userId}`,
    method: 'PUT',
    label: UPDATE_PROJECT_FINANCE_SINGLE_RATE,
    data,
  })
}

// Billing
export function getProjectFinanceBillingOpenInvoicesList({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/open-invoices`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST,
  })
}

export function getProjectFinanceBillingCompletedInvoicesList({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/completed-invoices`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST,
  })
}

export function getProjectFinanceBillingUnbilledTimeList({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/unbilled-time`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST,
  })
}

export function getProjectFinanceBillingExpenseList({projectId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/expense/unbilledlist`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST,
  })
}

export function createProjectFinanceBillingInvoice({projectId, budgetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice`,
    method: 'POST',
    label: CREATE_PROJECT_FINANCE_BILLING_INVOICE,
    data,
    showToast: true,
    successMessage: 'Invoice Created',
  })
}

export function getProjectFinanceBillingSingleInvoice({projectId, budgetId, invoiceId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE,
  })
}

export function updateProjectFinanceBillingInvoice({projectId, budgetId, invoiceId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}`,
    method: 'PUT',
    label: UPDATE_PROJECT_FINANCE_BILLING_INVOICE,
    data,
    showToast: true,
    successMessage: 'Invoice Updated',
  })
}

export function deleteProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_FINANCE_BILLING_INVOICE,
    showToast: true,
    successMessage: 'Invoice Deleted',
  })
}

export function getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}/logs`,
    method: 'GET',
    label: GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS,
  })
}

export function moveProjectFinanceBillingItems({projectId, budgetId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/move-items`,
    method: 'POST',
    data,
    label: MOVE_PROJECT_FINANCE_BILLING_ITEMS,
    showToast: true,
    successMessage: 'Item Moved',
  })
}

export function completeProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}/mark-completed`,
    method: 'PATCH',
    label: COMPLETE_PROJECT_FINANCE_BILLING_INVOICE,
    showToast: true,
    successMessage: 'Invoice Completed',
  })
}

export function reopenProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/budgets/${projectId}/budget/${budgetId}/invoice/${invoiceId}/reopen`,
    method: 'PATCH',
    label: REOPEN_PROJECT_FINANCE_BILLING_INVOICE,
    showToast: true,
    successMessage: 'Invoice Re-opened',
  })
}

// Tasks Stage
export function createProjectsTasksListStage({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/task-stage`,
    method: 'POST',
    label: CREATE_PROJECTS_TASKS_LIST_STAGE,
    data,
    showToast: true,
    successMessage: 'Column Created',
  })
}

export function getProjectsTasksListSingleStage({projectId, stageId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/task-stage/${stageId}`,
    method: 'GET',
    label: GET_PROJECTS_TASKS_LIST_SINGLE_STAGE,
  })
}

export function updateProjectsTasksListStage({projectId, stageId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/task-stage/${stageId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TASKS_LIST_STAGE,
    data,
    showToast: true,
    successMessage: 'Column Updated',
  })
}

export function deleteProjectsTasksListStage({projectId, stageId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/task-stage/${stageId}`,
    method: 'Delete',
    label: DELETE_PROJECTS_TASKS_LIST_STAGE,
    showToast: true,
    successMessage: 'Column Deleted',
  })
}

export function moveProjectsTasksListStage({projectId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/tasks/${projectId}/task-stage/move`,
    method: 'PATCH',
    label: MOVE_PROJECTS_TASKS_LIST_STAGE,
    data,
    showToast: true,
    successMessage: 'Column moved',
  })
}

export function createProjectRisk({data, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/risk-management/${projectId}/add-risk`,
    method: 'POST',
    label: CREATE_PROJECT_RISK,
    data,
    showToast: true,
    successMessage: 'Risk Created',
  })
}

export function editProjectRisk({data, riskId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/risk-management/${projectId}/update-risk/${riskId}`,
    method: 'PUT',
    label: EDIT_PROJECT_RISK,
    data,
    showToast: true,
    successMessage: 'Risk Updated',
  })
}

export function getSingleProjectRisk({riskId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/risk-management/${projectId}/get-risk/${riskId}`,
    method: 'GET',
    label: GET_SINGLE_PROJECT_RISK,
  })
}

export function getAllProjectRisks(projectId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/risk-management/${projectId}/get-risks`,
    method: 'GET',
    label: GET_ALL_PROJECT_RISKS,
  })
}

export function deleteProjectRisk({riskId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/risk-management/${projectId}/delete-risk/${riskId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_RISK,
    showToast: true,
    successMessage: 'Risk Deleted',
  })
}

export function createProjectMilestone({data, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/add-milestone`,
    method: 'POST',
    label: CREATE_PROJECT_MILESTONE,
    data,
    showToast: true,
    successMessage: 'Milestone Created',
  })
}

export function editProjectMilestone({data, milestoneId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/update-milestone/${milestoneId}`,
    method: 'PUT',
    label: EDIT_PROJECT_MILESTONE,
    data,
    showToast: true,
    successMessage: 'Milestone Updated',
  })
}

export function getSingleProejctMilestone({milestoneId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/get-milestone/${milestoneId}`,
    method: 'GET',
    label: GET_SINGLE_PROJECT_MILESTONE,
  })
}

export function getAllProjectMilestone({projectId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/list-milestones?${query}`,
    method: 'GET',
    label: GET_ALL_PROJECT_MILESTONE,
  })
}

export function deleteProjectMilestone({milestoneId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/delete-milestone/${milestoneId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_MILESTONE,
    showToast: true,
    successMessage: 'Milestone Deleted',
  })
}

export function copyProjectMilestone({data, milestoneId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/duplicate/${milestoneId}`,
    method: 'POST',
    label: COPY_PROJECT_MILESTONE,
    data,
    showToast: true,
    successMessage: 'Milestone Cloned',
  })
}

export function completeProjectMilestone({projectId, milestoneId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/complete/${milestoneId}`,
    method: 'PATCH',
    label: COMPLETE_PROJECT_MILESTONE,
    data,
    showToast: true,
    successMessage: data.isCompleted ? 'Milestone Completed' : 'Milestone Uncompleted',
  })
}

export function addProjectMilestoneComment({projectId, milestoneId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/comments/${milestoneId}`,
    method: 'POST',
    label: ADD_PROJECT_MILESTONE_COMMENT,
    data,
    showToast: true,
    successMessage: 'Added Comment',
  })
}

export function editProjectMilestoneComment({data, milestoneId, commentId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/${milestoneId}/comments/${commentId}`,
    method: 'PUT',
    label: EDIT_PROJECT_MILESTONE_COMMENT,
    data,
    showToast: true,
    successMessage: 'Milestone Updated',
  })
}

export function getProjectMilestoneComments({milestoneId, projectId, commentId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/${milestoneId}/comments/${commentId}`,
    method: 'GET',
    label: GET_PROJECT_MILESTONE_COMMENTS,
  })
}

export function deleteProjectMilestoneComment({milestoneId, projectId, commentId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/${milestoneId}/comments/${commentId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_MILESTONE_COMMENT,
    showToast: true,
    successMessage: 'Comment Deleted',
  })
}

export function moveProjectMilestone({projectId, milestoneId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/milestones/${projectId}/move/${milestoneId}`,
    method: 'PATCH',
    label: MOVE_PROJECT_MILESTONE,
    data,
    showToast: true,
    successMessage: 'Milestone Moved',
  })
}

export function createProjectForm({data, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/add-form`,
    method: 'POST',
    label: CREATE_PROJECT_FORM,
    data,
    showToast: true,
    successMessage: 'Form Created',
  })
}

export function getAllProjectForms({projectId, params}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/list-forms?${query}`,
    method: 'GET',
    label: GET_ALL_PROJECT_FORMS,
  })
}

export function deleteProjectForm({formId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/delete-form/${formId}`,
    method: 'DELETE',
    label: DELETE_PROJECT_FORM,
    showToast: true,
    successMessage: 'Form Deleted',
  })
}

export function getSingleProjectForm({formId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/get-form/${formId}`,
    method: 'GET',
    label: GET_SINGLE_PROJECT_FORM,
  })
}

export function editProjectForm({data, formId, projectId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/update-form/${formId}`,
    method: 'PUT',
    label: EDIT_PROJECT_FORM,
    data,
    showToast: true,
    successMessage: 'Form Updated',
  })
}

export function getPublicProjectForms(formId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/public/forms/${formId}`,
    method: 'GET',
    label: GET_PUBLIC_PROJECT_FORMS,
  })
}

export function publishProjectForm({projectId, formId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/patch/${formId}`,
    method: 'PATCH',
    label: PROJECT_FORM_PUBLISHED,
    data,
    showToast: true,
    successMessage: 'Form Published',
  })
}

export function submitProjectFrom({data, formId, successMessage}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/public/form-submissions/${formId}`,
    method: 'POST',
    label: SUBMIT_PROJECT_FORM,
    data,
    showToast: true,
    successMessage,
  })
}

export function cloneProjectForm({projectId, formId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/forms/${projectId}/clone/${formId}`,
    method: 'GET',
    label: CLONE_PROJECT_FORM,
    showToast: true,
    successMessage: 'Form Cloned',
  })
}

// Project templates
export function createProjectTemplate(data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates`,
    method: 'POST',
    label: CREATE_PROJECT_TEMPLATES,
    data,
    showToast: true,
    successMessage: 'Template Created',
  })
}

export function getProjectsTemplateslist() {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_LIST,
  })
}

export function getProjectsSingleTemplate(templateId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}`,
    method: 'GET',
    label: GET_PROJECTS_SINGLE_TEMPLATE,
  })
}

export function updateProjectsTemplate({templateId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECTS_TEMPLATE,
    showToast: true,
    successMessage: 'Template Updated',
  })
}

export function deleteProjectsTemplate(templateId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TEMPLATE,
    showToast: true,
    successMessage: 'Template Deleted',
  })
}

export function getProjectsTemplatesFilters({templateId, filters = []}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/filters?filters=${filters.join(',')}`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_FILERS,
  })
}

export function getProjectsTemplatesTasklist(templateId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_TASKLIST,
  })
}

export function createProjectsTemplatesTasklist({templateId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist`,
    method: 'POST',
    label: CREATE_PROJECTS_TEMPLATES_TASKLIST,
    data,
    showToast: true,
    successMessage: 'Taskslist Created',
  })
}

export function getProjectsTemplatesSingleTasklist({templateId, tasklistId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist/${tasklistId}`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST,
  })
}

export function updateProjectsTemplatesTasklist({templateId, tasklistId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist/${tasklistId}`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECTS_TEMPLATES_TASKLIST,
    showToast: true,
    successMessage: 'Tasklist Updated',
  })
}

export function deleteProjectsTemplatesTasklist({templateId, tasklistId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist/${tasklistId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TEMPLATES_TASKLIST,
    showToast: true,
    successMessage: 'Tasklist Deleted',
  })
}

export function getProjectsTemplatesTasklistFilters({templateId, tasklistId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/tasklist/${tasklistId}/filter`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS,
  })
}

export function getProjectsTemplatesTasklistTasks({templateId, tasklistId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/${tasklistId}/task/list`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_TASKLIST_TASKS,
  })
}

export function createProjectsTemplatesTasklistTask({templateId, tasklistId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/${tasklistId}/task`,
    method: 'POST',
    data,
    label: CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
    showToast: true,
    successMessage: 'Task Created',
  })
}

export function getProjectsTemplatesTasklistSingleTask({templateId, taskId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task/${taskId}`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK,
  })
}

export function updateProjectsTemplatesTasklistTask({templateId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task/${taskId}`,
    method: 'PUT',
    data,
    label: UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK,
    showToast: true,
    successMessage: 'Task Updated',
  })
}

export function deleteProjectsTemplatesTasklistTask({templateId, taskId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task/${taskId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK,
    showToast: true,
    successMessage: 'Task Deleted',
  })
}

export function createProjectsTemplatesTaskslistStage({templateId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task-stage`,
    method: 'POST',
    label: CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
    data,
    showToast: true,
    successMessage: 'Column Created',
  })
}

export function getProjectsTemplatesTaskslistSingleStage({templateId, stageId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task-stage/${stageId}`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE,
  })
}

export function deleteProjectsTemplatesTasklistStage({templateId, stageId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task-stage/${stageId}`,
    method: 'Delete',
    label: DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
    showToast: true,
    successMessage: 'Column Deleted',
  })
}

export function updateProjectsTemplatesTasklistTaskStage({templateId, taskId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/${taskId}/stages`,
    method: 'PATCH',
    label: UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE,
    data,
    showToast: true,
    successMessage: 'Task Stage Updated',
  })
}

export function moveProjectsTemplatesTasklistStage({templateId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/task-stage/move`,
    method: 'PATCH',
    label: MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE,
    data,
    showToast: true,
    successMessage: 'Column Moved',
  })
}

export function getProjectsTemplatesFinanceBudget(templateId) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/get-budget`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_FINANCE_BUDGET,
  })
}

export function createProjectsTemplatesFinanceBudget({templateId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/budget`,
    method: 'POST',
    label: CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET,
    data,
    showToast: true,
    successMessage: 'Budget Created',
  })
}

export function deleteProjectsTemplatesFinanceBudget({templateId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/budget/${budgetId}`,
    method: 'DELETE',
    label: DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET,
    showToast: true,
    successMessage: 'Budget Deleted',
  })
}

export function getProjectsTemplatesFinanceRatesList({templateId, budgetId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/budget/${budgetId}/user-rates`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST,
  })
}

export function getProjectsTemplatesFinanceSingleRate({templateId, budgetId, userId}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/budget/${budgetId}/user-rate/${userId}`,
    method: 'GET',
    label: GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
  })
}

export function updateProjectsTemplatesFinanceSingleRate({templateId, budgetId, userId, data}) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/templates/${templateId}/budget/${budgetId}/user-rate/${userId}`,
    method: 'PUT',
    label: UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE,
    data,
    showToast: true,
    successMessage: 'User rate updated',
  })
}
