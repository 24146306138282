import React from 'react'
import {VmoButton, VmoCard, VmoCardContent, VmoForm} from 'vmo-library'
import PreviewQuestions from './PreviewQuestions'

function FormPreview({watchFormData}) {
  const {settings, questions, design} = watchFormData

  return (
    <VmoCard fluid>
      <VmoCardContent>
        <div className="design-preview-tabs">
          <div className="formContent">
            <div className="formContentBody">
              <div className="formContentBodyhead" style={{backgroundColor: design?.backgroundColor}}>
                <p>{settings?.title}</p>
              </div>
              <div className="form-preview">
                <VmoForm className="errorLabel">
                  {questions.map(q => (
                    <>
                      <PreviewQuestions {...q} />
                    </>
                  ))}
                </VmoForm>
              </div>
              <div className="preview-contentfooter">
                <VmoButton
                  primary
                  style={{backgroundColor: design?.submitButtonColor, marginTop: design?.submitButtonMargin}}
                >
                  {design?.submitButtonText}
                </VmoButton>
              </div>
            </div>
            <div className="poweredByLogo">{settings?.showBranding && <span>Powered By Kooper</span>}</div>
          </div>
        </div>
      </VmoCardContent>
    </VmoCard>
  )
}

export default FormPreview
