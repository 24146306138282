/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router'
import {AiOutlineCheck} from 'react-icons/ai'
import {FiArchive, FiChevronRight} from 'react-icons/fi'
import _ from 'lodash'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoMenu,
  VmoMenuItem,
} from 'vmo-library'

import {
  CREATE_PROJECTS_TIMESHEET,
  CREATE_PROJECTS_TIMESHEET_TIME_LOG,
  DELETE_PROJECTS_TIMESHEET,
  DELETE_PROJECTS_TIMESHEET_TIME_LOGS,
  GET_PROJECTS_TIMESHEETS,
  GET_PROJECTS_TIMESHEET_TIME_LOGS,
  STATUS_PROJECTS_TIMESHEET,
  STATUS_PROJECTS_TIMESHEET_TIME_LOGS,
  UPDATE_PROJECTS_TIMESHEET,
  UPDATE_PROJECTS_TIMESHEET_TIME_LOG,
} from 'actions/types'
import {
  deleteProjectsTimesheet,
  getProjectsFilters,
  getProjectsTimeSheets,
  getProjectsTimesheetSummary,
  getProjectsTimesheetTimeLogs,
  statusProjectsTimesheet,
} from 'actions/projects'
import {get, getPermissionTooltipContent} from 'utils/helper'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import TimesheetModal from './TimesheetModal'
import StatusTimesheetModal from './StatusTimesheetModal'
import TimesheetDetails from './TimesheetDetails'
import TimeModal from './TimeModal'

const Time = ({rolePermissions}) => {
  const {projectId} = useParams()
  const dispatch = useDispatch()
  const {state} = useLocation()

  const [isTimesheetModalOpen, setIsTimesheetModalOpen] = useState(false)
  const [active, setActive] = useState({
    active: true,
    archieve: false,
  })
  const [activeTimesheets, setActiveTimesheets] = useState([])
  const [archivedTimesheets, setArchivedTimesheets] = useState([])
  const [timesheetId, setTimesheetId] = useState(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isTimesheetStatusModalOpen, setIsTimesheetStatusModalOpen] = useState(false)
  const [currentlySelectedTimesheet, setCurrentlySelectedTimesheet] = useState(null)
  const [currentListParams, setCurrentListParams] = useState({
    status: 'all',
    createdBy: 'all',
    groupBy: 'date',
    orderBy: 'asc',
  })
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [checked, setChecked] = useState([])

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {getAllTimeLogs = {}} = useSelector(state => state.projects)

  const {isLoading: isLoadingTimeSheets} = useApiLoader({label: GET_PROJECTS_TIMESHEETS})
  const {isLoading: isLoadingTimeSheetsTimeLogs} = useApiLoader({label: GET_PROJECTS_TIMESHEET_TIME_LOGS})

  const {data: timesheetList = [], callAction: callGetProjectsTimeSheetsAction} = useApiResponse({
    action: getProjectsTimeSheets,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    label: GET_PROJECTS_TIMESHEETS,
    storePath: 'projects.timesheetList',
    onSuccess: (timesheetList = []) => {
      if (timesheetList?.length > 0) {
        const allActiveTimesheets = timesheetList.filter(timesheet => timesheet.status === 'active')
        setActiveTimesheets(allActiveTimesheets)

        const allArchivedTimesheets = timesheetList.filter(timesheet => timesheet.status === 'archived')
        setArchivedTimesheets(allArchivedTimesheets)

        if (currentlySelectedTimesheet) {
          //
        } else {
          setCurrentlySelectedTimesheet(timesheetList[0].id)
          dispatch(getProjectsTimesheetTimeLogs({projectId, timesheetId: timesheetList[0].id}))
          dispatch(getProjectsTimesheetSummary({projectId, timesheetId: timesheetList[0].id}))
        }
      }
    },
  })

  useEffect(() => {
    dispatch(getProjectsFilters(projectId, {filters: ['assignedTo']}))
  }, [dispatch, projectId])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TIMESHEET) ||
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET) ||
      successLabels.includes(STATUS_PROJECTS_TIMESHEET)
    ) {
      callGetProjectsTimeSheetsAction()
    }
    if (successLabels.includes(DELETE_PROJECTS_TIMESHEET)) {
      setCurrentlySelectedTimesheet(null)
      callGetProjectsTimeSheetsAction()
    }
  }, [successLabels, callGetProjectsTimeSheetsAction])

  useEffect(() => {
    if (currentlySelectedTimesheet) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimesheetTimeLogs({projectId, timesheetId: currentlySelectedTimesheet, params: newList}))
      dispatch(getProjectsTimesheetSummary({projectId, timesheetId: currentlySelectedTimesheet}))
    }
  }, [currentlySelectedTimesheet, currentListParams, dispatch, projectId])

  useEffect(() => {
    if (state) {
      setCurrentlySelectedTimesheet(state.id)
    }
  }, [state])

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(DELETE_PROJECTS_TIMESHEET_TIME_LOGS) ||
      successLabels.includes(STATUS_PROJECTS_TIMESHEET_TIME_LOGS)
    ) {
      setChecked([])
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimesheetTimeLogs({projectId, timesheetId: currentlySelectedTimesheet, params: newList}))
      dispatch(getProjectsTimesheetSummary({projectId, timesheetId: currentlySelectedTimesheet}))
    }
  }, [successLabels, currentListParams, dispatch, projectId, currentlySelectedTimesheet])

  if (isLoadingTimeSheets) {
    return <PageLoader />
  }

  if (!timesheetList.length) {
    return (
      <div className="setEmpty-wrap">
        {isTimesheetModalOpen && (
          <TimesheetModal open={isTimesheetModalOpen} toggle={setIsTimesheetModalOpen} projectId={projectId} />
        )}
        <CreateFirst
          header="Time"
          subHeader="Effectively log the time spent by each team member on their tasks associated with the project"
          addFirst={() => rolePermissions && setIsTimesheetModalOpen(true)}
          buttonText="Create Timesheet"
          tooltip={!rolePermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
          imageSrc={create}
          Feature1="Automatic time logging"
          list1="Use time estimation timers to track time spent by project members on each task"
          Feature2="Export timesheets"
          list2="Export timesheets for all the users in a project in CSV format"
          Feature3="Mark as private"
          list3="Make the timesheet only visible to the selected team members"
        />
      </div>
    )
  }

  const renderDropdown = timesheet => {
    return (
      <VmoDropdownMenu>
        <VmoDropdownItem
          onClick={() => {
            setTimesheetId(timesheet.id)
            setIsTimesheetModalOpen(true)
          }}
        >
          <SvgIcon path="common/edit2" /> Edit
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            setTimesheetId(timesheet.id)
            setIsDeleteModalOpen(true)
          }}
        >
          <SvgIcon path="common/delete" /> Delete
        </VmoDropdownItem>
        <VmoDropdownItem
          onClick={() => {
            if (timesheet.status === 'active') {
              setTimesheetId(timesheet.id)
              setIsTimesheetStatusModalOpen(true)
            } else {
              const data = {status: 'active'}
              dispatch(statusProjectsTimesheet({projectId, timesheetId: timesheet.id, data}))
            }
          }}
        >
          {timesheet.status === 'active' ? (
            <>
              <FiArchive /> Archive
            </>
          ) : (
            <>
              <AiOutlineCheck /> Unarchive
            </>
          )}
        </VmoDropdownItem>
      </VmoDropdownMenu>
    )
  }

  const onClickTimesheet = id => {
    if (currentlySelectedTimesheet !== id) {
      setCurrentlySelectedTimesheet(id)
      setChecked([])
    }
  }

  const renderTimesheetsList = timesheetList => {
    return timesheetList.map(timesheet => {
      return (
        <VmoMenuItem
          key={timesheet.id}
          className="project-discussion-sidemenu pr-0"
          active={timesheet.id === currentlySelectedTimesheet}
          onClick={() => onClickTimesheet(timesheet.id)}
        >
          <div className="d-flex" style={{justifyContent: 'flex-start'}}>
            {!timesheet.isPublic && <SvgIcon path="common/lock" className="mr-1" />} {get(['title'], timesheet, '')}
          </div>
          {rolePermissions && (
            <VmoDropdown
              className="project-discussion-sidemenu-dropdown"
              icon={<SvgIcon path="common/more-verticle" />}
            >
              {renderDropdown(timesheet)}
            </VmoDropdown>
          )}
        </VmoMenuItem>
      )
    })
  }

  const SideMenu = () => {
    return (
      <VmoMenu vertical secondary className="projects-side-menu kooper-menu">
        <VmoAccordion className="mt-0">
          <VmoAccordionTitle className="d-flex">
            <div
              className="d-flex"
              style={{justifyContent: 'flex-start'}}
              onClick={() => setActive({...active, active: !active.active})}
            >
              {active.active ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}
              Active
            </div>
            {rolePermissions && (
              <SvgIcon
                path="common/plus"
                onClick={() => {
                  setTimesheetId(null)
                  setIsTimesheetModalOpen(true)
                }}
              />
            )}
          </VmoAccordionTitle>
          <VmoAccordionContent active={active.active}>{renderTimesheetsList(activeTimesheets)}</VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion>
          <VmoAccordionTitle
            className="d-flex"
            style={{justifyContent: 'flex-start'}}
            onClick={() => setActive({...active, archieve: !active.archieve})}
          >
            {active.archieve ? (
              <SvgIcon path="common/down" style={{marginRight: 8}} />
            ) : (
              <FiChevronRight style={{marginRight: 8}} />
            )}
            Archived
          </VmoAccordionTitle>
          <VmoAccordionContent active={active.archieve}>{renderTimesheetsList(archivedTimesheets)}</VmoAccordionContent>
        </VmoAccordion>
      </VmoMenu>
    )
  }

  return (
    <>
      <SideMenu />

      {isLoadingTimeSheetsTimeLogs && <PageLoader />}

      {!isLoadingTimeSheetsTimeLogs &&
        (Object.keys(getAllTimeLogs).length === 0 &&
        currentListParams.status === 'all' &&
        currentListParams.createdBy === 'all' ? (
          <div className="setEmpty-wrap">
            <CreateFirst
              header="Time"
              subHeader="Effectively log the time spent by each team member on their tasks associated with the project"
              addFirst={() => rolePermissions && setIsTimeModalOpen(true)}
              buttonText="Create Time"
              tooltip={!rolePermissions}
              tooltipContent={getPermissionTooltipContent({roleAccess: rolePermissions})}
              imageSrc={create}
              Feature1="Automatic time logging"
              list1="Use time estimation timers to track time spent by project members on each task"
              Feature2="Export timesheets"
              list2="Export timesheets for all the users in a project in CSV format"
              Feature3="Mark as private"
              list3="Make the timesheet only visible to the selected team members"
            />
          </div>
        ) : (
          <TimesheetDetails
            projectId={projectId}
            timesheetId={currentlySelectedTimesheet}
            currentListParams={currentListParams}
            setCurrentListParams={setCurrentListParams}
            checked={checked}
            setChecked={setChecked}
            rolePermissions={rolePermissions}
          />
        ))}

      {isTimesheetModalOpen && (
        <TimesheetModal
          open={isTimesheetModalOpen}
          toggle={setIsTimesheetModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTimesheet({projectId, timesheetId}))}
          type={DELETE_PROJECTS_TIMESHEET}
        />
      )}

      {isTimesheetStatusModalOpen && (
        <StatusTimesheetModal
          open={isTimesheetStatusModalOpen}
          toggle={setIsTimesheetStatusModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
        />
      )}

      {isTimeModalOpen && (
        <TimeModal
          open={isTimeModalOpen}
          toggle={setIsTimeModalOpen}
          projectId={projectId}
          timesheetId={currentlySelectedTimesheet}
        />
      )}
    </>
  )
}

export default Time
