import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VmoButton, VmoForm, VmoFormField, VmoInput, VmoModal, VmoModalContent, VmoModalHeader} from 'vmo-library'
import {SEND_VERIFICATION_MAIL_INBOX} from 'actions/types'
import {copyToClipboard} from 'utils/helper'
import {sendVerificationMailInbox} from 'actions/inbox'

const EmailSetup = ({id, accountEmail, internalEmail, inboxSetupModalOpen, setInboxSetupModalOpen}) => {
  const dispatch = useDispatch()
  const textAreaRef = React.useRef(null)
  const [copyMsg, setCopyMsg] = useState('Copy')

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)

  useEffect(() => {
    if (successLabels.includes(SEND_VERIFICATION_MAIL_INBOX)) {
      setInboxSetupModalOpen(false)
    }
  }, [successLabels, setInboxSetupModalOpen])

  return (
    <VmoModal size="small" closeIcon open={inboxSetupModalOpen} onClose={() => setInboxSetupModalOpen(false)}>
      <VmoModalHeader>Forward your email</VmoModalHeader>
      <VmoModalContent className="emlIbxSetupModal">
        <VmoForm>
          <div className="email-frwd-wrapper">
            <h2 className="mb-0">For incoming Emails</h2>
            <p className="mb-3">
              Forward your <b>{accountEmail}</b> all email to below Kooper Email address...
            </p>
            <VmoFormField>
              <label htmlFor="kooperemail">Kooper Email</label>
              <VmoInput
                type="text"
                value={internalEmail}
                ref={textAreaRef}
                action={{
                  color: 'blue',
                  labelPosition: 'right',
                  icon: 'copy',
                  content: copyMsg,
                  onClick: e => copyToClipboard(e, textAreaRef, setCopyMsg),
                }}
              />
            </VmoFormField>
            <div className="email-frwd-listbox">
              <h4>Need a hand in getting setup?</h4>
              <p className="kooper-lead mt-3 mb-0">
                Quickly <a>send instructions to a teammate</a> to get your email address connected or{' '}
                <a>chat to one of our support specialists.</a>
              </p>
            </div>
          </div>
        </VmoForm>

        <VmoButton
          fluid
          primary
          className="mt-5"
          onClick={() => dispatch(sendVerificationMailInbox(parseInt(id)))}
          disabled={type.includes(SEND_VERIFICATION_MAIL_INBOX)}
        >
          I've setup my email forwarding
        </VmoButton>
        <VmoButton fluid className="basic-fill-btn my-4">
          My email provider has sent a confirmation code
        </VmoButton>
        <VmoButton onClick={() => setInboxSetupModalOpen(false)} basic>
          I'll finish my setup later
        </VmoButton>
      </VmoModalContent>
    </VmoModal>
  )
}

export default EmailSetup
