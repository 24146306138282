import {ANALYTICS_ENDPOINT} from './endpoint'
import {apiAction} from './api'
import {TOTAL_VENDORS, NEW_VENDORS, VENDORS_BY_CATEGORY, VENDORS_OVER_TIME} from './types'

export function totalVendors(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/vendors/total-vendors`,
    method: 'POST',
    label: TOTAL_VENDORS,
    data,
  })
}

export function newVendors(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/vendors/new-added-vendors`,
    method: 'POST',
    label: NEW_VENDORS,
    data,
  })
}

export function vendorsByCategory(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/vendors/vendors-by-category`,
    method: 'POST',
    label: VENDORS_BY_CATEGORY,
    data,
  })
}

export function vendorsOverTime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/finance/vendors/vendors-over-time`,
    method: 'POST',
    label: VENDORS_OVER_TIME,
    data,
  })
}
