import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {FiArrowDown, FiArrowUp, FiList, FiMessageSquare, FiPhoneCall} from 'react-icons/fi'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoIcon,
  VmoList,
  VmoListContent,
  VmoListDescription,
  VmoListHeader,
  VmoListItem,
  VmoModal,
  VmoModalContent,
  VmoModalHeader,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import moment from 'moment'
import {get, removeDoubleQuotes, startCase, wrapText} from 'utils/helper'
import _ from 'lodash'
import SvgIcon from 'components/common/SvgIcon'
import EmailEditor from '../EmailModal'
import SmsModal from '../SmsModal'
import CreateTask from '../TaskModal'

const Flow = props => {
  const {errors, control, stepList, setStepList, assignee, dayList, currentStep, watchMailBoxId, clearErrors, style} =
    props

  const [addModal, setAddModal] = useState(false)
  const [addType, setAddType] = useState(null)
  const [editData, setEditData] = useState(null)
  const [insertAt, setInsertAt] = useState(stepList.length)

  const handleAdd = data => {
    const steps = [...stepList]
    if (!editData) {
      steps.splice(insertAt, 0, {
        step: insertAt + 1,
        type: addType,
        sendAfterDays: 2,
        sendAt: '09:00:00',
        isContinueWithoutCompletion: true,
        data,
      })
      steps.map((item, index) => {
        if (index > insertAt) {
          item.step += 1
        }
      })
    } else {
      steps[insertAt].data = data
    }
    clearErrors()
    setStepList(steps)
    setEditData(null)
  }

  function move(arr, fromIndex, toIndex) {
    const array = [...arr]
    const fromElement = array[fromIndex]
    const toElement = array[toIndex]
    const temp = toElement.step
    toElement.step = fromElement.step
    fromElement.step = temp

    array[fromIndex] = toElement
    array[toIndex] = fromElement

    return array
  }

  const upDownHandle = (type, fromIndex) => {
    if (type === 'up') {
      setStepList(move(stepList, fromIndex, fromIndex - 1))
    }
    if (type === 'down') {
      setStepList(move(stepList, fromIndex, fromIndex + 1))
    }
  }

  const deleteStep = index => {
    const list = stepList.filter((step, i) => index !== i)
    list.map((item, _index) => {
      if (_index >= index) {
        item.step -= 1
      }
    })

    setStepList(list)
  }

  const getSubject = (type, data) => {
    if (type === 'task_call' || type === 'task_meeting' || type === 'task_activity') {
      return wrapText(data.subject, 50)
    }
    if (type === 'manual_email' || type === 'auto_email') {
      return wrapText(data.subject, 50)
    }
    if (type === 'auto_sms') {
      return wrapText(data.messageText, 50)
    }
  }

  const colors = {
    manual_email: '#2196F3',
    auto_email: '#4481bd',
    auto_sms: '#8BC34A',
    task_activity: '#ff9800',
    task_call: '#e03997',
    task_meeting: '#767676',
  }

  const getIcon = type => {
    if (type === 'auto_email' || type === 'manual_email' || type === 'auto_sms') {
      return <SvgIcon path="common/mail" />
    }
    if (type === 'task_call') {
      return <FiPhoneCall />
    }
    if (type === 'task_activity' || type === 'task_meeting') {
      return <SvgIcon path="settings/users" />
    }
  }

  const AddItemModal = () => {
    return (
      <VmoModal
        onClose={() => setAddModal(!addModal)}
        size="tiny"
        open={addModal}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        className="seqMailModal"
      >
        <VmoModalHeader>Select Type of Step</VmoModalHeader>
        <VmoModalContent className="p-0">
          <VmoList selection divided relaxed="very" className="createFlowList">
            <VmoListItem
              onClick={() => {
                setAddType('auto_email')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <SvgIcon path="common/mail" />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Auto Email</VmoListHeader>
                <VmoListDescription>Send an automatic email on your desired date/time</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
            <VmoListItem
              onClick={() => {
                setAddType('manual_email')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <SvgIcon path="common/mail" />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Manual Email</VmoListHeader>
                <VmoListDescription>Send a manual email on your desired date/time</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
            <VmoListItem
              onClick={() => {
                setAddType('task_call')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <FiPhoneCall />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Activity as Call</VmoListHeader>
                <VmoListDescription>Create activity as a call</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
            <VmoListItem
              onClick={() => {
                setAddType('task_meeting')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <SvgIcon path="settings/users" />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Activity as Meeting</VmoListHeader>
                <VmoListDescription>Create activity as meeting</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
            <VmoListItem
              onClick={() => {
                setAddType('task_activity')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <FiList />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Activity</VmoListHeader>
                <VmoListDescription>Create a specific activity</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
            <VmoListItem
              onClick={() => {
                setAddType('auto_sms')
                setAddModal(false)
              }}
            >
              <VmoIcon>
                <FiMessageSquare />
              </VmoIcon>
              <VmoListContent>
                <VmoListHeader>Auto SMS</VmoListHeader>
                <VmoListDescription>Send an automatic SMS</VmoListDescription>
              </VmoListContent>
            </VmoListItem>
          </VmoList>
        </VmoModalContent>
      </VmoModal>
    )
  }

  useEffect(() => {
    if (stepList.length > 0) {
      const list = [...stepList]
      list[0] = _.omit(list[0], ['sendAfterDays'])

      setStepList(list)
    }
  }, [JSON.stringify(stepList)])

  return (
    <VmoTabPane style={style}>
      {addModal && <AddItemModal />}
      {addType === 'auto_sms' && (
        <SmsModal open={addType === 'auto_sms'} toggle={setAddType} handleAdd={handleAdd} editData={editData} />
      )}
      {(addType === 'task_activity' || addType === 'task_call' || addType === 'task_meeting') && (
        <CreateTask
          open={addType === 'task_activity' || addType === 'task_call' || addType === 'task_meeting'}
          toggle={setAddType}
          handleAdd={handleAdd}
          addType={addType}
          editData={editData}
        />
      )}
      {(addType === 'manual_email' || addType === 'auto_email') && (
        <EmailEditor
          open={addType === 'manual_email' || addType === 'auto_email'}
          toggle={setAddType}
          handleAdd={handleAdd}
          addType={addType}
          editData={editData}
          assignee={assignee}
          watchMailBoxId={watchMailBoxId}
        />
      )}
      {/* <div className="seqFlow"> */}
      <VmoCard className="seqFlow" fluid style={{width: '60%', margin: 'auto'}}>
        <VmoCardContent>
          <VmoForm className="whiteBg errorLabel">
            <VmoFormField required>
              <label>Sequence Title</label>
              <Controller
                name="name"
                render={({onChange, value, onBlur}) => (
                  <VmoFormInput
                    placeholder="Title"
                    maxLength={20}
                    value={value}
                    onChange={e => {
                      onChange(e.target.value)
                    }}
                    onBlur={onBlur}
                    error={
                      errors.name && {
                        content: removeDoubleQuotes(errors.name.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoForm>

          {stepList.map((step, index) => (
            <>
              <div className="addBtn">
                <VmoButton
                  circular
                  onClick={() => {
                    setInsertAt(index)
                    setAddModal(true)
                  }}
                  disabled={index + 1 < currentStep}
                >
                  <SvgIcon path="common/plus" />
                </VmoButton>
              </div>

              <div className="seqBox" style={{borderColor: colors[step.type]}}>
                <div className="seqHeader" style={{background: colors[step.type]}}>
                  <div className="info">
                    {getIcon(step.type)} Step {index + 1} :{startCase(step.type)}
                  </div>
                  <div className="action custom-icon">
                    {index < stepList.length - 1 && (
                      <VmoButton icon onClick={() => upDownHandle('down', index)}>
                        <FiArrowDown />
                      </VmoButton>
                    )}
                    {index > 0 && (
                      <VmoButton icon onClick={() => upDownHandle('up', index)} disabled={index + 1 < currentStep}>
                        <FiArrowUp />
                      </VmoButton>
                    )}
                    <VmoTooltip
                      content="Delete"
                      position="top center"
                      size="mini"
                      trigger={
                        <VmoButton icon onClick={() => deleteStep(index)} disabled={index + 1 < currentStep}>
                          <SvgIcon path="common/delete" />
                        </VmoButton>
                      }
                    />
                    <VmoTooltip
                      content="Edit"
                      position="top center"
                      size="mini"
                      trigger={
                        <VmoButton
                          icon
                          onClick={() => {
                            setAddType(step.type)
                            setEditData(step.data)
                            setInsertAt(index)
                          }}
                          disabled={index + 1 < currentStep}
                        >
                          <SvgIcon path="common/edit2" />
                        </VmoButton>
                      }
                    />
                  </div>
                </div>
                <div className="timeBar">
                  {index > 0 && (
                    <div className="waitFor">
                      <p>Wait for</p>
                      <VmoFormSelect
                        size="mini"
                        style={{width: 100}}
                        value={step.sendAfterDays}
                        search
                        options={dayList}
                        onChange={(e, {value}) => {
                          const list = [...stepList]
                          list[index].sendAfterDays = value
                          setStepList(list)
                        }}
                        disabled={index + 1 < currentStep}
                      />
                      <p className="ml-2 mr-0">day</p>
                    </div>
                  )}
                  {index < 1 && (
                    <div className="waitFor">
                      <p>Execution Time</p>
                      <VmoDatePicker
                        style={{width: 226}}
                        onChange={value => {
                          const list = [...stepList]
                          list[index].sendAt = moment(value).format('HH:MM:SS')
                          setStepList(list)
                        }}
                        value={moment(new Date())
                          .set({
                            h: step.sendAt.substring(0, 2),
                            m: 0,
                          })
                          .toDate()}
                        className="vmo-picker"
                        step={60}
                        inputProps={{
                          component: props => <input {...props} readOnly />,
                        }}
                        disabled={index + 1 < currentStep}
                      />
                    </div>
                  )}
                </div>
                <div className="seqBody">
                  <p>{getSubject(step.type, step.data)}</p>
                  <VmoCheckbox
                    label="continue without completing task"
                    checked={stepList[index].isContinueWithoutCompletion}
                    onChange={() => {
                      const list = [...stepList]
                      list[index].isContinueWithoutCompletion = !list[index].isContinueWithoutCompletion
                      setStepList(list)
                    }}
                    disabled={index + 1 < currentStep}
                  />
                </div>
              </div>
            </>
          ))}

          <div className="addBtn">
            <VmoButton
              circular
              onClick={() => {
                setInsertAt(stepList.length)
                setAddModal(true)
              }}
            >
              <SvgIcon path="common/plus" />
            </VmoButton>
            <h5>End</h5>
            {get(['steps', 'message'], errors) && (
              <span style={{color: 'red'}}>{get(['steps', 'message'], errors)}</span>
            )}
          </div>
        </VmoCardContent>
      </VmoCard>
      {/* </div> */}
    </VmoTabPane>
  )
}

export default Flow
