import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {Controller} from 'react-hook-form'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormRadio,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'

const SegmentStep1 = props => {
  const {open, setOpen, control, handleSubmit, errors, type} = props

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      onClose={() => setOpen(false)}
      open={open}
      size="tiny"
    >
      <VmoModalHeader>Segment Information</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <label>Name</label>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  maxLength={20}
                  placeholder="Enter name"
                  onChange={onChange}
                  value={value}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <label>Type</label>
            <VmoFormGroup inline>
              <Controller
                name="isStatic"
                render={({value, onChange}) => (
                  <div>
                    <VmoFormRadio
                      label="Static"
                      value
                      checked={value === true}
                      onClick={(e, {value}) => onChange(value)}
                      error={
                        errors.isStatic && {
                          content: errors.isStatic.message,
                        }
                      }
                    />
                    <label className="kooper-lead" style={{marginLeft: '27px'}}>
                      Create a static or fixed segment
                    </label>
                    <VmoFormRadio
                      className="mt-3"
                      label="Dynamic"
                      value={false}
                      checked={value === false}
                      onClick={(e, {value}) => onChange(value)}
                      error={
                        errors.isStatic && {
                          content: errors.isStatic.message,
                        }
                      }
                    />
                    <label className="kooper-lead" style={{marginLeft: '27px'}}>
                      Create a dynamic or variable segment
                    </label>
                  </div>
                )}
                control={control}
              />
            </VmoFormGroup>
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton onClick={() => setOpen(false)} basic>
          Cancel
        </VmoButton>
        <VmoButton content="Next" primary onClick={handleSubmit(() => setOpen(type))} />
      </VmoModalActions>
    </VmoModal>
  )
}

export default SegmentStep1
