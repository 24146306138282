import React, {useState} from 'react'
import {
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {get, GetFormattedDate} from 'utils/helper'
import KooperCustomPagination from 'components/common/KooperCustomPagination'
import ViewTask from './ViewTask'

const TableView = props => {
  const {tableData, totalPages, currentListParams, setCurrentListParams, projectId, rolePermissions} = props

  const [taskId, setTaskId] = useState(null)
  const [isViewTaskModalOpen, setIsViewTaskModalOpen] = useState(false)

  const renderTableBody = () => {
    if (tableData.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return tableData.map((task, index) => (
      <VmoTableRow
        className="tableLink"
        key={task.id}
        onClick={() => {
          if (rolePermissions) {
            setTaskId(task.id)
            setIsViewTaskModalOpen(true)
          }
        }}
      >
        <VmoTableCell>{index + 1}</VmoTableCell>
        <VmoTableCell>
          <VmoHeader as="h5">{task.title}</VmoHeader>
        </VmoTableCell>
        <VmoTableCell>{GetFormattedDate(task.startDate)}</VmoTableCell>
        <VmoTableCell>{GetFormattedDate(task.endDate)}</VmoTableCell>
        <VmoTableCell>{get(['stageDetails', 'name'], task, '')}</VmoTableCell>
        <VmoTableCell>
          <div>{`${get(['assigneeDetails', 'firstName'], task, '')} ${get(
            ['assigneeDetails', 'lastName'],
            task,
            ''
          )}`}</div>
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <>
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Title</VmoTableHeaderCell>
            <VmoTableHeaderCell>Start Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Due Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Stage</VmoTableHeaderCell>
            <VmoTableHeaderCell>Assignees</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>
      {totalPages > 1 && (
        <KooperCustomPagination
          totalPages={totalPages}
          activePage={currentListParams.page}
          onPageChange={page => {
            setCurrentListParams(sort => ({...sort, page}))
          }}
        />
      )}

      {isViewTaskModalOpen && (
        <ViewTask open={isViewTaskModalOpen} toggle={setIsViewTaskModalOpen} projectId={projectId} taskId={taskId} />
      )}
    </>
  )
}

export default TableView
