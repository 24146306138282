/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {deleteProjectRisk, getAllProjectRisks, getSingleProjectRisk} from 'actions/projects'
import {DELETE_PROJECT_RISK, GET_ALL_PROJECT_RISKS} from 'actions/types'
import {startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import DeleteModal from 'components/common/DeleteModal'
import CreateFirst from 'components/common/CreateFirst'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import RiskModal from './RiskModal'

function RisksList(props) {
  const {projectId} = props
  const dispatch = useDispatch()

  const [isAddRiskOpen, setIsAddRiskOpen] = useState(false)
  const [editId, setEditId] = useState()
  const [deleteModal, setDeleteModal] = useState(false)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_PROJECT_RISKS})

  const {data: getRisksList = [], callAction} = useApiResponse({
    action: getAllProjectRisks,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    storePath: 'projects.getRisksList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_PROJECT_RISK)) {
      callAction()
    }
  }, [successLabels, callAction])

  const renderTableBody = () => {
    return getRisksList.map(
      (
        {
          id,
          riskSource,
          createdBy,
          status,
          probabilityType,
          probabilityValue,
          impactType,
          impactValue,
          result,
          isImpactOnCost,
          isImpactOnPerformance,
          isImpactOnSchedule,
        },
        index
      ) => {
        return (
          <VmoTableRow
            key={id}
            className="tableLink"
            onClick={() => {
              dispatch(getSingleProjectRisk({riskId: id, projectId}))
              setIsAddRiskOpen(true)
            }}
          >
            <VmoTableCell>{index + 1}</VmoTableCell>
            <VmoTableCell>
              <VmoHeader as="h5">{startCase(riskSource)}</VmoHeader>
            </VmoTableCell>
            <VmoTableCell>{`${createdBy?.firstName} ${createdBy?.lastName}`}</VmoTableCell>
            <VmoTableCell>
              {probabilityType === 'Low' && (
                <span className="circle-type" style={{backgroundColor: '#59c894'}}>
                  {probabilityValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell style={{textAlign: 'center'}}>
              {probabilityType === 'Medium' && (
                <span className="circle-type" style={{backgroundColor: 'orange'}}>
                  {probabilityValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell style={{textAlign: 'center'}}>
              {probabilityType === 'High' && (
                <span className="circle-type" style={{backgroundColor: 'red'}}>
                  {probabilityValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell style={{textAlign: 'center'}}>
              {impactType === 'Low' && (
                <span className="circle-type" style={{backgroundColor: '#59c894'}}>
                  {impactValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell style={{textAlign: 'center'}}>
              {impactType === 'Medium' && (
                <span className="circle-type" style={{backgroundColor: 'orange'}}>
                  {impactValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell style={{textAlign: 'center'}}>
              {impactType === 'High' && (
                <span className="circle-type" style={{backgroundColor: 'red'}}>
                  {impactValue}
                </span>
              )}
            </VmoTableCell>
            <VmoTableCell
              style={{backgroundColor: '#cfe5fd', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            >
              {result >= 0 && result <= 15 ? (
                <span className="circle-type" style={{backgroundColor: '#59c894', textAlign: 'center'}}>
                  {result}
                </span>
              ) : result > 15 && result <= 48 ? (
                <span className="circle-type" style={{backgroundColor: 'orange'}}>
                  {result}
                </span>
              ) : result > 48 ? (
                <span className="circle-type" style={{backgroundColor: 'red', textAlign: 'center'}}>
                  {result}
                </span>
              ) : (
                ''
              )}
            </VmoTableCell>
            <VmoTableCell style={{fontWeight: 'bold', color: '#ff3454', textAlign: 'center'}}>
              {isImpactOnCost && <SvgIcon path="common/check" />}
            </VmoTableCell>
            <VmoTableCell style={{fontWeight: 'bold', color: '#ff3454', textAlign: 'center'}}>
              {isImpactOnSchedule && <SvgIcon path="common/check" />}
            </VmoTableCell>
            <VmoTableCell style={{fontWeight: 'bold', color: '#ff3454', textAlign: 'center'}}>
              {isImpactOnPerformance && <SvgIcon path="common/check" />}
            </VmoTableCell>
            <VmoTableCell>
              <VmoLabel
                style={
                  status === 'Open'
                    ? {background: '#59c894', color: '#fff'}
                    : status === 'Pending'
                    ? {background: 'orange', color: '#fff'}
                    : {background: 'red', color: '#fff'}
                }
              >
                {status}
              </VmoLabel>
            </VmoTableCell>
            <VmoTableCell>
              <VmoTooltip
                content="Delete"
                size="mini"
                position="top center"
                trigger={
                  <VmoButton
                    icon={<SvgIcon path="common/delete" />}
                    onClick={e => {
                      e.stopPropagation()
                      setEditId(id)
                      setDeleteModal(true)
                    }}
                  />
                }
              />
            </VmoTableCell>
          </VmoTableRow>
        )
      }
    )
  }

  const renderRiskModal = () => {
    if (isAddRiskOpen) {
      return <RiskModal projectId={projectId} setIsAddRiskOpen={setIsAddRiskOpen} isAddRiskOpen={isAddRiskOpen} />
    }
  }

  if (!getRisksList?.length) {
    return (
      <div className="pageBodyWrap">
        <CreateFirst
          header="Risk Management"
          subHeader="Risk management helps in identifying, assessing, and mitigating potential threats to project success"
          addFirst={() => setIsAddRiskOpen(true)}
          buttonText="Create Risk"
          Feature1="Risk Assessment"
          Feature2="Risk Identification"
          Feature3="Risk Monitoring"
          list1="Evaluate risks based on probability to prioritize actions."
          list2="Identify and categorize potential risks that could impact your project."
          list3="Track identified risks throughout the project lifecycle to stay proactive."
        />
        {renderRiskModal()}
      </div>
    )
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <div className="pageBodyWrap">
        <div className="pageHeader px-0">
          <div className="pageTitle">
            <div className="pageTitleIcon">
              <h5>Risks ({getRisksList ? getRisksList?.length : 0})</h5>
            </div>
          </div>
          <div className="pageAction">
            <VmoButton content="Create Risk" primary onClick={() => setIsAddRiskOpen(!isAddRiskOpen)} />
          </div>
        </div>
        <VmoTable sortable basic selectable>
          <VmoTableHeader>
            <VmoTableRow>
              <VmoTableHeaderCell />
              <VmoTableHeaderCell />
              <VmoTableHeaderCell />
              <VmoTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Probability
              </VmoTableHeaderCell>
              <VmoTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Impact
              </VmoTableHeaderCell>
              <VmoTableHeaderCell />
              <VmoTableHeaderCell style={{fontWeight: 'bold', textAlign: 'center'}} colSpan={3}>
                Impact Areas
              </VmoTableHeaderCell>
              <VmoTableHeaderCell />
              <VmoTableHeaderCell />
            </VmoTableRow>
            <VmoTableRow>
              <VmoTableHeaderCell>#</VmoTableHeaderCell>
              <VmoTableHeaderCell>Source</VmoTableHeaderCell>
              <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
              <VmoTableHeaderCell>Low</VmoTableHeaderCell>
              <VmoTableHeaderCell>Medium</VmoTableHeaderCell>
              <VmoTableHeaderCell>High</VmoTableHeaderCell>
              <VmoTableHeaderCell>Low</VmoTableHeaderCell>
              <VmoTableHeaderCell>Medium</VmoTableHeaderCell>
              <VmoTableHeaderCell>High</VmoTableHeaderCell>
              <VmoTableHeaderCell>Result</VmoTableHeaderCell>
              <VmoTableHeaderCell>Cost</VmoTableHeaderCell>
              <VmoTableHeaderCell>Schedule</VmoTableHeaderCell>
              <VmoTableHeaderCell>Performance</VmoTableHeaderCell>
              <VmoTableHeaderCell>Status</VmoTableHeaderCell>
              <VmoTableHeaderCell>Action</VmoTableHeaderCell>
            </VmoTableRow>
          </VmoTableHeader>
          <VmoTableBody>
            {isLoadingData && type.includes(GET_ALL_PROJECT_RISKS) ? (
              <VmoTablePlaceholder columns={15} />
            ) : !isLoadingData && (getRisksList || []).length > 0 ? (
              renderTableBody()
            ) : (
              <tr>
                <td colSpan={15} className="emptyValue">
                  No Data Available
                </td>
              </tr>
            )}
          </VmoTableBody>
        </VmoTable>
      </div>

      {renderRiskModal()}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete ?"
          onDelete={() => dispatch(deleteProjectRisk({riskId: editId, projectId}))}
          type={DELETE_PROJECT_RISK}
        />
      )}
    </>
  )
}

export default RisksList
