import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import DocumentTitle from 'components/common/DocumentTitle'
import Overview from 'components/pages/Analytics/Reports/Conversations/Overview'
import Inbox from 'components/pages/Analytics/Reports/Conversations/Inbox'
import Channels from 'components/pages/Analytics/Reports/Conversations/Channels'
import Tags from 'components/pages/Analytics/Reports/Conversations/Tags'
import EmailAnalytics from 'components/pages/Analytics/Reports/Conversations/Email_Tracking'
import Chat from 'components/pages/Analytics/Reports/Campaigns/Chat'
import Email from 'components/pages/Analytics/Reports/Campaigns/Email'
import SMS from 'components/pages/Analytics/Reports/Campaigns/SMS'
import Metrics from 'components/pages/Analytics/Reports/Performance/Metrics'
import Users from 'components/pages/Analytics/Reports/Performance/Users'
import Sla from 'components/pages/Analytics/Reports/Performance/Sla'
import Contacts from 'components/pages/Analytics/Reports/Customers/Contacts'
import CompanyAnalytics from 'components/pages/Analytics/Reports/Customers/Companies'
import Visitors from 'components/pages/Analytics/Reports/Customers/Visitors'
import ConversionAnalytics from 'components/pages/Analytics/Reports/Deals/Conversion'
import DealsAnalytics from 'components/pages/Analytics/Reports/Deals/Deals'
import ProductsAnalytics from 'components/pages/Analytics/Reports/Revenue/Products'
import QuotesAnalytics from 'components/pages/Analytics/Reports/Revenue/Quotes'
import TaskAnalytics from 'components/pages/Analytics/Reports/Performance/Task'
import ForecastAnalytics from 'components/pages/Analytics/Reports/Revenue/Forecast'
import TemplateAnalytics from 'components/pages/Analytics/Reports/Productivity/Template'
import Vendors from 'components/pages/Analytics/Reports/Finance/Vendors'
import Purchases from 'components/pages/Analytics/Reports/Finance/Purchases'
import Projects from 'components/pages/Analytics/Reports/Reports/Projects'
import Resources from 'components/pages/Analytics/Reports/Reports/resource'
import Employees from 'components/pages/Analytics/Reports/Hr/Employees'
import Expenses from 'components/pages/Analytics/Reports/Hr/Expenses'
import TimeOff from 'components/pages/Analytics/Reports/Hr/TimeOff'
import PurchaseOrders from 'components/pages/Analytics/Reports/Finance/PurchaseOrders'
import SalesOrders from 'components/pages/Analytics/Reports/Finance/SalesOrders'
import Invoices from 'components/pages/Analytics/Reports/Finance/Invoices'
import ProjectHealth from 'components/pages/Analytics/Reports/Reports/ProjectHealth/ProjectHealth'
import TimesheetByProjects from 'components/pages/Analytics/Reports/Reports/TimesheetByProjects'
import TimesheetByUsers from 'components/pages/Analytics/Reports/Reports/TimesheetByUsers'
import TaskByUsers from 'components/pages/Analytics/Reports/Reports/TaskByUsers'
import ResourceUtilization from 'components/pages/Analytics/Reports/Reports/ResourceUtilization'

const routes = [
  // Conversations
  {path: '/reports/overview', component: Overview, title: 'Overview', exact: true},
  {path: '/reports/inbox', component: Inbox, title: 'Inbox', exact: true},
  {path: '/reports/channels', component: Channels, title: 'Channels', exact: true},
  {path: '/reports/tags', component: Tags, title: 'Tags', exact: true},
  {path: '/reports/emailtracking', component: EmailAnalytics, title: 'Email Tracking', exact: true},
  // Clients
  {path: '/reports/contacts', component: Contacts, title: 'Contacts', exact: true},
  {path: '/reports/company', component: CompanyAnalytics, title: 'Company', exact: true},
  {path: '/reports/visitors', component: Visitors, title: 'Visitors', exact: true},
  // Sales
  {path: '/reports/deals', component: DealsAnalytics, title: 'Deals', exact: true},
  {path: '/reports/conversions', component: ConversionAnalytics, title: 'Conversions', exact: true},
  {path: '/reports/forecasts', component: ForecastAnalytics, title: 'Forecasts', exact: true},
  {path: '/reports/products', component: ProductsAnalytics, title: 'Products', exact: true},
  {path: '/reports/quotes', component: QuotesAnalytics, title: 'Quotes', exact: true},
  // Projects
  {path: '/reports/project-health', component: ProjectHealth, title: 'Project Health', exact: true},
  {path: '/reports/project-timesheet', component: TimesheetByProjects, title: 'Timesheet by Projects', exact: true},
  {path: '/reports/project-timesheet-users', component: TimesheetByUsers, title: 'Timesheet by Users', exact: true},
  {path: '/reports/project-task-users', component: TaskByUsers, title: 'Task by Users', exact: true},
  {path: '/reports/resource-utilization', component: ResourceUtilization, title: 'Resource Utilization', exact: true},
  {path: '/reports/projects', component: Projects, title: 'Projects', exact: true},
  {path: '/reports/resources', component: Resources, title: 'Resources', exact: true},
  // Finance
  {path: '/reports/vendors', component: Vendors, title: 'Vendors', exact: true},
  {path: '/reports/purchases', component: Purchases, title: 'Purchases', exact: true},
  {path: '/reports/purchase-orders', component: PurchaseOrders, title: 'Purchase Orders', exact: true},
  {path: '/reports/sales-orders', component: SalesOrders, title: 'Sales Orders', exact: true},
  {path: '/reports/invoices', component: Invoices, title: 'Invoices', exact: true},
  // {path: '/reports/budgets-planner', component: BudgetsPlanner, title: 'Budgets Planner', exact: true},
  // Performance
  {path: '/reports/metrics', component: Metrics, title: 'Metrics', exact: true},
  {path: '/reports/users', component: Users, title: 'Users', exact: true},
  {path: '/reports/sla', component: Sla, title: 'SLA', exact: true},
  {path: '/reports/tasks', component: TaskAnalytics, title: 'Tasks', exact: true},
  {path: '/reports/email-templates', component: TemplateAnalytics, title: 'Email Template', exact: true},
  // Campaigns
  {path: '/reports/chat', component: Chat, title: 'Chat', exact: true},
  {path: '/reports/email', component: Email, title: 'Email', exact: true},
  {path: '/reports/sms', component: SMS, title: 'SMS', exact: true},
  // hr
  {path: '/reports/employees', component: Employees, title: 'Employees', exact: true},
  {path: '/reports/expenses', component: Expenses, title: 'Expenses', exact: true},
  {path: '/reports/timeOff', component: TimeOff, title: 'Time Off', exact: true},
]

const renderRoute = ({path, component: Component, title, exact = false}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    render={props => (
      <>
        <DocumentTitle title={title} reports />
        <Component {...props} />
      </>
    )}
  />
)

const ReportsRoutes = () => (
  <Switch>
    {routes.map(route => renderRoute(route))}
    <Route path="/reports/*">
      <Redirect to="/reports/overview" />
    </Route>
  </Switch>
)

export default ReportsRoutes
