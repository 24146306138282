import {apiAction} from './api'
import {PROJECTS_ENDPOINT} from './endpoint'
import {
  ADD_COMMENT,
  ADD_NEW_VERSION,
  CREATE_PROOF,
  DELETE_COMMENT,
  DELETE_PROOF,
  EDIT_PROOF,
  GET_ALL_PROOFS,
  GET_COMMENTS,
  GET_SINGLE_PROOF,
  UPDATE_APPROVAL_STATUS,
  UPDATE_PROOF_STATUS,
} from './types'

export function createProof(data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs`,
    method: 'POST',
    label: CREATE_PROOF,
    data,
    showToast: true,
    successMessage: 'Proof Created',
  })
}

export function getAllProofs(params = {}) {
  const keys = Object.keys(params || {})

  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs${query ? `?${query}` : ''}`,
    method: 'GET',
    label: GET_ALL_PROOFS,
  })
}

export function deleteProof(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}`,
    method: 'DELETE',
    label: DELETE_PROOF,
    showToast: true,
    successMessage: 'Proof Deleted',
  })
}

export function getSingleProof(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}`,
    method: 'GET',
    label: GET_SINGLE_PROOF,
  })
}

export function editProof(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}`,
    method: 'PUT',
    label: EDIT_PROOF,
    data,
    showToast: true,
    successMessage: 'Proof Updated',
  })
}

export function updateProofStatus(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}`,
    method: 'PATCH',
    label: UPDATE_PROOF_STATUS,
    data,
    showToast: true,
    successMessage: 'Status Updated',
  })
}

export function updateApprovalStatus(id, data, successMessage) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}/approval`,
    method: 'PATCH',
    label: UPDATE_APPROVAL_STATUS,
    data,
    showToast: true,
    successMessage,
  })
}

export function addNewVersion(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}/version`,
    method: 'PATCH',
    label: ADD_NEW_VERSION,
    data,
    showToast: true,
    successMessage: 'Added new version',
  })
}

export function addComment(id, data) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/${id}/comments`,
    method: 'POST',
    label: ADD_COMMENT,
    data,
    showToast: true,
    successMessage: 'Added Comment',
  })
}

export function getComments(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/comments/${id}`,
    method: 'GET',
    label: GET_COMMENTS,
  })
}

export function deleteComment(id) {
  return apiAction({
    url: `${PROJECTS_ENDPOINT}/proofs/comments/${id}`,
    method: 'DELETE',
    label: DELETE_COMMENT,
    showToast: true,
    successMessage: 'Comment Deleted',
  })
}
