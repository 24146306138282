import {apiAction} from './api'
import {HR_APPRAISALS_ENDPOINT} from './endpoint'
import {
  CREATE_APPRAISAL_TEMPLATE,
  GET_ALL_APPRAISAL_TEMPLATE,
  DELETE_APPRAISAL_TEMPLATE,
  UPDATE_APPRAISAL_TEMPLATE,
  GET_APPRAISAL_TEMPLATE_DATA,
  GET_REVEIWER_LIST,
  SEND_APPRAISAL,
} from './types'

export function createAppraisalTemplate(data) {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/template`,
    method: 'POST',
    label: CREATE_APPRAISAL_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Appraisal Template Added',
  })
}

export function getAllAppraisalTemplate(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/templates/${query}`,
    method: 'GET',
    label: GET_ALL_APPRAISAL_TEMPLATE,
  })
}

export function deleteAppraisalTemplate(id) {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/template/${id}`,
    method: 'DELETE',
    label: DELETE_APPRAISAL_TEMPLATE,
    showToast: true,
    successMessage: 'Template Deleted',
  })
}

export function getSingleAppraisalTemplate(id) {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/template/${id}`,
    method: 'GET',
    label: GET_APPRAISAL_TEMPLATE_DATA,
  })
}

export function updateAppraisalTemplate(id, data) {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/template/${id}`,
    method: 'PUT',
    label: UPDATE_APPRAISAL_TEMPLATE,
    data,
    showToast: true,
    successMessage: 'Appraisal Updated',
  })
}

export function getReveiwerList() {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/list/reveiwer`,
    method: 'GET',
    label: GET_REVEIWER_LIST,
  })
}

export function sendAppraisal(id, data) {
  return apiAction({
    url: `${HR_APPRAISALS_ENDPOINT}/template/${id}/send-appraisal`,
    method: 'POST',
    label: SEND_APPRAISAL,
    data,
    showToast: true,
    successMessage: 'Appraisal Sent',
  })
}
