import React, {useEffect, useState} from 'react'
import {joiResolver} from '@hookform/resolvers'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import _ from 'lodash'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {CREATE_PROJECT_MILESTONE, EDIT_PROJECT_MILESTONE, GET_SINGLE_PROJECT_MILESTONE, GET_USERS} from 'actions/types'
import {getUsers} from 'actions/settings'
import {
  createProjectMilestone,
  editProjectMilestone,
  getAllProjectMilestone,
  getProjectsTasksList,
} from 'actions/projects'
import {get, removeDoubleQuotes} from 'utils/helper'
import {addMilestoneSchema} from 'validation/Projects/projects/milestone.schema'
import moment from 'moment'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const initialCreateInfo = {
  name: '',
  dueDate: null,
  assignees: [],
  tasklistIds: [],
  description: '',
}

function MilestoneModal(props) {
  const {isMilestoneOpen, setIsMilestoneOpen, projectId} = props
  const dispatch = useDispatch()

  const [assigneeList, setAssigneeList] = useState([])
  const [editId, setEditId] = useState(null)

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {tasksList = [], getSingleMilestoneData} = useSelector(state => state.projects)

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: joiResolver(addMilestoneSchema),
    defaultValues: initialCreateInfo,
  })

  const watchDueDate = watch('dueDate')

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setAssigneeList(list || [])
    },
  })

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectsTasksList(projectId))
    }
  }, [projectId, dispatch])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_PROJECT_MILESTONE)) {
      reset({
        name: get(['name'], getSingleMilestoneData, ''),
        assignees: get(['assignees'], getSingleMilestoneData, []),
        tasklistIds: get(['tasklistIds'], getSingleMilestoneData, []),
        description: get(['description'], getSingleMilestoneData, ''),
        dueDate: get(['dueDate'], getSingleMilestoneData, null),
      })

      setEditId(get(['id'], getSingleMilestoneData, null))
    }
  }, [successLabels, getSingleMilestoneData])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_MILESTONE) || successLabels.includes(EDIT_PROJECT_MILESTONE)) {
      reset(initialCreateInfo)
      dispatch(getAllProjectMilestone({projectId}))
      setIsMilestoneOpen(false)
    }
  }, [successLabels, dispatch, projectId, initialCreateInfo])

  const submitForm = data => {
    data.dueDate = moment(watchDueDate).format('YYYY-MM-DD')
    const newList = _.omitBy(data, value => !!(value === '' || value === null || _.isEqual(value, [])))

    if (editId) {
      dispatch(editProjectMilestone({data: newList, milestoneId: editId, projectId}))
    } else {
      dispatch(createProjectMilestone({data: newList, projectId}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      open={isMilestoneOpen}
      onClose={() => setIsMilestoneOpen(false)}
      size="small"
      centered={false}
    >
      <VmoModalHeader className="vmoModalHeader">{editId ? 'Edit Milestone' : 'Add Milestone'}</VmoModalHeader>
      <VmoModalContent scrolling>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Milestone Name</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify name of the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  type="text"
                  placeholder="Milestone name..."
                  value={value}
                  maxLength={50}
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  onBlur={onBlur}
                  error={
                    errors.name && {
                      content: removeDoubleQuotes(errors.name.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>

          <VmoFormGroup widths="equal">
            <VmoFormField required>
              <div className="info-header">
                <label>Due Date</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set due date of the milestone"
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="dueDate"
                control={control}
                render={({value, onChange}) => (
                  <VmoDatePicker
                    time={false}
                    value={value ? new Date(value) : value}
                    onChange={value => {
                      onChange(value)
                    }}
                    defaultValue={typeof value === 'string' ? new Date(value) : value}
                    inputProps={{
                      component: props => <input {...props} readOnly />,
                    }}
                  />
                )}
              />
              {errors.dueDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.dueDate.message)}</p>}
            </VmoFormField>

            <VmoFormField required>
              <div className="info-header">
                <label className="label-class">Assignee</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Assign team members for this milestone "
                  size="mini"
                  psoition="top center"
                />
              </div>
              <Controller
                name="assignees"
                render={({onChange, value, onBlur}) => (
                  <VmoFormSelect
                    fluid
                    multiple
                    options={assigneeList}
                    selection
                    placeholder="Owners"
                    onBlur={onBlur}
                    value={value}
                    onChange={(e, {value}) => {
                      onChange(value)
                    }}
                    error={
                      errors?.assignees && {
                        content: removeDoubleQuotes(errors?.assignees?.message),
                      }
                    }
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoFormGroup>
          <VmoFormField>
            <div className="info-header">
              <label>Tasklist</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select a tasklist from project"
                size="mini"
                psoition="top center"
              />
            </div>

            <Controller
              name="tasklistIds"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  multiple
                  placeholder="Select"
                  options={tasksList?.map(({id, title}) => ({key: id, value: id, text: title}))}
                  value={value}
                  onChange={(e, {value}) => {
                    onChange(value)
                  }}
                  error={errors.tasklistIds && {content: removeDoubleQuotes(errors.tasklistIds.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify a brief description of the milestone"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="description"
              render={({onChange, value}) => (
                <VmoFormTextArea
                  type="text"
                  value={value}
                  maxLength={250}
                  placeholder="Description"
                  onChange={e => {
                    onChange(e.target.value)
                  }}
                  error={
                    errors.description && {
                      content: removeDoubleQuotes(errors.description.message),
                    }
                  }
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions style={{position: 'relative'}}>
        <VmoButton
          basic
          onClick={() => setIsMilestoneOpen(false)}
          disabled={isLoadingData && type.includes(CREATE_PROJECT_MILESTONE)}
        >
          Cancel
        </VmoButton>
        <VmoButton
          primary
          onClick={handleSubmit(submitForm)}
          loading={(isLoadingData && type.includes(CREATE_PROJECT_MILESTONE)) || type.includes(EDIT_PROJECT_MILESTONE)}
          disabled={!isDirty}
        >
          {editId ? 'Update Milestone' : 'Add Milestone'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default MilestoneModal
