import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import Joi from 'joi'
import _ from 'lodash'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormRadio,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'

import {CREATE_PROJECTS_NOTEBOOK, GET_PROJECTS_SINGLE_NOTEBOOK, UPDATE_PROJECTS_NOTEBOOK} from 'actions/types'
import {createProjectsNotebook, getProjectsSingleNotebook, updateProjectsNotebook} from 'actions/projects'
import {removeDoubleQuotes} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const NotebookModal = ({open, toggle, projectId, notebookId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(
      Joi.object({
        title: Joi.string().trim().min(3).max(50).required().messages({
          'string.min': `Length must me at least 3 charactes`,
          'string.max': `Length must be less than or equal to 50 characters`,
        }),
        pinToTop: Joi.boolean(),
      })
    ),
    defaultValues: {
      title: '',
      pinToTop: false,
    },
  })

  useApiResponse({
    action: getProjectsSingleNotebook,
    payload: {projectId, notebookId},
    dependency: [projectId, notebookId],
    enabled: projectId && notebookId,
    label: GET_PROJECTS_SINGLE_NOTEBOOK,
    storePath: 'projects.notebookData',
    onSuccess: (notebookData = {}) => {
      const data = _.pick(notebookData, ['title', 'pinToTop'])
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_NOTEBOOK) || successLabels.includes(UPDATE_PROJECTS_NOTEBOOK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (notebookId) {
      dispatch(updateProjectsNotebook({projectId, notebookId, data}))
    } else {
      dispatch(createProjectsNotebook({projectId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{notebookId ? 'Edit Notebook' : 'Add Notebook'}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Title</label>
              <VmoTooltip
                content="Specify title of the notebook"
                trigger={<SvgIcon path="common/question" />}
                size="mini"
                position="right center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="d-flex">
              <div>
                <label>Pin to top</label>
                <p className="kooper-lead">Pin to top</p>
              </div>
              <Controller
                name="pinToTop"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormRadio toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
                )}
              />
            </div>
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {notebookId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default NotebookModal
