import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {FiChevronRight, FiFolder, FiMoreHorizontal} from 'react-icons/fi'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoButton,
  VmoDivider,
  VmoIcon,
  VmoLabel,
  VmoList,
  VmoListContent,
  VmoListItem,
  VmoMenu,
  VmoMenuItem,
  VmoPopup,
  VmoRadio,
  VmoTooltip,
} from 'vmo-library'
import {setMetaData} from 'actions/index'
import {get, getPermissionTooltipContent, wrapText} from 'utils/helper'
import {priorityList} from 'constants/variables'
import DocumentTitle from 'components/common/DocumentTitle'
import {getMetadataInfo, setMetadataInfo} from 'utils/local-storage'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

// Get Channel name for icon
const getChannel = type => {
  if (type === 'web') return <SvgIcon path="inbox/web-channel" />
  if (type === 'email') return <SvgIcon path="common/mail" />
  if (type === 'support_form') return <SvgIcon path="inbox/support-form" />
  if (type === 'facebook') return <SvgIcon path="inbox/facebook" />
  if (type === 'twitter') return <SvgIcon path="inbox/twitter" />
  if (type === 'custom_sms') return <SvgIcon path="inbox/custom-sms" />
  if (type === 'twilio') return <SvgIcon path="inbox/twilio" />
  return <FiFolder />
}

export const InboxNavigation = props => {
  const {selectedInbox, setSelectedInbox, setIsOpenMailComposer, setSearchConvseration, mailBox} = props

  const dispatch = useDispatch()

  const [activeAccordion, setActiveAccordion] = useState({
    inbox: true,
    viewMore: false,
    activeChannels: false,
    activeUsers: false,
    activeTags: false,
    activeStatus: false,
    activePriority: false,
  })

  const {data: {users: teammates = []} = {}} = useSelector(state => state.settings)
  const {countData = {}, statusList = []} = useSelector(state => state.emailInbox)
  const {tagList = []} = useSelector(state => state.settingsProductivity)
  const [stateMetaData, setStateMetaData] = useState(getMetadataInfo())

  const {conversationsPermissions} = useUserPermissions()
  const manageConversationPermissions = conversationsPermissions?.manage_conversations

  const handleSetMetaData = data => {
    setStateMetaData(data)
    setMetadataInfo(data)
  }

  const renderMailBox = () => {
    return mailBox.map((inbox, i) => {
      return (
        <VmoMenuItem
          className="inboxTitle"
          style={{overflow: 'auto'}}
          key={inbox + i}
          active={selectedInbox.id === `${inbox.id}inbox`}
          onClick={() => {
            setSelectedInbox({
              type: 'inbox',
              name: inbox.id,
              label: inbox.name,
              id: `${inbox.id}inbox`,
            })
            setSearchConvseration('')
          }}
          tag="a"
          action
          title={inbox.name}
        >
          {getChannel(inbox.type)} {wrapText(inbox.name, 18)}
          <span>
            {(get(['inboxes'], countData, []).find(inboxData => inboxData.inboxId === inbox.id) &&
              (get(['inboxes'], countData, []).find(inboxData => inboxData.inboxId === inbox.id).count || 0)) ||
              0}
          </span>
        </VmoMenuItem>
      )
    })
  }

  const renderTeammates = () => {
    return (
      <>
        {teammates?.filter(s => !_.get(stateMetaData, 'favUsers', []).includes(s.id)).length === 0 && (
          <p className="no-menu-data">No Data Available</p>
        )}
        {teammates.length > 0 &&
          teammates
            .filter(u => !_.get(stateMetaData, 'favUsers', []).includes(u.id))
            .map(teammate => {
              return (
                <div key={teammate.id}>
                  <VmoMenuItem
                    className="userNav"
                    active={selectedInbox.id === `${teammate.id}teammate`}
                    onClick={() => {
                      setSelectedInbox({
                        label: `${teammate.firstName} ${teammate.lastName}`,
                        name: teammate.id,
                        type: 'teammate',
                        id: `${teammate.id}teammate`,
                      })
                      setSearchConvseration('')
                    }}
                    action
                  >
                    <SvgIcon path="inbox/user" /> {teammate.firstName}
                    <span>
                      {get(['users'], countData, []).find(mailbox => teammate.id === mailbox.id) &&
                        get(['users'], countData, []).find(mailbox => teammate.id === mailbox.id).count}
                    </span>
                  </VmoMenuItem>
                </div>
              )
            })}
      </>
    )
  }

  const renderTags = () => {
    return (
      <>
        {tagList?.filter(s => !_.get(stateMetaData, 'favTags', []).includes(s.id)).length === 0 && (
          <p className="no-menu-data">No Data Available</p>
        )}
        {tagList?.length > 0 &&
          tagList
            .filter(t => !_.get(stateMetaData, 'favTags', []).includes(t.id))
            .map((tag, i) => {
              return (
                <div key={i}>
                  <VmoMenuItem
                    className="userNav"
                    active={selectedInbox.tag === tag.label + i}
                    onClick={() => {
                      setSelectedInbox({
                        tag: tag.label + i,
                        type: 'tag',
                        name: tag.id,
                        label: tag.label,
                      })
                      setSearchConvseration('')
                    }}
                    action
                  >
                    <VmoLabel className="tag-label" circular color={tag.color || 'blue'} />
                    {tag.label}
                  </VmoMenuItem>
                </div>
              )
            })}
      </>
    )
  }

  const renderStatus = () => {
    return (
      <>
        {statusList?.filter(s => !_.get(stateMetaData, 'favStatus', []).includes(s.id)).length === 0 && (
          <p className="no-menu-data">No Data Available</p>
        )}
        {statusList.length > 0 &&
          statusList
            .filter(s => !_.get(stateMetaData, 'favStatus', []).includes(s.id))
            .map((status, i) => {
              return (
                <div key={i}>
                  <VmoMenuItem
                    className="userNav"
                    active={selectedInbox.status === status.status + i}
                    onClick={() => {
                      setSelectedInbox({
                        status: status.status + i,
                        type: 'status',
                        name: status.id,
                        label: status.status,
                      })
                      setSearchConvseration('')
                    }}
                    action
                  >
                    <SvgIcon path={`inbox/${status.status.toLowerCase().split(' ').join('-')}`} /> {status.status}
                    <span>
                      {get(['statuses'], countData, []).find(stat => status.id === stat.id) &&
                        get(['statuses'], countData, []).find(stat => status.id === stat.id).count}
                    </span>
                  </VmoMenuItem>
                </div>
              )
            })}
      </>
    )
  }

  const renderPriority = () => {
    return (
      <>
        {priorityList?.filter(p => !_.get(stateMetaData, 'favPriority', []).includes(p.key)).length === 0 && (
          <p className="no-menu-data">No Data Available</p>
        )}
        {priorityList
          .filter(p => !_.get(stateMetaData, 'favPriority', []).includes(p.key))
          .map((priority, i) => {
            return (
              <div key={i}>
                <VmoMenuItem
                  className="userNav"
                  active={selectedInbox.priority === priority.value + i}
                  onClick={() => {
                    setSelectedInbox({
                      priority: priority.value + i,
                      type: 'priority',
                      name: priority.value,
                      label: priority.text,
                    })
                    setSearchConvseration('')
                  }}
                  action
                >
                  <SvgIcon path={`inbox/${priority.value}`} /> {priority.text}
                  <span>
                    {get(['priorities'], countData, []).find(stat => priority.value === stat.priority) &&
                      get(['priorities'], countData, []).find(stat => priority.value === stat.priority).count}
                  </span>
                </VmoMenuItem>
              </div>
            )
          })}
      </>
    )
  }

  return (
    <div className="email-sidebar">
      <DocumentTitle title="Inbox" />
      <VmoMenu vertical secondary className="kooper-menu-inbox">
        <VmoTooltip
          size="mini"
          content={
            manageConversationPermissions
              ? 'New Conversation'
              : getPermissionTooltipContent({roleAccess: manageConversationPermissions})
          }
          position="top center"
          trigger={
            <VmoButton
              fluid
              className="basic-fill-btn "
              onClick={e => {
                e.stopPropagation()
                if (manageConversationPermissions) {
                  setIsOpenMailComposer(true)
                }
              }}
            >
              <VmoIcon name="edit outline" /> New
            </VmoButton>
          }
        />
        <VmoAccordion>
          <VmoAccordionTitle onClick={() => setActiveAccordion({...activeAccordion, inbox: !activeAccordion.inbox})}>
            <div className="d-flex-ac">
              {activeAccordion.inbox ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Inbox
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeAccordion.inbox}>
            <VmoMenuItem
              active={selectedInbox.name === 'all_inbox'}
              onClick={() => {
                setSelectedInbox({type: 'default', name: 'all_inbox', label: 'All Inbox'})
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/all-inbox" /> All Inbox
              <span>{get(['allInbox'], countData, 0)}</span>
            </VmoMenuItem>
            {/* <VmoAccordionContent className="ml-3" active={activeAccordion.activeChannels}>
              {renderMailBox()}
            </VmoAccordionContent> */}
            <VmoMenuItem
              active={selectedInbox.name === 'unassign'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'unassign',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/unassigned" /> Unassigned
              <span>{get(['unassigned'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              action
              className="inboxtour__step-one"
              active={selectedInbox.name === 'assign_me'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'assign_me',
                })
                setSearchConvseration('')
              }}
            >
              <SvgIcon path="inbox/assigned-me" /> Assigned to me
              <span>{get(['assignedToMe'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              active={selectedInbox.name === 'mention'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'mention',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/mention" /> Mentions
              <span>{get(['myMentions'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              active={selectedInbox.name === 'offline'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'offline',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/offline" /> Offline
              <span>{get(['offline'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              active={selectedInbox.name === 'following'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'following',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/following" /> Following
              <span>{get(['following'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              active={selectedInbox.name === 'overdue'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'overdue',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/overdue" /> Overdue
              <span>{get(['overdue'], countData, 0)}</span>
            </VmoMenuItem>
            <VmoMenuItem
              active={selectedInbox.name === 'sent'}
              onClick={() => {
                setSelectedInbox({
                  type: 'default',
                  name: 'sent',
                })
                setSearchConvseration('')
              }}
              tag="a"
              action
            >
              <SvgIcon path="inbox/sent" /> Sent
              <span>{get(['sent'], countData, 0)}</span>
            </VmoMenuItem>

            <VmoAccordion className="m-0">
              <VmoAccordionTitle
                onClick={() => setActiveAccordion({...activeAccordion, viewMore: !activeAccordion.viewMore})}
                active={activeAccordion.viewMore}
                style={{display: 'block'}}
              >
                <VmoMenuItem action tag="a" name="teammatesCollapse">
                  {activeAccordion.viewMore ? <SvgIcon path="common/down" /> : <FiChevronRight />}
                  More
                </VmoMenuItem>
              </VmoAccordionTitle>
              <VmoAccordionContent active={activeAccordion.viewMore} className="p-0">
                <VmoMenuItem
                  active={selectedInbox.name === 'slaBreached'}
                  onClick={() => {
                    setSelectedInbox({
                      type: 'default',
                      name: 'slaBreached',
                    })
                    setSearchConvseration('')
                  }}
                  tag="a"
                  action
                >
                  <SvgIcon path="inbox/sla-brached" /> SLA Breached
                  <span>{get(['slaBreached'], countData, 0)}</span>
                </VmoMenuItem>
                <VmoMenuItem
                  active={selectedInbox.name === 'snooze'}
                  onClick={() => {
                    setSelectedInbox({
                      type: 'default',
                      name: 'snooze',
                    })
                    setSearchConvseration('')
                  }}
                  tag="a"
                  action
                >
                  <SvgIcon path="inbox/snooze" /> Snooze
                  <span>{get(['snoozed'], countData, 0)}</span>
                </VmoMenuItem>
                <VmoMenuItem
                  active={selectedInbox.name === 'trash'}
                  onClick={() => {
                    setSelectedInbox({
                      type: 'default',
                      name: 'trash',
                    })
                    setSearchConvseration('')
                  }}
                  tag="a"
                  action
                >
                  <SvgIcon path="inbox/trash" /> Trash
                  <span>{get(['trash'], countData, 0)}</span>
                </VmoMenuItem>
                <VmoMenuItem
                  active={selectedInbox.name === 'draft'}
                  onClick={() => {
                    setSelectedInbox({
                      type: 'default',
                      name: 'draft',
                    })
                    setSearchConvseration('')
                  }}
                  tag="a"
                  action
                >
                  <SvgIcon path="inbox/draft" /> Draft
                  <span>{get(['draft'], countData, 0)}</span>
                </VmoMenuItem>
                <VmoMenuItem
                  className="mb-3"
                  active={selectedInbox.name === 'spam'}
                  onClick={() => {
                    setSelectedInbox({
                      type: 'default',
                      name: 'spam',
                    })
                    setSearchConvseration('')
                  }}
                  tag="a"
                  action
                >
                  <SvgIcon path="inbox/spam" /> Spam
                  <span>{get(['spam'], countData, 0)}</span>
                </VmoMenuItem>
              </VmoAccordionContent>
            </VmoAccordion>
          </VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion>
          <VmoAccordionTitle
            onClick={() => setActiveAccordion({...activeAccordion, activeChannels: !activeAccordion.activeChannels})}
          >
            <div className="d-flex-ac">
              {activeAccordion.activeChannels ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Channels ({mailBox.length})
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeAccordion.activeChannels}>{renderMailBox()}</VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion>
          <VmoAccordionTitle
            onClick={() => setActiveAccordion({...activeAccordion, activeUsers: !activeAccordion.activeUsers})}
          >
            <div className="d-flex-ac">
              {activeAccordion.activeUsers ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Users ({teammates?.length})
            </div>
            <div className="indexUser">
              {activeAccordion.activeUsers && (
                <VmoPopup
                  trigger={
                    <FiMoreHorizontal
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    />
                  }
                  className="es-more-dd"
                  on="click"
                  basic
                  pinned
                >
                  <VmoList selection verticalAlign="middle">
                    <h3>Users</h3>
                    <VmoDivider />
                    {teammates.length > 0 ? (
                      teammates.map(teammate => {
                        return (
                          <VmoListItem
                            key={teammate.id}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          >
                            <VmoListContent
                              className="d-flex"
                              onClick={() => {
                                let users = _.get(stateMetaData, 'favUsers', [])
                                const check = _.get(stateMetaData, 'favUsers', []).includes(teammate.id)

                                if (check) {
                                  users = users.filter(agent => agent !== teammate.id)
                                } else {
                                  users = [...users, teammate.id]
                                }
                                const data = {
                                  favUsers: users,
                                }
                                handleSetMetaData({
                                  ...stateMetaData,
                                  favUsers: users,
                                })

                                dispatch(setMetaData(data))
                              }}
                            >
                              <h4>{teammate.firstName}</h4>
                              <VmoRadio
                                name={teammate.id}
                                checked={!_.get(stateMetaData, 'favUsers', []).includes(teammate.id)}
                                toggle
                              />
                            </VmoListContent>
                          </VmoListItem>
                        )
                      })
                    ) : (
                      <p className="pb-3 no-menu-data">No Data Available</p>
                    )}
                  </VmoList>
                </VmoPopup>
              )}
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeAccordion.activeUsers}>{renderTeammates()}</VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion>
          <VmoAccordionTitle
            onClick={() => setActiveAccordion({...activeAccordion, activeTags: !activeAccordion.activeTags})}
          >
            <div className="d-flex-ac">
              {activeAccordion.activeTags ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Labels ({tagList.length})
            </div>
            <div className="indexUser">
              {activeAccordion.activeTags && (
                <VmoPopup
                  trigger={
                    <FiMoreHorizontal
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    />
                  }
                  className="es-more-dd"
                  on="click"
                  pinned
                  basic
                >
                  <VmoList selection verticalAlign="middle">
                    <h3>Labels</h3>
                    <VmoDivider />
                    {tagList.length > 0 ? (
                      tagList.map(tag => {
                        return (
                          <VmoListItem
                            key={tag.id}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                            }}
                          >
                            <VmoListContent
                              className="d-flex"
                              onClick={() => {
                                let users = _.get(stateMetaData, 'favTags', [])
                                const check = _.get(stateMetaData, 'favTags', []).includes(tag.id)
                                if (check) {
                                  users = users.filter(agent => agent !== tag.id)
                                } else {
                                  users = [...users, tag.id]
                                }
                                const data = {
                                  favTags: users,
                                }
                                handleSetMetaData({
                                  ...stateMetaData,
                                  favTags: users,
                                })

                                dispatch(setMetaData(data))
                              }}
                            >
                              <h4>{tag.label}</h4>
                              <VmoRadio
                                name={tag.id}
                                checked={!_.get(stateMetaData, 'favTags', []).includes(tag.id)}
                                toggle
                              />
                            </VmoListContent>
                          </VmoListItem>
                        )
                      })
                    ) : (
                      <p className="pb-3"> No Data Available </p>
                    )}
                  </VmoList>
                </VmoPopup>
              )}
            </div>
          </VmoAccordionTitle>

          <VmoAccordionContent active={activeAccordion.activeTags}>{renderTags()}</VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion className="inboxtour__step-three">
          <VmoAccordionTitle
            onClick={() => setActiveAccordion({...activeAccordion, activeStatus: !activeAccordion.activeStatus})}
          >
            <div className="d-flex-ac">
              {activeAccordion.activeStatus ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Status
            </div>
            <div className="indexUser">
              {activeAccordion.activeStatus && (
                <VmoPopup
                  trigger={
                    <FiMoreHorizontal
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    />
                  }
                  className="es-more-dd"
                  on="click"
                  pinned
                  basic
                >
                  <VmoList selection verticalAlign="middle">
                    <h3>Status</h3>
                    <VmoDivider />
                    {statusList.map(stat => {
                      return (
                        <VmoListItem
                          key={stat.id}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                        >
                          <VmoListContent
                            className="d-flex"
                            onClick={() => {
                              let users = _.get(stateMetaData, 'favStatus', [])
                              const check = _.get(stateMetaData, 'favStatus', []).includes(stat.id)
                              if (check) {
                                users = users.filter(agent => agent !== stat.id)
                              } else {
                                users = [...users, stat.id]
                              }
                              const data = {
                                favStatus: users,
                              }
                              handleSetMetaData({
                                ...stateMetaData,
                                favStatus: users,
                              })

                              dispatch(setMetaData(data))
                            }}
                          >
                            <h4>{stat.status}</h4>
                            <VmoRadio
                              name={stat.id}
                              checked={!_.get(stateMetaData, 'favStatus', []).includes(stat.id)}
                              toggle
                            />
                          </VmoListContent>
                        </VmoListItem>
                      )
                    })}
                  </VmoList>
                </VmoPopup>
              )}
            </div>
          </VmoAccordionTitle>

          <VmoAccordionContent active={activeAccordion.activeStatus}>{renderStatus()}</VmoAccordionContent>
        </VmoAccordion>

        <VmoAccordion>
          <VmoAccordionTitle
            onClick={() => setActiveAccordion({...activeAccordion, activePriority: !activeAccordion.activePriority})}
          >
            <div className="d-flex-ac">
              {activeAccordion.activePriority ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              Priority
            </div>
            <div className="indexUser">
              {activeAccordion.activePriority && (
                <VmoPopup
                  trigger={
                    <FiMoreHorizontal
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                      }}
                    />
                  }
                  className="es-more-dd"
                  on="click"
                  pinned
                  basic
                >
                  <VmoList selection verticalAlign="middle">
                    <h3>Priority</h3>
                    <VmoDivider />
                    {priorityList.map(priority => {
                      return (
                        <VmoListItem
                          key={priority.key}
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                        >
                          <VmoListContent
                            className="d-flex"
                            onClick={() => {
                              let users = _.get(stateMetaData, 'favPriority', [])
                              const check = _.get(stateMetaData, 'favPriority', []).includes(priority.key)
                              if (check) {
                                users = users.filter(agent => agent !== priority.key)
                              } else {
                                users = [...users, priority.key]
                              }
                              const data = {
                                favPriority: users,
                              }
                              handleSetMetaData({
                                ...stateMetaData,
                                favPriority: users,
                              })
                              dispatch(setMetaData(data))
                            }}
                          >
                            <h4>{priority.text}</h4>
                            <VmoRadio
                              name={priority.key}
                              checked={!_.get(stateMetaData, 'favPriority', []).includes(priority.key)}
                              toggle
                            />
                          </VmoListContent>
                        </VmoListItem>
                      )
                    })}
                  </VmoList>
                </VmoPopup>
              )}
            </div>
          </VmoAccordionTitle>
          <VmoAccordionContent active={activeAccordion.activePriority}>{renderPriority()}</VmoAccordionContent>
        </VmoAccordion>
      </VmoMenu>
    </div>
  )
}
