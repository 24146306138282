/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDivider,
  VmoForm,
  VmoFormCheckbox,
  VmoFormField,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
  VmoLabel,
} from 'vmo-library'
import {UPDATE_ADVANCED_WIDGET} from 'actions/types'
import {GetAdvancedWidget, GetInstallationWidget, UpdateAdvancedWidget} from 'actions/settings_messenger'
import SettingLayout from 'layouts/settingLayout'
import {get, removeDoubleQuotes} from 'utils/helper'
import {advanceDefaultValue, advanceSchema} from 'validation/Settings/messenger.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'

const Advanced = ({setWarningModal}) => {
  const dispatch = useDispatch()

  const {
    handleSubmit,
    control,
    reset,
    errors,
    watch,
    formState: {isDirty},
  } = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(advanceSchema),
    defaultValues: advanceDefaultValue,
  })

  const processData = watch('processData')
  const comMsg = watch('comMsg')
  const optInBtn = watch('optInBtn')
  const optOutBtn = watch('optOutBtn')

  const {advanced, installation} = useSelector(state => state.settingsMessenger)
  const {successLabels = []} = useSelector(store => store.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const advancedLearnMore = settingsLearnMoreUrl?.messenger?.advanced?.learnMore
  const sneakLearnMore = settingsLearnMoreUrl?.messenger?.advanced?.sneakLearnMore

  const {channelsPermissions} = useUserPermissions()
  const manageMessangerPagesPermissions = channelsPermissions?.manage_messanger_pages

  useEffect(() => {
    if (!(installation && installation[0])) dispatch(GetInstallationWidget())
  }, [dispatch, installation])

  useEffect(() => {
    if (installation && installation[0]) dispatch(GetAdvancedWidget(installation[0].id))
  }, [dispatch, installation])

  useEffect(() => {
    if (advanced) {
      const data = {
        inAttach: get(['inAttach'], advanced, false),
        outAttach: get(['outAttach'], advanced, false),
        showRead: get(['showRead'], advanced, false),
        sneakPeek: get(['sneakPeek'], advanced, false),
        isDisableMobile: get(['isDisableMobile'], advanced, true),
        enableVoiceMessage: get(['enableVoiceMessage'], advanced, true),
        processData: get(['processData'], advanced, false),
        comMsg: get(['comMsg'], advanced, ''),
        optInBtn: get(['optInBtn'], advanced, ''),
        optOutBtn: get(['optOutBtn'], advanced, ''),
      }
      reset(data)
    } else {
      reset(advanceDefaultValue)
    }
  }, [advanced, reset])

  useEffect(() => {
    if (successLabels.includes(UPDATE_ADVANCED_WIDGET)) {
      setWarningModal(false)
    }
  }, [setWarningModal, successLabels])

  useEffect(() => {
    if (isDirty) {
      setWarningModal(isDirty)
    }
    return () => setWarningModal(false)
  }, [isDirty, setWarningModal])

  const resetData = () => {
    if (advanced) {
      const data = {
        inAttach: get(['inAttach'], advanced, false),
        outAttach: get(['outAttach'], advanced, false),
        showRead: get(['showRead'], advanced, false),
        sneakPeek: get(['sneakPeek'], advanced, false),
        isDisableMobile: get(['isDisableMobile'], advanced, true),
        enableVoiceMessage: get(['enableVoiceMessage'], advanced, true),
        processData: get(['processData'], advanced, false),
        comMsg: advanced.comMsg === null ? '' : advanced.comMsg,
        optInBtn: advanced.optInBtn === null ? '' : advanced.optInBtn,
        optOutBtn: advanced.optOutBtn === null ? '' : advanced.optOutBtn,
      }
      reset(data)
    } else {
      reset(advanceDefaultValue)
    }
  }

  const updateData = data => {
    if (advanced.id) dispatch(UpdateAdvancedWidget(advanced.id, data))
    dispatch({type: UPDATE_ADVANCED_WIDGET, data})
    setWarningModal(false)
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/advanced" />}
      header="Advanced Settings"
      subHeader="Manage all advanced settings related to your integrated chat messenger"
      learnMoreUrl={advancedLearnMore}
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: 'Reset',
          onClick: resetData,
        },
        success: {
          content: 'Save',
          ...(manageMessangerPagesPermissions && {
            onClick: handleSubmit(updateData),
          }),
        },
      }}
      lockRole={!manageMessangerPagesPermissions}
    >
      <VmoCard fluid className="switchBox">
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Allow incoming attachment</h5>
              <p className="kooper-lead">Customer can send attachment during chat</p>
            </div>
            <Controller
              control={control}
              name="inAttach"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Allow outgoing attachment</h5>
              <p className="kooper-lead">Chat agent can sent attachment to customers</p>
            </div>
            <Controller
              control={control}
              name="outAttach"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Show read/ delivery message</h5>
              <p className="kooper-lead">See if the message has been read or not</p>
            </div>
            <Controller
              control={control}
              name="showRead"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5> Message sneak peek</h5>
              <p className="kooper-lead">
                Get a sneak peek of the message which the customer is typing. <a href={sneakLearnMore}>Learn More</a>
              </p>
            </div>
            <Controller
              control={control}
              name="sneakPeek"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Disable Messenger in Mobile</h5>
              <p className="kooper-lead">Don't show messenger when the page is opened in a mobile device</p>
            </div>
            <Controller
              control={control}
              name="isDisableMobile"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Allow voice message</h5>
              <p className="kooper-lead">Enable both ways voice message communication</p>
            </div>
            <Controller
              control={control}
              name="enableVoiceMessage"
              render={({onChange, value}) => (
                <VmoFormCheckbox
                  toggle
                  checked={value}
                  onChange={(e, value) => {
                    onChange(value.checked)
                  }}
                />
              )}
            />
          </div>
        </VmoCardContent>
        <VmoCardContent>
          <div className="d-flex">
            <div>
              <h5>Consent to process data</h5>
              <p className="kooper-lead">Ask for user consent on processing their data</p>
            </div>
            <Controller
              control={control}
              name="processData"
              render={({onChange, value}) => (
                <VmoFormCheckbox toggle checked={value} onChange={(e, {checked}) => onChange(checked)} />
              )}
            />
          </div>

          {processData && (
            <>
              <VmoDivider />
              <VmoGrid>
                <VmoGridColumn width={8}>
                  <VmoForm>
                    <Controller
                      control={control}
                      name="comMsg"
                      render={({onChange, value}) => (
                        <VmoFormField disabled={!processData}>
                          <label>Consent to communicate messages</label>
                          <VmoFormInput
                            type="textarea"
                            placeholder="Yes, I agree with tearms and privacy policy."
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.comMsg && {
                                content: removeDoubleQuotes(errors.comMsg.message),
                              }
                            }
                          />
                          <span className="charType">{comMsg ? comMsg.length : 0}/20</span>
                        </VmoFormField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="optInBtn"
                      render={({onChange, value}) => (
                        <VmoFormField disabled={!processData}>
                          <label>Opt-in button text</label>
                          <VmoFormInput
                            type="text"
                            placeholder="Yes, I agree"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.optInBtn && {
                                content: removeDoubleQuotes(errors.optInBtn.message),
                              }
                            }
                          />
                          <span className="charType">{optInBtn ? optInBtn.length : 0}/20</span>
                        </VmoFormField>
                      )}
                    />
                    <Controller
                      control={control}
                      name="optOutBtn"
                      render={({onChange, value}) => (
                        <VmoFormField disabled={!processData}>
                          <label>Opt-out button text</label>
                          <VmoFormInput
                            type="text"
                            placeholder="No, Not Now"
                            value={value}
                            onChange={(e, {value}) => onChange(value)}
                            error={
                              errors.optOutBtn && {
                                content: removeDoubleQuotes(errors.optOutBtn.message),
                              }
                            }
                          />
                          <span className="charType">{optOutBtn ? optOutBtn.length : 0}/20</span>
                        </VmoFormField>
                      )}
                    />
                  </VmoForm>
                </VmoGridColumn>
                <VmoGridColumn width={8}>
                  <VmoLabel disabled={!processData}>Preview</VmoLabel>
                  <p>{comMsg}</p>
                  <div>
                    <VmoButton className="mr-3" disabled={!processData} basic>
                      {optOutBtn}
                    </VmoButton>
                    <VmoButton disabled={!processData} primary>
                      {optInBtn}
                    </VmoButton>
                  </div>
                </VmoGridColumn>
              </VmoGrid>
            </>
          )}
        </VmoCardContent>
      </VmoCard>
      {/* <a className="link-to-know">
        <FiBook />
        Lorem ipsum dolor sit amet consectetur adipisicing elit.
      </a> */}
    </SettingLayout>
  )
}

export default Advanced
