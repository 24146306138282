import {apiAction} from './api'
import {QUOTES_ENDPOINT} from './endpoint'
import {
  CREATE_QUOTE_PREFIX,
  DELETE_QUOTE_PREFIX,
  GET_QUOTE_PREFIX,
  CREATE_QUOTE,
  UPDATE_QUOTE,
  DELETE_QUOTE,
  GET_SINGLE_QUOTE,
  GET_QUOTE_LIST,
  PUBLISH_QUOTE,
  GET_NEW_QUOTE_NUMBER,
  COPY_QUOTE,
  DELETE_LOGO,
  GET_QUOTE_PUBLIC_LINK,
} from './types'

export function getQuotePrefixes() {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/prefixes`,
    method: 'GET',
    label: GET_QUOTE_PREFIX,
  })
}

export function createQuotePrefix(data) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/prefix`,
    method: 'POST',
    label: CREATE_QUOTE_PREFIX,
    data,
    showToast: true,
    successMessage: 'Prefix Added',
  })
}

export function deleteQuotePrefix(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/prefix/${id}`,
    method: 'DELETE',
    label: DELETE_QUOTE_PREFIX,
    showToast: true,
    successMessage: 'Prefix Deleted',
  })
}

export function createQuote(data) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote`,
    method: 'POST',
    label: CREATE_QUOTE,
    data,
    showToast: true,
    successMessage: 'Quote Added',
  })
}

export function updateQuote(data, id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/${id}`,
    method: 'PUT',
    label: UPDATE_QUOTE,
    data,
    showToast: true,
    successMessage: 'Quote Updated',
  })
}

export function getQuoteList(params = {page: 1}) {
  const keys = Object.keys(params || {})
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${QUOTES_ENDPOINT}?${query}`,
    method: 'GET',
    label: GET_QUOTE_LIST,
  })
}

export function getSingleQuote(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/${id}`,
    method: 'GET',
    label: GET_SINGLE_QUOTE,
  })
}

export function deleteQuote(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/${id}`,
    method: 'DELETE',
    label: DELETE_QUOTE,
    showToast: true,
    successMessage: 'Quote Deleted',
  })
}

export function publishQuote(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/publish/${id}`,
    method: 'PUT',
    label: PUBLISH_QUOTE,
    showToast: true,
    successMessage: 'Quote Published',
  })
}

export function copyQuote(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/copy/${id}`,
    method: 'PUT',
    label: COPY_QUOTE,
    showToast: true,
    successMessage: 'Quote Cloned',
  })
}
export function getNewQuoteNumber(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/number/${id}`,
    method: 'GET',
    label: GET_NEW_QUOTE_NUMBER,
  })
}

export function deleteLogo(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/logo/${id}`,
    method: 'DELETE',
    label: DELETE_LOGO,
    showToast: true,
    successMessage: 'Logo Updated',
  })
}

export function getQuotePublicLink(id) {
  return apiAction({
    url: `${QUOTES_ENDPOINT}/quote/link/${id}`,
    method: 'GET',
    label: GET_QUOTE_PUBLIC_LINK,
  })
}
