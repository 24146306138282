import {deleteProjectsTimesheetTimeLogs, getProjectsTimeTrackingList} from 'actions/projects'
import {timeStatusList} from 'constants/projects'
import _ from 'lodash'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {FiChevronRight} from 'react-icons/fi'
import {useDispatch, useSelector} from 'react-redux'
import {get, getFormattedDate, startCase} from 'utils/helper'
import {
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoIcon,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import DeleteModal from 'components/common/DeleteModal'
import {DELETE_PROJECTS_TIMESHEET_TIME_LOGS, UPDATE_PROJECTS_TIMESHEET_TIME_LOG} from 'actions/types'
import SvgIcon from 'components/common/SvgIcon'
import TimeModal from '../../../Time/TimeModal'

const ProjectBudgetTimeLog = ({projectId}) => {
  const dispatch = useDispatch()

  const [activeAccordion, setActiveAccordion] = useState(-1)
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false)
  const [timesheetId, setTimesheetId] = useState(null)
  const [timeId, setTimeId] = useState(null)
  const [isTimeDeleteModalOpen, setIsTimeDeleteModalOpen] = useState(false)

  const [currentListParams, setCurrentListParams] = useState({
    projectId,
    status: 'all',
    createdBy: 'all',
    groupBy: 'date',
    orderBy: 'asc',
  })

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {timeTrackingList = {}, projectAssignees = [], timesheets = []} = useSelector(state => state.projects)

  useEffect(() => {
    const newList = _.omitBy(currentListParams, value => value === 'all')
    dispatch(getProjectsTimeTrackingList(newList))
  }, [dispatch, currentListParams])

  useEffect(() => {
    if (
      successLabels.includes(UPDATE_PROJECTS_TIMESHEET_TIME_LOG) ||
      successLabels.includes(DELETE_PROJECTS_TIMESHEET_TIME_LOGS)
    ) {
      const newList = _.omitBy(currentListParams, value => value === 'all')
      dispatch(getProjectsTimeTrackingList(newList))
    }
  }, [successLabels, currentListParams, dispatch])

  const getValue = key => {
    if (currentListParams.groupBy === 'date') {
      return moment(key).format('MMM DD')
    }
    if (currentListParams.groupBy === 'month') {
      return moment(key).format('MMM, YYYY')
    }
    if (currentListParams.groupBy === 'person') {
      const assignee = projectAssignees.find(assignee => assignee.id === +key)
      if (assignee) {
        return `${get(['firstName'], assignee, '')} ${get(['lastName'], assignee, '')}`
      }
      return null
    }
    if (currentListParams.groupBy === 'timesheet') {
      const timesheet = timesheets.find(timesheet => timesheet.id === +key)
      if (timesheet) {
        return timesheet.title
      }
      return null
    }
    return null
  }

  const renderTableBody = () => {
    if (Object.values(timeTrackingList).every(val => val.length === 0)) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="8" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return Object.entries(timeTrackingList).map(([key, data], index) => {
      return (
        <>
          <VmoTableRow onClick={() => setActiveAccordion(activeAccordion === index ? '-1' : index)}>
            <VmoTableCell>
              {activeAccordion === index ? (
                <SvgIcon path="common/down" style={{marginRight: 8}} />
              ) : (
                <FiChevronRight style={{marginRight: 8}} />
              )}{' '}
              {getValue(key)}
            </VmoTableCell>
            <VmoTableCell colSpan={8} />
          </VmoTableRow>
          {data.map(timeLog => (
            <VmoTableRow
              key={timeLog.id}
              className="tableLink"
              style={{
                display: activeAccordion === index ? '' : 'none',
              }}
            >
              <VmoTableCell>
                {get(['createdByDetails', 'firstName'], timeLog, '')}{' '}
                {get(['createdByDetails', 'lastName'], timeLog, '')}
              </VmoTableCell>
              <VmoTableCell>{`${get(['loggedHrs'], timeLog, 0)}h ${get(['loggedMins'], timeLog, 0)}m`}</VmoTableCell>
              <VmoTableCell>{getFormattedDate(timeLog.date)}</VmoTableCell>
              <VmoTableCell>{timeLog.description || 'No Description'}</VmoTableCell>
              <VmoTableCell>{timeLog.tasklistDetails?.title || '-'}</VmoTableCell>
              <VmoTableCell>{timeLog.taskDetails?.title || '-'}</VmoTableCell>
              <VmoTableCell>{startCase(get(['status'], timeLog, ''))}</VmoTableCell>
              <VmoTableCell>
                <VmoDropdown direction="left" icon={<SvgIcon path="common/more-verticle" />} className="icon">
                  <VmoDropdownMenu>
                    <VmoDropdownItem
                      onClick={() => {
                        setTimeId(timeLog.id)
                        setTimesheetId(timeLog.timesheetId)
                        setIsTimeModalOpen(true)
                      }}
                    >
                      <VmoIcon name="edit" /> Edit
                    </VmoDropdownItem>
                    <VmoDropdownItem
                      onClick={() => {
                        setTimeId(timeLog.id)
                        setTimesheetId(timeLog.timesheetId)
                        setIsTimeDeleteModalOpen(true)
                      }}
                    >
                      <VmoIcon name="trash alternate" /> Delete
                    </VmoDropdownItem>
                  </VmoDropdownMenu>
                </VmoDropdown>
              </VmoTableCell>
            </VmoTableRow>
          ))}
        </>
      )
    })
  }

  return (
    <>
      <div className="project-finance-budget-expense-action">
        <VmoTooltip
          position="top center"
          content="Status"
          size="mini"
          trigger={
            <VmoDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[{key: 'all', value: 'all', text: 'All'}, ...timeStatusList]}
              value={currentListParams.status}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  status: value,
                })
              }
            />
          }
        />
        <VmoTooltip
          position="top center"
          content="Logged By"
          size="mini"
          trigger={
            <VmoDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'all', value: 'all', text: 'All'},
                ...projectAssignees.map(({id, firstName, lastName}) => ({
                  key: id,
                  value: id,
                  text: `${firstName} ${lastName}`,
                })),
              ]}
              value={currentListParams.createdBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  createdBy: value,
                })
              }
            />
          }
        />
        <VmoTooltip
          position="top center"
          content="Group By"
          size="mini"
          trigger={
            <VmoDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'date', value: 'date', text: 'Date'},
                {key: 'month', value: 'month', text: 'Month'},
                {key: 'person', value: 'person', text: 'Person'},
                {key: 'timesheet', value: 'timesheet', text: 'Timesheet'},
              ]}
              value={currentListParams.groupBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  groupBy: value,
                })
              }
            />
          }
        />
        <VmoTooltip
          position="top center"
          content="Order By"
          size="mini"
          trigger={
            <VmoDropdown
              icon="chevron down"
              button
              className="dropdown-ellipsis"
              options={[
                {key: 'asc', value: 'asc', text: 'Asc'},
                {key: 'desc', value: 'desc', text: 'Desc'},
              ]}
              value={currentListParams.orderBy}
              onChange={(e, {value}) =>
                setCurrentListParams({
                  ...currentListParams,
                  orderBy: value,
                })
              }
            />
          }
        />
      </div>

      <VmoTable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell>Logged By</VmoTableHeaderCell>
            <VmoTableHeaderCell>Time Logged</VmoTableHeaderCell>
            <VmoTableHeaderCell>Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Description</VmoTableHeaderCell>
            <VmoTableHeaderCell>Tasklist</VmoTableHeaderCell>
            <VmoTableHeaderCell>Task</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>{renderTableBody()}</VmoTableBody>
      </VmoTable>

      {isTimeModalOpen && (
        <TimeModal
          open={isTimeModalOpen}
          toggle={setIsTimeModalOpen}
          projectId={projectId}
          timesheetId={timesheetId}
          timeId={timeId}
        />
      )}

      {isTimeDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isTimeDeleteModalOpen}
          setIsModalOpen={setIsTimeDeleteModalOpen}
          onDelete={() => dispatch(deleteProjectsTimesheetTimeLogs({projectId, timesheetId, timeIds: [timeId]}))}
          type={DELETE_PROJECTS_TIMESHEET_TIME_LOGS}
        />
      )}
    </>
  )
}

export default ProjectBudgetTimeLog
