import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoDivider,
  VmoForm,
  VmoFormDropdown,
  VmoFormField,
  VmoFormInput,
  VmoGrid,
  VmoGridColumn,
  VmoInput,
  VmoTooltip,
} from 'vmo-library'

import {ADD_SURVEY, DELETE_SURVEY, EDIT_SURVEY, GET_EMAIL_SURVEY} from 'actions/types'
import {getAllMailInbox} from 'actions/inbox'
import {addSurvey, deleteSurvey, editSurvey, getEmailSurvey} from 'actions/settings_customer_satisfaction'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import {removeDoubleQuotes} from 'utils/helper'
import dummyAvatar from 'assets/user_avatar.png'
import star from 'assets/star.png'
import star1 from 'assets/star1.png'
import star2 from 'assets/star2.png'
import {emailSurveySchema} from 'validation/Settings/customerSatisfaction/emailSurvey.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {FaGrinHearts} from 'react-icons/fa'
import SvgIcon from 'components/common/SvgIcon'
import {INBOX_TYPES} from 'constants/enums'

const EmailSurveyForm = ({toggleCreate, setWarningModal}) => {
  const dispatch = useDispatch()
  const {id} = useParams()
  const history = useHistory()

  const [listMailBox, setListMailBox] = useState([])
  const [profilePicture, setProfilePicture] = useState(null)
  const [uploadProfile, setUploadProfile] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [idToDelete, setIdToDelete] = useState(0)

  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {inbox: mailBoxList} = useSelector(state => state.mailInbox)
  const {emailSurveySingle} = useSelector(state => state.settingsCustomerSatisfaction)
  const {productivityPermissions} = useUserPermissions()
  const customerSatficationsPermissions = productivityPermissions?.customer_satfication

  const defaultValueEail = {
    subject: '',
    inboxIds: [],
    ratingsType: 'star',
    heading: '',
    subHeading: '',
    footer: '',
    finishText: 'Thank you for your valuable feedback',
    commentEnabledBelow: 3,
    commentTitle: 'Can you tell us more about your experience?',
    commentEnabled: false,
    allowMultipleFeedback: false,
  }

  const {
    handleSubmit,
    errors,
    control,
    watch,
    reset,
    formState: {isDirty},
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(emailSurveySchema),
    defaultValues: defaultValueEail,
  })

  const ratingType = watch('ratingsType')

  const handleReset = () => {
    if (id) {
      reset(
        _.omit(emailSurveySingle, [
          'id',
          'accountId',
          'isDeleted',
          'isActive',
          'logo',
          'logoUrl',
          'type',
          'name',
          'description',
        ])
      )
      setUploadProfile(_.get(emailSurveySingle, 'logoUrl'))
    } else {
      reset(defaultValueEail)
    }
  }

  useEffect(() => {
    if (!mailBoxList) dispatch(getAllMailInbox())
    if (id) dispatch(getEmailSurvey())
  }, [dispatch, id])

  useEffect(() => {
    if (id && (isDirty || profilePicture)) {
      setWarningModal(isDirty)
    }
    return () => {
      if (id && (isDirty || profilePicture)) setWarningModal(false)
    }
  }, [isDirty, profilePicture, setWarningModal])

  useEffect(() => {
    if (successLabels.includes(GET_EMAIL_SURVEY)) {
      handleReset()
    }
    if (successLabels.includes(EDIT_SURVEY)) {
      dispatch(getEmailSurvey())
    }
    if (successLabels.includes(DELETE_SURVEY)) {
      dispatch({type: 'CLEAR_EMAIL_SURVEY'})
      history.push('/settings/email-survey')
    }
  }, [successLabels])

  useEffect(() => {
    if (mailBoxList) {
      const list = mailBoxList
        .filter(data => data.type === INBOX_TYPES.EMAIL && data.emailVerified)
        .map(({id, name = '', email = ''}) => ({
          key: id,
          value: id,
          content: (
            <div>
              {name}
              <small className="ml-2 kooper-lead">{email}</small>
            </div>
          ),
          text: name,
        }))
      setListMailBox(list)
    }
  }, [mailBoxList])

  const surveySubmit = data => {
    const payload = {
      ...data,
      ...(profilePicture && {file: profilePicture}),
      ...(!id && {type: 'email'}),
    }

    if (id) {
      dispatch(editSurvey(id, payload))
      setWarningModal(false)
    } else {
      dispatch(addSurvey(payload))
    }
  }

  const commentEnableOptions = Array.from({length: 5}, (_, i) => {
    if (ratingType === 'number') {
      return {value: i + 1, key: i + 1, text: `${i + 1}`}
    }
    return {value: i + 1, key: i + 1, text: `${i + 1} Star`}
  })

  return (
    <SettingLayout
      icon={<FaGrinHearts />}
      header={id ? 'Edit Email Survey' : 'Email Survey'}
      headerbackbtn={
        !id && {
          onClick: () => toggleCreate(false),
        }
      }
      subHeader="Create and share an engaging email survey to collect valuable customer feedback"
      table={false}
      headerDivider
      actionButton={{
        cancel: {
          content: id ? 'Reset' : 'Cancel',
          disabled: isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY)),
          onClick: () => {
            if (id) {
              handleReset()
            } else {
              toggleCreate(false)
            }
          },
        },
        success: {
          content: id ? 'Update' : 'Create',
          loading: isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY)),
          ...(customerSatficationsPermissions && {
            onClick: handleSubmit(data => surveySubmit(data)),
          }),
          disabled: profilePicture
            ? false
            : !isDirty || (isLoadingData && (type.includes(EDIT_SURVEY) || type.includes(ADD_SURVEY))),
        },
      }}
      lockRole={!customerSatficationsPermissions}
    >
      <VmoForm className="errorLabel">
        <VmoFormField required width={10}>
          <label>Mail Subject</label>
          <Controller
            name="subject"
            control={control}
            render={({value, onChange}) => (
              <VmoFormInput
                placeholder="Subject..."
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.subject && {
                    content: removeDoubleQuotes(errors.subject.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField required width={10}>
          <div className="info-header">
            <label className="d-block label-class">Select Inbox</label>
            <VmoTooltip
              content="Select inbox in which you want to receive survey response"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="inboxIds"
            control={control}
            render={({value, onChange}) => (
              <VmoFormDropdown
                fluid
                multiple
                selection
                placeholder="Select Inbox"
                value={value}
                options={listMailBox}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.inboxIds && {
                    content: removeDoubleQuotes(errors.inboxIds.message),
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={6}>
          <div className="info-header">
            <label>Rating Type</label>
            <VmoTooltip
              content="Choose the rating type for your survey"
              size="mini"
              position="top center"
              trigger={<SvgIcon path="common/question" />}
            />
          </div>
          <Controller
            name="ratingsType"
            control={control}
            render={({value, onChange}) => (
              <VmoFormDropdown
                selection
                value={value}
                options={[
                  {
                    value: 'star',
                    text: 'Star Ratings',
                    key: 'star',
                  },
                  {
                    value: 'number',
                    text: 'Number Ratings',
                    key: 'number',
                  },
                ]}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
        <div className="ruleBox">
          <div className="info-header">
            <h5 className="m-0">Email Preview</h5>
            <VmoTooltip
              trigger={<SvgIcon path="common/question" />}
              content="view of the email the customer will receive requesting for ratings and feedback"
              size="mini"
              position="top center"
            />
          </div>
          <div className="starRatPre">
            <div className="agentAvtar">
              <img src={uploadProfile || dummyAvatar} className="img-fluid" alt="img" />
            </div>
            <VmoButton basic className="fileBtn mb-3" size="small">
              Logo
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                key={uploadProfile}
                onInput={e => {
                  if (e.target.files) {
                    setProfilePicture(e.target.files)
                    setUploadProfile(URL.createObjectURL(e.target.files[0]))
                  }
                }}
              />
            </VmoButton>
            <Controller
              name="heading"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  type="text"
                  size="huge"
                  transparent
                  fluid
                  placeholder="Write Heading Here..."
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.heading && {
                      content: errors.heading.message,
                    }
                  }
                />
              )}
            />
            <Controller
              name="subHeading"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  type="text"
                  size="huge"
                  transparent
                  fluid
                  placeholder="Write Sub Heading Here..."
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={
                    errors.subHeading && {
                      content: errors.subHeading.message,
                    }
                  }
                />
              )}
            />

            <div className="RatprevBox">
              <VmoGrid className="starTable" columns="equal">
                {ratingType === 'number'
                  ? Array.from({length: 5}, (_, i) => {
                      let name = 'numberRating'
                      if (i === 2) name += ' numberRatingWarning'
                      if (i > 2) name += ' numberRatingGreen'
                      return (
                        <VmoGridColumn key={i}>
                          <div className={name}>{(i + 1).toString().padStart(2, '0')}</div>
                        </VmoGridColumn>
                      )
                    })
                  : Array.from({length: 5}, (_, i) => {
                      let source = star
                      if (i === 2) source = star1
                      if (i > 2) source = star2
                      return (
                        <VmoGridColumn key={i}>
                          <img alt="img" src={source} />
                        </VmoGridColumn>
                      )
                    })}
              </VmoGrid>
              <VmoGrid className="starTable">
                <VmoGridColumn width={2}>
                  <VmoInput disabled transparent type="text" className="emailStarText" value="Bad" />
                </VmoGridColumn>
                <VmoGridColumn width={12} />
                <VmoGridColumn width={2}>
                  <VmoInput disabled transparent type="text" className="emailStarText" value="Good" />
                </VmoGridColumn>
              </VmoGrid>
            </div>

            <div className="ratingFooter">
              <Controller
                name="footer"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    placeholder="Write down footer text here... e.g. All rights are reserved by KooperApp"
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.footer && {
                        content: errors.footer.message,
                      }
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
        <VmoDivider className="my-5" />
        <h4 className="mb-2">Thank you page</h4>
        <p className="kooper-lead mb-4">
          Your portal layout will be applied to this page. <a> How to change it? </a>
        </p>
        <VmoFormField width={10}>
          <Controller
            name="finishText"
            control={control}
            render={({value, onChange}) => (
              <VmoFormInput
                type="text"
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.finishText && {
                    content: errors.finishText.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoFormField>
          <label>Include comments box after below rating by</label>
          <Controller
            name="commentEnabledBelow"
            control={control}
            render={({value, onChange}) => (
              <VmoFormDropdown
                selection
                fluid
                width="2"
                value={value}
                options={commentEnableOptions}
                onChange={(e, {value}) => onChange(value)}
              />
            )}
          />
        </VmoFormField>
        <VmoFormField width={10}>
          <label>Comment box title</label>
          <Controller
            name="commentTitle"
            control={control}
            render={({value, onChange}) => (
              <VmoFormInput
                value={value}
                onChange={(e, {value}) => onChange(value)}
                error={
                  errors.commentTitle && {
                    content: errors.commentTitle.message,
                  }
                }
              />
            )}
          />
        </VmoFormField>
        <VmoCard fluid className="switchBox switchBoxSmall">
          <VmoCardContent>
            <div className="d-flex">
              <div>
                <h5>Additional Comment</h5>
                <p>Include additional comment textbox</p>
              </div>
              <Controller
                name="commentEnabled"
                control={control}
                render={({value, onChange}) => {
                  return <VmoCheckbox toggle checked={value} onChange={() => onChange(!value)} />
                }}
              />
            </div>
          </VmoCardContent>
          <VmoCardContent>
            <div className="d-flex">
              <div>
                <h5>Allow Multiple Feedback</h5>
                <p>Allow multiple feedback from the customer</p>
              </div>
              <Controller
                name="allowMultipleFeedback"
                control={control}
                render={({value, onChange}) => {
                  return <VmoCheckbox toggle checked={value} onChange={() => onChange(!value)} />
                }}
              />
            </div>
          </VmoCardContent>
        </VmoCard>

        {id && (
          <>
            <h4 className="mt-5 mb-0">Want to delete Email Survey?</h4>
            <p className="kooper-lead">Lorem Ipsum has been the industry standard dummy text</p>
            <VmoButton
              className="basic-red"
              onClick={() => {
                setIdToDelete(id)
                setDeleteModal(true)
              }}
            >
              Delete Email Survey
            </VmoButton>
          </>
        )}
      </VmoForm>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToDelete}
          deleteAction={deleteSurvey}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          type={DELETE_SURVEY}
        />
      )}
    </SettingLayout>
  )
}

export default EmailSurveyForm
