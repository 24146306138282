import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoDatePicker,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoHeaderContent,
  VmoIcon,
  VmoInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTab,
  VmoTabPane,
} from 'vmo-library'
import moment from 'moment'
import {
  CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT,
  GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
  UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK,
} from 'actions/types'
import {
  createProjectsTasksListTaskComment,
  getProjectTasksListSingleTask,
  updateProjectTaskListSingleTask,
} from 'actions/projects'
import {get, getFormattedDate, getFormattedTime, removeDoubleQuotes, startCase} from 'utils/helper'
import {viewTaskSchema} from 'validation/Projects/projects/tasks.schema'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const taskDefaultValues = {
  title: '',
  description: '',
  estimatedHrs: null,
  estimatedMin: null,
  startDate: new Date(moment().startOf('day')),
  endDate: new Date(moment().add(1, 'days').startOf('day')),
  stageId: null,
  labelIds: [],
  assignedTo: null,
}

const ViewTask = ({open, toggle, projectId, taskId}) => {
  const dispatch = useDispatch()

  const [comment, setComment] = useState('')

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {taskListAssignees = [], taskListStages = [], taskData = {}} = useSelector(state => state.projects)
  const {getTagList = []} = useSelector(state => state.multipleFields)
  const {allActivities = []} = taskData

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(viewTaskSchema),
    defaultValues: taskDefaultValues,
  })

  const {callAction: callGetProjectTasksListSingleTaskAction} = useApiResponse({
    action: getProjectTasksListSingleTask,
    payload: {projectId, taskId},
    dependency: [projectId, taskId],
    enabled: projectId && taskId,
    label: GET_PROJECTS_TASKS_LIST_SINGLE_TASK,
    storePath: 'projects.taskData',
    onSuccess: (taskData = {}) => {
      const data = _.pick(taskData, [
        'title',
        'description',
        'estimatedHrs',
        'estimatedMin',
        'startDate',
        'endDate',
        'stageId',
        'labelIds',
        'assignedTo',
      ])
      reset(data)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT)) {
      setComment('')
      callGetProjectTasksListSingleTaskAction()
    }
  }, [successLabels, callGetProjectTasksListSingleTaskAction])

  useEffect(() => {
    if (successLabels.includes(UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK)) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    dispatch(updateProjectTaskListSingleTask({projectId, taskId, data}))
  }

  const panes = [
    {
      menuItem: 'Details',
      pane: (
        <VmoTabPane className="mt-3">
          <VmoForm className="errorLabel">
            <VmoFormField required>
              <label>Assignee</label>
              <Controller
                name="assignedTo"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    search
                    options={taskListAssignees.map(({id, firstName, lastName}) => ({
                      key: id,
                      value: id,
                      text: `${firstName} ${lastName}`,
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.assignedTo && {content: removeDoubleQuotes(errors.assignedTo.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField required>
              <label>Title</label>
              <Controller
                name="title"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormInput
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormField>
              <label>Description</label>
              <Controller
                name="description"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormTextArea
                    style={{resize: 'none'}}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.description && {content: removeDoubleQuotes(errors.description.message)}}
                  />
                )}
              />
            </VmoFormField>
            <VmoFormGroup>
              <VmoFormField required width={8}>
                <label>Estimate Time</label>
                <VmoGrid columns="equal">
                  <VmoGridColumn>
                    <Controller
                      name="estimatedHrs"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormInput
                          fluid
                          type="number"
                          placeholder="00H"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.estimatedHrs && {content: removeDoubleQuotes(errors.estimatedHrs.message)}}
                        />
                      )}
                    />
                  </VmoGridColumn>
                  <VmoGridColumn>
                    <Controller
                      name="estimatedMin"
                      control={control}
                      render={({value, onChange}) => (
                        <VmoFormInput
                          fluid
                          type="number"
                          placeholder="00M"
                          value={value}
                          onChange={(e, {value}) => onChange(value)}
                          error={errors.estimatedMin && {content: removeDoubleQuotes(errors.estimatedMin.message)}}
                        />
                      )}
                    />
                  </VmoGridColumn>
                </VmoGrid>
              </VmoFormField>
              <VmoFormField required width={8}>
                <label>Stage</label>
                <Controller
                  name="stageId"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoFormSelect
                      options={taskListStages.map(({id, name}) => ({key: id, value: id, text: name}))}
                      value={value}
                      onChange={(e, {value}) => onChange(value)}
                      error={errors.stageId && {content: removeDoubleQuotes(errors.stageId.message)}}
                    />
                  )}
                />
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormField>
              <label>Actual Time</label>
              <p>
                {get(['actualHrs'], taskData, 0)}h {get(['actualMin'], taskData, 0)}m
              </p>
            </VmoFormField>
            <VmoFormGroup>
              <VmoFormField required>
                <label>Start Date</label>
                <Controller
                  name="startDate"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoDatePicker
                      time={false}
                      value={value ? new Date(value) : value}
                      onChange={value => {
                        onChange(value)
                      }}
                      min={new Date()}
                      defaultValue={typeof value === 'string' ? new Date(value) : value}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
                {errors.startDate && (
                  <p className="errorLabelElement">{removeDoubleQuotes(errors.startDate.message)}</p>
                )}
              </VmoFormField>
              <VmoFormField required>
                <label>Due Date</label>
                <Controller
                  name="endDate"
                  control={control}
                  render={({value, onChange}) => (
                    <VmoDatePicker
                      time={false}
                      value={value ? new Date(value) : value}
                      onChange={value => {
                        onChange(value)
                      }}
                      min={new Date()}
                      defaultValue={typeof value === 'string' ? new Date(value) : value}
                      inputProps={{
                        component: props => <input {...props} readOnly />,
                      }}
                    />
                  )}
                />
                {errors.endDate && <p className="errorLabelElement">{removeDoubleQuotes(errors.endDate.message)}</p>}
              </VmoFormField>
            </VmoFormGroup>

            <VmoFormField>
              <label>Labels</label>
              <Controller
                name="labelIds"
                control={control}
                render={({value, onChange}) => (
                  <VmoFormSelect
                    search
                    multiple
                    options={getTagList.map(({id, label, color}) => ({
                      key: id,
                      value: id,
                      text: startCase(label),
                      label: {color},
                    }))}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={errors.labelIds && {content: removeDoubleQuotes(errors.labelIds.message)}}
                  />
                )}
              />
            </VmoFormField>
          </VmoForm>
        </VmoTabPane>
      ),
    },
    {
      menuItem: 'Timeline',
      pane: (
        <VmoTabPane className="mt-3">
          <VmoInput
            fluid
            action={{
              color: 'blue',
              content: 'Add Comment',
              onClick: () => {
                const data = {
                  content: comment,
                }

                if (comment.trim() !== '') {
                  dispatch(createProjectsTasksListTaskComment({projectId, taskId, data}))
                }
              },
            }}
            actionPosition="right"
            placeholder="Write a comment"
            value={comment}
            onChange={e => setComment(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                const data = {
                  content: comment,
                }

                if (comment.trim() !== '') {
                  dispatch(createProjectsTasksListTaskComment({projectId, taskId, data}))
                }
              }
            }}
          />

          <div className="modalTimeline">
            {allActivities.map(activity => (
              <VmoHeader as="h5">
                <VmoIcon>
                  <SvgIcon path="settings/activities" />
                </VmoIcon>
                <VmoHeaderContent>
                  <div className="content">
                    {[
                      <strong>{get(['agent'], activity, '')} &nbsp;</strong>,
                      <strong>
                        {activity.activityDescription}
                        &nbsp;
                      </strong>,
                    ]}
                    <span className="time mt-1">
                      {getFormattedTime(activity.ts || activity.createdAt)},{' '}
                      {getFormattedDate(activity.ts || activity.createdAt)}
                    </span>
                  </div>
                </VmoHeaderContent>
              </VmoHeader>
            ))}
          </div>
        </VmoTabPane>
      ),
    },
  ]

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>Activities</VmoModalHeader>
      <VmoModalContent scrolling>
        <VmoTab panes={panes} menu={{secondary: true, pointing: true, color: 'blue'}} renderActiveOnly={false} />
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          Update
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default ViewTask
