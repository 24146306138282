import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import {
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoFormSelect,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {
  createProjectsTemplatesTasklist,
  getProjectsTemplatesSingleTasklist,
  updateProjectsTemplatesTasklist,
} from 'actions/projects'
import {
  CREATE_PROJECTS_TEMPLATES_TASKLIST,
  GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST,
  UPDATE_PROJECTS_TEMPLATES_TASKLIST,
} from 'actions/types'
import {get, removeDoubleQuotes} from 'utils/helper'
import {templateTaskListSchema} from 'validation/Projects/projects/templates.schema'
import useApiResponse from 'hooks/impure/useApiResponse'
import SvgIcon from 'components/common/SvgIcon'

const tasklistDefaultValue = {
  title: '',
  subscribers: [],
}

const TemplateTasklistModal = ({open, toggle, templateId, tasklistId}) => {
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {projectsTemplatesAssignees = []} = useSelector(state => state.projects)

  const {handleSubmit, control, errors, reset} = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: joiResolver(templateTaskListSchema),
    defaultValues: tasklistDefaultValue,
  })

  useApiResponse({
    action: getProjectsTemplatesSingleTasklist,
    payload: {templateId, tasklistId},
    dependency: [templateId, tasklistId],
    enabled: templateId && tasklistId,
    label: GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST,
    storePath: 'projects.projectsTemplatesTasklistData',
    onSuccess: projectsTemplatesTasklistData => {
      const data = {
        title: get(['title'], projectsTemplatesTasklistData, ''),
        subscribers: get(['subscribers'], projectsTemplatesTasklistData, []),
      }
      reset(data)
    },
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_PROJECTS_TEMPLATES_TASKLIST) ||
      successLabels.includes(UPDATE_PROJECTS_TEMPLATES_TASKLIST)
    ) {
      toggle(false)
    }
  }, [successLabels, toggle])

  const submitForm = data => {
    if (tasklistId) {
      dispatch(updateProjectsTemplatesTasklist({templateId, tasklistId, data}))
    } else {
      dispatch(createProjectsTemplatesTasklist({templateId, data}))
    }
  }

  return (
    <VmoModal
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
      size="tiny"
      open={open}
      onClose={() => toggle(false)}
    >
      <VmoModalHeader>{tasklistId ? 'Edit List' : 'Add List'}</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label>Title</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify title of the task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="title"
              control={control}
              render={({value, onChange}) => (
                <VmoFormInput
                  maxLength={50}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.title && {content: removeDoubleQuotes(errors.title.message)}}
                />
              )}
            />
          </VmoFormField>

          <VmoFormField>
            <div className="info-header">
              <label>Assignee</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Assignee agents for this particular task"
                size="mini"
                psoition="top center"
              />
            </div>
            <Controller
              name="subscribers"
              control={control}
              render={({value, onChange}) => (
                <VmoFormSelect
                  search
                  multiple
                  options={projectsTemplatesAssignees.map(({id, firstName, lastName}) => ({
                    key: id,
                    value: id,
                    text: `${firstName} ${lastName}`,
                  }))}
                  value={value}
                  onChange={(e, {value}) => onChange(value)}
                  error={errors.subscribers && {content: removeDoubleQuotes(errors.subscribers.message)}}
                />
              )}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton basic onClick={() => toggle(false)}>
          Cancel
        </VmoButton>
        <VmoButton primary onClick={handleSubmit(submitForm)}>
          {tasklistId ? 'Update' : 'Add'}
        </VmoButton>
      </VmoModalActions>
    </VmoModal>
  )
}

export default TemplateTasklistModal
