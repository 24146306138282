import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTablePlaceholder,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {useHistory} from 'react-router-dom'
import DeleteModal from 'components/common/DeleteModal'
import SettingLayout from 'layouts/settingLayout'
import CreateFirst from 'components/common/CreateFirst'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getRouting, deleteRouting} from 'actions/settings_automations'
import {CREATE_ROUTING, DELETE_ROUTING, GET_ROUTING, UPDATE_ROUTING} from 'actions/types'
import create from 'assets/create-rules.svg'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getFormattedDate, getPermissionTooltipContent} from 'utils/helper'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import RoutingForm from './RoutingForm'

const Routing = () => {
  const history = useHistory()

  const [idToEdit, setIdToEdit] = useState(0)
  const [deleteModal, setDeleteModal] = useState(false)
  const [createRouting, setCreateRouting] = useState(false)

  const {type = [], successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const routingLearnMore = settingsLearnMoreUrl?.automations?.routing?.learnMore

  const {isLoading} = useApiLoader({label: GET_ROUTING})
  const {automationPermissions} = useUserPermissions()
  const manageRoutingRulesPermissions = automationPermissions?.manage_routing_rules

  const {data: routingList = [], callAction: callGetRoutingAction} = useApiResponse({
    action: getRouting,
    enabled: true,
    storePath: 'settingAutomation.routingList',
  })

  useEffect(() => {
    if (
      successLabels.includes(CREATE_ROUTING) ||
      successLabels.includes(UPDATE_ROUTING) ||
      successLabels.includes(DELETE_ROUTING)
    ) {
      callGetRoutingAction()
    }
  }, [successLabels, callGetRoutingAction])

  if (isLoading) {
    return <PageLoader />
  }

  if (createRouting) {
    return <RoutingForm toggleModal={setCreateRouting} />
  }

  if (routingList.length === 0) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Routing"
          subHeader="Routing helps to distribute work efficiently to right users for further action"
          addFirst={() => manageRoutingRulesPermissions && setCreateRouting(true)}
          Feature1="Task or Case Routing"
          Feature2="Rule-Based Routing"
          Feature3="Priority-Based Routing"
          list1="Route cases to specific users based on criteria."
          list2="Set up rules to determine the routing flow"
          list3="Route records based on priority levels."
          buttonText="Create Routing"
          tooltip={!manageRoutingRulesPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: manageRoutingRulesPermissions})}
          imageSrc={create}
        />
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/routing" />}
      header="Routing"
      subHeader="Create routing rules and direct inquiries to appropriate agents for efficient resolution"
      learnMoreUrl={routingLearnMore}
      headerButton={{
        onClick: () => manageRoutingRulesPermissions && setCreateRouting(true),
        content: 'Create Routing',
      }}
      table
      headerDivider
      lockRole={!manageRoutingRulesPermissions}
    >
      <VmoTable basic selectable>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Inbox</VmoTableHeaderCell>
            <VmoTableHeaderCell>Create by</VmoTableHeaderCell>
            <VmoTableHeaderCell>Created Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {type.includes(GET_ROUTING) ? (
            <VmoTablePlaceholder columns={6} />
          ) : (
            routingList.map((list, index) => (
              <VmoTableRow
                className="tableLink"
                onClick={() => history.push(`/settings/routing/${list.id}`)}
                key={list.id}
              >
                <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
                <VmoTableCell>
                  <VmoHeader as="h5">{list.name}</VmoHeader>
                </VmoTableCell>
                <VmoTableCell>{list.inbox}</VmoTableCell>
                <VmoTableCell>{list.createdBy}</VmoTableCell>
                <VmoTableCell>{getFormattedDate(list.createdAt)}</VmoTableCell>
                <VmoTableCell>
                  <VmoTooltip
                    trigger={
                      <VmoButton
                        icon
                        onClick={e => {
                          e.stopPropagation()
                          if (manageRoutingRulesPermissions) {
                            setIdToEdit(list.id)
                            setDeleteModal(true)
                          }
                        }}
                      >
                        <SvgIcon path="common/delete" />
                      </VmoButton>
                    }
                    size="mini"
                    position="top center"
                    content={
                      manageRoutingRulesPermissions
                        ? 'Delete'
                        : getPermissionTooltipContent({roleAccess: manageRoutingRulesPermissions})
                    }
                  />
                </VmoTableCell>
              </VmoTableRow>
            ))
          )}
        </VmoTableBody>
      </VmoTable>

      {deleteModal && (
        <DeleteModal
          idTobeDeleted={idToEdit}
          deleteAction={deleteRouting}
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete?"
          type={DELETE_ROUTING}
        />
      )}
    </SettingLayout>
  )
}
export default Routing
