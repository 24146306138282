import {apiAction} from './api'
import {SETTINGS_ENDPOINT} from './endpoint'
import {
  ADD_ACCOUNT_CURRENCY,
  DELETE_ACCOUNT_CURRENCY,
  GET_ACCOUNT_CURRENCY,
  GET_ACCOUNT_CURRENCY_NOTE_ADDED,
  GET_ALL_CURRENCY,
  UPDATE_ACCOUNT_CURRENCY,
  UPDATE_ACCOUNT_CURRENCY_BASE,
} from './types'

export function getAllCurrency() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies`,
    method: 'GET',
    label: GET_ALL_CURRENCY,
  })
}
export function getAccountCurrency() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/account`,
    method: 'GET',
    label: GET_ACCOUNT_CURRENCY,
  })
}
export function getAccountCurrencyNoteAdded() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/notadded`,
    method: 'GET',
    label: GET_ACCOUNT_CURRENCY_NOTE_ADDED,
  })
}
export function addAccountCurrency(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/account`,
    method: 'POST',
    label: ADD_ACCOUNT_CURRENCY,
    data,
    showToast: true,
    successMessage: 'Currency added',
  })
}
export function updateAccountCurrency(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/account`,
    method: 'PUT',
    label: UPDATE_ACCOUNT_CURRENCY,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}
export function updateAccountCurrencyBase(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/base/${id}`,
    method: 'PUT',
    label: UPDATE_ACCOUNT_CURRENCY_BASE,
    showToast: true,
    successMessage: 'Currency Updated',
  })
}
export function deleteAccountCurrency(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/currencies/account/${id}`,
    method: 'DELETE',
    label: DELETE_ACCOUNT_CURRENCY,
    showToast: true,
    successMessage: 'Currency Deleted',
  })
}
