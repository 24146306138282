import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoFormField,
  VmoGrid,
  VmoGridColumn,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoRadio,
} from 'vmo-library'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import {currentSubscription, resumeBilling} from 'actions/billing'
import {useDispatch, useSelector} from 'react-redux'
import {CANCEL_BILLING, CURRENT_SUBSCRIPTION, RESUME_BILLING} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'
import {get} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import PlanDetails from './PlanDetails'
import PlanList from './PlanList'
import CancelPlanModal from './CancelPlanModal'
import ChangeCardDetails from './ChangeCardDetails'
import BillingInfo from './BillingInfo'

const getPlan = plan => {
  if (plan.includes('Monthly')) {
    return plan.replace('Monthly', 'Yearly')
  }
  if (plan.includes('Yearly')) {
    return plan.replace('Yearly', 'Monthly')
  }
  return plan
}

const PlanStep1 = props => {
  const {
    setStep,
    paymentData: {interval: subInterval = 'monthly'},
    paymentData,
    setPaymentData,
  } = props

  const dispatch = useDispatch()
  const history = useHistory()

  const [isChangeCardDetail, setIsChangeCardDetail] = useState(false)
  const [updateBillingInfo, setUpdateBillingInfo] = useState(false)
  const [cancelConfirmation, setCancelConfirmation] = useState(false)
  const [thankYou, setThankYou] = useState(false)
  const [startCancelPlan, setStartCancelPlan] = useState(false)
  const [showTrialing, setShowTrialing] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {currentSubscriptionData = {}} = useSelector(state => state.billing)
  const {manageBillingDefaultPermissions} = useUserPermissions()

  useApiResponse({
    label: RESUME_BILLING,
    onSuccess: () => {
      setStartCancelPlan(false)
      dispatch(currentSubscription())
    },
  })

  useApiResponse({
    label: CANCEL_BILLING,
    onSuccess: () => {
      dispatch(currentSubscription())
      setCancelConfirmation(false)
      setThankYou(true)
    },
  })

  useEffect(() => {
    if (successLabels.includes(CURRENT_SUBSCRIPTION)) {
      setShowTrialing(currentSubscriptionData?.subscription?.status === 'trialing')
    }
  }, [successLabels, currentSubscriptionData])

  const renderCancelPlan = () => {
    if (currentSubscriptionData?.subscription?.status === 'active' && manageBillingDefaultPermissions) {
      if (!get(['subscription', 'isCancelled'], currentSubscriptionData)) {
        return (
          <VmoGridColumn width={16} style={{textAlign: 'left', marginTop: '40px'}}>
            <h4 className="mt-4 mb-2">Want to cancel your current plan?</h4>
            <VmoButton color="red" inverted onClick={() => setCancelConfirmation(true)}>
              Cancel Plan
            </VmoButton>
          </VmoGridColumn>
        )
      }
    }
  }

  return (
    <VmoGrid>
      {isChangeCardDetail && (
        <ChangeCardDetails isChangeCardDetail={isChangeCardDetail} setIsChangeCardDetail={setIsChangeCardDetail} />
      )}
      {updateBillingInfo && (
        <BillingInfo updateBillingInfo={updateBillingInfo} setUpdateBillingInfo={setUpdateBillingInfo} />
      )}

      <VmoGridColumn width={16}>
        <PlanDetails
          currentSubscriptionData={currentSubscriptionData}
          setStartCancelPlan={setStartCancelPlan}
          setStep={setStep}
          isChangeCardDetail={isChangeCardDetail}
          setIsChangeCardDetail={setIsChangeCardDetail}
          showTrialing={showTrialing}
          setShowTrialing={setShowTrialing}
          updateBillingInfo={updateBillingInfo}
          setUpdateBillingInfo={setUpdateBillingInfo}
          rolePermissions={manageBillingDefaultPermissions}
        />

        <div className="myPlanPage">
          <div className="priceHeader">
            <h3 className="mb-2">Happy Customers, Happy Teams</h3>
            <VmoFormField>
              <VmoGrid>
                <VmoGridColumn width={16}>
                  <VmoRadio
                    id="myPlan1"
                    label="Annually"
                    inline
                    value="yearly"
                    checked={subInterval === 'yearly'}
                    onChange={(e, {value}) =>
                      setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                    }
                  />
                  <span>(Limited Time)</span>
                  <VmoRadio
                    id="myPlan2"
                    label="Monthly"
                    value="monthly"
                    checked={subInterval === 'monthly'}
                    onChange={(e, {value}) =>
                      setPaymentData({...paymentData, interval: value, plan: getPlan(paymentData.plan), addons: []})
                    }
                  />
                  <a href="#" className="comparePlan">
                    Compare Plans
                  </a>
                </VmoGridColumn>
              </VmoGrid>
            </VmoFormField>
          </div>

          <VmoGrid relaxed columns="equal">
            <PlanList
              subInterval={subInterval}
              setPaymentData={setPaymentData}
              setStep={setStep}
              rolePermissions={manageBillingDefaultPermissions}
            />
          </VmoGrid>
          <VmoGrid>{renderCancelPlan()}</VmoGrid>
        </div>
      </VmoGridColumn>

      {cancelConfirmation && <CancelPlanModal isOpen={cancelConfirmation} setIsOpen={setCancelConfirmation} />}

      {thankYou && (
        <VmoModal
          size="small"
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          open={thankYou}
          onClose={() => setThankYou(false)}
        >
          <VmoModalHeader>Thank You !!!</VmoModalHeader>
          <VmoModalContent>
            <h4>Thank You for using Kooper</h4>
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton
              color="primary"
              onClick={() => {
                history.push(`/inbox`)
                setThankYou(!thankYou)
              }}
            >
              OK
            </VmoButton>
          </VmoModalActions>
        </VmoModal>
      )}

      {startCancelPlan && (
        <VmoModal
          size="small"
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
          open={startCancelPlan}
          onClose={() => setStartCancelPlan(false)}
        >
          <VmoModalHeader>Reactivate Subscription</VmoModalHeader>
          <VmoModalContent>
            <h4>Are you sure you want restart the subscription ?</h4>
          </VmoModalContent>
          <VmoModalActions>
            <VmoButton primary onClick={() => dispatch(resumeBilling())}>
              OK
            </VmoButton>
          </VmoModalActions>
        </VmoModal>
      )}
    </VmoGrid>
  )
}

export default PlanStep1
