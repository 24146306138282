/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {FaCrosshairs} from 'react-icons/fa'
import _ from 'lodash'
import {
  VmoButton,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoIcon,
  VmoLabel,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
  VmoTablePlaceholder,
} from 'vmo-library'

import {DELETE_SEQUENCE, UPDATE_SEQUENCE_STATUS, GET_ALL_SEQUENCES} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {deleteSequence, getAllSequences, updateSequenceStatus} from 'actions/sequence'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

const sequenceType = [
  {key: 'all', value: 'all', text: 'All'},
  {key: 'live', value: 'live', text: 'Live'},
  {key: 'scheduled', value: 'scheduled', text: 'Scheduled'},
  {key: 'completed', value: 'completed', text: 'Completed'},
  {key: 'paused', value: 'paused', text: 'Paused'},
  {key: 'draft', value: 'draft', text: 'Draft'},
]

const Sequences = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [filter, setFilter] = useState({})
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const [idToDelete, setIdToDelete] = useState('')
  const {successLabels = [], isLoadingData, type} = useSelector(state => state.apiReducer)

  const {isLoading} = useApiLoader({label: GET_ALL_SEQUENCES})
  const {salesAndCrmAccess, sequencesPermissions} = useUserPermissions()
  const salesSequencesAccess = salesAndCrmAccess?.salesSequences
  const manageSequencesPermissions = sequencesPermissions?.manage_sequences

  const {data: sequencesList = []} = useApiResponse({
    action: getAllSequences,
    dependency: [salesSequencesAccess, manageSequencesPermissions],
    enabled: salesSequencesAccess && manageSequencesPermissions,
    label: GET_ALL_SEQUENCES,
    storePath: 'sequences.sequencesList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_SEQUENCE)) {
      dispatch(getAllSequences())
    }
    if (successLabels.includes(UPDATE_SEQUENCE_STATUS)) {
      dispatch(getAllSequences())
    }
  }, [successLabels, dispatch])

  const renderSequenceList = () => {
    if (sequencesList.length > 0) {
      return sequencesList.map((sequence, index) => (
        <VmoTableRow
          key={index}
          className="tableLink"
          onClick={() => history.push(`/sequence/${sequence.id}/insights`)}
        >
          <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoHeader as="h5">{startCase(sequence.name)}</VmoHeader>
          </VmoTableCell>
          <VmoTableCell>{sequence.assignee}</VmoTableCell>
          <VmoTableCell>{getFormattedDate(sequence.startAt)}</VmoTableCell>
          <VmoTableCell>
            <VmoLabel className="kooper-label" color={sequence.isActive ? 'green' : 'red'}>
              {sequence.isActive ? 'True' : 'False'}
            </VmoLabel>
          </VmoTableCell>
          <VmoTableCell>
            <VmoLabel className="kooper-label">{startCase(sequence.status)}</VmoLabel>
          </VmoTableCell>
          <VmoTableCell>{startCase(sequence.inbox)}</VmoTableCell>
          <VmoTableCell>
            <VmoDropdown
              icon={<SvgIcon path="common/more-verticle" id="TooltipProductInbox" />}
              button
              className="icon"
              direction="left"
            >
              <VmoDropdownMenu>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <VmoDropdownItem
                    onClick={() =>
                      manageSequencesPermissions &&
                      dispatch(
                        updateSequenceStatus(sequence.id, {
                          isActive: !sequence.isActive,
                        })
                      )
                    }
                  >
                    {sequence.isActive ? <VmoIcon name="eye slash" /> : <SvgIcon path="common/publish" />}
                    {sequence.isActive ? 'Unpublish' : 'Publish'}
                  </VmoDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <VmoDropdownItem
                    onClick={() => manageSequencesPermissions && history.push(`/sequence/${sequence.id}`)}
                  >
                    <SvgIcon path="common/edit2" /> Edit
                  </VmoDropdownItem>
                </LockPermissionTooltip>
                <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
                  <VmoDropdownItem
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      if (manageSequencesPermissions) {
                        setIsDeleteOpen(true)
                        setIdToDelete(sequence.id)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </VmoDropdownItem>
                </LockPermissionTooltip>
              </VmoDropdownMenu>
            </VmoDropdown>
          </VmoTableCell>
        </VmoTableRow>
      ))
    }
  }

  useEffect(() => {
    if (salesSequencesAccess && manageSequencesPermissions) {
      if (filter) {
        dispatch(getAllSequences(filter))
      }
    }
  }, [salesSequencesAccess, manageSequencesPermissions, filter, dispatch])

  if (isLoading && salesSequencesAccess && manageSequencesPermissions) {
    return <PageLoader />
  }

  if (!salesSequencesAccess || !manageSequencesPermissions || (sequencesList.length < 1 && _.isEmpty(filter))) {
    return (
      <CreateFirst
        src={<FaCrosshairs />}
        Feature1="Personalized Sequence"
        Feature2="Execution time"
        Feature3="Sequence Delay"
        list1="Create contact specific information sequence dynamically"
        list2="Set the execution time to send it at optimal time"
        list3="Set delay between two sequence for more efficiency"
        buttonText="Create Sequence"
        addFirst={() => salesSequencesAccess && manageSequencesPermissions && history.push(`/sequences/create`)}
        tooltip={!salesSequencesAccess || !manageSequencesPermissions}
        tooltipContent={getPermissionTooltipContent({
          featureAccess: salesSequencesAccess,
          roleAccess: manageSequencesPermissions,
        })}
        img={create}
        header="Sequence"
        subHeader="Sequences are a series of scheduled touchpoints that help you connect with customers at a scale."
      />
    )
  }

  return (
    <div className="pageBodyWrap">
      <div className="pageHeader px-0">
        <div className="pageTitle">
          <div className="pageTitleIcon">
            <SvgIcon path="navigation/sequences" />
            <h5>Sequences ({sequencesList?.length})</h5>
          </div>
        </div>

        <div className="pageAction">
          <VmoTooltip
            content="Sequence Status"
            size="mini"
            position="top center"
            trigger={
              <VmoDropdown
                icon="chevron down"
                button
                className="dropdown-ellipsis"
                defaultValue="all"
                options={sequenceType}
                direction="left"
                onChange={(e, {value}) => {
                  setFilter({
                    ...filter,
                    status: value,
                  })
                }}
              />
            }
          />
          <LockPermissionTooltip isRoleAccessDenied={!manageSequencesPermissions}>
            <VmoButton
              content="Create Sequence"
              primary
              className={!manageSequencesPermissions ? 'disabled-button' : ''}
              onClick={() => manageSequencesPermissions && history.push(`/sequences/create`)}
            />
          </LockPermissionTooltip>
        </div>
      </div>
      <VmoTable selectable basic className="m-0">
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Owner</VmoTableHeaderCell>
            <VmoTableHeaderCell>Start Date</VmoTableHeaderCell>
            <VmoTableHeaderCell>Active</VmoTableHeaderCell>
            <VmoTableHeaderCell>Status</VmoTableHeaderCell>
            <VmoTableHeaderCell>Mailbox</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {isLoadingData && type.includes(GET_ALL_SEQUENCES) ? (
            <VmoTablePlaceholder columns={8} />
          ) : sequencesList.length > 0 ? (
            renderSequenceList()
          ) : (
            <tr>
              <td colSpan="8" className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}
        </VmoTableBody>
      </VmoTable>
      {isDeleteOpen && (
        <DeleteModal
          isModalOpen={isDeleteOpen}
          setIsModalOpen={setIsDeleteOpen}
          modalText="Are you sure you want to delete ?"
          deleteAction={deleteSequence}
          idTobeDeleted={idToDelete}
          type={DELETE_SEQUENCE}
        />
      )}
    </div>
  )
}
export default Sequences
