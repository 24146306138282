import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ResponsiveBar} from '@nivo/bar'
import {VmoCard, VmoCardContent, VmoCardHeader, VmoGrid, VmoGridColumn, VmoTooltip} from 'vmo-library'

import {
  averageResolutionTimeSLA,
  firstResponseTimeSLA,
  resolveTargetOverTimeSLA,
  responseTargetOverTimeSLA,
} from 'actions/analytics_sla'
import {RESOLVE_TARGET_OVER_TIME_SLA, RESPONSE_TARGET_OVER_TIME_SLA} from 'actions/types'
import {getGridYValuesMultipleBar, maxOfGraphMultipleBar} from 'utils/graph'
import {adjustTimeUnit, get} from 'utils/helper'
import CustomLoader from 'components/common/CustomLoader'
import {AssigneeDropdown, MailboxDropdown} from 'components/pages/Analytics/AnalyticsUtils'
import DateRangeGroup from 'components/pages/Analytics/DateRangeGroup'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

const Sla = () => {
  const dispatch = useDispatch()

  const {type = []} = useSelector(state => state.apiReducer)
  const {analyticsSelectedInbox, analyticsSelectedAssignee} = useSelector(state => state.analyticsInbox)
  const {
    firstResponseTimeSLAData = {},
    averageResolutionTimeSLAData = {},
    resolveTargetOverTimeSLAData = [],
    responseTargetOverTimeSLAData = [],
  } = useSelector(state => state.analyticsSla)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const slaAccess = analyticsAccess?.sla
  const performanceReportsPermissions = reportsPermissions?.performance_reports

  const actionsToDispatch = useCallback(
    data => {
      dispatch(firstResponseTimeSLA(data))
      dispatch(averageResolutionTimeSLA(data))
      dispatch(resolveTargetOverTimeSLA(data))
      dispatch(responseTargetOverTimeSLA(data))
    },
    [dispatch]
  )

  return (
    <div className="kooper-side-wrap" style={{minHeight: '100%', background: '#F9FBFD'}}>
      <div className="page-header">
        <div className="page-title">
          <div className="page-titleIcon">
            <SvgIcon path="analytics/reports/sla" />
            <h5>SLA</h5>
          </div>
        </div>
        {slaAccess && performanceReportsPermissions && (
          <div className="page-action">
            <MailboxDropdown />
            <AssigneeDropdown />
            <DateRangeGroup
              actionsToDispatch={actionsToDispatch}
              userId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'assignee'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              teamId={
                analyticsSelectedAssignee && analyticsSelectedAssignee.type === 'team'
                  ? Number(analyticsSelectedAssignee.id)
                  : null
              }
              inboxId={
                analyticsSelectedInbox !== 'all' && analyticsSelectedInbox ? Number(analyticsSelectedInbox) : null
              }
            />
          </div>
        )}
      </div>

      <VmoGrid>
        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoCardHeader>First Response Time</VmoCardHeader>
            </VmoCardContent>
            <VmoCardContent style={{border: 0}}>
              <VmoGrid columns={3}>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Target Achieved</VmoCardHeader>
                    <h3>
                      {get(['targetAchieved', 'achieved'], firstResponseTimeSLAData, 0)}
                      <span
                        className={
                          get(['targetAchieved', 'growth'], firstResponseTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['targetAchieved', 'growth'], firstResponseTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['targetAchieved', 'percentage'], firstResponseTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Target Breaches</VmoCardHeader>
                    <h3>
                      {get(['targetBreached', 'breached'], firstResponseTimeSLAData, 0)}
                      <span
                        className={
                          get(['targetBreached', 'growth'], firstResponseTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['targetBreached', 'growth'], firstResponseTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['targetBreached', 'percentage'], firstResponseTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Time Exceeded</VmoCardHeader>
                    <h3>
                      {adjustTimeUnit(get(['targetExceeded', 'timeExceeded'], firstResponseTimeSLAData, 0))}
                      <span
                        className={
                          get(['targetExceeded', 'growth'], firstResponseTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['targetExceeded', 'growth'], firstResponseTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['targetExceeded', 'percentage'], firstResponseTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>

        <VmoGridColumn width={8}>
          <VmoCard fluid>
            <VmoCardContent>
              <VmoCardHeader>Average Resolution Time</VmoCardHeader>
            </VmoCardContent>
            <VmoCardContent style={{border: 0}}>
              <VmoGrid columns={3}>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Target Achieved</VmoCardHeader>
                    <h3>
                      {get(['targetAchieved', 'achieved'], averageResolutionTimeSLAData, 0)}
                      <span
                        className={
                          get(['targetAchieved', 'growth'], averageResolutionTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['targetAchieved', 'growth'], averageResolutionTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['targetAchieved', 'percentage'], averageResolutionTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Target Breaches</VmoCardHeader>
                    <h3>
                      {get(['targetBreached', 'breached'], averageResolutionTimeSLAData, 0)}
                      <span
                        className={
                          get(['targetBreached', 'growth'], averageResolutionTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['targetBreached', 'growth'], averageResolutionTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['targetBreached', 'percentage'], averageResolutionTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
                <VmoGridColumn>
                  <div className="valueBox">
                    <VmoCardHeader>Time Exceeded</VmoCardHeader>
                    <h3>
                      {adjustTimeUnit(get(['timeExceeded', 'timeExceeded'], averageResolutionTimeSLAData, 0))}
                      <span
                        className={
                          get(['timeExceeded', 'growth'], averageResolutionTimeSLAData, 'positive') === 'positive'
                            ? ''
                            : 'text-danger'
                        }
                      >
                        <VmoTooltip
                          position="top center"
                          size="mini"
                          content="Compared to Previous Duration"
                          trigger={
                            <div>
                              {get(['timeExceeded', 'growth'], averageResolutionTimeSLAData, 'positive') ===
                              'positive' ? (
                                <SvgIcon path="common/arrow-up-right" />
                              ) : (
                                <SvgIcon path="common/arrow-down-left" className="down-left" />
                              )}
                              {get(['timeExceeded', 'percentage'], averageResolutionTimeSLAData, 0)}%
                            </div>
                          }
                        />
                      </span>
                    </h3>
                  </div>
                </VmoGridColumn>
              </VmoGrid>
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>SLA Target - Closed</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of SLA target for closing conversation over a time frame"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 300}}>
              {type.includes(RESOLVE_TARGET_OVER_TIME_SLA) && <CustomLoader />}
              {!type.includes(RESOLVE_TARGET_OVER_TIME_SLA) &&
                resolveTargetOverTimeSLAData.every(({Breached, Achieved}) => Breached === 0 && Achieved === 0) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(RESOLVE_TARGET_OVER_TIME_SLA) && (
                <ResponsiveBar
                  data={resolveTargetOverTimeSLAData}
                  keys={['Breached', 'Achieved']}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 70, left: 60}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(resolveTargetOverTimeSLAData, ['Breached', 'Achieved'])}
                  gridYValues={getGridYValuesMultipleBar(resolveTargetOverTimeSLAData, ['Breached', 'Achieved'])}
                  groupMode="grouped"
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`${d.id}Color`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(resolveTargetOverTimeSLAData, ['Breached', 'Achieved']),
                    legend: 'Conversations',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipHeight={1}
                  label={d => <tspan y={-8}>{d.value}</tspan>}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 30,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>

      <VmoGrid>
        <VmoGridColumn width={16}>
          <VmoCard fluid className="chartBox">
            <VmoCardContent className="headerBox">
              <VmoCardHeader>SLA Target - Responses</VmoCardHeader>
              <VmoTooltip
                content="Graphical representation of SLA target for responding to conversations over a timeframe"
                size="mini"
                position="top center"
                trigger={<SvgIcon path="common/question" />}
              />
            </VmoCardContent>
            <VmoCardContent className="pt-0" style={{height: 350}}>
              {type.includes(RESPONSE_TARGET_OVER_TIME_SLA) && <CustomLoader />}
              {!type.includes(RESPONSE_TARGET_OVER_TIME_SLA) &&
                responseTargetOverTimeSLAData.every(({Breached, Achieved}) => Breached === 0 && Achieved === 0) && (
                  <div className="empty-chart">No Data Available</div>
                )}
              {!type.includes(RESPONSE_TARGET_OVER_TIME_SLA) && (
                <ResponsiveBar
                  data={responseTargetOverTimeSLAData}
                  keys={['Breached', 'Achieved']}
                  indexBy="time"
                  margin={{top: 27, right: 27, bottom: 70, left: 60}}
                  padding={0.3}
                  minValue={0}
                  maxValue={maxOfGraphMultipleBar(responseTargetOverTimeSLAData, ['Breached', 'Achieved'])}
                  gridYValues={getGridYValuesMultipleBar(responseTargetOverTimeSLAData, ['Breached', 'Achieved'])}
                  groupMode="grouped"
                  valueScale={{type: 'linear'}}
                  indexScale={{type: 'band', round: true}}
                  colors={d => d.data[`${d.id}Color`]}
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: 'Time',
                    legendPosition: 'middle',
                    legendOffset: 60,
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: getGridYValuesMultipleBar(responseTargetOverTimeSLAData, ['Breached', 'Achieved']),
                    legend: 'Conversations',
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipHeight={1}
                  label={d => <tspan y={-8}>{d.value}</tspan>}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top',
                      direction: 'row',
                      justify: false,
                      translateX: 0,
                      translateY: -30,
                      itemsSpacing: 0,
                      itemWidth: 100,
                      itemHeight: 30,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 10,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </VmoCardContent>
          </VmoCard>
        </VmoGridColumn>
      </VmoGrid>
    </div>
  )
}

export default Sla
