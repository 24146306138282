import React, {useEffect, useState} from 'react'
import simpleNumberLocalizer from 'react-widgets-simple-number'
import _ from 'lodash'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCardHeader,
  VmoContainer,
  VmoDivider,
  VmoDropdown,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoHeaderSubheader,
  VmoRadio,
  VmoTabPane,
  VmoTooltip,
} from 'vmo-library'
import SocialCreateMultilple from 'components/common/SocialCreateMultilple'
import {timeDuration} from 'constants/sales/meeting'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import {getUsers} from 'actions/settings'
import {GET_USERS} from 'actions/types'

const Details = props => {
  simpleNumberLocalizer()
  const {
    errors,
    control,
    Controller,
    watch,
    getValues,
    removeDuration,
    appendDuration,
    durationFields,
    profilePicture,
    setProfilePicture,
    setUploadProfile,
    userAccountImage,
    setUserAccountImage,
    uploadProfile,
    socialFields,
    appendSocial,
    removeSocial,
    socialFieldsName,
    style,
  } = props
  const [durationList, setDurationList] = useState(timeDuration)
  const [ownerEmail, setOwnerEmail] = useState(false)
  const [ownerPhoneNo, setOwnerPhoneNo] = useState(false)
  const [ownerSocial, setOwnerSocial] = useState(false)
  const [ownerMsg, setOwnerMsg] = useState(false)
  const [userOption, setUserOption] = useState([])

  const ownerDetailWatch = watch('ownerDetail')
  const ownerEmailWatch = watch('ownerEmail')
  const ownerPhoneNoWatch = watch('ownerPhoneNo')
  const ownerSocialMediaWatch = watch('ownerSocialMedia')
  const displayMessageWatch = watch('displayMessage')
  const durationFieldsWatch = watch('meetingDuration')

  useEffect(() => {
    const usedTimeSlot = durationFieldsWatch?.map(({time}) => time)

    const list = durationList?.map(list => {
      if (usedTimeSlot?.includes(list.value)) {
        return {...list, disabled: true}
      }
      return {...list, disabled: false}
    })
    setDurationList(list)
  }, [JSON.stringify(durationFieldsWatch)])

  useEffect(() => {
    if (ownerEmailWatch) {
      setOwnerEmail(true)
    }
    if (ownerPhoneNoWatch) {
      setOwnerPhoneNo(true)
    }
    if (_.size(ownerSocialMediaWatch) !== 0) {
      setOwnerSocial(true)
    }
    if (displayMessageWatch) {
      setOwnerMsg(true)
    }
  }, [ownerEmailWatch, ownerPhoneNoWatch, ownerSocialMediaWatch, displayMessageWatch])

  useEffect(() => {
    setOwnerEmail(false)
    setOwnerPhoneNo(false)
    setOwnerSocial(false)
    setOwnerMsg(false)
  }, [])

  useApiResponse({
    action: getUsers,
    enabled: true,
    label: GET_USERS,
    storePath: 'settings.getUsersList',
    onSuccess: getUsersList => {
      const list = getUsersList?.users?.map(user => ({
        key: user.id,
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
      }))
      setUserOption(list)
    },
  })

  return (
    <VmoTabPane style={style}>
      <VmoContainer>
        <VmoCard fluid>
          <VmoCardContent>
            <label className="mb-0">Image</label>
            <p className="mt-0 mb-4 kooper-lead">
              Set an image or logo for your meeting. It will show to the customer while he is booking
              <a href=""> Learn More</a>
            </p>
            <VmoForm className="mt-4 errorLabel">
              <div className="profilePicSec">
                <div className="agentAvtar">
                  {userAccountImage ? <img src={profilePicture} className="img-fluid" alt="img" /> : 'Logo'}
                </div>
                <VmoHeader as="h3" className="mt-0">
                  <VmoHeaderSubheader className="mt-2">
                    <VmoButton basic className="fileBtn mr-3" size="small">
                      Change Photo
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        key={uploadProfile}
                        onInput={e => {
                          if (e.target.files) {
                            setUserAccountImage(true)
                            setProfilePicture(URL.createObjectURL(e.target.files[0]))
                            setUploadProfile(e.target.files[0])
                          }
                        }}
                      />
                    </VmoButton>
                  </VmoHeaderSubheader>
                  <VmoHeaderSubheader className="kooper-lead mt-2">
                    PNG or JPG no bigger than 1000px wide and tall
                  </VmoHeaderSubheader>
                </VmoHeader>
              </div>
              <VmoDivider hidden />
              <VmoFormGroup>
                <VmoFormField width={10} required>
                  <label>Heading</label>
                  <Controller
                    name="meetingHeading"
                    render={({onChange, value}) => (
                      <VmoFormInput
                        maxLength={60}
                        type="text"
                        fluid
                        value={value}
                        placeholder="Meeting Heading"
                        onChange={e => {
                          onChange(e.target.value)
                        }}
                        error={
                          errors.meetingHeading && {
                            content: errors.meetingHeading.message,
                          }
                        }
                      />
                    )}
                    control={control}
                  />
                </VmoFormField>
              </VmoFormGroup>
              <VmoFormField width={16}>
                <div className="info-header">
                  <label>Duration</label>
                  <VmoTooltip
                    content="Lorem ipsum"
                    size="mini"
                    position="top center"
                    trigger={<SvgIcon path="common/question" />}
                  />
                </div>
                {durationFields?.map((item, index) => {
                  const timeValue = getValues(`meetingDuration[${index}].time`)
                  return (
                    <div className={index !== 0 && 'mt-3'} style={{display: 'flex'}} key={item.id}>
                      <Controller
                        name={`meetingDuration[${index}].time`}
                        defaultValue={item.time}
                        render={({onChange, value}) => (
                          <VmoDropdown
                            options={durationList}
                            selection
                            className="mr-3"
                            name={`meetingDuration[${index}].time`}
                            placeholder="Time Duration"
                            defaultValue={value}
                            onChange={(e, {value}) => {
                              onChange(value)
                            }}
                          />
                        )}
                        control={control}
                      />
                      {durationFields.length > 1 && (
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Delete"
                          trigger={
                            <VmoButton
                              className="mr-3 mt-2"
                              icon
                              onClick={() => {
                                setDurationList(durationList =>
                                  durationList?.map(dropdownItem =>
                                    dropdownItem.value === timeValue ? {...dropdownItem, disabled: false} : dropdownItem
                                  )
                                )
                                removeDuration(index)
                              }}
                            >
                              <SvgIcon path="common/delete" />
                            </VmoButton>
                          }
                        />
                      )}
                      {_.size(durationFields) < 3 && _.size(durationFields) - 1 === index && (
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Add"
                          trigger={
                            <VmoButton
                              icon
                              className="mt-2"
                              onClick={() => {
                                appendDuration({
                                  time: durationList.find(list => list.disabled === false).value,
                                })
                              }}
                            >
                              <SvgIcon path="common/plus" />
                            </VmoButton>
                          }
                        />
                      )}
                    </div>
                  )
                })}
              </VmoFormField>
            </VmoForm>
          </VmoCardContent>
        </VmoCard>

        <VmoCard fluid>
          <VmoCardContent>
            <h3 className="mb-0">User Detail</h3>
            <div className="d-flex mt-3">
              <div>
                <h5 className="mb-0">User Detail</h5>
                <p className="kooper-lead">Manage user details settings for your meeting</p>
              </div>
              <Controller
                name="ownerDetail"
                render={({onChange, value}) => (
                  <VmoRadio toggle onChange={(e, {checked}) => onChange(checked)} checked={value} />
                )}
                control={control}
              />
            </div>
          </VmoCardContent>
          {ownerDetailWatch && (
            <VmoCardContent>
              <VmoCardHeader>Informations</VmoCardHeader>
              <VmoForm className="mt-3 errorLabel">
                <VmoFormGroup>
                  <VmoFormField required width={5}>
                    <div className="info-header">
                      <label>Owner Name</label>
                      <VmoTooltip
                        content="Lorem ipsum"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </div>
                    <Controller
                      name="assigneeId"
                      render={({onChange, value, onBlur}) => (
                        <VmoFormSelect
                          fluid
                          options={userOption}
                          selection
                          placeholder="Owners"
                          onBlur={onBlur}
                          defaultValue={value}
                          onChange={(e, {value}) => {
                            onChange(value)
                          }}
                          error={
                            errors?.assigneeId && {
                              content: errors?.assigneeId?.message,
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>
                  <VmoFormField width={5}>
                    <div className="info-header">
                      <label>Designation</label>
                      <VmoTooltip
                        content="Lorem ipsum"
                        size="mini"
                        position="top center"
                        trigger={<SvgIcon path="common/question" />}
                      />
                    </div>
                    <Controller
                      name="ownerDesignation"
                      render={({onChange, value}) => (
                        <VmoFormInput
                          type="text"
                          fluid
                          value={value}
                          placeholder="Designation"
                          onChange={e => {
                            onChange(e.target.value)
                          }}
                          error={
                            errors.ownerDesignation && {
                              content: errors.ownerDesignation.message,
                            }
                          }
                        />
                      )}
                      control={control}
                    />
                  </VmoFormField>
                </VmoFormGroup>

                <VmoFormField>
                  <div className="d-flex">
                    <div>
                      <h5 className="mb-2">Email Address</h5>
                      <p className="kooper-lead">Show your email address in the meeting details</p>
                    </div>
                    <VmoRadio
                      checked={ownerEmail}
                      onClick={() => {
                        setOwnerEmail(!ownerEmail)
                      }}
                      toggle
                    />
                  </div>
                  {ownerEmail && (
                    <Controller
                      name="ownerEmail"
                      render={({onChange, value}) => (
                        <VmoFormGroup>
                          <VmoFormInput
                            type="text"
                            width={10}
                            className="mt-3"
                            fluid
                            value={value}
                            placeholder="Owner Email"
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            error={
                              errors.ownerEmail && {
                                content: errors.ownerEmail.message,
                              }
                            }
                          />
                        </VmoFormGroup>
                      )}
                      control={control}
                    />
                  )}
                </VmoFormField>

                <VmoFormField>
                  <div className="d-flex">
                    <div>
                      <h5 className="mb-2">Phone No</h5>
                      <p className="kooper-lead">Show your phone number in meeting details</p>
                    </div>
                    <VmoRadio
                      checked={ownerPhoneNo}
                      onClick={() => {
                        setOwnerPhoneNo(!ownerPhoneNo)
                      }}
                      toggle
                    />
                  </div>
                  {ownerPhoneNo && (
                    <Controller
                      name="ownerPhoneNo"
                      render={({onChange, value}) => (
                        <VmoFormGroup>
                          <VmoFormInput
                            type="text"
                            fluid
                            width={10}
                            className="mt-3"
                            value={value}
                            placeholder="Owner PhoneNo"
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            error={
                              errors.ownerPhoneNo && {
                                content: errors.ownerPhoneNo.message,
                              }
                            }
                          />
                        </VmoFormGroup>
                      )}
                      control={control}
                    />
                  )}
                </VmoFormField>

                <VmoFormField>
                  <div className="d-flex">
                    <div>
                      <h5 className="mb-2">Social Media Link</h5>
                      <p className="kooper-lead">Show your social media links in meeting details</p>
                    </div>
                    <VmoRadio
                      checked={ownerSocial}
                      onClick={() => {
                        setOwnerSocial(!ownerSocial)
                      }}
                      toggle
                    />
                  </div>
                  {ownerSocial && (
                    <>
                      <SocialCreateMultilple
                        socialFields={socialFields}
                        errors={errors}
                        appendSocial={appendSocial}
                        control={control}
                        removeSocial={removeSocial}
                        socialFieldsName={socialFieldsName}
                      />
                      {_.size(socialFields) === 0 && (
                        <VmoTooltip
                          size="mini"
                          position="top center"
                          content="Add"
                          trigger={
                            <VmoButton
                              icon
                              className="bg-btn-color ml-2"
                              onClick={() => {
                                appendSocial({category: '', url: ''})
                              }}
                            >
                              <SvgIcon path="common/plus" />
                            </VmoButton>
                          }
                        />
                      )}
                    </>
                  )}
                </VmoFormField>

                <VmoFormField>
                  <div className="d-flex">
                    <div>
                      <h5 className="mb-2">Display Message</h5>
                      <p className="kooper-lead">Display a custom message to user booking the meeting</p>
                    </div>
                    <VmoRadio
                      checked={ownerMsg}
                      onClick={() => {
                        setOwnerMsg(!ownerMsg)
                      }}
                      toggle
                    />
                  </div>
                  {ownerMsg && (
                    <Controller
                      name="displayMessage"
                      render={({onChange, value}) => (
                        <VmoFormGroup>
                          <VmoFormTextArea
                            type="text"
                            maxLength={160}
                            fluid
                            width={10}
                            className="mt-3"
                            value={value}
                            placeholder="Display Message"
                            onChange={e => {
                              onChange(e.target.value)
                            }}
                            error={
                              errors.displayMessage && {
                                content: errors.displayMessage.message,
                              }
                            }
                          />
                        </VmoFormGroup>
                      )}
                      control={control}
                    />
                  )}
                </VmoFormField>
              </VmoForm>
            </VmoCardContent>
          )}
        </VmoCard>
      </VmoContainer>
    </VmoTabPane>
  )
}

export default Details
