import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {FiChevronRight} from 'react-icons/fi'
import {
  VmoAccordion,
  VmoAccordionContent,
  VmoAccordionTitle,
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDropdown,
  VmoDropdownItem,
  VmoDropdownMenu,
  VmoHeader,
  VmoHeaderContent,
  VmoHeaderSubheader,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
  VmoTooltip,
} from 'vmo-library'
import {inboxCounts, splitConversation} from 'actions/inbox'
import {getMailBox} from 'actions/index'
import {get, getFormattedDate, getFormattedTime, wrapText} from 'utils/helper'
import WrappedIframe from 'components/common/WrappedIframe'
import {InboxInLineComposeMail} from 'components/pages/EmailInbox/InboxInLineComposeMail'
import useEmailBody from 'hooks/pure/useEmailBody'
import {KOOPER_ATTACHMENTS} from 'actions/endpoint'
import {ADD_MESSAGE_TO_CONVERSATION} from 'actions/types'
import PermissionIcon from 'components/common/PermissionIcon'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'

export const ConversationMsgBox = props => {
  const {
    to = [],
    date,
    FromMailId,
    messageId,
    textHtml,
    replyEditorRef,
    isInbound,
    attachments,
    index,
    conversationMessagesReverse,
    isDraft,
    conversationId,
    singleMsgData,
    conversationType,
    conversationSubject,
  } = props

  const dispatch = useDispatch()

  const {setEditorOpenFor, setEditorData, editorData} = useEmailBody()

  const [isSplitModalOpen, setSplitModalOpen] = useState(false)
  const [toggleMessage, setToggleMessage] = useState(true)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {conversation = {}} = useSelector(state => state.emailInbox)
  const {inbox: mailBoxes = []} = useSelector(state => state.mailInbox)

  const {clientManagementAccess, conversationsPermissions} = useUserPermissions()
  const mergeAndSplitAccess = clientManagementAccess?.mergeAndSplit
  const manageConversationPermissions = conversationsPermissions?.manage_conversations
  const splitConversationPermissions = conversationsPermissions?.split_conversation

  const renderFrom = fromItem => {
    const from =
      `${get(['firstName'], fromItem) || get(['name'], fromItem, '')} <${conversationType}` === 'sms'
        ? get(['phone'], fromItem, '')
        : get(['username'], fromItem) || `${get(['email'], fromItem, '')}>`
    return from
  }

  const renderFromOnlyName = fromItem => {
    const from =
      get(['firstName'], fromItem) ||
      get(['name'], fromItem) ||
      get(['username'], fromItem) ||
      get(['email'], fromItem, '(no-name)')

    return from
  }

  const renderTo = toItem => {
    const to = get(['email'], toItem) || get(['username'], toItem, '')
    return to
  }

  const setHtmlToForward = () => {
    let joinedHtmlStr = ''
    if (conversationType === 'email') {
      const slicedArr = conversationMessagesReverse.slice(0, index + 1)
      const htmlSlicedArr = slicedArr.map(msg => msg.messageHtml)

      const fromArr = slicedArr.map(msg => {
        if (msg.from) {
          return {
            name: renderFrom(msg.from),
            email: renderTo(msg.from),
            ts: get(['updatedAt'], msg),
          }
        }
        return {}
      })

      // for adding seperator(time name and email) before every mail text
      if (fromArr.length > 0) {
        htmlSlicedArr.forEach((item, i) => {
          const seperator = `<br/> On, ${getFormattedDate(fromArr[i].ts)}, ${getFormattedTime(fromArr[i].ts)} 
          ${fromArr[i].name}
          (${fromArr[i].email}) wrote: <br/> `
          joinedHtmlStr = joinedHtmlStr + seperator + item
        })
      }
    }

    return joinedHtmlStr
  }

  function convertToStr(to) {
    return `${get([[0], 'firstName'], to) || get([[0], 'name'], to, '')} <${
      get([[0], 'username'], to) || get([[0], 'email'], to, '')
    }>`
  }

  const replyLogic = () => {
    if (!isInbound) {
      setEditorData({
        ...editorData,
        from: renderFrom(singleMsgData.from),
        to: to.map(list => renderTo(list)),
        subject: get(['subject'], conversation, ''),
        editorValue: setHtmlToForward(),
      })
    } else {
      const from = to.find(list => list.id === FromMailId)

      setEditorData({
        ...editorData,
        from: renderFrom(from),
        to: [renderTo(singleMsgData.from)],
        subject: `Re: ${get(['subject'], conversation, '')}`,
        editorValue: setHtmlToForward(),
      })
    }
  }

  const replyAllLogic = () => {
    if (!isInbound) {
      setEditorData({
        ...editorData,
        from: renderFrom(singleMsgData.from),
        to: to.map(list => renderTo(list)),
        subject: get(['subject'], conversation, ''),
        editorValue: setHtmlToForward(),
      })
    } else {
      const from = to.find(list => list.id === FromMailId)

      setEditorData({
        ...editorData,
        from: renderFrom(from),
        to: [...to.filter(ls => ls.id !== FromMailId).map(list => renderTo(list)), renderTo(singleMsgData.from)],
        subject: `Re: ${get(['subject'], conversation, '')}`,
        editorValue: setHtmlToForward(),
      })
    }
  }

  function parseHtmlMsg(html) {
    if (!html) return ''
    return html
  }

  const onSplitConversation = () => {
    // const {index} = props
    const messagesToSplit = conversationMessagesReverse.filter((fil, i) => i >= index).map(item => item.id)

    const data = {
      messageIds: messagesToSplit,
    }
    // dispatch(splitConversation(data, props.conversationId))
    dispatch(splitConversation(data, conversationId))
    setSplitModalOpen(false)
  }

  const renderModalForSplittingConversation = () => {
    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        size="tiny"
        open={isSplitModalOpen}
        onClose={() => setSplitModalOpen(!isSplitModalOpen)}
      >
        <VmoModalHeader>Split conversation</VmoModalHeader>
        <VmoModalContent>
          <h4>Are you sure?</h4>
          <p className="kooper-lead">You want to split this conversation from this message?</p>
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton basic onClick={() => setSplitModalOpen(false)}>
            Cancel
          </VmoButton>
          <VmoButton primary onClick={() => onSplitConversation()}>
            Split
          </VmoButton>
        </VmoModalActions>
      </VmoModal>
    )
  }

  const renderAttachmentSection = attachments => {
    if (attachments && attachments.length > 0) {
      return (
        <>
          <h5 className="mb-0">{`${attachments.length} Attachment${attachments.length > 1 ? 's' : ''}`}</h5>
          {attachments.map(attachment => (
            <VmoCard className="attachcard-left mb-1">
              <VmoCardContent className="attchcontent">
                <VmoHeader as="h5">
                  <SvgIcon path="common/attachment" />
                  <VmoHeaderContent className="attchhead">
                    {wrapText(attachment.name, 10)}
                    <VmoHeaderSubheader>
                      {Number(attachment.size / (1024 * 1024)).toFixed(2) > 1
                        ? `${Number(attachment.size / (1024 * 1024)).toFixed(2)} Mb`
                        : `${Number(attachment.size / 1024).toFixed(2)} Kb`}
                    </VmoHeaderSubheader>
                  </VmoHeaderContent>
                  <VmoTooltip
                    size="mini"
                    position="top center"
                    content="Download"
                    trigger={
                      <VmoButton
                        icon
                        basic
                        className="dw-btn"
                        href={`${KOOPER_ATTACHMENTS}/${attachment.id}/download`}
                        target="_blank"
                      >
                        <SvgIcon path="common/download" className="m-0" />
                      </VmoButton>
                    }
                  />
                </VmoHeader>
              </VmoCardContent>
            </VmoCard>
          ))}
        </>
      )
    }
  }

  // UseEffects ------------------------------------------>

  useEffect(() => {
    if (replyEditorRef.current) {
      replyEditorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [replyEditorRef.current])

  useEffect(() => {
    if (index < conversation.messages.length - 3 && conversation.messages.length > 3) {
      setToggleMessage(false)
    } else {
      setToggleMessage(true)
    }
  }, [index, conversation])

  useEffect(() => {
    if (successLabels.includes(ADD_MESSAGE_TO_CONVERSATION)) {
      dispatch(inboxCounts())
    }
  }, [successLabels, dispatch])

  const dateTimeLabel = date => {
    if (date.indexOf(':') !== -1) {
      return 'Time'
    }
    return 'Date'
  }

  if (!isDraft) {
    return (
      <div className="kooper-msg-box">
        {renderModalForSplittingConversation()}
        <VmoAccordion>
          <VmoAccordionTitle
            onClick={() => setToggleMessage(!toggleMessage)}
            style={{
              borderBottom: toggleMessage ? '1px solid var(--border-color)' : null,
            }}
          >
            <VmoHeader as="h5">
              {toggleMessage ? <SvgIcon path="common/down" /> : <FiChevronRight />}
              <div>
                {renderFromOnlyName(singleMsgData.from)}
                <VmoHeaderSubheader className="mt-1">
                  <VmoDropdown text={convertToStr(to)} direction="right">
                    <VmoDropdownMenu className="p-4" style={{color: '#000'}}>
                      <p>From: {renderFrom(singleMsgData.from)}</p>
                      <p>To: {convertToStr(to)}</p>
                      <p>
                        {dateTimeLabel(date)}: {date}
                      </p>
                    </VmoDropdownMenu>
                  </VmoDropdown>
                  {index === 0 && conversation.agent && (
                    <span className="replyUser">Raised by {get(['agent', 'firstName'], conversation, ' ')}</span>
                  )}
                </VmoHeaderSubheader>
              </div>
            </VmoHeader>
            <div className="emailActionBtn">
              <VmoTooltip
                content="More"
                size="mini"
                position="left center"
                trigger={
                  <VmoDropdown
                    direction="left"
                    button
                    icon={<SvgIcon path="common/more-verticle" id={`TooltipReply${messageId}`} />}
                  >
                    <VmoDropdownMenu>
                      <VmoDropdownItem
                        onClick={() => mergeAndSplitAccess && splitConversationPermissions && setSplitModalOpen(true)}
                      >
                        {/* <SvgIcon path="inbox/split" />  */}
                        Split Conversation
                        {(!mergeAndSplitAccess || !splitConversationPermissions) && (
                          <span style={{marginLeft: 'auto'}}>
                            <PermissionIcon
                              isFeatureAccessDenied={!mergeAndSplitAccess}
                              isRoleAccessDenied={!splitConversationPermissions}
                            />
                          </span>
                        )}
                      </VmoDropdownItem>
                      <VmoDropdownItem
                        onClick={() => {
                          if (manageConversationPermissions) {
                            setEditorOpenFor('reply')
                            replyLogic()
                            dispatch(getMailBox(FromMailId))
                          }
                        }}
                      >
                        <SvgIcon path="inbox/reply" /> Reply
                        {!manageConversationPermissions && (
                          <span style={{marginLeft: 'auto'}}>
                            <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                          </span>
                        )}
                      </VmoDropdownItem>
                      <VmoDropdownItem
                        onClick={() => {
                          if (manageConversationPermissions) {
                            setEditorOpenFor('replyAll')
                            replyAllLogic()
                            dispatch(getMailBox(FromMailId))
                          }
                        }}
                      >
                        <SvgIcon path="inbox/replyAll" /> Reply All
                        {!manageConversationPermissions && (
                          <span style={{marginLeft: 'auto'}}>
                            <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                          </span>
                        )}
                      </VmoDropdownItem>
                      <VmoDropdownItem
                        onClick={() => {
                          if (!manageConversationPermissions) return
                          setEditorOpenFor('forward')
                          setEditorData({
                            ...editorData,
                            from: mailBoxes.find(ls => ls.id === FromMailId).email,
                            to: [],
                            editorValue: setHtmlToForward(),
                            subject: `Fwd: ${get(['subject'], conversation, '')}`,
                          })
                        }}
                      >
                        <SvgIcon path="inbox/forward" /> Forward
                        {!manageConversationPermissions && (
                          <span style={{marginLeft: 'auto'}}>
                            <PermissionIcon isRoleAccessDenied={!manageConversationPermissions} />
                          </span>
                        )}
                      </VmoDropdownItem>
                    </VmoDropdownMenu>
                  </VmoDropdown>
                }
              />
              <p className="kooper-lead">
                {!isInbound && (
                  <span className="replyUser">Replied by {get(['agent', 'firstName'], singleMsgData, ' ')}</span>
                )}
                &nbsp;
                {props.dateAndTime}
              </p>
              <p className="kooper-lead" />
            </div>
          </VmoAccordionTitle>

          <VmoAccordionContent active={toggleMessage} style={{padding: '0 12px'}}>
            <WrappedIframe index={index} srcDoc={parseHtmlMsg(textHtml)} />
          </VmoAccordionContent>
        </VmoAccordion>
        {renderAttachmentSection(attachments)}
      </div>
    )
  }

  if (isDraft)
    return (
      <InboxInLineComposeMail
        defaultFrom={mailBoxes.map(inbox => {
          if (inbox.id === FromMailId) return inbox.name || inbox.username
        })}
        defaultTo={to.map(to => to.email)}
        defaultSubject={conversationSubject}
        defaultEditorValue={textHtml}
        isDraftConversation={isDraft}
        ConversationId={conversationId}
        draftMessageId={messageId}
        fromInboxId={FromMailId}
        conversationType={conversation.type}
      />
    )
}
