/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import {
  VmoButton,
  VmoTable,
  VmoTableHeader,
  VmoTableRow,
  VmoTableHeaderCell,
  VmoTableBody,
  VmoTablePlaceholder,
  VmoTableCell,
  VmoHeader,
  VmoDropdown,
  VmoDropdownMenu,
  VmoDropdownItem,
  VmoTooltip,
  VmoDivider,
  VmoLabel,
} from 'vmo-library'
import {COPY_INVOICES, DELETE_INVOICES, GET_INVOICES_LIST} from 'actions/types'
import {copyInvoices, deleteInvoices, getInvoiceslist} from 'actions/invoices'
import {platformEndpoint} from 'actions/endpoint'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import {Status} from 'constants/variables'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import SearchComponent from 'components/common/SearchComponent'
import create from 'assets/create.svg'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SettingLayout from 'layouts/settingLayout'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

function openInvoice(url) {
  const win = window.open(`${platformEndpoint}/view/invoice/${url}`, '_blank')
  win.focus()
}

function InvoicesList() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    status: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageInvoicesPermissions = financePermissions?.manage_invoices

  const {isLoading} = useApiLoader({label: GET_INVOICES_LIST})

  const {data: invoicesList = []} = useApiResponse({
    action: getInvoiceslist,
    enabled: true,
    label: GET_INVOICES_LIST,
    storePath: 'invoices.invoicesList',
  })

  console.log('list', invoicesList[0])

  useEffect(() => {
    if (successLabels.includes(DELETE_INVOICES) || successLabels.includes(COPY_INVOICES)) {
      dispatch(getInvoiceslist())
    }
  }, [successLabels, dispatch])

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getInvoiceslist(newCurrentListParams))
  }, [currentListParams, dispatch])

  const renderTableBody = () => {
    return invoicesList.map(({id, invoiceNumber, urlLink, subject, invoiceDate, status, total}, index) => {
      return (
        <VmoTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            dispatch(getInvoiceslist(id))
            history.push(`/invoices/update/${id}`)
          }}
        >
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoHeader as="h5">{startCase(invoiceNumber)}</VmoHeader>
          </VmoTableCell>
          <VmoTableCell>{startCase(subject)}</VmoTableCell>
          <VmoTableCell>{invoiceDate && getFormattedDate(invoiceDate)}</VmoTableCell>
          <VmoTableCell>{status ? <VmoLabel color="gray">{startCase(status)}</VmoLabel> : ''}</VmoTableCell>
          <VmoTableCell>{total}</VmoTableCell>
          <VmoTableCell>
            <VmoDropdown button icon={<SvgIcon path="common/more-verticle" />} className="icon" direction="left">
              <VmoDropdownMenu>
                <VmoDropdownItem onClick={() => openInvoice(urlLink)}>
                  <SvgIcon path="common/page-view" /> View Page
                </VmoDropdownItem>

                <LockPermissionTooltip isRoleAccessDenied={!manageInvoicesPermissions}>
                  <VmoDropdownItem onClick={() => manageInvoicesPermissions && dispatch(copyInvoices(id))}>
                    <SvgIcon path="common/clone" /> Clone
                  </VmoDropdownItem>
                </LockPermissionTooltip>

                <LockPermissionTooltip isRoleAccessDenied={!manageInvoicesPermissions}>
                  <VmoDropdownItem
                    onClick={() => {
                      if (manageInvoicesPermissions) {
                        setEditId(id)
                        setDeleteModal(true)
                      }
                    }}
                  >
                    <SvgIcon path="common/delete" /> Delete
                  </VmoDropdownItem>
                </LockPermissionTooltip>
              </VmoDropdownMenu>
            </VmoDropdown>
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {invoicesList.length > 0 || currentListParams.text || currentListParams.status ? (
        <div className="kooper-side-wrap">
          <div className="page-header">
            <div className="page-title">
              <div className="page-titleIcon">
                <SvgIcon path="navigation/invoices" />
                <h5>Invoices List ({invoicesList ? invoicesList.length : 0})</h5>
              </div>
            </div>
            <div className="page-action">
              <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />
              <VmoTooltip
                position="top center"
                size="mini"
                content="Select Status"
                trigger={
                  <div className="mailActivity ml-3">
                    <VmoDropdown
                      icon="chevron down"
                      button
                      defaultValue="all"
                      options={[{key: 'all', value: 'all', text: 'All Status'}, <VmoDivider />, ...Status]}
                      onChange={(e, {value}) => {
                        setCurrentListParams({
                          ...currentListParams,
                          status: value,
                        })
                      }}
                    />
                  </div>
                }
              />
              <LockPermissionTooltip isRoleAccessDenied={!manageInvoicesPermissions}>
                <VmoButton
                  className={!manageInvoicesPermissions ? 'disabled-button' : ''}
                  content="Add"
                  primary
                  onClick={() => manageInvoicesPermissions && history.push(`/invoices/create`)}
                />
              </LockPermissionTooltip>
            </div>
          </div>
          <VmoTable sortable basic selectable>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell>#</VmoTableHeaderCell>
                <VmoTableHeaderCell>Invoice Number</VmoTableHeaderCell>
                <VmoTableHeaderCell>Subject</VmoTableHeaderCell>
                <VmoTableHeaderCell>Invoice Date</VmoTableHeaderCell>
                <VmoTableHeaderCell>Status</VmoTableHeaderCell>
                <VmoTableHeaderCell>Total Value</VmoTableHeaderCell>
                <VmoTableHeaderCell>Actions</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(GET_INVOICES_LIST) ? (
                <VmoTablePlaceholder columns={8} />
              ) : (invoicesList || []).length > 0 ? (
                renderTableBody()
              ) : (
                <tr>
                  <td colSpan={8} className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </VmoTableBody>
          </VmoTable>

          {deleteModal && (
            <DeleteModal
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete ?"
              deleteAction={deleteInvoices}
              idTobeDeleted={editId}
              type={DELETE_INVOICES}
            />
          )}
        </div>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Invoice"
            addFirst={() => manageInvoicesPermissions && history.push(`/invoices/create`)}
            tooltip={!manageInvoicesPermissions}
            tooltipContent={getPermissionTooltipContent({
              roleAccess: manageInvoicesPermissions,
            })}
            buttonText="Create Invoice"
            imageSrc={create}
            subHeader="Invoices helps you to create product invoice and receive payment faster in a minute"
            Feature1="Custom Templates"
            list1="Choose multiple invoice templates to suit your customers."
            Feature2="Add Taxes and Discounts"
            list2="Apply taxes and discounts to invoice based on policies."
            Feature3="Payment Terms"
            list3="Easily add payment terms and conditions"
          />
        </SettingLayout>
      )}
    </>
  )
}

export default InvoicesList
