/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import {joiResolver} from '@hookform/resolvers'
import _ from 'lodash'
import {
  VmoButton,
  VmoDivider,
  VmoForm,
  VmoFormField,
  VmoFormGroup,
  VmoFormInput,
  VmoFormSelect,
  VmoFormTextArea,
  VmoGrid,
  VmoGridColumn,
  VmoHeader,
  VmoInput,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoTooltip,
} from 'vmo-library'
import {CREATE_VENDORS_FIELD, EDIT_VENDORS_FIELD, GET_SINGLE_VENDORS_FIELD} from 'actions/types'
import {createVendorsField, editVendorsField} from 'actions/vendors'
import {createVendorsFieldSchema} from 'validation/Finance/vendors.schema'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import SvgIcon from 'components/common/SvgIcon'

const initialFormData = {
  type: 'Text',
  required: false,
  categoryIds: [],
  description: '',
  helpText: '',
  name: '',
  value: '',
}

const FieldsModal = props => {
  const {open, modalToggle, categoryList, setCategoryList} = props
  const dispatch = useDispatch()
  const {getSingleVendorsFieldData, getVendorsCategoryData} = useSelector(state => state.vendors)
  const {type, isLoadingData, successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const manageVendorSettingsPermissions = financePermissions?.manage_vendor_settings

  const [fieldId, setFieldId] = useState(null)

  const {handleSubmit, errors, control, clearErrors, watch, reset, setValue} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: joiResolver(createVendorsFieldSchema),
    defaultValues: initialFormData,
  })

  const currentValues = watch()

  const typeOption = [
    {key: 'Text', value: 'Text', text: 'Text'},
    {key: 'Textarea', value: 'Textarea', text: 'Textarea'},
    {key: 'Number', value: 'Number', text: 'Number'},
    {key: 'Dropdown', value: 'Dropdown', text: 'Dropdown'},
    {
      key: 'Multiple Option',
      value: 'Multiple Option',
      text: 'Multiple Option',
    },
    {key: 'Date', value: 'Date', text: 'Date'},
  ]

  const [inputList, setInputList] = useState([{name: ''}])

  const createEditField = (data, id = -1) => {
    const newData = {...data}
    if (data.type === 'Dropdown' || data.type === 'Multiple Option') {
      const valueString = inputList
        .map(item => {
          return item.name.trim()
        })
        .filter(el => {
          return el != null && el !== ''
        })
        .join(',')
      newData.value = valueString
      setValue('value', valueString)
    }

    if (!fieldId) {
      const newList = _.omitBy(newData, value => !!(value === '' || value === null || _.isEqual(value, [])))
      dispatch(createVendorsField(newList))
    } else {
      const newList = _.omitBy(
        newData,
        (value, key) => !!(value === '' || value === null || _.isEqual(value, []) || key === 'id' || key === 'type')
      )
      dispatch(editVendorsField(id, newList))
    }
  }

  useEffect(() => {
    return () => {
      reset(initialFormData)
      setInputList([{name: ''}])
    }
  }, [])

  useEffect(() => {
    if (successLabels.includes(CREATE_VENDORS_FIELD)) {
      modalToggle(false)
      reset(initialFormData)
    }
    if (successLabels.includes(EDIT_VENDORS_FIELD)) {
      modalToggle(false)
      reset(initialFormData)
    }
  }, [successLabels])

  useEffect(() => {
    if (getVendorsCategoryData)
      setCategoryList(
        getVendorsCategoryData.map(({category, id}) => {
          return {key: id, value: id, text: category}
        })
      )
  }, [JSON.stringify(getVendorsCategoryData)])

  useEffect(() => {
    if (successLabels.includes(GET_SINGLE_VENDORS_FIELD)) {
      const data = _.omit(getSingleVendorsFieldData, 'id', 'categoryIds')

      setFieldId(getSingleVendorsFieldData.id)

      if (getSingleVendorsFieldData.categoryIds) {
        const availableValues = categoryList.map(cate => cate.value)
        data.categoryIds = getSingleVendorsFieldData.categoryIds
          .filter(cate => {
            if (availableValues.includes(cate)) {
              return cate
            }
          })
          .map(item => item)
      }

      reset(data)
      if (getSingleVendorsFieldData.value)
        setInputList(
          getSingleVendorsFieldData.value.split(',').map(name => {
            return {name}
          })
        )
    }
  }, [getSingleVendorsFieldData, successLabels])

  return (
    <VmoModal
      size="small"
      open={open}
      onClose={() => modalToggle(false)}
      closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
    >
      <VmoHeader className="vmoModalHeader" content={fieldId ? 'Edit Vendors Fields' : 'Create Vendors Fields'} />
      <VmoModalContent scrolling>
        <VmoForm className="errorLabel">
          <VmoFormField required>
            <div className="info-header">
              <label className="label-class">Name</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Specify name of the field"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="name"
              render={({value, onChange, onBlur}) => (
                <VmoFormInput
                  type="text"
                  maxLength={20}
                  placeholder="Name.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.name && {
                      content: errors.name.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Description</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="mention brief description about the field"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="description"
              render={({value, onChange, onBlur}) => (
                <VmoFormTextArea
                  maxLength={160}
                  id="version"
                  placeholder="Write description.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.description && {
                      content: errors.description.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormGroup widths="equal">
            <VmoFormField>
              <div className="info-header">
                <label>Type</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Set what type of field it is"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="type"
                render={({value, onChange, onBlur}) => (
                  <VmoFormSelect
                    fluid
                    disabled={fieldId}
                    options={typeOption}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.type && {
                        content: errors.type.message,
                      }
                    }
                    onBlur={onBlur}
                  />
                )}
                control={control}
              />
            </VmoFormField>

            <VmoFormField required>
              <div className="info-header">
                <label className="label-class">Link Category</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="Link field with a category"
                  size="mini"
                  position="top center"
                />
              </div>
              <Controller
                name="categoryIds"
                render={({value, onChange, onBlur}) => (
                  <VmoFormSelect
                    search
                    multiple
                    fluid
                    options={categoryList}
                    onClick={() => errors.categoryIds && clearErrors('categoryIds')}
                    value={value}
                    onChange={(e, {value}) => onChange(value)}
                    error={
                      errors.categoryIds && {
                        content: errors.categoryIds.message,
                      }
                    }
                    onBlur={onBlur}
                    allowAdditions
                    onAddItem={(e, {value}) => {
                      setCategoryList([{key: value, value, text: value}, ...categoryList])
                    }}
                  />
                )}
                control={control}
              />
            </VmoFormField>
          </VmoFormGroup>
          {(currentValues.type === 'Dropdown' || currentValues.type === 'Multiple Option') && (
            <VmoFormField>
              <div className="info-header">
                <label>Dropdown Field</label>
                <VmoTooltip
                  trigger={<SvgIcon path="common/question" />}
                  content="select if it is required or not"
                  size="mini"
                  position="top center"
                />
              </div>
              <VmoDivider hidden />
              <VmoGrid width={16}>
                {inputList.map((input, index) => {
                  return (
                    <VmoGridColumn width={8}>
                      <VmoFormGroup key={index} inline>
                        <VmoFormField>
                          <label>{index + 1}</label>
                          <VmoInput
                            autoFocus
                            name="name"
                            placeholder="Enter name..."
                            value={input.name}
                            onChange={e => {
                              const {name, value} = e.target
                              const list = [...inputList]
                              list[index][name] = value // trim-multiple-space -->value.replace(/\s+/g, " ")
                              setInputList(list)
                            }}
                            action={{
                              content: index === inputList.length - 1 ? 'Add' : 'Remove',
                              disabled: inputList.slice(-1)[0].name.trim() === '',
                              // disabled: inputList.length < 2,
                              onClick: () => {
                                if (index === inputList.length - 1) {
                                  setInputList([...inputList, {name: ''}])
                                } else {
                                  const list = [...inputList]
                                  list.splice(index, 1)
                                  setInputList(list)
                                }
                              },
                            }}
                          />
                        </VmoFormField>
                      </VmoFormGroup>
                    </VmoGridColumn>
                  )
                })}
              </VmoGrid>
            </VmoFormField>
          )}
          <VmoFormField>
            <div className="info-header">
              <label>Required</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Select if it is required or not"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="required"
              render={({value, onChange}) => (
                <VmoFormSelect
                  selection
                  fluid
                  value={value}
                  options={[
                    {key: 'yes', value: true, text: 'Yes'},
                    {key: 'no', value: false, text: 'No'},
                  ]}
                  onChange={(e, {value}) => onChange(value)}
                />
              )}
              control={control}
            />
          </VmoFormField>
          <VmoFormField>
            <div className="info-header">
              <label>Help text for agents</label>
              <VmoTooltip
                trigger={<SvgIcon path="common/question" />}
                content="Help agent to understand the significance of the field"
                size="mini"
                position="top center"
              />
            </div>
            <Controller
              name="helpText"
              render={({value, onChange, onBlur}) => (
                <VmoFormTextArea
                  maxLength={160}
                  placeholder="Write here.."
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  error={
                    errors.helpText && {
                      content: errors.helpText.message,
                    }
                  }
                  onBlur={onBlur}
                />
              )}
              control={control}
            />
          </VmoFormField>
        </VmoForm>
      </VmoModalContent>
      <VmoModalActions>
        <VmoButton
          onClick={() => modalToggle(false)}
          disabled={isLoadingData && (type.includes(EDIT_VENDORS_FIELD) || type.includes(CREATE_VENDORS_FIELD))}
          basic
        >
          Cancel
        </VmoButton>
        {fieldId ? (
          <LockPermissionTooltip isRoleAccessDenied={!manageVendorSettingsPermissions}>
            <VmoButton
              disabled={isLoadingData && type.includes(EDIT_VENDORS_FIELD)}
              loading={isLoadingData && type.includes(EDIT_VENDORS_FIELD)}
              primary
              className={!manageVendorSettingsPermissions ? 'disabled-button' : ''}
              {...(manageVendorSettingsPermissions && {
                onClick: handleSubmit(data => createEditField(data, fieldId)),
              })}
            >
              Save
            </VmoButton>
          </LockPermissionTooltip>
        ) : (
          <VmoButton
            loading={isLoadingData && type.includes(CREATE_VENDORS_FIELD)}
            disabled={isLoadingData && type.includes(CREATE_VENDORS_FIELD)}
            primary
            onClick={handleSubmit(createEditField)}
          >
            Create
          </VmoButton>
        )}
      </VmoModalActions>
    </VmoModal>
  )
}

export default FieldsModal
