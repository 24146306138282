/* eslint-disable jsx-a11y/anchor-is-valid */
import SvgIcon from 'components/common/SvgIcon'
import React from 'react'
import {
  VmoModal,
  VmoModalHeader,
  VmoModalContent,
  VmoButton,
  VmoForm,
  VmoFormField,
  VmoCardContent,
  VmoIcon,
} from 'vmo-library'

const ImportModal = props => {
  const {isModalOpen, setIsModalOpen} = props

  return (
    <VmoModal
      size="small"
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      toggle={() => setIsModalOpen(!isModalOpen)}
      closeIcon={
        <VmoButton icon floated="right">
          <SvgIcon path="common/close" />
        </VmoButton>
      }
    >
      <VmoModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Import CSV</VmoModalHeader>
      <VmoModalContent>
        <VmoForm className="vmo-form">
          <VmoFormField>
            <label htmlFor="import">Import CSV</label>
            <VmoButton
              primary
              icon
              labelPosition="left"
              // onClick={() => uploadCVS.current.click()}
            >
              <VmoIcon name="file" />
              Upload CSV
            </VmoButton>
            <input
              hidden
              // ref={uploadCVS}
              type="file"
              id="import"
              // name="import"
              // label="Upload CSV"
              // onChange={(e) => setFiles(e.target.files)}
            />
            <br />
            <small>Max upload file size is 2 MB</small>
          </VmoFormField>
          <h5 className="mb-0">Download CSV example</h5>
          <p className="kooper-lead m-0">
            Download file : <a href="">Sample.csv</a>
          </p>
        </VmoForm>
      </VmoModalContent>
      <VmoCardContent>
        <VmoButton onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</VmoButton>
        <VmoButton
          primary
          // onClick={() => {
          //   let data = {
          //     file: files,
          //   };
          //   dispatch(uploadContactsCSV(data));
          // }}
        >
          Import
        </VmoButton>{' '}
      </VmoCardContent>
    </VmoModal>
  )
}

export default ImportModal
