import {COMPANIES_ENDPOINT, KOOPER_ACCOUNT_V1} from './endpoint'
import {
  GET_ALL_COMPANIES,
  CREATE_COMPANY,
  GET_SINGLE_COMPANY,
  EDIT_COMPANY,
  UPDATE_COMPANY_TAGS,
  ADD_COMPANY_INTERACTION,
  ADD_NOTE_COMPANY,
  GET_ALL_COMPANY_NOTES,
  GET_ALL_ACTIVITIES_COMPANY,
  DELETE_COMPANY,
  MULTIPLE_UPDATE_COMPANY,
  ATTACHMENT_POST_COMPANY,
  EXPORT_ALL_COMPANIES,
  SAMPLE_COMPANIES_IMPORT,
  IMPORT_COMPANIES,
  MULTIPLE_MERGE_COMPANY,
  GET_COLUMNS_SELECTION_COMPANY,
  COLUMNS_SELECTION_COMPANY,
  GET_ALL_COMPANIES_IDS,
} from './types'
import {apiAction} from './api'

export function getCompanyList(params = {}) {
  const keys = Object.keys(params)
  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')
  return apiAction({
    url: `${COMPANIES_ENDPOINT}?${query}`,
    method: 'GET',
    label: GET_ALL_COMPANIES,
  })
}

export function getAllCompaniesIds() {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/all/ids`,
    method: 'GET',
    label: GET_ALL_COMPANIES_IDS,
  })
}

export function addCompany(data) {
  return apiAction({
    url: COMPANIES_ENDPOINT,
    method: 'POST',
    label: CREATE_COMPANY,
    data,
    showToast: true,
    successMessage: 'Company Created',
  })
}

export function getSingleCompany(companyId, userId = 'all') {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${companyId}?userId=${userId}`,
    method: 'GET',
    label: GET_SINGLE_COMPANY,
  })
}

export function editCompany(formInfo, id) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}`,
    method: 'PUT',
    label: EDIT_COMPANY,
    data: formInfo,
    showToast: true,
    successMessage: 'Company Updated',
  })
}

export function updateTagCompany(id, data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/tags`,
    method: 'POST',
    label: UPDATE_COMPANY_TAGS,
    data,
    showToast: true,
    successMessage: 'Updated Tag',
  })
}

export function addInteractionCompany(companyId, data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${companyId}/interactions`,
    method: 'POST',
    label: ADD_COMPANY_INTERACTION,
    data,
    showToast: true,
    successMessage: 'Interaction Created',
  })
}

export function addNoteCompany(id, data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_COMPANY,
    data,
    showToast: true,
    successMessage: 'Note Created',
  })
}

export function getAllCompanyNotes(id) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/notes`,
    method: 'GET',
    label: GET_ALL_COMPANY_NOTES,
  })
}

export function getAllActivitiesCompany(id) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/activity`,
    method: 'GET',
    label: GET_ALL_ACTIVITIES_COMPANY,
  })
}

export function deleteCompany(id) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}`,
    method: 'DELETE',
    label: DELETE_COMPANY,
    showToast: true,
    successMessage: 'Deleted Company',
  })
}

export function companyMultipleUpdate(data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/multiple`,
    method: 'PUT',
    label: MULTIPLE_UPDATE_COMPANY,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}

export function uploadAttachmentsCompany(id, data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/${id}/attachments`,
    method: 'POST',
    label: ATTACHMENT_POST_COMPANY,
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'File Uploaded',
  })
}

export function exportAllCompany() {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/export`,
    label: EXPORT_ALL_COMPANIES,
    method: 'GET',
  })
}

export function sampleCompanyImportFile() {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/import/sample`,
    label: SAMPLE_COMPANIES_IMPORT,
    method: 'GET',
  })
}

export function importCompanies(data) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/import`,
    label: IMPORT_COMPANIES,
    method: 'POST',
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Imported',
  })
}

export function columnsSelectionCompany(activeCol) {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/columns`,
    method: 'PUT',
    label: COLUMNS_SELECTION_COMPANY,
    data: activeCol,
    showToast: true,
    successMessage: 'Columns Selected',
  })
}

export function getColumnsSelectionCompany() {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/columns`,
    method: 'GET',
    label: GET_COLUMNS_SELECTION_COMPANY,
  })
}

export function multipleMergeCompany(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/companies/merge`,
    method: 'POST',
    label: MULTIPLE_MERGE_COMPANY,
    data,
    showToast: true,
    successMessage: 'Updated',
  })
}
