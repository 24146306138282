import React, {useEffect, useMemo, useRef, useState} from 'react'
import Moment from 'moment'
import {DateRangePicker} from 'react-date-range'
import 'react-date-range/dist/theme/default.css'
import 'react-date-range/dist/styles.css' // main style fil
import {VmoButton, VmoDropdown, VmoDropdownItem, VmoDropdownMenu} from 'vmo-library'
import {get} from 'utils/helper'
import {DATE_RANGE_KEY} from 'constants/variables'

const DateRangeGroup = props => {
  const {actionsToDispatch, docId, inboxId, userId, teamId, serviceId, campaignId, workflowId, projectId} = props

  const dateRef = useRef()

  const [date, setDate] = useState(false)
  const [selection, setSelection] = useState([
    {
      startDate: new Date(Moment().subtract(6, 'days').startOf('day')),
      endDate: new Date(Moment().endOf('day')),
      key: 'selection',
    },
  ])
  const oldKooper = useMemo(() => {
    return JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY) || '{}')
  }, [])
  const [startDate, setStartDate] = useState(
    () =>
      get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY))) ||
      Moment().subtract(6, 'days').startOf('day')
  )

  const [endDate, setEndDate] = useState(
    () =>
      get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY))) || Moment().endOf('day')
  )

  const data = useMemo(() => {
    const diff = Moment(endDate).diff(Moment(startDate), 'days')
    const setData = () => {
      let type

      if (diff === 0) {
        type = 'hour'
      } else if (diff < 7) {
        type = 'day'
      } else if (diff < 31) {
        type = 'date'
      } else {
        type = 'month'
      }

      return type
    }
    const otherData = {
      ...(userId && {userId}),
      ...(teamId && {teamId}),
      ...(inboxId && {inboxId}),
      ...(docId && {docId}),
      ...(serviceId && {serviceId}),
      ...(campaignId && {campaignId}),
      ...(workflowId && {workflowId}),
      ...(projectId && {projectId}),
    }
    if (diff === 0) {
      return {
        type: 'hour',
        from: new Date(Moment(startDate).startOf('day')).toISOString(),
        to: new Date(Moment(endDate).endOf('day')).toISOString(),
        ...otherData,
      }
    }
    return {
      type: setData(),
      from: new Date(startDate).toISOString(),
      to: new Date(endDate).toISOString(),
      ...otherData,
    }
  }, [endDate, startDate, userId, teamId, inboxId, docId, serviceId, campaignId, workflowId, projectId])

  useEffect(() => {
    function handleClickOutside(e) {
      if (dateRef.current && !dateRef.current.contains(e.target)) {
        setDate(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dateRef])

  useEffect(() => {
    actionsToDispatch(data)

    window.localStorage.setItem(
      DATE_RANGE_KEY,
      JSON.stringify({
        ...oldKooper,
        persistDateRangeStartDate: startDate,
        persistDateRangeEndDate: endDate,
        persistDateRangeDataToDispatch: data,
      })
    )
  }, [actionsToDispatch, data, endDate, oldKooper, startDate])

  const onApply = () => {
    setStartDate(selection[0].startDate)
    setEndDate(selection[0].endDate)
    setDate(false)
  }

  useEffect(() => {
    setSelection([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      },
    ])
  }, [startDate, endDate])

  return (
    <div className="mailActivity">
      <VmoDropdown
        icon="chevron down"
        button
        className="data-pick"
        text={
          Moment(get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).format(
            'DDMMYYYY'
          ) !==
          Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).format(
            'DDMMYYYY'
          )
            ? `${Moment(
                get(['persistDateRangeStartDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))
              ).format('DD MMM, YYYY')} to ${Moment(
                get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))
              ).format('DD MMM, YYYY')}`
            : Moment(get(['persistDateRangeEndDate'], JSON.parse(window.localStorage.getItem(DATE_RANGE_KEY)))).format(
                'DD MMM, YYYY'
              )
        }
      >
        <VmoDropdownMenu className="left">
          <VmoDropdownItem ref={dateRef} onClick={e => e.stopPropagation()}>
            <DateRangePicker
              moveRangeOnFirstSelection={false}
              ranges={selection}
              onChange={({selection: newSelection}) => setSelection([newSelection])}
            />
          </VmoDropdownItem>
          <div className="action">
            <VmoButton basic onClick={() => setDate(false)}>
              Close
            </VmoButton>
            <VmoButton primary onClick={onApply}>
              Apply
            </VmoButton>
          </div>
        </VmoDropdownMenu>
      </VmoDropdown>
    </div>
  )
}

export default DateRangeGroup
