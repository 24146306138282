import {apiAction} from './api'
import {FINANCE_INVOICES_ENDPOINT} from './endpoint'
import {
  CREATE_INVOICES_PREFIX,
  GET_NEW_INVOICES_NUMBER,
  GET_INVOICES_PREFIX,
  DELETE_INVOICES_PREFIX,
  CREATE_INVOICES,
  GET_INVOICES_LIST,
  DELETE_INVOICES,
  GET_INVOICES_DATA,
  UPDATE_INVOICES,
  COPY_INVOICES,
  DELETE_INVOICE_LOGO,
} from './types'

export function createInvoicesPrefix(data) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/prefix`,
    method: 'POST',
    label: CREATE_INVOICES_PREFIX,
    data,
    showToast: true,
    successMessage: 'Prefix Added',
  })
}

export function getNewInvoicesNumber(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/number/${id}`,
    method: 'GET',
    label: GET_NEW_INVOICES_NUMBER,
  })
}

export function getInvoicesPrefixes() {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/prefixes`,
    method: 'GET',
    label: GET_INVOICES_PREFIX,
  })
}

export function deleteInvoicesPrefix(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/prefix/${id}`,
    method: 'DELETE',
    label: DELETE_INVOICES_PREFIX,
    showToast: true,
    successMessage: 'Prefix Deleted',
  })
}

export function createInvoices(data) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}`,
    method: 'POST',
    label: CREATE_INVOICES,
    data,
    showToast: true,
    successMessage: 'Invoice Added',
  })
}

export function getInvoiceslist(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params || {})
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: FINANCE_INVOICES_ENDPOINT + query,
    method: 'GET',
    label: GET_INVOICES_LIST,
  })
}

export function deleteInvoices(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/${id}`,
    method: 'DELETE',
    label: DELETE_INVOICES,
    showToast: true,
    successMessage: 'Invoice Deleted',
  })
}

export function getInvoicesData(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_INVOICES_DATA,
  })
}

export function updateInvoices(id, data) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/${id}`,
    method: 'PUT',
    label: UPDATE_INVOICES,
    data,
    showToast: true,
    successMessage: 'Invoice Updated',
  })
}

export function copyInvoices(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}/clone/${id}`,
    method: 'PUT',
    label: COPY_INVOICES,
    showToast: true,
    successMessage: 'Invoice Cloned',
  })
}

export function deleteInvoiceLogo(id) {
  return apiAction({
    url: `${FINANCE_INVOICES_ENDPOINT}logo/${id}`,
    method: 'DELETE',
    label: DELETE_INVOICE_LOGO,
    showToast: true,
    successMessage: 'Logo Updated',
  })
}
