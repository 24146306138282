/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {joiResolver} from '@hookform/resolvers'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoCheckbox,
  VmoContainer,
  VmoForm,
  VmoFormField,
  VmoFormInput,
  VmoImage,
  VmoModal,
  VmoModalActions,
  VmoModalContent,
  VmoModalHeader,
} from 'vmo-library'
import {get, removeDoubleQuotes} from 'utils/helper'
import {logInUser, otpRequest} from 'actions'
import {LOGIN_USER, RESET_PASSWORD} from 'actions/types'
import logo from 'assets/omni24-full-logo.png'
import useApiResponse from 'hooks/impure/useApiResponse'
import {
  setAccountInfo,
  setMetadataInfo,
  setUserInfo,
  setRoleInfo,
  setAppToken,
  setLastActivity,
} from 'utils/local-storage'
import {signInSchema} from 'validation/signin.schema'
import FullPageLoader from 'components/common/FullPageLoader'
import {currentSubscription} from 'actions/billing'
import {getUserRolePermissions} from 'actions/roles'
import moment from 'moment'
import SvgIcon from 'components/common/SvgIcon'

function Signin() {
  const location = useLocation()
  const integrationParams = location.integration
  const {handleSubmit, errors, control} = useForm({
    mode: 'onTouched',
    shouldFocusError: true,
    reValidateMode: 'onChange',
    submitFocusError: true,
    shouldUnregister: false,
    resolver: joiResolver(signInSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const {successLabels = [], type = []} = useSelector(state => state.apiReducer)

  const [passwordRequestStatus, setPasswordRequestStatus] = useState(false)
  const [showPwd, setShowPwd] = useState(false)
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [multipleLogin, setMultipleLogin] = useState(false)
  const [resetEmailText, setResetEmailText] = useState('')

  // eslint-disable-next-line no-useless-escape
  const subDomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i
  const subdomain = window.location.hostname.match(subDomainRegex)

  const setUserDataInLocatStorage = loginUserDetails => {
    const date = Date.now()
    setLastActivity(date)
    const time = moment(get(['customerSubscription', 'endAt'], loginUserDetails)).diff(moment(), 'miliseconds')

    setRememberMe(rememberMe)
    setAppToken(get(['token'], loginUserDetails))
    setAccountInfo(get(['account'], loginUserDetails))
    setUserInfo(get(['user'], loginUserDetails))
    setMetadataInfo(get(['account', 'metadata'], loginUserDetails))
    setRoleInfo(get(['role'], loginUserDetails))

    if (get(['customerSubscription', 'status'], loginUserDetails) === 'trialing' && time > 0) {
      history.push(`/getting-started`)
    } else {
      history.push('/home')
    }

    // if (
    //   !(
    //     get(['metadata', 'emailInboxStatus'], loginUserDetails, '') &&
    //     get(['metadata', 'docsStatus'], loginUserDetails, '') &&
    //     get(['metadata', 'inviteStatus'], loginUserDetails, '') &&
    //     get(['metadata', 'ticketStatus'], loginUserDetails, '') &&
    //     get(['metadata', 'widgetStatus'], loginUserDetails, '') &&
    //     get(['metadata', 'facebookStatus'], loginUserDetails, '')
    //   )
    // ) {
    //   // history.push(`/gettingstarted`)
    //   history.push(`/home`)
    // } else {
    //   history.push(`/home`)
    // }

    dispatch({
      type: 'LOGGED_IN',
    })
  }

  useApiResponse({
    label: RESET_PASSWORD,
    onSuccess: () => {
      setPasswordRequestStatus(true)
      setTimeout(() => {
        setPasswordRequestStatus(false)
        setForgotPasswordModal(false)
        setResetEmailText('')
      }, 3000)
    },
  })

  const {data: loginUserDetails} = useApiResponse({
    label: LOGIN_USER,
    storePath: 'loginUser.loginUserDetails',
  })

  const onSignIn = data => {
    const payload = {
      email: data.email,
      password: data.password,
      domain: (subdomain && subdomain[1]) || window.localStorage.getItem('domain'),
    }
    if (integrationParams) payload.integration = integrationParams.integration
    dispatch(logInUser(payload))
  }

  const resetEmail = () => {
    const data = {
      email: resetEmailText,
      domain: (subdomain && subdomain[1]) || window.localStorage.getItem('domain'),
    }
    dispatch(otpRequest(data))
  }

  const RenderMultipleLoginDetected = () => {
    return (
      <VmoModal
        size="tiny"
        open={multipleLogin}
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        onClose={() => setMultipleLogin(false)}
      >
        <VmoModalHeader className="vmoModalHeader">Multiple Login Detected</VmoModalHeader>
        <VmoModalContent>
          <h5>
            If you log in from this browser, all the previous login will be logged out automatically. Do you wish to
            continue?
          </h5>
        </VmoModalContent>
        <VmoModalActions>
          <VmoButton basic onClick={() => setMultipleLogin(false)}>
            No
          </VmoButton>
          <VmoButton
            primary
            icon="checkmark"
            labelPosition="right"
            content="Yes"
            onClick={handleSubmit(data => {
              dispatch(
                logInUser({
                  email: data.email,
                  password: data.password,
                  isLoginConfirm: true,
                  domain: (subdomain && subdomain[1]) || window.localStorage.getItem('domain'),
                })
              )
            })}
          />
        </VmoModalActions>
      </VmoModal>
    )
  }

  const RenderForgotPassword = () => {
    return (
      <VmoModal
        closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        open={forgotPasswordModal}
        onClose={() => {
          setForgotPasswordModal(false)
          setResetEmailText('')
        }}
        size="tiny"
      >
        <VmoModalHeader>Forgot Password ?</VmoModalHeader>
        <VmoModalContent>
          {!passwordRequestStatus && (
            <>
              <h5>
                Please enter the email address you used to register, We will then send you a link to reset password...
              </h5>
              <VmoForm size="large">
                <VmoFormField>
                  <label>Type your email address</label>
                  <input type="text" value={resetEmailText} onChange={e => setResetEmailText(e.target.value)} />
                </VmoFormField>
              </VmoForm>
            </>
          )}
          {passwordRequestStatus && (
            <div>
              Please check <b>{resetEmailText}</b> inbox for OTP and link for reseting the password
            </div>
          )}
        </VmoModalContent>
        {!passwordRequestStatus && (
          <VmoModalActions>
            <VmoButton
              basic
              onClick={() => {
                setForgotPasswordModal(false)
                setResetEmailText('')
              }}
            >
              Cancel
            </VmoButton>
            <VmoButton primary content="Reset Password" onClick={resetEmail} />
          </VmoModalActions>
        )}
      </VmoModal>
    )
  }

  useEffect(() => {
    if (loginUserDetails && loginUserDetails.isLoginConfirm) {
      setMultipleLogin(true)
    }
  }, [loginUserDetails])

  useEffect(() => {
    if (successLabels.includes(LOGIN_USER)) {
      if (!loginUserDetails.message) {
        // dispatch(getRole(get(['user', 'roleId'], loginUserDetails, '')))
        setUserDataInLocatStorage(loginUserDetails)
        setMultipleLogin(false)
        dispatch(currentSubscription())
        dispatch(getUserRolePermissions())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successLabels])

  const getUrl = service => {
    if (subdomain && subdomain[1]) {
      return `https://${subdomain[1]}.kooperhq.com/${service}/signin`
    }
    return `https://${window.localStorage.getItem('domain')}.kooperhq.com/${service}/signin`
  }

  if (type.includes(LOGIN_USER)) {
    return <FullPageLoader />
  }

  return (
    <VmoContainer style={{background: '#f9fbfd', width: '100%', margin: 0}}>
      <div className="signInPage">
        <div className="signin-portal">
          <VmoButton className="basic-fill-btn" onClick={() => window.open(getUrl('employee-portal'))}>
            Employee Portal
          </VmoButton>
          <VmoButton className="basic-fill-btn" onClick={() => window.open(getUrl('client-portal'))}>
            Client Portal
          </VmoButton>
        </div>

        <VmoImage src={logo} className="kooper-logo" />
        <div className="signInBody">
          <VmoCard className="loginCard">
            <VmoCardContent>
              <h1>Sign in to {process.env.REACT_APP_NAME} Admin Dashboard</h1>
              <p>Enter your Sign-In Credentials.</p>
              <VmoForm className="errorLabel" size="large" onSubmit={handleSubmit(onSignIn)}>
                <Controller
                  name="email"
                  render={({value, onChange}) => (
                    <VmoFormInput
                      autoFocus
                      fluid
                      icon={<SvgIcon path="common/mail" />}
                      iconPosition="left"
                      placeholder="Email address"
                      error={
                        errors.email && {
                          content: removeDoubleQuotes(errors.email.message),
                        }
                      }
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleSubmit(onSignIn)()
                        }
                      }}
                    />
                  )}
                  control={control}
                />
                <Controller
                  name="password"
                  render={({value, onChange}) => (
                    <VmoFormInput
                      fluid
                      type={!showPwd ? 'password' : 'text'}
                      icon={<SvgIcon path="common/lock" />}
                      iconPosition="left"
                      placeholder="password"
                      error={
                        errors.password && {
                          content: removeDoubleQuotes(errors.password.message),
                        }
                      }
                      value={value}
                      onChange={e => {
                        onChange(e.target.value)
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                          handleSubmit(onSignIn)()
                        }
                      }}
                      action={{
                        // color: errors.password ? "red" : "basic",
                        color: 'basic',
                        icon: !showPwd ? 'eye slash' : 'eye',
                        onClick: e => {
                          e.preventDefault()
                          setShowPwd(!showPwd)
                        },
                      }}
                    />
                  )}
                  control={control}
                />

                <VmoButton primary type="submit" fluid size="large">
                  Sign In
                </VmoButton>
              </VmoForm>
              <div className="remMe">
                <VmoCheckbox label="Remember me" checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} />
                <span onClick={() => setForgotPasswordModal(true)}>Forgot Password?</span>
                {forgotPasswordModal && RenderForgotPassword()}
                {multipleLogin && RenderMultipleLoginDetected()}
              </div>
            </VmoCardContent>
          </VmoCard>
        </div>
        <p className="kooper-lead mt-4">
          Don't have an account?
          <Link to="/signup"> Sign up</Link>
        </p>
      </div>
    </VmoContainer>
  )
}

export default Signin
