import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import _ from 'lodash'
import {VmoButton, VmoGridColumn, VmoModal, VmoModalContent, VmoModalHeader} from 'vmo-library'

import {
  calculateBilling,
  currentSubscription,
  getKooperPlans,
  invoiceListing,
  refreshAccessToken,
} from 'actions/billing'
import SettingLayout from 'layouts/settingLayout'
import {CURRENT_SUBSCRIPTION, REFRESH_ACCESS_TOKEN, START_BILLING, UPDATE_BILLING} from 'actions/types'
import {setAppToken} from 'utils/local-storage'
import {get, getFormattedDate} from 'utils/helper'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PlanStep1 from './PlanStep1'
import PlanStep2 from './PlanStep2'
import PlanStep3 from './PlanStep3'
import CheckoutModal from './CheckoutModal'

const MyPlan = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [step, setStep] = useState('step1')
  const [paymentData, setPaymentData] = useState({
    interval: 'monthly',
    quantity: 5,
    plan: 'ultimateMonthly',
    addons: [],
    coupon: '',
  })

  const [paymentConfirmation, setPaymentConfirmation] = useState(false)
  const [paymentSuccessfull, setPaymentSuccessfull] = useState(false)
  const [addressValidation, setAddressValidation] = useState(false)
  const [redirectionTimer, setRedirectionTimer] = useState(5)
  const [couponError, setCouponError] = useState(false)

  const listSteps = ['step1', 'step2', 'step3']

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {
    // currentSubscriptionData = {},
    getListOfInvoiceData = [],
    refreshAccessTokenData = {},
  } = useSelector(state => state.billing)

  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const myPlanLearnMore = settingsLearnMoreUrl?.billing?.myPlan?.learnMore

  const {data: currentSubscriptionData = {}} = useApiResponse({
    action: currentSubscription,
    enabled: true,
    label: CURRENT_SUBSCRIPTION,
    storePath: 'billing.currentSubscriptionData',
    onSuccess: (currentSubscriptionData = {}) => {
      setPaymentData({
        plan: currentSubscriptionData?.subscription?.activePlan,
        quantity: currentSubscriptionData?.subscription?.quantity,
        addons: currentSubscriptionData?.subscription?.addons,
        interval: currentSubscriptionData?.subscription?.interval,
      })
    },
  })

  useEffect(() => {
    dispatch(getKooperPlans())
  }, [dispatch])

  useEffect(() => {
    if (paymentData.quantity) {
      dispatch(calculateBilling(paymentData))
    }
  }, [paymentData, dispatch])

  useEffect(() => {
    if (successLabels.includes(START_BILLING) || successLabels.includes(UPDATE_BILLING)) {
      setPaymentConfirmation(false)
      setPaymentSuccessfull(true)
      setStep('step1')
      dispatch(invoiceListing())
      dispatch(refreshAccessToken())
    }
  }, [successLabels, dispatch, currentSubscriptionData])

  useEffect(() => {
    if (successLabels.includes(REFRESH_ACCESS_TOKEN)) {
      setAppToken(get(['token'], refreshAccessTokenData))
      dispatch(currentSubscription())
    }
  }, [successLabels, refreshAccessTokenData, dispatch])

  const head = () => {
    if (step === 'step1') {
      return 'My Plan'
    }
    if (step === 'step2') {
      return 'Plan Information'
    }
    if (step === 'step3') {
      return 'Billing Information'
    }
    return null
  }

  const setStepsNavigation = nav => {
    const currentPageIndex = listSteps.findIndex(s => s === step)

    if (nav === 'back') {
      if (currentPageIndex > 0) {
        setStep(listSteps[currentPageIndex - 1])
      }
    }
    if (nav === 'forward') {
      if (currentPageIndex < listSteps.length - 1) {
        setStep(listSteps[currentPageIndex + 1])
      }
    }
  }

  const InvoiceCounter = () => {
    if (redirectionTimer === 0 || redirectionTimer > 0) {
      if (redirectionTimer > 0) {
        setTimeout(() => {
          setRedirectionTimer(redirectionTimer - 1)
        }, 1000)
      } else if (redirectionTimer === 0) {
        const Id = _.head(getListOfInvoiceData)?.id
        history.push({
          pathname: `/settings/invoices/${Id}`,
        })
      }
    }
    return redirectionTimer
  }

  const paymentSuccessfullDisplay = () => {
    if (get(['subscriptionSchedule'], currentSubscriptionData)) {
      return `Your next payment is now due on : ${getFormattedDate(
        get(['subscriptionSchedule', 'startAt'], currentSubscriptionData)
      )}`
    }
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/my-plan" />}
      header={head()}
      subHeader="Choose from a variety of affordable plans according to your requirements. We promise the best of the service in return."
      learnMoreUrl={myPlanLearnMore}
      table={false}
      headerDivider
      fullWidth
    >
      {step === 'step1' && <PlanStep1 setStep={setStep} paymentData={paymentData} setPaymentData={setPaymentData} />}
      {step === 'step2' && (
        <PlanStep2
          paymentData={paymentData}
          setPaymentData={setPaymentData}
          couponError={couponError}
          setCouponError={setCouponError}
        />
      )}
      {step === 'step3' && <PlanStep3 setAddressValidation={setAddressValidation} />}

      {step !== 'step1' && (
        <VmoGridColumn width={16} className="mt-4">
          <VmoButton className="mr-3" basic onClick={() => setStepsNavigation('back')}>
            Back to Plan
          </VmoButton>
          <VmoButton
            primary
            disabled={
              paymentData.quantity === '0' || paymentData.quantity === '' || couponError || step === 'step3'
                ? !addressValidation
                : ''
            }
            onClick={() => {
              if (step === 'step3') {
                setPaymentConfirmation(true)
              } else {
                setStepsNavigation('forward')
              }
            }}
          >
            {step === 'step3' ? 'Proceed to checkout' : 'Next'}
          </VmoButton>
        </VmoGridColumn>
      )}

      {paymentSuccessfull && (
        <VmoModal
          size="small"
          open={paymentSuccessfull}
          onClose={() => setPaymentSuccessfull(false)}
          closeIcon={<SvgIcon path="common/close" className="closeIcon" />}
        >
          <VmoModalHeader>Thank You !!!</VmoModalHeader>
          <VmoModalContent>
            <h4>
              Thank You for Subcribing with Kooper. You will be redirected to invoice copy in{' '}
              {paymentSuccessfull && InvoiceCounter()} seconds
            </h4>
            <p>{paymentSuccessfullDisplay()}</p>
          </VmoModalContent>
        </VmoModal>
      )}

      {paymentConfirmation && (
        <CheckoutModal isOpen={paymentConfirmation} setIsOpen={setPaymentConfirmation} paymentData={paymentData} />
      )}
    </SettingLayout>
  )
}

export default MyPlan
