import React, {useEffect, useState} from 'react'
import CreateFirst from 'components/common/CreateFirst'
import {VmoTab, VmoTabPane} from 'vmo-library'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectFinanceBudget, getProjectsLookup, getProjectsTasksList} from 'actions/projects'
import {CREATE_PROJECT_FINANCE_BUDGET} from 'actions/types'
import useApiResponse from 'hooks/impure/useApiResponse'
import CreateProjectBudgetModal from './CreateProjectBudgetModal'
import ProjectBudgetExpenses from './Expenses/ProjectBudgetExpenses'
import ProjectBudgetNotifications from './Notifications/ProjectBudgetNotifications'
import ProjectBudgetTimeLog from './TimeLogs/ProjectBudgetTimeLog'
import ProjectBudgetTasklistBudget from './TasklistBudgets/ProjectBudgetTasklistBudget'

const ProjectBudgets = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [isCreateBudgetModalOpen, setIsCreateBudgetModalOpen] = useState(false)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {isBudgetCreated = false} = useSelector(state => state.projects)

  const {data: tasksList = []} = useApiResponse({
    action: getProjectsTasksList,
    payload: projectId,
    dependency: [projectId],
    enabled: projectId,
    storePath: 'projects.tasksList',
  })

  useEffect(() => {
    dispatch(getProjectsLookup({lookup: ['timesheets']}))
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(CREATE_PROJECT_FINANCE_BUDGET)) {
      setIsCreateBudgetModalOpen(false)
      dispatch(getProjectFinanceBudget(projectId))
    }
  }, [successLabels, dispatch, projectId])

  const renderBudgetModal = () => {
    if (isCreateBudgetModalOpen) {
      return (
        <CreateProjectBudgetModal
          open={isCreateBudgetModalOpen}
          toggle={value => setIsCreateBudgetModalOpen(value)}
          projectId={projectId}
        />
      )
    }
  }

  const panes = [
    ...(tasksList.length
      ? [
          {
            menuItem: {
              key: 'tasklistBudgets',
              icon: 'tasks',
              content: 'Task list budgets',
            },
            render: () => (
              <VmoTabPane attached={false}>
                <ProjectBudgetTasklistBudget projectId={projectId} />
              </VmoTabPane>
            ),
          },
        ]
      : []),
    {
      menuItem: {key: 'expenses', icon: 'money bill alternate', content: 'Expenses'},
      render: () => (
        <VmoTabPane attached={false}>
          <ProjectBudgetExpenses projectId={projectId} budgetId={budgetId} />
        </VmoTabPane>
      ),
    },
    {
      menuItem: {key: 'notifications', icon: 'bullhorn', content: 'Notifications'},
      render: () => (
        <VmoTabPane attached={false}>
          <ProjectBudgetNotifications />
        </VmoTabPane>
      ),
    },
    {
      menuItem: {key: 'timeLogs', icon: 'clock', content: 'Time Logs'},
      render: () => (
        <VmoTabPane attached={false}>
          <ProjectBudgetTimeLog projectId={projectId} />
        </VmoTabPane>
      ),
    },
  ]

  if (!isBudgetCreated) {
    return (
      <div className="kooper-side-wrap">
        <CreateFirst
          header="Budgets"
          subHeader="Project managers can create, track, and monitor budgets for their projects with ease."
          addFirst={() => setIsCreateBudgetModalOpen(true)}
          buttonText="Create Budget"
          Feature1="Budget Creation"
          Feature2="Budget Limit"
          Feature3="Multiple currencies"
          list1="Create detailed project budgets with line items for expenses"
          list2="Set budget limits to prevent cost overruns"
          list3="Support multiple currencies for global projects"
        />
        {renderBudgetModal()}
      </div>
    )
  }

  return (
    <div className="kooper-side-wrap pt-0">
      <VmoTab panes={panes} menu={{color: 'blue', secondary: true, pointing: true}} />
    </div>
  )
}

export default ProjectBudgets
