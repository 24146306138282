import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  VmoButton,
  VmoHeader,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
  VmoTooltip,
} from 'vmo-library'
import {DELETE_QUOTE_PREFIX, GET_QUOTE_PREFIX} from 'actions/types'
import DeleteModal from 'components/common/DeleteModal'
import {deleteQuotePrefix, getQuotePrefixes} from 'actions/quotes'
import SettingLayout from 'layouts/settingLayout'
import {getPermissionTooltipContent, startCase} from 'utils/helper'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import useLearnMoreUrl from 'hooks/pure/useLearnMoreUrl'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'
import PrefixModal from './PrefixModal'

function PrefixList() {
  const dispatch = useDispatch()

  const [prefixModal, setPrefixModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [prefixId, setPrefixId] = useState()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {settingsLearnMoreUrl} = useLearnMoreUrl()
  const quotesLearnMore = settingsLearnMoreUrl?.dataManagement?.quotes?.learnMore

  const {salesAndCrmAccess, quotesPermissions} = useUserPermissions()
  const quotesAccess = salesAndCrmAccess?.quotes
  const manageQuoteSettingsPermissions = quotesPermissions?.manage_quote_settings

  const {data: prefixList = []} = useApiResponse({
    action: getQuotePrefixes,
    dependency: [quotesAccess],
    enabled: quotesAccess,
    label: GET_QUOTE_PREFIX,
    storePath: 'quotes.prefixList',
  })

  useEffect(() => {
    if (successLabels.includes(DELETE_QUOTE_PREFIX)) {
      dispatch(getQuotePrefixes())
    }
  }, [successLabels, dispatch])

  // if (prefixList.length === 0) {
  //   return (
  //     <div className="setEmpty-wrap">
  //       <CreateFirst
  //         src={<FaEnvelope />}
  //         Feature1="Easy setup and managing"
  //         Feature2="Fully customisable email editor to make it User friendly"
  //         Feature3="Avoid mistakes related to typing"
  //         list1="Simple and Easy setup and management can be done efficiently"
  //         list2="With many customisations it is possible to create the template as per the requirement"
  //         list3="Creating template will reduce the chances of having a spelling or typing mistakes"
  //         addFirst={() => setPrefixModal(true)}
  //         buttonText="Add Templates"
  //         img={img}
  //         header="Prefix list"
  //         subHeader="Templates are pre-written set of emails which are frequently needed for daily interaction with customers, those can be configured so that the agent can operate faster and solve customer ticket with more efficiency by eliminating repetition"
  //       />
  //     </div>
  //   )
  // }

  if (prefixList.length === 0 || !quotesAccess) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Quote Prefix"
          subHeader="Add prefix for your quote-id which you can use to crate quote ids."
          buttonText="Create Quote Prefix"
          addFirst={() => quotesAccess && manageQuoteSettingsPermissions && setPrefixModal(true)}
          img={create}
          tooltip={!quotesAccess || !manageQuoteSettingsPermissions}
          tooltipContent={getPermissionTooltipContent({
            featureAccess: quotesAccess,
            roleAccess: manageQuoteSettingsPermissions,
          })}
        />
        {prefixModal && <PrefixModal open={prefixModal} toggle={() => setPrefixModal(false)} />}
      </div>
    )
  }

  return (
    <SettingLayout
      icon={<SvgIcon path="settings/quotes" />}
      header="Quote Prefix"
      subHeader="Create customizable quote prefix to streamline quoting process"
      learnMoreUrl={quotesLearnMore}
      headerButton={{
        onClick: () => manageQuoteSettingsPermissions && setPrefixModal(true),
        content: 'Create Quote Prefix',
      }}
      table
      headerDivider
      lockRole={!manageQuoteSettingsPermissions}
    >
      <VmoTable selectable basic>
        <VmoTableHeader>
          <VmoTableRow>
            <VmoTableHeaderCell className="pl-4">#</VmoTableHeaderCell>
            <VmoTableHeaderCell>Name</VmoTableHeaderCell>
            <VmoTableHeaderCell>Action</VmoTableHeaderCell>
          </VmoTableRow>
        </VmoTableHeader>
        <VmoTableBody>
          {!prefixList.length && (
            <tr>
              <td colSpan="6" className="emptyValue">
                No Data Available
              </td>
            </tr>
          )}

          {prefixList.map(({prefix, id}, index) => (
            <VmoTableRow key={id} className="tableLink">
              <VmoTableCell className="pl-4">{index + 1}</VmoTableCell>
              <VmoTableCell>
                <VmoHeader as="h5">{startCase(prefix)}</VmoHeader>
              </VmoTableCell>
              <VmoTableCell>
                <VmoTooltip
                  content={
                    manageQuoteSettingsPermissions
                      ? 'Delete'
                      : getPermissionTooltipContent({roleAccess: manageQuoteSettingsPermissions})
                  }
                  size="mini"
                  position="top center"
                  trigger={
                    <VmoButton
                      icon
                      onClick={e => {
                        e.stopPropagation()
                        if (manageQuoteSettingsPermissions) {
                          setPrefixId(id)
                          setDeleteModal(true)
                        }
                      }}
                    >
                      <SvgIcon path="common/delete" />
                    </VmoButton>
                  }
                />
              </VmoTableCell>
            </VmoTableRow>
          ))}
        </VmoTableBody>
      </VmoTable>
      {prefixModal && <PrefixModal open={prefixModal} toggle={() => setPrefixModal(false)} />}
      {deleteModal && (
        <DeleteModal
          isModalOpen={deleteModal}
          setIsModalOpen={setDeleteModal}
          modalText="Are you sure you want to delete this Prefix?"
          deleteAction={deleteQuotePrefix}
          idTobeDeleted={prefixId}
          type={DELETE_QUOTE_PREFIX}
        />
      )}
    </SettingLayout>
  )
}

export default PrefixList
