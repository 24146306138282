import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {FiChevronRight} from 'react-icons/fi'
import SvgIcon from 'components/common/SvgIcon'
import {VmoAccordion, VmoAccordionContent, VmoAccordionTitle, VmoMenu, VmoMenuItem} from 'vmo-library'
import {isActiveNavlink} from 'utils/helper'

const ProjectMenu = () => {
  const history = useHistory()
  const [expand, setExpand] = useState([])

  const menus = [
    {
      key: 'projects',
      title: 'Projects',
      items: [
        {
          path: '/projects',
          title: 'All Projects',
          icon: <SvgIcon path="navigation/projects" />,
          isExact: true,
        },
        {
          // path: '/projects/default',
          title: 'Default',
          icon: <SvgIcon path="projects/default" />,
        },
        {
          // path: '/projects/fields',
          title: 'Fields',
          icon: <SvgIcon path="projects/fields" />,
        },
        {
          // path: '/projects/import',
          title: 'Import',
          icon: <SvgIcon path="projects/import" />,
        },
      ],
    },
    {
      key: 'templates',
      title: 'Templates',
      path: '/projects/templates',
      icon: <SvgIcon path="projects/templates" />,
      isExact: true,
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <VmoMenu vertical secondary className="kooper-menu">
      {menus.map(({key, title, items, path, icon, isExact}) => (
        <React.Fragment key={key}>
          {items ? (
            <VmoAccordion className={key === 'projects' ? 'mt-0' : ''}>
              <VmoAccordionTitle style={{justifyContent: 'flex-start'}} onClick={() => handleMenuExpand(key)}>
                {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />} {title}
              </VmoAccordionTitle>
              <VmoAccordionContent active={isActive(key)}>
                {items.map(({path, title, icon, isExact}) => (
                  <VmoMenuItem key={path} active={isActiveNavlink(path, isExact)} onClick={() => history.push(path)}>
                    {icon} {title}
                  </VmoMenuItem>
                ))}
              </VmoAccordionContent>
            </VmoAccordion>
          ) : (
            <VmoMenuItem key={path} active={isActiveNavlink(path, isExact)} onClick={() => history.push(path)}>
              {icon} {title}
            </VmoMenuItem>
          )}
        </React.Fragment>
      ))}
    </VmoMenu>
  )
}

export default ProjectMenu
