import {
  ADD_INBOX,
  UPDATE_INBOX,
  GET_HOME_PAGE_DATA,
  SEND_MAIL,
  GET_CONVERSATION_DATA,
  GET_CONVERSATION,
  ADD_MESSAGE_TO_CONVERSATION,
  SEND_NOTE,
  MOVE_CONVERSATION_TO,
  PUT_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_LABEL,
  SNOOZE_CONVERSATION,
  UPDATE_CONVERSATION_AND_REFRESH,
  DELETE_MSG_OF_CONVERSATION,
  GET_SERACHED_CONVERSATION_DATA,
  MERGE_CONVERSATION,
  SPLIT_CONVERSATION,
  ATTACHMENT_GET,
  GET_USER_TEMPLATES,
  GET_SINGLE_TEMPLATE,
  TICKET_ID_LIST,
  MASS_ACTION,
  INBOX_COUNTS,
  NAVBAR_SEARCH,
  GET_STATUS_LIST,
  CREATE_TICKET,
  GET_SMS_CHANNEL_LIST,
  GET_SMS_SINGLE,
  UPDATE_SMS,
  DELETE_SMS,
  ADD_SMS_CHANNEL,
  GET_PHONE_NUMBER,
  GET_SHARED_SECRET_KEY,
  GET_CONTACT_DETAILS,
  EDIT_CONTACT,
  ADD_NOTE_CONTACTS,
  GET_CONTACT_LIST_ALL,
  EDIT_MESSAGE,
  DELETE_NOTE,
  CONVERSATION_ATTACHMENT_UPLOAD,
  UPDATE_CONVERSATION_IS_READ,
  EMAIL_ID_PAIRS,
  GET_MAIL_BOX,
  GET_ACTIVITY_FEED,
  ADD_GMAIL_INBOX,
  SEND_VERIFICATION_MAIL_INBOX,
  CONVERSATION_AUDIO_UPLOAD,
} from './types'
import {apiAction} from './api'
import * as endpoint from './endpoint'

// Get all Inbox
export function getAllMailInbox(data) {
  return apiAction({
    url: endpoint.INBOXES_ENDPOINT,
    method: 'GET',
    label: 'GET_ALL_MAIL_INBOX',
    params: data,
  })
}

//
export function getEmailIdPairs() {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/emailidpairs `,
    method: 'GET',
    label: EMAIL_ID_PAIRS,
  })
}

export function getMailBox(data) {
  return apiAction({
    url: `${endpoint.MAILBOXS_ENDPOINT}/${data}`,
    method: 'GET',
    label: GET_MAIL_BOX,
  })
}

// Add Inbox
export function addInbox(data) {
  return apiAction({
    url: endpoint.INBOXES_ENDPOINT,
    method: 'POST',
    label: ADD_INBOX,
    data,
    showToast: true,
    successMessage: 'Inbox Created',
  })
}

export function addGmailInbox(data) {
  return apiAction({
    url: `${endpoint.EMAIL_INTEGRATION_ENDPOINT}/gmail/connect`,
    method: 'POST',
    label: ADD_GMAIL_INBOX,
    data,
  })
}

// Update Inbox
export function updateInbox(data, id) {
  return apiAction({
    url: `${endpoint.INBOXES_ENDPOINT}/${id}/settings`,
    method: 'PUT',
    label: UPDATE_INBOX,
    data,
    showToast: true,
    successMessage: 'Inbox Updated',
  })
}

// Delete Inbox
export function deleteInbox(data) {
  return apiAction({
    url: `${endpoint.INBOXES_ENDPOINT}/${data}`,
    method: 'DELETE',
    label: 'DELETE_INBOX',
    showToast: true,
    successMessage: 'Inbox Deleted',
  })
}

export function createTicket(data) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/ticket`,
    method: 'POST',
    label: CREATE_TICKET,
    data,
    showToast: true,
    successMessage: 'Ticket Created',
  })
}

export function assignInbox(data) {
  return apiAction({
    url: `${endpoint.INBOXES_ENDPOINT}/assign`,
    method: 'POST',
    label: 'ASSIGN_INBOX',
    data,
  })
}

export function sendVerificationMailInbox(id) {
  return apiAction({
    url: `${endpoint.INBOXES_ENDPOINT}/${id}/sendverificationmail`,
    method: 'POST',
    label: SEND_VERIFICATION_MAIL_INBOX,
  })
}

export function getHomePageData(data) {
  return apiAction({
    url: endpoint.KOOPER_ACCOUNT_V1,
    method: 'GET',
    label: GET_HOME_PAGE_DATA,
    data,
  })
}

export function sendEmail(data, toastMsg) {
  return apiAction({
    url: endpoint.CONVERSATIONS_ENDPOINT,
    method: 'POST',
    label: SEND_MAIL,
    data,
    showToast: true,
    successMessage: toastMsg || 'Email Sent',
  })
}

export function sendNote(data) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/notes`,
    method: 'POST',
    label: SEND_NOTE,
    data,
    showToast: true,
    successMessage: 'Note Added',
  })
}

export function getTicketIds(data) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/ticketIds/all`,
    method: 'GET',
    label: TICKET_ID_LIST,
    data,
  })
}

export function addMessageToConversation(data, conversationId, toastMsg) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/${conversationId}/messages`,
    method: 'POST',
    label: ADD_MESSAGE_TO_CONVERSATION,
    data,
    showToast: !!toastMsg,
    successMessage: toastMsg,
  })
}

export function deleteMessageOfConversation(conversationId, messageId) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/${conversationId}/messages/${messageId}`,
    method: 'DELETE',
    label: DELETE_MSG_OF_CONVERSATION,
    showToast: true,
    successMessage: 'Msg Deleted',
  })
}

export function moveConversationTo(data) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/inbox`,
    method: 'POST',
    label: MOVE_CONVERSATION_TO,
    data,
    showToast: true,
    successMessage: 'Conversation Moved',
  })
}

export function getMailBoxConversation(params) {
  const keys = Object.keys(params || {})

  const query = keys
    .filter(key => params[key])
    .map(key => `${key}=${params[key]}`)
    .join('&')

  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}?${query}`,
    method: 'GET',
    label: GET_CONVERSATION_DATA,
  })
}

export function getSearchedMailBoxConversation(data) {
  const keys = Object.keys(data)

  let url = `${endpoint.CONVERSATIONS_ENDPOINT}?`

  for (let i = 0; i < keys.length; i + 1) {
    const key = keys[i]
    if (i === 0) {
      url = `${url + key}=${data[key]}`
    } else {
      url = `${url}&${key}=${data[key]}`
    }
  }

  return apiAction({
    url,
    method: 'GET',
    label: GET_SERACHED_CONVERSATION_DATA,
  })
}

export function getConversation(id) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/${id}`

  return apiAction({
    url,
    method: 'GET',
    label: GET_CONVERSATION,
  })
}

export function updateConversationisRead(data) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/update`,
    method: 'POST',
    label: UPDATE_CONVERSATION_IS_READ,
    data,
  })
}

export function updateConversation(data, type) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/update`

  return apiAction({
    url,
    method: 'POST',
    label: PUT_CONVERSATION,
    data,
    showToast: !!type,
    successMessage: `${type} Updated`,
    successData: type,
  })
}

export function updateConversationPriority(data, type) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/priority`

  return apiAction({
    url,
    method: 'PUT',
    label: PUT_CONVERSATION,
    data,
    showToast: true,
    successMessage: `${type} Updated`,

    successData: type,
  })
}

export function updateConversationStatus(data, type) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/status`

  return apiAction({
    url,
    method: 'PUT',
    label: PUT_CONVERSATION,
    data,
    showToast: true,
    successMessage: `${type} Updated`,

    successData: type,
  })
}

// ---migration pending---//
export function permanentDeleteConversation(data) {
  const url = endpoint.CONVERSATIONS_ENDPOINT
  const params = data.conversationIds.join(',')
  return apiAction({
    url: `${url}/${params}`,
    method: 'DELETE',
    label: DELETE_CONVERSATION,
    showToast: true,
    successMessage: 'Conversation Deleted',
  })
}

export function addLabelToConversation(data) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/labels`

  return apiAction({
    url,
    method: 'POST',
    label: ADD_LABEL,
    data,
    showToast: true,
    successMessage: 'Label Added ',
  })
}

export function updateConversationAndRefresh(data, type) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/update`

  return apiAction({
    url,
    method: 'POST',
    label: UPDATE_CONVERSATION_AND_REFRESH,
    data,
    successData: type,
    showToast: !!type,
    successMessage: type,
  })
}

export function snoozeConversation(data, conversationId) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/${conversationId}/snooze`

  return apiAction({
    url,
    method: 'PUT',
    label: SNOOZE_CONVERSATION,
    data,
    showToast: true,
    successMessage: 'Snooze Updated',
  })
}

export function mergeConversation(data) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/merge`

  return apiAction({
    url,
    method: 'POST',
    label: MERGE_CONVERSATION,
    data,
    showToast: true,
    successMessage: 'Conversation Merged',
  })
}

export function splitConversation(data, conversationId) {
  const url = `${endpoint.CONVERSATIONS_ENDPOINT}/${conversationId}/split`

  return apiAction({
    url,
    method: 'POST',
    label: SPLIT_CONVERSATION,
    data,
    showToast: true,
    successMessage: 'Conversation splited',
  })
}

export function getAttachmentFromMail(id) {
  const url = `${endpoint.KOOPER_ATTACHMENTS}/${id}`

  return apiAction({
    url,
    method: 'GET',
    label: ATTACHMENT_GET,
    accept: 'text/html, application/xhtml+xml, application/xml;q=0.9, image/webp, */*;q=0.8',
  })
}

export function getUserTemplates() {
  const url = `${endpoint.KOOPER_ACCOUNT_V1}/inbox-general/templates`

  return apiAction({
    url,
    method: 'GET',
    label: GET_USER_TEMPLATES,
  })
}

export function singleUserTemplates(id, conversationId) {
  const params = conversationId ? `?conversationId=${conversationId}` : ''

  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/inbox-general/templates/${id}${params}`,
    method: 'GET',
    label: GET_SINGLE_TEMPLATE,
  })
}

export function massAction(data, toastMsg) {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/massaction`,
    method: 'PUT',
    label: MASS_ACTION,
    showToast: true,
    successMessage: toastMsg || 'Mass Updated',
    data,
  })
}

export function inboxCounts() {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/counts`,
    method: 'GET',
    label: INBOX_COUNTS,
  })
}

export function navbarSearch(value) {
  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/search?text=${value}`,
    method: 'GET',
    label: NAVBAR_SEARCH,
  })
}

export function getStatusList() {
  return apiAction({
    url: `${endpoint.CONVERSATIONS_ENDPOINT}/statuses`,
    method: 'GET',
    label: GET_STATUS_LIST,
  })
}

export function getSMSList() {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms`,
    method: 'GET',
    label: GET_SMS_CHANNEL_LIST,
  })
}

export function getSMSSingle(id) {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms/${id}`,
    method: 'GET',
    label: GET_SMS_SINGLE,
  })
}

export function updateSMS(id, data) {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms/${id}`,
    method: 'PUT',
    label: UPDATE_SMS,
    data,
    successMessage: 'SMS Channel Updated',
  })
}

export function deleteSMS(id) {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/${id}`,
    method: 'DELETE',
    label: DELETE_SMS,
    successMessage: 'SMS Channel Deleted',
  })
}

export function addSmsChannel(data) {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms`,
    method: 'POST',
    label: ADD_SMS_CHANNEL,
    data,
    showToast: true,
    successMessage: 'SMS Channel Added',
  })
}

export function getPhoneNumberSMS(data) {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms/incomingphonenumber`,
    method: 'GET',
    label: GET_PHONE_NUMBER,
    params: data,
    showToast: true,
  })
}

export function getSharedSecretKey() {
  return apiAction({
    url: `${endpoint.KOOPER_CHANNEL_V1}/sms/sharedsecret`,
    method: 'GET',
    label: GET_SHARED_SECRET_KEY,
    showToast: true,
  })
}

export function getContactDetails(id) {
  return apiAction({
    url: `${endpoint.CONTACTS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_CONTACT_DETAILS,
  })
}

export function editContact(data, id) {
  return apiAction({
    url: `${endpoint.CONTACTS_ENDPOINT}/${id}`,
    method: 'PUT',
    label: EDIT_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contact Updated',
  })
}

export function addNoteInContacts(id, data) {
  return apiAction({
    url: `${endpoint.CONTACTS_ENDPOINT}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_CONTACTS,
    data,
    showToast: true,
    successMessage: 'Note added',
  })
}

export function getContactListAll() {
  return apiAction({
    url: `${endpoint.CONTACTS_ENDPOINT}/all`,
    method: 'GET',
    label: GET_CONTACT_LIST_ALL,
  })
}

// Chat Inbox

export function editMessage(Id, data) {
  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/conversations/messages/${Id}`,
    method: 'PUT',
    label: EDIT_MESSAGE,
    data,
    showToast: true,
    successMessage: 'Message Edited',
  })
}

export function deleteNote(id) {
  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/conversations/notes/${id}`,
    method: 'DELETE',
    label: DELETE_NOTE,
    showToast: true,
    successMessage: 'Note Deleted',
  })
}

export function uploadChatAttachment(data) {
  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/conversations/fileUpload`,
    method: 'POST',
    label: CONVERSATION_ATTACHMENT_UPLOAD,
    contentType: 'multipart/form-data',
    data,
  })
}

export function audioUpload(data) {
  return apiAction({
    url: `${endpoint.KOOPER_ACCOUNT_V1}/conversations/audioUpload`,
    method: 'POST',
    label: CONVERSATION_AUDIO_UPLOAD,
    data,
  })
}

export function getActivityFeed(data) {
  return apiAction({
    url: `${endpoint.SETTINGS_ENDPOINT}/activities/livefeed`,
    method: 'POST',
    label: GET_ACTIVITY_FEED,
    data,
  })
}
