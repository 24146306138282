import {apiAction} from './api'
import {ANALYTICS_ENDPOINT} from './endpoint'
import {EMAIL_TEMPLATE, TEMPLATE_OVER_TIME} from './types'

export function templateOverTime(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/templates/tempaltesovertime`,
    method: 'POST',
    label: TEMPLATE_OVER_TIME,
    data,
  })
}

export function emailTemplate(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/templates/emailtemplates`,
    method: 'POST',
    label: EMAIL_TEMPLATE,
    data,
  })
}
