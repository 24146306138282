import React from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {VmoButton, VmoDropdown, VmoFormField, VmoFormInput, VmoIcon, VmoTooltip} from 'vmo-library'
import {phoneOpition} from 'constants/variables'
import SvgIcon from './SvgIcon'

const Phone = props => {
  const dispatch = useDispatch()

  const {
    id,
    deletePhone,
    refForm,
    control,
    Controller,
    errors,
    phoneFields,
    appendPhone,
    removePhone,
    disabled = false,
  } = props
  return phoneFields.map((item, index) => {
    return (
      <div className="mutiple" key={item.id}>
        <VmoFormField className={`${index !== 0 ? 'mt-3' : ''} input-customization`}>
          <div className="hover-plusicon d-flex">
            <Controller
              control={control}
              name={`phones[${index}].category`}
              defaultValue={item.category}
              render={({onChange, value, onBlur}) => (
                <div className="mailActivity">
                  <VmoDropdown
                    className="email-component"
                    icon="chevron down"
                    button
                    placeholder="select category"
                    options={phoneOpition}
                    disabled={disabled}
                    onChange={(e, {value}) => {
                      onChange(value)
                      refForm.current.handleSubmit()
                    }}
                    defaultValue={item.category}
                    error={
                      _.get(errors, `phones[${index}].category`) && {
                        content: _.get(errors, `phones[${index}].category.message`),
                      }
                    }
                  />
                </div>
              )}
            />
            <div className="d-flexb">
              {_.size(phoneFields) > 1 && (
                <div className="ShowAddBtn">
                  <VmoButton
                    style={{padding: '6px'}}
                    disabled={disabled}
                    onClick={() => {
                      if (phoneFields[index].id && phoneFields[index].category && phoneFields[index].phone) {
                        dispatch(deletePhone(id, phoneFields[index].id))
                      }
                      removePhone(index)
                    }}
                  >
                    <VmoTooltip
                      content="Delete Field"
                      size="mini"
                      position="left center"
                      trigger={<VmoIcon className="m-0" name="trash alternate outline" />}
                    />
                  </VmoButton>
                </div>
              )}

              {index === phoneFields.length - 1 && _.size(phoneFields) < 3 && (
                <div className="ShowAddBtn">
                  <VmoButton
                    style={{padding: '4px'}}
                    disabled={disabled}
                    onClick={() => {
                      appendPhone({
                        category: item.category,
                        phone: '',
                      })
                    }}
                  >
                    <VmoTooltip
                      content="Add Phone No"
                      size="mini"
                      position="left center"
                      trigger={<SvgIcon path="common/plus" />}
                    />
                  </VmoButton>
                </div>
              )}
            </div>
          </div>
          <Controller
            control={control}
            name={`phones[${index}].phone`}
            defaultValue={item.phone}
            render={({onChange, value, onBlur}) => (
              <VmoFormInput
                maxLength={20}
                defaultValue={item.phone}
                placeholder="Add phone"
                onChange={(e, {value}) => {
                  onChange(value)
                }}
                onBlur={e => {
                  onBlur(e)
                  refForm.current.handleSubmit()
                }}
                disabled={disabled}
                error={
                  _.get(errors, `phones[${index}].phone`) && {
                    content: 'Invalid Phone Number',
                  }
                }
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    e.target.blur()
                    e.preventDefault()
                    e.stopPropagation()
                  }
                }}
              />
            )}
          />
        </VmoFormField>
      </div>
    )
  })
}

export default Phone
