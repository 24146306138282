import {
  CREATE_CONTACT,
  GET_ALL_CONTACTS,
  GET_SINGLE_CONTACT,
  EDIT_CONTACT,
  ADD_NOTE_CONTACT,
  GET_ALL_CONTACT_NOTES,
  GET_ALL_ACTIVITIES_CONTACT,
  ADD_CONTACT_INTERACTION,
  CONTACT_STATUS_LIST,
  CONTACT_TYPE_LIST,
  DELETE_CONTACT,
  MULTIPLE_UPDATE_CONTACT,
  EXPORT_ALL_CONTACTS,
  SAMPLE_CONTACTS_IMPORT,
  IMPORT_CONTACTS,
  GET_COLUMNS_SELECTION_CONTACT,
  COLUMNS_SELECTION_CONTACT,
  CONTACT_ALL_COMPANIES,
  MULTIPLE_MERGE_CONTACT,
  ATTACHMENT_POST_CONTACT,
  GET_CONTACT_DETAILS,
  GET_ALL_CONTACTS_IDS,
} from 'actions/types'
import {apiAction} from './api'
import {CONTACTS_ENDPOINT, KOOPER_ACCOUNT_V1, COMPANIES_ENDPOINT} from './endpoint'

export function addContacts(data) {
  return apiAction({
    url: CONTACTS_ENDPOINT,
    method: 'POST',
    label: CREATE_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contact Created',
  })
}

export function getContactList(params = {}) {
  let query = ''

  if (typeof params === 'object') {
    const keys = Object.keys(params)
    query = `?${keys
      .filter(key => params[key])
      .map(key => `${key}=${params[key]}`)
      .join('&')}`
  }

  return apiAction({
    url: CONTACTS_ENDPOINT + query,
    method: 'GET',
    label: GET_ALL_CONTACTS,
  })
}

export function getAllContactsIds() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/all/ids`,
    mehod: 'GET',
    label: GET_ALL_CONTACTS_IDS,
  })
}

export function getSingleContact(id, userId = 'all') {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}?userId=${userId}`,
    method: 'GET',
    label: GET_SINGLE_CONTACT,
  })
}

export function editContact(formInfo, id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}`,
    method: 'PUT',
    label: EDIT_CONTACT,
    data: formInfo,
    showToast: true,
    successMessage: 'Contact updated',
  })
}

export function addNoteContact(id, data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/notes`,
    method: 'POST',
    label: ADD_NOTE_CONTACT,
    data,
    showToast: true,
    successMessage: 'Note Created',
  })
}

export function getAllContactNotes(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/notes`,
    method: 'GET',
    label: GET_ALL_CONTACT_NOTES,
  })
}

export function getAllActivitiesContact(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/activity`,
    method: 'GET',
    label: GET_ALL_ACTIVITIES_CONTACT,
  })
}

export function addInteractionContact(contactId, data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${contactId}/interactions`,
    method: 'POST',
    label: ADD_CONTACT_INTERACTION,
    data,
    showToast: true,
    successMessage: 'Interaction Created',
  })
}

export function getContactStatuses() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/statuses`,
    method: 'GET',
    label: CONTACT_STATUS_LIST,
  })
}

export function getContactType() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/types`,
    method: 'GET',
    label: CONTACT_TYPE_LIST,
  })
}

export function deleteContact(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}`,
    method: 'DELETE',
    label: DELETE_CONTACT,
    showToast: true,
    successMessage: 'Contact Deleted',
  })
}

export function contactMultipleUpdate(data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/multiple`,
    method: 'PUT',
    label: MULTIPLE_UPDATE_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contacts Updated',
  })
}

export function exportAllContacts() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/export`,
    method: 'GET',
    label: EXPORT_ALL_CONTACTS,
  })
}

export function sampleContactImportFile() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/import/sample`,
    label: SAMPLE_CONTACTS_IMPORT,
    method: 'GET',
  })
}

export function importContacts(data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/import`,
    label: IMPORT_CONTACTS,
    method: 'POST',
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'Contacts Imported',
  })
}

export function columnsSelectionContact(activeCol) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/columns`,
    method: 'PUT',
    label: COLUMNS_SELECTION_CONTACT,
    data: activeCol,
    showToast: true,
    successMessage: 'Columns updated',
  })
}

export function getColumnsSelectionContact() {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/columns`,
    method: 'GET',
    label: GET_COLUMNS_SELECTION_CONTACT,
  })
}

export function getContactDetails(id) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}`,
    method: 'GET',
    label: GET_CONTACT_DETAILS,
  })
}

export function getContactCompanyList() {
  return apiAction({
    url: `${COMPANIES_ENDPOINT}/all/ids`,
    method: 'GET',
    label: CONTACT_ALL_COMPANIES,
  })
}

export function multipleMergeContact(data) {
  return apiAction({
    url: `${KOOPER_ACCOUNT_V1}/contacts/merge`,
    method: 'POST',
    label: MULTIPLE_MERGE_CONTACT,
    data,
    showToast: true,
    successMessage: 'Contacts merged',
  })
}
export function uploadAttachmentsContact(id, data) {
  return apiAction({
    url: `${CONTACTS_ENDPOINT}/${id}/attachments`,
    method: 'POST',
    label: ATTACHMENT_POST_CONTACT,
    data,
    contentType: 'multipart/form-data',
    showToast: true,
    successMessage: 'File Uploaded',
  })
}
