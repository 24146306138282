import React, {useState} from 'react'
import {VmoMenu, VmoMenuItem, VmoAccordion, VmoAccordionTitle, VmoAccordionContent} from 'vmo-library'
import {useHistory, useRouteMatch} from 'react-router-dom'
import {FiChevronRight, FiLayers} from 'react-icons/fi'
import SvgIcon from 'components/common/SvgIcon'
import {isActiveNavlink} from 'utils/helper'

const InvoicesMenu = () => {
  const history = useHistory()
  const {url} = useRouteMatch()

  const [expand, setExpand] = useState([])

  const menus = [
    {
      key: 'settings',
      title: 'Settings',
      items: [
        {
          path: '/prefix',
          title: 'Prefix',
          icon: <SvgIcon path="finance/prefix" />,
        },
      ],
    },
  ]

  const handleMenuExpand = key => {
    const menus = [...expand]
    if (menus.includes(key)) {
      menus.splice(menus.indexOf(key), 1)
      setExpand(menus)
    } else {
      setExpand([...menus, key])
    }
  }

  const isActive = key => !expand.includes(key)

  return (
    <VmoMenu vertical secondary className="kooper-menu">
      <VmoMenuItem active={isActiveNavlink('/invoices/all')} onClick={() => history.push('/invoices/all')}>
        <FiLayers /> All Invoices
      </VmoMenuItem>

      {menus.map(({title, key, items}) => (
        <VmoAccordion key={key}>
          <VmoAccordionTitle onClick={() => handleMenuExpand(key)}>
            {title}
            {isActive(key) ? <SvgIcon path="common/down" /> : <FiChevronRight />}
          </VmoAccordionTitle>
          <VmoAccordionContent active={isActive(key)}>
            {items.map(({path, title, icon}) => (
              <VmoMenuItem
                key={path}
                active={isActiveNavlink(`${url}${path}`)}
                onClick={() => history.push(`${url}${path}`)}
              >
                {icon} {title}
              </VmoMenuItem>
            ))}
          </VmoAccordionContent>
        </VmoAccordion>
      ))}
    </VmoMenu>
  )
}

export default InvoicesMenu
