import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CAMPAIGN_STATES_ANALYTICS, GET_CAMPAIGN_LIST} from 'actions/types'
import {getCampaignList} from 'actions/campaign'
import {startCase} from 'utils/helper'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import SvgIcon from 'components/common/SvgIcon'
import {CAMPAIGN_TYPES} from 'constants/enums'
import Campaign from './Campaign'

const initialCampaignStatus = [
  {name: 'totalSent', label: 'Total Sent'},
  {name: 'uniqueVisitors', label: 'Unique Contacts'},
  {name: 'totalRead', label: 'Total Read'},
  {name: 'engagementRate', label: 'Open Rate'},
]

const Email = () => {
  const dispatch = useDispatch()

  const [campaignStatus, setCampaignStatus] = useState(initialCampaignStatus)
  const [campaignIsAvailabel, setCampaignIsAvailabel] = useState(false)

  const {successLabels} = useSelector(state => state.apiReducer)
  const {campaignList = []} = useSelector(state => state.campaign)
  const {campaignAnalyticsStatus = {}} = useSelector(state => state.analyticsCampaign)
  const {analyticsAccess, reportsPermissions} = useUserPermissions()
  const campaignsAccess = analyticsAccess?.campaigns
  const campaignReportsPermissions = reportsPermissions?.campaign_reports

  useEffect(() => {
    if (campaignsAccess && campaignReportsPermissions) {
      dispatch(getCampaignList())
    } else {
      setCampaignIsAvailabel(false)
      dispatch({type: 'CLEAR_ANALYTICS_CAMPAIGN'})
      setCampaignStatus(initialCampaignStatus)
    }
  }, [campaignReportsPermissions, dispatch, campaignsAccess])

  useEffect(() => {
    if (successLabels.includes(GET_CAMPAIGN_LIST)) {
      if (campaignList.some(campaign => campaign.type === CAMPAIGN_TYPES.EMAIL)) {
        setCampaignIsAvailabel(true)
      } else {
        setCampaignIsAvailabel(false)
        dispatch({type: 'CLEAR_ANALYTICS_CAMPAIGN'})
        setCampaignStatus(initialCampaignStatus)
      }
    }
  }, [dispatch, campaignList, successLabels])

  useEffect(() => {
    if (successLabels.includes(CAMPAIGN_STATES_ANALYTICS) && Object.keys(campaignAnalyticsStatus).length > 0) {
      const temp = []
      for (const [key, val] of Object.entries(campaignAnalyticsStatus)) {
        if (key !== 'totalReplied') {
          if (key === 'uniqueVisitors') temp.push({name: key, label: 'Unique Contacts', ...val})
          else if (key === 'engagementRate') temp.push({name: key, label: 'Open Rate', ...val})
          else temp.push({name: key, label: startCase(key), ...val})
        }
      }
      setCampaignStatus(temp)
    }
  }, [campaignAnalyticsStatus, successLabels])

  return (
    <Campaign
      headerLogo={<SvgIcon path="common/mail" />}
      header="Email"
      campaignType={CAMPAIGN_TYPES.EMAIL}
      campaignStatus={campaignStatus}
      lineChartHeader="Total Sent and Unique Contacts"
      campaignIsAvailabel={campaignIsAvailabel}
    />
  )
}

export default Email
