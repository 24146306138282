import React from 'react'
import {
  VmoGrid,
  VmoGridColumn,
  VmoImage,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableHeader,
  VmoTableHeaderCell,
  VmoTableRow,
} from 'vmo-library'
import {getFormattedDate} from 'utils/helper'
import SvgIcon from 'components/common/SvgIcon'

function BasicTemplate({data}) {
  const {
    setupData = {},
    buyerInfo: {contacts = [], company} = {},
    yourInfo = {},
    template = {},
    products = {},
    signature = {},
    preview,
  } = data

  const yourCompany = yourInfo?.company || {}

  return (
    <div className="quote-theme">
      <div className="quote-thm-wrap">
        <div className="quo-thm-one">
          <div className="quo-thm-one-header">
            <h5 className="title" style={{backgroundColor: template.color}}>
              {template.header}
            </h5>
            <div className="date">
              {setupData.quoteNo ? <p>ID : {setupData.quoteNo}</p> : null}
              {/* <MdFiberManualRecord /> */}
              <p>{getFormattedDate(new Date())}</p>
              {setupData?.validTill ? <span>Valid till : {getFormattedDate(setupData.validTill)}</span> : null}
            </div>
          </div>
          <div className="kooper">
            {preview && (
              <div className="logo">
                <VmoImage src={preview} />
              </div>
            )}
            <div className="com-address">
              <h2>{setupData.name}</h2>
            </div>
          </div>
          <VmoGrid style={{padding: '0 100px'}}>
            {contacts?.map(contact => {
              return (
                <VmoGridColumn width={8} key={contact.id}>
                  <div className="bill-to">
                    {/* <h6>Bill To :</h6> */}
                    <h4>{`${contact.firstName} ${contact.lastName}`} </h4>
                    <h5>{contact.jobTitle}</h5>
                    {contact.phone && (
                      <p>
                        <SvgIcon path="settings/phone" /> {contact.phone}
                      </p>
                    )}
                    {contact.email && (
                      <a href="mailto:">
                        <SvgIcon path="common/mail" />
                        {contact.email}
                      </a>
                    )}
                  </div>
                </VmoGridColumn>
              )
            })}

            {company && (
              <VmoGridColumn width={8}>
                <div className="bill-to">
                  <h4>{company.name}</h4>
                  <p>
                    {company.streetAddress} {company.locality}
                  </p>
                  <p>
                    {company.state} {company.country}
                  </p>
                  <p>{company.postalCode}</p>
                </div>
              </VmoGridColumn>
            )}
          </VmoGrid>

          <div className="bill-item">
            {products?.products?.length ? (
              <VmoTable>
                <VmoTableHeader>
                  <VmoTableRow>
                    <VmoTableHeaderCell>Product Name</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Item</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Unit Price</VmoTableHeaderCell>
                    <VmoTableHeaderCell>Amount ($)</VmoTableHeaderCell>
                  </VmoTableRow>
                </VmoTableHeader>
                <VmoTableBody>
                  {products.products.map(prod => {
                    return (
                      <VmoTableRow>
                        <VmoTableCell>{prod.productId}</VmoTableCell>
                        <VmoTableCell>{prod.quantity}</VmoTableCell>
                        <VmoTableCell>{prod.unitPrice}</VmoTableCell>
                        <VmoTableCell>{prod.unitTotal}</VmoTableCell>
                      </VmoTableRow>
                    )
                  })}

                  <VmoTableRow style={{fontWeight: '500', fontSize: '15px'}}>
                    <VmoTableCell colspan="3">Total</VmoTableCell>
                    <VmoTableCell>{products.subTotal}</VmoTableCell>
                  </VmoTableRow>

                  {products?.otherCharges?.map((charge, index) => {
                    return (
                      <VmoTableRow key={`charge${index}`}>
                        <VmoTableCell
                          colspan="2"
                          // rowspan="3"
                          style={{verticalAlign: 'top'}}
                        >
                          {charge.name}
                        </VmoTableCell>
                        <VmoTableCell style={{textAlign: 'right'}}>
                          {charge.type}
                          {charge.subType === '%' ? `( ${charge.value} %)` : null}
                        </VmoTableCell>
                        <VmoTableCell>{charge.total}</VmoTableCell>
                      </VmoTableRow>
                    )
                  })}

                  <VmoTableRow style={{fontWeight: '600', fontSize: '15px'}}>
                    <VmoTableCell colspan="3" style={{textAlign: 'right'}}>
                      Quote Total
                    </VmoTableCell>
                    <VmoTableCell>$ {products.total}</VmoTableCell>
                  </VmoTableRow>
                </VmoTableBody>
              </VmoTable>
            ) : null}

            {template.terms && <div className="tc-info">{template.terms}</div>}

            {template.paymentProcess && <div className="tc-info">{template.paymentProcess}</div>}

            {signature.signature === 'Add Signature' ? (
              <div className="sign my-4">
                <h6>Customer Acceptance</h6>
                <VmoGrid>
                  <VmoGridColumn width={8}>
                    <h5>Signature</h5>
                  </VmoGridColumn>
                  <VmoGridColumn width={4}>
                    <h5>Printed Name</h5>
                  </VmoGridColumn>
                  <VmoGridColumn width={4}>
                    <h5>Date</h5>
                  </VmoGridColumn>
                </VmoGrid>
              </div>
            ) : null}
          </div>

          <div className="kooper">
            <div className="logo">
              {preview && <VmoImage src={preview} />}
              <h2>{yourCompany.name}</h2>
            </div>
            <div className="com-address">
              <p>
                {yourCompany.streetAddress ? `${yourCompany.streetAddress}, ` : ''}
                {yourCompany.locality ? `${yourCompany.locality}, ` : ''}
                {yourCompany.state ? `${yourCompany.state}, ` : ''}
                {yourCompany.country ? `${yourCompany.country}, ` : ''}
                {yourCompany.postalCode ? yourCompany.postalCode : ''}
              </p>
              {/* <p>
                <SvgIcon path="common/mail" /> info@kooper.com <FiPhone className="ml-4" /> +91 12345
                67890
              </p> */}
              {/* <a href="#">www.kooper.com</a> */}
            </div>
          </div>
          <p className="help-line" style={{backgroundColor: template.color}}>
            If you have any questions, Please contact on <b>{yourInfo.phone}</b>
            or
            <a href="mailto:">
              <b>{yourInfo.email}</b>
            </a>
            <p>{template.footer}</p>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BasicTemplate
