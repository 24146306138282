/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useHistory} from 'react-router'
import _ from 'lodash'
import {GET_PURCHASES_LIST, DELETE_PURCHASES} from 'actions/types'
import {
  VmoButton,
  VmoTable,
  VmoTableHeader,
  VmoTableRow,
  VmoTableHeaderCell,
  VmoTableBody,
  VmoTablePlaceholder,
  VmoTableCell,
  VmoTooltip,
  VmoHeader,
} from 'vmo-library'
import create from 'assets/create.svg'
import {deletePurchases, getPurchasesData, getPurchaseslist} from 'actions/purchases'
import SearchComponent from 'components/common/SearchComponent'
import CreateFirst from 'components/common/CreateFirst'
import DeleteModal from 'components/common/DeleteModal'
import {getFormattedDate, getPermissionTooltipContent, startCase} from 'utils/helper'
import useApiLoader from 'hooks/pure/useApiLoader'
import PageLoader from 'components/common/PageLoader'
import SettingLayout from 'layouts/settingLayout'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import LockPermissionTooltip from 'components/common/LockPermissionTooltip'
import {roleAccessDeniedContent} from 'constants/variables'
import SvgIcon from 'components/common/SvgIcon'
import useApiResponse from 'hooks/impure/useApiResponse'

function Purchases() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [deleteModal, setDeleteModal] = useState(false)
  const [editId, setEditId] = useState()
  const [currentListParams, setCurrentListParams] = useState({
    page: 1,
    category: '',
    sortBy: '',
    sortType: '',
    text: '',
  })

  const {isLoadingData, type, successLabels = []} = useSelector(state => state.apiReducer)
  const {financePermissions} = useUserPermissions()
  const managePurchasesPermissions = financePermissions?.manage_purchases

  const {isLoading} = useApiLoader({label: GET_PURCHASES_LIST})

  useEffect(() => {
    if (successLabels.includes(DELETE_PURCHASES)) {
      dispatch(getPurchaseslist())
    }
  }, [successLabels, dispatch])

  const {data: purchaseListData = []} = useApiResponse({
    action: getPurchaseslist,
    enabled: true,
    label: GET_PURCHASES_LIST,
    storePath: 'purchases.purchaseListData',
  })

  useEffect(() => {
    const newCurrentListParams = _.omitBy(currentListParams, value => {
      if (value === 'all' || value === '') {
        return true
      }
      return false
    })

    dispatch(getPurchaseslist(newCurrentListParams))
  }, [currentListParams, dispatch])

  const renderTableBody = () => {
    return purchaseListData.map(({id, name, billNo, dateOfPurchase, updatedBy, createdBy, items}, index) => {
      return (
        <VmoTableRow
          key={id}
          className="tableLink"
          onClick={() => {
            dispatch(getPurchasesData(id))
            history.push(`/purchases/update/${id}`)
          }}
        >
          <VmoTableCell>{index + 1}</VmoTableCell>
          <VmoTableCell>
            <VmoHeader as="h5">{startCase(name)}</VmoHeader>
          </VmoTableCell>
          <VmoTableCell>{items?.map(({category}) => startCase(category))}</VmoTableCell>
          <VmoTableCell>{billNo}</VmoTableCell>
          <VmoTableCell>{getFormattedDate(dateOfPurchase)}</VmoTableCell>
          <VmoTableCell>{startCase(createdBy.name)}</VmoTableCell>
          <VmoTableCell>{startCase(updatedBy?.name)}</VmoTableCell>
          <VmoTableCell>
            <VmoTooltip
              content={!managePurchasesPermissions ? roleAccessDeniedContent : 'Delete'}
              size="mini"
              position="top center"
              trigger={
                <VmoButton
                  icon={<SvgIcon path="common/delete" />}
                  onClick={e => {
                    e.stopPropagation()
                    if (managePurchasesPermissions) {
                      setEditId(id)
                      setDeleteModal(true)
                    }
                  }}
                />
              }
            />
          </VmoTableCell>
        </VmoTableRow>
      )
    })
  }

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <>
      {purchaseListData.length > 0 || currentListParams.text || currentListParams.category ? (
        <div className="kooper-side-wrap">
          <div className="page-header">
            <div className="page-title">
              <div className="page-titleIcon">
                <SvgIcon path="navigation/purchases" />
                <h5>Purchases List ({purchaseListData ? purchaseListData.length : 0})</h5>
              </div>
            </div>
            <div className="page-action">
              <SearchComponent onSuccess={value => setCurrentListParams({text: value})} />

              <LockPermissionTooltip isRoleAccessDenied={!managePurchasesPermissions}>
                <VmoButton
                  className={!managePurchasesPermissions ? 'disabled-button' : ''}
                  content="Add"
                  primary
                  onClick={() => managePurchasesPermissions && history.push(`/purchases/add`)}
                />
              </LockPermissionTooltip>
            </div>
          </div>
          <VmoTable sortable basic selectable>
            <VmoTableHeader>
              <VmoTableRow>
                <VmoTableHeaderCell>#</VmoTableHeaderCell>
                <VmoTableHeaderCell>Name</VmoTableHeaderCell>
                <VmoTableHeaderCell>Category</VmoTableHeaderCell>
                <VmoTableHeaderCell>Bill No</VmoTableHeaderCell>
                <VmoTableHeaderCell>Date Of Expense</VmoTableHeaderCell>
                <VmoTableHeaderCell>Created By</VmoTableHeaderCell>
                <VmoTableHeaderCell>Updated By</VmoTableHeaderCell>
                <VmoTableHeaderCell>Action</VmoTableHeaderCell>
              </VmoTableRow>
            </VmoTableHeader>
            <VmoTableBody>
              {isLoadingData && type.includes(GET_PURCHASES_LIST) ? (
                <VmoTablePlaceholder columns={8} />
              ) : (purchaseListData || []).length > 0 ? (
                renderTableBody()
              ) : (
                <tr>
                  <td colSpan={6} className="emptyValue">
                    No Data Available
                  </td>
                </tr>
              )}
            </VmoTableBody>
          </VmoTable>

          {deleteModal && (
            <DeleteModal
              isModalOpen={deleteModal}
              setIsModalOpen={setDeleteModal}
              modalText="Are you sure you want to delete ?"
              deleteAction={deletePurchases}
              idTobeDeleted={editId}
              type={DELETE_PURCHASES}
            />
          )}
        </div>
      ) : (
        <SettingLayout>
          <CreateFirst
            header="Purchases"
            addFirst={() => managePurchasesPermissions && history.push(`/purchases/add`)}
            tooltip={!managePurchasesPermissions}
            tooltipContent={getPermissionTooltipContent({
              roleAccess: managePurchasesPermissions,
            })}
            buttonText="Create Purchase"
            imageSrc={create}
            subHeader="Easily manage and track all transactions in the form of bills in one place"
            Feature1="Simplified process"
            list1="Simplify billing and invoicing processes"
            Feature2="Payment status"
            list2="Track status and send reminders for overdue invoices."
            Feature3="Online payment"
            list3="Allow customers to pay online through purchase bills"
          />
        </SettingLayout>
      )}
    </>
  )
}

export default Purchases
