import {ANALYTICS_ENDPOINT} from './endpoint'
import {apiAction} from './api'
import {
  EXPENSES_STATS,
  EXPENSES_VALUE_STATS,
  EXPENSES_BREAKDOWN_BY_CATEGORY,
  EXPENSES_BREAKDOWN_BY_STATUS,
  EXPENSES_BY_DEPARTMENT,
  EXPENSES_STATUS_TIMETREND,
  EXPENSES_CATEGORY_TIMETREND,
  EXPENSES_TIMETREND_BY_DEPARTMENT,
  EXPENSES_EMPLOYEE_STATS,
} from './types'

export function expensesCountStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/count-stats`,
    method: 'POST',
    label: EXPENSES_STATS,
    data,
  })
}
export function expensesValueStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/value-stats`,
    method: 'POST',
    label: EXPENSES_VALUE_STATS,
    data,
  })
}
export function expensesBreakdownByCategory(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/breakdown-by-category`,
    method: 'POST',
    label: EXPENSES_BREAKDOWN_BY_CATEGORY,
    data,
  })
}
export function expensesBreakdownByStatus(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/breakdown-by-status`,
    method: 'POST',
    label: EXPENSES_BREAKDOWN_BY_STATUS,
    data,
  })
}
export function expensesbyDepartment(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/expenses-by-department`,
    method: 'POST',
    label: EXPENSES_BY_DEPARTMENT,
    data,
  })
}
export function expensesStatusTimeTrend(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/status-timetrend`,
    method: 'POST',
    label: EXPENSES_STATUS_TIMETREND,
    data,
  })
}
export function expensesCategoryTimeTrend(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/category-timetrend`,
    method: 'POST',
    label: EXPENSES_CATEGORY_TIMETREND,
    data,
  })
}
export function expensesTimeTrendByDepartment(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/timetrend-by-department`,
    method: 'POST',
    label: EXPENSES_TIMETREND_BY_DEPARTMENT,
    data,
  })
}
export function expensesEmployeeStats(data) {
  return apiAction({
    url: `${ANALYTICS_ENDPOINT}/hr/expenses/employee-stats`,
    method: 'POST',
    label: EXPENSES_EMPLOYEE_STATS,
    data,
  })
}
