const accEndpoint = process.env.REACT_APP_ACCOUNT_ENDPOINT
export const platformEndpoint = process.env.REACT_APP_PLATFORM_ENDPOINT
const socket = process.env.REACT_APP_SOCKET
const docs = process.env.REACT_APP_DOCS_ENDPOINT

export const USER_PLATFORM_V1 = `${accEndpoint}/v1/accounts`

export const KOOPER = `${platformEndpoint}/v1`

export const ANALYTICS_ENDPOINT = `${KOOPER}/analytics`

export const USER_ACCOUNTS = USER_PLATFORM_V1
export const USER_VERIFICATIONS = `${USER_PLATFORM_V1}/verifications`

export const KOOPER_ACCOUNT_V1 = `${platformEndpoint}/v1/kooper`
export const USER_USERS = `${USER_PLATFORM_V1}/users`
export const MEETINGS_ENDPOINT = `${platformEndpoint}/v1/meetings`

export const VENDORS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/vendors`
export const PURCHASES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/purchases`
export const PURCHASE_ORDERS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/purchase-orders`
export const SALES_ORDERS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/sales-orders`
export const FINANCE_INVOICES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/invoices`
export const FINANCE_BUDGET_ENDPOINT = `${KOOPER_ACCOUNT_V1}/finance/budget`

export const CONTACTS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/customers/contacts`
export const COMPANIES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/customers/companies`
export const CALENDAR_ENDPOINT = `${KOOPER_ACCOUNT_V1}/customers/calendar`
export const CLIENT_PORTAL_ENDPOINT = `${KOOPER_ACCOUNT_V1}/customers/client_portal`

export const PRODUCTS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/sales/products`
export const DEALS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/sales/deals`
export const QUOTES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/sales/quotes`

export const HR_EMPLOYEES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/human_resources/employees`
export const HR_EXPENSES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/human_resources/expenses`
export const HR_TIMEOFFS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/human_resources/timeoffs`
export const HR_ANNOUNCEMENTS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/human_resources/announcements`
export const HR_APPRAISALS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/human_resources/appraisals`

export const INBOXES_ENDPOINT = `${KOOPER_ACCOUNT_V1}/inboxes`
export const CONVERSATIONS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/conversations`
export const TASKS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/tasks`
export const MAILBOXS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/mailboxes`
export const EMAIL_INTEGRATION_ENDPOINT = `${KOOPER_ACCOUNT_V1}/email-integration`

export const SETTINGS_ENDPOINT = `${KOOPER_ACCOUNT_V1}/settings`

export const KOOPER_CHANNEL = `${platformEndpoint}/channels`
export const KOOPER_CHANNEL_V1 = `${platformEndpoint}/v1/channels`

export const FORM = `${platformEndpoint}/v1/forms`

export const DOCS = `${docs}/v1/docs`
export const DOCS_ANALYTICS = `${DOCS}/analytics`

export const KOOPER_ATTACHMENTS = `${KOOPER_ACCOUNT_V1}/attachments`
export const PROJECTS_ENDPOINT = `${KOOPER}/projects`

export const EMPLOYEE_ENDPOINT = `${KOOPER}/employee`

export const SOCKET = socket
