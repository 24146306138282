import React, {useEffect, useState} from 'react'
import {
  VmoButton,
  VmoCard,
  VmoCardContent,
  VmoDivider,
  VmoTable,
  VmoTableBody,
  VmoTableCell,
  VmoTableFooter,
  VmoTableRow,
} from 'vmo-library'
import {useDispatch, useSelector} from 'react-redux'
import DeleteModal from 'components/common/DeleteModal'
import {
  deleteProjectFinanceBillingInvoice,
  getProjectFinanceBillingCompletedInvoicesList,
  getProjectFinanceBillingInvoiceLogs,
  reopenProjectFinanceBillingInvoice,
} from 'actions/projects'
import {DELETE_PROJECT_FINANCE_BILLING_INVOICE, REOPEN_PROJECT_FINANCE_BILLING_INVOICE} from 'actions/types'
import {PROJECTS_ENDPOINT} from 'actions/endpoint'
import {getFormattedDate} from 'utils/helper'
import ProjectBillingInvoiceList from '../ProjectBillingInvoiceList'

const ProjectBillingCompletedInvoices = ({projectId, budgetId}) => {
  const dispatch = useDispatch()

  const [invoiceId, setInvoiceId] = useState(null)
  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false)
  const [idToDeleteInvoice, setIdToDeleteInvoice] = useState(null)

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {billingCompletedInvoicesList = [], billingInvoiceLogs = {}} = useSelector(state => state.projects)
  const {expenseList = [], timeLogs = [], currency = ''} = billingInvoiceLogs

  useEffect(() => {
    if (projectId && budgetId && invoiceId) {
      dispatch(getProjectFinanceBillingInvoiceLogs({projectId, budgetId, invoiceId}))
    }
  }, [dispatch, projectId, budgetId, invoiceId])

  useEffect(() => {
    if (
      successLabels.includes(DELETE_PROJECT_FINANCE_BILLING_INVOICE) ||
      successLabels.includes(REOPEN_PROJECT_FINANCE_BILLING_INVOICE)
    ) {
      setIdToDeleteInvoice(null)
      setInvoiceId(null)
      dispatch(getProjectFinanceBillingCompletedInvoicesList({projectId, budgetId}))
    }
  }, [successLabels, dispatch, projectId, budgetId])

  const getHourMinuteFormatFromMinutes = minutes => {
    const hour = Math.floor(minutes / 60)
    const minute = minutes % 60
    return `${hour} hrs ${minute} mins`
  }

  const getTotalTime = () => {
    const totalMinutes = timeLogs.reduce((acc, item) => acc + item.loggedHrs * 60 + item.loggedMins, 0)
    return getHourMinuteFormatFromMinutes(totalMinutes)
  }

  const getSubTotalCost = list => {
    return list.reduce((acc, item) => acc + item.cost, 0).toFixed(2)
  }

  const renderTimeTableBody = () => {
    if (timeLogs.length === 0) {
      return (
        <VmoTableRow>
          <VmoTableCell colSpan="6" className="emptyValue">
            No Data Available
          </VmoTableCell>
        </VmoTableRow>
      )
    }
    return timeLogs.map(time => (
      <VmoTableRow key={time.id} className="tableLink">
        <VmoTableCell>{getFormattedDate(time.date)}</VmoTableCell>
        <VmoTableCell>
          {time.createdByDetails?.firstName} {time.createdByDetails?.lastName}
        </VmoTableCell>
        <VmoTableCell>
          {time.tasklistDetails && (
            <p>
              {time.tasklistDetails?.title} - {time.taskDetails?.title}
            </p>
          )}
          <p>{time.description || 'No description'}</p>
        </VmoTableCell>
        <VmoTableCell>{getHourMinuteFormatFromMinutes(time.loggedHrs * 60 + time.loggedMins)}</VmoTableCell>
        <VmoTableCell>
          {currency} {time.cost.toFixed(2)}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  const renderExpenseTableBody = () => {
    return expenseList.map(expense => (
      <VmoTableRow key={expense.id} className="tableLink">
        <VmoTableCell>{getFormattedDate(expense.expenseDate)}</VmoTableCell>
        <VmoTableCell>{expense.title}</VmoTableCell>
        <VmoTableCell>{expense.description}</VmoTableCell>
        <VmoTableCell>{expense.createdBy?.name}</VmoTableCell>
        <VmoTableCell>
          {currency} {expense.cost.toFixed(2)}
        </VmoTableCell>
      </VmoTableRow>
    ))
  }

  return (
    <div className="project-finance-billing-content">
      <div className="project-finance-billing-content-left">
        <ProjectBillingInvoiceList
          invoiceList={billingCompletedInvoicesList}
          onListItemClick={invoiceId => {
            setInvoiceId(invoiceId)
          }}
          onDeleteClick={invoiceId => {
            setIdToDeleteInvoice(invoiceId)
            setisDeleteModalOpen(true)
          }}
        />
      </div>
      <div className="project-finance-billing-content-right">
        {invoiceId && (
          <VmoCard fluid>
            <VmoCardContent>
              <div>
                <p>Invoice No./ID : {billingInvoiceLogs.invoiceNumber}</p>
                <p>
                  Issue Date : {billingInvoiceLogs.invoiceDate ? getFormattedDate(billingInvoiceLogs.invoiceDate) : ''}
                </p>
              </div>
              <VmoDivider />
              {timeLogs.length ? (
                <>
                  <h3>Billable Time </h3>
                  <VmoTable basic>
                    <VmoTableBody>{renderTimeTableBody()}</VmoTableBody>
                    <VmoTableFooter>
                      <VmoTableRow>
                        <VmoTableCell colSpan="2" />
                        <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
                        <VmoTableCell>{getTotalTime()}</VmoTableCell>
                        <VmoTableCell>
                          {currency} {getSubTotalCost(timeLogs)}
                        </VmoTableCell>
                      </VmoTableRow>
                    </VmoTableFooter>
                  </VmoTable>
                </>
              ) : (
                <p>No time logged on this invoice</p>
              )}

              {expenseList.length ? (
                <>
                  <h3>Expenses </h3>
                  <VmoTable basic>
                    <VmoTableBody>{renderExpenseTableBody()}</VmoTableBody>
                    <VmoTableFooter>
                      <VmoTableRow>
                        <VmoTableCell colSpan="3" />
                        <VmoTableCell textAlign="right">Sub-Total:</VmoTableCell>
                        <VmoTableCell>
                          {currency} {getSubTotalCost(expenseList)}
                        </VmoTableCell>
                      </VmoTableRow>
                    </VmoTableFooter>
                  </VmoTable>
                </>
              ) : (
                <p>No expenses logged on this invoice</p>
              )}

              <VmoDivider />

              <div className="d-flex">
                <div>
                  <VmoButton
                    basic
                    primary
                    icon="download"
                    content="Export"
                    onClick={() =>
                      window.open(`${PROJECTS_ENDPOINT}/budgets/invoice/download/${billingInvoiceLogs.link}`, '_blank')
                    }
                  />
                  <VmoButton
                    basic
                    primary
                    icon="lock open"
                    content="Reopen Invoice"
                    onClick={() => dispatch(reopenProjectFinanceBillingInvoice({projectId, budgetId, invoiceId}))}
                    className="ml-3"
                  />
                </div>
                <div>
                  <h2>
                    Total: {currency} {billingInvoiceLogs.finalTotal?.toFixed(2)}
                  </h2>
                  {billingInvoiceLogs.footerText && <span>({billingInvoiceLogs.footerText})</span>}
                </div>
              </div>
            </VmoCardContent>
          </VmoCard>
        )}
      </div>

      {isDeleteModalOpen && (
        <DeleteModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setisDeleteModalOpen}
          modalText="Are you sure you want to delete this invoice?"
          onDelete={() =>
            dispatch(deleteProjectFinanceBillingInvoice({projectId, budgetId, invoiceId: idToDeleteInvoice}))
          }
          type={DELETE_PROJECT_FINANCE_BILLING_INVOICE}
        />
      )}
    </div>
  )
}

export default ProjectBillingCompletedInvoices
