import React, {useEffect, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'

import {ADD_SURVEY, DELETE_SURVEY, EDIT_SURVEY, GET_EMAIL_SURVEY} from 'actions/types'
import {getEmailSurvey} from 'actions/settings_customer_satisfaction'
import CreateFirst from 'components/common/CreateFirst'
import create from 'assets/create.svg'
import PageLoader from 'components/common/PageLoader'
import useApiLoader from 'hooks/pure/useApiLoader'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import {getPermissionTooltipContent} from 'utils/helper'
import EmailSurveyForm from './EmailSurveyForm'

const EmailSurvey = () => {
  const {url} = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  const {successLabels = []} = useSelector(state => state.apiReducer)
  const {emailSurveySingle} = useSelector(state => state.settingsCustomerSatisfaction)

  const [emailSurvey, setEmailSurvey] = useState(Boolean(emailSurveySingle))
  const [emailSurveyCreate, setEmailSurveyCreate] = useState(false)

  const {isLoading} = useApiLoader({label: GET_EMAIL_SURVEY})
  const {productivityPermissions} = useUserPermissions()
  const customerSatficationsPermissions = productivityPermissions?.customer_satfication

  useEffect(() => {
    dispatch(getEmailSurvey())
  }, [dispatch])

  useEffect(() => {
    if (successLabels.includes(ADD_SURVEY)) {
      dispatch(getEmailSurvey())
      setEmailSurvey(false)
    }
    if (successLabels.includes(DELETE_SURVEY)) {
      dispatch(getEmailSurvey())
    }
    if (successLabels.includes(EDIT_SURVEY)) {
      dispatch(getEmailSurvey())
    }
    if (successLabels.includes(GET_EMAIL_SURVEY)) {
      setEmailSurvey(Boolean(emailSurveySingle))
    }
  }, [successLabels, dispatch, emailSurveySingle])

  useEffect(() => {
    if (emailSurvey) {
      history.push(`${url}/${emailSurveySingle.id}`)
    }
  }, [emailSurvey, history, url, emailSurveySingle])

  if (isLoading) {
    return <PageLoader />
  }

  if (emailSurveyCreate) {
    return <EmailSurveyForm toggleCreate={setEmailSurveyCreate} />
  }

  if (!emailSurvey) {
    return (
      <div className="setEmpty-wrap">
        <CreateFirst
          header="Email Survey"
          subHeader="Email surveys are a valuable tool for collecting feedback, opinions, and insights from customers."
          addFirst={() => customerSatficationsPermissions && setEmailSurveyCreate(true)}
          buttonText="Add Email Survey"
          tooltip={!customerSatficationsPermissions}
          tooltipContent={getPermissionTooltipContent({roleAccess: customerSatficationsPermissions})}
          imageSrc={create}
          Feature1="Survey Creation"
          list1="Create customized email surveys based on customers"
          Feature2="Rating type"
          list2="Based on your brand choose rating types from list"
          Feature3="Preview survey"
          list3="Preview customized survey easily before sending"
        />
      </div>
    )
  }
  return null
}

export default EmailSurvey
