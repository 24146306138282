/* eslint-disable prettier/prettier */
export const CREATE_USER_ACCOUNT = 'CREATE_USER_ACCOUNT'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const VERIFICATION = 'VERIFICATION'
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE'
export const API = 'API'
export const API_START = 'API_START'
export const API_END = 'API_END'
export const API_SUCCESS = 'API_SUCCESS'

export const ACCESS_DENIED = 'ACCESS_DENIED'
export const API_ERROR = 'API_ERROR'
export const APP_DATA = 'APP_DATA'
export const LOGOUT = 'LOGOUT'
export const RESEND_VERIFICATION = 'RESEND_VERIFICATION'
export const RESEND_VERIFICATION_FAILURE =
  'RESEND_VERIFICATION_FAILURE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_VERIFICATION =
  'RESET_PASSWORD_VERIFICATION'
export const RESET_PASSWORD_OTP_FAIL = 'RESET_PASSWORD_OTP_FAIL'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const EMAIL_SETUP = 'EMAIL_SETUP'
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN'
export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const FORGOT_DOMAIN = 'FORGOT_DOMAIN'
export const LOGGED_OUT = 'LOGGED_OUT'
export const ADD_NEW_INVITEE = 'ADD_NEW_INVITEE'
export const NEW_PASSWORD_EMAIL = 'NEW_PASSWORD_EMAIL'
export const GET_ALL_ASSIGNEE = 'GET_ALL_ASSIGNEE'
export const ADD_INBOX = 'ADD_INBOX'
export const ADD_GMAIL_INBOX='ADD_GMAIL_INBOX'
export const ASSIGN_INBOX = 'ASSIGN_INBOX'
export const GET_ALL_MAIL_INBOX = 'GET_ALL_MAIL_INBOX'
export const DELETE_INBOX = 'DELETE_INBOX'
export const UPDATE_INBOX = 'UPDATE_INBOX'
export const SEND_VERIFICATION_MAIL_INBOX='SEND_VERIFICATION_MAIL_INBOX'
export const SET_METADATA = 'SET_METADATA'
export const GET_META_DATA = 'GET_META_DATA'
export const GET_MAIL_BOX = 'GET_MAIL_BOX'
export const INTEGRATE_MAIL_BOX = 'INTEGRATE_MAIL_BOX'
export const UPDATE_SMTP_SETTINGS = 'UPDATE_SMTP_SETTINGS'
export const GET_PENDING_ASSIGNEES = 'GET_PENDING_ASSIGNEES'
export const RESEND_INVITATION = 'RESEND_INVITATION'
export const DELETE_INVITATION = 'DELETE_INVITATION'
export const GET_VERIFICATION = 'GET_VERIFICATION'
export const SET_PASSWORD = 'SET_PASSWORD'
export const EDIT_MESSAGE = 'EDIT_MESSAGE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const LIVE_COUNT_DETAIL = 'LIVE_COUNT_DETAIL'
export const GET_LIVEUSER_CONTACTS = 'GET_LIVEUSER_CONTACTS'
export const START_NEW_CONVERSATION = 'START_NEW_CONVERSATION'
export const GET_ACTIVITY_FEED = 'GET_ACTIVITY_FEED'
export const CLEAR_ACTIVITY_FEED = 'CLEAR_ACTIVITY_FEED'
export const LEARN_MORE = 'LEARN_MORE'
export const MY_ACTIVITIES = 'MY_ACTIVITIES'

export const UPDATE_CONTACT_LIST = 'UPDATE_CONTACT_LIST'
export const CONVERSATION_ATTACHMENT_UPLOAD =
  'CONVERSATION_ATTACHMENT_UPLOAD'
  export const CONVERSATION_AUDIO_UPLOAD='CONVERSATION_AUDIO_UPLOAD'
export const GET_HOME_PAGE_DATA = 'GET_HOME_PAGE_DATA'
export const GET_CONVERSATION_DATA = 'GET_CONVERSATION_DATA'
export const GET_SERACHED_CONVERSATION_DATA =
  'GET_SERACHED_CONVERSATION_DATA'
export const SEND_MAIL = ' SEND_MAIL'
export const GET_CONVERSATION = 'GET_CONVERSATION'
export const PUT_CONVERSATION = 'PUT_CONVERSATION'
export const EMAIL_ID_PAIRS = 'EMAIL_ID_PAIRS'
export const UPDATE_CONVERSATION_IS_READ =
  'UPDATE_CONVERSATION_IS_READ'
export const ADD_MESSAGE_TO_CONVERSATION =
  'ADD_MESSAGE_TO_CONVERSATION'
export const SEND_NOTE = 'SEND_NOTE'
export const MOVE_CONVERSATION_TO = 'MOVE_CONVERSATION_TO'
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION'
export const ADD_LABEL = 'ADD_LABEL'
export const SNOOZE_CONVERSATION = 'SNOOZE_CONVERSATION'
export const UPDATE_CONVERSATION_AND_REFRESH =
  'UPDATE_CONVERSATION_AND_REFRESH'
export const DELETE_MSG_OF_CONVERSATION = 'DELETE_MSG_OF_CONVERSATION'
export const UPDATE_CONTACT_ONLINE_STATUS = 'UPDATE_CONTACT_ONLINE_STATUS'
export const UPDATE_STATUS_EMAIL_LIST = 'UPDATE_STATUS_EMAIL_LIST'
export const MERGE_CONVERSATION = 'MERGE_CONVERSATION'
export const SPLIT_CONVERSATION = 'SPLIT_CONVERSATION'
export const ATTACHMENT_POST = 'ATTACHMENT_POST'
export const ATTACHMENT_GET = 'ATTACHMENT_GET'
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST'
export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const SEARCH_CONTACT = 'SEARCH_CONTACT'
export const NEW_CONTACT = 'NEW_CONTACT'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const ADD_LABEL_CONTACT = 'ADD_LABEL_CONTACT'
export const ADD_NOTE_CONTACTS = 'ADD_NOTE_CONTACTS'
export const GET_NOTE_CONTACTS = 'GET_NOTE_CONTACTS'
export const LIST_COMPANY_CONTACTS = 'LIST_COMPANY_CONTACTS'
export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES'
export const GET_ALL_COMPANIES_IDS='GET_ALL_COMPANIES_IDS'
export const CREATE_COMPANY = 'CREATE_COMPANY'
export const GET_SINGLE_COMPANY = 'GET_SINGLE_COMPANY'
export const UPDATE_COMPANY_TAGS = 'UPDATE_COMPANY_TAGS'
export const GET_ALL_COMPANY_NOTES = 'GET_ALL_COMPANY_NOTES'
export const GET_ALL_ACTIVITIES_COMPANY = 'GET_ALL_ACTIVITIES_COMPANY'
export const MULTIPLE_UPDATE_COMPANY = 'MULTIPLE_UPDATE_COMPANY'
export const ATTACHMENT_POST_COMPANY = 'ATTACHMENT_POST_COMPANY'
export const EXPORT_ALL_COMPANIES = 'EXPORT_ALL_COMPANIES'
export const SAMPLE_COMPANIES_IMPORT = 'SAMPLE_COMPANIES_IMPORT'
export const IMPORT_COMPANIES = 'IMPORT_COMPANIES'
export const MULTIPLE_MERGE_COMPANY = 'MULTIPLE_MERGE_COMPANY'
export const GET_COLUMNS_SELECTION_COMPANY = 'GET_COLUMNS_SELECTION_COMPANY'
export const COLUMNS_SELECTION_COMPANY = 'COLUMNS_SELECTION_COMPANY'
export const NEW_COMPANY = 'NEW_COMPANY'
export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS'
export const EDIT_COMPANY = 'EDIT_COMPANY'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const ADD_NOTE_COMPANIES = 'ADD_NOTE_COMPANIES'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const UPDATE_PROFILE_PASSWORD = 'UPDATE_PROFILE_PASSWORD'
export const GET_USERS = 'GET_USERS'
export const DELETE_USER = 'DELETE_USER'
export const ALLOW_USER_ACCESS='ALLOW_USER_ACCESS'
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE'
export const GET_TEMPLATE = 'GET_TEMPLATE'
export const GET_SINGLE_TEMPLATE = 'GET_SINGLE_TEMPLATE'
export const EDIT_SINGLE_TEMPLATE = 'EDIT_SINGLE_TEMPLATE'
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
export const DELETE_TEMPLATE_ATTACHMENT = 'DELETE_TEMPLATE_ATTACHMENT'
export const CREATE_TAG = 'CREATE_TAG'
export const DELETE_TAG = 'DELETE_TAG'
export const GET_TAGS = 'GET_TAGS'
export const GET_LABEL_TAGS = 'GET_LABEL_TAGS'

export const ADD_SURVEY = 'ADD_SURVEY'
export const EDIT_SURVEY = 'EDIT_SURVEY'
export const DELETE_SURVEY = 'DELETE_SURVEY'

export const GET_EMAIL_SURVEY = 'GET_EMAIL_SURVEY'
export const GET_CHAT_SURVEY = 'GET_CHAT_SURVEY'

export const ADD_SLA = 'ADD_SLA'
export const GET_SLA = 'GET_SLA'
export const EDIT_SLA = 'EDIT_SLA'
export const DELETE_SLA = 'DELETE_SLA'

export const UPDATE_OFFLINE_EXPERIENCE = 'UPDATE_OFFLINE_EXPERIENCE'
export const GET_OFFLINE_EXPERIENCE = 'GET_OFFLINE_EXPERIENCE'

export const GET_USER_TEMPLATES = 'GET_USER_TEMPLATES'
export const CREATE_TIMING_SCHEME = 'CREATE_TIMING_SCHEME'
export const GET_TIMING_SCHEME = 'GET_TIMING_SCHEME'
export const EDIT_TIMING_SCHEME = 'EDIT_TIMING_SCHEME'
export const DELETE_TIMING_SCHEME = 'DELETE_TIMING_SCHEME'
export const CREATE_TICKET = 'CREATE_TICKET'

export const ATTACHMENT_CONTACTS = 'ATTACHMENT_CONTACTS'
export const NAVBAR_SEARCH = 'NAVBAR_SEARCH'
export const GET_STATUS_LIST = 'GET_STATUS_LIST'

export const GET_FEEDBACK_FORM = 'GET_FEEDBACK_FORM'
export const EDIT_FEEDBACK_FORM = 'EDIT_FEEDBACK_FORM'
export const CREATE_FEEDBACK_FORM = 'CREATE_FEEDBACK_FORM'
export const DELETE_FEEDBACK_FORM = 'DELETE_FEEDBACK_FORM'
export const SINGLE_FEEDBACK_FORM = 'SINGLE_FEEDBACK_FORM'

export const EDIT_STATUS_FORM = 'EDIT_STATUS_FORM'

export const GET_FORM_WIDGET = 'GET_FORM_WIDGET'
export const EDIT_FORM_WIDGET = 'EDIT_FORM_WIDGET'

export const GET_WIDGET = 'GET_WIDGET'
export const EDIT_WIDGET = 'EDIT_WIDGET'

export const GET_ALL_RULES = 'GET_ALL_RULES'
export const GET_RULE = 'GET_RULE'
export const EDIT_RULE = 'EDIT_RULE'
export const DELETE_RULE = 'DELETE_RULE'
export const CREATE_RULE = 'CREATE_RULE'

export const CREATE_ROUTING = 'CREATE_ROUTING'
export const GET_ROUTING = 'GET_ROUTING'
export const GET_ROUTING_DATA = 'GET_ROUTING_DATA'
export const DELETE_ROUTING = 'DELETE_ROUTING'
export const UPDATE_ROUTING = 'UPDATE_ROUTING'

export const GET_DOMAIN_LIST = 'GET_DOMAIN_LIST'
export const CREATE_DOMAIN = 'CREATE_DOMAIN'
export const GET_DOMAIN = 'GET_DOMAIN'
export const EDIT_DOMAIN = 'EDIT_DOMAIN'
export const DELETE_DOMAIN = 'DELETE_DOMAIN'

export const GET_CANNED_RESPONSE_LIST = 'GET_CANNED_RESPONSE_LIST'
export const GET_CANNED_RESPONSE_DETAIL = 'GET_CANNED_RESPONSE_DETAIL'
export const UPDATE_CANNED_RESPONSE = 'UPDATE_CANNED_RESPONSE'
export const CREATE_CANNED_RESPONSE = 'CREATE_CANNED_RESPONSE'
export const DELETE_CANNED_RESPONSE = 'DELETE_CANNED_RESPONSE'
export const UPDATE_INBOX_ASSIGNEE = 'UPDATE_INBOX_ASSIGNEE'

export const INBOX_COUNTS = 'INBOX_COUNTS'

export const INFLOW_BY_CHANNEL = 'INFLOW_BY_CHANNEL'
export const INFLOW_BY_CHANNEL_VS_TIME = 'INFLOW_BY_CHANNEL_VS_TIME'
export const CHANNEL_DISTRIBUTION = 'CHANNEL_DISTRIBUTION'
export const INFLOW_ACROSS_TIME = 'INFLOW_ACROSS_TIME'
export const USER_ACITIVITIES_TIME = 'USER_ACITIVITIES_TIME'
export const INFLOW_BY_DATE_ACROSS_TIME = 'INFLOW_BY_DATE_ACROSS_TIME'
export const INFLOW_BY_CHANNEL_ACROSS_TIME =
  'INFLOW_BY_CHANNEL_ACROSS_TIME'

export const CONVERSATION_BY_INBOX = 'CONVERSATION_BY_INBOX'
export const TOTAL_CONVERSATION = 'TOTAL_CONVERSATION'
export const AVERAGE_FIRST_RESPONSE = 'AVERAGE_FIRST_RESPONSE'
export const AVERAGE_RESOLUTION = 'AVERAGE_RESOLUTION'
export const PRIORITY_ACROSS_TIME = 'PRIORITY_ACROSS_TIME'
export const FIRST_RESPONSE_TIME_STATS = 'FIRST_RESPONSE_TIME_STATS'
export const NEW_CONTACTS_ANALYTICS = 'NEW_CONTACTS_ANALYTICS'
export const AVERAGE_REPLIES_RESOLUTION = 'AVERAGE_REPLIES_RESOLUTION'
export const AVERAGE_RESOLUTION_TIME_STATS =
  'AVERAGE_RESOLUTION_TIME_STATS'

export const TOTAL_RESOLVED = 'TOTAL_RESOLVED'
export const TOTAL_ASSIGNED = 'TOTAL_ASSIGNED'
export const FIRST_RESPONSE_USERS = 'FIRST_RESPONSE_USERS'
export const AVERAGE_RESOLUTION_USERS = 'AVERAGE_RESOLUTION_USERS'
export const ASSIGNED_VS_RESOLVED = ' ASSIGNED_VS_RESOLVED'
export const STATUS_ACROSS_TIME_USERS = ' STATUS_ACROSS_TIME_USERS'
export const AVERAGE_FIRST_RESPONSE_USERS =
  ' AVERAGE_FIRST_RESPONSE_USERS'
export const AVERAGE_RESOLVED_TIME_USERS =
  ' AVERAGE_RESOLVED_TIME_USERS'
export const OPEN_CONVERSATION_INBOX_STATUS =
  'OPEN_CONVERSATION_INBOX_STATUS'
export const AVERAGE_REPLIES_RESOLUTION_USERS =
  ' AVERAGE_REPLIES_RESOLUTION_USERS'
export const OPEN_CONVERSATION_TIMETRACKING_PERCENTAGE =
  'OPEN_CONVERSATION_TIMETRACKING_PERCENTAGE'
export const OPEN_CONVERSATION_TIMETRACKING_USERDATA =
  'OPEN_CONVERSATION_TIMETRACKING_USERDATA'
export const CONVERSATION_STATS = 'CONVERSATION_STATS'
export const HOME_USERS_ANALYTICS = 'HOME_USERS_ANALYTICS'
export const CONTACT_DEVICES_ANALYTICS = 'CONTACT_DEVICES_ANALYTICS'
export const HOMEPAGE_LIVE_COUNT='HOMEPAGE_LIVE_COUNT'

export const FIRST_RESPONSE_TIME_SLA = 'FIRST_RESPONSE_TIME_SLA'
export const AVERAGE_RESOLUTION_TIME_SLA =
  'AVERAGE_RESOLUTION_TIME_SLA'
export const RESOLVE_TARGET_OVER_TIME_SLA =
  'RESOLVE_TARGET_OVER_TIME_SLA'
export const RESPONSE_TARGET_OVER_TIME_SLA =
  'RESPONSE_TARGET_OVER_TIME_SLA'
export const USER_ACTIVITIES_TABLE_SLA = 'USER_ACTIVITIES_TABLE_SLA'

export const INBOX_ACTIVITIES = 'INBOX_ACTIVITIES'

export const TAGS_STATS = 'TAGS_STATS'
export const FIRST_CONTACT_RESOLVED = 'FIRST_CONTACT_RESOLVED'
export const TAGGED_VS_NON_TAGGED_OVERTIME =
  'TAGGED_VS_NON_TAGGED_OVERTIME'
export const TAGS_COUNT_TABLE = 'TAGS_COUNT_TABLE'

export const NPS_SCORE_ANALYTICS = 'NPS_SCORE_ANALYTICS'
export const RATINGS_PERFORMANCE_ANALYTICS =
  'RATINGS_PERFORMANCE_ANALYTICS'
export const RATINGS_AND_NPS_SCORE = 'RATINGS_AND_NPS_SCORE'
export const USER_ACTIVITIES_OVERTIME = 'USER_ACTIVITIES_OVERTIME'
export const NPS_PERFORMANCE_ANALYTICS = 'NPS_PERFORMANCE_ANALYTICS'
export const USER_RATINGS = 'USER_RATINGS'
export const USER_ASSIGNMENT_OVERTIME = 'USER_ASSIGNMENT_OVERTIME'

export const TOTAL_VISITORS_ANALYTICS = 'TOTAL_VISITORS_ANALYTICS'
export const TOTAL_VIEWS_ANALYTICS = 'TOTAL_VIEWS_ANALYTICS'
export const TOTAL_ARTICLE_VIEWS = 'TOTAL_ARTICLE_VIEWS'
export const KB_PERFORMANCE_ANALYTICS = 'KB_PERFORMANCE_ANALYTICS'
export const MOST_POPULAR_PAGES = 'MOST_POPULAR_PAGES'
export const ARTICLE_RATINGS_ANALYTICS = 'ARTICLE_RATINGS_ANALYTICS'
export const CTR_STATS = 'CTR_STATS'

export const VISITOR_STATS = 'VISITOR_STATS'
export const VISITORS_OVERTIME = 'VISITORS_OVERTIME'
export const VISITORS_TOP_LIST = 'VISITORS_TOP_LIST'

export const AUTOMATION_BY_STATUS = 'AUTOMATION_BY_STATUS'
export const RULES_BY_USER = ' RULES_BY_USER'
export const CONTACT_IMPACTED = 'CONTACT_IMPACTED'
export const CONVERSATION_IMPACTED = 'CONVERSATION_IMPACTED'

export const OPEN_CONVERSATION_INBOX = 'OPEN_CONVERSATION_INBOX'
export const OPEN_CONVERSATION_INBOX_PRIORITY =
  'OPEN_CONVERSATION_INBOX_PRIORITY'
export const TOTAL_VISITORS_SEARCH = 'TOTAL_VISITORS_SEARCH'
export const SEARCH_ANALYTICS = 'SEARCH_ANALYTICS'
export const TOTAL_SEARCH_ANALYTICS = 'TOTAL_SEARCH_ANALYTICS'
export const CUST_SAT_CONVO = 'CUST_SAT_CONVO'
export const SUCCESSFUL_TERMS_TABLE = 'SUCCESSFUL_TERMS_TABLE'
export const FAILED_TERMS_TABLE = 'FAILED_TERMS_TABLE'


export const CONVERSATION_STATUS_ANALYTICS =
  'CONVERSATION_STATUS_ANALYTICS'

export const CREATE_CONTACT_FIELD = 'CREATE_CONTACT_FIELD'
export const EDIT_CONTACT_FIELD = 'EDIT_CONTACT_FIELD'
export const GET_ALL_CONTACT_FIELD = 'GET_ALL_CONTACT_FIELD'
export const GET_SINGLE_CONTACT_FIELD = 'GET_SINGLE_CONTACT_FIELD'
export const DELETE_CONTACT_FIELD = 'DELETE_CONTACT_FIELD'
export const GET_CONTACT_FIELD = 'GET_CONTACT_FIELD'

export const CREATE_TASK = 'CREATE_TASK'
export const GET_TASK = 'GET_TASK'
export const GET_ALL_TASK = 'GET_ALL_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const EDIT_TASK = 'EDIT_TASK'
export const CREATE_SUBTASK = 'CREATE_SUBTASK'
export const EDIT_SUBTASK = 'EDIT_SUBTASK'
export const DELETE_SUBTASK = 'DELETE_SUBTASK'
export const ADD_TASK = 'ADD_TASK'
export const UPDATE_SUB_TASK = 'UPDATE_SUB_TASK'
export const ADD_SUB_TASK = 'ADD_SUB_TASK'
export const DELETE_SUB_TASK = 'DELETE_SUB_TASK'

export const TICKET_ID_LIST = 'TICKET_ID_LIST'
export const GET_TASK_OF_CONTACT = 'GET_TASK_OF_CONTACT'
export const UPDATE_WIDGET_LOGO = 'UPDATE_WIDGET_LOGO'
export const DELETE_WIDGET_LOGO = 'DELETE_WIDGET_LOGO'
export const ACTIVITIES_DATA = 'ACTIVITIES_DATA'

export const GET_CONTACT_LIST_ALL = 'GET_CONTACT_LIST_ALL'
export const GET_TWITTER_CODE = 'GET_TWITTER_CODE'
export const GET_CHANNELS_ALL = 'GET_CHANNELS_ALL'
export const GET_CHANNEL = 'GET_CHANNEL'
export const EDIT_CHANNEL = 'EDIT_CHANNEL'
export const DELETE_CHANNEL = 'DELETE_CHANNEL'
export const ADD_WHATSAPP_CHANNEL = 'ADD_WHATSAPP_CHANNEL'
export const GET_TRAIL_LOGS = 'GET_TRAIL_LOGS'
export const GET_CONVERSATION_ACTIVITIES =
  'GET_CONVERSATION_ACTIVITIES'

export const EDIT_USER = 'EDIT_USER'
export const GET_USER = 'GET_USER'
export const GET_TAGS_SETTING = 'GET_TAGS_SETTING'
export const EDIT_TAG_SETTING = 'EDIT_TAG_SETTING'

export const EDIT_TEAMS = 'EDIT_TEAMS'
export const GET_TEAM_DATA = 'GET_TEAM_DATA'
export const GET_TEAMS_LIST = 'GET_TEAMS_LIST'
export const DELETE_TEAMS = 'DELETE_TEAMS'
export const CREATE_TEAMS = 'CREATE_TEAMS'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const PUT_NOTIFICATION = 'PUT_NOTIFICATION'
export const MASS_ACTION = 'MASS_ACTION'

export const GET_GENERAL_SETTING = 'GET_GENERAL_SETTING'
export const GET_GENERAL_SETTING_ORGANIZATION =
  'GET_GENERAL_SETTING_ORGANIZATION'
export const UPDATE_GENERAL_SETTING = 'UPDATE_GENERAL_SETTING'
export const UPDATE_GENERAL_SETTING_ORGANIZATION =
  'UPDATE_GENERAL_SETTING_ORGANIZATION'
export const PRODUCT_STATS = 'PRODUCT_STATS'
export const CREATE_ADDRESS = 'CREATE_ADDRESS'
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'
export const GET_ADDRESS_DATA = 'GET_ADDRESS_DATA'
export const GET_ALL_ADDRESSESS = 'GET_ALL_ADDRESSESS'
export const DELETE_ADDRESS = 'DELETE_ADDRESS'

export const START_PLAN_SUBSCRIPTION = 'START_PLAN_SUBSCRIPTION'
export const GET_PLAN_LIST = 'GET_PLAN_LIST'
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'

export const GET_ADDON_LIST = 'GET_ADDON_LIST'

export const CHANGE_CARD_DETAILS = 'CHANGE_CARD_DETAILS'
export const GET_PROFILE_INFO_BILLING = 'GET_PROFILE_INFO_BILLING'
export const UPDATE_PROFILE_INFO_BILLING =
  'UPDATE_PROFILE_INFO_BILLING'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const DELETE_SUBSCRIPTION_PERMANENT =
  'DELETE_SUBSCRIPTION_PERMANENT'
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE'
export const PAYMENT_METHOD = 'PAYMENT_METHOD'
export const CHANGE_PLAN_SUBSCRIPTION = 'CHANGE_PLAN_SUBSCRIPTION'
export const EXTEND_TRIAL = 'EXTEND_TRIAL'

export const TASK_BY_TYPES = 'TASK_BY_TYPES'
export const TASK_BY_STATUS = 'TASK_BY_STATUS'
export const TASK_TYPES_OVER_TIME = 'TASK_TYPES_OVER_TIME'
export const TASK_STATUS_OVER_TIME = 'TASK_STATUS_OVER_TIME'

export const CREATE_TASK_NOTE = 'CREATE_TASK_NOTE'
export const GET_ALL_TASK_SCROLL = 'GET_ALL_TASK_SCROLL'
export const CLEAR_ALL_TASK_SCROLL = 'CLEAR_ALL_TASK_SCROLL'

export const GET_ALL_TASK_TEMPLATES = 'GET_ALL_TASK_TEMPLATES'
export const CREATE_TASK_TEMPLATE = 'CREATE_TASK_TEMPLATE'
export const EDIT_TASK_TEMPLATE = 'EDIT_TASK_TEMPLATE'
export const DELETE_TASK_TEMPLATE = 'DELETE_TASK_TEMPLATE '
export const GET_TASK_TEMPLATE = 'GET_TASK_TEMPLATE'

export const GET_ALL_TASK_AUTOMATIONS = 'GET_ALL_TASK_AUTOMATIONS'
export const CREATE_TASK_AUTOMATION = 'CREATE_TASK_AUTOMATION'
export const EDIT_TASK_AUTOMATION = 'EDIT_TASK_AUTOMATION'
export const DELETE_TASK_AUTOMATION = 'DELETE_TASK_AUTOMATION '
export const GET_TASK_AUTOMATION = 'GET_TASK_AUTOMATION'

export const GET_LIVECHAT = 'GET_LIVECHAT'
export const DELETE_LIVECHAT = 'DELETE_LIVECHAT'
export const INSTALL_LIVECHAT = 'INSTALL_LIVECHAT'
export const UPDATE_LIVECHAT = 'UPDATE_LIVECHAT'

export const ADD_SHOPIFY = 'ADD_SHOPIFY'
export const GET_MAILBOX_SHOPIFY = 'GET_MAILBOX_SHOPIFY'
export const GET_CUSTOMER_DETAILS_SHOPIFY =
  'GET_CUSTOMER_DETAILS_SHOPIFY'
export const GET_CUSTOMER_ORDER_SHOPIFY = 'GET_CUSTOMER_ORDER_SHOPIFY'
export const GET_SHOPIFY_SHOP_LIST = 'GET_SHOPIFY_SHOP_LIST'
export const EDIT_SHOPIFY_SHOP = 'EDIT_SHOPIFY_SHOP'
export const DELETE_SHOPIFY_SHOP = 'DELETE_SHOPIFY_SHOP'

export const GET_SHOPIFY_PRODUCT_LIST = 'GET_SHOPIFY_PRODUCT_LIST'
export const CREATE_DRAFT_ORDER = 'CREATE_DRAFT_ORDER'
export const UPDATE_DRAFT_ORDER = 'UPDATE_DRAFT_ORDER'
export const DELETE_DRAFT_ORDER = 'DELETE_DRAFT_ORDER'
export const DRAFT_TO_COMPLETE_ORDER = 'DRAFT_TO_COMPLETE_ORDER'
export const CREATE_DUPLICATE_ORDER = 'CREATE_DUPLICATE_ORDER'
export const CALCULATE_REFUNDS_ORDER = 'CALCULATE_REFUNDS_ORDER'
export const CONFIRM_CANCEL_OR_REFUND_ORDER =
  'CONFIRM_CANCEL_OR_REFUND_ORDER'

export const GET_BIGCOMMERCE_STORE_CUSTOMER_DETAILS =
  'GET_BIGCOMMERCE_STORE_CUSTOMER_DETAILS'
export const GET_BIGCOMMERCE_PRODUCT_LIST =
  'GET_BIGCOMMERCE_PRODUCT_LIST'
export const CREATE_CUSTOMER_ORDER = 'CREATE_CUSTOMER_ORDER'
export const GET_BIGCOMMERCE_CUSTOMER_ADDRESS =
  'GET_BIGCOMMERCE_CUSTOMER_ADDRESS'
export const GET_BIGCOMMERCE_ORDER_LIST = 'GET_BIGCOMMERCE_ORDER_LIST'
export const BIGCOMMERCE_CALCULATE_REFUND =
  'BIGCOMMERCE_CALCULATE_REFUND'
export const BIGCOMMERCE_CONFIRM_REFUND = 'BIGCOMMERCE_CONFIRM_REFUND'
export const GET_BIGCOMMERCE_SHOP_LIST = 'GET_BIGCOMMERCE_SHOP_LIST'
export const GET_MAILBOX_BIGCOMMERCE = 'GET_MAILBOX_BIGCOMMERCE'
export const EDIT_BIGCOMMERCE_SHOP = 'EDIT_BIGCOMMERCE_SHOP'
export const GET_SMS_CHANNEL_LIST = 'GET_SMS_CHANNEL_LIST'
export const GET_SMS_SINGLE = 'GET_SMS_SINGLE'
export const UPDATE_SMS = 'UPDATE_SMS'
export const DELETE_SMS = 'DELETE_SMS'
export const ADD_SMS_CHANNEL = 'ADD_SMS_CHANNEL'
export const GET_PHONE_NUMBER = 'GET_PHONE_NUMBER'
export const GET_SHARED_SECRET_KEY = 'GET_SHARED_SECRET_KEY'

export const ADD_JIRA = 'ADD_JIRA'
export const GET_JIRA = 'GET_JIRA'
export const UPDATE_JIRA = 'UPDATE_JIRA'
export const DELETE_JIRA = 'DELETE_JIRA'
export const GET_JIRA_ISSUES = 'GET_JIRA_ISSUES'
export const JIRA_LINK_ISSUE_WITH_CONVERSATION =
  'JIRA_LINK_ISSUE_WITH_CONVERSATION'
export const GET_JIRA_CONVERSATION_ISSUES =
  'GET_JIRA_CONVERSATION_ISSUES'
export const JIRA_UNLINK_ISSUE_WITH_CONVERSATION =
  'JIRA_UNLINK_ISSUE_WITH_CONVERSATION'
export const GET_JIRA_PROJECT_ISSUES_TYPES =
  'GET_JIRA_PROJECT_ISSUES_TYPES'
export const GET_JIRA_USERS_LIST = 'GET_JIRA_USERS_LIST'
export const CREATE_JIRA_ISSUE_AND_LINK = 'CREATE_JIRA_ISSUE_AND_LINK'

export const GET_GITHUB = 'GET_GITHUB'
export const UPDATE_GITHUB = 'UPDATE_GITHUB'
export const STATE_GITHUB = 'STATE_GITHUB'
export const GET_GITHUB_CONVERSATION_ISSUES =
  'GET_GITHUB_CONVERSATION_ISSUES'
export const GET_GITHUB_REPOS = 'GET_GITHUB_REPOS'
export const GET_GITHUB_MILESTONES = 'GET_GITHUB_MILESTONES'
export const GET_GITHUB_LABELS = 'GET_GITHUB_LABELS'
export const GET_GITHUB_COLLABORATORS = 'GET_GITHUB_COLLABORATORS'
export const CREATE_GITHUB_ISSUE_AND_LINK =
  'CREATE_GITHUB_ISSUE_AND_LINK'
export const GET_GITHUB_ISSUES_FOR_REPO = 'GET_GITHUB_ISSUES_FOR_REPO'
export const GET_GITHUB_LINKED_ISSUE = 'GET_GITHUB_LINKED_ISSUE'
export const GITHUB_LINK_EXISTING_ISSUES =
  'GITHUB_LINK_EXISTING_ISSUES'
export const GITHUB_UNLINK_ISSUE = 'GITHUB_UNLINK_ISSUE'
export const GITHUB_CHANGE_STATE_ISSUE = 'GITHUB_CHANGE_STATE_ISSUE'
export const DELETE_GITHUB = 'DELETE_GITHUB'
export const GITHUB_RANDOM_STATE = 'GITHUB_RANDOM_STATE'

export const INTEGRATE_ASANA = 'INTEGRATE_ASANA'
export const INSTALL_ASANA = 'INSTALL_ASANA'
export const GET_ASANA = 'GET_ASANA'
export const UPDATE_ASANA = 'UPDATE_ASANA'
export const CREATE_ASANA_TASK_AND_LINK = 'CREATE_ASANA_TASK_AND_LINK'
export const GET_ASANA_WORKSPACES = 'GET_ASANA_WORKSPACES'
export const GET_ASANA_ASSIGNEES = 'GET_ASANA_ASSIGNEES'
export const GET_ASANA_TAGS = 'GET_ASANA_TAGS'
export const GET_ASANA_PROJECTS = 'GET_ASANA_PROJECTS'
export const GET_ASANA_TASK_FOR_PROJECT = 'GET_ASANA_TASK_FOR_PROJECT'
export const GET_ASANA_TASK_DETAILS = 'GET_ASANA_TASK_DETAILS'
export const GET_ASANA_LINK_EXISTING = 'GET_ASANA_LINK_EXISTING'
export const GET_LINKED_ASANA_TASKS = 'GET_LINKED_ASANA_TASKS'
export const ASANA_UNLINK_TASK = 'ASANA_UNLINK_TASK'
export const DELETE_ASANA = 'DELETE_ASANA'

export const DELETE_CLICKUP = 'DELETE_CLICKUP'
export const UPDATE_CLICKUP = 'UPDATE_CLICKUP'
export const GET_CLICKUP = 'GET_CLICKUP'
export const INSTALL_CLICKUP = 'INSTALL_CLICKUP'
export const INTEGRATE_CLICKUP = 'INTEGRATE_CLICKUP'
export const GET_CLICKUP_WORKSPACES = 'GET_CLICKUP_WORKSPACES'
export const GET_CLICKUP_SPACES = 'GET_CLICKUP_SPACES'
export const GET_CLICKUP_FOLDERS_WITHIN_SPACE =
  'GET_CLICKUP_FOLDERS_WITHIN_SPACE'
export const GET_CLICKUP_LIST_WITHIN_SPACE =
  'GET_CLICKUP_LIST_WITHIN_SPACE'
export const GET_CLICKUP_LIST_WITHIN_FOLDER =
  'GET_CLICKUP_LIST_WITHIN_FOLDER'

export const GET_CLICKUP_TASKS_FROM_LIST =
  'GET_CLICKUP_TASKS_FROM_LIST'
export const GET_CLICKUP_TASK_BY_ID = 'GET_CLICKUP_TASK_BY_ID'
export const GET_CLICKUP_TAGS = 'GET_CLICKUP_TAGS'
export const CLICKUP_CREATE_TASK = 'CLICKUP_CREATE_TASK'
export const CLICKUP_LINK_TASK = 'CLICKUP_LINK_TASK'
export const GET_CLICKUP_LINKED_TASKS = 'GET_CLICKUP_LINKED_TASKS'
export const CLICKUP_UNLINK_TASK = 'CLICKUP_UNLINK_TASK'

export const GET_TRELLO = 'GET_TRELLO'
export const UPDATE_TRELLO = 'UPDATE_TRELLO'
export const INSTALL_TRELLO = 'INSTALL_TRELLO'
export const DELETE_TRELLO = 'DELETE_TRELLO'
export const INTEGRATE_TRELLO = 'INTEGRATE_TRELLO'
export const TRELLO_UNLINK_CARD = 'TRELLO_UNLINK_CARD'
export const GET_TRELLO_LINKED_CARDS = 'GET_TRELLO_LINKED_CARDS'
export const GET_TRELLO_BOARDS = 'GET_TRELLO_BOARDS'
export const GET_TRELLO_LISTS = 'GET_TRELLO_LISTS'
export const GET_TRELLO_CARDS = 'GET_TRELLO_CARDS'
export const GET_TRELLO_LABELS = 'GET_TRELLO_LABELS'
export const GET_TRELLO_MEMBERS = 'GET_TRELLO_MEMBERS'
export const CREATE_TRELLO_CARD = 'CREATE_TRELLO_CARD'
export const TRELLO_LINK_EXISTING_CARD = 'TRELLO_LINK_EXISTING_CARD'


export const GET_MAGENTO = 'GET_MAGENTO'
export const CREATE_MAGENTO = 'CREATE_MAGENTO'
export const UPDATE_MAGENTO = 'UPDATE_MAGENTO'
export const DELETE_MAGENTO = 'DELETE_MAGENTO'
export const GET_MAGENTO_ORDERS = 'GET_MAGENTO_ORDERS'
export const GET_MAGENTO_ORDER_BY_ID = 'GET_MAGENTO_ORDER_BY_ID'
export const LINK_ORDER_MAGENTO = 'LINK_ORDER_MAGENTO'
export const CANCEL_ORDER_MAGENTO = 'CANCEL_ORDER_MAGENTO'
export const REFUND_ORDER_MAGENTO = 'REFUND_ORDER_MAGENTO'
export const GET_LINKED_ORDERS = 'GET_LINKED_ORDERS'
export const HOLD_ORDER_MAGENTO = 'HOLD_ORDER_MAGENTO'

export const DELETE_SLACK = 'DELETE_SLACK'
export const GET_SLACK = 'GET_SLACK'
export const INSTALL_SLACK = 'INSTALL_SLACK'
export const UPDATE_SLACK = 'UPDATE_SLACK'

export const DELETE_INTERCOM = 'DELETE_INTERCOM'
export const GET_INTERCOM = 'GET_INTERCOM'
export const INSTALL_INTERCOM = 'INSTALL_INTERCOM'
export const UPDATE_INTERCOM = 'UPDATE_SLACK'

export const DELETE_DRIFT = 'DELETE_DRIFT'
export const GET_DRIFT = 'GET_DRIFT'
export const INSTALL_DRIFT = 'INSTALL_DRIFT'
export const UPDATE_DRIFT = 'UPDATE_DRIFT'
export const GET_DRIFT_CONTACT = 'GET_DRIFT_CONTACT'

export const UPDATE_TIME_TRACKING = 'UPDATE_TIME_TRACKING'
export const GET_TIME_TRACKING = 'GET_TIME_TRACKING'
export const GET_TIME_TRACKING_CONVERSATION =
  'GET_TIME_TRACKING_CONVERSATION'
export const UPDATE_TIME_TRACKING_ENTRY = 'UPDATE_TIME_TRACKING_ENTRY'
export const SUBMIT_TIME_TRACKING_MANUAL =
  'SUBMIT_TIME_TRACKING_MANUAL'
export const SUBMIT_TIME_TRACKING_AUTOMATIC =
  'SUBMIT_TIME_TRACKING_AUTOMATIC'
export const GET_TIME_TRACKING_ENTRY_STATUS =
  'GET_TIME_TRACKING_ENTRY_STATUS'

export const GET_ENTERIES_CONVERSATION = 'GET_ENTERIES_CONVERSATION'
export const DELETE_ENTRIES = 'DELETE_ENTRIES'
export const MANUAL_TASK_ENTERIES = 'MANUAL_TASK_ENTERIES'
export const DELETE_ENTERIES_TASK = 'DELETE_ENTERIES_TASK'
export const UPDATE_ENTERIES_TASK = 'UPDATE_ENTERIES_TASK'
export const GET_MANUAL_TASK_ENTERIES = 'GET_MANUAL_TASK_ENTERIES'
export const GET_TIME_TRACKING_ENTRY_STATUS_TASK =
  'GET_TIME_TRACKING_ENTRY_STATUS_TASK'
export const GET_ENTERIES_TASK = 'GET_ENTERIES_TASK'

export const ADD_SEGMENT = 'ADD_SEGMENT'
export const UPDATE_SEGMENT = 'UPDATE_SEGMENT'
export const DELETE_SEGMENT = 'DELETE_SEGMENT'
export const GET_SEGMENT = 'GET_SEGMENT'
export const GET_ALL_SEGMENT = 'GET_ALL_SEGMENT'
export const GET_STATIC_CONTACT = 'GET_STATIC_CONTACT'
export const GET_INTEGRATION_LIST = 'GET_INTEGRATION_LIST'
export const GET_SEGMENTS_LIST = 'GET_SEGMENTS_LIST'

export const GET_ALL_COMPANY_SEGMENT = 'GET_ALL_COMPANY_SEGMENT'
export const GET_STATIC_COMPANY = 'GET_STATIC_COMPANY'
export const ADD_COMPANY_SEGMENT = 'ADD_COMPANY_SEGMENT'
export const UPDATE_COMPANY_SEGMENT = 'UPDATE_COMPANY_SEGMENT'
export const DELETE_COMPANY_SEGMENT = 'DELETE_COMPANY_SEGMENT'
export const GET_COMPANY_SEGMENT = 'GET_COMPANY_SEGMENT'

export const GET_INSTALLATION_WIDGET = 'GET_INSTALLATION_WIDGET'
export const UPDATE_APPEARENCE_WIDGET = 'UPDATE_APPEARENCE_WIDGET'
export const GET_APPEARENCE_WIDGET = 'GET_APPEARENCE_WIDGET'
export const UPDATE_CONFIGURE_WIDGET = 'UPDATE_CONFIGURE_WIDGET'
export const GET_CONFIGURE_WIDGET = 'GET_CONFIGURE_WIDGET'
export const UPDATE_ADVANCED_WIDGET = 'UPDATE_ADVANCED_WIDGET'
export const GET_ADVANCED_WIDGET = 'GET_ADVANCED_WIDGET'
export const CONFIGURE_LOGO_UPLOAD = 'CONFIGURE_LOGO_UPLOAD'

// analytics Matrics
export const METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS =
  'METRICS_FIRST_RESPONSE_BREAKDOWN_ANALYTICS'
export const METRICS_RESOLUTION_BREAKDOWN_ANALYTICS =
  'METRICS_RESOLUTION_BREAKDOWN_ANALYTICS'

// analytics Contacts
export const ADDED_VS_DELETED = 'ADDED_VS_DELETED'
export const ADDED_BY_SOURCE_OVERTIME = 'ADDED_BY_SOURCE_OVERTIME'
export const CONTACT_STATS = 'CONTACT_STATS'

// analytics Staffing Prediction
export const STAFFING_PREDICTION_OVERTIME =
  'STAFFING_PREDICTION_OVERTIME'

// analtics SMS Campaign
export const SMS_CAMPAIGN_STATS = 'SMS_CAMPAIGN_STATS'
export const SMS_SENT_DELIVERED_OVERTIME='SMS_SENT_DELIVERED_OVERTIME'
export const SMS_SUCCESS_RATE_OVERTIME = 'SMS_SUCCESS_RATE_OVERTIME'

// Campaign
export const GET_CAMPAIGN_LIST = 'GET_CAMPAIGN_LIST'
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN'
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS'
export const UPDATE_SMS_CAMPAIGN_STATUS = 'UPDATE_SMS_CAMPAIGN_STATUS'

export const GET_CAMPAIGN_FILTERS = 'GET_CAMPAIGN_FILTERS'
export const GET_CAMPAIGN_DATA = 'GET_CAMPAIGN_DATA'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const CAMPAIGN_ATTACHMENT_UPLOAD = 'CAMPAIGN_ATTACHMENT_UPLOAD'

export const CAMPAIGN_STATES_ANALYTICS = 'CAMPAIGN_STATES_ANALYTICS'
export const CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS =
  'CAMPAIGN_TOTAL_SENT_UNIQUE_VISITORS_ANALYTICS'

export const GET_CAMPAIGN_TEMPLATE_LIST = 'GET_CAMPAIGN_TEMPLATE_LIST'
export const GET_CAMPAIGN_TEMPLATE = 'GET_CAMPAIGN_TEMPLATE'
export const DELETE_CAMPAIGN_TEMPLATE = 'DELETE_CAMPAIGN_TEMPLATE'
export const UPDATE_CAMPAIGN_TEMPLATE = 'UPDATE_CAMPAIGN_TEMPLATE'
export const CREATE_CAMPAIGN_TEMPLATE = 'CREATE_CAMPAIGN_TEMPLATE'

// Workflow
export const GET_WORKFLOW = 'GET_WORKFLOW'
export const ADD_WORKFLOW = 'ADD_WORKFLOW'
export const GET_WORKFLOW_DATA = 'GET_WORKFLOW_DATA'
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW'
export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS'
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW'


export const GET_PUSH_SEGMENT_LIST = 'GET_PUSH_SEGMENT_LIST'
export const GET_PUSH_SEGMENT = 'GET_PUSH_SEGMENT'
export const CREATE_PUSH_SEGMENT = 'CREATE_PUSH_SEGMENT'
export const UPDATE_PUSH_SEGMENT = 'UPDATE_PUSH_SEGMENT'
export const DELETE_PUSH_SEGMENT = 'DELETE_PUSH_SEGMENT'

export const CREATE_SMS_CAMPAIGN = 'CREATE_SMS_CAMPAIGN'
export const GET_SINGLE_SMS_CAMPAIGN = 'GET_SINGLE_SMS_CAMPAIGN'
export const UPDATE_SMS_CAMPAIGN = 'UPDATE_SMS_CAMPAIGN'
export const DELETE_SMS_CAMPAIGN = 'DELETE_SMS_CAMPAIGN'

// Contacts
export const CREATE_CONTACT = 'CREATE_CONTACT'
export const GET_ALL_CONTACTS = 'GET_ALL_CONTACTS'
export const GET_ALL_CONTACTS_IDS='GET_ALL_CONTACTS_IDS'
export const GET_SINGLE_CONTACT = 'GET_SINGLE_CONTACT'
export const ADD_NOTE_CONTACT = 'ADD_NOTE_CONTACT'
export const GET_ALL_CONTACT_NOTES = 'GET_ALL_CONTACT_NOTES'
export const GET_ALL_ACTIVITIES_CONTACT = 'GET_ALL_ACTIVITIES_CONTACT'
export const CONTACT_STATUS_LIST = 'CONTACT_STATUS_LIST'
export const CONTACT_TYPE_LIST = 'CONTACT_TYPE_LIST'
export const DELETE_CONTACT_TYPE = 'DELETE_CONTACT_TYPE'
export const UPDATE_CONTACT_TYPE = 'UPDATE_CONTACT_TYPE'
export const ADD_CONTACT_TYPE = 'ADD_CONTACT_TYPE'
export const MULTIPLE_UPDATE_CONTACT = 'MULTIPLE_UPDATE_CONTACT'
export const SAMPLE_CONTACTS_IMPORT = 'SAMPLE_CONTACTS_IMPORT'
export const IMPORT_CONTACTS = 'IMPORT_CONTACTS'
export const GET_COLUMNS_SELECTION_CONTACT = 'GET_COLUMNS_SELECTION_CONTACT'
export const COLUMNS_SELECTION_CONTACT = 'COLUMNS_SELECTION_CONTACT'
export const CONTACT_ALL_COMPANIES = 'CONTACT_ALL_COMPANIES'
export const MULTIPLE_MERGE_CONTACT = 'MULTIPLE_MERGE_CONTACT'
export const ATTACHMENT_POST_CONTACT = 'ATTACHMENT_POST_CONTACT'
export const ADD_CONTACT_WEBSITE = 'ADD_CONTACT_WEBSITE'
export const UPDATE_CONTACT_WEBSITE = 'UPDATE_CONTACT_WEBSITE'
export const UPDATE_PHONE_CONTACT = 'UPDATE_PHONE_CONTACT'
export const EXPORT_ALL_CONTACTS = 'EXPORT_ALL_CONTACTS'
export const ADD_PHONE_CONTACT = 'ADD_PHONE_CONTACT'
export const UPDATE_EMAIL_CONTACT = 'UPDATE_EMAIL_CONTACT'
export const DELETE_CONTACT_EMAIL = 'DELETE_CONTACT_EMAIL'
export const ADD_EMAIL_CONTACT = 'ADD_EMAIL_CONTACT'
export const DELETE_CONTACT_PHONE = 'DELETE_CONTACT_PHONE'
export const DELETE_CONTACT_WEBSITE = 'DELETE_CONTACT_WEBSITE'
export const ADD_SOCIAL = 'ADD_SOCIAL'
export const UPDATE_SOCIAL = 'UPDATE_SOCIAL'
export const REMOVE_SOCIAL = 'REMOVE_SOCIAL'
export const ADD_PHONE_COMPANY = 'ADD_PHONE_COMPANY'
export const UPDATE_PHONE_COMPANY = 'UPDATE_PHONE_COMPANY'
export const ADD_EMAIL_COMPANY = 'ADD_EMAIL_COMPANY'
export const UPDATE_EMAIL_COMPANY = 'UPDATE_EMAIL_COMPANY'
export const GET_TAG_SETTING = 'GET_TAG_SETTING'
export const GET_ALL_TAG_LABEL = 'GET_ALL_TAG_LABEL'
export const DELETE_COMPANY_PHONE = 'DELETE_COMPANY_PHONE'
export const DELETE_COMPANY_EMAIL = 'DELETE_COMPANY_EMAIL'
export const ADD_COMPANY_WEBSITE = 'ADD_COMPANY_WEBSITE'
export const UPDATE_COMPANY_WEBSITE = 'UPDATE_COMPANY_WEBSITE'
export const DELETE_COMPANY_WEBSITE = 'DELETE_COMPANY_WEBSITE'
export const GET_ACCOUNT_SETTING = 'GET_ACCOUNT_SETTING'
export const UPDATE_ACCOUNT_SETTING = 'UPDATE_ACCOUNT_SETTING'
export const UPDATE_CONTACT_STATUS = 'UPDATE_CONTACT_STATUS'
export const DELETE_CONTACT_STATUS = 'DELETE_CONTACT_STATUS'
export const GET_CONTACT_STATUSES = 'GET_CONTACT_STATUSES';
export const ADD_CONTACT_STATUS = 'ADD_CONTACT_STATUS';

// Interaction
export const ADD_COMPANY_INTERACTION = 'ADD_COMPANY_INTERACTION'
export const ADD_INTERACTION = 'ADD_INTERACTION'
export const ADD_CONTACT_INTERACTION = 'ADD_CONTACT_INTERACTION'
export const ADD_DEAL_INTERACTION = 'ADD_DEAL_INTERACTION'

export const ADD_NOTE_COMPANY = 'ADD_NOTE_COMPANY'
export const ADD_NOTE_DEAL = 'ADD_NOTE_DEAL'

// <-- products -->
// defaults -> category
export const GET_PRODUCTS_CATEGORY = 'GET_PRODUCTS_CATEGORY';
export const CREATE_PRODUCTS_CATEGORY = 'CREATE_PRODUCTS_CATEGORY';
export const GET_SINGLE_PRODUCTS_CATEGORY = 'GET_SINGLE_PRODUCTS_CATEGORY';
// export const EDIT_PRODUCTS_CATEGORY = "EDIT_PRODUCTS_CATEGORY";
export const DELETE_PRODUCTS_CATEGORY = 'DELETE_PRODUCTS_CATEGORY';

export const CREATE_PRODUCTS_FIELD = 'CREATE_PRODUCTS_FIELD';
export const GET_PRODUCTS_FIELDLIST = 'GET_PRODUCTS_FIELDLIST';
export const GET_PRODUCTS_FIELD = 'GET_PRODUCTS_FIELD';
export const EDIT_PRODUCTS_FIELD = 'EDIT_PRODUCTS_FIELD';
export const DELETE_PRODUCTS_FIELD = 'DELETE_PRODUCTS_FIELD';
export const GET_FIELD_BY_CATEGORY = 'GET_FIELD_BY_CATEGORY';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_MULTIPLE_PRODUCTS = 'DELETE_MULTIPLE_PRODUCTS';
export const GET_PRODUCT_DATA = 'GET_PRODUCT_DATA';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const CLONE_PRODUCT = 'CLONE_PRODUCT';

export const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';
export const EXPORT_ALL_PRODUCTS = 'EXPORT_ALL_PRODUCTS';
export const EXPORT_SELECTED_PRODUCTS = 'EXPORT_SELECTED_PRODUCTS';
export const EXPORT_SAMPLE_PRODUCTS_CSV = 'EXPORT_SAMPLE_PRODUCTS_CSV';
export const DELETE_PRODUCT_IMAGE = 'DELETE_PRODUCT_IMAGE';

// Currency
export const GET_ALL_CURRENCY = 'GET_ALL_CURRENCY';
export const GET_ACCOUNT_CURRENCY = 'GET_ACCOUNT_CURRENCY';
export const GET_ACCOUNT_CURRENCY_NOTE_ADDED =
  'GET_ACCOUNT_CURRENCY_NOTE_ADDED';
export const ADD_ACCOUNT_CURRENCY = 'ADD_ACCOUNT_CURRENCY';
export const UPDATE_ACCOUNT_CURRENCY = 'UPDATE_ACCOUNT_CURRENCY';
export const UPDATE_ACCOUNT_CURRENCY_BASE = 'UPDATE_ACCOUNT_CURRENCY_BASE';
export const DELETE_ACCOUNT_CURRENCY = 'DELETE_ACCOUNT_CURRENCY';

// Deals
export const GET_ALL_DEALS = 'GET_ALL_DEALS';
export const ADD_DEAL = 'ADD_DEAL';
export const WIN_DEAL = 'WIN_DEAL';
export const LOSS_DEAL = 'LOSS_DEAL';
export const ABANDON_DEAL = 'ABANDON_DEAL';
export const GET_LOST_REASONS = 'GET_LOST_REASONS';
export const ADD_LOST_REASONS = 'ADD_LOST_REASONS';
export const UPDATE_LOST_REASONS = 'UPDATE_LOST_REASONS';
export const DELETE_LOST_REASONS = 'DELETE_LOST_REASONS';
export const GET_SOURCES = 'GET_SOURCES';
export const ADD_SOURCES = 'ADD_SOURCES';
export const UPDATE_SOURCES = 'UPDATE_SOURCES';
export const DELETE_SOURCES = 'DELETE_SOURCES';
export const UNLINK_PRODUCT = 'UNLINK_PRODUCT';
export const EDIT_DEAL = 'EDIT_DEAL';
export const COLUMNS_SELECTION_DEALS = 'COLUMNS_SELECTION_DEALS'
export const GET_COLUMNS_SELECTION_DEALS = 'GET_COLUMNS_SELECTION_DEALS'
export const GET_STAGE_DEALS = 'GET_STAGE_DEALS'
export const GET_SOURCE_DEALS = 'GET_SOURCE_DEALS'
export const GET_SINGLE_DEALS = 'GET_SINGLE_DEALS'
export const MULTIPLE_UPDATE_DEAL = 'MULTIPLE_UPDATE_DEAL'
export const UNLINK_CONTACT = 'UNLINK_CONTACT'
export const PRIMARY_CONTACT_CHANGE = 'PRIMARY_CONTACT_CHANGE'
export const ADD_CONTACT_DEAL = 'ADD_CONTACT_DEAL'
export const DELETE_DEAL = 'DELETE_DEAL'
export const CREATE_PIPELINE = 'CREATE_PIPELINE'
export const UPDATE_PIPELINE = 'UPDATE_PIPELINE'
export const GET_ALL_PIPELINES = 'GET_ALL_PIPELINES'
export const DELETE_PIPELINE = 'DELETE_PIPELINE'
export const ATTACHMENT_POST_DEAL = 'ATTACHMENT_POST_DEAL'

// EXPORT
export const EXPORT_ALL_DEALS = 'EXPORT_ALL_DEALS';

// import
export const IMPORT_DEALS = 'IMPORT_DEALS';
// SAMPLE IMPORT
export const SAMPLE_DEALS_IMPORT = 'SAMPLE_DEALS_IMPORT';

// Quotes
export const CREATE_QUOTE_PREFIX = 'CREATE_QUOTE_PREFIX';
export const GET_QUOTE_PREFIX = 'GET_QUOTE_PREFIX';
export const DELETE_QUOTE_PREFIX = 'DELETE_QUOTE_PREFIX';
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const UPDATE_QUOTE = 'UPDATE_QUOTE';
export const DELETE_QUOTE = 'DELETE_QUOTE';
export const GET_SINGLE_QUOTE = 'GET_SINGLE_QUOTE';
export const GET_QUOTE_LIST = 'GET_QUOTE_LIST';
export const GET_NEW_QUOTE_NUMBER = 'GET_NEW_QUOTE_NUMBER';
export const COPY_QUOTE = 'COPY_QUOTE';
export const UPDATE_LOGO = 'UPDATE_LOGO';
export const DELETE_LOGO = 'DELETE_LOGO';
export const PUBLISH_QUOTE = 'PUBLISH_QUOTE';
export const GET_QUOTE_PUBLIC_LINK = 'GET_QUOTE_PUBLIC_LINK';

// Sequence
export const GET_ALL_SEQUENCES = 'GET_ALL_SEQUENCES';
export const GET_SEQUENCE = 'GET_SEQUENCE';
export const CREATE_SEQUENCE = 'CREATE_SEQUENCE';
export const EDIT_SEQUENCE = 'EDIT_SEQUENCE';
export const DELETE_SEQUENCE = 'DELETE_SEQUENCE';
export const UPDATE_SEQUENCE_STATUS = 'UPDATE_SEQUENCE_STATUS';
export const SEQUENCE_CONTACTS = 'SEQUENCE_CONTACTS';
export const SEQUENCE_EMAILS = 'SEQUENCE_EMAILS';
export const SEQUENCE_OVERVIEW = 'SEQUENCE_OVERVIEW';

// Forecast
export const UPDATE_FORECAST_SETTING = 'UPDATE_FORECAST_SETTING';
export const GET_FORECAST_SETTING = 'GET_FORECAST_SETTING';
export const ADD_FORECAST = 'ADD_FORECAST';
export const UPDATE_FORECAST = 'UPDATE_FORECAST';
export const DELETE_FORECAST = 'DELETE_FORECAST';
export const GET_FORECAST_LIST = 'GET_FORECAST_LIST';
export const GET_FORECAST_DETAIL = 'GET_FORECAST_DETAIL';
export const BREAK_DOWN_BY_KPI = 'BREAK_DOWN_BY_KPI';
export const GOAL_KPI_BREAK_DOWN = 'GOAL_KPI_BREAK_DOWN';
export const FORECAST_SUMMARY = 'FORECAST_SUMMARY';

// Analytics

export const CONTACT_TAGS = 'CONTACT_TAGS';
export const COMPANY_TAGS = 'COMPANY_TAGS';
export const DEAL_TAGS = 'DEAL_TAGS';

// Home analytics
export const HOME_STATS = 'HOME_STATS';
export const STATUS_WISE_DEALS = 'STATUS_WISE_DEALS';
export const TOP_TASKS = 'TOP_TASKS';
export const SALES_INFO = 'SALES_INFO';
export const CONVERSATION_BY_CHANNELS = 'CONVERSATION_BY_CHANNELS'
export const PROJECTS_BY_BUDGET_TYPE = 'PROJECTS_BY_BUDGET_TYPE'
export const PROJECTS_TIMES_BY_USER = 'PROJECTS_TIMES_BY_USER'

// Email analytics
export const EMAIL_STATS = 'EMAIL_STATS';
export const EMAIL_OPEN_VS_TIME = 'EMAIL_OPEN_VS_TIME';
export const EMAIL_CLICK_VS_TIME = 'EMAIL_CLICK_VS_TIME';
export const EMAIL_TEMPLATE_STATS = '  EMAIL_TEMPLATE_STATS';

// COMPANIES ANALYTICS
export const COMPANIES_STATS = 'COMPANIES_STATS';
export const COMPANIES_BY_STATUS = 'COMPANIES_BY_STATUS';

// Deal Conversion
export const ESTIMATED_REVENUE = 'ESTIMATED_REVENUE';
export const WIN_RATIO = 'WIN_RATIO';

// DEALS ANALYTICS
export const TOTAL_DEALS = 'TOTAL_DEALS';
export const OPEN_DEALS_BY_STAGES = 'OPEN_DEALS_BY_STAGES';
export const OPEN_DEALS_OVER_TIME = 'OPEN_DEALS_OVER_TIME';
export const WON_DEALS_OVER_TIME = 'WON_DEALS_OVER_TIME';
export const LOST_DEALS_OVER_TIME = 'LOST_DEALS_OVER_TIME';
export const DEAL_STATUS_BREAKDOWN = 'DEAL_STATUS_BREAKDOWN';
export const DEAL_STAGE_BREAKDOWN = 'DEAL_STAGE_BREAKDOWN';
export const DEAL_BY_USERS = 'DEAL_BY_USERS';
export const DEAL_BY_LOSS_REASON = 'DEAL_BY_LOSS_REASON';
export const DEAL_FUNNEL_REPORT = 'DEAL_FUNNEL_REPORT';
export const DEAL_ANALYTICS_BY_USERS = 'DEAL_ANALYTICS_BY_USERS';

// PRODUCTS ANALYTICS
export const WON_DEALS_BY_PRODUCTS = 'WON_DEALS_BY_PRODUCTS';
export const TOTAL_REVENUE_BY_PRODUCTS = 'TOTAL_REVENUE_BY_PRODUCTS';
export const TOTAL_QUANTITY_SOLD_BY_PRODUCTS =
  'TOTAL_QUANTITY_SOLD_BY_PRODUCTS';
export const CURRENT_DEAL_BY_PRODUCTS = 'CURRENT_DEAL_BY_PRODUCTS';
export const CONTACT_BY_PRODUCTS = 'CONTACT_BY_PRODUCTS';

  // QUOTES ANALYTICS
export const QUOTES_OVER_TIME = 'QUOTES_OVER_TIME';
export const QUOTES_BY_PRODUCTS = 'QUOTES_BY_PRODUCTS';
export const QUOTES_BY_OWNER = 'QUOTES_BY_OWNER';
export const QUOTES_BY_OPEN_DEALS = 'QUOTES_BY_OPEN_DEALS';
export const QUOTES_BY_CLOSE_DEALS = 'QUOTES_BY_CLOSE_DEALS';

// TASKS ANALYTICS
export const TASKS_BY_TYPES = 'TASKS_BY_TYPES';
export const TASKS_BY_STATUS = 'TASKS_BY_STATUS';
export const TASKS_BY_TYPES_OVER_TIME = 'TASKS_BY_TYPES_OVER_TIME';
export const TASKS_BY_STATUS_OVER_TIME = 'TASK_BY_USERS';
export const TASK_BY_USERS = 'TASKS_BY_STATUS_OVER_TIME';

// Template Analytics
export const TEMPLATE_OVER_TIME = 'TEMPLATE_OVER_TIME'
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE'

// VENDORS

export const GET_VENDORS_CATEGORY = 'GET_VENDORS_CATEGORY'
export const CREATE_VENDORS_CATEGORY = 'CREATE_VENDORS_CATEGORY'
export const DELETE_VENDORS_CATEGORY = 'DELETE_VENDORS_CATEGORY'
export const GET_SINGLE_VENDORS_CATEGORY = 'GET_SINGLE_VENDORS_CATEGORY'
export const EDIT_VENDORS_CATEGORY = 'EDIT_VENDORS_CATEGORY'

export const CREATE_VENDORS_FIELD = 'CREATE_VENDORS_FIELD'
export const GET_VENDORS_FIELD = 'GET_VENDORS_FIELD'
export const GET_SINGLE_VENDORS_FIELD = 'GET_SINGLE_VENDORS_FIELD'
export const EDIT_VENDORS_FIELD = 'EDIT_VENDORS_FIELD'
export const DELETE_VENDORS_FIELD = 'DELETE_VENDORS_FIELD'
export const GET_VENDOR_FIELD_BY_CATEGORY = 'GET_VENDOR_FIELD_BY_CATEGORY'

export const CREATE_VENDORS = 'CREATE_VENDORS'
export const GET_VENDORS_LIST = 'GET_VENDORS_LIST'
export const DELETE_VENDORS = 'DELETE_VENDORS'
export const GET_VENDORS_DATA = 'GET_VENDORS_DATA'
export const UPDATE_VENDORS = 'UPDATE_VENDORS'
export const EXPORT_ALL_VENDORS = 'EXPORT_ALL_VENDORS'
export const DELETE_VENDOR_IMAGE='DELETE_VENDOR_IMAGE'

// Purchases
export const GET_PURCHASES_CATEGORY = 'GET_PURCHASES_CATEGORY'
export const CREATE_PURCHASES_CATEGORY = 'CREATE_PURCHASES_CATEGORY'
export const DELETE_PURCHASES_CATEGORY = 'DELETE_PURCHASES_CATEGORY'
export const GET_SINGLE_PURCHASES_CATEGORY = 'GET_SINGLE_PURCHASES_CATEGORY'
export const EDIT_PURCHASES_CATEGORY = 'EDIT_PURCHASES_CATEGORY'

export const CREATE_PURCHASES = 'CREATE_PURCHASES'
export const GET_PURCHASES_LIST = 'GET_PURCHASES_LIST'
export const DELETE_PURCHASES = 'DELETE_PURCHASES'
export const GET_PURCHASES_DATA = 'GET_PURCHASES_DATA'
export const UPDATE_PURCHASES = 'UPDATE_PURCHASES'

// Vendors Reports
export const TOTAL_VENDORS = 'TOTAL_VENDORS'
export const NEW_VENDORS = 'NEW_VENDORS'
export const VENDORS_BY_CATEGORY = 'VENDORS_BY_CATEGORY'
export const VENDORS_OVER_TIME = 'VENDORS_OVER_TIME'

// Purchases Reports
export const PURCHASES_STATS = 'PURCHASES_STATS'
export const PURCHASES_AMOUNTS = 'PURCHASES_AMOUNTS'
export const PURCHASES_NUMBERS_BY_CATEGORY = 'PURCHASES_NUMBERS_BY_CATEGORY'
export const PURCHASES_AMOUNT_BY_CATEGORY = 'PURCHASES_AMOUNT_BY_CATEGORY'
export const PURCHASES_NUMBER_OVERTIME = 'PURCHASES_NUMBER_OVERTIME'
export const PURCHASES_AMOUNT_OVERTIME = 'PURCHASES_AMOUNT_OVERTIME'

// Purchase Orders Reports
export const TOTAL_PURCHASE_ORDERS = 'TOTAL_PURCHASE_ORDERS'
export const PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS = 'PURCHASE_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS'
export const PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS = 'PURCHASE_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS'
export const NUMBER_OF_PURCHASE_ORDER_BY_USERS = 'NUMBER_OF_PURCHASE_ORDER_BY_USERS'
export const AMOUNT_OF_PURCHASE_ORDER_BY_USERS = 'AMOUNT_OF_PURCHASE_ORDER_BY_USERS'
export const PURCHASE_ORDER_COUNT_OVERTIME = 'PURCHASE_ORDER_COUNT_OVERTIME'
export const PURCHASE_ORDER_AMOUNT_OVERTIME = 'PURCHASE_ORDER_AMOUNT_OVERTIME'

// Sales Orders Reports
export const TOTAL_SALES_ORDERS = 'TOTAL_SALES_ORDERS'
export const SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS = 'SALES_ORDER_NUMBER_BREAKDOWN_BY_PRODUCTS'
export const SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS = 'SALES_ORDER_AMOUNT_BREAKDOWN_BY_PRODUCTS'
export const NUMBER_OF_SALES_ORDER_BY_USERS = 'NUMBER_OF_SALES_ORDER_BY_USERS'
export const AMOUNT_OF_SALES_ORDER_BY_USERS = 'AMOUNT_OF_SALES_ORDER_BY_USERS'
export const SALES_ORDER_COUNT_OVERTIME = 'SALES_ORDER_COUNT_OVERTIME'
export const SALES_ORDER_AMOUNT_OVERTIME = 'SALES_ORDER_AMOUNT_OVERTIME'

// Sales Orders Reports
export const TOTAL_INVOICES = 'TOTAL_INVOICES'
export const INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS = 'INVOICES_NUMBER_BREAKDOWN_BY_PRODUCTS'
export const INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS = 'INVOICES_AMOUNT_BREAKDOWN_BY_PRODUCTS'
export const NUMBER_OF_INVOICES_BY_USERS = 'NUMBER_OF_INVOICES_BY_USERS'
export const AMOUNT_OF_INVOICES_BY_USERS = 'AMOUNT_OF_INVOICES_BY_USERS'
export const INVOICES_COUNT_OVERTIME = 'INVOICES_COUNT_OVERTIME'
export const INVOICES_AMOUNT_OVERTIME = 'INVOICES_AMOUNT_OVERTIME'

// Purchase Orders
export const GET_PREFIX = 'GET_PREFIX'
export const CREATE_PREFIX = 'CREATE_PREFIX'
export const DELETE_PREFIX = 'DELETE_PREFIX'
export const GET_NEW_PO_NUMBER = 'GET_NEW_PO_NUMBER'

export const CREATE_PURCHASE_ORDERS = 'CREATE_PURCHASE_ORDERS'
export const GET_PURCHASE_OREDRS_LIST = 'GET_PURCHASE_OREDRS_LIST'
export const DELETE_PURCHASE_ORDERS = 'DELETE_PURCHASE_ORDERS'
export const GET_PURCHASE_ORDERS_DATA = 'GET_PURCHASE_ORDERS_DATA'
export const UPDATE_PURCHASE_ORDERS = 'UPDATE_PURCHASE_ORDERS'
export const COPY_PURCHASE_ORDER = 'COPY_PURCHASE_ORDER'
export const DELETE_PO_LOGO = 'DELETE_PO_LOGO'

// Projects
export const CREATE_PROJECT ='CREATE_PROJECT'
export const GET_PROJECTS_LIST ='GET_PROJECTS_LIST'
export const GET_PROJECTS_LOOKUP ='GET_PROJECTS_LOOKUP'
export const GET_PROJECTS_FILTERS='GET_PROJECTS_FILTERS'
export const DELETE_PROJECT ='DELETE_PROJECT'
export const GET_SINGLE_PROJECT ='GET_SINGLE_PROJECT'
export const UPDATE_PROJECT ='UPDATE_PROJECT'
export const CLONE_PROJECT ='CLONE_PROJECT'
export const UPDATE_PROJECT_STATUS ='UPDATE_PROJECT_STATUS'
export const ARCHIVED_PROJECT ='ARCHIVED_PROJECT'
export const FAVOURITE_PROJECT ='FAVOURITE_PROJECT'
export const UPDATE_PROJECT_PEOPLE='UPDATE_PROJECT_PEOPLE'
export const CREATE_PROJECT_DISCUSSION_TOPIC ='CREATE_PROJECT_DISCUSSION_TOPIC'
export const GET_PROJECT_DISCUSSION_TOPICS ='GET_PROJECT_DISCUSSION_TOPICS'
export const GET_PROJECT_DISCUSSION_SINGLE_TOPIC ='GET_PROJECT_DISCUSSION_SINGLE_TOPIC'
export const UPDATE_PROJECT_DISCUSSION_TOPIC ='UPDATE_PROJECT_DISCUSSION_TOPIC'
export const DELETE_PROJECT_DISCUSSION_TOPIC ='DELETE_PROJECT_DISCUSSION_TOPIC'
export const UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS ='UPDATE_PROJECT_DISCUSSION_TOPIC_SUBSCRIBERS'
export const ARCHIVE_PROJECT_DESCUSSION_TOPIC='ARCHIVE_PROJECT_DESCUSSION_TOPIC'
export const PIN_PROJECT_DESCUSSION_TOPIC='PIN_PROJECT_DESCUSSION_TOPIC'
export const GET_PROJECT_DISCUSSION_TOPIC_COMMENTS='GET_PROJECT_DISCUSSION_TOPIC_COMMENTS'
export const CREATE_PROJECT_DISCUSSION_TOPIC_COMMENT='CREATE_PROJECT_DISCUSSION_TOPIC_COMMENT'
export const UPDATE_PROJECT_DISCUSSION_TOPIC_COMMENT='UPDATE_PROJECT_DISCUSSION_TOPIC_COMMENT'
export const DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT='DELETE_PROJECT_DISCUSSION_TOPIC_COMMENT'
export const CREATE_PROJECT_TASKS_LIST='CREATE_PROJECT_TASKS_LIST'
export const GET_PROJECTS_TASKS_LIST='GET_PROJECTS_TASKS_LIST'
export const GET_PROJECTS_SINGLE_TASKS_LIST='GET_PROJECTS_SINGLE_TASKS_LIST'
export const UPDATE_PROJECT_TASKS_LIST='UPDATE_PROJECT_TASKS_LIST'
export const DELETE_PROJECTS_TASKS_LIST = 'DELETE_PROJECTS_TASKS_LIST'
export const STATUS_PROJECTS_TASKS_LIST = 'STATUS_PROJECTS_TASKS_LIST'
export const COPY_PROJECTS_TASK_LIST = 'COPY_PROJECTS_TASK_LIST'
export const MOVE_PROJECTS_TASK_LIST = 'MOVE_PROJECTS_TASK_LIST'
export const GET_PROJECTS_TASKS_LIST_FILTER='GET_PROJECTS_TASKS_LIST_FILTER'
export const GET_PROJECTS_TASKS_LIST_TASKS='GET_PROJECTS_TASKS_LIST_TASKS'
export const CREATE_PROJECTS_TASKS_LIST_TASK='CREATE_PROJECTS_TASKS_LIST_TASK'
export const UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE='UPDATE_PROJECTS_TASKS_LIST_TASK_STAGE'
export const GET_PROJECTS_TASKS_LIST_SINGLE_TASK='GET_PROJECTS_TASKS_LIST_SINGLE_TASK'
export const UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK='UPDATE_PROJECTS_TASKS_LIST_SINGLE_TASK'
export const UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE='UPDATE_PROJECTS_TASKS_LIST_TASK_ASSIGNEE'
export const DELETE_PROJECTS_TASKS_LIST_TASK='DELETE_PROJECTS_TASKS_LIST_TASK'
export const COPY_PROJECTS_TASKS_LIST_TASK='COPY_PROJECTS_TASKS_LIST_TASK'
export const MOVE_PROJECTS_TASKS_LIST_TASK='MOVE_PROJECTS_TASKS_LIST_TASK'
export const STATUS_PROJECTS_TASKS_LIST_TASK='STATUS_PROJECTS_TASKS_LIST_TASK'
export const COMPLETE_PROJECTS_TASKS_LIST_TASK='COMPLETE_PROJECTS_TASKS_LIST_TASK'
export const CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT='CREATE_PROJECTS_TASKS_LIST_TASK_COMMENT'
export const CREATE_PROJECTS_NOTEBOOK='CREATE_PROJECTS_NOTEBOOK'
export const GET_PROJECTS_NOTEBOOKS='GET_PROJECTS_NOTEBOOKS'
export const GET_PROJECTS_SINGLE_NOTEBOOK='GET_PROJECTS_SINGLE_NOTEBOOK'
export const UPDATE_PROJECTS_NOTEBOOK = 'UPDATE_PROJECTS_NOTEBOOK'
export const DELETE_PROJECTS_NOTEBOOK = 'DELETE_PROJECTS_NOTEBOOK'
export const COPY_PROJECTS_NOTEBOOK='COPY_PROJECTS_NOTEBOOK'
export const MOVE_PROJECTS_NOTEBOOK='MOVE_PROJECTS_NOTEBOOK'
export const PIN_PROJECT_NOTEBOOK='PIN_PROJECT_NOTEBOOK'
export const CREATE_PROJECTS_NOTEBOOK_NOTE='CREATE_PROJECTS_NOTEBOOK_NOTE'
export const GET_PROJECTS_NOTEBOOK_NOTES='GET_PROJECTS_NOTEBOOK_NOTES'
export const GET_PROJECTS_NOTEBOOK_SINGLE_NOTE='GET_PROJECTS_NOTEBOOK_SINGLE_NOTE'
export const UPDATE_PROJECTS_NOTEBOOK_NOTE='UPDATE_PROJECTS_NOTEBOOK_NOTE'
export const DELETE_PROJECTS_NOTEBOOK_NOTE='DELETE_PROJECTS_NOTEBOOK_NOTE'
export const UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS='UPDATE_PROJECTS_NOTEBOOK_NOTE_SUBSCRIBERS'
export const COPY_PROJECTS_NOTEBOOK_NOTE='COPY_PROJECTS_NOTEBOOK_NOTE'
export const MOVE_PROJECTS_NOTEBOOK_NOTE='MOVE_PROJECTS_NOTEBOOK_NOTE'
export const UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION='UPDATE_PROJECTS_NOTEBOOK_NOTE_DESCRIPTION'
export const CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT='CREATE_PROJECTS_NOTEBOOK_NOTE_COMMENT'
export const UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT='UPDATE_PROJECTS_NOTEBOOK_NOTE_COMMENT'
export const DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT='DELETE_PROJECTS_NOTEBOOK_NOTE_COMMENT'
export const CREATE_PROJECTS_TIMESHEET='CREATE_PROJECTS_TIMESHEET'
export const GET_PROJECTS_TIMESHEETS='GET_PROJECTS_TIMESHEETS'
export const GET_PROJECTS_SINGLE_TIMESHEET='GET_PROJECTS_SINGLE_TIMESHEET'
export const UPDATE_PROJECTS_TIMESHEET='UPDATE_PROJECTS_TIMESHEET'
export const DELETE_PROJECTS_TIMESHEET='DELETE_PROJECTS_TIMESHEET'
export const STATUS_PROJECTS_TIMESHEET='STATUS_PROJECTS_TIMESHEET'
export const CREATE_PROJECTS_TIMESHEET_TIME_LOG='CREATE_PROJECTS_TIMESHEET_TIME_LOG'
export const GET_PROJECTS_TIMESHEET_TIME_LOGS='GET_PROJECTS_TIMESHEET_TIME_LOGS'
export const GET_PROJECTS_TIMESHEET_SUMMARY='GET_PROJECTS_TIMESHEET_SUMMARY'
export const GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG='GET_PROJECTS_TIMESHEET_SINGLE_TIME_LOG'
export const UPDATE_PROJECTS_TIMESHEET_TIME_LOG='UPDATE_PROJECTS_TIMESHEET_TIME_LOG'
export const EXPORT_PROJECTS_TIMESHEET='EXPORT_PROJECTS_TIMESHEET'
export const DELETE_PROJECTS_TIMESHEET_TIME_LOGS='DELETE_PROJECTS_TIMESHEET_TIME_LOGS'
export const STATUS_PROJECTS_TIMESHEET_TIME_LOGS='STATUS_PROJECTS_TIMESHEET_TIME_LOGS'
export const GET_PROJECTS_TIME_TRACKING='GET_PROJECTS_TIME_TRACKING'
export const UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET='UPDATE_PROJECT_FINANCE_TASKS_LIST_BUDGET'
export const GET_PROJECT_FINANCE_BUDGET='GET_PROJECT_FINANCE_BUDGET'
export const CREATE_PROJECT_FINANCE_BUDGET='CREATE_PROJECT_FINANCE_BUDGET'
export const UPDATE_PROJECT_FINANCE_BUDGET='UPDATE_PROJECT_FINANCE_BUDGET'
export const DELETE_PROJECT_FINANCE_BUDGET='DELETE_PROJECT_FINANCE_BUDGET'
export const GET_PROJECT_FINANCE_BUDGET_OVERVIEW = 'GET_PROJECT_FINANCE_BUDGET_OVERVIEW'
export const GET_PROJECT_FINANCE_BUDGET_PROFITABILITY = 'GET_PROJECT_FINANCE_BUDGET_PROFITABILITY'
export const GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW = 'GET_PROJECT_FINANCE_BUDGET_RETAINER_OVERVIEW'
export const GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST='GET_PROJECT_FINANCE_BUDGET_EXPENSE_LIST'
export const CREATE_PROJECT_FINANCE_EXPENSE='CREATE_PROJECT_FINANCE_EXPENSE'
export const GET_PROJECT_FINANCE_SINGLE_EXPENSE='GET_PROJECT_FINANCE_SINGLE_EXPENSE'
export const UPDATE_PROJECT_FINANCE_EXPENSE='UPDATE_PROJECT_FINANCE_EXPENSE'
export const DELETE_PROJECT_FINANCE_EXPENSE='DELETE_PROJECT_FINANCE_EXPENSE'
export const GET_PROJECT_FINANCE_RATES_LIST = 'GET_PROJECT_FINANCE_RATES_LIST'
export const GET_PROJECT_FINANCE_SINGLE_RATE = 'GET_PROJECT_FINANCE_SINGLE_RATE'
export const UPDATE_PROJECT_FINANCE_SINGLE_RATE = 'UPDATE_PROJECT_FINANCE_SINGLE_RATE'
export const GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST = 'GET_PROJECT_FINANCE_BILLING_OPEN_INVOICES_LIST'
export const GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST = 'GET_PROJECT_FINANCE_BILLING_COMPLETED_INVOICES_LIST'
export const GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST = 'GET_PROJECT_FINANCE_BILLING_UNBILLED_TIME__LIST'
export const GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST='GET_PROJECT_FINANCE_BILLING_EXPENSE_LIST'
export const CREATE_PROJECT_FINANCE_BILLING_INVOICE='CREATE_PROJECT_FINANCE_BILLING_INVOICE'
export const GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE='GET_PROJECT_FINANCE_BILLING_SINGLE_INVOICE'
export const UPDATE_PROJECT_FINANCE_BILLING_INVOICE='UPDATE_PROJECT_FINANCE_BILLING_INVOICE'
export const DELETE_PROJECT_FINANCE_BILLING_INVOICE='DELETE_PROJECT_FINANCE_BILLING_INVOICE'
export const GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS='GET_PROJECT_FINANCE_BILLING_INVOICE_LOGS'
export const MOVE_PROJECT_FINANCE_BILLING_ITEMS='MOVE_PROJECT_FINANCE_BILLING_ITEMS'
export const COMPLETE_PROJECT_FINANCE_BILLING_INVOICE='COMPLETE_PROJECT_FINANCE_BILLING_INVOICE'
export const REOPEN_PROJECT_FINANCE_BILLING_INVOICE='REOPEN_PROJECT_FINANCE_BILLING_INVOICE'
export const CREATE_PROJECTS_TASKS_LIST_STAGE='CREATE_PROJECTS_TASKS_LIST_STAGE'
export const GET_PROJECTS_TASKS_LIST_SINGLE_STAGE='GET_PROJECTS_TASKS_LIST_SINGLE_STAGE'
export const UPDATE_PROJECTS_TASKS_LIST_STAGE='UPDATE_PROJECTS_TASKS_LIST_STAGE'
export const DELETE_PROJECTS_TASKS_LIST_STAGE='DELETE_PROJECTS_TASKS_LIST_STAGE'
export const MOVE_PROJECTS_TASKS_LIST_STAGE='MOVE_PROJECTS_TASKS_LIST_STAGE'
export const CREATE_PROJECT_RISK='CREATE_PROJECT_RISK'
export const EDIT_PROJECT_RISK='EDIT_PROJECT_RISK'
export const GET_SINGLE_PROJECT_RISK='GET_SINGLE_PROJECT_RISK'
export const GET_ALL_PROJECT_RISKS='GET_ALL_PROJECT_RISKS'
export const DELETE_PROJECT_RISK='DELETE_PROJECT_RISK'
export const CREATE_PROJECT_MILESTONE='CREATE_PROJECT_MILESTONE'
export const GET_SINGLE_PROJECT_MILESTONE='GET_SINGLE_PROJECT_MILESTONE'
export const EDIT_PROJECT_MILESTONE='EDIT_PROJECT_MILESTONE'
export const GET_ALL_PROJECT_MILESTONE='GET_ALL_PROJECT_MILESTONE'
export const DELETE_PROJECT_MILESTONE='DELETE_PROJECT_MILESTONE'
export const COPY_PROJECT_MILESTONE='COPY_PROJECT_MILESTONE'
export const COMPLETE_PROJECT_MILESTONE='COMPLETE_PROJECT_MILESTONE'
export const ADD_PROJECT_MILESTONE_COMMENT='ADD_PROJECT_MILESTONE_COMMENT'
export const EDIT_PROJECT_MILESTONE_COMMENT='EDIT_PROJECT_MILESTONE_COMMENT'
export const GET_PROJECT_MILESTONE_COMMENTS='GET_PROJECT_MILESTONE_COMMENTS'
export const DELETE_PROJECT_MILESTONE_COMMENT='DELETE_PROJECT_MILESTONE_COMMENT'
export const MOVE_PROJECT_MILESTONE='MOVE_PROJECT_MILESTONE'
export const CREATE_PROJECT_FORM='CREATE_PROJECT_FORM'
export const GET_ALL_PROJECT_FORMS='GET_ALL_PROJECT_FORMS'
export const DELETE_PROJECT_FORM='DELETE_PROJECT_FORM'
export const GET_SINGLE_PROJECT_FORM='GET_SINGLE_PROJECT_FORM'
export const EDIT_PROJECT_FORM='EDIT_PROJECT_FORM'
export const GET_PUBLIC_PROJECT_FORMS='GET_PUBLIC_PROJECT_FORMS'
export const PROJECT_FORM_PUBLISHED='PROJECT_FORM_PUBLISHED'
export const SUBMIT_PROJECT_FORM='SUBMIT_PROJECT_FORM'
export const CLONE_PROJECT_FORM='CLONE_PROJECT_FORM'

// Projects - Templates
export const CREATE_PROJECT_TEMPLATES = 'CREATE_PROJECT_TEMPLATES'
export const GET_PROJECTS_TEMPLATES_LIST='GET_PROJECTS_TEMPLATES_LIST'
export const GET_PROJECTS_SINGLE_TEMPLATE='GET_PROJECTS_SINGLE_TEMPLATE'
export const UPDATE_PROJECTS_TEMPLATE='UPDATE_PROJECTS_TEMPLATE'
export const DELETE_PROJECTS_TEMPLATE='DELETE_PROJECTS_TEMPLATE'
export const GET_PROJECTS_TEMPLATES_FILERS='GET_PROJECTS_TEMPLATES_FILERS'
export const GET_PROJECTS_TEMPLATES_TASKLIST='GET_PROJECTS_TEMPLATES_TASKLIST'
export const CREATE_PROJECTS_TEMPLATES_TASKLIST='CREATE_PROJECTS_TEMPLATES_TASKLIST'
export const GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST='GET_PROJECTS_TEMPLATES_SINGLE_TASKLIST'
export const UPDATE_PROJECTS_TEMPLATES_TASKLIST='UPDATE_PROJECTS_TEMPLATES_TASKLIST'
export const DELETE_PROJECTS_TEMPLATES_TASKLIST='DELETE_PROJECTS_TEMPLATES_TASKLIST'
export const GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS='GET_PROJECTS_TEMPLATES_TASKLIST_FILTERS'
export const GET_PROJECTS_TEMPLATES_TASKLIST_TASKS='GET_PROJECTS_TEMPLATES_TASKLIST_TASKS'
export const CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK='CREATE_PROJECTS_TEMPLATES_TASKLIST_TASK'
export const GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK='GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_TASK'
export const UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK='UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK'
export const DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK='DELETE_PROJECTS_TEMPLATES_TASKLIST_TASK'
export const CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE='CREATE_PROJECTS_TEMPLATES_TASKLIST_STAGE'
export const GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE='GET_PROJECTS_TEMPLATES_TASKLIST_SINGLE_STAGE'
export const DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE='DELETE_PROJECTS_TEMPLATES_TASKLIST_STAGE'
export const UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE='UPDATE_PROJECTS_TEMPLATES_TASKLIST_TASK_STAGE'
export const MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE='MOVE_PROJECTS_TEMPLATES_TASKLIST_STAGE'
export const GET_PROJECTS_TEMPLATES_FINANCE_BUDGET='GET_PROJECTS_TEMPLATES_FINANCE_BUDGET'
export const CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET='CREATE_PROJECTS_TEMPLATES_FINANCE_BUDGET'
export const DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET='DELETE_PROJECTS_TEMPLATES_FINANCE_BUDGET'
export const GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST='GET_PROJECTS_TEMPLATES_FINANCE_RATES_LIST'
export const GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE='GET_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE'
export const UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE='UPDATE_PROJECTS_TEMPLATES_FINANCE_SINGLE_RATE'

export const GET_PROJECTS_BUDGETS_LIST='GET_PROJECTS_BUDGETS_LIST'

export const GLOBAL_SEARCH_CUSTOMERS = 'GLOBAL_SEARCH_CUSTOMERS'
export const GLOBAL_SEARCH_INBOX = 'GLOBAL_SEARCH_INBOX'
export const GLOBAL_SEARCH_SALES = 'GLOBAL_SEARCH_SALES'
export const GLOBAL_SEARCH_PROJECTS = 'GLOBAL_SEARCH_PROJECTS'
export const GLOBAL_SEARCH_FINANCE = 'GLOBAL_SEARCH_FINANCE'
export const GLOBAL_SEARCH_HR = 'GLOBAL_SEARCH_HR'

// Settings Project
export const GET_PROJECTS_CATEGORIES='GET_PROJECTS_CATEGORIES'
export const CREATE_PROJECTS_CATEGORY = 'CREATE_PROJECTS_CATEGORY'
export const GET_PROJECTS_SINGLE_CATEGORY = 'GET_PROJECTS_SINGLE_CATEGORY'
export const UPDATE_PROJECTS_CATEGORY = 'UPDATE_PROJECTS_CATEGORY'
export const DELETE_PROJECTS_CATEGORY = 'DELETE_PROJECTS_CATEGORY'

// Sales Orders
export const CREATE_SALES_ORDERS_PREFIX = 'CREATE_SALES_ORDERS_PREFIX'
export const GET_SALES_ORDERS_PREFIX = 'GET_SALES_ORDERS_PREFIX'
export const DELETE_SALES_ORDERS_PREFIX = 'DELETE_SALES_ORDERS_PREFIX'
export const GET_NEW_SO_NUMBER = 'GET_NEW_SO_NUMBER'
export const CREATE_SALES_ORDERS = 'CREATE_SALES_ORDERS'
export const GET_SALES_ORDERS_LIST = 'GET_SALES_ORDERS_LIST'
export const DELETE_SALES_ORDERS = 'DELETE_SALES_ORDERS'
export const GET_SALES_ORDERS_DATA = 'GET_SALES_ORDERS_DATA'
export const UPDATE_SALES_ORDERS = 'UPDATE_SALES_ORDERS'
export const COPY_SALES_ORDERS = 'COPY_SALES_ORDERS'
export const DELETE_SO_LOGO = 'DELETE_SO_LOGO'

// Invoices
export const CREATE_INVOICES_PREFIX = 'CREATE_INVOICES_PREFIX'
export const GET_NEW_INVOICES_NUMBER = 'GET_NEW_INVOICES_NUMBER'
export const GET_INVOICES_PREFIX = 'GET_INVOICES_PREFIX'
export const DELETE_INVOICES_PREFIX = 'DELETE_INVOICES_PREFIX'
export const CREATE_INVOICES = 'CREATE_INVOICES'
export const GET_INVOICES_LIST = 'GET_INVOICES_LIST'
export const DELETE_INVOICES = 'DELETE_INVOICES'
export const GET_INVOICES_DATA = 'GET_INVOICES_DATA'
export const UPDATE_INVOICES = 'UPDATE_INVOICES'
export const COPY_INVOICES = 'COPY_INVOICES'
export const DELETE_INVOICE_LOGO = 'DELETE_INVOICE_LOGO'

// Budget
export const GET_BREAKDOWN_LIST = 'GET_BREAKDOWN_LIST'
export const CREATE_BUDGET = 'CREATE_BUDGET'
export const GET_BUDGET_LIST = 'GET_BUDGET_LIST'
export const DELETE_BUDGET = 'DELETE_BUDGET'
export const UPDATE_BUDGET_STATUS = 'UPDATE_BUDGET_STATUS'
export const GET_BUDGET_DATA = 'GET_BUDGET_DATA'
export const UPDATE_BUDGET = 'UPDATE_BUDGET'

// Meeting
export const MEETING_CREATE = 'MEETING_CREATE';
export const GET_MEETING_LIST = 'GET_MEETING_LIST';
export const DELETE_MEETINGS = 'DELETE_MEETINGS';
export const GET_SINGLE_MEETING = 'GET_SINGLE_MEETING';
export const EDIT_MEETING = 'EDIT_MEETING';
export const GOOGLE_CALENDAR_ACCESS = 'GOOGLE_CALENDAR_ACCESS';
export const GOOGLE_CALENDAR_AUTH_CHECK = 'GOOGLE_CALENDAR_AUTH_CHECK';
export const BOOKING_SLOT_AVALIBLITY = 'BOOKING_SLOT_AVALIBLITY';
export const MEETING_EVENT_LIST = 'MEETING_EVENT_LIST';
export const ADD_EVENT_MEETING_BOOK = 'ADD_EVENT_MEETING_BOOK';
export const UNAVAILABLE_SLOTS = 'UNAVAILABLE_SLOTS';
export const GOOGLE_CALENDAR_DISCONNECT = 'GOOGLE_CALENDAR_DISCONNECT';
export const MEETING_SAMPLE_MAIL = 'MEETING_SAMPLE_MAIL';
export const MEETING_CALENDAR_LIST = 'MEETING_CALENDAR_LIST';
export const MEETING_CALENDAR_SET_DEFAULT = 'MEETING_CALENDAR_SET_DEFAULT';
export const MEETING_CALENDAR_TWO_WAY_SYNC = 'MEETING_CALENDAR_TWO_WAY_SYNC';

export const GET_ALL_CALENDAR_EVENTS = 'GET_ALL_CALENDAR_EVENTS'

// Employees
export const ADD_DEPARTMENT = 'ADD_DEPARTMENT'
export const GET_DEPARTMENT = 'GET_DEPARTMENT'
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT'
export const GET_SINGLE_DEPARTMENT = 'GET_SINGLE_DEPARTMENT'
export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT'
export const GET_EMPLOYEES_SETTING = 'GET_EMPLOYEES_SETTING'
export const UPDATE_EMPLOYEES_SETTING = 'UPDATE_EMPLOYEES_SETTING'
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE'
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES'
export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES'
export const GET_SINGLE_EMPLOYEE = 'GET_SINGLE_EMPLOYEE'
export const UPDATE_EMPLOYEES = 'UPDATE_EMPLOYEES'
export const RESEND_EMPLOYEE_INVITATION='RESEND_EMPLOYEE_INVITATION'

// Automations
export const CREATE_AUTOMATION='CREATE_AUTOMATION'
export const GET_AUTOMATIONS='GET_AUTOMATIONS'
export const GET_SINGLE_AUTOMATION='GET_SINGLE_AUTOMATION'
export const UPDATE_AUTOMATION='UPDATE_AUTOMATION'
export const DELETE_AUTOMATION='DELETE_AUTOMATION'
export const CHANGE_AUTOMATION_STATUS='CHANGE_AUTOMATION_STATUS'

// Employees Reports
export const EMPLOYEES_STATS ='EMPLOYEES_STATS'
export const EMPLOYEES_BY_STATUS ='EMPLOYEES_BY_STATUS'
export const EMPLOYEES_BY_TYPE ='EMPLOYEES_BY_TYPE'
export const EMPLOYEES_BY_DEPARTMENT ='EMPLOYEES_BY_DEPARTMENT'
export const EMPLOYEES_TIME_TREND ='EMPLOYEES_TIME_TREND'
export const NEW_EMPLOYEE_BY_DEPARTMENT ='NEW_EMPLOYEE_BY_DEPARTMENT'

// Expenses
export const GET_EXPENSES_CATEGORY ='GET_EXPENSES_CATEGORY'
export const CREATE_EXPENSES_CATEGORY ='CREATE_EXPENSES_CATEGORY'
export const DELETE_EXPENSES_CATEGORY ='DELETE_EXPENSES_CATEGORY'
export const GET_SINGLE_EXPENSES_CATEGORY ='GET_SINGLE_EXPENSES_CATEGORY'
export const EDIT_EXPENSES_CATEGORY ='EDIT_EXPENSES_CATEGORY'
export const GET_EMPLOYEE_LIST ='GET_EMPLOYEE_LIST'
export const GET_PROJECT_LIST ='GET_PROJECT_LIST'
export const CREATE_EXPENSES ='CREATE_EXPENSES'
export const GET_ALL_EXPENSES ='GET_ALL_EXPENSES'
export const DELETE_EXPENSES ='DELETE_EXPENSES'
export const GET_SINGLE_EXPENSE ='GET_SINGLE_EXPENSE'
export const UPDATE_EXPENSE ='UPDATE_EXPENSE'

// Reports Expenses
export const EXPENSES_STATS = 'EXPENSES_STATS'
export const EXPENSES_VALUE_STATS = 'EXPENSES_VALUE_STATS'
export const EXPENSES_BREAKDOWN_BY_CATEGORY = 'EXPENSES_BREAKDOWN_BY_CATEGORY'
export const EXPENSES_BREAKDOWN_BY_STATUS = 'EXPENSES_BREAKDOWN_BY_STATUS'
export const EXPENSES_BY_DEPARTMENT = 'EXPENSES_BY_DEPARTMENT'
export const EXPENSES_STATUS_TIMETREND = 'EXPENSES_STATUS_TIMETREND'
export const EXPENSES_CATEGORY_TIMETREND = 'EXPENSES_CATEGORY_TIMETREND'
export const EXPENSES_TIMETREND_BY_DEPARTMENT = 'EXPENSES_TIMETREND_BY_DEPARTMENT'
export const EXPENSES_EMPLOYEE_STATS = 'EXPENSES_EMPLOYEE_STATS'

// Time Off
export const CREATE_TIMEOFF_TYPES = 'CREATE_TIMEOFF_TYPES'
export const GET_TIMEOFF_TYPES = 'GET_TIMEOFF_TYPES'
export const DELETE_TIMEOFF_TYPE = 'DELETE_TIMEOFF_TYPE'
export const GET_SINGLE_TIMEOFF_TYPE = 'GET_SINGLE_TIMEOFF_TYPE'
export const EDIT_TIMEOFF_TYPE = 'EDIT_TIMEOFF_TYPE'
export const GET_TIMEOFF_EMPLOYEE_LIST = 'GET_TIMEOFF_EMPLOYEE_LIST'
export const CREATE_TIMEOFF = 'CREATE_TIMEOFF'
export const GET_ALL_TIMEOFF = 'GET_ALL_TIMEOFF'
export const DELETE_TIMEOFF = 'DELETE_TIMEOFF'
export const UPDATE_TIMEOFF = 'UPDATE_TIMEOFF'
export const GET_SINGLE_TIMEOFF = 'GET_SINGLE_TIMEOFF'

export const BREAKDOWN_BY_TYPES = 'BREAKDOWN_BY_TYPES'
export const BREAKDOWN_BY_DEPARTMENT = 'BREAKDOWN_BY_DEPARTMENT'
export const LEAVES_BY_DEPARTMENT = 'LEAVES_BY_DEPARTMENT'
export const LEAVES_BY_TYPE = 'LEAVES_BY_TYPE'
export const EMPLOYEE_STATS = 'EMPLOYEE_STATS'

// Holidays
export const CREATE_HOLIDAY_GROUP = 'CREATE_HOLIDAY_GROUP'
export const GET_HOLIDAY_GROUP = 'GET_HOLIDAY_GROUP'
export const GET_SINGLE_HOLIDAY_GROUP = 'GET_SINGLE_HOLIDAY_GROUP'
export const UPDATE_HOLIDAY_GROUP = 'UPDATE_HOLIDAY_GROUP'
export const DELETE_HOLIDAY_GROUP = 'DELETE_HOLIDAY_GROUP'
export const CREATE_HOLIDAY = 'CREATE_HOLIDAY'
export const DELETE_HOLIDAY = 'DELETE_HOLIDAY'
export const GET_SINGLE_HOLIDAY = 'GET_SINGLE_HOLIDAY'
export const UPDATE_HOLIDAY = 'UPDATE_HOLIDAY'
export const DEFAULT_GROUP = 'DEFAULT_GROUP'

// Announcement
export const EMPLOYEES_FOR_ANNOUNCEMENT = 'EMPLOYEES_FOR_ANNOUNCEMENT'
export const DEPARTMENTS_FOR_ANNOUNCEMENT = 'DEPARTMENTS_FOR_ANNOUNCEMENT'
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT'
export const GET_ALL_ANNOUNCEMENT = 'GET_ALL_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const GET_SINGLE_ANNOUNCEMENT = 'GET_SINGLE_ANNOUNCEMENT'
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT'


// employee
export const EMPLOYEE_SET_PASSWORD='EMPLOYEE_SET_PASSWORD'
export const LOGIN_EMPLOYEE='LOGIN_EMPLOYEE'
export const EMPLOYEE_RESET_PASSWORD='EMPLOYEE_RESET_PASSWORD'
export const EMPLOYEE_LOGOUT='EMPLOYEE_LOGOUT'
// Appraisal
export const CREATE_APPRAISAL_TEMPLATE = 'CREATE_APPRAISAL_TEMPLATE'
export const GET_ALL_APPRAISAL_TEMPLATE = 'GET_ALL_APPRAISAL_TEMPLATE'
export const DELETE_APPRAISAL_TEMPLATE = 'DELETE_APPRAISAL_TEMPLATE'
export const GET_APPRAISAL_TEMPLATE_DATA = 'GET_APPRAISAL_TEMPLATE_DATA'
export const UPDATE_APPRAISAL_TEMPLATE = 'UPDATE_APPRAISAL_TEMPLATE'
export const GET_REVEIWER_LIST = 'GET_REVEIWER_LIST'
export const SEND_APPRAISAL = 'SEND_APPRAISAL'

// Client portal
export const CREATE_CLIENT_PORTAL_ANNOUNCEMENT='CREATE_CLIENT_PORTAL_ANNOUNCEMENT'
export const GET_CLIENT_PORTAL_ANNOUNCEMENTS='GET_CLIENT_PORTAL_ANNOUNCEMENTS'
export const GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT='GET_CLIENT_PORTAL_SINGLE_ANNOUNCEMENT'
export const UPDATE_CLIENT_PORTAL_ANNOUNCEMENT='UPDATE_CLIENT_PORTAL_ANNOUNCEMENT'
export const DELETE_CLIENT_PORTAL_ANNOUNCEMENT='DELETE_CLIENT_PORTAL_ANNOUNCEMENT'
export const GET_CLIENT_PORTAL_SETTINGS='GET_CLIENT_PORTAL_SETTINGS'
export const UPDATE_CLIENT_PORTAL_SETTINGS='UPDATE_CLIENT_PORTAL_SETTINGS'
export const UPLOAD_CLIENT_PORTAL_LOGO='UPLOAD_CLIENT_PORTAL_LOGO'
export const GET_CLIENT_PORTAL_TICKET_FORM='GET_CLIENT_PORTAL_TICKET_FORM'
export const UPDATE_CLIENT_PORTAL_TICKET_FORM='UPDATE_CLIENT_PORTAL_TICKET_FORM'

// Scoring rules
export const CREATE_SCORING_RULES='CREATE_SCORING_RULES'
export const DELETE_SCORING_RULES='Delete_SCORING_RULES'
export const GET_SCORING_RULES='GET_SCORING_RULES'
export const GET_SINGLE_SCORING_RULES='GET_SINGLE_SCORING_RULES'
export const UPDATE_SCORING_RULES='Update_SCORING_RULES'
export const GET_SCORING_RULES_INSIGHTS='GET_SCORING_RULES_INSIGHTS'
export const UPDATE_SCORING_RULES_INSIGHTS='UPDATE_SCORING_RULES_INSIGHTS'

// Setting Billing and Invoices
export const CURRENT_SUBSCRIPTION = 'CURRENT_SUBSCRIPTION';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const CALCULATE_BILLING = 'CALCULATE_BILLING';
export const CANCEL_BILLING = 'CANCEL_BILLING';
export const GET_BILLING_ADDRESS = 'GET_BILLING_ADDRESS';
export const GET_KOOPER_PLANS = 'GET_KOOPER_PLANS'
export const RESUME_BILLING = 'RESUME_BILLING';
export const START_BILLING = 'START_BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const INVOICE_LISTING = 'INVOICE_LISTING';
export const GET_SINGLE_INVOICE = 'GET_SINGLE_INVOICE';
export const REFRESH_ACCESS_TOKEN='REFRESH_ACCESS_TOKEN'

// Setting - My Organization - Roles
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const GET_ROLE = 'GET_ROLE'
export const ADD_ROLE = 'ADD_ROLE'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const GET_ROLES_STRUCTURE='GET_ROLES_STRUCTURE'
export const GET_USER_ROLE_PERMISSIONS='GET_USER_ROLE_PERMISSIONS'

// Proofs
export const CREATE_PROOF = 'CREATE_PROOF'
export const GET_ALL_PROOFS = 'GET_ALL_PROOFS'
export const DELETE_PROOF = 'DELETE_PROOF'
export const GET_SINGLE_PROOF = 'GET_SINGLE_PROOF'
export const EDIT_PROOF = 'EDIT_PROOF'
export const UPDATE_PROOF_STATUS = 'UPDATE_PROOF_STATUS'
export const UPDATE_APPROVAL_STATUS = 'UPDATE_APPROVAL_STATUS'
export const ADD_NEW_VERSION = 'ADD_NEW_VERSION'
export const ADD_COMMENT = 'ADD_COMMENT'
export const GET_COMMENTS = 'GET_COMMENTS'
export const DELETE_COMMENT = 'DELETE_COMMENT'

// Reports - Projects
export const GET_PROJECTS_HEALTH_LIST='GET_PROJECTS_HEALTH_LIST'
export const GET_PROJECTS_HEALTH_DATA='GET_PROJECTS_HEALTH_DATA'
export const GET_PROJECTS_TIMESHEET_TIMELOGS_CHART = 'GET_PROJECTS_TIMESHEET_TIMELOGS_CHART'
export const GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST = 'GET_PROJECTS_TIMESHEET_TIMELOGS_BY_PROJECT_LIST'
export const GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST = 'GET_PROJECTS_TIMESHEET_TIMELOGS_BY_USER_LIST'
export const GET_PROJECTS_USERS_TASKS_BY_USER_LIST = 'GET_PROJECTS_USERS_TASKS_BY_USER_LIST'
export const GET_PROJECTS_PLANNING_REPORT_UTILIZATION = 'GET_PROJECTS_PLANNING_REPORT_UTILIZATION'

// Resource Planning
export const GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS='GET_PROJECTS_PLANNING_OVERVIEW_TIMEWISE_STATS'
export const GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER='GET_PROJECTS_PLANNING_OVERVIEW_STATS_BY_USER'
export const GET_PROJECTS_PLANNING_WORKLOADS_LIST='GET_PROJECTS_PLANNING_WORKLOADS_LIST'
export const UPDATE_PROJECTS_TASKS_PROGRESS='UPDATE_PROJECTS_TASKS_PROGRESS'