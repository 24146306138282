import {apiAction} from './api'
import {SETTINGS_ENDPOINT} from './endpoint'
import {ADD_SURVEY, DELETE_SURVEY, EDIT_SURVEY, GET_CHAT_SURVEY, GET_EMAIL_SURVEY} from './types'

export function getEmailSurvey() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/surveys/email`,
    method: 'GET',
    label: GET_EMAIL_SURVEY,
  })
}

export function getChatSurvey() {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/surveys/web`,
    method: 'GET',
    label: GET_CHAT_SURVEY,
  })
}

export function addSurvey(data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/surveys`,
    method: 'POST',
    label: ADD_SURVEY,
    data,
    showToast: true,
    successMessage: 'Survey Created',
    contentType: 'multipart/form-data',
  })
}

export function editSurvey(id, data) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/surveys/${id}`,
    method: 'PUT',
    label: EDIT_SURVEY,
    data,
    showToast: true,
    successMessage: 'Survey Updated',
    contentType: 'multipart/form-data',
  })
}

export function deleteSurvey(id) {
  return apiAction({
    url: `${SETTINGS_ENDPOINT}/surveys/${id}`,
    method: 'DELETE',
    label: DELETE_SURVEY,
    showToast: true,
    successMessage: 'Survey Deleted',
  })
}
