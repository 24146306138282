import React from 'react'
import {getUserInfo} from 'utils/local-storage'
import {get} from 'utils/helper'
import DummyAvtarAccountUser from './DummyAvtar'

const AccountUserImage = () => {
  const userData = getUserInfo()

  if (get(['avatar'], userData)) {
    const avtar = get(['avatar'], userData)
    return <img src={avtar} className="agentImage" alt="img" />
  }
  return (
    <div
      className="kooper-dummyAvtar"
      // style={{
      //   backgroundColor: getProfilePicColor(),
      // }}
    >
      <DummyAvtarAccountUser />
    </div>
  )
}

export default React.memo(AccountUserImage)
