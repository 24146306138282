import React from 'react'
import create from 'assets/create.svg'
import CreateFirst from 'components/common/CreateFirst'
import useUserPermissions from 'hooks/pure/useUserPermissions'
import PurchaseOrdersMenu from 'navigation/sub-routes/Finance/PurchaseOrders/PurchaseOrdersMenu'
import PurchaseOrdersRoutes from 'navigation/sub-routes/Finance/PurchaseOrders/PurchaseOrdersRoutes'
import {getPermissionTooltipContent} from 'utils/helper'

function PurchaseOrders() {
  const {financeAccess} = useUserPermissions()
  const purchaseOrdersAccess = financeAccess?.purchaseOrders

  if (!purchaseOrdersAccess) {
    return (
      <CreateFirst
        header="Purchase Orders"
        buttonText="Create Purchase Order"
        tooltip={!purchaseOrdersAccess}
        tooltipContent={getPermissionTooltipContent({featureAccess: purchaseOrdersAccess})}
        imageSrc={create}
        subHeader="Effortlessly create, manage and track purchase orders to ensure that financial transactions are well-organized"
        Feature1="Easy Purchase Order Creation"
        list1="Generate purchase orders with user-friendly templates & minimal input"
        Feature2="Customizable Templates"
        list2="Tailor purchase orders to fit your branding and specific needs"
        Feature3="Comprehensive Tax and Discounts"
        list3="Prepare purchase orders for products with tax and discount percentages"
      />
    )
  }
  return (
    <>
      <PurchaseOrdersMenu />
      <PurchaseOrdersRoutes />
    </>
  )
}

export default PurchaseOrders
