import Joi from 'joi'

import {inputNumberValidation, inputStringValidation} from 'validation'

export const createExpensesSchema = Joi.object({
  name: inputStringValidation.required(),
  expenseDate: Joi.date().required().messages({
    'date.base': 'Date must be a valid date',
  }),
  docNumber: Joi.string().max(20).allow('', null).messages({
    'string.max': `Length must be less than or equal to 20 characters`,
  }),
  employeeId: inputNumberValidation.required().messages({
    'number.base': 'Please select an employee id',
  }),
  projectId: inputNumberValidation.allow('', null),
  labelIds: Joi.array().items(Joi.number()).allow(null),
  currencyId: Joi.number(),
  paidBy: Joi.string().required(),
  status: Joi.string().allow('', null),
  lists: Joi.array().items(
    Joi.object().keys({
      categoryId: Joi.number().required().messages({'number.base': 'Category must be specified'}),
      description: Joi.string().max(200).allow('', null).messages({
        'string.max': `Length must be less than or equal to 200 characters`,
      }),
      price: Joi.number().positive().required().messages({
        'number.positive': 'Price must be position',
      }),
      discount: Joi.number(),
      tax: Joi.number(),
      total: Joi.number(),
    })
  ),
  taxAmount: Joi.number(),
  subTotal: Joi.number(),
  totalAmount: Joi.number(),
  attachmentIds: Joi.array().items(Joi.number()),
})

export const createExpensesCategorySchema = Joi.object({
  category: inputStringValidation.required().messages({
    'string.empty': `category cannot be an empty field`,
    'any.required': `category is a required field`,
    'string.min': `category should have a minimum length of 2`,
    'string.max': `category should have length of 20 characters or less`,
  }),
  description: Joi.string().allow('', null).max(200).messages({
    'string.max': `Length must be less than or equal to 200 characters`,
  }),
  needApproval: Joi.boolean(),
})
